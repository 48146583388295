import produce from 'immer';
import { findIndex } from 'lodash-es';
import { ISpace } from '../../shared/motive/models/Space';
import { SpaceActionTypes } from './SpaceActions';
import { Reducer } from 'redux';

interface ISpacesState {
    spaces: ISpace[];
}

const defaultState: ISpacesState = {
    spaces: []
};
const santizeId = (id: number) => `${id}`;

const compareById = (space: ISpace, id: string) => santizeId(space.id) === id;

export const SpacesReducer: Reducer<ISpacesState, SpaceActionTypes> = produce(
    (state: ISpacesState, action: SpaceActionTypes) => {
        switch (action.type) {
            case 'space/set': {
                state.spaces = action.spaces;
                break;
            }
            case 'space/append': {
                if (state.spaces.some((space: ISpace) => compareById(space, santizeId(action.space.id)))) {
                    throw new Error(
                        `Space with id=${action.space.id} already exists within the collection, cannot append again. Did you mean to update?`
                    );
                }

                state.spaces.push(action.space);
                break;
            }
            case 'space/clear': {
                state.spaces = [];
                break;
            }
            case 'space/delete': {
                state.spaces = state.spaces.filter(space => !compareById(space, action.id));
                break;
            }
            case 'space/update': {
                const idx = findIndex(state.spaces, (space: ISpace) => compareById(space, action.id));

                if (idx < 0) {
                    throw new Error(
                        `Can not replace space with id=${action.id} as it does not exist in collection yet. Check SpacesReducer for more info.`
                    );
                }

                state.spaces[idx] = action.space;
                break;
            }
        }
    },
    defaultState
);
