import React from 'react';
import { IFrame, IScript } from '../../shared/motive/models/Script';
import { useStyle } from '../../shared/hooks/useStyle';
import { useScriptAndGlobalVariables } from '../../shared/motive/hooks/useScriptAndGlobalVariables';
import { IScriptVariableMap, removeFrameVariable } from '../../util/ScriptDynamicsUtil';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Button, ButtonVariant } from '../../core-ui/button';
import { IScriptFrameAction, ScriptActionType } from '../../shared/motive/reducers/ScriptEditorReducers';
import { defaultVariablesStyle, flexStyleRowRightAligned, variableHeadingStyle } from './FrameInspectorVariables.style';
import { Size } from '../../core-ui/constants/Size';
import { VariableSelectorEditorContainer } from '../../containers/variableSelectorEditorContainer';
import { Text } from '../../core-ui/text';
import { SelectScriptEditorCurrentScript } from '../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';
import { useDispatch, useSelector } from 'react-redux';

export interface IFrameVariableSettingProps {
    frame: IFrame;
    variableId: string;
    varMap: IScriptVariableMap;
    onClickSetVariableId: (variableId: string) => void;
}

const FrameVariableSetting: React.FC<IFrameVariableSettingProps> = ({
    variableId,
    varMap,
    frame,
    onClickSetVariableId
}) => {
    const variable = varMap[variableId];
    const script = useSelector(SelectScriptEditorCurrentScript);
    const scriptDispatch = useDispatch();

    const handleRemove = () => {
        const updatedFrame = removeFrameVariable(frame, variable.id);

        const scriptDispatchAction: IScriptFrameAction = {
            type: ScriptActionType.FRAME_UPDATE,
            frame: updatedFrame
        };

        scriptDispatch(scriptDispatchAction);
    };

    const token = useStyle();

    return (
        <>
            {variable && (
                <div title={variable.name} css={defaultVariablesStyle(token)}>
                    <div css={flexStyleRowRightAligned}>
                        <Text size={Size.MEDIUM} css={variableHeadingStyle}>
                            {variable.name}
                        </Text>
                        <Button
                            variant={ButtonVariant.HOLLOW}
                            icon={IconTypes.EYE}
                            size={Size.SMALL}
                            onClick={() => onClickSetVariableId(variable.id)}
                        />
                        <Button icon={IconTypes.DELETE} size={Size.SMALL} onClick={handleRemove} />
                    </div>
                    <VariableSelectorEditorContainer script={script} frame={frame} variable={variable} />
                </div>
            )}
        </>
    );
};

export interface IFrameInspectorVariablesProps {
    script: IScript;
    frame: IFrame;
    onClickSetVariableId: (variableId: string) => void;
}

export const FrameInspectorVariables = React.memo(function FrameInspectorVariables({
    frame,
    onClickSetVariableId
}: IFrameInspectorVariablesProps) {
    const varMap = useScriptAndGlobalVariables();

    return (
        <>
            {frame.definedVariables?.map(v => (
                <FrameVariableSetting
                    key={v.variableId}
                    frame={frame}
                    varMap={varMap}
                    variableId={v.variableId}
                    onClickSetVariableId={onClickSetVariableId}
                />
            ))}
        </>
    );
});
