import { css } from '@emotion/core';

export const accountGrid = (isLoading: boolean) => css`
    display: grid;
    grid-gap: 5vw;
    grid-template-rows: 2fr 10fr 2fr;
    grid-template-columns: 1fr 4fr 5fr 2fr;
    margin: 50px;
    padding: 15px;
    opacity: ${isLoading ? 0.4 : 1} // eslint-disable-next-line prettier/prettier
        form {
        margin: 15px;
    }
`;

export const accountTitle = css`
    grid-row: 1;
    grid-column-start: 2;
    grid-column-end: 4;
    text-align: center;
`;

export const accountGridEntry = (isLoading: boolean) => css`
    grid-row: 2;
    grid-column: 3;
    height: ${isLoading ? '100%' : 'auto'};
    width: ${isLoading ? '100%' : 'auto'};
    background-color: ${isLoading ? '#3b3f48' : 'inherit'};
    opacity: ${isLoading ? 0.2 : 1};
    border-radius: ${isLoading ? '10px' : 0};
`;

export const accountFormImage = css`
    grid-row: 2;
    grid-column: 2;
    width: 100%;
`;
