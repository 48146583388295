import { useObjectDefinitions } from '../useScriptEditorInfo/useScriptEditorInfo';
import { ITypeDefinitionMap } from '../../models/TypeDefinition';
import { createScriptObject } from '../../../../util/MotiveUtils';
import { IScriptObjectModel } from '../../models/ScriptObjectModel';

export const useCreateScriptObject = () => {
    const typeDefinitions: ITypeDefinitionMap = useObjectDefinitions();

    return <T extends IScriptObjectModel = IScriptObjectModel>(type: string) => {
        const newObj = createScriptObject<T>(type, typeDefinitions);
        return newObj;
    };
};
