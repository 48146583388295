import React from 'react';
import { ICollaborator } from '../../shared/motive/models/Collaborator';
import { UserIcon } from '../../core-ui/userIcon';
import { Text } from '../../core-ui/text';
import { useStyle } from '../../shared/hooks/useStyle';
import { Size } from '../../core-ui/constants/Size';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Button } from '../../core-ui/button';
import { pulse } from '../../constants/AnimationStyles';
import { IDeleteCollaboratorParams } from '../../shared/motive/hooks/useDeleteCollaborator/useDeleteCollaborator';
import { LoadingSkeleton } from '../../core-ui/loadingSkeleton/LoadingSkeleton';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { userIconSize } from '../sidebarCollaborators/SidebarCollaborators';
import {
    collaboratorStyle,
    controlStyle,
    collaboratorTextStyle,
    removeCollaboratorButton
} from './SidebarCollaboratorItem.style';

interface ISidebarCollaboratorItemProps {
    collaborator?: ICollaborator;
    deleteCollaboratorAction?: IHookProvidedCall<IDeleteCollaboratorParams>;
}
export const SidebarCollaboratorItem: React.FC<ISidebarCollaboratorItemProps> = ({
    collaborator,
    deleteCollaboratorAction
}) => {
    const fullName = collaborator ? `${collaborator.firstName} ${collaborator.lastName}` : '';
    const deleteCollab = deleteCollaboratorAction;

    const tokens = useStyle();

    return (
        <div css={collaboratorStyle(tokens)} onMouseOver={() => {}}>
            <div css={controlStyle} className={`controls`}>
                {collaborator && (
                    <Button
                        size={Size.SMALL}
                        css={removeCollaboratorButton(tokens)}
                        icon={IconTypes.TIMES}
                        onClick={() => deleteCollab?.execute({ collaborator })}
                    ></Button>
                )}
            </div>
            <div css={deleteCollab?.isLoading && pulse}>
                {collaborator ? (
                    <UserIcon size={`${userIconSize}`} name={fullName} email={collaborator.email} />
                ) : (
                    <LoadingSkeleton circle width={userIconSize} height={userIconSize}></LoadingSkeleton>
                )}
            </div>
            <div css={collaboratorTextStyle(tokens)}>
                <Text size={Size.MEDIUM}>{fullName ? fullName : <LoadingSkeleton width={50}></LoadingSkeleton>}</Text>
            </div>
        </div>
    );
};
