import React from 'react';
import { IBackupProps, Backup } from '../../components/backup';
import { useBundles } from '../../shared/motive/hooks/useBundles/useBundles';
import { useLoadDataOnMount } from '../../shared/hooks/useLoadDataOnMount';
import { useDeleteBundle } from '../../shared/motive/hooks/useDeleteBundle/useDeleteBundle';

interface IBackupContainerProps {
    projectId: string;
    children?: (props: IBackupProps) => React.ReactNode;
}

export const BackupContainer: React.FC<IBackupContainerProps> = ({ children, projectId }) => {
    const bundlesDataSource = useBundles(projectId);
    const deleteBundle = useDeleteBundle();

    useLoadDataOnMount(bundlesDataSource);

    const bundleProps: IBackupProps = {
        bundles: bundlesDataSource,
        onDeleteBundle: deleteBundle
    };

    return <>{children ? children(bundleProps) : <Backup {...bundleProps}></Backup>}</>;
};
