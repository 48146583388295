import React, { useState } from 'react';
import { useStyle } from '../../shared/hooks/useStyle';
import { ICatalog, ICatalogInfo } from '../../shared/motive/models/Catalog';
import { scriptHeadingStyle, scriptControlWrapper, catalogRowWrapper } from './ScriptCatalogs.style';
import { Button, ButtonVariant } from '../../core-ui/button';
import { IScript } from '../../shared/motive/models/Script';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Dropdown } from '../../core-ui/dropdown';
import { IDropdownOption } from '../../core-ui/dropdown/Dropdown';
import { ICreateScriptParams } from '../../shared/motive/hooks/useCreateScript/useCreateScript';
import { Collapsible } from '../../core-ui/collapse';
import { Scripts } from '../scripts';
import { CreateScriptItem } from '../createScriptItem';
import { times } from 'lodash-es';
import { ScriptItem } from '../scriptItem';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';

export interface IScriptsProps {
    scriptCatalogs: ICatalog<IScript>[];
    isLoading: boolean;
    showAll?: boolean;
    onScriptClicked: (script: IScriptObjectModel) => void;
    addScriptAction: IHookProvidedCall<ICreateScriptParams>;
}

const generateScriptCatalogOptions = (catalogs: ICatalog<IScript>[]): IDropdownOption[] => {
    const options: IDropdownOption[] = [];
    options.push({ label: 'All Catalogs', value: '' });

    catalogs.forEach(cat => {
        options.push({ label: cat.title ?? cat.id, value: cat.id });
    });

    return options;
};

const toggleCatalogsOpen = (catalog: ICatalogInfo[]) => {
    const record: Record<string, boolean> = {};

    catalog.forEach(c => {
        record[c.id] = true;
    });

    return record;
};

export const ScriptCatalogs: React.FC<IScriptsProps> = ({
    scriptCatalogs,
    onScriptClicked,
    addScriptAction,
    isLoading,
    showAll
}): React.ReactElement => {
    const tokens = useStyle();

    const [addScriptToCatalog, setAddScriptToCatalog] = useState<Record<string, boolean>>({});
    const [collapsedCats, setCollapsedCats] = useState<Record<string, boolean>>(toggleCatalogsOpen(scriptCatalogs));

    const [filter, setFilter] = useState<string>('');

    const handleAddScriptToCatalog = (id: string, state: boolean) => {
        setAddScriptToCatalog({ ...addScriptToCatalog, [id]: state });
    };

    const handleCatalogCollapse = (id: string, state: boolean) => {
        setCollapsedCats({ ...collapsedCats, [id]: state });
    };

    const onFilterChange = (filterId: string) => {
        setFilter(filterId);
    };

    const scriptsCatalog = scriptCatalogs.map(catalogItem => {
        if (filter && catalogItem.id !== filter) return <></>;

        return (
            <div
                css={catalogRowWrapper}
                key={catalogItem.id}
                onClick={() => handleCatalogCollapse(catalogItem.id, !collapsedCats[catalogItem.id])}
            >
                <div css={scriptHeadingStyle(tokens)}>
                    <span>{catalogItem.title}</span>
                    {!addScriptToCatalog[catalogItem.id] && (
                        <Button
                            onClick={() => {
                                handleCatalogCollapse(catalogItem.id, true);
                                handleAddScriptToCatalog(catalogItem.id, true);
                            }}
                            variant={ButtonVariant.CIRCULAR}
                            icon={IconTypes.PLUS}
                        ></Button>
                    )}
                </div>
                {addScriptToCatalog[catalogItem.id] && (
                    <CreateScriptItem
                        addScript={addScriptAction}
                        catalogId={catalogItem.id}
                        onScriptCreated={() => handleAddScriptToCatalog(catalogItem.id, false)}
                    ></CreateScriptItem>
                )}
                <Collapsible isShowing={collapsedCats[catalogItem.id]}>
                    <Scripts catalogItem={catalogItem} onScriptClicked={onScriptClicked}></Scripts>
                </Collapsible>
            </div>
        );
    });

    return (
        <>
            <div css={scriptControlWrapper}>
                {!showAll && (
                    <Dropdown
                        onChange={onFilterChange}
                        options={generateScriptCatalogOptions(scriptCatalogs)}
                    ></Dropdown>
                )}
            </div>
            {isLoading ? times(16, idx => <ScriptItem key={idx}></ScriptItem>) : scriptsCatalog}
        </>
    );
};
