import React from 'react';
import { IFieldEditorProps } from '..';
import { FieldTypes } from '../../../shared/motive/models/ScriptObjectModel';
import { IScript } from '../../../shared/motive/models/Script';
import {
    IScriptFrameAction,
    ScriptActionType,
    IScriptEditorAddDefinedVariablesAction
} from '../../../shared/motive/reducers/ScriptEditorReducers';
import { FieldVariableDropdown } from '../fieldVariableEditor/FieldVariableEditor';
import { setFieldVariable, createNewFieldVariable } from '../../../util/ScriptDynamicsUtil';
import { VariableSelectorEditorContainer } from '../../variableSelectorEditorContainer';
import { useScriptAndGlobalVariables } from '../../../shared/motive/hooks/useScriptAndGlobalVariables';
import { getSmartName } from '../../../util/ObjectDefinitionsUtils';
import { useTypeDefinitions } from '../../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { useCurrentLanguageSettings } from '../../../shared/motive/stores/editorLocale/EditorLocale';
import { useDispatch, useSelector } from 'react-redux';
import {
    SelectScriptEditorCurrentScript,
    SelectScriptEditorFrame,
    SelectScriptEditorSelectedFrameId
} from '../../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';

export interface IAdaptiveFieldEditorProps extends IFieldEditorProps<FieldTypes> {
    variableId?: string;
    dynamicFieldName: string;
    onCreateNew?: () => void;
    showSelector?: boolean;
}

export const AdaptiveFieldEditor: React.FC<IAdaptiveFieldEditorProps> = ({
    fieldDefinition,
    dynamicFieldName,
    variableId,
    parentObjectEditorProps,
    showSelector
}: IAdaptiveFieldEditorProps): React.ReactElement => {
    const frame = useSelector(SelectScriptEditorFrame(useSelector(SelectScriptEditorSelectedFrameId)));
    const varMap = useScriptAndGlobalVariables();
    const typeDefs = useTypeDefinitions();
    const language = useCurrentLanguageSettings();
    const script = useSelector(SelectScriptEditorCurrentScript);
    const variable = variableId ? varMap[variableId] : undefined;

    const scriptDispatch = useDispatch();

    const onVariableChange = (variableId: string | undefined) => {
        if (parentObjectEditorProps && parentObjectEditorProps.value && frame) {
            if (!variableId) {
                setFieldVariable(frame, parentObjectEditorProps.value.id, fieldDefinition.name, undefined);
            } else {
                const newFrame = setFieldVariable(
                    frame,
                    parentObjectEditorProps.value.id,
                    dynamicFieldName,
                    variableId
                );

                const action: IScriptFrameAction = {
                    type: ScriptActionType.FRAME_UPDATE,
                    frame: newFrame
                };

                scriptDispatch(action);
            }
        }
    };

    const onCreateNew = (varName: string) => {
        if (script && parentObjectEditorProps.value) {
            if (parentObjectEditorProps && parentObjectEditorProps.value && frame) {
                const newVar = createNewFieldVariable(
                    script,
                    frame,
                    varName,
                    fieldDefinition,
                    parentObjectEditorProps.value.id,
                    fieldDefinition.name,
                    scriptDispatch
                );

                onVariableChange(newVar.id);

                const scriptDispatchAction: IScriptEditorAddDefinedVariablesAction = {
                    type: ScriptActionType.ADD_DEFINED_VARIABLES_TO_FRAME,
                    targetFrameId: frame.id,
                    variableIds: [newVar.id]
                };

                scriptDispatch(scriptDispatchAction);
            }
        }
    };

    const defaultVarName = parentObjectEditorProps.value
        ? `${getSmartName(parentObjectEditorProps.value, typeDefs, language)} ${fieldDefinition.editorInfo?.label ??
              fieldDefinition.name}`
        : `New ${fieldDefinition.name}`;

    return (
        <>
            <FieldVariableDropdown
                onChange={onVariableChange}
                valueDefinition={fieldDefinition}
                variableId={variableId}
                script={script as IScript}
                onCreateNew={onCreateNew}
                defaultVariableName={defaultVarName}
            />
            {frame && script && variable && showSelector && (
                <VariableSelectorEditorContainer frame={frame} script={script} variable={variable} />
            )}
        </>
    );
};
