/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
    previewAudioPlayerStyle,
    ttsWrapperStyle,
    ttsButtonControlStyle,
    ttsButtonStyle,
    ttsSpacingStyle,
    ttsVoiceOptions,
    previewLayout
} from './TextToSpeechZone.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { TextArea } from '../../core-ui/textArea';
import { useTextToSpeechPreview } from '../../shared/motive/hooks/useTextToSpeechPreview/useTextToSpeechPreview';
import { useCreateTextToSpeechMedia } from '../../shared/motive/hooks/useCreateTextToSpeechMedia/useCreateTextToSpeechMedia';
import { ITextToSpeechRequest, ITextToSpeechCreateMediaRequest } from '../../shared/motive/models/TextToSpeechRequest';
import { IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { Logger } from '../../util/logger';
import { InputField } from '../../core-ui/inputField';
import { Button, ButtonVariant } from '../../core-ui/button';
import { Dropdown, IDropdownOption } from '../../core-ui/dropdown';
import { useTextToSpeechVoices } from '../../shared/motive/hooks/useTextToSpeechVoices/useTextToSpeechVoices';
import { useLoadDataOnMount } from '../../shared/hooks/useLoadDataOnMount';
import { IPollyVoice } from '../../redux/spaceKeyed/textToSpeech/TextToSpeechReducer';
import { uniqBy, sortBy } from 'lodash-es';
import { Heading } from '../../core-ui/heading';
import { Divider } from '@chakra-ui/core';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { rotating } from '../../constants/AnimationStyles';
import { getErrorStatusCode } from '../../shared/motive/networking/HttpClient';
import { MediaSourceConflictModal } from '../../containers/mediaSourceConflictModal/MediaSourceConflictModal';
import { useTranslation } from 'react-i18next';

interface ITextToSpeechZoneProps {
    onSelectItem?: (item: IHostedMediaSource) => void;
}

const generatePollyLanguagesOptions = (voices: IPollyVoice[], voiceFilter: string): IDropdownOption[] => {
    const dropOptions: IDropdownOption[] = [];

    voices.forEach(voice => {
        if (voice.supportedEngines.some(c => c === 'standard')) {
            if (!voiceFilter || voiceFilter === voice.languageCode) {
                dropOptions.push({ label: voice.name, value: voice.id });
            }
        }
    });
    return dropOptions;
};

const generateVoiceFilterOptions = (voices: IPollyVoice[]) => {
    const unique = uniqBy(voices, v => v.languageCode);

    const voiceGroup = sortBy(unique, u => u.languageCode.split('-')[0] !== 'en');

    const dropOptions: IDropdownOption[] = [];

    voiceGroup.forEach(u => {
        const dropOption: IDropdownOption = {
            label: u.languageName,
            value: u.languageCode
        };
        dropOptions.push(dropOption);
    });

    return dropOptions;
};

const getSmartFileName = (text: string) => {
    const parts = text.split(' ');

    let _fileName = '';

    for (let i = 0; i < parts.length; i++) {
        const part = parts[i];

        if (i > 0) {
            _fileName += ' ';
        }

        _fileName += part;

        if (_fileName.length > 20) {
            break;
        }
    }

    return _fileName + '.ogg';
};

export const TextToSpeechZone: React.FC<ITextToSpeechZoneProps> = props => {
    const token = useStyle();
    const { t, i18n } = useTranslation();
    const language = i18n.language;

    const { execute: previewCall } = useTextToSpeechPreview();
    const { execute: createMediaCall, error, isLoading: isCreateFileLoading } = useCreateTextToSpeechMedia();
    const voices = useTextToSpeechVoices();

    useLoadDataOnMount(voices);

    const [text, setText] = useState('');
    const [fileName, setFileName] = useState('');
    const [voicesFilter, setVoicesFilter] = useState(sessionStorage.getItem('tts.language') ?? 'en-US');
    const [selectedVoice, setSelectedVoice] = useState<string | undefined>(
        sessionStorage.getItem('tts.voice') ?? undefined
    );

    const [previewUrl, setPreviewUrl] = useState('');
    const audioRef = useRef<HTMLAudioElement>(null);
    const [isConflict, setIsConflict] = useState(false);

    const handleFilterChange = (voiceFilter: string) => {
        setPreviewUrl('');

        setVoicesFilter(voiceFilter);

        const lv = voices.data.find(v => v.languageCode === voiceFilter);

        setSelectedVoice(lv?.id);

        sessionStorage.setItem('tts.language', voiceFilter);
    };

    const handleSelectedVoicechange = (voice: string) => {
        setPreviewUrl('');

        setSelectedVoice(voice);

        sessionStorage.setItem('tts.voice', voice);
    };

    const handlePreview = async () => {
        var req: ITextToSpeechRequest = {
            text: text,
            cultureCode: language,
            voice: selectedVoice ?? '',
            format: 'ogg_vorbis'
        };

        var result = await previewCall(req);

        if (result?.url) {
            setPreviewUrl(result.url);
        }
    };

    useEffect(() => {
        if (audioRef && audioRef.current && previewUrl) {
            audioRef.current.pause();
            audioRef.current.load();
            audioRef.current.play();
        }
    }, [previewUrl]);

    useEffect(() => {
        if (error) {
            const status = getErrorStatusCode(error);

            if (status === 409) {
                Logger.error('conflict!');
                setIsConflict(true);
            } else {
                Logger.error('Error converting text-to-speech');
            }
        }
    }, [error]);

    const handleTextUpdated = (text: string) => {
        setPreviewUrl('');
        setText(text);
        setFileName(getSmartFileName(text));
    };

    const handleCreateMedia = async (overwrite: boolean = false) => {
        // setIsConflict(false);

        var req: ITextToSpeechCreateMediaRequest = {
            text: text,
            cultureCode: language,
            fileName: fileName.endsWith('.ogg') ? fileName : `${fileName}.ogg`,
            voice: selectedVoice ?? '',
            format: 'ogg_vorbis',
            overwrite: overwrite
        };

        var result = await createMediaCall(req);

        if (result) {
            props.onSelectItem?.(result);
        }
    };

    const handleOverwrite = async () => {
        setIsConflict(false);

        await handleCreateMedia(true);
    };

    const handleRename = () => {};

    const handleCloseConflictModal = () => {
        setIsConflict(false);
    };

    const voiceFilterOpts = useMemo(() => {
        return generateVoiceFilterOptions(voices.data);
    }, [voices]);

    const voicesOpts = useMemo(() => {
        const opts = generatePollyLanguagesOptions(voices.data, voicesFilter);

        return opts;
    }, [voices, voicesFilter]);

    return (
        <div css={ttsWrapperStyle(token)}>
            <Heading>{t('textToSpeech')}</Heading>
            <Divider />
            <div css={ttsVoiceOptions}>
                {!voices.isLoading && (
                    <>
                        <Dropdown
                            css={ttsSpacingStyle(token)}
                            options={voiceFilterOpts}
                            value={voicesFilter}
                            onChange={handleFilterChange}
                        ></Dropdown>
                        <Dropdown
                            css={ttsSpacingStyle(token)}
                            options={voicesOpts}
                            value={selectedVoice}
                            onChange={handleSelectedVoicechange}
                        ></Dropdown>
                    </>
                )}
            </div>
            <TextArea
                css={ttsSpacingStyle(token)}
                placeholder={t('writeText')}
                value={text}
                onChange={handleTextUpdated}
            ></TextArea>
            <div>
                <div css={previewLayout(token)}>
                    <Button
                        variant={ButtonVariant.SOLID}
                        disabled={text.length === 0 || previewUrl.length > 0}
                        css={ttsButtonStyle(token)}
                        onClick={handlePreview}
                    >
                        {t('preview')}
                    </Button>
                    <audio controls css={previewAudioPlayerStyle(token)} ref={audioRef}>
                        <source src={previewUrl} type={'audio/ogg'} />
                    </audio>
                </div>
                {/* <>{props.uploadStatus?.message}</> */}
                <div css={ttsButtonControlStyle(token)}>
                    <InputField placeholder={t('fileName')} value={fileName} onChange={setFileName}></InputField>
                    <Button
                        variant={ButtonVariant.SOLID}
                        css={ttsButtonStyle(token)}
                        onClick={() => handleCreateMedia(false)}
                        disabled={isCreateFileLoading}
                        icon={isCreateFileLoading ? IconTypes.LOAD_SPINNER : undefined}
                        iconStyle={rotating}
                    >
                        {t('save')}
                    </Button>
                </div>

                <MediaSourceConflictModal
                    isOpen={isConflict}
                    onOverwrite={handleOverwrite}
                    onCloseModal={handleCloseConflictModal}
                    onRename={handleRename}
                />
            </div>
        </div>
    );
};
