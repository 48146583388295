import { deleteBundle } from '../../networking/BundleService';
import { useCurrentSpace } from '../useCurrentSpace';
import { useCurrentProject } from '../useCurrentProject';
import { useDispatch } from 'react-redux';
import { IBundleEntity } from '../../../../redux/spaceKeyed/bundle/BundleReducer';

export const useDeleteBundle = () => {
    const [currentSpace] = useCurrentSpace();
    const [currentProject] = useCurrentProject();

    const bundleDispatch = useDispatch();

    const call = async (bundleEntity: IBundleEntity) => {
        if (bundleEntity.status.loadingState === 'loading') return;

        await deleteBundle(currentSpace, currentProject, bundleEntity.bundle, bundleDispatch);
    };

    return call;
};
