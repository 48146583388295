import React from 'react';
import { Collapsible } from '../../core-ui/collapse';
import { useStyle } from '../../shared/hooks/useStyle';
import {
    scenarioNodeRowStyle,
    rowMiddleItemStyle1,
    numberInputStyle,
    scenarioMiniItemStyle,
    dynamicsWorkAreaNarrow,
    nodeLineStyle,
    middleContentStyle,
    deleteButtonIconStyle,
    collapsibleLayoutStyle,
    conditionArea,
    rootNodeStyle,
    scenarioNameStyle,
    topRowChildStyle,
    bottomRowChildStyle,
    dynamicIconsStyle,
    verticalDividerParent,
    verticalDividerStyle,
    rowRightContent
} from './DynamicRowNode.style';
import {
    ISelectorBranch,
    ISelectorNode,
    ISelectorTree,
    SelectorBranchProcessingOrder
} from '../../shared/motive/models/Script';
import { Size } from '../../core-ui/constants/Size';
import { countTotalConditionsRecursive } from '../../util/ScriptUtils';
import { useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo';
import { Icon } from '../../core-ui/icon';
import { EditableText } from '../../core-ui/editableText';
import { InputField } from '../../core-ui/inputField';
import { Heading } from '../../core-ui/heading';
import { getConditionIcons } from '../../util/IconUtils';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { DynamicRowBase } from '../../components/dynamicRowBase';
import {
    ILeftColProps,
    LEFT_IDENTIFIER_SIZE,
    LINE_WIDTH,
    MARGIN_OFFSET,
    NODE_ROW_HEIGHT
} from '../../components/dynamicRowBase/DynamicRowBase';
import { DroppableScenarioConditionContainer } from '../../containers/droppableScenarioConditionContainer/DroppableScenarioConditionContainer';
import { VariableSelectorsContainer } from '../../containers/variableSelectorsContainer';
import { ConditionContainer } from '../../containers/conditionContainer/ConditionContainer';
import { IRecursiveDataProps } from '../../containers/frameInspectorDynamicsContainer';
import { branchButtonStyle, branchLineStyle } from '../dynamicRowBranch/DynamicRowBranch.style';
import { Button } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Text } from '../../core-ui/text';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

export interface IDynamicRowNodeProps extends IRecursiveDataProps {
    scenarioNode?: ISelectorNode;
    scenarioNodeIndex?: number;
    parentBranch?: ISelectorBranch;
    parentBranchPath?: string;
    selectorTree?: ISelectorTree | null;
    isSelectorTreeRoot?: boolean;
    onDeleteBranch?: () => void;
    dragHandler?: DraggableProvidedDragHandleProps | null;
}

export const DynamicRowNode: React.FC<IDynamicRowNodeProps> = ({
    indexDepth,
    scenarioNode,
    scenarioNodeIndex,
    parentBranch,
    parentBranchPath,
    selectorTree,
    isSelectorTreeRoot,
    selected,
    setSelected,
    onChange,
    onClickCreateBranch,
    subFramePath,
    frame,
    script,
    onLeftColWidthChange,
    onDeleteBranch,
    dragHandler
}) => {
    const token = useStyle();
    const { t } = useTranslation();
    const objectDefinitions = useObjectDefinitions();

    const isShowingCollapse = !!(selected && selected === subFramePath);

    const handleClickScenarioRow = () => {
        setSelected(isShowingCollapse ? undefined : subFramePath);
    };

    const selectors = (isSelectorTreeRoot ? selectorTree?.defaultSelectors : scenarioNode?.selectors) ?? [];
    // Not all selectors are dynamic value selectors, however we can likely assume that any
    // variable selector with a variable reference and more than one key has a setting.
    const filledSelectorsCount =
        selectors?.filter(selector => selector.variableReference && Object.keys(selector).length > 1).length ?? 0;

    let conditionsCount = 0;
    if (scenarioNode?.condition && scenarioNode.condition.id) {
        conditionsCount = countTotalConditionsRecursive(scenarioNode.condition);
    }
    const conditionIcon = getConditionIcons(objectDefinitions, scenarioNode?.condition);

    const handlePriorityOrWeightChange = (value: string) => {
        if (!scenarioNode) {
            return;
        }
        const newScenario: ISelectorNode = { ...scenarioNode };
        if (parentBranch?.processingOrder?.name === 'priority') {
            newScenario.priority = +value;
        } else if (parentBranch?.processingOrder?.name === 'random') {
            newScenario.weight = +value;
        }
        onChange(subFramePath, newScenario);
    };

    const handleNameChange = (name: string) => {
        if (!scenarioNode) {
            return;
        }
        onChange(`${subFramePath}.name`, name);
    };

    const handleClickCreateBranch = () => {
        onClickCreateBranch(scenarioNode, subFramePath, 0);
    };

    const handleDeleteNode = () => {
        const newArr = [...(parentBranch?.nodes as ISelectorNode[])];
        newArr?.splice(scenarioNodeIndex as number, 1);

        // if no nodes are left, delete branch.
        if (newArr.length > 0) {
            onChange(`${parentBranchPath}`, { ...parentBranch, nodes: newArr });
        } else {
            onDeleteBranch && onDeleteBranch();
        }
    };

    const priorityOrWeight =
        parentBranch?.processingOrder?.name === SelectorBranchProcessingOrder.Priority
            ? scenarioNode?.priority
            : parentBranch?.processingOrder?.name === SelectorBranchProcessingOrder.Random
            ? scenarioNode?.weight
            : 0;

    let leftColProps: ILeftColProps = {
        children: <div css={scenarioMiniItemStyle}></div>,
        connectorNode: (
            <svg height={NODE_ROW_HEIGHT} width={LINE_WIDTH}>
                <line
                    x1={0}
                    y1={NODE_ROW_HEIGHT / 2 + 1.5}
                    x2={LINE_WIDTH}
                    y2={NODE_ROW_HEIGHT / 2 + 1.5}
                    css={nodeLineStyle}
                ></line>
            </svg>
        )
    };

    if (onLeftColWidthChange) {
        leftColProps = {
            ...leftColProps,
            onWidthChange: onLeftColWidthChange
        };
    }

    const subFramePathToCondition = `${subFramePath}.condition`;

    const conditionOffset = indexDepth * 2 * MARGIN_OFFSET + LINE_WIDTH + LEFT_IDENTIFIER_SIZE + 12;

    return (
        <>
            <DroppableScenarioConditionContainer
                scenarioNode={scenarioNode}
                subFramePath={subFramePathToCondition}
                frameId={frame.id}
            >
                <div>
                    <div {...dragHandler} style={{ outline: 'none' }}>
                        <DynamicRowBase
                            rowHeight={NODE_ROW_HEIGHT}
                            onClick={handleClickScenarioRow}
                            leftColProps={leftColProps}
                            css={scenarioNodeRowStyle(token)}
                            onClickPlus={handleClickCreateBranch}
                            lineStyle={branchLineStyle}
                            buttonStyle={branchButtonStyle}
                            offset={2 * indexDepth}
                        >
                            <div css={[middleContentStyle, isSelectorTreeRoot && rootNodeStyle]}>
                                <div css={rowMiddleItemStyle1}>
                                    <div css={topRowChildStyle}>
                                        {scenarioNode ? (
                                            <EditableText
                                                defaultValue={scenarioNode.name}
                                                onEditComplete={handleNameChange}
                                                inputStyle={scenarioNameStyle}
                                                textStyle={scenarioNameStyle}
                                                size={Size.MEDIUM}
                                            />
                                        ) : (
                                            <span>{t('defaults')}</span>
                                        )}
                                    </div>
                                    {(conditionsCount > 0 || filledSelectorsCount > 0) && (
                                        <div css={[bottomRowChildStyle, verticalDividerParent]}>
                                            {conditionsCount > 0 ? (
                                                <>
                                                    {conditionsCount > 1 && conditionsCount}
                                                    <Icon
                                                        faIcon={conditionIcon.icon as IconName}
                                                        css={dynamicIconsStyle}
                                                    />
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            {filledSelectorsCount > 0 ? (
                                                <>
                                                    {conditionsCount > 0 && <div css={verticalDividerStyle} />}
                                                    <Icon icon={IconTypes.ATOM} css={dynamicIconsStyle}></Icon>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div css={rowRightContent}>
                                    {parentBranch?.processingOrder &&
                                        parentBranch.processingOrder.name !==
                                            SelectorBranchProcessingOrder.Sequence && (
                                            <InputField
                                                css={numberInputStyle}
                                                onChange={handlePriorityOrWeightChange}
                                                size={Size.MEDIUM}
                                                type="number"
                                                defaultValue={priorityOrWeight ?? 0}
                                            />
                                        )}
                                    <Button
                                        icon={IconTypes.DELETE}
                                        size={Size.MEDIUM}
                                        css={deleteButtonIconStyle}
                                        onClick={parentBranch ? handleDeleteNode : undefined}
                                    ></Button>
                                </div>
                            </div>
                        </DynamicRowBase>
                    </div>
                    <Collapsible isShowing={isShowingCollapse}>
                        {!isSelectorTreeRoot && scenarioNode && (
                            <div css={collapsibleLayoutStyle}>
                                <div css={conditionArea(conditionOffset)}>
                                    <div css={dynamicsWorkAreaNarrow}>
                                        <Heading size={Size.SMALL}>{t('conditions')}</Heading>

                                        <ConditionContainer
                                            condition={scenarioNode.condition}
                                            subFrameConditionPath={subFramePathToCondition}
                                            frame={frame}
                                            dropId={scenarioNode.id}
                                            script={script}
                                        />
                                    </div>
                                    <div css={dynamicsWorkAreaNarrow}>
                                        <Text size={Size.LARGER}>
                                            {t('variableWithCount', {
                                                count: frame.definedVariables?.length ?? 0
                                            })}
                                        </Text>
                                        <VariableSelectorsContainer
                                            frame={frame}
                                            script={script}
                                            onChange={onChange}
                                            selectors={scenarioNode.selectors}
                                            subFramePath={`${subFramePath}.selectors`}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {isSelectorTreeRoot && (
                            <div css={collapsibleLayoutStyle}>
                                <div css={conditionArea(conditionOffset)}>
                                    <div css={dynamicsWorkAreaNarrow}>
                                        <Text size={Size.LARGER}>
                                            {t('variableWithCount', {
                                                count: frame.definedVariables?.length ?? 0
                                            })}
                                        </Text>
                                        <VariableSelectorsContainer
                                            frame={frame}
                                            script={script}
                                            onChange={onChange}
                                            selectors={selectorTree?.defaultSelectors}
                                            subFramePath={`${subFramePath}.defaultSelectors`}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </Collapsible>
                </div>
            </DroppableScenarioConditionContainer>
        </>
    );
};
