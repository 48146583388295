import React from 'react';
import { Icon } from '../../core-ui/icon';
import { rotateChevron } from '../../constants/AnimationStyles';
import { Size, IconSize } from '../../core-ui/constants/Size';
import { IconTypes } from '../../core-ui/constants/IconTypes';

interface IRotateChevronProps {
    isPointingDown: boolean;
    size?: IconSize;
    className?: string;
}
export const RotateChevron: React.FC<IRotateChevronProps> = ({ isPointingDown, size, className }) => {
    return (
        <>
            <Icon
                className={className}
                css={[rotateChevron(isPointingDown)]}
                size={size ?? Size.SMALLER}
                icon={IconTypes.CHEVRON_DOWN}
            />
        </>
    );
};
