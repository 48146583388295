import { GetSpaces } from '../../networking/SpacesService';
import { ISpace } from '../../models/Space';
import { IHookProvidedDataSource } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallForDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { useSelector, useDispatch } from 'react-redux';
import { selectSpaces } from '../../../../redux/spaces/SpaceSelectors';

export const useSpaces = (): IHookProvidedDataSource<ISpace[]> => {
    const state = useSelector(selectSpaces);
    const dispatch = useDispatch();

    const { triggerCall, ...rest } = useNetworkCallForDataSource(state);

    const call = async () => {
        await triggerCall(GetSpaces(dispatch));
    };

    return { data: state, load: call, ...rest };
};
