import { useCurrentSpace } from '../useCurrentSpace';
import { IEnumDefinitionItem } from '../../models/EnumDefinition';
import { createEnumItemNetworkCall } from '../../networking/EnumDefinitionService';
import { uniqueMotiveId } from '../../../../util/MotiveUtils';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useDispatch } from 'react-redux';

export interface IUseCreateEnumDefinitionItemParams {
    enumItemTitle: string;
    enumId: string;
}

export const useCreateEnumDefinitionItem = (): IHookProvidedCall<
    IUseCreateEnumDefinitionItemParams,
    IEnumDefinitionItem | undefined
> => {
    const [currentSpace] = useCurrentSpace();
    const dispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const createEnumDefinitionItem = async (
        params: IUseCreateEnumDefinitionItemParams
    ): Promise<IEnumDefinitionItem | undefined> => {
        if (!params.enumItemTitle) {
            throw new Error('Enum Item Title cannot be empty');
        }

        const eDefItem: IEnumDefinitionItem = {
            id: uniqueMotiveId(),
            name: params.enumItemTitle,
            label: params.enumItemTitle,
            space: currentSpace
        };

        const newEnum = await triggerCall(
            createEnumItemNetworkCall(currentSpace, eDefItem, params.enumId, getCancelableDispatch(dispatch))
        );

        return newEnum;
    };

    return {
        execute: createEnumDefinitionItem,
        ...rest
    };
};
