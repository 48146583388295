import React, { createContext, useState, Dispatch, SetStateAction } from 'react';
import { LocalizationKeys } from '../../../../localization/LocalizationKeys';
import { LocalizationText } from '../../../../localization/Localization';
import { EditorCultureCode } from '../../../../constants/EditorCultureCode';

interface ILocaleContextValue {
    language: EditorCultureCode;
    setLanguage: Dispatch<SetStateAction<EditorCultureCode>>;
    getText: (key: LocalizationKeys) => string | undefined;
}
export const LocaleContext = createContext<ILocaleContextValue>({
    language: EditorCultureCode.ENGLISH,
    setLanguage: () => undefined,
    getText: () => undefined
});

export const LocaleStore: React.FC = ({ children }): React.ReactElement => {
    const [language, setLanguage] = useState<EditorCultureCode>(EditorCultureCode.ENGLISH);

    const getText = (key: LocalizationKeys) => LocalizationText(language, key);

    return (
        <LocaleContext.Provider value={{ language: language, setLanguage: setLanguage, getText: getText }}>
            {children}
        </LocaleContext.Provider>
    );
};
