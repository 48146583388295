import React from 'react';
import { outerStyle, objectEditorTitleStyleStyle, toolsParentContainerStyle } from './ObjectEditorBox.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { Button, ButtonVariant } from '../../core-ui/button';
import { Size } from '../../core-ui/constants/Size';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Text } from '../../core-ui/text';

export interface IObjectEditorBoxTool {
    key: string;
    icon: IconTypes;
    onClick: () => void;
}

interface IObjectEditorBoxProps {
    title?: string | undefined;
    id?: string;
    onClickDelete?: () => void;
    depth: number;
    tools?: IObjectEditorBoxTool[];
}

export const ObjectEditorBox: React.FC<IObjectEditorBoxProps> = ({
    tools,
    title,
    id,
    onClickDelete,
    children,
    depth
}) => {
    const tokens = useStyle();
    return (
        <>
            <ObjectEditorBoxSimple depth={depth}>
                <div css={toolsParentContainerStyle(tokens)}>
                    {tools &&
                        tools.map(t => {
                            return (
                                <Button key={t.key} variant={ButtonVariant.HOLLOW} icon={t.icon} onClick={t.onClick} />
                            );
                        })}
                    {onClickDelete && (
                        <Button variant={ButtonVariant.HOLLOW} icon={IconTypes.TIMES} onClick={onClickDelete} />
                    )}
                </div>
                {title && (
                    <>
                        <Text size={Size.LARGE} css={objectEditorTitleStyleStyle}>
                            {title}
                        </Text>
                        <Text size={Size.MEDIUM}>{id}</Text>
                    </>
                )}
                {children}
            </ObjectEditorBoxSimple>
        </>
    );
};

interface IObjectEditorBoxSimpleProps {
    className?: string;
    depth: number;
}

export const ObjectEditorBoxSimple: React.FC<IObjectEditorBoxSimpleProps> = ({ children, className, depth }) => {
    const tokens = useStyle();
    return (
        <>
            <div css={outerStyle(tokens, depth)} className={className}>
                {children}
            </div>
        </>
    );
};
