import { useCurrentSpace } from '../useCurrentSpace';
import { getSystemName } from '../../../../util/MotiveUtils';
import { IProjectConfig } from '../../models/ProjectConfig';
import { ICatalogInfo } from '../../models/Catalog';
import { createProjectConfigNetworkCall } from '../../networking/ProjectConfigService';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useDispatch } from 'react-redux';

export interface ICreateProjectParams {
    title: string;
    catalogsToImport: string[];
}
export const useCreateProject = (): IHookProvidedCall<ICreateProjectParams, IProjectConfig | undefined> => {
    const [currentSpace] = useCurrentSpace();

    const { triggerCall, ...rest } = useNetworkCallWithoutDataSource();

    const dispatch = useDispatch();

    const addProjectCatalogs = async (params: ICreateProjectParams) => {
        var name = getSystemName(params.title);

        var newConfig: IProjectConfig = {
            id: '',
            name: name,
            title: params.title,
            catalogs: params.catalogsToImport.map(c => {
                const cat: ICatalogInfo = {
                    objectType: '',
                    id: c,
                    name: ''
                };
                return cat;
            })
        };

        return await triggerCall(createProjectConfigNetworkCall(currentSpace, newConfig, dispatch));
    };

    return { execute: addProjectCatalogs, ...rest };
};
