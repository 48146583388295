import { IUseCreateEnumDefinitionParams } from '../../shared/motive/hooks/useCreateEnumDefinition/useCreateEnumDefinition';
import { IEnumDefinition } from '../../shared/motive/models/EnumDefinition';
import { useState } from 'react';
import { EditableText } from '../../core-ui/editableText';
import { Size } from '../../core-ui/constants/Size';
import React from 'react';
import { editableTextWrapperStyle, editableTextTextStyle } from './CreateEnumDefinition.style';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useTranslation } from 'react-i18next';

interface ICreateEnumDefinitionProps {
    createEnumCall: IHookProvidedCall<IUseCreateEnumDefinitionParams, IEnumDefinition | undefined>;
}

export const CreateEnumDefinition: React.FC<ICreateEnumDefinitionProps> = ({ createEnumCall: onCreateEnum }) => {
    const [newEnumName, setNewEnumName] = useState<string>('');

    const { t } = useTranslation();

    const handleCreate = () => {
        if (!newEnumName) {
            return;
        }

        onCreateEnum.execute({ enumTitle: newEnumName }).then(() => setNewEnumName(''));
    };

    return (
        <>
            <EditableText
                wrapperStyle={editableTextWrapperStyle}
                textStyle={editableTextTextStyle}
                inputStyle={editableTextTextStyle}
                size={Size.MEDIUM}
                placeholder={newEnumName ? newEnumName : t('newEnumName')}
                onEditComplete={handleCreate}
                onChange={newName => setNewEnumName(newName.trim())}
            />
        </>
    );
};
