import { css } from '@emotion/core';

export const inlineBlockStyle = css`
    display: inline-block;
    padding-left: 10px;
`;

export const outerMostContainerStyle = css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-x: hidden;
    padding: 0px;
`;

export const variablesHeaderContainer = css`
    width: 100%;
    padding: 12px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
        cursor: pointer;
        background-color: #d7d9de;
    }
`;

export const iconStyle = css`
    font-weight: 600;
    padding-right: 12px;
`;
