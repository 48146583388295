import css from '@emotion/css';
import { SIDEBAR_MIN_WIDTH } from '../../containers/sidebarContainer/SidebarContainer.style';

const TOP_BOT_PADDING = 8;

export const constWidthContainer = (isInSmallerRange: boolean, maxHeight?: number) => css`
    align-self: center;
    width: 20vw;

    min-width: ${SIDEBAR_MIN_WIDTH}px;
    max-width: 200px;
    ${isInSmallerRange &&
        css`
            min-width: unset;
            width: calc(${maxHeight}px - ${TOP_BOT_PADDING}px);
            margin-left: 18px;
            margin-right: 18px;
        `}
`;

export const outerParentRatioStyle = (isInSmallerRange: boolean) => css`
    position: relative;
    height: 0;
    background: transparent;
    padding-top: 27.17%;
    align-self: center;

    ${isInSmallerRange &&
        css`
            padding-top: 100%;
        `}
`;

export const storyFlowLogoStyle = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;
