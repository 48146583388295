import React from 'react';
import { useStyle } from '../../shared/hooks/useStyle';
import {
    primaryBarStyle,
    secondaryBarStyle,
    tertiaryBarStyle,
    baseBarStyle,
    resourceBarHeaderStyle,
    conditionHeaderStyle,
    variableHeaderStyle,
    transparentBarStyle
} from './Bar.style';
import { IWithDragHandle } from '../../util/DragUtils';

export enum BarVariant {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    TERTIARY = 'TERTIARY',
    RESOURCEHEADER = 'RESOURCEHEADER',
    CONDITIONHEADER = 'CONDITIONHEADER',
    VARIABLEHEADER = 'VARIABLEHEADER',
    TRANSPARENT = 'TRANSPARENT'
}

const barStyleMap = {
    [BarVariant.PRIMARY]: primaryBarStyle,
    [BarVariant.SECONDARY]: secondaryBarStyle,
    [BarVariant.TERTIARY]: tertiaryBarStyle,
    [BarVariant.RESOURCEHEADER]: resourceBarHeaderStyle,
    [BarVariant.CONDITIONHEADER]: conditionHeaderStyle,
    [BarVariant.VARIABLEHEADER]: variableHeaderStyle,
    [BarVariant.TRANSPARENT]: transparentBarStyle
};

export interface IBarProps extends IWithDragHandle {
    variant?: BarVariant;
    className?: string;
    onClick?: () => void;
    onDoubleClick?: () => void;
    setVisibleRef?: (instance: HTMLDivElement | null) => void;
    onMouseDownCapture?: (event: React.MouseEvent) => void;
    onMouseUpCapture?: (event: React.MouseEvent) => void;
}

export const Bar: React.FC<IBarProps> = ({
    children,
    variant = BarVariant.PRIMARY,
    className,
    onClick,
    onDoubleClick,
    setVisibleRef,
    onMouseDownCapture,
    onMouseUpCapture,
    dragHandleProps
}): React.ReactElement => {
    const styleToken = useStyle();
    return (
        <div
            {...dragHandleProps}
            ref={setVisibleRef}
            onDoubleClick={onDoubleClick}
            onClick={onClick}
            css={[baseBarStyle(), barStyleMap[variant](styleToken)]}
            className={className}
            onMouseDownCapture={onMouseDownCapture}
            onMouseUpCapture={onMouseUpCapture}
        >
            {children}
        </div>
    );
};
