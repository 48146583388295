import { useContext, Context } from 'react';

export function useStore<T>(context: Context<T>): NonNullable<T> {
    const contextValue = useContext(context);

    if (contextValue === undefined) {
        throw new Error('useStore must be used within the Store you are trying to access');
    }

    return contextValue as NonNullable<T>;
}
