import { useCurrentSpace } from '../useCurrentSpace';
import { updateEnumItemNetworkCall } from '../../networking/EnumDefinitionService';
import { IEnumDefinitionItem } from '../../models/EnumDefinition';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { useDispatch } from 'react-redux';

export interface IUpdateEnumDefinitionItemParams {
    enumId: string;
    enumItemId: string;
    updatedItem: IEnumDefinitionItem;
}

export const useUpdateEnumDefinitionItem = (): IHookProvidedCall<IUpdateEnumDefinitionItemParams> => {
    const [currentSpace] = useCurrentSpace();

    const dispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const updateEnumDefinitionItem = async (params: IUpdateEnumDefinitionItemParams) => {
        await triggerCall(
            updateEnumItemNetworkCall(
                currentSpace,
                params.enumId,
                params.enumItemId,
                params.updatedItem,
                getCancelableDispatch(dispatch)
            )
        );
    };

    return {
        execute: updateEnumDefinitionItem,
        ...rest
    };
};
