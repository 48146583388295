import React from 'react';
import { Switch } from '@chakra-ui/core';
import { ToggleSize } from '../constants/Size';
import { baseToggleStyle } from './Toggle.style';
import { getChakraSize } from '../utils/SizeMapping';

export interface IToggleProps {
    className?: string;
    size?: ToggleSize;
    color?: string;
    value?: boolean;
    onClick?: () => void;
    isDisabled?: boolean;
    id?: string;
}

export const Toggle: React.FC<IToggleProps> = ({ children, size, onClick, value, ...rest }): React.ReactElement => {
    return (
        <Switch
            {...rest}
            onChange={e => {
                e.stopPropagation();
                onClick && onClick();
            }}
            css={onClick && baseToggleStyle}
            size={getChakraSize(size) as 'sm' | 'md' | 'lg'}
            color={'green'}
            isChecked={value}
        >
            {children}
        </Switch>
    );
};
