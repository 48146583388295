import React, { useState } from 'react';
import { ICatalog, ICatalogInfo } from '../../shared/motive/models/Catalog';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { RowItem } from '../../core-ui/rowItem';
import { InputField } from '../../core-ui/inputField';
import { groupBy, sortBy } from 'lodash-es';
import { Button, ButtonVariant } from '../../core-ui/button';
import { Size } from '../../core-ui/constants/Size';
import { useStyle } from '../../shared/hooks/useStyle';
import {
    parentStyle,
    rowItemStyle,
    itemButtonsContainer,
    iconButtonStyle,
    catalogItemContainerStyle,
    titleStyle,
    inlineButtonStyle,
    rowItemCatalogTypeStyle,
    catalogTypeTextStyle,
    rowItemContentFlexStyle,
    toggleCreateImportContainer,
    inlineCreateContentStyle,
    nameChangeInputStyle,
    noCatSpacing,
    contextualItemStyle,
    catalogItemTitleStyle,
    newCatButtonContentStyle,
    searchStyle
} from './Catalogs.style';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Collapsible } from '../../core-ui/collapse';
import { IProjectConfig } from '../../shared/motive/models/ProjectConfig';
import { ICatalogsContainerProps } from '../../containers/catalogsContainer';
import { useTypeDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { getSmartTypeName } from '../../util/ObjectDefinitionsUtils';
import { QuickCreateImportCatalogsContainer } from '../../containers/quickCreateImportCatalogsContainer';
import { CatalogItemList } from '../catalogItemList';
import { QuickObjectEditor } from '../quickObjectEditor';
import { filterObjects } from '../../util/FilterUtils';
import { catalogQuickEditorButtonStyle } from '../catalogQuickEditor/CatalogQuickEditor.style';
import { reverseCircularButton } from '../../core-ui/button/Button.style';
import { IContextualMenuItem, ContextualMenu } from '../../core-ui/contextualMenu';
import { Icon } from '../../core-ui/icon';
import { optionMenuRow, optionMenuIcon } from '../catalogItemList/CatalogScriptItem.style';
import { Text } from '../../core-ui/text';
import { DeleteModal } from '../../core-ui/deleteModal/DeleteModal';
import { removeHighlight } from '../../constants/HelperStyles';
import { useTranslation } from 'react-i18next';
export const TEST_ID_ALL_CATALOGS = 'catalogs';
export const TEST_ID_DELETE = 'deleteButton';
export const TEST_ID_CREATE = 'createCatalogButton';
export const TEST_ID_IMPORT = 'importCatalogButton';
export const TEST_ID_POST = 'postButton';
export const TEST_ID_RENAME = 'renameButton';

const DEFAULT_NEW_NAME = '';

export interface ICatalogsProps {
    catalogs: ICatalog<IScriptObjectModel>[];
    collapsible?: boolean;
    initializeOpen?: boolean;
    itemFilter?: string;
    showItemSearch?: boolean;
    itemsAreDraggable?: boolean;
    onDeleteCatalog: (catalogId: string, onComplete?: () => void) => void;
    onDeleteCatalogLoading?: boolean;
    onRenameCatalog: (catalogId: string, catalogTitle: string) => void;
    onCreateCatalog: (catalogTitle: string, catalogType: string) => void;
    onCatalogRowPressed: (catalogInfo: ICatalogInfo) => void;
    onImportCatalogs: (projectId: string, catalogIds: string[]) => void;
    onAddCatalogItem?: (catalogId: string, item: IScriptObjectModel) => void;
    onSelectItem?: (catalogId: string, item: IScriptObjectModel) => void;
    allSpaceCatalogs?: ICatalogInfo[];
    projectConfig?: IProjectConfig;
    allowQuickObjectEdit?: boolean;
    quickEditTitle?: string;
    quickAddTitle?: string;
    whiteListTypes?: ICatalogsContainerProps['whiteListTypes'];
    blackListTypes?: ICatalogsContainerProps['blackListTypes'];
}

const CatalogRow: React.FC<{
    catalog: ICatalog<IScriptObjectModel>;
    collapsible?: boolean;
    initializeOpen?: boolean;
    itemFilter?: string;
    allowQuickObjectEdit?: boolean;
    quickEditTitle?: string;
    quickAddTitle?: string;
    itemsAreDraggable?: boolean;
    onDeleteCatalog: (catalogId: string) => void;
    onDeleteCatalogLoading?: boolean;
    onRenameCatalog: (catalogId: string, catalogTitle: string) => void;
    onCatalogRowPressed: (catalogInfo: ICatalogInfo) => void;
    onAddCatalogItem?: (catalogId: string, item: IScriptObjectModel) => void;
    onSelectItem?: (catalogId: string, item: IScriptObjectModel) => void;
}> = ({
    catalog,
    itemFilter,
    collapsible,
    initializeOpen,
    onDeleteCatalog,
    onDeleteCatalogLoading,
    onRenameCatalog,
    onCatalogRowPressed,
    allowQuickObjectEdit,
    quickEditTitle,
    quickAddTitle,
    itemsAreDraggable,
    onAddCatalogItem,
    onSelectItem
}): React.ReactElement => {
    const [isItemsShowing, setIsItemsShowing] = useState(initializeOpen ?? false);
    const [isAddingItem, setIsAddingItem] = useState(false);
    const items = filterObjects(catalog?.items, itemFilter);

    const handleToggleItemsShowing = () => {
        const showing = !isItemsShowing;

        setIsItemsShowing(showing);
        setIsAddingItem(false);
    };

    const handleAddNewItem = (object: IScriptObjectModel) => {
        onAddCatalogItem?.(catalog.id, object);
    };

    const token = useStyle();
    const { t } = useTranslation();

    return (
        <>
            {(!itemFilter || (items && items.length > 0)) && (
                <div css={removeHighlight} onClick={handleToggleItemsShowing}>
                    <Catalog
                        catalogInfo={catalog}
                        onDeleteCatalog={onDeleteCatalog}
                        onDeleteCatalogLoading={onDeleteCatalogLoading}
                        onRenameCatalog={onRenameCatalog}
                        onCatalogRowPress={onCatalogRowPressed}
                        allowQuickObjectEdit={allowQuickObjectEdit}
                        quickEditTitle={quickEditTitle}
                    ></Catalog>
                </div>
            )}
            {(collapsible || itemFilter) && (
                <Collapsible isShowing={isItemsShowing || !!itemFilter}>
                    {items ? (
                        <CatalogItemList
                            onSelectItem={item => onSelectItem?.(catalog.id, item)}
                            isDraggable={itemsAreDraggable}
                            catalogId={catalog.id}
                            allowQuickEdit={allowQuickObjectEdit}
                            items={items}
                        />
                    ) : (
                        <div>{t('emptyList')}</div>
                    )}

                    {isAddingItem ? (
                        <QuickObjectEditor
                            onSave={handleAddNewItem}
                            onCancel={() => setIsAddingItem(false)}
                            type={catalog.objectType}
                            title={quickAddTitle ?? t('addNewItem')}
                        />
                    ) : (
                        <>
                            {onAddCatalogItem && !itemFilter && (
                                <>
                                    <Button
                                        css={catalogQuickEditorButtonStyle(token)}
                                        variant={ButtonVariant.HOLLOW}
                                        size={Size.MEDIUM}
                                        icon={IconTypes.PLUS}
                                        onClick={() => setIsAddingItem(true)}
                                    >
                                        {quickAddTitle ?? t('addNewItem')}
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </Collapsible>
            )}
        </>
    );
};

export const Catalogs: React.FC<ICatalogsProps> = ({
    catalogs,
    itemFilter,
    showItemSearch,
    collapsible,
    initializeOpen,
    onDeleteCatalog,
    onDeleteCatalogLoading,
    onRenameCatalog,
    onCreateCatalog,
    onCatalogRowPressed,
    onImportCatalogs,
    allSpaceCatalogs,
    projectConfig,
    whiteListTypes,
    blackListTypes,
    allowQuickObjectEdit,
    itemsAreDraggable,
    onAddCatalogItem,
    onSelectItem,
    quickAddTitle,
    quickEditTitle
}): React.ReactElement => {
    const tokens = useStyle();
    const [inlineCreateCatalogTitle, setInlineCreateCatalogTitle] = useState('');
    const [isAdditionCatalogsOpen, setIsAdditionCatalogsOpen] = useState(false);
    const [, setIsCreateCollapseShowing] = useState(false);
    const [quickCreateCatalogType, setQuickCreateCatalogType] = useState('');
    const [filterValue, setFilterValue] = useState('');
    //const ref: React.RefObject<HTMLDivElement> = useRef(null);
    const { t } = useTranslation();

    const groupedCatalogs = groupBy(sortBy(catalogs, 'id'), 'objectType');
    const sortedCatalogs = Object.keys(groupedCatalogs).sort();

    const typeDefs = useTypeDefinitions();

    const handleClickNoCatsForImport = () => {
        setIsAdditionCatalogsOpen(true);
        setIsCreateCollapseShowing(false);
    };

    const handleClickNoCatForCreate = () => {
        setIsAdditionCatalogsOpen(true);
    };

    const handleEnableQuickCreateCatalog = (type: string) => {
        //setIsAdditionCatalogsOpen(true);
        setQuickCreateCatalogType(type);
        setInlineCreateCatalogTitle('');
        //setQuickCreateOpenPane('create');
        //handleScrollInto(ref);
    };

    const handleQuickCreateCatalog = () => {
        onCreateCatalog(inlineCreateCatalogTitle, quickCreateCatalogType);
    };

    const handleFilterValueChange = (changedFilterValue: string) => setFilterValue(changedFilterValue.trim());

    return (
        <>
            <div>
                <Button
                    variant={ButtonVariant.HOLLOW}
                    css={toggleCreateImportContainer(tokens)}
                    onClick={() => setIsAdditionCatalogsOpen(!isAdditionCatalogsOpen)}
                >
                    <span css={newCatButtonContentStyle}>
                        <span>{t('createOrImportCatalog')}</span>
                        <Icon
                            icon={isAdditionCatalogsOpen ? IconTypes.CHEVRON_DOWN : IconTypes.CHEVRON_RIGHT}
                            css={reverseCircularButton(tokens)}
                        />
                    </span>
                </Button>
                <Collapsible isShowing={isAdditionCatalogsOpen}>
                    <QuickCreateImportCatalogsContainer
                        projectConfig={projectConfig}
                        allSpaceCatalogs={allSpaceCatalogs}
                        blackListTypes={blackListTypes}
                        whiteListTypes={whiteListTypes}
                        onImportCatalogs={onImportCatalogs}
                        onCreateCatalog={onCreateCatalog}
                        quickCreateCatalogType={quickCreateCatalogType}
                    />
                </Collapsible>
            </div>
            {showItemSearch && (
                <InputField
                    css={searchStyle}
                    placeholder={t('search')}
                    onChange={handleFilterValueChange}
                    size={Size.MEDIUM}
                />
            )}
            {sortedCatalogs.map(type => (
                <div key={type} css={parentStyle(tokens)}>
                    {whiteListTypes?.length !== 1 && (
                        <RowItem css={rowItemCatalogTypeStyle(tokens)}>
                            {() => ({
                                content: (
                                    <div>
                                        <div css={rowItemContentFlexStyle}>
                                            <Text size={Size.LARGE} css={catalogTypeTextStyle(tokens)}>
                                                {getSmartTypeName(type, typeDefs) ?? ''}
                                            </Text>
                                            {quickCreateCatalogType === type ? (
                                                <Button
                                                    css={reverseCircularButton(tokens)}
                                                    size={Size.SMALL}
                                                    icon={IconTypes.TIMES}
                                                    variant={ButtonVariant.CIRCULAR}
                                                    onClick={() => handleEnableQuickCreateCatalog('')}
                                                />
                                            ) : (
                                                <Button
                                                    css={reverseCircularButton(tokens)}
                                                    size={Size.SMALL}
                                                    icon={IconTypes.PLUS}
                                                    variant={ButtonVariant.CIRCULAR}
                                                    onClick={() => handleEnableQuickCreateCatalog(type)}
                                                />
                                            )}
                                        </div>
                                        {quickCreateCatalogType === type && (
                                            <div css={inlineCreateContentStyle}>
                                                <InputField
                                                    placeholder={t('catalogName')}
                                                    size={Size.MEDIUM}
                                                    onChange={catalogTitle => {
                                                        setInlineCreateCatalogTitle(catalogTitle);
                                                    }}
                                                ></InputField>
                                                <Button
                                                    disabled={
                                                        !inlineCreateCatalogTitle || inlineCreateCatalogTitle === ''
                                                    }
                                                    css={iconButtonStyle(tokens)}
                                                    size={Size.SMALL}
                                                    icon={IconTypes.CHECKMARK}
                                                    variant={ButtonVariant.CIRCULAR}
                                                    onClick={() => handleQuickCreateCatalog()}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </RowItem>
                    )}
                    {groupedCatalogs[type].map(catalog => {
                        return (
                            <CatalogRow
                                key={catalog.id}
                                catalog={catalog}
                                itemFilter={itemFilter ?? filterValue}
                                itemsAreDraggable={itemsAreDraggable}
                                onCatalogRowPressed={onCatalogRowPressed}
                                onDeleteCatalog={onDeleteCatalog}
                                onDeleteCatalogLoading={onDeleteCatalogLoading}
                                onRenameCatalog={onRenameCatalog}
                                allowQuickObjectEdit={allowQuickObjectEdit}
                                collapsible={collapsible}
                                initializeOpen={initializeOpen}
                                onAddCatalogItem={onAddCatalogItem}
                                onSelectItem={onSelectItem}
                                quickAddTitle={quickAddTitle}
                                quickEditTitle={quickEditTitle}
                            />
                        );
                    })}
                </div>
            ))}
            {sortedCatalogs.length <= 0 && (
                <>
                    <div css={noCatSpacing}>
                        <Text>{t('noCatalogText')}</Text>
                        <div>
                            <Button
                                onClick={handleClickNoCatsForImport}
                                variant={ButtonVariant.ACTIONLINK}
                                css={inlineButtonStyle(tokens)}
                            >
                                {t('importCatalog')}
                            </Button>
                            &nbsp;
                            <Button
                                onClick={handleClickNoCatForCreate}
                                variant={ButtonVariant.ACTIONLINK}
                                css={inlineButtonStyle(tokens)}
                            >
                                {t('createCatalog')}
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

interface ICatalogItemProps {
    catalogInfo: ICatalogInfo;
    allowQuickObjectEdit?: boolean;
    quickEditTitle?: string;
    onDeleteCatalog: (catalogId: string, onComplete?: () => void) => void;
    onDeleteCatalogLoading?: boolean;
    onRenameCatalog: (catalogId: string, catalogTitle: string) => void;
    onCatalogRowPress: (catalogInfo: ICatalogInfo) => void;
}

const Catalog: React.FC<ICatalogItemProps> = ({
    catalogInfo,
    catalogInfo: { id, title },
    onDeleteCatalog,
    onRenameCatalog,
    onCatalogRowPress
}): React.ReactElement => {
    const [newName, setNewName] = useState(DEFAULT_NEW_NAME);
    const [isRenameCatalogShown, setIsRenameCatalogShown] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    // const [showOptions, setShowOptions] = useState<string | undefined>(undefined);
    const [menuActive, setMenuActive] = useState(false);

    const tokens = useStyle();

    const { t } = useTranslation();

    const cancelRename = () => {
        setNewName(DEFAULT_NEW_NAME);
        setIsRenameCatalogShown(false);
    };

    const handleSavePressed = () => {
        if (!newName) {
            cancelRename();
        } else {
            onRenameCatalog(id, newName);
            setIsRenameCatalogShown(false);
        }
    };

    const handleRenamePressed = () => {
        setIsRenameCatalogShown(true);
    };

    const handleCatalogRowPressed = () => {
        onCatalogRowPress(catalogInfo);
    };

    const handleDeleteCatalog = () => {
        onDeleteCatalog(id, () => setShowDeleteModal(false));
    };

    const handleShowDeleteCatalogModal = () => {
        setShowDeleteModal(true);
    };

    const generateMenuItems: IContextualMenuItem[] = [
        {
            children: (
                <div css={optionMenuRow}>
                    <Icon css={optionMenuIcon} icon={IconTypes.EDIT} /> <Text>{t('rename')}</Text>
                </div>
            ),
            onPress: handleRenamePressed
        },
        {
            children: (
                <div css={optionMenuRow}>
                    <Icon css={optionMenuIcon} icon={IconTypes.DELETE_H} />
                    <Text>{t('delete')}</Text>
                </div>
            ),
            onPress: handleShowDeleteCatalogModal
        }
    ];

    return (
        <>
            <RowItem
                onClick={handleCatalogRowPressed}
                key={id}
                testId={TEST_ID_ALL_CATALOGS}
                css={rowItemStyle(tokens)}
            >
                {() => ({
                    content: (
                        <div css={catalogItemContainerStyle}>
                            <div css={catalogItemTitleStyle}>
                                {isRenameCatalogShown ? (
                                    <>
                                        <InputField
                                            css={nameChangeInputStyle}
                                            placeholder={title}
                                            size={Size.SMALL}
                                            onChange={catalogName => {
                                                setNewName(catalogName);
                                            }}
                                        ></InputField>
                                        <Button
                                            data-testid={TEST_ID_RENAME}
                                            size={Size.SMALLER}
                                            variant={ButtonVariant.CIRCULAR}
                                            icon={IconTypes.CHECKMARK}
                                            onClick={handleSavePressed}
                                        />
                                        <Button
                                            size={Size.SMALLER}
                                            variant={ButtonVariant.CIRCULAR}
                                            icon={IconTypes.TIMES}
                                            onClick={cancelRename}
                                        />
                                    </>
                                ) : (
                                    <Text size={Size.MEDIUM} css={titleStyle}>
                                        {title}
                                    </Text>
                                )}
                            </div>
                            <div css={itemButtonsContainer}>
                                <div css={contextualItemStyle}>
                                    <ContextualMenu
                                        alignLeft={false}
                                        active={menuActive}
                                        menuItems={generateMenuItems}
                                        onCloseEnd={() => setMenuActive(false)}
                                    >
                                        <Button
                                            onClick={() => setMenuActive(!menuActive)}
                                            variant={ButtonVariant.HOLLOW}
                                            icon={IconTypes.ELLIPSES_H}
                                        ></Button>
                                    </ContextualMenu>
                                </div>
                                {/* {showOptions === id && (
                                    <div css={catalogOptionsWrapper(tokens)}>
                                        <Button
                                            size={Size.SMALLER}
                                            onClick={handleRenamePressed}
                                            variant={ButtonVariant.ACTIONLINK}
                                            icon={IconTypes.EDIT}
                                        >
                                            Rename
                                        </Button>
                                        <Button
                                            size={Size.SMALLER}
                                            onClick={handleShowDeleteCatalogModal}
                                            variant={ButtonVariant.ACTIONLINK}
                                            icon={IconTypes.DELETE}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                )} */}
                            </div>
                        </div>
                    ),
                    subContent: isRenameCatalogShown ? <></> : undefined
                })}
            </RowItem>
            <DeleteModal
                isOpen={showDeleteModal}
                onConfirm={handleDeleteCatalog}
                onCloseModal={() => setShowDeleteModal(false)}
            >
                {t('confirmCatalogDelete', { name: title })}
            </DeleteModal>
        </>
    );
};
