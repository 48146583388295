import React from 'react';
import { IEditorColor } from '../../shared/motive/models/EditorColor';
import { SketchPicker, ColorResult } from 'react-color';

interface IColorPickerProps {
    className?: string;
    color?: IEditorColor;
    onChange?: (color: IEditorColor | undefined) => void;
    onChangeComplete?: (color: IEditorColor | undefined) => void;
}

export const ColorPicker: React.FC<IColorPickerProps> = ({
    color = { r: 0, g: 0, b: 0 },
    onChange,
    onChangeComplete
}): React.ReactElement => {
    const handleOnChange = (color: ColorResult) => {
        onChange && onChange({ r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: color.rgb.a as number });
    };

    const handleOnChangeComplete = (color: ColorResult) => {
        onChangeComplete &&
            onChangeComplete({
                r: color.rgb.r,
                g: color.rgb.g,
                b: color.rgb.b,
                a: color.rgb.a as number
            });
    };

    return (
        <SketchPicker
            color={{
                r: color.r,
                g: color.g,
                b: color.b,
                a: color?.a as number
            }}
            onChange={(color: ColorResult) => {
                handleOnChange(color);
            }}
            onChangeComplete={(color: ColorResult) => {
                handleOnChangeComplete(color);
            }}
        />
    );
};
