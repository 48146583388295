import { useCurrentSpace } from '../useCurrentSpace';
import { deleteEnumItemNetworkCall } from '../../networking/EnumDefinitionService';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useDispatch } from 'react-redux';

export interface IDeleteEnumDefinitionItemParams {
    enumId: string;
    enumItemId: string;
}

export const useDeleteEnumDefinitionItem = (): IHookProvidedCall<IDeleteEnumDefinitionItemParams> => {
    const [currentSpace] = useCurrentSpace();
    const dispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const deleteEnumDefinitionItem = async (params: IDeleteEnumDefinitionItemParams) => {
        await triggerCall(
            deleteEnumItemNetworkCall(currentSpace, params.enumId, params.enumItemId, getCancelableDispatch(dispatch))
        );
    };

    return { execute: deleteEnumDefinitionItem, ...rest };
};
