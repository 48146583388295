import { css } from '@emotion/core';

export const MAX_WIDTH = 500;

export const catalogExpressionPlayerContainerStyle = (width?: number) => css`
    position: relative;
    background-color: black;
    color: white;
    display: flex;
    max-width: 100%;
    overflow: hidden;
    ${width && width < MAX_WIDTH ? `flex-direction: column;` : `height: 500px;`}
`;

export const expressionFieldStyle = css`
    flex-basis: 47%;
`;

export const expressionContainerStyle = css`
    display: flex;
    flex-direction: column;
`;

export const expressionLeftColStyle = (width?: number) => css`
    background-color: rgb(67,66,78);
    ${width && width > MAX_WIDTH && `width: 250px;`}
`;

export const expressionSlidersContainerstyle = (width?: number) => css`
    ${width && width > MAX_WIDTH && `height: 100%;`}
`;

export const tabButtonStyle = (selected: boolean) => css`
    width: 45px;
    height: 45px;
    background-color: #232323;
    color: orange;
    border: none;
    border: 2px solid #131313;
    border-radius: 4px;
    &:hover {
        cursor: pointer;
        background-color: black;
    }
    &:focus {
        outline: none;
    }
    ${selected &&
        `
        background-color: black;
    `}
`;

export const facialExpressionTabsStyle = css`
    display: flex;
    justify-content: space-between;
    padding: 10px;
`;

export const expressionsSettingsLayoutStyle = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    padding-right: 0;
`;

export const expressionWeightTitleStyle = css`
    font-size: 12px;
`;

export const expressionCategoryButtonIconStyle = css`
    fill: red;
    height: 100%;
    width: 100%;
`;

export const expressionSliderStyle = css`
    -webkit-appearance: none;
    margin: 10px 0;
    width: 80px;

    &:focus {
        outline: none;
    }
    ::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        animate: 0.2s;
        background: #ff9a00;
    }
    ::-webkit-slider-thumb {
        height: 10px;
        width: 10px;
        border-radius: 50px;
        background: #ffffff;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -4px;
    }
    &:focus::-webkit-slider-runnable-track {
        background: #ff9a00;
    }
    &::-moz-range-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        animate: 0.2s;
        background: #ff9a00;
    }
    &::-moz-range-thumb {
        height: 10px;
        width: 10px;
        border-radius: 50px;
        background: #ffffff;
        cursor: pointer;
    }
    &::-ms-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
    &::-ms-fill-lower {
        background: #ff9a00;
    }
    &::-ms-fill-upper {
        background: #ff9a00;
    }
    &::-ms-thumb {
        height: 10px;
        width: 10px;
        border-radius: 50px;
        background: #ffffff;
        cursor: pointer;
    }
    &:focus::-ms-fill-lower {
        background: #ff9a00;
    }
    &:focus::-ms-fill-upper {
        background: #ff9a00;
    }
`;
export const expressionNumberStyle = css`
width: 35px;
margin: 0px 5px;
background-color: #0e0e0e;
color: white;
text-align: center;
font-size: 14px;
border: none;
&:hover {
    border: none;
    background-color: #454545;
}
border-radius: 3px;
`;