import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const GRID_NAV_BAR = 'navBarGrid';
export const GRID_BODY = 'body';

export const gridChildStyle = css`
    grid-row: ${GRID_NAV_BAR};
`;

//prettier-ignore
export const navBarParentStyle = css`
    z-index: 1;
`;

export const maxHeightStyle = (value: number) => css`
    height: ${value}px;
`;

export const userInfoMenuItemStyle = css`
    text-align: center;
    width: 200px;
`;

export const userInfoNameStyle = (token: IToken) => css`
    margin-top: ${token.spacings.small};
    font-weight: 600;
`;

export const userInfoEmailStyle = (token: IToken) => css`
    margin-top: ${token.spacings.smaller};
    font-size: ${token.fontSizes.small};
`;

export const userInfoManageAccountStyle = (token: IToken) => css`
    margin-top: ${token.spacings.smallest};
    font-size: ${token.fontSizes.small};
    font-weight: 600;
`;

export const userLogoutButtonStyle = (token: IToken) => css`
    font-size: ${token.fontSizes.small};
    font-weight: 600;
`;
