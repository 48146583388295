import * as React from 'react';
import { IScriptObjectEventEditorProps, ScriptObjectEventEditor } from './ScriptObjectEventEditor';
import { useSelector } from 'react-redux';
import { SelectScriptEditorCurrentScript } from '../../../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';
import { findResource } from '../../../../util/ScriptUtils';
import { IScriptObjectEventCondition } from '../../../../shared/motive/models/IScriptObjectEventCondition';

export interface IConditionScriptObjectEventEditorProps extends IScriptObjectEventEditorProps {
    condition?: IScriptObjectEventCondition;
}

/**
 * Child class of ScriptObjectEventEditor, adds custom event options from the resource on the condition
 */
export const ConditionScriptObjectEventEditor = ({ condition, ...props }: IConditionScriptObjectEventEditorProps) => {
    const script = useSelector(SelectScriptEditorCurrentScript);

    const objId = condition?.objectReference?.objectId;

    const enumOpts = [...props.options];

    if (objId) {
        const resWrapper = findResource(script, objId);

        resWrapper?.events?.forEach(e => {
            enumOpts.push({ value: e.id, label: e.name });
            props.itemRefs.push({ itemId: e.id, name: e.name });
        });
    }

    return <ScriptObjectEventEditor {...props} options={enumOpts} />;
};
