import React, { useState, useEffect } from 'react';
import { Bar } from '../../core-ui/bar';
import { UserIcon } from '../../core-ui/userIcon';
import {
    navBarUserIcon,
    navBarUserIconWrapper,
    containerStyle,
    fillStyle,
    navBarFixedStyle,
    navBarUserHover,
    topTabMenu,
    topNavContainer,
    closeIconStyle,
    logoContainerStyle,
    navbarContextMenuStyle
} from './NavBar.style';
import { ContextualMenu, IContextualMenuItem } from '../../core-ui/contextualMenu';
import { useStyle } from '../../shared/hooks/useStyle';
import { StoryFlowLogo } from '../../core-ui/storyFlowLogo';
import { SerializedStyles } from '@emotion/core';
import { useDashboardRouteMatch, useDashboardRoute } from '../../routes/dashboardRoute';
import { useScriptsRoute, useScriptsRouteMatch } from '../../routes/scriptsRoute/ScriptsRoute';
import { useProjectsRoute } from '../../routes/projectsRoute';
import { Button } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { useCurrentProject } from '../../shared/motive/hooks/useCurrentProject';
import {
    useNavbarCurrentTab,
    useNavbarCurrentTabId,
    useNavbarRemoveTab,
    useNavbarSetCurrentTab,
    useNavbarTabs
} from '../../redux/navbar/NavbarHooks';
import { INavBarItem } from '../../redux/navbar/NavbarReducer';
import { get } from 'lodash-es';
import { useSpacesRouteMatch } from '../../routes/spacesRoute';
import { useScenariosRoute } from '../../routes/scenariosRoute';
import { SwitchLanguage } from '../../components/switchLanguage';
declare const confirm: (message: string) => boolean;

export interface INavBarProps {
    name: string;
    menuItems: IContextualMenuItem[];
    className?: string;
    visibleRef?: (instance: HTMLDivElement | null) => void;
    heightCss?: SerializedStyles;
    navBarHeight: number;
}

export const NavBar: React.FC<INavBarProps> = ({
    menuItems,
    name,
    visibleRef,
    heightCss,
    className,
    navBarHeight
}): React.ReactElement => {
    const [isMenuActive, setIsMenuActive] = useState(false);
    const token = useStyle();
    const dashboardMatch = useDashboardRouteMatch();
    const { goTo: goToScenarios } = useScenariosRoute();
    const authoringMatch = useScriptsRouteMatch();
    const spacesMatch = useSpacesRouteMatch();
    const { goTo: goToProject } = useProjectsRoute();
    const { goTo: goToScript } = useScriptsRoute();

    const handleMenuToggleClick = () => {
        setIsMenuActive(!isMenuActive);
    };

    const handleMenuCloseEnd = () => {
        setIsMenuActive(false);
    };

    const [currentProject] = useCurrentProject();

    const selectTab = useNavbarSetCurrentTab();
    const currentTabId = useNavbarCurrentTabId();
    const tabs = useNavbarTabs();
    const removeTab = useNavbarRemoveTab();

    const onTabClick = (id: string) => {
        if (id === 'project') {
            goToScenarios({ pathParams: { projectName: currentProject } });
        } else {
            goToScript({ pathParams: { projectName: currentProject, scriptId: id } });
        }
    };

    const handleDeleteTab = (item: INavBarItem) => {
        const remove = (id: string) => {
            const idx = tabs.findIndex(c => c.id === id);
            const nextIdx = idx + 1 === tabs.length ? idx - 1 : idx + 1;
            if (id === currentTabId) {
                onTabClick(tabs[nextIdx].id);
            }
            removeTab(id);
        };

        if (!item.promptBeforeUnload?.shouldPrompt || item.id !== currentTabId) {
            remove(item.id);
            return;
        }

        if (!confirm(item.promptBeforeUnload.message)) {
            return;
        }

        if (item.promptBeforeUnload.onBeforeClose) {
            item.promptBeforeUnload.onBeforeClose();
        }
        remove(item.id);
    };

    // hide language switcher while there is no French
    const showSwitchLanguage = false;
    return (
        <div css={[heightCss]} ref={visibleRef} className={className}>
            <Bar css={[navBarFixedStyle, heightCss]}>
                <div css={containerStyle}>
                    <div css={logoContainerStyle}>
                        <StoryFlowLogo maxHeight={navBarHeight} onClick={() => currentProject && goToProject({})} />
                    </div>
                    {(dashboardMatch || authoringMatch) && tabs.length > 0 && (
                        <div css={topNavContainer(token)}>
                            {tabs.map(item => {
                                return (
                                    <div
                                        key={item.id}
                                        onClick={() => {
                                            onTabClick(item.id);
                                        }}
                                        css={topTabMenu(token, currentTabId === item.id)}
                                    >
                                        <span>{item.title}</span>
                                        {item.closeable && (
                                            <Button
                                                icon={IconTypes.TIMES}
                                                onClick={() => handleDeleteTab(item)}
                                                css={closeIconStyle}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div css={fillStyle} />
                    {showSwitchLanguage && <SwitchLanguage />}
                    <div css={navBarUserIcon(token)}>
                        <ContextualMenu
                            active={isMenuActive}
                            menuItems={menuItems}
                            alignLeft={false}
                            onCloseEnd={handleMenuCloseEnd}
                            css={navbarContextMenuStyle(token)}
                        >
                            <div css={navBarUserHover(token)} onClick={handleMenuToggleClick}>
                                <div css={navBarUserIconWrapper}>
                                    <div>
                                        <UserIcon name={name}></UserIcon>
                                    </div>
                                </div>
                            </div>
                        </ContextualMenu>
                    </div>
                </div>
            </Bar>
        </div>
    );
};
