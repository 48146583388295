import { IScriptObjectModel } from '../../../shared/motive/models/ScriptObjectModel';

export type CatalogItemActions = ReturnType<
    typeof catalogItemAppendAction | typeof catalogItemDeleteAction | typeof catalogItemUpdateAction
>;

export const catalogItemAppendAction = (catalogId: string, item: IScriptObjectModel) => {
    return {
        type: 'catalog/item/append',
        catalogId,
        item
    } as const;
};

export const catalogItemUpdateAction = (item: IScriptObjectModel) => {
    return {
        type: 'catalog/item/update',
        item
    } as const;
};

export const catalogItemDeleteAction = (catalogId: string, id: string) => {
    return {
        type: 'catalog/item/delete',
        catalogId,
        id
    } as const;
};
