import React from 'react';
import { shouldShowField, IObjectEditorRendererProps } from '../../containers/objectEditor';
import { useObjectDefinition } from '../../shared/motive/hooks/useScriptEditorInfo';
import {
    IBehaviourModelBindings,
    IBehaviourStateVariable,
    IBehaviourModel
} from '../../shared/motive/models/IBehaviourModel';
import { FieldVariableDropdown } from '../../containers/objectEditor/fieldVariableEditor/FieldVariableEditor';
import { IScript } from '../../shared/motive/models/Script';
import { useScriptAndGlobalVariables } from '../../shared/motive/hooks/useScriptAndGlobalVariables';
import { createObjectReferenceFromObject, uniqueMotiveId } from '../../util/MotiveUtils';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { createNewVariable } from '../../util/ScriptDynamicsUtil';
import { useCatalogItem } from '../../shared/motive/hooks/useCatalogs/useCatalogItem/useCatalogItem';
import { useDispatch, useSelector } from 'react-redux';
import { SelectScriptEditorCurrentScript } from '../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IBehaviourModelBindingsEditorProps extends IObjectEditorRendererProps<IBehaviourModelBindings> {}

export const BehaviourModelBindingsEditor: React.FC<IBehaviourModelBindingsEditorProps> = (
    props
): React.ReactElement => {
    const objectDefinition = useObjectDefinition(props.type);
    const value = props.value;
    const scriptDispatch = useDispatch();
    const script = useSelector(SelectScriptEditorCurrentScript);
    const behaviourModel = useCatalogItem<IBehaviourModel>(value?.behaviourModel?.id) ?? value?.behaviourModel;

    const targetRefsDef = objectDefinition.fieldDefinitions['targetReferences'];
    const behaviourModelDef = objectDefinition.fieldDefinitions['behaviourModel'];

    const varMap = useScriptAndGlobalVariables();

    const renderStateVariables = () => {
        if (value && behaviourModel && behaviourModel.behaviourStates) {
            return behaviourModel.behaviourStates.map(state => {
                const currStateIdx = value.stateVariableReferences?.findIndex(
                    r => r.behaviourStateReference?.objectId === state.id
                );

                const varRefs = value.stateVariableReferences ?? [];
                const currStateVar = currStateIdx >= 0 ? varRefs?.[currStateIdx] : undefined;

                const handleCreateNewVariable = (varName: string) => {
                    if (script) {
                        const newVar = createNewVariable(script, state.valueDefinition, varName, scriptDispatch);

                        const varRef = createObjectReferenceFromObject(newVar);

                        const stateRef: IBehaviourStateVariable = {
                            id: uniqueMotiveId(),
                            type: MotiveTypes.BEHAVIOUR_STATE_VARIABLE,
                            behaviourStateReference: createObjectReferenceFromObject(state),
                            variableReference: varRef
                        };

                        const refs = value.stateVariableReferences
                            ? [...value.stateVariableReferences, stateRef]
                            : [stateRef];

                        props.onChange('stateVariableReferences', refs);
                    }
                };

                const handleChangeVariable = (varId: string | undefined) => {
                    const path = 'stateVariableReferences';

                    if (varId) {
                        const variable = varMap[varId];

                        if (variable) {
                            const varRef = createObjectReferenceFromObject(variable);

                            const stateRef: IBehaviourStateVariable = {
                                id: uniqueMotiveId(),
                                type: MotiveTypes.BEHAVIOUR_STATE_VARIABLE,
                                behaviourStateReference: createObjectReferenceFromObject(state),
                                variableReference: varRef
                            };

                            if (currStateVar) {
                                const stateVarPath = `${path}.${currStateIdx}`;

                                props.onChange(stateVarPath, stateRef);
                            } else {
                                const newVarRefs = [...varRefs, stateRef];

                                props.onChange(path, newVarRefs);
                            }
                        }
                    } else {
                        const refs = value.stateVariableReferences.filter(
                            r => r.behaviourStateReference?.objectId !== state.id
                        );

                        if (refs) {
                            props.onChange(path, refs);
                        }
                    }
                };

                return props.renderFieldEditor(state.name, () => {
                    return (
                        <FieldVariableDropdown
                            onChange={handleChangeVariable}
                            script={script}
                            valueDefinition={state.valueDefinition}
                            variableId={currStateVar?.variableReference?.objectId}
                            onCreateNew={handleCreateNewVariable}
                            defaultVariableName={`${behaviourModel.name} ${state.name}`}
                        />
                    );
                });
            });
        }
    };

    const objProps = props as IObjectEditorRendererProps;

    return (
        <>
            {props.renderField(objProps, targetRefsDef, value?.targetReferences)}
            {props.renderField(objProps, behaviourModelDef, value?.behaviourModel)}
            {shouldShowField(behaviourModelDef, !!props.frame, props.showFields, props.hideFields) &&
                renderStateVariables()}
        </>
    );
};
