import React, { ReactNode, forwardRef } from 'react';
import { parentLayoutStyle, columnItemStyle } from './DocumentBox.style';

export interface IDocumentBoxProps {
    className?: string;
    rightColRender?: ReactNode;
    children: ReactNode;
}

export const DocumentBox = forwardRef<HTMLDivElement, IDocumentBoxProps>(function DocumentBox(
    { children, className, rightColRender },
    ref
) {
    return (
        <>
            <div ref={ref} className={className} css={parentLayoutStyle}>
                {children}
                {rightColRender && <div css={columnItemStyle}>{rightColRender}</div>}
            </div>
        </>
    );
});
