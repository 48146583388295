import React, { useState } from 'react';
import { IProjectConfig } from '../../shared/motive/models/ProjectConfig';
import { InputField } from '../../core-ui/inputField';
import {
    createProjectInputField,
    inputFieldStyle,
    importSectionToggle,
    createProjectStyle,
    rotateChevron,
    importSection,
    createButton,
    fieldGrid,
    importerItem,
    importerText
} from './CreateProject.style';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { rotating } from '../../constants/AnimationStyles';
import { Size } from '../../core-ui/constants/Size';
import { Icon } from '../../core-ui/icon';
import { getSystemName } from '../../util/MotiveUtils';
import { useCurrentSpace } from '../../shared/motive/hooks/useCurrentSpace';
import { ICatalogInfo, ICatalog } from '../../shared/motive/models/Catalog';
import { CatalogImporter } from '../catalogImporter';
import { Collapsible } from '../../core-ui/collapse';
import { useToggle } from '../../shared/hooks/useToggle';
import { Heading } from '../../core-ui/heading';
import { useStyle } from '../../shared/hooks/useStyle';
import { Button, ButtonVariant } from '../../core-ui/button';
import { ICreateProjectParams } from '../../shared/motive/hooks/useCreateProject/useCreateProject';
import { useScenariosRoute } from '../../routes/scenariosRoute';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { Checkbox } from '../../core-ui/checkbox';
import { Text } from '../../core-ui/text';
import { ICreateCatalogParams } from '../../shared/motive/hooks/useCatalogs/useCreateCatalog/useCreateCatalog';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useTranslation } from 'react-i18next';

interface ICreateProjectProps {
    createProjectAction: IHookProvidedCall<ICreateProjectParams, IProjectConfig | undefined>;
    createCatalogAction: IHookProvidedCall<ICreateCatalogParams, ICatalog | undefined>;
    projects: IProjectConfig[];
    availableCatalogs: ICatalogInfo[];
}

enum ProjectNameErrors {
    TOO_SHORT,
    TOO_LONG,
    TAKEN
}

interface IProjectNameErrorsMessage {
    TOO_SHORT: string;
    TOO_LONG: string;
    TAKEN: string;
}

const setValidationMessage = (error: ProjectNameErrors, message: IProjectNameErrorsMessage) => {
    switch (error) {
        case ProjectNameErrors.TOO_LONG:
            return message.TOO_LONG;
        case ProjectNameErrors.TOO_SHORT:
            return message.TOO_SHORT;
        case ProjectNameErrors.TAKEN:
            return message.TAKEN;
    }
};

export const CreateProject: React.FC<ICreateProjectProps> = ({
    createProjectAction,
    projects,
    availableCatalogs,
    createCatalogAction
}) => {
    const [newProjectName, setNewProjectName] = useState('');
    const [validationError, setValidationError] = useState<ProjectNameErrors | undefined>(undefined);
    const [selectedCatalogs, setSelectedCatalogs] = useState<string[]>([]);
    const [isImportShowing, toggleImportShowing] = useToggle();
    const [autoGenerateCatalogs, setAutoGenerateCatalogs] = useState(true);
    const tokens = useStyle();
    const { t } = useTranslation();

    const validationErrorMessage: IProjectNameErrorsMessage = {
        TOO_LONG: t('projectNameIsTooLong'),
        TOO_SHORT: t('projectNameIsTooShort'),
        TAKEN: t('projectNameIsTaken')
    };

    const addCatalog = (id: string) => {
        setSelectedCatalogs(prev => [...prev, id]);
    };

    const removeCatalog = (id: string) => {
        setSelectedCatalogs(prev => prev.filter(c => c !== id));
    };

    const [currentSpace] = useCurrentSpace();
    const { goTo } = useScenariosRoute();

    const handleChange = (name: string) => {
        setValidationError(undefined);
        setNewProjectName(name);
    };

    const onSubmit = async (name: string, catalogs?: string[]) => {
        if (createProjectAction.isLoading) {
            return;
        }

        if (name.length < 5) {
            setValidationError(ProjectNameErrors.TOO_SHORT);
        } else if (name.length > 64) {
            setValidationError(ProjectNameErrors.TOO_LONG);
        } else {
            const projectExists = projects.find(c => c.name === getSystemName(name));

            if (projectExists) {
                setValidationError(ProjectNameErrors.TAKEN);
            } else {
                const catalogsToImport = catalogs ?? [];

                if (autoGenerateCatalogs) {
                    const [scenarios, media, scripts] = await Promise.all([
                        createCatalogAction.execute({
                            catalogTitle: `[${newProjectName}] ${t('scenarios')}`,
                            importToCurrentProject: false,
                            objectType: MotiveTypes.SCRIPT_DIRECTORY_ITEM
                        }),
                        createCatalogAction.execute({
                            catalogTitle: `[${newProjectName}] ${t('media')}`,
                            importToCurrentProject: false,
                            objectType: MotiveTypes.MEDIA_ITEM
                        }),
                        createCatalogAction.execute({
                            catalogTitle: `[${newProjectName}] ${t('scripts')}`,
                            importToCurrentProject: false,
                            objectType: MotiveTypes.MOTIVE_SCRIPT
                        })
                    ]);

                    scenarios && catalogsToImport.push(scenarios.id);
                    media && catalogsToImport.push(media.id);
                    scripts && catalogsToImport.push(scripts.id);
                }

                const config = await createProjectAction.execute({
                    title: name,
                    catalogsToImport: catalogsToImport
                });

                if (!!config) {
                    goTo({
                        pathParams: {
                            spaceName: currentSpace,
                            projectName: config.id
                        }
                    });
                }
            }
        }
    };

    return (
        <>
            <div css={createProjectInputField}>
                <Heading css={createProjectStyle(tokens)} size={Size.LARGER}>
                    {t('createProject')}
                </Heading>
                <div css={fieldGrid}>
                    <InputField
                        placeholder={t('projectName')}
                        value={newProjectName}
                        onChange={handleChange}
                        css={inputFieldStyle(tokens)}
                    />
                    <Button
                        css={createButton}
                        size={Size.LARGER}
                        variant={ButtonVariant.SOLID}
                        onClick={() => onSubmit(newProjectName, selectedCatalogs)}
                        icon={
                            createProjectAction.isLoading || createCatalogAction.isLoading
                                ? IconTypes.LOAD_SPINNER
                                : undefined
                        }
                        iconStyle={
                            createProjectAction.isLoading || createCatalogAction.isLoading ? rotating : undefined
                        }
                    >
                        {!createProjectAction.isLoading && !createCatalogAction.isLoading && <span>{t('create')}</span>}
                    </Button>
                </div>

                {validationError !== undefined && (
                    <div>{setValidationMessage(validationError, validationErrorMessage)}</div>
                )}
                <div css={importSectionToggle(tokens)} onClick={toggleImportShowing}>
                    <Icon css={rotateChevron(isImportShowing)} icon={IconTypes.CHEVRON_DOWN}></Icon>
                    <span>{t('advancedSettings')}</span>
                </div>
                <Collapsible isShowing={isImportShowing}>
                    <div css={importSection(tokens)}>
                        <span>{t('catalogOptions')}</span>
                        <div css={importerItem(tokens)}>
                            <Checkbox
                                isChecked={autoGenerateCatalogs}
                                onClick={() => setAutoGenerateCatalogs(!autoGenerateCatalogs)}
                            ></Checkbox>
                            <Text css={importerText(tokens)}>{t('autoGenerateCatalogs')}</Text>
                        </div>
                    </div>
                    <div css={importSection(tokens)} hidden={availableCatalogs.length < 1}>
                        <span>
                            {t('importCatalog')} ({`${selectedCatalogs.length}`})
                        </span>
                        <CatalogImporter
                            availableCatalogs={availableCatalogs}
                            addCatalog={addCatalog}
                            removeCatalog={removeCatalog}
                            selectedCatalogs={selectedCatalogs}
                        ></CatalogImporter>
                    </div>
                </Collapsible>
            </div>
        </>
    );
};
