import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const catalogMenuItem = css`
    display: flex;
    align-items: center;
    text-align: left;
`;

export const catalogMenuText = css`
    margin-left: 6px;
`;

export const catalogMenuIcon = css`
    width: 20px;
`;
export const catalogFolderStyle = (token: IToken, isSelected: boolean) => css`
    text-align: center;
    width: 80px;
    margin: 0 12px;
    position: relative;
    cursor: pointer;
    border-radius: ${token.borderRadii.small};
    border: 1px solid transparent;
    ${!isSelected &&
        `&:hover {
            border: 1px solid #bedcff;
            background-color: rgba(82, 235, 255, 0.1);
    }`}

    ${isSelected &&
        `border: 1px solid #bedcff;
    background-color: rgba(82, 235, 255, 0.15);`}
`;

export const catalogNameStyle = (token: IToken) => css`
    margin: 0 auto;
    padding: 0;
    user-select: none;
    border: 1px solid transparent;
    &:hover {
        border: 1px solid ${token.colors.border.primary};
    }
    min-width: 0;
    max-width: fit-content;
`;

export const catalogFolderIconStyle = css`
    font-size: 2em;
    color: lightgray;
    cursor: pointer;
`;

export const deleteModalControlStyle = css`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
`;

export const deleteControlStyle = css`
    padding: 0 8px;
    margin: 0 8px;
    width: 80px;
`;
