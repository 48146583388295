/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { IScriptEditorStateProps, ScriptReducerAction } from '../../shared/motive/reducers/ScriptEditorReducers';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { getDefaultStateHistory } from '../../shared/motive/reducers/StateHistoryTree';

interface IScriptEditorContextValues {
    scriptState: IScriptEditorStateProps;
    scriptDispatch: React.Dispatch<ScriptReducerAction>;
}

export const DEFAULT_CONTEXT_VALUE: IScriptEditorContextValues = {
    scriptState: {
        script: {
            id: '',
            name: '',
            type: '',
            rootFrame: {
                name: '',
                isExclusive: false,
                isEnabled: true,
                isLive: false,
                resetOnClose: false,
                id: '',
                type: MotiveTypes.FRAME
            }
        },
        stateHistory: getDefaultStateHistory(),
        selectedFrameId: '',
        isDirty: false
    },
    scriptDispatch: () => undefined
};

export const scriptEditorContext = React.createContext<IScriptEditorContextValues>(DEFAULT_CONTEXT_VALUE);

export const ScriptEditorContextProvider = scriptEditorContext.Provider;
