import { createSelector } from 'reselect';
import { ApplicationState } from '../AppReducer';
import { INavbarState } from './NavbarReducer';

const SelectNavbarState = (state: ApplicationState) => state.navbar as INavbarState;

export const SelectCurrentNavbarTabId = createSelector(SelectNavbarState, state => {
    return state.currentItemId;
});

export const SelectNavbarItems = createSelector(SelectNavbarState, state => state.items);

export const SelectCurrentNavbarItem = createSelector(SelectNavbarItems, SelectCurrentNavbarTabId, (items, id) =>
    items.find(i => i.id === id)
);

export const SelectNavbarRouteToTab = createSelector(SelectNavbarState, state => state.routeToTabId);

export const SelectNavbarTab = (id: string) => createSelector(SelectNavbarItems, items => items.find(i => i.id === id));
