import React, { useState } from 'react';
import { IntentCatalogsContainer } from '../../containers/intentsCatalogContainer';
import { Button, ButtonVariant } from '../../core-ui/button';
import { Collapsible } from '../../core-ui/collapse';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Size } from '../../core-ui/constants/Size';
import { InputField } from '../../core-ui/inputField';
import { useStyle } from '../../shared/hooks/useStyle';
import { mergeCatalogItemsIntoSingleArray } from '../../util/CatalogUtils';
import { CreateIntentCatalog } from '../createIntentCatalog';
import { IntentCatalogList } from '../intentCatalogs';
import {
    actionBarWrapperStyle,
    createCatalogWrapperStyle,
    drawerIntentCatalogsWrapperStyle,
    searchStyle
} from './DrawerIntentCatalogs.style';

interface IDrawerIntentCatalogsProps {
    onSelectCatalog: (catalogId: string) => void;
}

export const DrawerIntentCatalogs: React.FC<IDrawerIntentCatalogsProps> = props => {
    const token = useStyle();
    const [showCreateCatalog, setShowCreateCatalog] = useState<boolean>(false);

    return (
        <>
            <IntentCatalogsContainer onSelectCatalog={props.onSelectCatalog} onSelectIntent={() => undefined}>
                {props => (
                    <>
                        <div css={actionBarWrapperStyle}>
                            <InputField
                                value={props.searchFilter}
                                onChange={props.onIntentSearch}
                                css={searchStyle}
                                size={Size.MEDIUM}
                                placeholder={'Search Catalogs'}
                            />
                            <Button
                                variant={ButtonVariant.SOLID}
                                icon={IconTypes.PLUS}
                                onClick={() => setShowCreateCatalog(!showCreateCatalog)}
                            />
                        </div>
                        <div css={drawerIntentCatalogsWrapperStyle(token)}>
                            <Collapsible isShowing={showCreateCatalog}>
                                <div css={createCatalogWrapperStyle(token)}>
                                    <CreateIntentCatalog
                                        isLoading={props.isCreateCatalogLoading}
                                        onCatalogCreate={title => props.onCreateCatalog && props.onCreateCatalog(title)}
                                    />
                                </div>
                            </Collapsible>
                            <IntentCatalogList
                                agents={mergeCatalogItemsIntoSingleArray(props.agentCatalogs)}
                                onSelectCat={props.onSelectCatalog}
                                cats={props.intentCatalogs}
                                searchFilter={props.searchFilter}
                                onDeleteCat={props.onDeleteIntentCatalog}
                                onRenameCat={props.onRenameIntentCatalog}
                                isCreateCatalogLoading={props.isCreateCatalogLoading}
                            />
                        </div>
                    </>
                )}
            </IntentCatalogsContainer>
        </>
    );
};
