import { ICatalog } from '../../../shared/motive/models/Catalog';
import { IScriptObjectModel } from '../../../shared/motive/models/ScriptObjectModel';
import { createSelector } from 'reselect';
import { createSubSelector, GenerateSubSelector } from '../spaceKeyedSelectors';
import { ICatalogsState } from './CatalogsReducer';

export const SelectAllCatalogs = createSubSelector(state => state.catalog.catalogData);

export const SelectCatalogById = (id: string) =>
    createSelector(SelectAllCatalogs, catalogs => catalogs.find(c => c.id === id));

const getCatalogsWithFilter = (filter: string[], prop: keyof ICatalog<IScriptObjectModel>) =>
    createSelector(SelectAllCatalogs, catalogs => catalogs.filter(c => filter.includes(c[prop] as string)));

export const SelectCatalogsWithIdFilter = (filterIds: string[]) => getCatalogsWithFilter(filterIds, 'id');
export const SelectCatalogsWithTypeFilter = (filterTypes: string[]) => getCatalogsWithFilter(filterTypes, 'objectType');

export const SelectCatalogsAllCatalogsLoaded = createSelector(
    GenerateSubSelector<ICatalogsState>('catalog'),
    state => state.allCatalogsLoaded
);

interface IGetCatalogByIdProps {
    id: string;
}

interface IGetCatalogWithTypesProps {
    types: string[];
}

// export const useAllCatalogs = () => {
//     return useSelector(getAllCatalogs);
// };

// export const makeGetCatalog = () => {
//     return createSelector([getCatalogById], catalog => {
//         return catalog;
//     });
// };

// export const makeGetCatalogsWithTypes = () => {
//     return createSelector([getCatalogsWithTypes], catalogs => {
//         return catalogs;
//     });
// };

// export const useFindCatalogFromStoreById = () => {
//     var catalogs = useAllCatalogs();

//     return (id: string) => {
//         return catalogs.find(c => c.id === id);
//     };
// };

// export const useCatalogsWithTypes = (types: string[]) => {
//     const selector = makeGetCatalogsWithTypes();

//     const getCatalogs = (state: IStoryFlowState) => {
//         return selector(state, { types: types });
//     };

//     return useSelector(getCatalogs);
// };
