import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const modalButtonStyle = (token: IToken) => css`
    margin-right: ${token.spacings.smaller};
`;
const normal = '#fb6969';
const hover = '#bf0c0c';

export const deleteButtonStyle = css`
    color: white;
    background-color: ${normal};

    &:hover {
        color: white;
        background-color: ${hover};
    }
`;

export const deleteModalContentStyle = css`
    padding: 32px 0;
    margin-top: 24px;
    font-weight: bold;
    text-align: center;
`;

export const trashIconStyle = css`
    color: white;
`;

export const trashIconWrapperStyle = css`
    position: absolute;
    top: -48px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 24px;
    border-radius: 50%;
    background-color: ${normal};
`;

export const deleteModalButtonWrapper = css`
    display: flex;
    justify-content: flex-end;
`;
