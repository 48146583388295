import React from 'react';
import { RowItem } from '../../core-ui/rowItem';
import { IProjectConfig } from '../../shared/motive/models/ProjectConfig';
import { Card } from '../../core-ui/card';
import { containerStyle, rowStyle, subcontentStyle, projectItemIcon, iconWrapperStyle } from './ProjectConfig.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { Size } from '../../core-ui/constants/Size';
import { prettierDate } from '../../util/DateUtils';
import { Icon } from '../../core-ui/icon';
import { IconTypes } from '../../core-ui/constants/IconTypes';

interface IProjectConfigsViewProps {
    projects: IProjectConfig[];
    onProjectSelect: (config: IProjectConfig) => void;
}

interface IProjectItemProps {
    project: IProjectConfig;
    onClick: () => void;
}

const ProjectItem: React.FC<IProjectItemProps> = ({ project, onClick }) => {
    const tokens = useStyle();

    return (
        <RowItem key={project.id} css={rowStyle(tokens)} size={Size.MEDIUM} onClick={onClick}>
            {() => ({
                content: <>{project.title}</>,
                subContent: <span css={subcontentStyle(tokens)}>{prettierDate(project.creationTime)}</span>,
                rightContent: (
                    <Icon
                        className={`iconWrapper`}
                        css={iconWrapperStyle}
                        iconStyle={projectItemIcon}
                        icon={IconTypes.CHEVRON_DOWN}
                    ></Icon>
                )
            })}
        </RowItem>
    );
};

export const ProjectConfigs: React.FC<IProjectConfigsViewProps> = ({
    projects,
    onProjectSelect
}): React.ReactElement => {
    const tokens = useStyle();

    return (
        <div css={containerStyle(tokens)}>
            <Card>
                {projects.map(project => (
                    <ProjectItem
                        key={project.id}
                        project={project}
                        onClick={() => onProjectSelect(project)}
                    ></ProjectItem>
                ))}
            </Card>
        </div>
    );
};
