import React from 'react';
import { useActivities } from '../../shared/motive/hooks/useActivities/useActivities';
import { IActivity } from '../../shared/motive/models/Activity';
import { IHookProvidedDataSource } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useLoadDataOnMount } from '../../shared/hooks/useLoadDataOnMount';

export interface IActivitiesProps {
    activityStatus: IHookProvidedDataSource<IActivity[]>;
}

export interface IActivitiesContainerProps {
    children: (props: IActivitiesProps) => React.ReactNode;
}

export const ActivitiesContainer: React.FC<IActivitiesContainerProps> = ({ children }) => {
    const source = useActivities();
    useLoadDataOnMount(source);
    return (
        <>
            {children({
                activityStatus: source
            })}
        </>
    );
};
