import { IPollyVoice } from './TextToSpeechReducer';

export const textToSpeechSetAction = (voices: IPollyVoice[]) => {
    return {
        type: 'textToSpeech/set',
        voices
    } as const;
};

export type TextToSpeechAction = ReturnType<typeof textToSpeechSetAction>;
