import { css } from '@emotion/core';

export const editableTextWrapperStyle = css`
    border: none;
    outline: none;
    &:hover {
        outline: none;
        border: none;
    }
`;

export const editableTextTextStyle = css`
    font-size: 1em;
    &:focus {
        font-size: 1em;
        box-shadow: 0px 0px 8px -4px #6bbaba;
    }
`;
