import { IScriptObjectModel } from './ScriptObjectModel';
import { getFilename } from '../../../util/ObjectDefinitionsUtils';

export interface IHostedMediaSource extends IScriptObjectModel {
    storageId: string;
    url: string;
    eTag: string;
    title: string | null;
    lastModified: string;
    contentType: string;
    contentLength: number;
}

export interface IMediaItem extends IScriptObjectModel {
    title?: string;
    source?: IHostedMediaSource;
}

export function isAudio(source: IHostedMediaSource | undefined) {
    if (source?.url) {
        const file = getFilename(source.url);

        return source?.contentType?.startsWith('audio') || (file && file.endsWith('ogg'));
    }
}
