import React, { useMemo } from 'react';
import { IFieldEditorProps } from '../ObjectEditor';
import { Dropdown, IDropdownOption } from '../../../core-ui/dropdown';
import { IEnumItemReference, IFieldDefinition } from '../../../shared/motive/models/TypeDefinition';
import { useTypeDefinition } from '../../../shared/motive/hooks/useScriptEditorInfo';
import { generateEnumOptions } from '../../../core-ui/utils/ComponentUtils';
import { IEnumDefinition } from '../../../shared/motive/models/EnumDefinition';
import { MotiveTypes } from '../../../constants/MotiveTypes';
import { ResourceScriptObjectEventEditor } from './scriptObjectEventEditor/ResourceScriptObjectEventEditor';
import { ConditionScriptObjectEventEditor } from './scriptObjectEventEditor/ConditionScriptObjectEventEditor';
import { IScriptObjectEventCondition } from '../../../shared/motive/models/IScriptObjectEventCondition';

export interface IEnumEditorDropdownProps {
    onChange: (val?: IEnumItemReference) => void;
    options: IDropdownOption[];
    itemRefs: IEnumItemReference[];
    fieldDefinition: IFieldDefinition;
    value?: IEnumItemReference;
}

export const EnumItemReferenceEditorDropdown = React.memo(function EnumItemReferenceEditorDropdown(
    props: IEnumEditorDropdownProps
) {
    const handleDropdownChange = (id?: string) => {
        if (props.value?.itemId === id) return;

        const selectedOption = props.itemRefs.find(c => c.itemId === id);

        if (selectedOption) {
            props.onChange(selectedOption);
        } else {
            props.onChange(undefined);
        }
    };

    return (
        <Dropdown
            value={props.value?.itemId ?? undefined}
            options={props.options}
            onChange={handleDropdownChange}
        ></Dropdown>
    );
});

export const EnumItemReferenceEditor: React.FC<IFieldEditorProps<IEnumItemReference>> = ({
    fieldDefinition,
    value,
    type,
    onChange,
    parentObjectEditorProps
}) => {
    const enumDefinition = useTypeDefinition<IEnumDefinition>(type);
    const enumOpts = useMemo(
        () => generateEnumOptions(enumDefinition.items, fieldDefinition.isNullable || value === undefined),
        [enumDefinition]
    );

    const handleChange = (item?: IEnumItemReference) => {
        if (item) {
            onChange(item);
        } else {
            onChange(undefined);
        }
    };

    const itemRefs = enumDefinition.items?.map(i => ({ itemId: i.id, name: i.name }));

    const eventProps: IEnumEditorDropdownProps = {
        value: value,
        itemRefs: itemRefs ?? [],
        fieldDefinition: fieldDefinition,
        options: enumOpts,
        onChange: handleChange
    };

    if (type === MotiveTypes.SCRIPT_OBJECT_EVENT) {
        if (parentObjectEditorProps.resourceWrapper) {
            return (
                <ResourceScriptObjectEventEditor
                    resourceWrapper={parentObjectEditorProps.resourceWrapper}
                    {...eventProps}
                />
            );
        } else {
            return (
                <ConditionScriptObjectEventEditor
                    condition={parentObjectEditorProps.value as IScriptObjectEventCondition}
                    {...eventProps}
                />
            );
        }
    }

    return <EnumItemReferenceEditorDropdown {...eventProps} />;
};
