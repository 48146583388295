import React, { useState, Dispatch, FC } from 'react';
import { Heading } from '../../core-ui/heading';
import { FormControl } from '../../core-ui/formControl';
import { Size } from '../../core-ui/constants/Size';
import { InputField } from '../../core-ui/inputField';
import { Button, ButtonVariant } from '../../core-ui/button';
import { Divider } from '../../core-ui/divider';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { IAccountManagerViewModel } from '../../shared/motive/models/AccountManagerViewModel';
import { Form, Formik, FormikProps, Field, FieldValidator, FormikHelpers } from 'formik';
import { IPhoneNumberForm, addPhoneNumber, confirmPhoneNumber } from '../../shared/motive/networking/AccountService';
import { IMVCModelError } from '../../shared/motive/networking/MVCModelError';
import { useToast } from '../../core-ui/hooks/useToast';
import { UserInfoActionType } from '../../redux/userInfo/UserInfoActions';

// Add phone number inner form, contains all logic for validation and markup for form.
const addPhoneNumberRender: React.FC<FormikProps<IPhoneNumberForm> & { isConfirmSent: boolean }> = ({
    errors,
    submitForm,
    isConfirmSent
}) => {
    const fieldId = 'phone-number';

    const validatePhoneNumber: FieldValidator = (value: string) => {
        if (!value) {
            return 'Phone number is required.';
        }
    };

    const validateConfirmCode: FieldValidator = (value: string) => {
        if (!value && isConfirmSent) {
            return 'Code is required.';
        }
    };
    const helperTextPhone = errors.phoneNumber ? errors.phoneNumber : 'Your 10 digit phone number.';
    const helperTextConfirm = errors.verificationCode ? errors.verificationCode : 'Your confirmation code';

    return (
        <Form>
            <br></br>
            <Heading size={Size.MEDIUM}>Add Phone Number</Heading>
            <FormControl
                label={isConfirmSent ? 'ConfirmationCode' : 'Phone Number'}
                fieldId={fieldId}
                required={true}
                helperText={isConfirmSent ? helperTextConfirm : helperTextPhone}
            >
                <Field
                    id={fieldId}
                    validate={validatePhoneNumber}
                    type={isConfirmSent ? 'hidden' : 'phone'}
                    name="phoneNumber"
                    size={Size.MEDIUM}
                    as={InputField}
                    useNativeOnChange={true}
                    placeholder="Phone Number"
                    isInvalid={errors.phoneNumber}
                    hidden={isConfirmSent}
                />
                <Field
                    id={fieldId}
                    type={isConfirmSent ? 'text' : 'hidden'}
                    name="VerificationCode"
                    size={Size.MEDIUM}
                    as={InputField}
                    useNativeOnChange={true}
                    placeholder="Verification Code"
                    isInvalid={errors.verificationCode}
                    verification={validateConfirmCode}
                />
            </FormControl>
            <Button
                variant={ButtonVariant.SOLID}
                icon={isConfirmSent ? IconTypes.CHECKMARK : IconTypes.PLUS}
                onClick={submitForm}
            >
                {isConfirmSent ? 'Confirm' : 'Add'}
            </Button>
            <Divider></Divider>
        </Form>
    );
};

/**
 * Add phone number form, contains all logic driving the main state of the form.
 */
export const AddPhoneNumberForm: FC<IAccountManagerViewModel & { dispatch: Dispatch<UserInfoActionType> }> = ({
    dispatch
}) => {
    const [isConfirmSent, setIsConfirmSent] = useState(false);

    const toast = useToast();

    const getMessageString = (errorArray?: IMVCModelError[]) => {
        if (!errorArray) {
            return '';
        }
        return errorArray?.map(m => m.ErrorMessage).join('\n');
    };

    const handleSubmitConfirm = async (
        values: IPhoneNumberForm,
        { setErrors, setSubmitting, resetForm }: FormikHelpers<IPhoneNumberForm>
    ) => {
        setSubmitting(true);
        const resp = await confirmPhoneNumber(values, dispatch);
        setSubmitting(false);

        if (!!resp) {
            setErrors({
                verificationCode: resp.message ? resp.message : getMessageString(resp?.verificationCode)
            });

            return;
        }

        toast({
            description: 'Successfully linked phone number. 2FA May now be enabled.',
            isClosable: true,
            position: 'bottom',
            status: 'success',
            title: 'Success'
        });

        resetForm();
    };

    const handleSubmitPhone = async (
        values: IPhoneNumberForm,
        { setErrors, setSubmitting }: FormikHelpers<IPhoneNumberForm>
    ) => {
        setSubmitting(true);
        const resp = await addPhoneNumber(values, dispatch);
        setSubmitting(false);

        if (!!resp) {
            setErrors({
                phoneNumber: resp.message ? resp.message : getMessageString(resp?.phoneNumber)
            });

            return;
        }

        setIsConfirmSent(true);

        toast({
            description: 'Successfully added phone number. Check your text messages for a verification code.',
            isClosable: true,
            position: 'bottom',
            status: 'success',
            title: 'Success'
        });
    };

    return (
        <Formik initialValues={{ phoneNumber: '' }} onSubmit={!isConfirmSent ? handleSubmitPhone : handleSubmitConfirm}>
            {(wrapperProps: FormikProps<IPhoneNumberForm>) =>
                // This is necessary to pass along the confirm sent state.
                addPhoneNumberRender({ ...wrapperProps, isConfirmSent: isConfirmSent })
            }
        </Formik>
    );
};
