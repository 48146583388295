import React from 'react';
import './App.css';
import { LocaleStore } from './shared/motive/stores/locale';
import { StyleStore } from './stores/styleStore';
import { ThemeProvider } from '@chakra-ui/core';
import { EditorLocaleStore } from './shared/motive/stores/editorLocale';
import { EditorRoutes } from './routes/editorRoutes';
import { UiStore } from './stores/uiStore/UiStore';
import { StoryFlowStoreProvider } from './redux/StoryFlowStoreProvider';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { MotiveCookieConsent } from './components/cookieConsent/CookieConsent';

export const App: React.FC = (): React.ReactElement => {
    return (
        <>
            <MotiveCookieConsent
                message={
                    'Motive.io uses cookies to improve our user experience. By continuing to use this site you accept.'
                }
            >
                <ThemeProvider>
                    <StyleStore>
                        <LocaleStore>
                            <EditorLocaleStore>
                                <UiStore>
                                    <StoryFlowStoreProvider>
                                        <EditorRoutes />
                                    </StoryFlowStoreProvider>
                                </UiStore>
                            </EditorLocaleStore>
                        </LocaleStore>
                    </StyleStore>
                </ThemeProvider>
            </MotiveCookieConsent>
        </>
    );
};
