/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { MediaWizardZone } from '../../containers/mediaManagementContainer/MediaManagementContainer';
import { ButtonVariant, Button } from '../../core-ui/button';
import { Size } from '../../core-ui/constants/Size';
import { useStyle } from '../../shared/hooks/useStyle';
import { Collapsible } from '../../core-ui/collapse';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Dropdown } from '../../core-ui/dropdown';
import { InputField } from '../../core-ui/inputField';
import { ListOrGrid } from '../../core-ui/collection/Collection';
import {
    flexColumnStyle,
    flexRowStyle,
    headerWrapperStyle,
    actionBarWrapperStyle,
    gridButtonStyle,
    interactibleWrapperStyle,
    listButtonStyle,
    interactibleStyle,
    dropDownWrapperStyle,
    dropDownStyle
} from './MediaManagementBar.style';
import { Divider } from '../../core-ui/divider';
import { Heading } from '../../core-ui/heading';
import { TextToSpeechZone } from '../textToSpeechZone';
import { IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { MediaUploadContainer } from '../../containers/mediaUploadContainer/MediaUploadContainer';
import { useTranslation } from 'react-i18next';

interface IMediaManagementBarProps {
    currentZone?: MediaWizardZone;
    display: ListOrGrid;
    onUpdateTypeFilter: (type: string) => void;
    onUpdateSearchFilter: (str: string) => void;
    onShowListView: () => void;
    onShowGridView: () => void;
    onToggleZone: (zone: MediaWizardZone) => void;
    onSelectItem?: (item: IHostedMediaSource) => void;
    isLoading: boolean;
}

export const MediaManagementBar: React.FC<IMediaManagementBarProps> = props => {
    const token = useStyle();
    const { t, i18n } = useTranslation();
    const typeDropdownOptions = [
        { value: 'none', label: t('allTypes') },
        { value: 'image', label: t('images') },
        { value: 'audio', label: t('audio') },
        { value: 'video', label: t('video') },
        { value: 'other', label: t('other') }
    ];

    return (
        <div css={flexColumnStyle}>
            <span css={headerWrapperStyle}>
                <Heading size={Size.LARGE}>{i18n.format(t('mediaLibrary', 'uppercase'))}</Heading>
                <span css={actionBarWrapperStyle}>
                    <span css={interactibleWrapperStyle}>
                        <Button
                            css={interactibleStyle(token)}
                            size={Size.MEDIUM}
                            variant={ButtonVariant.SOLID}
                            onClick={() => props.onToggleZone('tts')}
                            icon={IconTypes.SPEECH}
                        >
                            {t('textToSpeech')}
                        </Button>
                        <Button
                            css={interactibleStyle(token)}
                            size={Size.MEDIUM}
                            variant={ButtonVariant.SOLID}
                            onClick={() => props.onToggleZone('upload')}
                            icon={IconTypes.UPLOAD}
                        >
                            {t('upload')}
                        </Button>
                    </span>
                </span>
            </span>
            <div>
                <Divider />
            </div>
            <div css={interactibleWrapperStyle}>
                <Collapsible isShowing={props.currentZone === 'upload'}>
                    <MediaUploadContainer onUpload={props.onSelectItem} />
                </Collapsible>
                <Collapsible isShowing={props.currentZone === 'tts'}>
                    <TextToSpeechZone onSelectItem={props.onSelectItem} />
                </Collapsible>
                <InputField placeholder={t('search')} onChange={props.onUpdateSearchFilter} />
                <span css={flexRowStyle(token)}>
                    <div>
                        <Button
                            icon={IconTypes.LIST}
                            onClick={props.onShowListView}
                            css={listButtonStyle(token, props.display)}
                            disabled={props.isLoading}
                        />
                        <Button
                            icon={IconTypes.GRID}
                            onClick={props.onShowGridView}
                            css={gridButtonStyle(token, props.display)}
                            disabled={props.isLoading}
                        />
                    </div>
                    <span css={dropDownWrapperStyle}>
                        <Dropdown
                            options={typeDropdownOptions}
                            onChange={props.onUpdateTypeFilter}
                            css={dropDownStyle}
                        />
                    </span>
                </span>
            </div>
        </div>
    );
};
