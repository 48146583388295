import { IActivity } from '../../../shared/motive/models/Activity';

export const activitySetAction = (activities: IActivity[]) => {
    return {
        type: 'activity/set',
        activities
    } as const;
};

export const activityClearAction = () => {
    return {
        type: 'activity/unset'
    } as const;
};

export type ActivityActions = ReturnType<typeof activitySetAction | typeof activityClearAction>;
