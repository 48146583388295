import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';
import chroma from 'chroma-js';

export const frameTreeWrapperStyle = css`
    padding: 15px;
`;

export const frameTreeContainerStyle = css`
    background-color: #2f2e41;
    height: 100%;

    & .ReactVirtualized__List {
        box-sizing: border-box;
    }

    & .rst__moveHandle {
        width: 30px;
        min-width: 30px;
    }
`;

export const nodeTitleStyle = css`
    font-size: 14px;
    color: white;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

export const nodeWrapperStyle = css`
    border: none;
    padding: 6px 2px;
    max-width: fit-content;
    &:hover {
        border: none;
        background-color: unset;
    }
`;

export const nodeInputStyle = css`
    all: unset;
    margin: 6px 2px;
    border: 1px solid transparent;
    &:hover {
        border: 1px solid gray;
    }
    &:focus {
        font-size: 14px;
        border: 1px solid white;
    }
`;

export const verticalDividerParent = css`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    flex-wrap: wrap;
    height: 16px;
    overflow: hidden;
`;

export const verticalDividerStyle = css`
    width: 2px;
    margin: 0 2px;
    height: 16px;
    background: white;
`;

export const iconStyleDisabled = (token: IToken) => css`
    color: ${token.colors.background.dark};
    margin: 0 4px;

    &:hover {
        color: ${token.colors.font.secondary};
    }
`;

export const signalledResourceIconStyle = css`
    color: #2f2e41;
`;

export const iconEnabledStyle = (token: IToken, isEnabled: boolean) => css`
    ${isEnabled ? undefined : 'opacity: 50%;'}
`;

export const iconStyle = (token: IToken) => css`
    color: white;
    margin: 0 4px;

    &:hover {
        color: ${token.colors.font.secondary};
    }
`;

export const iconStyleCondition = (token: IToken) => css`
    color: #63adc8;
    margin: 0 4px;

    &:hover {
        color: ${token.colors.font.secondary};
    }
`;
export const iconStyleDynamic = (token: IToken) => css`
    color: ${token.dynamicsColors.background.neutral};
    margin: 0 4px;

    &:hover {
        color: ${token.colors.font.secondary};
    }
`;

export const frameActionButtonStyle = (token: IToken) => css`
    width: auto;
    color: white;
    margin: 0 2px;
    &:hover {
        color: ${token.colors.font.secondary};
    }
    padding: 4px 6px;
`;

export const subtitleTextStyle = css`
    min-height: 1ex;
`;
export const variableNumber = css`
    font-size: 0.8em;
    color: white;
`;
export const frameThumbContentContainerStyle = (isSelected: boolean, isEnabled: boolean, isExclusive: boolean) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;

    &:hover {
        cursor: pointer;
    }
    ${isSelected && isExclusive && `background-color:#d71b69;`}
    ${isSelected && !isExclusive && `background-color:#d71b69;`}
    ${!isSelected && isExclusive && `background-color:#9DA4B3; border-left-color: #9DA4B3;`}

    ${!isEnabled && 'opacity: 50%;'}
`;

export const signalledFrameStyle = (isSelected: boolean) => css`
    background-color: #5c6bc0;

    ${isSelected &&
        `&:after{    
        border-left-color: #5C6BC0;
    }`}
`;

export const draggingOverStyle = (tokens: IToken, isDraggingOver: boolean) => css`
    height: 100%;
    width: 100%;
    ${isDraggingOver && `border: 1px solid red;`}
`;

export const eventLinkBoxStyle = (tokens: IToken, isTriggeredBy: boolean) => css`
    width: 15px;
    background-color: ${isTriggeredBy
        ? chroma('#d71b69')
              .darken(1)
              .css()
        : chroma('#d71b69')
              .darken(-1)
              .css()};
`;

const frameThumbArrow = (color: string) => css`
    &:after {
        left: 100%;
        top: 26px;
        overflow: hidden;
        border: solid transparent;
        content: ' ';
        position: absolute;
        border-left-color: ${color};
        border-width: 26px 0 26px 20px;
        margin-top: -26px;
    }
`;

export const frameThumbGrid = (isSelected: boolean) => css`
    display: grid;
    grid-template-columns: 1fr 70px;
    width: 100%;

    ${isSelected && frameThumbArrow('#d71b69')}
`;

export const frameTitleIconWrapper = css`
    overflow: hidden;
    float: left;
    display: inline-block;
    margin-left: 11px;
`;

export const frameTreeActions = css`
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
`;

export const overflowEllipsisStyle = css`
    text-overflow: ellipsis;
`;

export const frameThumbOnDragConditionStyle = (token: IToken) => css`
    background-color: ${token.conditionColors.background.neutral};
    &:after {
        border-left-color: ${token.conditionColors.background.neutral} !important;
    }
`;

export const frameThumbOnDragResourceStyle = (token: IToken) => css`
    background-color: ${token.colors.bar.resourceBarHeader};
    &:after {
        border-left-color: ${token.colors.bar.resourceBarHeader} !important;
    }
`;
