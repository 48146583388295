import React, { useState } from 'react';
import { ICollaboratorProps, CollaboratorItemContainer } from '../../containers/collaboratorContainer';
import { ICollaborator } from '../../shared/motive/models/Collaborator';
import { Text } from '../../core-ui/text';
import {
    collapsibleContainerStyle,
    addCollaboratorWrapper,
    addCollaboratorButton,
    addCollaboratorInput,
    addCollaboratorGroup,
    pendingCollaboratorWrapper
} from './SidebarCollaborators.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { Size } from '../../core-ui/constants/Size';
import { SidebarSection } from '../sidebarSection/SidebarSection';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { InputField } from '../../core-ui/inputField';
import { Button, ButtonVariant } from '../../core-ui/button';
import { rotating } from '../../constants/AnimationStyles';
import { orderBy, times } from 'lodash-es';
import { SidebarCollaboratorItem } from '../sidebarCollaboratorItem';
import { SidebarPendingCollaboratorItem } from '../sidebarPendingCollaboratorItem';
import { Loading } from '../../core-ui/loading';
import { IToken } from '../../shared/contexts/styleContext';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export const userIconSize = 60;

const sortedCollaborators = (collabs: ICollaborator[]) => {
    return orderBy(collabs, ['firstName', 'lastName'], ['asc']);
};

const getLoadingCollaborators = (tokens: IToken) => (
    <div css={collapsibleContainerStyle(tokens, 3)}>
        {times(3, i => (
            <CollaboratorItemContainer key={i}>{() => <SidebarCollaboratorItem />}</CollaboratorItemContainer>
        ))}
    </div>
);

const getLoadingPendingCollaborators = () => (
    <SidebarSection isInitiallyOpen={true} label={i18next.t('pendingInvite')}>
        <CollaboratorItemContainer>{() => <SidebarPendingCollaboratorItem />}</CollaboratorItemContainer>
    </SidebarSection>
);

export const SidebarCollaborators: React.FC<ICollaboratorProps> = ({
    isLoading,
    collaborators,
    pendingCollaborators,
    addCollaboratorAction
}) => {
    const addCollaborator = addCollaboratorAction;
    const [newCollaboratorEmail, setNewCollaboratorEmail] = useState('');
    const tokens = useStyle();
    const { t } = useTranslation();

    return (
        <>
            <SidebarSection label={`${t('team')} ${collaborators ? `(${collaborators.length})` : ``}`}>
                <Loading isLoaded={!isLoading} loadingRender={() => getLoadingCollaborators(tokens)}>
                    {collaborators && (
                        <div css={collapsibleContainerStyle(tokens, collaborators?.length)}>
                            {sortedCollaborators(collaborators).map(collab => (
                                <CollaboratorItemContainer key={collab.id}>
                                    {props => (
                                        <SidebarCollaboratorItem
                                            collaborator={collab}
                                            deleteCollaboratorAction={props.deleteCollaboratorAction}
                                        />
                                    )}
                                </CollaboratorItemContainer>
                            ))}
                        </div>
                    )}
                </Loading>

                <div css={addCollaboratorWrapper(tokens)}>
                    <div>
                        <Text size={Size.LARGER}>{t('addTeamMember')}:</Text>
                    </div>
                    <div css={addCollaboratorGroup}>
                        <InputField
                            css={addCollaboratorInput}
                            placeholder={`Email`}
                            type={`email`}
                            size={Size.MEDIUM}
                            isDisabled={addCollaborator?.isLoading}
                            value={newCollaboratorEmail}
                            onChange={setNewCollaboratorEmail}
                        ></InputField>
                        <Button
                            onClick={async () => {
                                await addCollaborator?.execute({ email: newCollaboratorEmail });
                                setNewCollaboratorEmail('');
                            }}
                            css={addCollaboratorButton}
                            iconStyle={addCollaborator?.isLoading ? rotating : undefined}
                            variant={ButtonVariant.SOLID}
                            icon={!addCollaborator?.isLoading ? IconTypes.PLUS : IconTypes.LOAD_SPINNER}
                            disabled={addCollaborator?.isLoading}
                        ></Button>
                    </div>
                </div>
            </SidebarSection>

            <Loading isLoaded={!isLoading} loadingRender={getLoadingPendingCollaborators}>
                {pendingCollaborators && pendingCollaborators.length > 0 && (
                    <SidebarSection
                        isInitiallyOpen={false}
                        label={t('pendingInviteWithCount', {
                            count: pendingCollaborators?.length ? pendingCollaborators?.length : 0
                        })}
                    >
                        <div css={pendingCollaboratorWrapper(tokens)}>
                            {pendingCollaborators?.map(collab => (
                                <CollaboratorItemContainer key={collab.InviteeEmail}>
                                    {props => (
                                        <SidebarPendingCollaboratorItem
                                            collaborator={collab}
                                            deleteCollaborator={props.deleteCollaboratorAction}
                                        />
                                    )}
                                </CollaboratorItemContainer>
                            ))}
                        </div>
                    </SidebarSection>
                )}
            </Loading>
        </>
    );
};
