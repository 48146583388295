import { IEnumDefinitionItem } from '../../shared/motive/models/EnumDefinition';
import { useState } from 'react';
import { EditableText } from '../../core-ui/editableText';
import { Size } from '../../core-ui/constants/Size';
import React from 'react';
import { editableTextWrapperStyle, editableTextTextStyle, errorMessageStyle } from './CreateEnumDefinitionItem.style';
import { IUseCreateEnumDefinitionItemParams } from '../../shared/motive/hooks/useCreateEnumDefinitionItem/useCreateEnumDefinitionItem';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useTranslation } from 'react-i18next';
import { Text } from '../../core-ui/text';

interface ICreateEnumDefinitionItemProps {
    onCreateEnumItem: IHookProvidedCall<IUseCreateEnumDefinitionItemParams, IEnumDefinitionItem | undefined>;
    enumId: string;
    enumDefItemNames?: string[];
}

export const CreateEnumDefinitionItem: React.FC<ICreateEnumDefinitionItemProps> = ({
    onCreateEnumItem,
    enumId,
    enumDefItemNames
}) => {
    const [newEnumItemName, setNewEnumItemName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const { t } = useTranslation();

    const handleCreate = async () => {
        if (!newEnumItemName) {
            return;
        }

        setErrorMessage('');

        if (enumDefItemNames?.includes(newEnumItemName.toLowerCase())) {
            setErrorMessage(t('enumItemNameError'));
            return;
        }

        await onCreateEnumItem.execute({ enumId: enumId, enumItemTitle: newEnumItemName });

        setNewEnumItemName('');
    };

    return (
        <>
            <EditableText
                wrapperStyle={editableTextWrapperStyle}
                textStyle={editableTextTextStyle}
                inputStyle={editableTextTextStyle}
                size={Size.MEDIUM}
                placeholder={newEnumItemName ? newEnumItemName : t('newEnumItemName')}
                onChange={newName => setNewEnumItemName(newName.trim())}
                onEditComplete={handleCreate}
            ></EditableText>
            {errorMessage && (
                <Text size={Size.SMALL} css={errorMessageStyle}>
                    {errorMessage}
                </Text>
            )}
        </>
    );
};
