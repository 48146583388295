import React from 'react';
import { InputField } from '../../core-ui/inputField';
import { Size } from '../../core-ui/constants/Size';
import { Button, ButtonVariant } from '../../core-ui/button';
import { useStyle } from '../../shared/hooks/useStyle';
import { publishingInputStyle, publishingSection, publishButton, requiredField } from './Publishing.style';
import { ILMSConfigurationOptions } from '../../containers/publishingContainer';
import { TextArea } from '../../core-ui/textArea';
import { Text } from '../../core-ui/text';
import { ICreateBundleParams } from '../../shared/motive/hooks/useCreateBundle/useCreateBundle';
import { Icon } from '../../core-ui/icon';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { rotating } from '../../constants/AnimationStyles';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useTranslation } from 'react-i18next';

export interface IPublishingProps {
    createBundleAction: IHookProvidedCall<ICreateBundleParams>;
    configuration: ILMSConfigurationOptions;
    onTitleChange: (title: string) => void;
    onDescriptionChange: (desc: string) => void;
    onAutoToggle: () => void;
    onHostedToggle: () => void;
    onLaunchServerChange: (launchServer: string) => void;
    onLaunchServerAuthChange: (launchAuth: string) => void;
}

/* istanbul ignore next */
export const Publishing: React.FC<IPublishingProps> = ({
    createBundleAction,
    configuration,
    onTitleChange,
    onDescriptionChange
}) => {
    const tokens = useStyle();
    const { t } = useTranslation();
    return (
        <>
            <div css={publishingSection(tokens)}>
                <Text size={Size.MEDIUM}>
                    {t('name')} <span css={requiredField}>*</span>
                </Text>
                <InputField
                    css={publishingInputStyle(tokens)}
                    value={configuration.title}
                    size={Size.MEDIUM}
                    onChange={onTitleChange}
                ></InputField>
            </div>
            <div css={publishingSection(tokens)}>
                <Text size={Size.MEDIUM}>{t('description')}</Text>
                <TextArea
                    css={publishingInputStyle(tokens)}
                    value={configuration.description}
                    onChange={onDescriptionChange}
                ></TextArea>
            </div>
            <div css={publishingSection(tokens)}>
                <Button
                    css={publishButton}
                    disabled={!configuration.title}
                    variant={ButtonVariant.SOLID}
                    onClick={() => createBundleAction.execute({ name: configuration.title })}
                >
                    {createBundleAction.isLoading ? (
                        <Icon icon={IconTypes.LOAD_SPINNER} iconStyle={rotating}></Icon>
                    ) : (
                        t('publish')
                    )}
                </Button>
            </div>
        </>
    );
};
