import React, { useState, useEffect } from 'react';
import { DashboardTab } from '../../components/dashboardNavBar';
import { mainBodyFlexStyle, dashboardLayoutStyle } from './ProjectDashboardContainer.style';
import { debounce } from 'lodash-es';
import { isWithinRangeSmallerNoFloor } from '../../core-ui/constants/Breakpoints';
import { DashboardDrawerContainer } from '../dashboardDrawerContainer';
import { IUIStackContextValue, UIStackContextProvider, IStackItem } from '../../shared/hooks/useUIStack';
import { ChooseGlobalContextContainer } from '../chooseGlobalContextContainer';
import { ChooseMediaSourceContextContainer } from '../chooseMediaSourceContextContainer';
import { useChooseMediaSourceState } from '../../hooks/useChooseMediaSource';
import { ChooseMediaSourceModal } from '../chooseMediaSourceModal';

interface IDashboardContainerProps {
    selectedTab?: DashboardTab;
    onTabSelected?: (tab: DashboardTab, prevSelectedItem?: string) => void;
}

const ChooseMediaSourceModalContainer: React.FC = () => {
    const state = useChooseMediaSourceState();

    return <>{state.state === 'choosing' && <ChooseMediaSourceModal />}</>;
};

export const ProjectDashboardContainer: React.FC<IDashboardContainerProps> = ({ children }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [uiStack, setUIStack] = useState<IStackItem[]>([]);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [uiStackStateValue, setUIStackStateValue] = useState<IUIStackContextValue>({
        uiStack,
        setUIStack,
        selectedIndex,
        setSelectedIndex
    });

    useEffect(() => {
        setUIStackStateValue({
            uiStack,
            setUIStack,
            selectedIndex,
            setSelectedIndex
        });
    }, [uiStack, setUIStack, selectedIndex, setSelectedIndex]);

    useEffect(() => {
        const handleWindowResized = debounce(() => {
            setWindowWidth(window.innerWidth);
        }, 200);

        handleWindowResized();
        window.addEventListener('resize', handleWindowResized);
        return () => window.removeEventListener('resize', handleWindowResized);
    }, [setWindowWidth]);

    const isInMediaQueryMobileRange = isWithinRangeSmallerNoFloor(windowWidth);

    return (
        <ChooseMediaSourceContextContainer>
            <ChooseGlobalContextContainer>
                <UIStackContextProvider value={uiStackStateValue}>
                    <div css={dashboardLayoutStyle}>
                        <ChooseMediaSourceModalContainer />
                        <div css={mainBodyFlexStyle(isInMediaQueryMobileRange)}>{children}</div>
                        <DashboardDrawerContainer
                            isInMediaQueryMobileRange={isInMediaQueryMobileRange}
                        ></DashboardDrawerContainer>
                    </div>
                </UIStackContextProvider>
            </ChooseGlobalContextContainer>
        </ChooseMediaSourceContextContainer>
    );
};
