import { IScriptEditorInfo } from '../../../shared/motive/models/ScriptEditorInfo';
import { IEnumDefinition } from '../../../shared/motive/models/EnumDefinition';
import { ITypeDefinitionMap } from '../../../shared/motive/models/TypeDefinition';
import { EditorInfoActions } from './EditorInfoActions';
import { Reducer } from 'redux';
import { produce } from 'immer';

export interface IEditorInfoState {
    scriptEditorInfo: IScriptEditorInfo;
}

const DEFAULT_ENUM_STORE_STORE_VALUE: IEditorInfoState = {
    scriptEditorInfo: {
        typeDefinitions: {},
        conditionTypes: [],
        resourceTypes: []
    }
};

const getDefinitionName = (typeDef: ITypeDefinitionMap, enumId: string): string | undefined => {
    const foundItem = Object.entries(typeDef).find(([, def]) => {
        if (def.dataType === 'enum') {
            const enumDef = def as IEnumDefinition;
            if (enumDef.id === enumId) {
                return true;
            }
        }
        return false;
    });
    if (foundItem) {
        const [key] = foundItem;
        return key;
    }
};

const findEnumDefinition = (typeDef: ITypeDefinitionMap, enumId: string) => {
    const enumName = getDefinitionName(typeDef, enumId);

    if (enumName && typeDef[enumName] !== undefined) {
        const enumDef = typeDef[enumName] as IEnumDefinition;
        return enumDef;
    }
    return undefined;
};

export const EditorInfoReducer: Reducer<IEditorInfoState, EditorInfoActions> = produce(
    (state: IEditorInfoState, action: EditorInfoActions) => {
        switch (action.type) {
            case 'projectConfig/scriptEditorInfo/set': {
                state.scriptEditorInfo = action.scriptEditorInfo;
                break;
            }
            case 'enum/append': {
                if (state.scriptEditorInfo.typeDefinitions[action.enumDefinition.name] === undefined) {
                    state.scriptEditorInfo.typeDefinitions[action.enumDefinition.name] = action.enumDefinition;
                    (state.scriptEditorInfo.typeDefinitions[action.enumDefinition.name] as IEnumDefinition).items = [];
                }
                break;
            }
            case 'enum/update': {
                const enumName = getDefinitionName(state.scriptEditorInfo.typeDefinitions, action.enumId);
                if (enumName && state.scriptEditorInfo.typeDefinitions[enumName] !== undefined) {
                    delete state.scriptEditorInfo.typeDefinitions[enumName];
                    state.scriptEditorInfo.typeDefinitions[action.enumDefinition.name] = action.enumDefinition;
                }
                break;
            }
            case 'enum/delete': {
                const enumName = getDefinitionName(state.scriptEditorInfo.typeDefinitions, action.enumId);
                enumName && delete state.scriptEditorInfo.typeDefinitions[enumName];

                break;
            }
            case 'enum/enumItem/append': {
                const enumDef = findEnumDefinition(state.scriptEditorInfo.typeDefinitions, action.enumId);

                if (enumDef && !enumDef.items?.some(enumItem => enumItem.id === action.enumItem.id)) {
                    enumDef.items?.push(action.enumItem);
                }
                break;
            }
            case 'enum/enumItem/update': {
                const enumDef = findEnumDefinition(state.scriptEditorInfo.typeDefinitions, action.enumId);
                const itemIdx = enumDef?.items?.findIndex(item => item.id === action.enumItemId) ?? -1;

                if (enumDef && enumDef.items && itemIdx >= 0) {
                    enumDef.items[itemIdx] = action.enumItem;
                }
                break;
            }
            case 'enum/enumItem/delete': {
                const enumDef = findEnumDefinition(state.scriptEditorInfo.typeDefinitions, action.enumId);

                if (enumDef) {
                    enumDef.items = enumDef.items?.filter(item => item.id !== action.enumItemId);
                }
                break;
            }
        }
    },
    DEFAULT_ENUM_STORE_STORE_VALUE
);
