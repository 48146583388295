import { IBaseModalProps, BaseModal } from '../baseModal/BaseModal';
import React from 'react';
import { Button, ButtonVariant } from '../button/Button';
import {
    modalButtonStyle,
    deleteButtonStyle,
    deleteModalContentStyle,
    trashIconStyle,
    trashIconWrapperStyle,
    deleteModalButtonWrapper
} from './DeleteModal.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Size } from '../../core-ui/constants/Size';
import { useStyle } from '../../shared/hooks/useStyle';
import { useTranslation } from 'react-i18next';

interface IDeleteModalProps extends IBaseModalProps {
    onConfirm?: () => void;
}

/**
 * Extends from the modal class to provide a more stylized version when deleting objects to provide more visual attention for destructive actions.
 */
export const DeleteModal: React.FC<IDeleteModalProps> = ({ children, onConfirm, ...props }) => {
    const token = useStyle();
    const { t } = useTranslation();
    return (
        <BaseModal
            {...props}
            style={{
                content: {
                    borderTop: '2px solid #fb6969'
                }
            }}
            showCloseButton={false}
        >
            <div css={trashIconWrapperStyle}>
                <FontAwesomeIcon css={trashIconStyle} icon="trash" size="3x"></FontAwesomeIcon>
            </div>
            <div css={deleteModalContentStyle}>{children}</div>
            <div css={deleteModalButtonWrapper}>
                <Button
                    variant={ButtonVariant.HOLLOW}
                    size={Size.MEDIUM}
                    css={modalButtonStyle(token)}
                    onClick={props.onCloseModal}
                >
                    {t('cancel')}
                </Button>
                <Button size={Size.MEDIUM} css={deleteButtonStyle} onClick={onConfirm}>
                    {t('delete')}
                </Button>
            </div>
        </BaseModal>
    );
};
