import React, { DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import {
    baseButtonStyle,
    smallerButtonStyle,
    smallButtonStyle,
    mediumButtonStyle,
    largeButtonStyle,
    largerButtonStyle,
    hollowButtonStyle,
    ghostButtonStyle,
    solidButtonStyle,
    baseContentWrapperStyle,
    iconWrapperStyle,
    disabledStyle,
    childrenWithIconStyle,
    circularButtonStyle,
    inheritButtonStyle,
    tabButtonStyle,
    baseIconStyle,
    linkButtonStyle,
    iconWithChildrenStyle
} from './Button.style';
import { SerializedStyles } from '@emotion/core';
import { Size, ButtonSize } from '../constants/Size';
import { IToken } from '../../shared/contexts/styleContext';
import { useStyle } from '../../shared/hooks/useStyle';
import { IconTypes } from '../constants/IconTypes';
import { Icon } from '../icon';

export enum ButtonVariant {
    GHOST = 'GHOST',
    HOLLOW = 'HOLLOW',
    SOLID = 'SOLID',
    CIRCULAR = 'CIRCULAR',
    TAB = 'TAB',
    ACTIONLINK = 'ACTIONLINK'
}

const getButtonSizeStyle = (token: IToken, size?: ButtonSize): SerializedStyles | undefined => {
    switch (size) {
        case Size.SMALLER:
            return smallerButtonStyle(token);
        case Size.SMALL:
            return smallButtonStyle(token);
        case Size.MEDIUM:
            return mediumButtonStyle(token);
        case Size.LARGE:
            return largeButtonStyle(token);
        case Size.LARGER:
            return largerButtonStyle(token);
        case Size.INHERIT:
            return inheritButtonStyle();
        default:
            throw new Error('An invalid size enumerator was passed into getButtonSizeStyle().');
    }
};

const getButtonVariantStyle = (
    token: IToken,
    isIconOnly: boolean,
    variant?: ButtonVariant,
    selected?: boolean
): SerializedStyles | undefined => {
    switch (variant) {
        case ButtonVariant.GHOST:
            return ghostButtonStyle(token);
        case ButtonVariant.HOLLOW:
            return hollowButtonStyle(token);
        case ButtonVariant.SOLID:
            return solidButtonStyle(token);
        case ButtonVariant.CIRCULAR:
            return circularButtonStyle(token);
        case ButtonVariant.TAB:
            return tabButtonStyle(token, selected);
        case ButtonVariant.ACTIONLINK:
            return linkButtonStyle(token);
        default:
            return;
    }
};

export interface IBaseButtonProps
    extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    variant?: ButtonVariant;
    size?: ButtonSize;
    className?: string;
    icon?: IconTypes;
    iconStyle?: SerializedStyles;
    disabledStyle?: SerializedStyles;
    selected?: boolean;
    stopClickPropagation?: boolean;
}

export const Button: React.FC<IBaseButtonProps> = ({
    variant = ButtonVariant.HOLLOW,
    size = Size.MEDIUM,
    selected,
    children,
    icon,
    iconStyle,
    disabled,
    disabledStyle: overrideDisabledStyle,
    onClick,
    stopClickPropagation = true,
    ...rest
}): React.ReactElement => {
    const tokens = useStyle();

    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (onClick) {
            onClick(e);
        }

        if (stopClickPropagation) {
            e.stopPropagation();
            e.preventDefault();
        }
    };

    const iconOnly = !!icon && !children;

    return (
        <button
            {...rest}
            disabled={disabled}
            onClick={handleOnClick}
            css={[
                baseButtonStyle(iconOnly),
                getButtonSizeStyle(tokens, size as ButtonSize),
                getButtonVariantStyle(tokens, iconOnly, variant, selected),
                disabled && [disabledStyle(tokens), overrideDisabledStyle]
            ]}
        >
            <div css={baseContentWrapperStyle(tokens, size as ButtonSize, !!icon)}>
                {!icon && children}
                {icon && (
                    <>
                        <Icon
                            css={[baseIconStyle, children && iconWithChildrenStyle]}
                            icon={icon}
                            iconStyle={iconStyle}
                            wrapperStyle={iconWrapperStyle}
                            size={size as ButtonSize}
                            variant={variant}
                        />
                        <div css={childrenWithIconStyle}>{children}</div>
                    </>
                )}
            </div>
        </button>
    );
};
