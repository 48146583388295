import { useCurrentSpace } from '../../useCurrentSpace';
import { saveProjectConfigNetworkCall } from '../../../networking/ProjectConfigService';
import { useProjectConfigs } from '../../useProjectConfigs';
import { IProjectConfig } from '../../../models/ProjectConfig';
import { ICatalogInfo } from '../../../models/Catalog';
import { useNetworkCallWithoutDataSource } from '../../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useDispatch } from 'react-redux';

interface IAddProjectCatalogParams {
    projectId: string;
    catalogIds: string[];
}
export const useAddProjectCatalogs = (): IHookProvidedCall<IAddProjectCatalogParams> => {
    const [currentSpace] = useCurrentSpace();
    const {
        data: { projectConfigs }
    } = useProjectConfigs();

    const dispatch = useDispatch();

    const { triggerCall, ...rest } = useNetworkCallWithoutDataSource();

    const addProjectCatalogs = async (params: IAddProjectCatalogParams) => {
        const projectConfig = projectConfigs.find(c => c.id === params.projectId);

        if (projectConfig) {
            const newConfig: IProjectConfig = {
                ...projectConfig,
                catalogs: [
                    ...(projectConfig?.catalogs ?? []),
                    ...(params.catalogIds.map(id => ({ id: id })) as ICatalogInfo[])
                ]
            };

            await triggerCall(saveProjectConfigNetworkCall(currentSpace, newConfig, dispatch));
        }
    };

    return {
        execute: addProjectCatalogs,
        ...rest
    };
};
