import React, { useEffect, useState } from 'react';
import { Button, ButtonVariant } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Divider } from '../../core-ui/divider';
import { InputField } from '../../core-ui/inputField';
import { useStyle } from '../../shared/hooks/useStyle';
import { ConversationalAgent } from '../../shared/motive/models/ConversationalAgent';
import { AgentContainer } from '../../containers/agentContainer';
import { Agents } from '../agents';
import { createButtonStyle, createButtonWrapperStyle } from './DashboardAgentManagement.style';

interface IDashboardAgentManagementProps {
    selectedAgent?: string;
    agents: ConversationalAgent[];
    searchFilter?: string;
    onSearchAgent?: (str?: string) => void;
    onSelectAgent: (agent: string) => void;
    goToAgentCreation: () => void;
}

type AIViewState = 'viewAgents' | 'viewAgent';

export const DashboardAgentManagement: React.FC<IDashboardAgentManagementProps> = props => {
    const token = useStyle();

    const [viewState, setViewState] = useState<AIViewState>('viewAgents');

    useEffect(() => {
        if (!props.selectedAgent) {
            setViewState('viewAgents');
        } else if (props.selectedAgent) {
            setViewState('viewAgent');
        }
    }, [props.selectedAgent, setViewState]);

    let viewContent = <></>;

    switch (viewState) {
        case 'viewAgents':
            viewContent = (
                <Agents
                    onSelectAgent={props.onSelectAgent}
                    agents={props.agents}
                    searchFilter={props.searchFilter}
                    onSearchAgents={props.onSearchAgent}
                />
            );
            break;
        case 'viewAgent':
            const selectedAgent = props.agents.find(agent => agent.id === props.selectedAgent);

            if (selectedAgent) {
                viewContent = <AgentContainer agent={selectedAgent} />;
            }
            break;
    }

    return (
        <div>
            {viewState === 'viewAgents' && (
                <>
                    <div css={createButtonWrapperStyle}>
                        <InputField onChange={props.onSearchAgent} placeholder={`Search for Agents`} />
                        <Button
                            icon={IconTypes.COGS}
                            css={createButtonStyle(token)}
                            variant={ButtonVariant.SOLID}
                            onClick={props.goToAgentCreation}
                        >{`Edit Agents`}</Button>
                    </div>
                    <Divider />
                </>
            )}
            <>{viewContent}</>
        </div>
    );
};
