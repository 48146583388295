import { updateResource, updateFrameObject, updateFrame, addPreCondition } from '../../../util/ScriptUtils';
import {
    addFrame,
    removeFrame,
    addResource,
    removeResource,
    moveResource,
    findFrame,
    renameFrame
} from '../../../util/ScriptUtils';
import { Logger } from '../../../util/logger';
import { IScript, IFrame, IResourceWrapper, IScriptVariable } from '../models/Script';
import { IScriptObjectModel } from '../models/ScriptObjectModel';
import { DEFAULT_CONTEXT_VALUE } from '../../../contexts/scriptEditorContext';
import { ITypeDefinitionMap } from '../models/TypeDefinition';
import {
    IStateHistoryTrackable,
    StateHistoryAction,
    IStateHistoryUpdate,
    trackStateHistoryReducer
} from './StateHistoryTree';
import { IScriptEditorInfo } from '../models/ScriptEditorInfo';
import { addDefinedVariableToFrame } from '../../../util/ScriptDynamicsUtil';

export enum ScriptActionType {
    SET_SCRIPT = 'SET_SCRIPT',
    SCRIPT_UPDATE_CUSTOM = 'SCRIPT_UPDATE_CUSTOM',
    UPDATE_SCRIPT_VARIABLES = 'UPDATE_SCRIPT_VARIABLES',
    UPDATE_SCRIPT_EVENTS = 'UPDATE_SCRIPT_EVENTS',
    SET_TYPE_DEFINITIONS = 'SET_TYPE_DEFINITIONS',
    SCRIPT_RENAME = 'SCRIPT_RENAME',
    RESOURCE_ADD = 'RESOURCE_ADD',
    RESOURCE_WRAPPER_ADD = 'RESOURCE_WRAPPER_ADD',
    RESOURCE_REMOVE = 'RESOURCE_REMOVE',
    RESOURCE_MOVE = 'RESOURCE_MOVE',
    RESOURCE_UPDATE = 'RESOURCE_UPDATE',
    FRAME_SELECT = 'FRAME_SELECT',
    FRAME_ADD = 'FRAME_ADD',
    FRAME_REMOVE = 'FRAME_REMOVE',
    FRAME_MOVE = 'FRAME_MOVE',
    FRAME_RENAME = 'FRAME_RENAME',
    OBJECT_UPDATE = 'OBJECT_UPDATE',
    FRAME_UPDATE = 'FRAME_UPDATE',
    RESET_STATE = 'RESET_STATE',
    PRE_CONDITION_ADD = 'PRE_CONDITION_ADD',
    ADD_DEFINED_VARIABLES_TO_FRAME = 'ADD_DEFINED_VARIABLES_TO_FRAME',
    VARIABLE_DELETE = 'VARIABLE_DELETE',
    SIGNAL_RESOURCE = 'SIGNAL_RESOURCE'
}

export interface IScriptEditorStateProps extends IStateHistoryTrackable<IScriptEditorStateProps> {
    script: IScript;
    selectedFrameId: string;
    isDirty: boolean;
}

export interface IScriptEditorAction {
    type?: ScriptActionType;
    script?: IScript;
    scriptEditorInfo?: IScriptEditorInfo;
    resource?: IScriptObjectModel;
    resourceWrapper?: IResourceWrapper;
    destinationIndex?: number;
    sourceIndex?: number;
    targetFrameId?: string;
    resourceId?: string;
    destinationFrameId?: string;
    newName?: string;
    isDirty?: boolean;
    state?: IScriptEditorStateProps;
}

export interface IScripCustomUpdateAction extends IScriptEditorAction {
    type: ScriptActionType.SCRIPT_UPDATE_CUSTOM;
    update: (script: IScript) => IScript;
}

export interface IScriptEditorVariablesAction extends IScriptEditorAction {
    type: ScriptActionType.UPDATE_SCRIPT_VARIABLES;
    variables: IScriptVariable[] | undefined;
}

export interface IScriptEditorAddDefinedVariablesAction extends IScriptEditorAction {
    type: ScriptActionType.ADD_DEFINED_VARIABLES_TO_FRAME;
    variableIds: string[];
    targetFrameId: string;
}

export interface IScriptFrameAction extends IScriptEditorAction {
    frame?: IFrame;
}

export interface IResourceWrapperAction extends IScriptEditorAction {
    type: ScriptActionType.RESOURCE_WRAPPER_ADD;
    resourceWrapper: IResourceWrapper;
}

export interface IScriptObjectUpdateAction extends IScriptEditorAction {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updatedObject: any;
    updatedObjectPath: string;
    targetFrameId: string;
}

export interface IScriptFrameObjectAction extends IScriptFrameAction {
    object: IScriptObjectModel;
    objectDefinitions: ITypeDefinitionMap;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IScriptEditorActionExternalScriptScopeUpdate extends IScriptObjectUpdateAction {
    targetFrameId: string;
}

export type ScriptReducerAction =
    | IScriptEditorAction
    | IScriptFrameAction
    | IScriptObjectUpdateAction
    | IScriptEditorActionExternalScriptScopeUpdate
    | IStateHistoryUpdate<IScriptEditorStateProps>;

export function PerformScriptReducer(
    state: IScriptEditorStateProps,
    action: ScriptReducerAction
): IScriptEditorStateProps {
    switch (action.type) {
        case StateHistoryAction.UPDATE: {
            if (!action.state) {
                throw new Error('Cannot do update with no state provided in action.');
            }
            return { ...action.state };
        }
        case ScriptActionType.SCRIPT_UPDATE_CUSTOM: {
            const updateAction = action as IScripCustomUpdateAction;

            const newScript = updateAction?.update(state.script);

            if (newScript) {
                return {
                    ...state,
                    script: newScript
                };
            } else {
                throw new Error('Custom update did not return a Script.');
            }
        }
        case ScriptActionType.SET_SCRIPT: {
            if (!action.script) {
                throw new Error('Cannot set script. A new script was not provided.');
            }
            if (!action.scriptEditorInfo) {
                throw new Error('Cannot set scriptEditorInfo');
            }

            return { ...state, script: action.script };
        }
        case ScriptActionType.UPDATE_SCRIPT_VARIABLES: {
            const variableAction = action as IScriptEditorVariablesAction;
            if (!state.script) {
                throw new Error('Cannot set variables. A script was not provided.');
            }

            return {
                ...state,
                script: {
                    ...state.script,
                    variables: variableAction.variables
                }
            };
        }

        case ScriptActionType.SCRIPT_RENAME: {
            if (!state.script) {
                Logger.error('Cannot rename a script. A script was not provided.');
                break;
            }

            if (!action.newName) {
                Logger.error('Cannot rename a script. A new name was not provided.');
                break;
            }

            return { ...state, script: { ...state.script, name: action.newName }, isDirty: true };
        }

        case ScriptActionType.RESOURCE_ADD: {
            if (!state.script) {
                Logger.error('Cannot add a resource. A script was not provided.');
                break;
            }

            if (state.script.rootFrame.id === state.selectedFrameId) {
                Logger.error('Cannot add a resource. You cannot add a resource to the main frame.');
                break;
            }

            const { script, frameId } = addResource(
                state.script,
                state.selectedFrameId,
                action.resource,
                action.destinationIndex
            );

            return {
                ...state,
                script: script,
                selectedFrameId: frameId,
                isDirty: true
            };
        }

        case ScriptActionType.RESOURCE_REMOVE: {
            if (!state.script) {
                Logger.error('Cannot remove a resource. A script was not provided.');
                break;
            }

            if (action.resourceId === undefined) {
                Logger.error('Cannot remove a resource. A resource id was not provided.');
                break;
            }

            const { script, frameId } = removeResource(state.script, state.selectedFrameId, action.resourceId);
            return {
                ...state,
                script: script,
                selectedFrameId: frameId,
                isDirty: true
            };
        }

        case ScriptActionType.RESOURCE_MOVE: {
            if (!state.script) {
                Logger.error('Cannot move a resource. A script was not provided.');
                break;
            }

            if (action.sourceIndex === undefined) {
                Logger.error('Cannot move a resource. A source index was not provided.');
                break;
            }

            if (action.destinationIndex === undefined) {
                Logger.error('Cannot move a resource. A destination index was not provided.');
                break;
            }

            const { script, frameId } = moveResource(
                state.script,
                state.selectedFrameId,
                action.sourceIndex,
                action.destinationIndex
            );

            return {
                ...state,
                script: script,
                selectedFrameId: frameId,
                isDirty: true
            };
        }

        case ScriptActionType.RESOURCE_UPDATE: {
            if (!state.script) {
                Logger.error('Cannot update a resource. A script was not provided.');
                break;
            }

            if (!action.resourceWrapper) {
                Logger.error('Cannot update a resource. A resource was not provided.');
                break;
            }

            // if (action.sourceIndex === undefined) {
            //     Logger.error('Cannot update a resource. A source index was not provided.');
            //     break;
            // }

            if (!action.targetFrameId) {
                throw new Error('target frame id required');
            }

            const { script } = updateResource(state.script, action.resourceWrapper, action.targetFrameId);

            return {
                ...state,
                script: script,
                isDirty: true,
                selectedFrameId: action.targetFrameId
            };
        }

        case ScriptActionType.OBJECT_UPDATE: {
            const update = action as IScriptObjectUpdateAction;

            if (update.updatedObjectPath !== undefined) {
                const script = updateFrameObject(
                    state.script,
                    update.targetFrameId,
                    update.updatedObject,
                    update.updatedObjectPath
                );

                return {
                    ...state,
                    script: script,
                    isDirty: true,
                    selectedFrameId: update.targetFrameId
                };
            } else {
                Logger.debug('A resource was not provided.');
            }

            break;
        }

        case ScriptActionType.FRAME_UPDATE: {
            const update = action as IScriptFrameAction;

            if (update.frame !== undefined) {
                const script = updateFrame(state.script, update.frame);

                return {
                    ...state,
                    script: script,
                    isDirty: true
                };
            } else {
                Logger.debug('A resource was not provided.');
            }

            break;
        }

        case ScriptActionType.FRAME_SELECT: {
            if (!state.script) {
                Logger.error('Cannot select a frame. A script was not provided.');
                break;
            }

            if (!action.targetFrameId) {
                Logger.error('Can not select frame without a frame id');
                break;
            }

            return { ...state, selectedFrameId: action.targetFrameId };
        }

        case ScriptActionType.FRAME_ADD: {
            const faction = action as IScriptFrameAction;
            if (!state.script) {
                Logger.error('Cannot add a frame. A script was not provided.');
                break;
            }

            if (!faction.targetFrameId) {
                Logger.error('Cannot add a frame. A target frame Id was not provided');
                break;
            }

            const { script, frameId } = addFrame(state.script, faction.targetFrameId, faction.frame);
            return {
                ...state,
                script: script,
                selectedFrameId: faction?.frame?.id ?? frameId,
                isDirty: true
            };
        }

        case ScriptActionType.FRAME_REMOVE: {
            if (!state.script) {
                Logger.error('Cannot remove frame. A script was not provided.');
                break;
            }

            if (!action.targetFrameId) {
                Logger.error('Cannot remove a frame. A target frame Id was not provided');
                break;
            }

            const { script, frameId } = removeFrame(state.script, action.targetFrameId);
            return {
                ...state,
                script: script,
                selectedFrameId: frameId,
                isDirty: true
            };
        }

        case ScriptActionType.FRAME_MOVE: {
            const oldSelectedFrameId = state.selectedFrameId;

            if (!state.script) {
                Logger.error('Cannot move frames. A script was not provided.');
                break;
            }

            if (!action.destinationFrameId) {
                Logger.error('Cannot move frames. A destination frame Id was not provided.');
                break;
            }

            const frame = findFrame(state.script.rootFrame, action.targetFrameId ? action.targetFrameId : '');
            const scriptWithoutFrame = removeFrame(state.script, action.targetFrameId);
            const { script } = addFrame(
                scriptWithoutFrame.script,
                action.destinationFrameId,
                frame as IFrame,
                action.destinationIndex
            );

            return {
                ...state,
                script: script,
                selectedFrameId: oldSelectedFrameId,
                isDirty: true
            };
        }
        case ScriptActionType.FRAME_RENAME: {
            if (!action.targetFrameId) {
                Logger.error('Cannot rename frame. A target frame ID was not provided.');
                break;
            }

            if (!action.newName) {
                Logger.error('Cannot rename frame. A new name was not provided.');
                break;
            }

            if (!state.script) {
                Logger.error('Cannot rename frame. A script was not provided.');
                break;
            }

            const newScript = renameFrame(state.script, action.targetFrameId, action.newName);

            return {
                ...state,
                script: newScript,
                isDirty: true
            };
        }

        case ScriptActionType.PRE_CONDITION_ADD: {
            const faction = action as IScriptFrameObjectAction;

            if (!state.script) {
                Logger.error('Cannot add a resource. A script was not provided.');
                break;
            }

            const conditionAddFrameId = action.destinationFrameId ?? state.selectedFrameId;
            if (state.script.rootFrame.id === conditionAddFrameId) {
                Logger.error('Cannot add a resource. You cannot add a resource to the main frame.');
                break;
            }

            const { script, frameId } = addPreCondition(
                state.script,
                conditionAddFrameId,
                faction.object,
                faction.objectDefinitions
            );

            return {
                ...state,
                script: script,
                selectedFrameId: frameId,
                isDirty: true
            };
        }

        case ScriptActionType.RESET_STATE: {
            return {
                ...DEFAULT_CONTEXT_VALUE.scriptState
            };
        }

        case ScriptActionType.ADD_DEFINED_VARIABLES_TO_FRAME: {
            const { variableIds, targetFrameId } = action as IScriptEditorAddDefinedVariablesAction;
            if (!targetFrameId) {
                throw new Error('no frame id');
            }
            const nullableFrame = findFrame(state.script.rootFrame, targetFrameId);
            if (!nullableFrame) {
                throw new Error('no frame found');
            }
            let frame: IFrame = nullableFrame;
            variableIds.forEach(varId => {
                frame = addDefinedVariableToFrame(varId, frame);
            });
            const script = updateFrame(state.script, frame);
            return {
                ...state,
                script,
                isDirty: true
            };
        }
    }

    return state;
}

export function ScriptReducer(
    state: IScriptEditorStateProps | undefined,
    action: ScriptReducerAction
): IScriptEditorStateProps {
    const next = trackStateHistoryReducer(
        PerformScriptReducer,
        action as IStateHistoryUpdate<IScriptEditorStateProps>,
        [],
        state,
        action
    );

    return next;
}

export function dispatchUpdateFrame(dispatch: React.Dispatch<ScriptReducerAction>, newFrame: IFrame) {
    const action: IScriptFrameAction = {
        type: ScriptActionType.FRAME_UPDATE,
        frame: newFrame
    };

    dispatch(action);
}
