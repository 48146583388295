import React, { useState } from 'react';
import { ButtonVariant, Button } from '../../core-ui/button';
import {
    backupRowStyle,
    alternateColorStyle,
    bottomSectionStyle,
    backupItemStyle,
    contextualItemStyle,
    bundlesEmptyCollectionStyle,
    restoreUploadStyle
} from './Backup.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { ContextualMenu, IContextualMenuItem } from '../../core-ui/contextualMenu';
import { DashboardPanel } from '../dashboardPanel';
import { openInNewTab } from '../../util/JavascriptUtils';
import { EmptyCollection } from '../../core-ui/emptyCollection';
import { PublishingContainer } from '../../containers/publishingContainer';
import { Publishing } from '../publishing';
import { bundleLayout, publishingLayout, containerStyle } from './Backup.style';
import { IHookProvidedDataSource } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { times, orderBy } from 'lodash-es';
import { LoadingSkeleton } from '../../core-ui/loadingSkeleton/LoadingSkeleton';
import { DeleteModal } from '../../core-ui/deleteModal/DeleteModal';
import { RestoreUploadContainer } from '../../containers/restoreUploadContainer/RestoreUploadContainer';
import { BaseModal } from '../../core-ui/baseModal/BaseModal';
import { Heading } from '../../core-ui/heading';
import { Size } from '../../core-ui/constants/Size';
import { Divider } from '../../core-ui/divider';
import { IBundleEntity } from '../../redux/spaceKeyed/bundle/BundleReducer';
import { useTranslation } from 'react-i18next';

export interface IBackupProps {
    bundles: IHookProvidedDataSource<IBundleEntity[]>;
    onDeleteBundle: (bundle: IBundleEntity) => void;
}

interface IBundleItemProps {
    bundleEntity?: IBundleEntity;
    useAlternateColor: boolean;
    onDelete?: () => void;
}

const BundleItem: React.FC<IBundleItemProps> = ({ bundleEntity, useAlternateColor, onDelete }) => {
    const tokens = useStyle();
    const { t } = useTranslation();
    const [menuActive, setMenuActive] = useState(false);

    const bundle = bundleEntity?.bundle;

    const generateMenuItems: IContextualMenuItem[] = bundle
        ? [
              {
                  children: <div>{t('download')}</div>,
                  onPress: () => {
                      openInNewTab(bundle.url);
                  }
              },
              { children: <div>{t('delete')}</div>, onPress: () => onDelete && onDelete() }
          ]
        : [];

    const date = bundle && new Date(bundle.date);
    return (
        <div css={[backupRowStyle(tokens), useAlternateColor && alternateColorStyle(tokens)]}>
            <div css={backupItemStyle}>{bundle ? bundle.name : <LoadingSkeleton width={90} />}</div>
            <div css={backupItemStyle}>
                <div>{date ? date.toDateString() : <LoadingSkeleton width={50} />}</div>
                <div css={bottomSectionStyle(tokens)}>
                    {date ? date.toLocaleTimeString() : <LoadingSkeleton width={70} />}
                </div>
            </div>
            <div css={backupItemStyle}>
                <div>{bundle ? bundle.scenarioCount : <LoadingSkeleton width={20} />}</div>
                <div css={bottomSectionStyle(tokens)}>{bundle ? t('scenarios') : <LoadingSkeleton width={60} />}</div>
            </div>
            <div css={backupItemStyle}>
                <div>{bundle ? bundle.scriptCount : <LoadingSkeleton width={20} />}</div>
                <div css={bottomSectionStyle(tokens)}>{bundle ? t('scripts') : <LoadingSkeleton width={60} />}</div>
            </div>
            <div css={backupItemStyle}>
                <div>{bundle ? bundle.catalogCount : <LoadingSkeleton width={20} />}</div>
                <div css={bottomSectionStyle(tokens)}>{bundle ? t('catalogs') : <LoadingSkeleton width={60} />}</div>
            </div>
            <div css={contextualItemStyle}>
                {bundle && (
                    <ContextualMenu
                        alignLeft={false}
                        active={menuActive}
                        menuItems={generateMenuItems}
                        onCloseEnd={() => setMenuActive(false)}
                    >
                        <Button
                            onClick={() => setMenuActive(!menuActive)}
                            variant={ButtonVariant.HOLLOW}
                            icon={IconTypes.ELLIPSES_H}
                        ></Button>
                    </ContextualMenu>
                )}
            </div>
        </div>
    );
};

export const Backup: React.FC<IBackupProps> = ({ bundles, onDeleteBundle }) => {
    const [bundleToDelete, setBundleToDelete] = useState<IBundleEntity | undefined>(undefined);
    const [restoreFlow, setRestoreFlow] = useState(false);
    const { t } = useTranslation();

    const confirmDelete = () => {
        bundleToDelete && onDeleteBundle && onDeleteBundle(bundleToDelete);
        setBundleToDelete(undefined);
    };

    return (
        <>
            <BaseModal isOpen={restoreFlow} onCloseModal={() => setRestoreFlow(false)}>
                <div css={restoreUploadStyle}>
                    <Heading size={Size.LARGE}>{t('restoreProject')}</Heading>
                    <Divider />
                    <RestoreUploadContainer />
                </div>
            </BaseModal>
            <DeleteModal
                isOpen={!!bundleToDelete}
                onCloseModal={() => setBundleToDelete(undefined)}
                onConfirm={confirmDelete}
            >
                {t('confirmBundleDelete')}
            </DeleteModal>
            <div css={containerStyle}>
                <div css={publishingLayout}>
                    <DashboardPanel headingTitle={t('publishing')}>
                        <PublishingContainer>{props => <Publishing {...props}></Publishing>}</PublishingContainer>
                    </DashboardPanel>
                </div>
                <div css={bundleLayout}>
                    <DashboardPanel
                        headingTitle={t('bundles')}
                        buttonText={t('restore')}
                        onClickCreateButton={() => setRestoreFlow(true)}
                    >
                        <>
                            {bundles.isLoading ? (
                                times(6, idx => (
                                    <BundleItem key={idx} useAlternateColor={(idx + 1) % 2 === 0}></BundleItem>
                                ))
                            ) : (
                                <>
                                    {bundles.data.length === 0 && (
                                        <EmptyCollection
                                            css={bundlesEmptyCollectionStyle}
                                            message={t('noBundlesText')}
                                        />
                                    )}
                                    {orderBy(bundles.data, e => new Date(e.bundle.date), 'desc').map(
                                        (bundleEntity, idx) => (
                                            <BundleItem
                                                key={idx}
                                                bundleEntity={bundleEntity}
                                                useAlternateColor={(idx + 1) % 2 === 0}
                                                onDelete={() => setBundleToDelete(bundleEntity)}
                                            ></BundleItem>
                                        )
                                    )}
                                </>
                            )}
                        </>
                    </DashboardPanel>
                </div>
            </div>
        </>
    );
};
