import { ICatalog } from '../shared/motive/models/Catalog';
import { IProjectConfig } from '../shared/motive/models/ProjectConfig';

// Keep catalogs if they are included in the project config
export const filterCatalogsByProject = (catalogs: ICatalog[], projectConfig?: IProjectConfig): ICatalog[] => {
    if (!projectConfig) {
        return [];
    }

    return catalogs.filter(cat => projectConfig.catalogs?.some(pc => pc.id === cat.id));
};

export const mergeCatalogItemsIntoSingleArray = (catalogs?: ICatalog[]): any => {
    if (!catalogs) {
        return [];
    }

    const mergedAgents = catalogs.map(cats => cats.items).flat();

    return mergedAgents;
};
