/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCurrentSpace } from '../useCurrentSpace';
import { uploadMediaItemNetworkCall, uploadMediaItemPath } from '../../networking/MediaService';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { BASE_URL } from '../../networking/HttpClient';
import { IHostedMediaSource } from '../../models/MediaItem';
import { useDispatch } from 'react-redux';

export interface IUploadMediaItemParams {
    file: any;
    overwrite?: boolean;
    oldAttemptId?: string;
}

export const useUploadMediaItem = (
    throwErrors: boolean = false
): IHookProvidedCall<IUploadMediaItemParams, IHostedMediaSource | undefined> => {
    const [currentSpace] = useCurrentSpace();
    const mediaDispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource(throwErrors);
    const postMediaItem = async (params: IUploadMediaItemParams): Promise<IHostedMediaSource | undefined> => {
        return await triggerCall(
            uploadMediaItemNetworkCall(
                currentSpace,
                params.file.name,
                params.file,
                getCancelableDispatch(mediaDispatch),
                params.overwrite,
                params.oldAttemptId
            )
        );
    };

    return {
        execute: postMediaItem,
        ...rest
    };
};

export const useUploadMediaItemUrl = (): string => {
    const [currentSpace] = useCurrentSpace();

    return BASE_URL + '/' + uploadMediaItemPath(currentSpace, undefined);
};
