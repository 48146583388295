import React, { useEffect } from 'react';
import { SelectCatalogItemListContainer } from '../selectCatalogItemListContainer';
import { useDashboardDrawer } from '../../hooks/useDashboardDrawer';
import { useChooseGlobalDispatch } from '../../hooks/useChooseGlobal';
import { useAddProjectCatalogs } from '../../shared/motive/hooks/useCatalogs/useAddProjectCatalogs/useAddProjectCatalogs';
import { useCreateCatalog } from '../../shared/motive/hooks/useCatalogs';
import { useCurrentProjectId } from '../../shared/motive/hooks/useProjectConfigs/useProjectConfigs';
import { IChooseAction } from '../../shared/motive/reducers/ChooseExternalScriptScopeReducer';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';

export interface IDrawerSelectCatalogItemListContainerProps {
    type: string;
    onChoose: (action: IChooseAction) => void;
}

export const DrawerSelectCatalogItemListContainer: React.FC<IDrawerSelectCatalogItemListContainerProps> = ({
    type,
    onChoose
}) => {
    const { handleClickCatalogForSelectItem, handleSelectGlobalCatalogItem } = useDashboardDrawer();
    const globalDispatch = useChooseGlobalDispatch();
    const { execute: addProjectCatalogs } = useAddProjectCatalogs();
    const { execute: handleCreateCatalog } = useCreateCatalog();
    const projectConfigId = useCurrentProjectId();

    useEffect(() => {
        return function cleanup() {
            globalDispatch({ actionType: 'cancelChoose' });

            onChoose({
                actionType: 'cancelChoose'
            });
        };
    }, []);

    // TODO: this is (mostly) a copy of code in CatalogsContainer: would be good to put this in one place
    const handleImportCatalogs = (projectId: string, catalogIds: string[]) => {
        addProjectCatalogs({ projectId: projectId, catalogIds: catalogIds });
    };

    const handleCreateAndImport = (title: string, type: string) => {
        handleCreateCatalog({ catalogTitle: title, objectType: type, importToCurrentProject: true });
    };

    const handleChooseItem = (catalogId: string, item: IScriptObjectModel | undefined) => {
        if (item) {
            // Note: this calls popItem: leaving this call
            // here in case we need the global hooks. If we
            // take this out, then should pop this item
            // from the stack here.
            handleSelectGlobalCatalogItem(catalogId, item);

            onChoose({
                actionType: 'choose',
                chosenValue: item
            });
        }
    };

    return (
        <SelectCatalogItemListContainer
            type={type}
            onSelectCatalog={catInfo => {
                handleClickCatalogForSelectItem(catInfo, item => {
                    handleChooseItem(catInfo.id, item);
                });
            }}
            onSelectItem={handleChooseItem}
            onCreateCatalog={handleCreateAndImport}
            onImportCatalog={handleImportCatalogs}
            projectConfigId={projectConfigId}
        />
    );
};
