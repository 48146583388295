import React from 'react';
import { IScriptVariable, IFrameDefinedVariable, IFrame } from '../../shared/motive/models/Script';
import { useScriptAndGlobalVariables } from '../../shared/motive/hooks/useScriptAndGlobalVariables';
import { useStyle } from '../../shared/hooks/useStyle';
import {
    defaultVariablesStyle,
    animateVariableBgStyle
} from '../../components/frameInspectorVariables/FrameInspectorVariables.style';
import { Text } from '../../core-ui/text';
import { Size } from '../../core-ui/constants/Size';

interface IFrameAndScriptVariable extends IScriptVariable, IFrameDefinedVariable {}

export interface IFrameVariablesOptionsContainerProps {
    frame: IFrame;
    onClickSetVariableId: (variableId: string) => void;
}

export const FrameVariablesOptionsContainer: React.FC<IFrameVariablesOptionsContainerProps> = ({
    frame,
    onClickSetVariableId
}) => {
    const allVarsMap = useScriptAndGlobalVariables();
    return (
        <>
            {frame.definedVariables?.map(frameVar => {
                const frameAndScriptVar = {
                    ...frameVar,
                    ...allVarsMap[frameVar.variableId]
                };
                return (
                    <div
                        key={frameVar.variableId}
                        onClick={() => onClickSetVariableId(frameVar.variableId)}
                        title="Show variable's origin"
                    >
                        <FrameVariablesOptionsCollection {...frameAndScriptVar} />
                    </div>
                );
            })}
        </>
    );
};

const FrameVariablesOptionsCollection: React.FC<IFrameAndScriptVariable> = ({ name }) => {
    const token = useStyle();
    return (
        <div key={name} css={[defaultVariablesStyle(token), animateVariableBgStyle(token)]}>
            <Text size={Size.MEDIUM}>{name}</Text>
        </div>
    );
};
