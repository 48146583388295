import * as React from 'react';
import { useSelector } from 'react-redux';
import { rotating } from '../../constants/AnimationStyles';
import { Bar } from '../../core-ui/bar';
import { Text } from '../../core-ui/text';
import { Button, ButtonVariant } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Size } from '../../core-ui/constants/Size';
import { InputField } from '../../core-ui/inputField';
import { SelectScriptEditorIsDirty } from '../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';
import { useStyle } from '../../shared/hooks/useStyle';
import { IScript } from '../../shared/motive/models/Script';
import { dateYMD } from '../../util/DateUtils';
import {
    scriptEditorBarStyle,
    flexGroupStyle,
    scriptEditorNameStyle,
    saveButtonFlex,
    saveButtonWrapper,
    scriptEditorLastModifiedStyle,
    flexSelectingGroupStyle
} from './ScriptEditor.style';
import { IChooseAction, IChooseState } from '../../shared/motive/reducers/ChooseExternalScriptScopeReducer';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ScriptEditorBar: React.FC<{
    isSelecting: boolean;
    isSaving: boolean;
    save: (script: IScript) => Promise<void>;
    rename: (value: string) => void | undefined;
    script: IScript;
    chooseScriptScopeDispatch: React.Dispatch<IChooseAction>;
    scriptScopeState: IChooseState;
}> = ({ isSelecting, script, chooseScriptScopeDispatch, scriptScopeState, save, isSaving, rename }) => {
    const styleTokens = useStyle();
    const { t } = useTranslation();
    const isDirty = useSelector(SelectScriptEditorIsDirty);

    const handleSave = () => {
        save(script);
    };

    useEffect(() => {
        const keydownHandler = (e: KeyboardEvent) => {
            if (e.key === 's' && e.ctrlKey) {
                e.preventDefault();
                handleSave();
            }
        };

        document.addEventListener('keydown', keydownHandler);

        return () => {
            document.removeEventListener('keydown', keydownHandler);
        };
    }, [handleSave]);

    const handleCancelSelectExternalScriptScopeField = () => {
        const cancelChoose: IChooseAction = {
            actionType: 'cancelChoose'
        };

        chooseScriptScopeDispatch(cancelChoose);
    };

    return (
        <Bar css={scriptEditorBarStyle(styleTokens)}>
            {!isSelecting && (
                <>
                    <div css={flexGroupStyle}>
                        <InputField
                            css={scriptEditorNameStyle(styleTokens)}
                            size={Size.MEDIUM}
                            value={script.name}
                            onChange={rename}
                            placeholder={script.id}
                        />
                        <div css={saveButtonFlex}>
                            <div css={saveButtonWrapper}>
                                {isSaving ? (
                                    <Button
                                        size={Size.MEDIUM}
                                        variant={ButtonVariant.SOLID}
                                        disabled
                                        icon={IconTypes.LOAD_SPINNER}
                                        iconStyle={rotating}
                                    ></Button>
                                ) : (
                                    <Button
                                        size={Size.MEDIUM}
                                        disabled={!isDirty}
                                        variant={ButtonVariant.SOLID}
                                        onClick={() => handleSave()}
                                        icon={IconTypes.SAVE}
                                    >
                                        {t('save')}
                                    </Button>
                                )}
                            </div>
                        </div>
                        <Text css={scriptEditorLastModifiedStyle(styleTokens)}>
                            {t('lastModifiedDate')}
                            {` ${dateYMD(script.lastModifiedTime)}`}
                        </Text>
                    </div>
                </>
            )}
            {isSelecting && (
                <>
                    <div css={flexSelectingGroupStyle}>isSelecting {scriptScopeState?.chooseTypePrettified}</div>
                    <div css={flexSelectingGroupStyle}>
                        <Button onClick={handleCancelSelectExternalScriptScopeField}>{t('cancel')}</Button>
                    </div>
                </>
            )}
        </Bar>
    );
};
