/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCurrentSpace } from '../useCurrentSpace';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { textToSpeechPreviewCall } from '../../networking/TextToSpeechService';
import { ITextToSpeechRequest, ITextToSpeechPreviewResponse } from '../../models/TextToSpeechRequest';

export const useTextToSpeechPreview = (): IHookProvidedCall<
    ITextToSpeechRequest,
    ITextToSpeechPreviewResponse | undefined
> => {
    const [currentSpace] = useCurrentSpace();
    const { triggerCall, ...rest } = useNetworkCallWithoutDataSource();
    const postPreviewRequest = async (
        request: ITextToSpeechRequest
    ): Promise<ITextToSpeechPreviewResponse | undefined> => {
        return await triggerCall(textToSpeechPreviewCall(currentSpace, request));
    };
    return {
        execute: postPreviewRequest,
        ...rest
    };
};
