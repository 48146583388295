import { css } from '@emotion/core';

export const baseTextStyle = css``;

export const textBoldStyle = css`
    font-weight: bold;
`;

export const textItalicStyle = css`
    font-style: italic;
`;

export const textUnderlineStyle = css`
    text-decoration: underline;
`;

export const textInlineStyle = css`
    display: inline;
`;

export const smallestTextStyle = css`
    font-size: 0.25em;
`;

export const smallerTextStyle = css`
    font-size: 0.65em;
`;

export const smallTextStyle = css`
    font-size: 0.75em;
`;

export const mediumTextStyle = css`
    font-size: 0.85em;
`;

export const largeTextStyle = css`
    font-size: 0.95em;
`;

export const largerTextStyle = css`
    font-size: 1em;
`;

export const largestTextStyle = css`
    font-size: 1.5em;
`;
