import React, { useContext } from 'react';
import { useClickInAndOutElement } from '../../shared/hooks/useClickOutElement/useClickOutElement';
import { useState } from 'react';
import { noop } from 'lodash-es';
import { UIContext } from '../../stores/uiStore/UiStore';

export interface IEditableContext {
    isEditMode: boolean;
    enableEditMode: () => void;
    disableEditMode: () => void;
    blockEditModeFromToggling: () => void;
    unblockEditModeFromToggling: () => void;
}

export const EditableContext = React.createContext<IEditableContext>({
    isEditMode: false,
    enableEditMode: noop,
    disableEditMode: noop,
    blockEditModeFromToggling: noop,
    unblockEditModeFromToggling: noop
});

export const EditableOnFocus: React.FC = (props): React.ReactElement => {
    const [enabled, setEnabled] = useState(false);
    const [clickHandlersDisabled, setClickHandlersDisabled] = useState(false);
    const uiContext = useContext(UIContext);

    const anchorRef = useClickInAndOutElement<HTMLDivElement>(
        () => !clickHandlersDisabled && !uiContext.drawerIsOpen && setEnabled(false),
        () => !clickHandlersDisabled && !uiContext.drawerIsOpen && setEnabled(true)
    );

    const ctxt: IEditableContext = {
        isEditMode: enabled,
        enableEditMode: () => setEnabled(true),
        disableEditMode: () => setEnabled(false),
        blockEditModeFromToggling: () => setClickHandlersDisabled(true),
        unblockEditModeFromToggling: () => setClickHandlersDisabled(false)
    };

    return (
        <div ref={anchorRef}>
            <EditableContext.Provider value={ctxt}>{props.children}</EditableContext.Provider>
        </div>
    );
};

export const EditableOnlyProvider: React.FC = (props): React.ReactElement => {
    const ctxt: IEditableContext = {
        isEditMode: true,
        enableEditMode: noop,
        disableEditMode: noop,
        blockEditModeFromToggling: noop,
        unblockEditModeFromToggling: noop
    };

    return <EditableContext.Provider value={ctxt}>{props.children}</EditableContext.Provider>;
};
