import { getUserInfoNetworkCall } from './../../networking/AccountService';
import { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IHookProvidedDataSource } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallForDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { UserInfoActionType } from '../../../../redux/userInfo/UserInfoActions';
import { SelectUserInfo } from '../../../../redux/userInfo/UserInfoSelectors';
import { IUserInfoState } from '../../../../redux/userInfo/UserInfoReducer';

export const useUserInfo = (): IHookProvidedDataSource<IUserInfoState> & {
    dispatch: Dispatch<UserInfoActionType>;
} => {
    const state = useSelector(SelectUserInfo);
    const dispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallForDataSource(state);

    const call = async () => {
        await triggerCall(getUserInfoNetworkCall(getCancelableDispatch(dispatch)));
    };

    // @ts-ignore
    return { data: state, dispatch, load: call, ...rest };
};
