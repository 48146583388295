import React, { useState, ReactText, useEffect } from 'react';
import { IFieldEditorProps } from '../../containers/objectEditor/ObjectEditor';
import { InputField } from '../../core-ui/inputField/InputField';
import { Size } from '../../core-ui/constants/Size';
import { Text } from '../../core-ui/text';
import { inRange } from 'lodash-es';
import { useDebounce } from '../../hooks/useDebounce';
import { EditModeAware } from '../editableOnFocus/EditModeAware';

export const intEditorTestId = 'intEditorTest';

interface IIntEditorProps extends IFieldEditorProps<number | undefined> {
    minRange?: number;
    maxRange?: number;
    placeholder?: string;
}

export const IntEditor: React.FC<IIntEditorProps> = ({
    value = '',
    onChange,
    maxRange = Number.MAX_VALUE,
    minRange = -Number.MAX_VALUE,
    placeholder
}): React.ReactElement => {
    const [isInvalid, setInvalid] = useState(false);
    const [state, setState] = useState<ReactText | undefined>(value);
    const debouncedState = useDebounce(state, 200);

    useEffect(() => {
        if (debouncedState !== value) {
            onChange(debouncedState);
        }
    }, [debouncedState]);

    const handleChange = (changedValue: string) => {
        const value = parseInt(changedValue);
        if (!isNaN(value)) {
            if (!inRange(value, minRange, maxRange + 1)) {
                setInvalid(true);
            } else {
                setState(value);
                setInvalid(false);
            }
        } else {
            setState(undefined);
            setInvalid(true);
        }
    };

    const renderEditor = () => {
        return (
            <InputField
                type="number"
                size={Size.MEDIUM}
                onChange={handleChange}
                value={state}
                placeholder={placeholder}
                isInvalid={isInvalid}
                data-testid={intEditorTestId}
            />
        );
    };

    const renderReadonly = () => {
        return <Text>{state}</Text>;
    };

    return <EditModeAware editModeRender={renderEditor} readonlyModeRender={renderReadonly} />;
};
