import { IFrame } from '../../shared/motive/models/Script';
import React, { useState } from 'react';
import {
    barLayoutStyle,
    editFrameOptsStyle,
    barTabsStyle,
    frameNameInputStyle,
    frameEnableToggleStyle,
    frameNameTextStyle,
    frameNameWrapperStyle,
    optionMenuRow,
    frameSettingsButtonStyle,
    frameNameContainerStyle
} from './FrameInspectorHeader.style';
import { Bar } from '../../core-ui/bar';
import { BarVariant } from '../../core-ui/bar/Bar';
import { Size } from '../../core-ui/constants/Size';
import { Toggle } from '../../core-ui/toggle';
import { EditableText } from '../../core-ui/editableText';
import { useStyle } from '../../shared/hooks/useStyle';
import { Text } from '../../core-ui/text';
import { ContextualMenu, IContextualMenuItem } from '../../core-ui/contextualMenu';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Button, ButtonVariant } from '../../core-ui/button';

interface IFrameInspectorHeaderProps {
    frame: IFrame;
    onChangeName: (oldName: string, newName: string) => void;
    onChangeEnabled: (isEnabled: boolean) => void;
    onChangeLive: (isLive: boolean) => void;
    onChangeExclusive: (isExclusive: boolean) => void;
    onChangeResetOnClose: (isResetOnClose: boolean) => void;
    visibleRef?: (instance: HTMLDivElement | null) => void;
}

export const FRAME_HEADER_HEIGHT = 50;

export const FrameInspectorHeader: React.FC<IFrameInspectorHeaderProps> = ({
    frame,
    visibleRef,
    onChangeName,
    children,
    onChangeEnabled,
    onChangeLive,
    onChangeExclusive,
    onChangeResetOnClose
}) => {
    const token = useStyle();
    const [menuActive, setMenuActive] = useState(false);

    const generateMenuItems: IContextualMenuItem[] = [
        {
            children: (
                <div css={optionMenuRow}>
                    <Text>Exclusive</Text>
                    <Toggle
                        css={frameEnableToggleStyle}
                        size={Size.MEDIUM}
                        value={!!frame.isExclusive}
                        onClick={() => onChangeExclusive(!frame.isExclusive)}
                    />
                </div>
            )
        },
        {
            children: (
                <div css={optionMenuRow}>
                    <Text>Live Frame</Text>
                    <Toggle
                        css={frameEnableToggleStyle}
                        size={Size.MEDIUM}
                        value={!!frame.isLive}
                        onClick={() => {
                            onChangeLive(!frame.isLive);
                        }}
                    />
                </div>
            )
        }
    ];

    frame.isLive &&
        generateMenuItems.push({
            children: (
                <div css={optionMenuRow}>
                    <Text>Reset state when condition is false</Text>
                    <Toggle
                        css={frameEnableToggleStyle}
                        size={Size.MEDIUM}
                        value={!!frame.resetOnClose}
                        onClick={() => {
                            onChangeResetOnClose(!frame.resetOnClose);
                        }}
                    />
                </div>
            )
        });

    return (
        <div ref={visibleRef}>
            <Bar variant={BarVariant.SECONDARY} css={barLayoutStyle(FRAME_HEADER_HEIGHT)}>
                <div css={editFrameOptsStyle}>
                    <span css={frameNameContainerStyle}>
                        <Toggle
                            css={frameEnableToggleStyle}
                            size={Size.SMALL}
                            value={!!frame.isEnabled}
                            onClick={() => onChangeEnabled(!frame.isEnabled)}
                        />
                        <EditableText
                            textStyle={frameNameTextStyle}
                            wrapperStyle={frameNameWrapperStyle}
                            inputStyle={frameNameInputStyle(token)}
                            size={Size.MEDIUM}
                            defaultValue={frame.name}
                            onEditComplete={val => onChangeName(frame.name, val)}
                            placeholder={frame.name}
                            maxTextLength={30}
                        />
                    </span>

                    <ContextualMenu
                        alignLeft={false}
                        active={menuActive}
                        menuItems={generateMenuItems}
                        onCloseEnd={() => setMenuActive(false)}
                    >
                        <Button
                            css={frameSettingsButtonStyle}
                            onClick={() => setMenuActive(!menuActive)}
                            variant={ButtonVariant.HOLLOW}
                            icon={IconTypes.COG}
                        ></Button>
                    </ContextualMenu>
                </div>
                <div css={barTabsStyle}>{children}</div>
            </Bar>
        </div>
    );
};
