import { IProjectConfigurations } from '../../../shared/motive/models/ProjectConfig';
import produce from 'immer';
import { ProjectConfigAction } from './ProjectConfigActions';

export interface IProjectConfigState {
    configData: IProjectConfigurations;
}

export const DefaultProjectConfigs: IProjectConfigState = {
    configData: { projectConfigs: [], availableCatalogs: [] }
};

export const ProjectConfigReducer = produce((state: IProjectConfigState, action: ProjectConfigAction) => {
    switch (action.type) {
        case 'projectConfig/set': {
            state.configData = action.config;
            break;
        }
        case 'projectConfig/append': {
            state.configData.projectConfigs.push(action.projectConfig);
            break;
        }
        case 'projectConfig/update': {
            const idx = state.configData.projectConfigs.findIndex(c => c.id === action.projectConfig.id);

            if (idx < 0) {
                throw new Error('Cannot update a project config as it does not exist in the collection');
            }

            state.configData.projectConfigs[idx] = action.projectConfig;
            break;
        }
        case 'projectConfig/append/catalog': {
            const configIdx = state.configData.projectConfigs.findIndex(c => c.id === action.projectId);

            if (configIdx < 0) {
                throw new Error('Cannot add a catalog to a project config that does not exist in the collection');
            }

            state.configData.availableCatalogs.push(action.catalog);

            if (!state.configData.projectConfigs[configIdx].catalogs) {
                state.configData.projectConfigs[configIdx].catalogs = [];
            }

            state.configData.projectConfigs[configIdx].catalogs?.push(action.catalog);
            break;
        }
        case 'catalog/delete': {
            state.configData.projectConfigs.forEach(pc => {
                if (pc.catalogs) {
                    pc.catalogs = pc.catalogs.filter(cat => cat.id !== action.id);
                }
            });

            break;
        }
    }
}, DefaultProjectConfigs);
