import { IScript, IFrame, IResourceWrapper } from '../shared/motive/models/Script';

export interface IDepthFrame extends IFrame {
    depth: number;
}

export interface IDepthAndParentFrame extends IDepthFrame {
    parentFrame: IDepthFrame | undefined;
}

/**
 * Goes through every frame from the given script and performs the operation on each frame. It can return an array
 * of type T. This is useful when trying to locate things and return items in the script
 * @param script The script that contains the frames to traverse through
 * @param operation A operation that is performed on every frame, can return a generic type from each frame
 */
export const recursivelyProcessFrames = <T>(script: IScript, operation: (frame: IDepthFrame) => T | undefined): T[] => {
    const processFramesInChildren = (parentFrame: IDepthFrame, operationData: T[] = []): T[] => {
        const performAction = operation(parentFrame);

        performAction && operationData.push(performAction);

        const depth = parentFrame.depth + 1;
        parentFrame.subFrames &&
            parentFrame.subFrames.forEach(frame => processFramesInChildren({ ...frame, depth }, operationData));

        return operationData;
    };

    return processFramesInChildren({ ...script.rootFrame, depth: 0 });
};

const recursivelyProcessFramesIncludeParent = <T>(
    script: IScript,
    operation: (frame: IDepthAndParentFrame) => T | undefined
): T[] => {
    const processFramesInChildren = (parentFrame: IDepthAndParentFrame, operationData: T[] = []): T[] => {
        const performAction = operation(parentFrame);

        performAction && operationData.push(performAction);

        const depth = parentFrame.depth + 1;
        parentFrame.subFrames &&
            parentFrame.subFrames.forEach(frame =>
                processFramesInChildren({ ...frame, depth, parentFrame }, operationData)
            );

        return operationData;
    };

    return processFramesInChildren({ ...script.rootFrame, depth: 0, parentFrame: undefined });
};

/**
 * Flattens all frames and returns them in an array
 */
export const getAllFrames = (script: IScript): IDepthFrame[] => {
    const getFramesOp = (frame: IDepthFrame) => frame;

    return recursivelyProcessFrames(script, getFramesOp);
};

/**
 * Flattens all frames and returns them in an array
 */
export const getAllFramesWithParent = (script: IScript): IDepthAndParentFrame[] => {
    const getFramesOp = (frame: IDepthAndParentFrame) => frame;

    return recursivelyProcessFramesIncludeParent(script, getFramesOp);
};

/**
 * Flattens all frames and returns them in an array with only the resources filtered that match any type.
 * @param type The list of resource types that when matched will return the frame it is contained in.
 */
export const getAllFramesFilteredByResourceTypes = (script: IScript, type: string[] = []): IFrame[] => {
    const getFramesByResourceTypeOp = (frame: IFrame): IFrame | undefined => {
        let filteredResources: IResourceWrapper[] | undefined | null;
        if (type.some(t => t === 'motive.core.object')) {
            filteredResources = frame.resources;
        } else {
            filteredResources =
                frame.resources && frame.resources.filter(wrapper => type.some(t => t === wrapper.resource.type));
        }

        if (filteredResources && filteredResources.length > 0) {
            return {
                ...frame,
                resources: [...filteredResources]
            };
        }
        return;
    };

    return recursivelyProcessFrames(script, getFramesByResourceTypeOp);
};
