import { useSelector } from 'react-redux';
import { SelectCatalogsWithTypeFilter } from '../../../../../redux/spaceKeyed/catalog/CatalogSelectors';
import { useProjectConfig, useCurrentProjectId } from '../../useProjectConfigs/useProjectConfigs';

export const useProjectCatalogsWithTypes = (types: string[], projectId: string) => {
    const { data: project } = useProjectConfig(projectId);

    const catalogs = useSelector(SelectCatalogsWithTypeFilter(types));
    if (project && catalogs) {
        return catalogs.filter(c => project.catalogs?.some(pc => pc.id === c.id));
    }

    return [];
};

export const useCurrentProjectCatalogsWithTypes = (types: string[]) => {
    const projectId = useCurrentProjectId();
    const { data: project } = useProjectConfig(projectId);

    const catalogs = useSelector(SelectCatalogsWithTypeFilter(types));

    if (project && catalogs) {
        return catalogs.filter(c => project.catalogs?.some(pc => pc.id === c.id));
    }

    return [];
};
