import { css } from '@emotion/core';
import { link } from 'fs';
import { get, isFunction } from 'lodash-es';
import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import useCookies from 'react-cookie/es6/useCookies';

const defaultMessage = () => {
    const getLink = (slug: string) => `https://motive.io/${slug}`;
    const linkStyle = css`
        a {
            color: gray;
        }
    `;
    return (
        <p css={linkStyle}>
            Motive.io uses cookies to enhance the user experience. By continuing to use this site you accept our{' '}
            <a href={getLink('cookie-policy')}>Cookie Policy</a>, <a href={getLink('privacy-policy')}>Cookie Policy</a>,
            and <a href={getLink('terms-of-service')}>Terms of Service</a>.
        </p>
    );
};
const cookieName = 'MotiveCookieConsent';

const ClickerInterceptLayer: React.FC<{ cookieAccepted: boolean }> = ({ children, cookieAccepted = false }) => {
    const noClickStyle = css`
        pointer-events: none;
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
        background-color: #ccc;
    `;

    const clickStyle = css`
        pointer-events: auto;
    `;

    return <div css={cookieAccepted ? clickStyle : noClickStyle}>{children}</div>;
};

export const MotiveCookieConsent: React.FC<{ message?: string }> = ({ message = defaultMessage, children }) => {
    const [cookies] = useCookies([cookieName]);

    const isUserConsentingToCookies = get(cookies, [cookieName], false);
    const [didUserAccept, setDidUserAccept] = useState(isUserConsentingToCookies);

    return (
        <>
            <ClickerInterceptLayer cookieAccepted={didUserAccept}>{children}</ClickerInterceptLayer>
            <CookieConsent
                style={{
                    background: 'rgb(23, 23, 32)',
                    color: 'white',
                    height: '10vh',
                    textAlign: 'center',
                    fontSize: '20px',
                    marginBotton: '25vh'
                }}
                buttonStyle={{
                    background: 'rgb(78, 187, 112)',
                    color: 'white',
                    fontWeight: 'bolder',
                    height: '6vh',
                    marginBottom: '2vh',
                    fontSize: '15px'
                }}
                hideOnAccept={true}
                buttonText={'I accept.'}
                cookieName={cookieName}
                onAccept={() => setDidUserAccept(true)}
            >
                {isFunction(message) ? message() : message}
            </CookieConsent>
        </>
    );
};
