import { css } from '@emotion/core';

export const mainBodyFlexStyle = (isMobileView: boolean) => css`
    flex: 1;
    flex-direction: ${isMobileView ? 'column' : 'row'};
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const dashboardLayoutStyle = css`
    display: flex;
    flex-direction: row;
    height: 100%;
`;
