import * as React from 'react';
import Avatar from 'react-avatar';
import { ClickableUserIconStyle, UserIconStyle } from './UserIcon.style';

interface IUserIconViewProps {
    name: string;
    size?: string;
    gravatar?: string;
    email?: string;
    onClick?: (event: React.SyntheticEvent) => void;
    className?: string;
}

export const UserIcon: React.FC<IUserIconViewProps> = ({
    name,
    size = '40',
    gravatar,
    onClick,
    email
}): React.ReactElement => {
    return (
        <Avatar
            css={UserIconStyle}
            src={gravatar}
            name={name}
            email={email}
            size={size}
            round={true}
            onClick={onClick}
            style={onClick && ClickableUserIconStyle}
        />
    );
};
