import { css } from '@emotion/core';
import { IToken } from '../../../shared/contexts/styleContext';

export const localizedMediaEditorStyle = (type?: string) => css`
    ${type && type.split('/')[0] !== 'audio' && `margin: auto;`};
`;

export const addMediaTextStyle = css`
    cursor: pointer;
`;

const dragOverStyle = (token: IToken) => `
    background-color:${token.colors.background.colorLighter};
`;

export const itemDropStyle = (token: IToken, isDraggingOver: boolean) => css`
    ${isDraggingOver && dragOverStyle(token)};
`;

export const cardStyle = css`
    box-shadow: none;
`;

export const cardHeadingTextStyle = (token: IToken) => css`
    color: ${token.colors.staticColors.white};
`;

export const flexLineBreakDiv = css`
    flex-grow: 1;
    flex-basis: 100%;
`;

export const placeholderWrapper = css`
    display: none;
`;

export const draggingTextStyle = (token: IToken) => css`
    margin: auto;
    text-align: center;
    color: ${token.colors.font.secondary};
`;

export const dragZoneStyle = (token: IToken) => css`
    border: 2px dashed ${token.colors.background.neutral};
    text-align: center;
    padding: ${token.spacings.smaller};
`;

export const dragZoneButtonStyle = css`
    margin: 0;
    padding: 0;
    width: 100%;
`;

export const spacingDivStyle = css``;

export const objectBoxWrapper = css`
    width: 100%;
`;
