import { useCurrentSpace } from '../useCurrentSpace';
import { DeleteCollaborator } from '../../networking/CollaboratorService';
import { ICollaborator, IPendingCollaborator } from '../../models/Collaborator';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { useDispatch } from 'react-redux';

export interface IDeleteCollaboratorParams {
    collaborator: ICollaborator | IPendingCollaborator;
}
export const useDeleteCollaborator = (): IHookProvidedCall<IDeleteCollaboratorParams> => {
    const [currentSpace] = useCurrentSpace();

    const dispatch = useDispatch();

    const { triggerCall, ...rest } = useNetworkCallWithoutDataSource();

    async function call(params: IDeleteCollaboratorParams) {
        await triggerCall(DeleteCollaborator(currentSpace, params.collaborator, dispatch));
    }

    return {
        execute: call,
        ...rest
    };
};
