import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const frameToolsContainerStyle = (token: IToken) => css`
    display: grid;
    grid-template-rows: auto 1fr;
    background-color: ${token.colors.background.dark};
`;

export const tabListStyle = css`
    display: flex;
    width: 305px;
    border-bottom: none;
`;

export const tabMinWidthStyle = (token: IToken) => css`
    min-width: 72px;
    border: none;
    background: rgba(250, 250, 250, 0.2);
    border-radius: 5px 5px 0px 0px;
    box-sizing: border-box;

    //border-top: 1px solid transparent;
    &[aria-selected='true'] {
        background: ${token.colors.background.dark};
        color: white;
        //border-top: 1px solid #b1b4b8;
    }
    font-size: ${token.fontSizes.small};
`;

export const tabPanelsStyle = ({ colors }: IToken) => css`
    border: 5px solid ${colors.background.dark};
    box-sizing: border-box;
    width: 305px;
`;
