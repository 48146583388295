/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useGetCatalogById, useSaveCatalog } from '../../shared/motive/hooks/useCatalogs';
import { Catalog, ICatalogProps } from '../../components/catalog';
import { useUIStack } from '../../shared/hooks/useUIStack';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { setWith, clone, isEqual } from 'lodash-es';
import { IObjectEditorProps } from '../objectEditor';
import { useCreateScriptObject } from '../../shared/motive/hooks/useCreateScriptObject';

export interface ICatalogContainerRenderProps extends ICatalogProps {
    onCatalogSave: () => Promise<void>;
    onCreateEmptyCatalogItem: () => void;
    onDeleteCatalogItem: (index: number) => void;
    isCatalogDirty: boolean;
    isCatalogSaving: boolean;
    catalogId: string;
}
export interface ICatalogContainerProps {
    catalogId: string;
    title?: string;
    children?: (renderProps: ICatalogContainerRenderProps) => React.ReactNode;
    onAfterSave?: (success: boolean) => void;
}

export const CatalogContainer: React.FC<ICatalogContainerProps> = ({ catalogId, children, onAfterSave }) => {
    const { data: networkCat, isLoading: byIdIsLoading } = useGetCatalogById(catalogId);

    const { setElementTitle } = useUIStack();
    const { execute: saveCatalog, isLoading: isCatalogSaving } = useSaveCatalog();
    const [catalog, setCatalog] = useState<ICatalog<any> | undefined>(undefined);
    const createScriptObject = useCreateScriptObject();

    useEffect(() => {
        if (!catalog) {
            setCatalog(networkCat);
        }
    }, [networkCat]);

    const handleChange: IObjectEditorProps['onChange'] = (path, value) => {
        if (!catalog) {
            throw new Error('no catalog found to change');
        }
        const newCatalog = setWith(clone(catalog), path, value, clone);

        setCatalog(newCatalog);
    };

    const handleCatalogSave = async () => {
        if (!catalog) {
            throw new Error('no catalog in props, aborting save');
        }

        const cat = await saveCatalog({ catalog: catalog });
        if (cat) {
            setCatalog(cat);
            onAfterSave && onAfterSave(true);
        }
    };

    const handleCreateEmptyCatalogItem = () => {
        if (catalog?.items && catalog?.objectType) {
            handleChange(path, [...catalog.items, createScriptObject(catalog.objectType)]);
        } else {
            throw new Error('unable to create catalog item');
        }
    };

    const handleDeleteCatalogItem = (index: number) => {
        if (catalog?.items) {
            const newItems = [...catalog.items];
            newItems.splice(index, 1);
            handleChange(path, newItems);
        }
    };

    const isLoading = byIdIsLoading || !catalog;
    const isCatalogDirty = !isEqual(networkCat, catalog);
    // Update media items state on mount
    useEffect(() => {
        if (catalog?.name) {
            setElementTitle(`catalog:${catalog.id}`, <span>{catalog.name}</span>);
        }
    }, [catalog?.name, catalog?.id]);

    const path = 'items';

    return (
        <>
            {children &&
                children({
                    catalog: catalog,
                    onChange: handleChange,
                    path: path,
                    isLoading: isLoading,
                    onCreateEmptyCatalogItem: handleCreateEmptyCatalogItem,
                    onCatalogSave: handleCatalogSave,
                    onDeleteCatalogItem: handleDeleteCatalogItem,
                    catalogId,
                    isCatalogDirty,
                    isCatalogSaving
                })}
            {!children && catalog && (
                <>
                    <Catalog catalog={catalog} onChange={handleChange} path={path} />
                </>
            )}
        </>
    );
};
