import { SerializedStyles } from '@emotion/core';
import { useState, useEffect } from 'react';
import { KeyCode } from '../../constants/KeyCode';
import { InputField } from '../inputField/InputField';
import React from 'react';
import { Size, EditableTextSize } from '../constants/Size';
import { Text } from '../text';
import { Heading } from '../heading';
import { IToken } from '../../shared/contexts/styleContext';
import {
    baseWrapperStyle,
    smallEditableTextStyle,
    mediumEditableTextStyle,
    largeEditableTextStyle,
    placeholderStyle,
    disabledEditableTextStyle,
    baseEditableStyle
} from './EditableText.style';
import { useStyle } from '../../shared/hooks/useStyle';

export interface IEditableTextProps {
    active?: boolean;
    value?: string;
    defaultValue?: string;
    placeholder?: string;
    onEditComplete?: (value: string) => void;
    onChange?: (changedValue: string) => void;
    type?: EditableTextType;
    size?: EditableTextSize;
    wrapperStyle?: SerializedStyles;
    textStyle?: SerializedStyles;
    inputStyle?: SerializedStyles;
    maxTextLength?: number;
    className?: string;
    disabled?: boolean;
    regex?: string;
}

export enum EditableTextType {
    HEADING,
    TEXT
}

const getEditableTextSizeStyle = (size: Size, token: IToken) => {
    switch (size) {
        case Size.SMALL:
            return smallEditableTextStyle(token);
        case Size.MEDIUM:
            return mediumEditableTextStyle(token);
        case Size.LARGE:
            return largeEditableTextStyle(token);
        default:
            return mediumEditableTextStyle(token);
    }
};

const handleDisplayText = (text: string, maxLength: number): string => {
    return text.length > maxLength ? `${text.substring(0, maxLength - 3 >= 0 ? maxLength - 3 : 0)}...` : text;
};

const getTextComponent = (
    type: EditableTextType,
    token: IToken,
    sizeStyle: SerializedStyles,
    textStyle?: SerializedStyles,
    value?: string,
    defaultValue?: string,
    placeholder?: string,
    maxTextLength?: number
) => {
    const displayValue = value || defaultValue || placeholder || '\u00A0';
    const isPlaceholder = !value && !defaultValue && !!placeholder;

    const textStyles = [sizeStyle, isPlaceholder && placeholderStyle(token), textStyle];
    const displayText = maxTextLength ? handleDisplayText(displayValue, maxTextLength) : displayValue;

    switch (type) {
        case EditableTextType.HEADING:
            return <Heading css={textStyles}>{displayText}</Heading>;
        default:
            return <Text css={textStyles}>{displayText}</Text>;
    }
};

/**
 * @props active - an override to this component's default state
 * @props value - the input field's value.
 * @props defaultValue - the input field's initial value
 * @props placeholder - displayed when the input field's value is an empty string
 * @props type - defines the type of the static text
 * @props onEditComplete - callback method when the user clicks out or enter
 * @props onChange - callback method when the event.target.value is changed
 * @props size - allows for spacing-specific styling that gets applied to the text and the input field to ensure consistent sizing
 * @props inputStyle - the override styling for the input field
 * @props textStyle - the override styling for the display text
 * @props wrapperStyle - the styling for the wrapper
 */

export const EditableText: React.FC<IEditableTextProps> = ({
    active = false,
    value,
    defaultValue,
    placeholder,
    onEditComplete,
    onChange,
    type = EditableTextType.TEXT,
    size = Size.MEDIUM,
    wrapperStyle,
    textStyle,
    inputStyle,
    maxTextLength,
    className,
    regex,
    disabled = false
}): React.ReactElement => {
    const [isEditing, setEdit] = useState<boolean>(active);
    const token = useStyle();

    const regexObj = regex ? new RegExp(regex) : undefined;

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!disabled) {
            event.preventDefault();
            event.stopPropagation();
            setEdit(true);
        }
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
        setEdit(true);
        event.currentTarget.select();
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        setEdit(false);
        onEditComplete && onEditComplete(event.currentTarget.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        switch (event.key) {
            case KeyCode.ENTER:
                event.currentTarget.blur();
                break;
            case KeyCode.ESCAPE:
                event.currentTarget.value = defaultValue ? defaultValue : '';
                event.currentTarget.blur();
                break;
        }
    };

    const sizeStyle: SerializedStyles = getEditableTextSizeStyle(size, token);

    useEffect(() => {
        setEdit(active);
    }, [active]);

    return (
        <div
            css={!isEditing && [baseWrapperStyle(token), wrapperStyle, disabled && disabledEditableTextStyle]}
            onClick={handleClick}
            className={className}
        >
            {isEditing ? (
                <InputField
                    autoFocus
                    size={size as EditableTextSize}
                    css={[sizeStyle, baseEditableStyle, inputStyle]}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    isInvalid={regexObj ? !regexObj.test(value ?? '') : false}
                />
            ) : (
                getTextComponent(type, token, sizeStyle, textStyle, value, defaultValue, placeholder, maxTextLength)
            )}
        </div>
    );
};
