import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const storyFlowStyle = ({ colors }: IToken) => css`
    background: ${colors.background.lighter};
    display: grid;
    grid-template-columns: minmax(10%, 4fr) 9fr;
    grid-auto-rows: 1fr;
    flex: 1;
`;
