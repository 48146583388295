import React, { useContext } from 'react';

export interface IResourceReference {
    [key: string]: React.RefObject<HTMLDivElement>;
}

interface IObjectReferenceValues {
    resourceReferences: IResourceReference;
    conditionReference: React.RefObject<HTMLDivElement>;
    scrollToResource: (id: string) => void;
    scrollToCondition: () => void;
    queueResourceScroll?: string;
}

const defaultReferences: IObjectReferenceValues = {
    resourceReferences: {},
    conditionReference: { current: null },
    scrollToCondition: () => undefined,
    scrollToResource: () => undefined,
    queueResourceScroll: undefined
};

const objectReferenceContext = React.createContext<IObjectReferenceValues>(defaultReferences);

export const ObjectReferenceContextProvider = objectReferenceContext.Provider;
export const useObjectReferenceContext = () => useContext(objectReferenceContext);
