import { IDropdownOption } from '../dropdown/Dropdown';
import { ITypeBundle } from '../../shared/motive/models/TypeBundle';
import { IEnumDefinitionItem } from '../../shared/motive/models/EnumDefinition';

export const generateEnumOptions = (items?: IEnumDefinitionItem[], includeEmpty?: boolean): IDropdownOption[] => {
    const opts: IDropdownOption[] = items
        ? items.map(item => ({ label: item.label ? item.label : '', value: item.id }))
        : [];

    if (includeEmpty) {
        opts.unshift({ label: '', value: undefined });
    }

    return opts;
};

export const generateTypeBundleOptions = (items: ITypeBundle[]): IDropdownOption[] => {
    let opts: IDropdownOption[] = [];

    items.forEach(i => {
        opts = opts.concat(
            i.typeReferences.map(r => {
                return { value: r.fullName, label: r.displayName };
            })
        );
    });

    return opts;
};
