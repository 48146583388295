/* eslint-disable @typescript-eslint/no-explicit-any */
// istanbul ignore file MOTIVE_EXCEPTION
import React from 'react';
import { ITypeReference, ITypeBundle } from '../../shared/motive/models/TypeBundle';
import { Text } from '../../core-ui/text';
import { Size } from '../../core-ui/constants/Size';
import { useStyle } from '../../shared/hooks/useStyle';
import {
    conditionItemTextStyle,
    conditionGroupContainerStyle,
    conditionIconStyle,
    baseConditionItemStyle
} from './ConditionsPanel.style';
import { IToken } from '../../shared/contexts/styleContext';
import { ListOrGrid } from '../../core-ui/collection/Collection';
import { useTypeDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { IconProp, IconName } from '@fortawesome/fontawesome-svg-core';
import { Icon } from '../../core-ui/icon';
import { getTypeIcon, CONDITION_ICON_DEFAULT } from '../../util/ObjectDefinitionsUtils';
import { FrameToolsPanel, IFrameToolsSection } from '../frameToolsPanel/FrameToolsPanel';
import { IObjectDrag } from '../_common/DragAndDrop/DragModels/DragModels';
import { DRAG_ITEM_TYPE_CREATE_CONDITION } from '../../constants/DragAndDrop';

export interface IConditionsPanelProps {
    conditionTypes: ITypeBundle[];
    display?: ListOrGrid;
    onDoubleClick?: (type: string) => void;
    filter?: string;
    onSearchChange: (value: string) => void;
}

export function ConditionGroupLabel(obj: any): React.ReactElement {
    return <div css={conditionGroupContainerStyle}>{obj.bundleName}</div>;
}

interface IRenderSingleItem {
    condition: ITypeReference;
    display: ListOrGrid;
    styleToken: IToken;
    onDoubleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    editorIcon: string;
}

const Item: React.FC<IRenderSingleItem> = ({ condition, styleToken: token, display, onDoubleClick, editorIcon }) => {
    return (
        <div css={[baseConditionItemStyle(token)]} onDoubleClick={onDoubleClick}>
            <Icon iconStyle={conditionIconStyle(token)} size={Size.LARGER} faIcon={editorIcon as IconProp} />
            <Text css={conditionItemTextStyle(token, display)} size={Size.SMALL}>
                {condition.displayName}
            </Text>
        </div>
    );
};

export const ConditionsPanel = React.memo(function ConditionsPanel(props: IConditionsPanelProps) {
    const { conditionTypes, filter, display = 'grid', onDoubleClick } = props;
    const typeDefinitions = useTypeDefinitions();
    const token = useStyle();

    const toolsData: IFrameToolsSection<IObjectDrag>[] = conditionTypes.map(t => {
        return {
            title: t.bundleName,
            items: t.typeReferences.map(r => {
                const editorIcon = getTypeIcon(typeDefinitions, r.fullName, CONDITION_ICON_DEFAULT) as IconName;

                const renderItem = () => (
                    <Item
                        onDoubleClick={() => onDoubleClick?.(r.fullName)}
                        styleToken={token}
                        editorIcon={editorIcon}
                        key={r.fullName}
                        condition={r}
                        display={'grid'}
                    />
                );

                return {
                    renderItem: renderItem,
                    key: r.fullName,
                    searchTerms: [r.fullName, r.displayName],
                    drag: {
                        dragType: DRAG_ITEM_TYPE_CREATE_CONDITION,
                        objectType: r.fullName
                    }
                };
            })
        };
    });

    return (
        <FrameToolsPanel<IObjectDrag>
            display={display}
            filter={filter}
            sections={toolsData}
            onSearchInputChange={props.onSearchChange}
        />
    );
});
