import React from 'react';
import { IPendingCollaborator } from '../../shared/motive/models/Collaborator';
import { Text } from '../../core-ui/text';
import { useStyle } from '../../shared/hooks/useStyle';
import { Size } from '../../core-ui/constants/Size';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Button } from '../../core-ui/button';
import { RowItem } from '../../core-ui/rowItem';
import { IDeleteCollaboratorParams } from '../../shared/motive/hooks/useDeleteCollaborator/useDeleteCollaborator';
import { LoadingSkeleton } from '../../core-ui/loadingSkeleton/LoadingSkeleton';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { pendingItemStyle } from './SidebarPendingCollaboratorItem.style';

interface ISidebarPendingCollaboratorItemProps {
    collaborator?: IPendingCollaborator;
    deleteCollaborator?: IHookProvidedCall<IDeleteCollaboratorParams>;
}
export const SidebarPendingCollaboratorItem: React.FC<ISidebarPendingCollaboratorItemProps> = ({
    collaborator,
    deleteCollaborator
}) => {
    const tokens = useStyle();

    return (
        <RowItem css={pendingItemStyle(tokens)} size={Size.SMALL}>
            {() => ({
                content: collaborator ? (
                    <Text>{collaborator.InviteeEmail}</Text>
                ) : (
                    <LoadingSkeleton width={100}></LoadingSkeleton>
                ),
                rightContent: collaborator && deleteCollaborator && (
                    <Button
                        onClick={async () => await deleteCollaborator.execute({ collaborator })}
                        icon={IconTypes.TIMES}
                    ></Button>
                )
            })}
        </RowItem>
    );
};
