import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const actionBarWrapperStyle = css`
    display: flex;
    align-items: center;
`;

export const searchStyle = css`
    width: 96%;
    margin: 2%;
`;

export const createCatalogWrapperStyle = (token: IToken) => css`
    padding: ${token.spacings.small};
    border: 1px solid ${token.colors.border.primary};
`;

export const drawerIntentCatalogsWrapperStyle = (token: IToken) => css`
    padding-left: ${token.spacings.smaller};
`