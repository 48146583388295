import React from 'react';
import { Button } from '../../core-ui/button';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { IFieldEditorProps } from '../../containers/objectEditor';
import { IScriptFrameAction, ScriptActionType } from '../../shared/motive/reducers/ScriptEditorReducers';
import { createCustomEventLinkFrame, findCustomEvents } from '../../util/ScriptUtils';
import { InputField } from '../../core-ui/inputField';
import { Size } from '../../core-ui/constants/Size';
import { Text } from '../../core-ui/text';
import { useCustomEventContext } from '../../contexts/customEventContext';
import { customEventEditorStyle } from './CustomEventEditor.style';
import { EditModeAware } from '../editableOnFocus/EditModeAware';
import { useObjectDefinitions, useScriptEditorInfo } from '../../shared/motive/hooks/useScriptEditorInfo';
import {
    SelectScriptEditorCurrentScript,
    SelectScriptEditorSelectedFrameId
} from '../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';
import { useDispatch, useSelector } from 'react-redux';

export const customEventTypes: string[] = [MotiveTypes.CUSTOM_EVENT, MotiveTypes.CUSTOM_EVENT_CONDITION];

export const CustomEventEditor: React.FC<IFieldEditorProps<string>> = ({
    value,
    onChange,
    parentObjectEditorProps
}): React.ReactElement => {
    const scriptDispatch = useDispatch();
    const script = useSelector(SelectScriptEditorCurrentScript);
    const selectedFrameId = useSelector(SelectScriptEditorSelectedFrameId);

    const scriptEditorInfo = useScriptEditorInfo();
    const { customEvents, setCustomEvents } = useCustomEventContext();
    const objectDefinitions = useObjectDefinitions();

    const handleEventLink = (): void => {
        if (value) {
            const action: IScriptFrameAction = {
                script: script,
                scriptEditorInfo: scriptEditorInfo.data,
                type: ScriptActionType.FRAME_ADD,
                targetFrameId: selectedFrameId,
                frame: createCustomEventLinkFrame(`On ${value}`, value, objectDefinitions)
            };

            scriptDispatch(action);
        }
    };

    const updateCustomEvents = () => {
        const list = findCustomEvents(script);
        setCustomEvents(list);
    };

    const renderEditor = () => {
        return (
            <div css={customEventEditorStyle}>
                <InputField
                    size={Size.MEDIUM}
                    value={value}
                    onFocus={updateCustomEvents}
                    onChange={(newVal: string) => onChange(newVal)}
                    suggestionList={'customEvents'}
                />
                <datalist id={'customEvents'}>
                    {customEvents.map((customEvent: string) => {
                        return <option key={customEvent} value={customEvent} />;
                    })}
                </datalist>

                {parentObjectEditorProps.type === MotiveTypes.CUSTOM_EVENT && (
                    <Button onClick={handleEventLink}>Create Event Link</Button>
                )}
            </div>
        );
    };

    const renderReadOnly = () => {
        return <Text>{value}</Text>;
    };

    return <EditModeAware editModeRender={renderEditor} readonlyModeRender={renderReadOnly} />;
};
