import React, { useState } from 'react';
import { InputField } from '../../core-ui/inputField';
import { Text } from '../../core-ui/text';
import { Size } from '../../core-ui/constants/Size';
import { IEditorColor, IEditorColorObjectModel } from '../../shared/motive/models/EditorColor';
import { IFieldEditorProps } from '../../containers/objectEditor';
import { ColorPicker } from '../../core-ui/colorPicker/ColorPicker';
import { colorEditorStyle, generateRGBaString } from './ColorEditor.style';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useClickOutElement } from '../../shared/hooks/useClickOutElement/useClickOutElement';
import { FieldTypes } from '../../shared/motive/models/ScriptObjectModel';
import { useCreateScriptObject } from '../../shared/motive/hooks/useCreateScriptObject';
import { EditModeAware } from '../editableOnFocus/EditModeAware';

export const getRGBRatio = (initValues: IEditorColor | undefined): IEditorColor => {
    if (!initValues) {
        return { r: 0, g: 0, b: 0 };
    }

    return { r: initValues.r / 255, g: initValues.g / 255, b: initValues.b / 255, a: initValues.a };
};

export const getRGBValues = (initValues: IEditorColor | undefined): IEditorColor => {
    if (!initValues) {
        return { r: 0, g: 0, b: 0 };
    }

    return {
        r: Math.round(initValues.r * 255),
        g: Math.round(initValues.g * 255),
        b: Math.round(initValues.b * 255),
        a: initValues.a
    };
};

export const ColorEditor: React.FC<IFieldEditorProps<IEditorColorObjectModel>> = ({ value, onChange }) => {
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
    const createScriptObject = useCreateScriptObject();

    const handleOnClickOut = (): void => setShowColorPicker(false);
    const anchorRef = useClickOutElement<HTMLDivElement>(handleOnClickOut);

    const handleChange = (color: IEditorColor | undefined) => {
        let newValue: FieldTypes = value;
        if (!value) {
            newValue = createScriptObject(MotiveTypes.COLOR);
        }
        newValue = {
            ...newValue,
            ...getRGBRatio(color)
        };

        onChange(newValue);
    };

    const renderEditor = () => {
        return (
            <div ref={anchorRef}>
                <InputField
                    size={Size.MEDIUM}
                    value={generateRGBaString(getRGBValues(value))}
                    onFocus={() => setShowColorPicker(true)}
                    css={colorEditorStyle(getRGBValues(value))}
                />
                {showColorPicker && <ColorPicker color={getRGBValues(value)} onChangeComplete={handleChange} />}
            </div>
        );
    };

    const renderReadonly = () => {
        return <Text>{generateRGBaString(getRGBValues(value))}</Text>;
    };

    return <EditModeAware readonlyModeRender={renderReadonly} editModeRender={renderEditor} />;
};
