import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const rightClickMenuStyle = (xPos: string, yPos: string, isActive: boolean) => css`
    position: fixed;
    z-index: 1;
    width: 140px;
    top: ${yPos};
    left: ${xPos};
    padding: 10px 0;
    background-color: white;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2);
    pointer-events: ${isActive ? `auto` : `none`};
    opacity: ${isActive ? `1` : `0`};
    transition: opacity 0.15s ease-in-out;
`;

export const rightClickMenuItemStyle = ({ colors: { background }, spacings }: IToken) => css`
    cursor: pointer;
    transition-duration: 100ms;
    padding: ${spacings.smallest} ${spacings.medium};
    background-color: ${background.default};
    &:hover {
        background-color: ${background.light};
    }
`;
