import { useLayoutEffect, useState, useRef, useCallback, RefObject } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { debounce } from 'lodash-es';

export const DEFAULT_DEBOUNCE_WAIT = 1000;

export type VisibleRef = (instance: HTMLDivElement | null) => void;

export const useResizeObserver: (
    wait?: number
) => [ResizeObserverEntry | undefined, (instance: HTMLDivElement | null) => void] = (wait = DEFAULT_DEBOUNCE_WAIT) => {
    const [entry, setEntry] = useState<ResizeObserverEntry>();
    const [node, setNode] = useState<HTMLDivElement | null>(null);
    const observer = useRef<ResizeObserver>();

    const disconnect = useCallback(() => {
        const { current } = observer;
        current && current.disconnect();
    }, []);

    const observe = useCallback(() => {
        observer.current = new ResizeObserver(debounce(([entry]) => setEntry(entry), wait));
        node && observer.current.observe(node);
    }, [node, setEntry]);

    useLayoutEffect(() => {
        observe();
        return () => disconnect();
    }, [disconnect, observe]);

    return [entry, setNode];
};

export const useResizeDimension = (ref: RefObject<HTMLElement>) => {
    const [width, setWidth] = useState<number>();
    const [height, setHeight] = useState<number>();

    const handleResize = useCallback((entries: ResizeObserverEntry[]) => {
        if (!Array.isArray(entries)) {
            return;
        }

        const entry = entries[0];
        setWidth(entry.contentRect.width);
        setHeight(entry.contentRect.height);
    }, []);

    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }

        const RO = new ResizeObserver((entries: ResizeObserverEntry[]) => handleResize(entries));
        RO.observe(ref.current);

        return () => {
            RO.disconnect();
        };
    }, [ref]);

    return [width, height];
};
