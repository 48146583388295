import * as React from 'react';
import { IEditorDragContextValue, EditorDragContextProvider } from '../../../../hooks/useDragContext';
import { DragDropContextProps, DragDropContext } from 'react-beautiful-dnd';
import { IBaseDrag } from '../Drop/Drop';
import { useReducer, useCallback } from 'react';
import { DragHandleReducer } from './DragAndDropReducer';
import { DragHandlerProvider, DragHoverProvider } from './DragAndDropContext';

export function extractDraggableInfo<T extends IBaseDrag>(draggableId: string): T {
    return JSON.parse(draggableId) as T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDraggableId = (idObj: any) => {
    return JSON.stringify(idObj);
};

export const DragAndDropProvider: React.FC = ({ children }) => {
    const [editorContextValue, setEditorContextValue] = React.useState<IEditorDragContextValue>({});

    const [dragHandlers, dragHandlerDispatch] = useReducer(DragHandleReducer, {
        onDragEndHandlers: {},
        dragDropHoverState: {}
    });

    const checkDragHover = useCallback(
        (dropId: string, dragType?: string, checkDomainOnly?: boolean): boolean => {
            if (!editorContextValue.beforeCapture) return false;

            if (!checkDomainOnly) {
                return dragHandlers.dragDropHoverState[dropId]?.dragType === dragType;
            } else {
                return Object.entries(dragHandlers.dragDropHoverState).some(
                    ([key, value]) => key.split(':')[0] === dropId.split(':')[0] && value?.dragType === dragType
                );
            }
        },
        [dragHandlers.dragDropHoverState, editorContextValue.beforeCapture]
    );

    const handleDragEndAny: DragDropContextProps['onDragEnd'] = dropResult => {
        const handlers = dragHandlers.onDragEndHandlers[dropResult.destination?.droppableId ?? ''];

        if (!handlers || handlers.length === 0) {
            return;
        }
        handlers.forEach(handler => {
            handler(extractDraggableInfo<IBaseDrag>(dropResult.draggableId), dropResult.destination?.index ?? 0);
        });
    };

    // const handleDragStartAny: DragDropContextProps['onDragStart'] = (dragStart, provided) => {
    //     Object.keys(anyHandlers).forEach(callKey => {
    //         const { onDragStart } = anyHandlers[callKey];
    //         if (!onDragStart) {
    //             return;
    //         }
    //         onDragStart(dragStart, provided);
    //     });
    // };

    // const handleBeforeCaptureAny: DragDropContextProps['onBeforeCapture'] = before => {
    //     Object.keys(anyHandlers).forEach(callKey => {
    //         const { onBeforeCapture } = anyHandlers[callKey];
    //         if (!onBeforeCapture) {
    //             return;
    //         }
    //         onBeforeCapture(before);
    //     });
    // };

    const handleDragEnd: DragDropContextProps['onDragEnd'] = (dropResult, provided): void => {
        handleDragEndAny(dropResult, provided);
        setEditorContextValue({
            ...editorContextValue,
            dropResult,
            dragStart: undefined,
            beforeCapture: undefined
        });
    };

    const handleDragStart: DragDropContextProps['onDragStart'] = dragStart => {
        //handleDragStartAny(dragStart, provided);
        setEditorContextValue({
            ...editorContextValue,
            dragStart
        });
    };

    const handleBeforeCapture: DragDropContextProps['onBeforeCapture'] = beforeCapture => {
        setEditorContextValue({
            ...editorContextValue,
            beforeCapture
        });
    };

    return (
        <DragHandlerProvider value={dragHandlerDispatch}>
            <DragHoverProvider value={checkDragHover}>
                <EditorDragContextProvider value={editorContextValue}>
                    <DragDropContext
                        onBeforeCapture={handleBeforeCapture}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                    >
                        {children}
                    </DragDropContext>
                </EditorDragContextProvider>
            </DragHoverProvider>
        </DragHandlerProvider>
    );
};
