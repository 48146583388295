import { ListOrGrid } from './../../core-ui/collection/Collection';
import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const flexColumnStyle = css`
    display: flex;
    flex-direction: column;
`;

export const flexRowStyle = (token: IToken) => css`
    margin-top: ${token.spacings.small};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const headerWrapperStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const actionBarWrapperStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const gridButtonStyle = (token: IToken, display: ListOrGrid) => css`
    ${display === 'grid' && `color: ${token.colors.interactible.tertiary};`}
`;

export const listButtonStyle = (token: IToken, display: ListOrGrid) => css`
    ${display === 'list' && `color: ${token.colors.interactible.tertiary};`}
`;
export const dropDownWrapperStyle = css`
    min-width: 150px;
`;

export const dropDownStyle = css`
    height: 32px;
`;

export const interactibleWrapperStyle = css`
    width: auto;
`;

export const interactibleStyle = (token: IToken) => css`
    padding: ${token.spacings.smaller} ${token.spacings.small};
    margin-left: ${token.spacings.small};
`;

export const createFileMenuStyle = css`
    width: 200px;
`;
