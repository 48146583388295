import { GenerateSubSelector, SelectCurrentProjectId } from '../spaceKeyedSelectors';
import { IProjectConfigState } from './ProjectConfigReducer';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

export const SelectProjectConfigsState = GenerateSubSelector<IProjectConfigState>('projectConfig');

export const SelectCurrentProjectConfig = createSelector(
    SelectCurrentProjectId,
    SelectProjectConfigsState,
    (projectId, state) => {
        return state.configData.projectConfigs.find(pc => pc.id === projectId);
    }
);

export const selectProjectConfig = createSelector(SelectProjectConfigsState, state => state.configData);

export const useCurrentProjectConfig = () => useSelector(SelectCurrentProjectConfig);
