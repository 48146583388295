import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const collectionStyle = (token: IToken) => css`
    justify-content: normal;
    display: flex;
    overflow-wrap: break-word;
    flex-wrap: wrap;
    background-color: ${token.colors.background.lighter};
    padding: ${token.spacings.small} ${token.spacings.smallest};
`;

export const catalogSectionStyle = (token: IToken) => css`
    margin-bottom: ${token.spacings.small};
`;

export const catalogTitleStyle = css`
    font-weight: 600;
`;

export const deleteCatalogIconWrapper = (token: IToken) => css`
    background-color: lightgray;
    padding: 4px 6px;
    border-radius: ${token.borderRadii.small};
`;

export const dashboardCatalogWrapper = css`
    display: grid;
    grid-template-columns: 80% 20%;
    overflow: hidden;
`;

export const dashboardCatalogFoldersWrapper = (token: IToken) => css`
    padding: ${token.spacings.small} ${token.spacings.medium} ${token.spacings.medium} ${token.spacings.medium};
`;

export const dashboardDetailsWrapper = (token: IToken) => css`
    border: 1px solid ${token.colors.border.primary};
    border-top: transparent;
    border-left: transparent;
    position: relative;
    padding-right: 0;
    padding-left: 0;
`;

export const dashboardSearchControls = css`
    margin-bottom: 16px;
`;

export const dashboardCatalogCard = (token: IToken) => css`
    border-radius: 0;
    border: 1px solid ${token.colors.border.primary};
    border-top: transparent;
    height: 100%;
    box-sizing: border-box;
`;

export const dashboardCatalogsLayout = css`
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    box-sizing: border-box;
`;

export const catalogDetailsIcon = css`
    text-align: center;
    font-size: 3em;
    color: gray;
    padding-left: 16px;
`;

export const catalogDetailsText = css`
    margin-top: 10px;
    text-align: center;
`;

export const selectCatalogSection = css`
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const closeDetailsIcon = css`
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
`;
