import React from 'react';

import {
    FormControl as ChakraFormControl,
    InputGroup as ChakraInputGroup,
    FormLabel,
    FormHelperText,
    FormErrorMessage,
    InputLeftAddon,
    InputRightAddon
} from '@chakra-ui/core';
import { SerializedStyles } from '@emotion/core';

export const InputGroup: React.FC<{
    leftAddonText?: string;
    rightAddonText?: string;
}> = ({ leftAddonText, rightAddonText, children }) => {
    return (
        <>
            <ChakraInputGroup>
                {leftAddonText && <InputLeftAddon>{leftAddonText}</InputLeftAddon>}
                {children}
                {rightAddonText && <InputRightAddon>{rightAddonText}</InputRightAddon>}
            </ChakraInputGroup>
        </>
    );
};

export const FormControl: React.FC<{
    className?: string;
    fieldId: string;
    helperText?: string;
    required?: boolean;
    label?: string;
    errors?: string[];
    helperTextStyle?: SerializedStyles;
}> = ({ className, fieldId, helperText, required = false, errors = [], label, helperTextStyle, children }) => {
    return (
        <>
            <ChakraFormControl className={className} isRequired={required}>
                {!!label && <FormLabel htmlFor={fieldId}>{label}</FormLabel>}
                {children}
                {helperText && (
                    <FormHelperText css={helperTextStyle} id={`${fieldId}-helper-text`}>
                        {helperText}
                    </FormHelperText>
                )}
                {errors.map((error, idx) => (
                    <FormErrorMessage key={idx}>{error}</FormErrorMessage>
                ))}
            </ChakraFormControl>
        </>
    );
};
