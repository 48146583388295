import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const anchorContainerStyle = css`
    position: relative;
    display: block;
    min-width: 0;
`;

export const menuContainerStyle = (token: IToken, alignLeft: boolean) => css`
    position: absolute;
    z-index: 120;
    visibility: visible;
    right: ${alignLeft ? 'auto' : '0'};
    left: ${alignLeft ? '0' : 'auto'};
    min-width: 100%;
    background-color: ${token.colors.background.default};
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.4);

    &:hover {
        z-index: 120;
    }
`;

export const menuItemStyle = ({ colors: { background }, spacings }: IToken, isClickable: boolean) => css`
    transition-duration: 100ms;
    padding: ${spacings.small} ${spacings.medium};
    z-index: 120;
    text-align: center;

    ${isClickable &&
        `
    cursor: pointer;
    &:hover {
        background-color: ${background.light};
    }`}
`;

export const menuDividerStyle = css`
    margin: 0px;
`;
