import { useCurrentSpace } from '../../useCurrentSpace';
import { IHookProvidedCall } from '../../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../../hooks/useNetworkCallForDataSource';
import { useDispatch } from 'react-redux';
import { deleteCatalogItemNetworkCall } from '../../../networking/CatalogItemService';

export interface IDeleteCatalogItemParams {
    catalogId: string;
    catalogItemId: string;
}

export const useDeleteCatalogItem = (): IHookProvidedCall<IDeleteCatalogItemParams> => {
    const [currentSpace] = useCurrentSpace();
    const catalogDispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const deleteCatalogItem = async (params: IDeleteCatalogItemParams) => {
        await triggerCall(
            deleteCatalogItemNetworkCall(
                currentSpace,
                params.catalogId,
                params.catalogItemId,
                getCancelableDispatch(catalogDispatch)
            )
        );
    };

    return {
        execute: deleteCatalogItem,
        ...rest
    };
};
