import { ICatalog } from '../../shared/motive/models/Catalog';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { IContextualMenuItem } from '../../core-ui/contextualMenu';
import {
    catalogMenuItem,
    catalogMenuIcon,
    catalogMenuText,
    catalogFolderStyle,
    catalogFolderIconStyle,
    catalogNameStyle
} from './CatalogFolder.style';
import { Size } from '../../core-ui/constants/Size';
import { useStyle } from '../../shared/hooks/useStyle';
import { useClickOutElement } from '../../shared/hooks/useClickOutElement/useClickOutElement';
import { RightClickContextualMenu } from '../../core-ui/rightClickContextualMenu/RightClickContextualMenu';
import { EditableText } from '../../core-ui/editableText';
import { Text } from '../../core-ui/text';
import React, { useState, useEffect } from 'react';
import { Icon } from '../../core-ui/icon';
import { IRenameCatalogParams } from '../../shared/motive/hooks/useCatalogs/useRenameCatalog/useRenameCatalog';
import { IDeleteCatalogParams } from '../../shared/motive/hooks/useCatalogs/useDeleteCatalog/useDeleteCatalog';
import { textPulse } from '../../constants/AnimationStyles';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { DeleteModal } from '../../core-ui/deleteModal/DeleteModal';
import { useToast } from '../../core-ui/hooks/useToast';
import { TooltipBox } from '../../core-ui/tooltip';
import { useCheckSystemNameConflict } from '../../shared/motive/hooks/useCheckSystemNameConflict/useCheckSystemNameConflict';
import { delay } from 'lodash-es';
import { useTranslation } from 'react-i18next';

interface ICatalogFolderProps {
    catalog: ICatalog<IScriptObjectModel>;
    isSelected?: boolean;
    onClick?: () => void;
    onDoubleClick?: () => void;
    onClickOut?: (event?: MouseEvent) => void;

    onRename: IHookProvidedCall<IRenameCatalogParams>;
    onDelete: IHookProvidedCall<IDeleteCatalogParams>;
}

const generateMenuItem = (actionName: string, icon?: IconTypes, onClick?: () => void): IContextualMenuItem => {
    return {
        children: (
            <div css={catalogMenuItem}>
                <div css={catalogMenuIcon}>{icon && <Icon icon={icon}></Icon>}</div>
                <Text size={Size.LARGE} css={catalogMenuText}>
                    {actionName}
                </Text>
            </div>
        ),
        onPress: onClick
    };
};

export const CatalogFolder: React.FC<ICatalogFolderProps> = ({
    catalog,
    onClick,
    onDoubleClick,
    onClickOut,
    onRename,
    onDelete,
    isSelected = false
}) => {
    const token = useStyle();
    const { t } = useTranslation();
    const toast = useToast();
    const checkNameConflict = useCheckSystemNameConflict();
    const [isMenuOpen, setMenu] = useState(false);
    const [hasRenameConflict, setHasRenameConflict] = useState<boolean>(false);

    //Callback only fires if it is the selected component and when the right click menu is not open
    const anchorRef = useClickOutElement<HTMLDivElement>(
        e => isSelected && !isMenuOpen && onClickOut && onClickOut(e),
        true
    );

    const [deleteModal, setDeleteModal] = useState(false);
    const [isRenaming, setRenaming] = useState(false);

    const menuItems: IContextualMenuItem[] = [
        generateMenuItem(t('open'), IconTypes.EXTERNAL_LINK, onDoubleClick),
        generateMenuItem(t('rename'), IconTypes.EDIT, () => setRenaming(true)),
        generateMenuItem(t('duplicate'), IconTypes.COPY_H),
        generateMenuItem(t('delete'), IconTypes.DELETE_H, () => {
            setDeleteModal(true);
        })
    ];

    const handleDeleteCatalog = async () => {
        setDeleteModal(false);
        await onDelete.execute({ catalogId: catalog.id });
    };

    const onEditCompleteHandler = (val: string) => {
        if (val === catalog.title) {
            setRenaming(false);
        } else if (checkNameConflict(val)) {
            setHasRenameConflict(true);
        } else {
            setRenaming(false);
            val !== catalog.title &&
                onRename.execute({ catalogId: catalog.id, catalogTitle: val }).then(() => {
                    toast({
                        description: t('successfullyRenamedCatalog', { name: val }),
                        isClosable: true,
                        position: 'bottom-right',
                        status: 'success',
                        title: t('successMessage', { message: t('renamedCatalog') })
                    });
                });
        }
    };

    useEffect(() => {
        if (onRename.error) {
            toast({
                description: t('errorRenamingCatalog'),
                isClosable: true,
                position: 'bottom-right',
                status: 'error',
                title: t('errorMessage', { message: 'renamingCatalog' })
            });
        }
    }, [onRename.error, toast]);

    // Make tooltip disappear after short amount of time
    useEffect(() => {
        if (hasRenameConflict) {
            delay(() => {
                setHasRenameConflict(false);
            }, 2500);
        }
    }, [hasRenameConflict, setHasRenameConflict]);

    return (
        <>
            <DeleteModal
                isOpen={deleteModal}
                onCloseModal={() => setDeleteModal(false)}
                onConfirm={handleDeleteCatalog}
            >
                {t('confirmCatalogDelete', { name: catalog.title })}
            </DeleteModal>

            <div
                ref={anchorRef}
                css={catalogFolderStyle(token, isSelected)}
                onClick={onClick}
                onDoubleClick={e => {
                    e.preventDefault();
                    onDoubleClick && onDoubleClick();
                }}
            >
                <RightClickContextualMenu
                    menuItems={menuItems}
                    onMenuClose={() => setMenu(false)}
                    onMenuOpen={() => setMenu(true)}
                >
                    <div>
                        <Icon css={catalogFolderIconStyle} icon={IconTypes.FOLDER} size={Size.INHERIT}></Icon>
                    </div>
                    <div>
                        <TooltipBox
                            color={'red.600'}
                            placement={'top'}
                            isOpen={hasRenameConflict}
                            label={t('conflictingCatalogName')}
                        >
                            <EditableText
                                active={isRenaming}
                                wrapperStyle={catalogNameStyle(token)}
                                textStyle={onRename.isLoading ? textPulse : undefined}
                                onEditComplete={onEditCompleteHandler}
                                defaultValue={catalog.title}
                                size={Size.SMALL}
                                disabled={onRename.isLoading}
                            />
                        </TooltipBox>
                    </div>
                </RightClickContextualMenu>
            </div>
        </>
    );
};
