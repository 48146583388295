import React, { Dispatch } from 'react';
import { FC } from 'react';
import { Card } from '../../core-ui/card';
import { accountGrid, accountGridEntry, accountFormImage } from './Account.style';
import { Loading } from '../../core-ui/loading';
import { SkeletonForm } from '../../core-ui/skeletonForm/';
import { ChangePasswordForm } from './ChangePasswordForm';
import { ToggleTwoFactorForm } from './ToggleTwoFactorForm';
import { AddPhoneNumberForm } from './AddPhoneNumberForm';
import { IAccountManagerViewModel } from '../../shared/motive/models/AccountManagerViewModel';
import { UserInfoActionType } from '../../redux/userInfo/UserInfoActions';

///
/// Account component, renders out fields based on what the user has available to them.
///
export const Account: FC<IAccountManagerViewModel & {
    dispatch: Dispatch<UserInfoActionType>;
    isLoading?: boolean;
}> = props => {
    const {
        user: { phoneNumber, phoneNumberConfirmed },
        isLoading = false
    } = props;
    const canEnable2fa = !!phoneNumber && !!phoneNumberConfirmed;

    const accountForm = (
        <Card css={accountGrid(isLoading)}>
            <img css={accountFormImage} src="/img/undraw_account_details.svg" alt="Account"></img>
            <div css={accountGridEntry(isLoading)}>
                <Loading isLoaded={!isLoading} loadingRender={() => SkeletonForm(15)}>
                    <>
                        {<ChangePasswordForm {...props} />}
                        {!phoneNumber && <AddPhoneNumberForm {...props} />}
                        {canEnable2fa && <ToggleTwoFactorForm {...props} />}
                    </>
                </Loading>
            </div>
        </Card>
    );

    return accountForm;
};
