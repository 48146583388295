import React from 'react';
import { useStyle } from '../../shared/hooks/useStyle';
import { sidebarSection, dashboardSection, outerStyle } from './SidebarContainer.style';

const dashboardSidebarWidth = 20;

interface ISidebarContainerProps {
    sidebarContent?: React.ReactNode;
}

export const SidebarContainer: React.FC<ISidebarContainerProps> = ({ children, sidebarContent }) => {
    const tokens = useStyle();

    return (
        <>
            {sidebarContent ? (
                <div css={outerStyle}>
                    <div css={sidebarSection(tokens, dashboardSidebarWidth)}>{sidebarContent}</div>
                    <div css={dashboardSection}>{children}</div>
                </div>
            ) : (
                <>{children}</>
            )}
        </>
    );
};
