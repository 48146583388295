import { useToggle } from '../../shared/hooks/useToggle';
import { Collapsible } from '../../core-ui/collapse';
import React from 'react';
import { Text } from '../../core-ui/text';
import { useStyle } from '../../shared/hooks/useStyle';
import { collapsibleHeader, infoContainerStyle } from './SidebarSection.style';
import { Size } from '../../core-ui/constants/Size';
import { RotateChevron } from '../rotateChevron';

interface ISidebarSectionProps {
    label: string;
    isInitiallyOpen?: boolean;
}

export const SidebarSection: React.FC<ISidebarSectionProps> = ({ children, label, isInitiallyOpen = true }) => {
    const [isShowing, toggleShowing] = useToggle(isInitiallyOpen);
    const tokens = useStyle();

    return (
        <>
            <button css={collapsibleHeader(tokens)} onClick={toggleShowing}>
                <RotateChevron isPointingDown={isShowing} />
                <Text size={Size.LARGE}>{label}</Text>
            </button>
            <Collapsible isShowing={isShowing}>
                <div css={infoContainerStyle(tokens)}>{children}</div>
            </Collapsible>
        </>
    );
};
