import { IBundle } from '../../../shared/motive/models/Bundle';
import { LoadingState } from '../../Status';

export type IBundleAction = ReturnType<
    | typeof bundleSetAction
    | typeof bundleErrorAction
    | typeof bundleLoadAction
    | typeof bundleLoadItemAction
    | typeof bundleDeleteItemAction
    | typeof bundleErrorItemAction
    | typeof bundleAddItemAction
    | typeof bundleEndRestoreAction
    | typeof bundleBeginRestoreAction
>;

export const bundleSetAction = (bundles: IBundle[]) => {
    return {
        type: 'bundle/set',
        bundles
    } as const;
};

export const bundleErrorAction = (error: Error) => {
    return {
        type: 'bundle/error',
        error
    } as const;
};

export const bundleLoadAction = () => {
    return {
        type: 'bundle/load'
    } as const;
};

export const bundleLoadItemAction = (id: string) => {
    return {
        type: 'bundle/itemLoad',
        id
    } as const;
};

export const bundleAddItemAction = (bundle: IBundle, loading: LoadingState) => {
    return {
        type: 'bundle/itemAdd',
        bundle,
        loading
    } as const;
};

export const bundleDeleteItemAction = (id: string) => {
    return {
        type: 'bundle/itemDelete',
        id
    } as const;
};

export const bundleErrorItemAction = (id: string, error: Error) => {
    return {
        type: 'bundle/itemError',
        id,
        error
    } as const;
};

export const bundleBeginRestoreAction = () => {
    return {
        type: 'bundle/restoreBegin'
    } as const;
};

export const bundleEndRestoreAction = (error?: Error) => {
    return {
        type: 'bundle/restoreEnd',
        error
    } as const;
};
