import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const mediaItemCardStyle = (token: IToken, hasOnClick?: boolean) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 151px;
    background-color: ${token.colors.background.lighter};
    overflow: hidden;
    ${hasOnClick &&
        `cursor: pointer; 
    &:hover { 
        border: 1px solid ${token.colors.background.dark}
    }`}
`;

export const mediaItemCardHeaderStyle = css`
    height: 70%;
    display: flex;
    align-items: center;
`;

export const mediaItemCardFooterStyle = (token: IToken) => css`
    height: 30%;
    width: 100%;
    background-color: ${token.colors.staticColors.white};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const mediaItemCardLabelStyle = css`
    display: block;
    width: 80%;
    text-align: center;
    text-overflow: ellipsis;
    padding: 2px;
    overflow: hidden;
    white-space: nowrap;
`;

export const mediaItemThumbnailWrapperStyle = css`
    display: flex;
    align-items: center;
`;

export const mediaItemIconStyle = (token: IToken) => css`
    color: ${token.colors.background.dark};
    transform: scale(2);
`;

export const mediaItemImageStyle = css`
    max-height: 100%;
    max-width: 100%;
`;

export const mediaItemVideoStyle = css`
    max-height: 100%;
    max-width: 100%;
`;
