import css from '@emotion/css';
import { IToken } from '../../shared/contexts/styleContext';
import chroma from 'chroma-js';

export const baseWrapperStyle = (token: IToken) => {
    return css`
        width: 100%;
        min-width: 150px;
        font-size: ${token.fontSizes.medium};
        padding: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 5px;
        $ span {
            font-size: ${token.fontSizes.medium};
        }
        &:hover {
            background-color: ${chroma(token.colors.background.light)
                .alpha(0.3)
                .css()};
        }
    `;
};

export const placeholderStyle = ({ colors: { font } }: IToken) => {
    return css`
        color: ${font.secondary};
    `;
};
export const baseEditableStyle = css`
    height: 100%;
    border: none;
    &:hover {
        border: none;
    }
`;

export const smallEditableTextStyle = ({ spacings, fontSizes }: IToken) => {
    return css`
        padding: ${spacings.smallest} 0px ${spacings.smallest} 0px;
        margin: 0;
        font-size: ${fontSizes.small};
    `;
};

export const mediumEditableTextStyle = ({ spacings, fontSizes }: IToken) => {
    return css`
        padding: ${spacings.smallest} 0px ${spacings.smallest} 0px;
        margin: 0;
        font-size: ${fontSizes.medium};
    `;
};

export const largeEditableTextStyle = ({ spacings, fontSizes }: IToken) => {
    return css`
        padding: ${spacings.smallest} 0px ${spacings.smallest} 0px;
        margin: 0;
        font-size: ${fontSizes.medium};
    `;
};

export const disabledEditableTextStyle = css`
    border: none;

    &:hover {
        background-color: auto;
        border: none;
    }
`;
