import { IToken } from '../../shared/contexts/styleContext';
import { css } from '@emotion/core';

export const tabStyle = css`
    display: flex;
    border-radius: 20px;
    position: relative;
    height: 40px;
    width: 90px;
    bottom: 0;
    border: none;
    font-size: 1.2em;
    &[aria-selected='true'] {
        background: #3b3f48;
        color: white;
    }
`;

export const inspectorBodyStyle = css`
    max-width: 600px;
    width: 100%;
`;

export const GRID_FRAME_INSPECT_HEADER = 'frameInspectHeaderGrid';

export const outerLayoutStyle = ({ colors }: IToken) => css`
    display: grid;
    grid-template-rows: auto 1fr;
    background-color: ${colors.background.lighter};
`;

export const tabListStyle = css`
    height: 100%;
    border-bottom: none;
    margin-bottom: 0;
    padding-right: 5px;
`;

export const documentAndToolsLayoutStyle = css`
    display: grid;
    grid-template-columns: minmax(30%, 6fr) 300px;
    & > div {
        min-width: 0;
    }
`;
