import React from 'react';
import { IFieldEditorProps } from '../../containers/objectEditor/ObjectEditor';
import { ILocalizedText, ILocalizedTextItem } from '../../shared/motive/models/LocalizedText';
import { StringEditor } from '../stringEditor';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { FieldTypes } from '../../shared/motive/models/ScriptObjectModel';
import { useCreateScriptObject } from '../../shared/motive/hooks/useCreateScriptObject';
import { InputSize, Size } from '../../core-ui/constants/Size';
import { localizedTextLayout, cultureCodeStyle } from './LocalizedTextEditor.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { useTranslation } from 'react-i18next';

export const findLocalizedItem = (
    locTxt: ILocalizedText | undefined,
    cultureCode: string
): ILocalizedTextItem | undefined => {
    return locTxt && locTxt.localizations?.find(c => c.cultureCode.toUpperCase() === cultureCode.toUpperCase());
};

const addLocalizedTextItem = (cultureCode: string, text: string, typeValues?: ILocalizedText): ILocalizedTextItem[] => {
    const textItem = { cultureCode: cultureCode, text: text };
    return typeValues
        ? [
              ...typeValues.localizations.filter(item => item.cultureCode.toUpperCase() !== cultureCode.toUpperCase()),
              textItem
          ]
        : [textItem];
};

interface IStringEditorProps extends IFieldEditorProps<ILocalizedText> {
    size?: InputSize;
}

export const LocalizedTextEditor: React.FC<IStringEditorProps> = ({ type, value, onChange, className, ...props }) => {
    const { i18n } = useTranslation();
    const cultureCode = i18n.language;
    const localizedText = findLocalizedItem(value, cultureCode);
    const createScriptObject = useCreateScriptObject();
    const token = useStyle();
    const handleChange = (changedValue: FieldTypes): void => {
        const selectedStr = changedValue as string;
        let newValue = value ?? createScriptObject<ILocalizedText>(type);

        const localizations = addLocalizedTextItem(cultureCode, selectedStr, value);
        newValue = {
            ...newValue,
            localizations
        };

        onChange && onChange(newValue);
    };

    return (
        <div css={localizedTextLayout}>
            <StringEditor
                type={MotiveTypes.STRING}
                value={localizedText?.text ?? undefined}
                onChange={handleChange}
                className={className}
                size={props.fieldDefinition.editorInfo?.useTextArea ? Size.LARGE : Size.MEDIUM}
                {...props}
            ></StringEditor>
            <div css={cultureCodeStyle(token)}>({cultureCode})</div>
        </div>
    );
};
