import React from 'react';
import { IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { useDashboardDrawer } from '../../hooks/useDashboardDrawer/UseDashboardDrawer';
import { useChooseMediaSourceState } from '../../hooks/useChooseMediaSource/UseChooseMediaSource';
import { useMediaItems } from '../../shared/motive/hooks/useMediaItems';
import { MediaSourceCollection } from '../../components/mediaSourceCollection';

export const MediaSourceCollectionContainer: React.FC = (): React.ReactElement => {
    const { data } = useMediaItems();
    const { handleChooseMediaSource } = useDashboardDrawer();
    const { state: chooseType } = useChooseMediaSourceState();

    const handleItemClick = (mediaSource: IHostedMediaSource) => {
        if (chooseType === 'choosing') {
            handleChooseMediaSource(mediaSource);

            // do more stuff here
        }
        // else open up the media item preview.
    };

    return <MediaSourceCollection data={data} onItemSelect={handleItemClick} isLoading={data === null} />;
};
