import React, { useEffect, useState } from 'react';
import { IFieldEditorProps } from '../../containers/objectEditor/ObjectEditor';
import { InputField } from '../../core-ui/inputField';
import { InputSize, Size } from '../../core-ui/constants/Size';
import { useDebounce } from '../../hooks/useDebounce/UseDebounce';
import { TextArea } from '../../core-ui/textArea';
import { Text } from '../../core-ui/text';
import { EditModeAware } from '../editableOnFocus/EditModeAware';

export const stringEditorTestId = 'stringEditorTest';

interface IStringEditorProps extends IFieldEditorProps<string> {
    size?: InputSize;
}

export const StringEditor: React.FC<IStringEditorProps> = ({ value, onChange, className, size = Size.MEDIUM }) => {
    const [state, setState] = useState(value);
    const debouncedState = useDebounce(state);

    useEffect(() => {
        if (debouncedState !== value) {
            onChange(debouncedState);
        }
    }, [debouncedState]);

    const handleChange = (newVal: string) => {
        setState(newVal);
    };

    useEffect(() => {
        setState(value);
    }, [value, setState]);

    const renderEditor = () => {
        return size === Size.LARGE ? (
            <TextArea
                onChange={handleChange}
                value={state}
                data-testid={stringEditorTestId}
                className={className}
            ></TextArea>
        ) : (
            <InputField
                onChange={handleChange}
                value={state}
                data-testid={stringEditorTestId}
                size={size as InputSize}
                className={className}
            ></InputField>
        );
    };

    const renderReadonly = () => {
        return <Text>{value}</Text>;
    };

    return <EditModeAware editModeRender={renderEditor} readonlyModeRender={renderReadonly} />;
};
