import { EditorCultureCode } from '../constants/EditorCultureCode';
import { LocalizationKeys } from './LocalizationKeys';
import { enStrings } from './translations/EnglishLocalization';
import { frStrings } from './translations/FrenchLocalization';

export const LocalizationText = (
    cultureCode: EditorCultureCode,
    localizationKey: LocalizationKeys
): string | undefined => {
    switch (cultureCode) {
        case EditorCultureCode.ENGLISH:
            return enStrings.get(localizationKey);
        case EditorCultureCode.FRENCH:
            return frStrings.get(localizationKey);
    }
};
