import produce from 'immer';
import { IAccountManagerViewModel } from '../../shared/motive/models/AccountManagerViewModel';
import { UserInfoActionType } from './UserInfoActions';

export enum UserLoginStatus {
    Initial = 'initial',
    Unknown = 'unknown',
    Pending = 'pending',
    LoggedIn = 'logged_in',
    AuthFailure = 'auth_failue'
}

export interface IUserInfoState {
    userInfo: IAccountManagerViewModel | undefined;
    status: UserLoginStatus;
}

const defaultState: IUserInfoState = { userInfo: undefined, status: UserLoginStatus.Initial };

export const UserInfoReducer = produce((state: IUserInfoState, action: UserInfoActionType) => {
    switch (action.type) {
        case 'userInfo/set': {
            state.userInfo = action.userInfo;
            break;
        }
        case 'userInfo/unset': {
            state.userInfo = undefined;
            break;
        }
        case `userInfo/status/set`: {
            state.status = action.status;
            break;
        }
        case 'userInfo/status/unset': {
            state.status = UserLoginStatus.Unknown;
            break;
        }
    }
}, defaultState);
