import { longRunningHttpClient } from './HttpClient';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IHostedMediaSource } from './../models/MediaItem';
import {
    IMediaAction,
    mediaLoadingAction,
    mediaUploadBeginAction,
    mediaUploadCompleteAction,
    mediaUploadErrorAction,
    mediaUploadProgressAction,
    mediaSetAction,
    mediaErrorAction,
    mediaItemLoadingAction,
    mediaItemErrorAction,
    mediaItemDeleteAction
} from '../../../redux/spaceKeyed/media/MediaActions';

import { SPACE_ENDPOINT } from '../constants/ApiEndpoint';
import { httpClient, BASE_URL } from './HttpClient';
import { tryUseCachedForGetReq, UseStaleWhileFetchingStrategy } from './StaleWhileFetching';
import { uniqueMotiveId } from '../../../util/MotiveUtils';

export function getViewableUrl(spaceName: string, mediaSourceId: string, redirect: boolean = false): string {
    return (
        `${BASE_URL}/${SPACE_ENDPOINT}/${spaceName}/media/${mediaSourceId}/viewableUrl` +
        (redirect ? '?redirect=true' : '')
    );
}

// Deprecated
// export const mediaItemPath = (spaceName: string) => {
//     return `${SPACE_ENDPOINT}/${spaceName}/mediaItems`;
// };

export const mediaPath = (spaceName: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/media`;
};

export const uploadMediaItemPath = (spaceName: string, fileName?: string, overwrite?: boolean) => {
    var path = '';
    if (fileName) {
        path = `${SPACE_ENDPOINT}/${spaceName}/media/?fileName=${encodeURIComponent(fileName)}`;

        if (overwrite) {
            path += '&overwrite=true';
        }
    } else {
        path = `${SPACE_ENDPOINT}/${spaceName}/media/`;

        if (overwrite) {
            path += '?overwrite=true';
        }
    }

    return path;
};

export const deleteMediaItemPath = (spaceName: string, itemId: string, force: boolean = false) => {
    return `${SPACE_ENDPOINT}/${spaceName}/media/${itemId}/` + (!!force ? '?force=true' : '');
};

const getMediaItemsNetworkCallLegacy = async (
    spaceName: string,
    dispatch: (param: IMediaAction) => void
): Promise<any> => {
    try {
        dispatch(mediaLoadingAction());

        const response = await httpClient.get(mediaPath(spaceName));

        dispatch(mediaSetAction(response.data as IHostedMediaSource[]));

        return response.data as IHostedMediaSource[];
    } catch (error) {
        dispatch(mediaErrorAction(error));
    }

    return [];
};

const getMediaItemsNetworkCallSWF = async (
    spaceName: string,
    dispatch: (param: IMediaAction) => void
): Promise<any> => {
    dispatch(mediaLoadingAction());

    const url = mediaPath(spaceName);

    return await tryUseCachedForGetReq(
        url,
        httpClient.get(url),
        data => dispatch(mediaSetAction(data as IHostedMediaSource[])),
        error => dispatch(mediaErrorAction(error))
    );
};

export const getMediaItemsNetworkCall = UseStaleWhileFetchingStrategy
    ? getMediaItemsNetworkCallSWF
    : getMediaItemsNetworkCallLegacy;

export const uploadMediaItemNetworkCall = async (
    spaceName: string,
    fileName: string,
    file: any,
    dispatch: (param: IMediaAction) => void,
    overwrite: boolean = false,
    oldId?: string
): Promise<IHostedMediaSource | undefined> => {
    const id = oldId ?? uniqueMotiveId();

    try {
        dispatch(mediaUploadBeginAction(id, file));

        const payload = new FormData();
        payload.append('file', file);

        const response = await longRunningHttpClient.post(
            uploadMediaItemPath(spaceName, fileName, overwrite),
            payload,
            {
                onUploadProgress: progressEvent => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    dispatch(mediaUploadProgressAction(id, progress));
                }
            }
        );

        dispatch(mediaUploadCompleteAction(id, response.data[0] as IHostedMediaSource));

        return response.data[0];
    } catch (error) {
        dispatch(mediaUploadErrorAction(id, error));

        throw error;
    }
};

export const deleteMediaItemNetworkCall = async (
    spaceName: string,
    itemId: string,
    dispatch: (param: IMediaAction) => void,
    force: boolean = false
): Promise<void> => {
    try {
        dispatch(mediaItemLoadingAction(itemId));

        await httpClient.delete(deleteMediaItemPath(spaceName, itemId, force));

        dispatch(mediaItemDeleteAction(itemId));
    } catch (error) {
        dispatch(mediaItemErrorAction(itemId, error));

        throw error;
    }
};
