import React, { useState } from 'react';
import { ICatalogInfo } from '../../shared/motive/models/Catalog';
import { IProjectConfig } from '../../shared/motive/models/ProjectConfig';
import { Button, ButtonVariant } from '../../core-ui/button';
import { Collapsible } from '../../core-ui/collapse';
import { Checkbox } from '@chakra-ui/core';
import { catalogImportListStyle, importButtonStyle } from './ImportCatalogs.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { Size } from '../../core-ui/constants/Size';
import { useTranslation } from 'react-i18next';

export interface IImportCatalogProps {
    projectConfig: IProjectConfig;
    allSpaceCatalogs: ICatalogInfo[];
    onImportCatalogs: (projectId: string, catalogIds: string[]) => void;
    whiteListTypes?: string[];
    blackListTypes?: string[];
}

export const ImportCatalogs: React.FC<IImportCatalogProps> = ({
    onImportCatalogs,
    allSpaceCatalogs,
    projectConfig,
    whiteListTypes,
    blackListTypes
}): React.ReactElement => {
    return (
        <>
            <div>
                <CatalogImporter
                    projectConfig={projectConfig}
                    allSpaceCatalogs={allSpaceCatalogs}
                    onImportCatalogs={onImportCatalogs}
                    whiteListTypes={whiteListTypes}
                    blackListTypes={blackListTypes}
                />
            </div>
        </>
    );
};

const contains = (list: string[], str?: string) => {
    return list.length <= 0 ? false : list.some(item => item === str);
};

const CatalogImporter: React.FC<IImportCatalogProps> = ({
    onImportCatalogs,
    projectConfig,
    allSpaceCatalogs,
    whiteListTypes,
    blackListTypes
}): React.ReactElement => {
    const [toImport, setImports] = useState<{ [key: string]: 'true' }>({});

    let allCatalogsFiltered: ICatalogInfo[] = allSpaceCatalogs;
    allCatalogsFiltered =
        !whiteListTypes || whiteListTypes.length <= 0
            ? allCatalogsFiltered
            : allCatalogsFiltered.filter(cat => contains(whiteListTypes, cat.objectType));
    allCatalogsFiltered =
        !blackListTypes || blackListTypes.length <= 0
            ? allCatalogsFiltered
            : allCatalogsFiltered.filter(cat => !contains(blackListTypes, cat.objectType));

    const projectConfigCatalogs = projectConfig.catalogs ?? [];

    const isSelectedForImport = (catalogId: string): boolean => {
        return Object.keys(toImport).some(catIdKey => catIdKey === catalogId);
    };

    const isCurrentlyUsedByProject = (catalogId: string) => {
        return projectConfigCatalogs.some(projectCat => projectCat.id === catalogId);
    };

    const handleImportChange = (catalogId: string, isImported: boolean) => {
        const newToImport = { ...toImport };
        if (isImported) {
            newToImport[catalogId] = 'true';
        } else {
            delete newToImport[catalogId];
        }

        setImports(newToImport);
    };

    const handleClickConfirmImport = () => {
        onImportCatalogs(projectConfig.id, Object.keys(toImport));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultReduceState: { map: { [x: string]: any }; catalogInfoes: IImportCatalogInfo[] } = {
        map: {},
        catalogInfoes: []
    };
    const { catalogInfoes } = allCatalogsFiltered.reduce((state, cat) => {
        if (state.map[cat.id]) {
            return state;
        }

        const catalogInfoes = [
            ...state.catalogInfoes,
            {
                catalogInfo: cat,
                isCurrentlyUsedByProject: isCurrentlyUsedByProject(cat.id),
                isToImport: isSelectedForImport(cat.id)
            }
        ];

        return {
            map: {
                ...state.map,
                [cat.id]: 'true'
            },
            catalogInfoes
        };
    }, defaultReduceState);

    catalogInfoes.sort((a, b) => {
        if (a.isCurrentlyUsedByProject !== b.isCurrentlyUsedByProject) {
            return a.isCurrentlyUsedByProject && !b.isCurrentlyUsedByProject ? 1 : -1;
        } else {
            return a.catalogInfo.name.localeCompare(b.catalogInfo.name);
        }
    });
    const token = useStyle();
    const { t } = useTranslation();
    return (
        <>
            <div css={catalogImportListStyle(token)}>
                <ImportCatalogList
                    onCatalogStateChange={handleImportChange}
                    key={projectConfig.title}
                    title={projectConfig.title || 'Config'}
                    importCatalogInfoes={catalogInfoes}
                />
            </div>
            <Collapsible isShowing={Object.keys(toImport).length !== 0}>
                <Button
                    onClick={handleClickConfirmImport}
                    variant={ButtonVariant.SOLID}
                    size={Size.SMALL}
                    css={importButtonStyle}
                >
                    {t('import')}
                </Button>
            </Collapsible>
        </>
    );
};

interface IImportCatalogInfo {
    catalogInfo: ICatalogInfo;
    isCurrentlyUsedByProject: boolean;
    isToImport: boolean;
}

interface IImportCatalogListProps {
    title: string;
    importCatalogInfoes?: IImportCatalogInfo[];
    onCatalogStateChange: (catalogId: string, isImported: boolean) => void;
}

const ImportCatalogList: React.FC<IImportCatalogListProps> = ({ importCatalogInfoes, onCatalogStateChange, title }) => {
    return (
        <>
            {importCatalogInfoes && importCatalogInfoes.length > 0 && (
                <>
                    <div>{title}</div>
                    {importCatalogInfoes &&
                        importCatalogInfoes.map(ci => (
                            <ImportCatalogItem
                                key={ci.catalogInfo.id}
                                {...ci}
                                onChange={onCatalogStateChange}
                            ></ImportCatalogItem>
                        ))}
                </>
            )}
        </>
    );
};

interface IImportCatalogItemProps extends IImportCatalogInfo {
    onChange: (catalogId: string, isSelected: boolean) => void;
}

const ImportCatalogItem: React.FC<IImportCatalogItemProps> = ({
    catalogInfo,
    isCurrentlyUsedByProject,
    isToImport,
    onChange
}) => {
    // const [isSelected, setSelected] = useState(isCurrentlyUsedByProject);

    return (
        <div>
            <Checkbox
                isDisabled={isCurrentlyUsedByProject}
                isChecked={isCurrentlyUsedByProject || isToImport}
                onChange={() => {
                    // setSelected(!isSelected);
                    onChange(catalogInfo.id, !isToImport);
                }}
            >
                {catalogInfo.title}
            </Checkbox>
        </div>
    );
};
