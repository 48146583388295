// istanbul ignore file MOTIVE_EXCEPTION
import { EditorRouteKey, IEditorRoute, routeMap } from '../Routes';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useCallback } from 'react';
import { compile } from 'path-to-regexp';

/**
 * An interface for the goTo (route) call arguments.
 */
interface IGoToArgs<P> {
    /**
     * An object containing url params
     * ```
     * {
     *  spaceName: 'mySpace'
     * }
     * ```
     */
    pathParams?: P;

    /**
     * An object containing props for pushing a new state into the history object.
     * ```
     * {
     *  search: `?continue=${encodeURIComponent(stripTrailingSlashes(pathname))}`
     * }
     * ```
     */
    pushProps?: {
        /**
         * If set, is passed into `history.push` call.
         * Specifically for handling login "continue" logic.
         * ```
         * history.push({pathName, search})
         * ```
         */
        search?: string;
        replace?: boolean;
    };
}

interface IUseEditorRoute<P extends {}> extends IEditorRoute {
    /**
     * Uses `history.push` to "go to" a route's path.
     */
    goTo: (args?: IGoToArgs<P>) => void;
    /**
     * A function that takes an object of values to be inserted into this path.
     * If no `pathParams` passed in, this call will attempt to use existing values in URL.
     */
    buildPath: (pathParams?: P) => string;

    /**
     * The current route params in the URL for this route (i.e. useParams)
     */
    params: { [key in keyof P]: string };
}

/**
 * Handles messiness of certain url values that can be falsey (null, zero)
 * @param pathParamsDirty
 */
const cleanParams = <P>(pathParamsDirty?: P) => {
    if (!pathParamsDirty) {
        return {};
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newParamsClean: any = {};
    if (pathParamsDirty) {
        const dirtyValueKeys = Object.keys(pathParamsDirty);
        const validKeys: (keyof P)[] = dirtyValueKeys.reduce((prev, dirtyKey) => {
            const key = dirtyKey as keyof P;
            const value = (pathParamsDirty[key] as unknown) as string;
            if (value !== '' && value !== undefined && value !== null) {
                prev.push(key);
            }

            return prev;
        }, [] as (keyof P)[]);

        validKeys.forEach(key => {
            newParamsClean[key] = pathParamsDirty[key];
        });
    }
    return newParamsClean;
};

/**
 * A hook to provide access to the editor routes, with some util attached.
 *
 * @param routeKey
 */
export const useRoute: <P extends {}>(routeKey: EditorRouteKey) => IUseEditorRoute<P> = <P extends {}>(
    routeKey: EditorRouteKey
) => {
    const editorRoute = routeMap[routeKey];
    const { push, replace } = useHistory();
    const params = useParams<P>();

    const buildPathWithParams = useCallback(compile(editorRoute.fullPath), [editorRoute.fullPath]);

    const goTo = useCallback(
        (args?: IGoToArgs<P>) => {
            const updateParams = cleanParams(args && args.pathParams);
            const newParams = { ...params, ...updateParams };
            const newPath = buildPathWithParams(newParams);
            let search: string | undefined = undefined;
            let useReplace: boolean | undefined = false;

            if (args && args.pushProps) {
                ({ search, replace: useReplace } = args.pushProps);
            }

            let historyUpdateCall = push;
            if (useReplace) {
                historyUpdateCall = replace;
            }

            historyUpdateCall({
                pathname: newPath,
                search
            });
        },
        [push, params, buildPathWithParams, replace]
    );

    const buildPath = (pathParams?: P) => {
        return buildPathWithParams(pathParams);
    };

    return {
        ...editorRoute,
        goTo,
        buildPath,
        params
    };
};

export const useEditorRouteMatch = <P extends {}>(routeKey: EditorRouteKey) => {
    const editorRoute = routeMap[routeKey];
    return useRouteMatch<P>(editorRoute.fullPath);
};
