/* eslint-disable @typescript-eslint/no-explicit-any */
import { IScriptObjectDict, getObjectDict } from './../shared/motive/models/ScriptObjectModel';
import {
    IFrame,
    IScript,
    IResourceWrapper,
    ICondition,
    FRAME_PRE_CONDITION,
    IConditionWrapper
} from '../shared/motive/models/Script';
import { clone, setWith } from 'lodash-es';
import { uniqueMotiveId, createScriptObject, createObjectReferenceFromObject } from './MotiveUtils';
import { MotiveTypes } from '../constants/MotiveTypes';
import { IScriptObjectEventCondition } from '../shared/motive/models/IScriptObjectEventCondition';
import { ITypeDefinitionMap, IObjectDefinition, IEnumItemReference } from '../shared/motive/models/TypeDefinition';
import { IScriptObjectModel } from '../shared/motive/models/ScriptObjectModel';
import { recursivelyProcessFrames } from './FrameOperationUtils';
import { ICompoundCondition, CompoundConditionOperator } from '../shared/motive/models/ICompoundCondition';
import { IDynamicValue } from '../shared/motive/models/IDynamicValue';

export const UNIQUE_ID = 0;

interface IFrameMutationMatch {
    frameId: string;
    modifyFunction: (match: IFrame) => IFrame;
    shouldRunModifyOnParent?: boolean;
}

export class VersionDates {
    public static UseEnumRefMinVersion: Date = new Date('2020-11-01T00:00:00.0Z');

    public static checkDate(minDate: Date, checkDate?: Date): boolean {
        return !!checkDate && checkDate >= minDate;
    }

    public static checkDateString(minDate: Date, checkDate?: string): boolean {
        return !!checkDate && VersionDates.checkDate(minDate, new Date(checkDate + 'Z'));
    }

    public static checkUseEnumRef(checkDate?: string): boolean {
        return VersionDates.checkDateString(VersionDates.UseEnumRefMinVersion, checkDate);
    }
}

export const findFrame = (curr: IFrame, frameId: string): IFrame | undefined => {
    if (curr.id === frameId) {
        return curr;
    }

    //istanbul ignore else
    if (curr.subFrames) {
        for (const sf of curr.subFrames) {
            const branchChild = findFrame(sf, frameId);
            if (branchChild) {
                return branchChild;
            }
        }
    }
    return undefined;
};

export const copyScriptObject = (scriptObject: IScriptObjectModel, typeDefinitions: ITypeDefinitionMap) => {
    const newObject = createScriptObject(scriptObject.type, typeDefinitions);
    let newObjectDict = getObjectDict(newObject);
    const scriptObjDict = getObjectDict(scriptObject);
    const objDef = typeDefinitions[scriptObject.type] as IObjectDefinition;

    Object.keys(objDef.fieldDefinitions).forEach(fieldName => {
        const fieldVal = scriptObjDict[fieldName];

        if (fieldVal !== undefined) {
            const fieldDef = objDef.fieldDefinitions[fieldName];
            let fieldTypeDef = typeDefinitions[fieldDef.typeName];

            if (fieldDef.typeName === 'dynamic') {
                const dynVal = fieldVal as IDynamicValue;

                const newDynVal: IDynamicValue = {
                    valueDefinition: dynVal.valueDefinition,
                    value: undefined
                };

                newObjectDict[fieldName] = newDynVal;

                fieldName = 'value';

                fieldTypeDef = typeDefinitions[dynVal.valueDefinition.typeName];

                newObjectDict = newDynVal as any;
            }

            if (fieldTypeDef.dataType === 'object' && !fieldDef.isExternalReference) {
                if (fieldDef.isArray) {
                    if (Array.isArray(fieldVal)) {
                        const newObjArray: IScriptObjectModel[] = [];
                        const objArray = fieldVal as [];

                        objArray.forEach(v => {
                            newObjArray.push(copyScriptObject(v, typeDefinitions));
                        });

                        newObjectDict[fieldName] = newObjArray;
                    }
                } else {
                    newObjectDict[fieldName] = copyScriptObject(fieldVal as IScriptObjectModel, typeDefinitions);
                }
            } else {
                newObjectDict[fieldName] = scriptObjDict[fieldName];
            }
        }
    });

    return newObject;
};

export const copyResource = (resourceWrapper: IResourceWrapper, typeDefinitions: ITypeDefinitionMap) => {
    const newWrapper: IResourceWrapper = {
        ...resourceWrapper,
        resource: copyScriptObject(resourceWrapper.resource, typeDefinitions)
    };

    return newWrapper;
};

export const eachCondition = (
    condition: IScriptObjectModel | undefined | null,
    call: (condition: IScriptObjectModel) => void
) => {
    if (!condition) return;

    switch (condition.type) {
        case MotiveTypes.CONDITION_WRAPPER:
            const wrapper = condition as IConditionWrapper;

            if (wrapper.condition) {
                eachCondition(wrapper.condition, call);
            }
            break;
        case MotiveTypes.COMPOUND_CONDITION:
            const compound = condition as ICompoundCondition;

            compound?.conditions?.forEach(c => eachCondition(c, call));
            break;
        default:
            call(condition);
            break;
    }
};

export const eachFrame = (curr: IFrame, call: (frame: IFrame) => void) => {
    call(curr);

    curr?.subFrames?.forEach(f => eachFrame(f, call));
};

export const findResource = (script: IScript, resourceId: string) => {
    let resource: IResourceWrapper | undefined;

    eachFrame(script.rootFrame, f => {
        const _r = f.resources?.find(w => w.resource.id === resourceId);

        if (_r) {
            resource = _r;
        }
    });

    return resource;
};

export const findSubframePath = (frame: IFrame, frameId: string): string | undefined => {
    const idx = frame?.subFrames?.findIndex(f => f.id === frameId);

    if (idx !== undefined && idx >= 0) {
        return `subFrames.${idx}`;
    } else if (frame.subFrames) {
        for (let i = 0; i < frame?.subFrames?.length; i++) {
            const path = findSubframePath(frame.subFrames[i], frameId);

            if (path) {
                return `subFrames.${i}.${path}`;
            }
        }
    }

    return undefined;
};

export const findFramePath = (script: IScript, frameId: string): string | undefined => {
    if (script?.rootFrame) {
        if (script.rootFrame.id === frameId) return 'rootFrame';

        const path = findSubframePath(script.rootFrame, frameId);

        if (path) {
            return `rootFrame.${path}`;
        }
    }

    return undefined;
};

export const findFrameByName = (curr: IFrame, name: string): IFrame | undefined => {
    if (curr.name === name) {
        return curr;
    }

    //istanbul ignore else
    if (curr.subFrames) {
        for (const sf of curr.subFrames) {
            const branchChild = findFrameByName(sf, name);
            if (branchChild) {
                return branchChild;
            }
        }
    }
    return undefined;
};

export const findParentOfFrame = (curr: IFrame, frameId: string): IFrame | null => {
    if ((curr.subFrames as IFrame[]).some((frame): boolean => frame.id === frameId)) {
        return curr;
    }

    //istanbul ignore else
    if (curr.subFrames) {
        for (const sf of curr.subFrames) {
            const branchChild = findParentOfFrame(sf, frameId);
            if (branchChild) {
                return branchChild;
            }
        }
    }
    //istanbul ignore
    return null;
};

/**
 * Helper function that will DFS down the tree, while creating a clone ofeach existing node as it is.
 * When it encounters a match at any given node it executes the corresponding modification function.
 * Returns a new copy of the state.
 * @param curr the current frame we are inspecting
 * @param frameId the id of the frame modify
 * @param modifyFunction a function that must return a new copy of the frame node.
 */
const modifyTreeAtFrameId = (curr: IFrame, frameMutationMatches: IFrameMutationMatch[]): IFrame => {
    let subFrames: IFrame[] = [];

    //istanbul ignore else
    if (curr.subFrames) {
        subFrames = curr.subFrames.map((frame): IFrame => modifyTreeAtFrameId(frame, frameMutationMatches));
    }

    let clonedFrame: IFrame = { ...curr, subFrames: subFrames };

    // If we have subframes we may need to run match because a SF is a match.
    if (!!curr.subFrames && curr.subFrames.length > 0) {
        const parentMatches = frameMutationMatches.filter((m): boolean => !!m.shouldRunModifyOnParent);
        for (const potentialMatch of parentMatches) {
            if (curr.subFrames.filter((sf): boolean => sf.id === potentialMatch.frameId).length > 0) {
                clonedFrame = potentialMatch.modifyFunction(clonedFrame);
            }
        }
    }

    //Run any matches that are intended solely for this frame.
    const nodeMatches = frameMutationMatches.filter((m): boolean => !m.shouldRunModifyOnParent);
    for (const potentialMatch of nodeMatches) {
        if (potentialMatch.frameId === curr.id) {
            clonedFrame = potentialMatch.modifyFunction(clonedFrame);
        }
    }

    return clonedFrame;
};

const applyMutationToScript = (script: IScript, frameMutationMatches: IFrameMutationMatch[]): IScript => {
    return { ...script, rootFrame: modifyTreeAtFrameId(script.rootFrame, frameMutationMatches) };
};

export const createFrame = (name: string): IFrame => {
    return {
        id: uniqueMotiveId(),
        type: MotiveTypes.FRAME,
        isExclusive: false,
        isEnabled: true,
        isLive: false,
        resetOnClose: false,
        name: name,
        subFrames: [],
        resources: []
    };
};

export const findOrAddFrameByName = (script: IScript, frameName: string): { script: IScript; frame: IFrame } => {
    const parentFrameId = script.rootFrame?.id;

    if (!parentFrameId) {
        throw new Error('Script does not have root frame.');
    }

    const frame = findFrameByName(script.rootFrame, frameName);

    if (frame) {
        return { script, frame };
    } else {
        const newFrame = createFrame(frameName);

        const { script: newScript } = addFrame(script, parentFrameId, newFrame);

        return { script: newScript, frame: newFrame };
    }
};

export const addFrame = (
    script: IScript,
    targetFrameId?: string,
    frame?: IFrame,
    destinationIndex?: number
): { script: IScript; frameId: string } => {
    if (!targetFrameId) {
        throw new Error('Can not add to a frame without a frame ID.');
    }

    let frameId: string = targetFrameId;

    const nextScript = applyMutationToScript(script, [
        {
            frameId: targetFrameId,
            modifyFunction: (f: IFrame): IFrame => {
                //istanbul ignore next
                const subFramesClone: IFrame[] = [...(f.subFrames ? f.subFrames : [])];

                if (!frame) {
                    // Push the new frame in.
                    const newFrame = createFrame('New Frame');
                    frameId = newFrame.id;
                    subFramesClone.push(newFrame);
                } else {
                    //istanbul ignore next
                    subFramesClone.splice(
                        destinationIndex !== undefined ? destinationIndex : subFramesClone.length - 1,
                        0,
                        frame
                    );
                }
                return { ...f, subFrames: subFramesClone };
            }
        }
    ]);
    return { script: nextScript, frameId };
};

export const removeFrame = (script: IScript, targetFrameId?: string): { script: IScript; frameId: string } => {
    if (!targetFrameId) {
        throw new Error(`Can not remove frame without frameId`);
    }

    if (targetFrameId === script.rootFrame.id) {
        throw new Error(`Can not remove root frames and frame ${script.rootFrame.id} is ${script.id}'s root frame.`);
    }

    let parentFrame: string | undefined = undefined;
    const nextScript = applyMutationToScript(script, [
        {
            frameId: targetFrameId,
            modifyFunction: (f: IFrame): IFrame => {
                if (!f.subFrames) {
                    throw new Error(
                        `Can not remove frame from parent that has no subframes. ${f.id} has no subframes.`
                    );
                }
                const clonedSubFrames = [...f.subFrames.filter((sf): boolean => sf.id !== targetFrameId)];
                parentFrame = f.id;
                return { ...f, subFrames: clonedSubFrames };
            },
            shouldRunModifyOnParent: true
        }
    ]);

    if (parentFrame === undefined) {
        throw new Error(`Could not find parent of frame ${targetFrameId}. Frame can not be removed.`);
    }

    return {
        script: nextScript,
        frameId: parentFrame
    };
};

export const removeResource = (
    script: IScript,
    frameId: string,
    resourceId: string
): { script: IScript; frameId: string; resourceWrapper: IResourceWrapper | undefined } => {
    let resourceWrapper: IResourceWrapper | undefined = undefined;

    const nextScript = applyMutationToScript(script, [
        {
            frameId: frameId,
            modifyFunction: (f: IFrame): IFrame => {
                if (!f.resources || f.resources.length === 0) {
                    throw new Error(`Can not remove from empty array of resources for frame ${f.id}`);
                }

                resourceWrapper = f.resources?.find(c => c.resource.id === resourceId);

                const clonedResources = [
                    ...f.resources.filter((wrapper): boolean => wrapper.resource.id !== resourceId)
                ];

                const newFrame: IFrame = {
                    ...f
                };

                if (
                    newFrame.objectFieldVariableBindings &&
                    resourceWrapper &&
                    newFrame.objectFieldVariableBindings[resourceWrapper.resource.id] !== undefined
                ) {
                    const { [resourceWrapper.resource.id]: _, ...newBindings } = newFrame.objectFieldVariableBindings;

                    newFrame.objectFieldVariableBindings = newBindings;
                }

                return { ...f, resources: clonedResources };
            }
        }
    ]);

    return { script: nextScript, frameId: frameId, resourceWrapper: resourceWrapper };
};

export const combineCondition = (
    currentCondition: IScriptObjectModel | null | undefined,
    condition: IScriptObjectModel,
    objectTypeDefinitions: ITypeDefinitionMap
): IScriptObjectModel => {
    let updatedCondition: IScriptObjectModel;

    if (currentCondition) {
        if (currentCondition.type === MotiveTypes.COMPOUND_CONDITION) {
            const compound: ICompoundCondition = clone(currentCondition) as ICompoundCondition;

            if (!compound.operator) compound.operator = CompoundConditionOperator.AND;

            compound.conditions = compound.conditions ? compound.conditions.concat([condition]) : [condition];

            updatedCondition = compound;
        } else {
            const compound = createScriptObject<ICompoundCondition>(
                MotiveTypes.COMPOUND_CONDITION,
                objectTypeDefinitions
            );

            compound.operator = CompoundConditionOperator.AND;
            compound.conditions = [currentCondition, condition];

            updatedCondition = compound;
        }
    } else {
        updatedCondition = condition;
    }

    return updatedCondition;
};

export const addCondition = (
    currentCondition: IScriptObjectModel | null | undefined,
    condition: IScriptObjectModel,
    objectTypeDefinitions: ITypeDefinitionMap
): IScriptObjectModel => {
    let wrapper =
        currentCondition && currentCondition.type === MotiveTypes.CONDITION_WRAPPER
            ? (currentCondition as IConditionWrapper)
            : undefined;

    const _curr = wrapper ? wrapper.condition : currentCondition;

    const updatedCondition = combineCondition(_curr, condition, objectTypeDefinitions);

    if (!wrapper) {
        wrapper = createScriptObject<IConditionWrapper>(MotiveTypes.CONDITION_WRAPPER, objectTypeDefinitions);
        wrapper.isEnabled = true;
    } else {
        wrapper = clone(wrapper);
    }

    wrapper.condition = updatedCondition;

    return wrapper;
};

export const countTotalConditionsRecursive: (condition?: ICondition) => number = condition => {
    if (!condition) {
        return 0;
    } else if (condition.type !== MotiveTypes.COMPOUND_CONDITION) {
        return 1;
    }
    const compound = condition as ICompoundCondition;
    if (!compound.conditions || compound.conditions.length <= 0) {
        return 1;
    }

    return compound.conditions.reduce((state, subCondition) => state + countTotalConditionsRecursive(subCondition), 0);
};

export const addPreCondition = (
    script: IScript,
    frameId?: string,
    condition?: IScriptObjectModel,
    objectDefinitions?: ITypeDefinitionMap
): { script: IScript; frameId: string } => {
    if (!frameId) {
        throw new Error('Can not add resource to frame without a frameId.');
    }

    if (!condition) {
        throw new Error('Can not add resource without a resource set.');
    }

    if (!objectDefinitions) {
        throw new Error('object defs not found');
    }

    const nextScript = applyMutationToScript(script, [
        {
            frameId: frameId,
            modifyFunction: (f: IFrame): IFrame => {
                const updatedCondition = addCondition(f[FRAME_PRE_CONDITION], condition, objectDefinitions);

                return { ...f, preCondition: updatedCondition };
            }
        }
    ]);

    return { script: nextScript, frameId: frameId };
};

export const addResourceWrapper = (
    script: IScript,
    frameId?: string,
    resourceWrapper?: IResourceWrapper,
    indexToAddAt: number = -1
): { script: IScript; frameId: string } => {
    if (!frameId) {
        throw new Error('Can not add resource to frame without a frameId.');
    }

    if (!resourceWrapper) {
        throw new Error('Can not add resource without a resource set.');
    }

    const nextScript = applyMutationToScript(script, [
        {
            frameId: frameId,
            modifyFunction: (f: IFrame): IFrame => {
                const clonedResources = [...(f.resources ? f.resources : [])];

                if (indexToAddAt < 0) {
                    clonedResources.push(resourceWrapper);
                } else {
                    clonedResources.splice(indexToAddAt, 0, resourceWrapper);
                }

                return { ...f, resources: clonedResources };
            }
        }
    ]);

    return { script: nextScript, frameId: frameId };
};

export const addResource = (
    script: IScript,
    frameId?: string,
    resource?: IScriptObjectModel,
    indexToAddAt: number = -1
): { script: IScript; frameId: string } => {
    if (!frameId) {
        throw new Error('Can not add resource to frame without a frameId.');
    }

    if (!resource) {
        throw new Error('Can not add resource without a resource set.');
    }

    const nextScript = applyMutationToScript(script, [
        {
            frameId: frameId,
            modifyFunction: (f: IFrame): IFrame => {
                const clonedResources = [...(f.resources ? f.resources : [])];

                if (indexToAddAt < 0) {
                    clonedResources.push({
                        isEnabled: true,
                        resource: { ...resource }
                    });
                } else {
                    clonedResources.splice(indexToAddAt, 0, {
                        isEnabled: true,
                        resource: { ...resource }
                    });
                }

                return { ...f, resources: clonedResources };
            }
        }
    ]);

    return { script: nextScript, frameId: frameId };
};

export const moveResource = (
    script: IScript,
    frameId?: string,
    sourceIndex?: number,
    destinationIndex?: number
): { script: IScript; frameId: string } => {
    if (!frameId) {
        throw new Error('Can not add resource to frame without a frameId.');
    }

    if (sourceIndex === undefined || destinationIndex === undefined) {
        throw new Error(
            `Both a source and destination index are required to move a resource for frame=${frameId} the following was given source=${sourceIndex} destination=${destinationIndex}`
        );
    }

    const nextScript = applyMutationToScript(script, [
        {
            frameId: frameId,
            modifyFunction: (f: IFrame): IFrame => {
                if (!f.resources || f.resources.length === 0) {
                    throw new Error(`Can not move resources in frame ${f.id} which has no resources.`);
                }

                const clonedResourceEntry = { ...f.resources[sourceIndex] };
                const clonedResources = [...f.resources.filter((element, index): boolean => index !== sourceIndex)];
                clonedResources.splice(destinationIndex, 0, clonedResourceEntry);
                return { ...f, resources: clonedResources };
            }
        }
    ]);

    return { script: nextScript, frameId: frameId };
};
export const updateFrameObject = (script: IScript, frameId: string, object: any, path: string): IScript => {
    const nextScript = applyMutationToScript(script, [
        {
            frameId: frameId,
            modifyFunction: (f: IFrame): IFrame => {
                const newFrame = { ...f };

                setWith(newFrame, path, object, clone);

                return newFrame;
            }
        }
    ]);

    return nextScript;
};

export const updateFrame = (script: IScript, frame: IFrame): IScript => {
    const nextScript = applyMutationToScript(script, [
        {
            frameId: frame.id,
            modifyFunction: (): IFrame => {
                return frame;
            }
        }
    ]);

    return nextScript;
};

export const updateResource = (
    script: IScript,
    newResource: IResourceWrapper,
    frameId?: string
): { script: IScript; frameId: string } => {
    if (!frameId) {
        throw new Error('Can not update resource in a frame without a frameId.');
    }

    const nextScript = applyMutationToScript(script, [
        {
            frameId: frameId,
            modifyFunction: (f: IFrame): IFrame => {
                if (!f.resources || f.resources.length === 0) {
                    throw new Error(`Can not update resources in frame ${f.id} which has no resources.`);
                }

                const clonedResources = f.resources.map(r => {
                    if (r.resource.id === newResource.resource.id) {
                        return newResource;
                    } else {
                        return r;
                    }
                });

                return { ...f, resources: clonedResources };
            }
        }
    ]);

    return { script: nextScript, frameId: frameId };
};

export const renameFrame = (script: IScript, frameId: string, newName: string): IScript => {
    const nextScript = applyMutationToScript(script, [
        {
            frameId: frameId,
            modifyFunction: (f: IFrame): IFrame => {
                return { ...f, name: newName };
            }
        }
    ]);

    return nextScript;
};

export const createObjectEventLinkFrame = (
    frameName: string,
    scriptObj: IScriptObjectModel,
    event: IEnumItemReference | string,
    objectTypeDefinitions: ITypeDefinitionMap
): IFrame => {
    const newFrame = createFrame(frameName);

    const wrapper = createScriptObject<IConditionWrapper>(MotiveTypes.CONDITION_WRAPPER, objectTypeDefinitions);
    const scriptObjCondition = createScriptObject<IScriptObjectEventCondition>(
        MotiveTypes.SCRIPT_OBJECT_CONDITION,
        objectTypeDefinitions
    );

    scriptObjCondition.event = event;
    scriptObjCondition.objectReference = createObjectReferenceFromObject(scriptObj, objectTypeDefinitions);

    wrapper.condition = scriptObjCondition;
    wrapper.isEnabled = true;

    newFrame[FRAME_PRE_CONDITION] = wrapper;

    return newFrame;
};

export const createCustomEventLinkFrame = (
    frameName: string,
    event: string,
    objectDefinitiions: ITypeDefinitionMap
): IFrame => {
    const newFrame = createFrame(frameName);

    const preCondition = createScriptObject(
        MotiveTypes.CUSTOM_EVENT_CONDITION,
        objectDefinitiions
    ) as IScriptObjectEventCondition;
    preCondition.event = event;
    newFrame[FRAME_PRE_CONDITION] = preCondition;

    return newFrame;
};

export const addFieldArrayItem = (onChange: (item: any) => void, array: any[], item: any) => {
    let newArr: any[];

    if (array) {
        newArr = clone(array);
    } else {
        newArr = [];
    }

    newArr.push(item);

    onChange(newArr);
};

export const removeFieldArrayItem = (onChange: (item: any) => void, array: any[], idx: number) => {
    const newArr = clone(array);

    newArr.splice(idx, 1);

    onChange(newArr);
};

export const swapFieldArrayItems = (onChange: (item: any) => void, array: any[], fromIdx: number, toIdx: number) => {
    const newArr = clone(array);

    const from = array[fromIdx];
    newArr[fromIdx] = newArr[toIdx];
    newArr[toIdx] = from;

    onChange(newArr);
};

export const findCustomEvents = (script: IScript): string[] => {
    const customEventList: string[] = [];

    const addToList = (frame: IFrame) => {
        if (frame.resources) {
            frame.resources.forEach((resourceWrapper: IResourceWrapper) => {
                if (resourceWrapper.resource.type === MotiveTypes.CUSTOM_EVENT) {
                    const event = ((resourceWrapper.resource as unknown) as IScriptObjectDict)['event'] as string;
                    event && !customEventList.includes(event) && customEventList.push(event);
                }
            });
        }
    };

    recursivelyProcessFrames(script, addToList);

    return customEventList;
};
