import React from 'react';
import { Collapse } from '@chakra-ui/core';

export interface ICollapsibleProps {
    isShowing: boolean;
    className?: string;
}

export const Collapsible: React.FC<ICollapsibleProps> = ({
    children,
    isShowing,
    className,
    ...restProps
}): React.ReactElement => {
    return (
        <Collapse isOpen={isShowing} className={className} {...restProps}>
            {children}
        </Collapse>
    );
};
