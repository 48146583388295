import css from '@emotion/css';
import {
    breakPointSmaller,
    breakPointSmall,
    breakPointMedium,
    breakPointLarge,
    breakPointLarger
} from '../constants/Breakpoints';

const flexColumn = css`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const transitionFlexBasis = css`
    transition: flex-basis 600ms ease-in-out;
`;

const flexNoShrinkNoGrowBasisIs = (percentSize: number) => css`
    flex: 0 0 ${percentSize}%;
`;

export const containerFlexStyle = css`
    display: flex;
    justify-content: space-between;
`;

export const leftFlexStyle = css`
    ${flexColumn}
    flex: 0 0 40px;
    ${transitionFlexBasis}

    ${breakPointSmaller} {
        flex-basis: 40px;
    }
    ${breakPointSmall} {
        flex-basis: 40px;
    }
    ${breakPointMedium} {
        flex-basis: 90px;
    }
    ${breakPointLarge} {
        flex-basis: 120px;
    }
    ${breakPointLarger} {
        flex-basis: 10%;
    }
`;
export const middleFlexStyle = css`
    ${flexColumn};
    flex: 1 1;
`;

export const rightFlexStyle = css`
    ${flexColumn}
    ${flexNoShrinkNoGrowBasisIs(10)}
    ${transitionFlexBasis}
    
    ${breakPointSmaller} {
        flex-basis: 0;
        border: unset;
    }
    ${breakPointSmall} {
        flex-basis: 30px;
        transition: flex-basis 200ms ease-in-out;
    }
    ${breakPointMedium} {
        flex-basis: 45px;
    }
    ${breakPointLarge} {
        flex-basis: 60px;
    }
    ${breakPointLarger} {
        flex-basis: 5%;
    }
`;

export const subContentFlexStyle = css`
    ${flexNoShrinkNoGrowBasisIs(25)}
`;
