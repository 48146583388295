import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const rowStyle = ({ colors, colors: { border }, spacings }: IToken) => css`
    border-bottom: 1px solid ${border.primary};
    padding: ${spacings.medium};

    &:hover {
        background-color: ${colors.background.lighter};
        .iconWrapper {
            opacity: 1;
        }
    }
`;

export const subcontentStyle = (tokens: IToken) => css`
    color: ${tokens.colors.font.secondary};
    font-size: ${tokens.fontSizes.small};
`;

export const chevronIconStyle = css`
    transform: rotate(-90deg);
    color: lightgray;
`;
