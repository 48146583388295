import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';
import { middleContentBaseStyle } from '../dynamicRowBase/DynamicRowBase.style';

const BRANCH_COLOR = '#47474a';
const HOVER_BRANCH_COLOR = `#3a3a3c`;

export const rowMiddleItemStyle1 = css`
    width: 50%;
    font-size: 0.95rem;
    padding: 2px;
`;
export const rowMiddleItemStyle2 = css`
    width: 25%;
`;

export const rowMiddleItemStyle3 = css`
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

export const branchLineStyle = css`
    fill: none;
    stroke-width: 2;
    stroke: ${BRANCH_COLOR};
`;

export const branchMiniItemStyle = (token: IToken, size: number) => css`
    height: ${size}px;
    width: ${size}px;
    background-color: ${BRANCH_COLOR};
    border-radius: ${token.borderRadii.small};
`;

export const branchRowStyle = ({ spacings }: IToken) => css`
    margin-bottom: ${spacings.smallest};
    display: grid;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`;

export const dropDownStyle = css`
    padding: 2px 4px;
    min-width: 100px;
    color: white;
    background-color: #2f2f2f;
    border-radius: 4px;
    outline: none;
    border: none;
    &:focus {
        box-shadow: none;
    }
`;

export const branchButtonStyle = css`
    background-color: ${BRANCH_COLOR};
    color: white;
    &:hover {
        background-color: ${HOVER_BRANCH_COLOR};
    }
`;

export const middleContentStyle = css`
    ${middleContentBaseStyle}
    background-color: ${BRANCH_COLOR};
    &:hover{
        background-color: ${HOVER_BRANCH_COLOR};
    }
`;

export const deleteButtonIconStyle = css`
    color: white;
    &:hover {
        color: #dcdcdc;
    }
`;
