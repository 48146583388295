import {
    ISelectorNode,
    ISelectorBranch,
    ISelectorTree,
    IFrame,
    IVariableSelector,
    IScript,
    IScriptVariable,
    IFrameDefinedVariable
} from '../shared/motive/models/Script';
import { IScriptObjectModel, PRIMITIVE_TYPES_NAME_MAP } from '../shared/motive/models/ScriptObjectModel';
import { clone, setWith, cloneDeep } from 'lodash-es';
import { createObjectReference, isTypeOrInterface, uniqueMotiveId, createScriptObject } from './MotiveUtils';
import { MotiveTypes } from '../constants/MotiveTypes';
import {
    ITypeDefinition,
    IObjectDefinition,
    IObjectDefinitionMap,
    IValueDefinition,
    ITypeDefinitionMap
} from '../shared/motive/models/TypeDefinition';
import { IDynamicValue } from '../shared/motive/models/IDynamicValue';
import {
    ScriptReducerAction,
    IScriptFrameAction,
    ScriptActionType
} from '../shared/motive/reducers/ScriptEditorReducers';
import { IDynamicValueSelector } from '../shared/motive/models/IDynamicValueSelector';
import { ICatalog } from '../shared/motive/models/Catalog';
import { ISetOperationSelector } from '../shared/motive/models/ISetOperationSelector';

const DEFAULT_SELECTOR_BRANCH_NAME = 'Branch';
const DEFAULT_SELECTOR_SCENARIO_NAME = 'Node Name';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IScriptVariableMap extends Record<string, IScriptVariable> {}

const toScriptVariableMap = (state: IScriptVariableMap, curVar: IScriptVariable) => {
    if (!curVar?.id || state[curVar.id]) {
        return state;
    }
    return {
        ...state,
        [curVar.id]: curVar
    };
};

export const getScriptVariablesMap = (scriptVariables?: IScriptVariable[]) =>
    scriptVariables?.reduce(toScriptVariableMap, {}) ?? {};

export const getScriptAndGlobalVariablesMap = (variableMap: IScriptVariableMap, catalogs: ICatalog<IScript>[]) => {
    return (
        catalogs?.reduce((stateCatalogLevel: IScriptVariableMap, curCatalog) => {
            if (!curCatalog?.items) {
                return stateCatalogLevel;
            }

            return curCatalog.items.reduce((stateScriptLevel, curScriptVariablesInfo) => {
                if (!curScriptVariablesInfo.variables?.some(v => v.isGlobal)) {
                    return stateScriptLevel;
                }

                return {
                    ...curScriptVariablesInfo.variables
                        .filter(v => v.isGlobal)
                        .reduce(toScriptVariableMap, stateScriptLevel)
                };
            }, stateCatalogLevel);
        }, variableMap) ?? {}
    );
};

export const createSelectorScenario = () => {
    const scenario: ISelectorNode = {
        id: uniqueMotiveId(),
        type: MotiveTypes.SELECTOR_NODE,
        name: DEFAULT_SELECTOR_SCENARIO_NAME,
        selectors: undefined,
        branches: undefined
    };

    return scenario;
};

export const createSelectorBranch = () => {
    const branch: ISelectorBranch = {
        name: DEFAULT_SELECTOR_BRANCH_NAME,
        nodes: [createSelectorScenario()]
    };
    return branch;
};

export const createSelectorTree = () => {
    const tree: ISelectorTree = {
        branches: [createSelectorBranch()]
    };
    return tree;
};

export const createFrameDefinedVariable = (scriptVariableId: string, args?: Partial<IFrameDefinedVariable>) => {
    const frameVar: IFrameDefinedVariable = { isRequired: false, ...(args ?? {}), variableId: scriptVariableId };
    return frameVar;
};

export const createdSelectedValue = (
    value: IDynamicValue['value'],
    valueDefinition: IDynamicValue['valueDefinition']
) => {
    const selectedValue: IDynamicValue = {
        value,
        valueDefinition
    };
    return selectedValue;
};

const createSelectorForVariable = (
    variable: IScriptVariable,
    selectorType: string | undefined,
    typeDefinitions: ITypeDefinitionMap,
    autoCreateEmptySelectedValue: boolean = false
) => {
    if (!selectorType || selectorType === MotiveTypes.DYNAMIC_VALUE_SELECTOR) {
        const varSelector: IDynamicValueSelector = {
            variableReference: createObjectReference(variable.type, variable.id, variable.name),
            id: uniqueMotiveId(),
            type: MotiveTypes.DYNAMIC_VALUE_SELECTOR,
            selectedValue: !autoCreateEmptySelectedValue
                ? undefined
                : createdSelectedValue(undefined, variable.valueDefinition)
        };

        return varSelector;
    } else if (selectorType === MotiveTypes.SET_OPERATION_SELECTOR) {
        const varSelector: ISetOperationSelector = {
            variableReference: createObjectReference(variable.type, variable.id, variable.name),
            id: uniqueMotiveId(),
            type: MotiveTypes.SET_OPERATION_SELECTOR,
            leftOperand: createdSelectedValue(undefined, variable.valueDefinition),
            rightOperand: createdSelectedValue(undefined, variable.valueDefinition)
        };

        return varSelector;
    } else {
        const varSelector = createScriptObject<IVariableSelector>(selectorType, typeDefinitions);

        varSelector.variableReference = createObjectReference(variable.type, variable.id, variable.name);

        return varSelector;
    }
};

export const getSelectorForVariable = (selectors?: IVariableSelector[], variableId?: string) => {
    if (variableId && selectors) {
        const index = selectors.findIndex(s => {
            return s.variableReference && s.variableReference.objectId === variableId;
        });

        if (index >= 0) {
            const selector = selectors[index];

            return { selector, index };
        }
    }

    return { selector: undefined, index: undefined };
};

export const getDefaultSelectorForVariable: (
    frame: IFrame | undefined,
    variableId: string | undefined
) => {
    selector?: IScriptObjectModel;
    index?: number;
} = (frame, variableId) => {
    return getSelectorForVariable(frame?.selectorTree?.defaultSelectors, variableId);
};

export const setDefaultSelectorForVariable: (frame: IFrame, variableId: string, value: IScriptObjectModel) => IFrame = (
    frame,
    variableId,
    value
) => {
    const defaultSelectors: IVariableSelector[] = frame.selectorTree?.defaultSelectors
        ? clone(frame.selectorTree?.defaultSelectors)
        : [];
    const oldSelectorIndex = defaultSelectors?.findIndex(
        defaultSelector => defaultSelector.variableReference?.objectId === variableId
    );
    const hasOldSelector = oldSelectorIndex > -1;

    const variableReference =
        hasOldSelector && defaultSelectors[oldSelectorIndex].variableReference
            ? defaultSelectors[oldSelectorIndex].variableReference
            : createObjectReference(MotiveTypes.SCRIPT_VARIABLE, variableId, 'my var');
    const newVarSelectValue: IVariableSelector = { ...value, variableReference };

    if (!!!value && hasOldSelector) {
        defaultSelectors.splice(oldSelectorIndex, 1);
    } else if (hasOldSelector) {
        defaultSelectors[oldSelectorIndex] = newVarSelectValue;
    } else {
        defaultSelectors.push(newVarSelectValue);
    }

    const branches = frame.selectorTree?.branches ?? [];

    return {
        ...frame,
        selectorTree: {
            branches,
            defaultSelectors
        }
    };
};

export const getScriptVariablesForType = (script: IScript, typeDefinition: ITypeDefinition): IScriptVariable[] => {
    if (script.variables) {
        return script.variables.filter(v => {
            if (typeDefinition.dataType === 'object') {
                const objDef = typeDefinition as IObjectDefinition;

                return isTypeOrInterface(v.valueDefinition.typeName, objDef);
            } else {
                return typeDefinition.name === v.valueDefinition.typeName;
            }
        });
    }

    return [];
};

export const addDefinedVariableToFrame = (variableId: string, frame: IFrame) => {
    if (!frame.definedVariables?.some(frameVar => frameVar.variableId === variableId)) {
        frame = {
            ...frame,
            definedVariables: [...(frame.definedVariables ?? []), createFrameDefinedVariable(variableId)]
        };
    }

    return frame;
};

export const addVariableSelectorToSelectors = (
    variableId: string,
    currentSelectors: IVariableSelector[] | undefined,
    pathToSelectorsFromFrame: string,
    script: IScript,
    frame: IFrame,
    typeDefinitions: ITypeDefinitionMap,
    scriptVarMap: IScriptVariableMap,
    selectorType?: string
) => {
    const variable = scriptVarMap[variableId];
    if (!variable) {
        throw new Error(`selected variable not found in variable map: ${variableId}`);
    } else if (currentSelectors?.some(selector => selector.variableReference?.objectId === variableId)) {
        throw new Error('that variable is already assigned to a selector');
    }

    const oldSelectors = currentSelectors ?? [];
    const newSelectors: IVariableSelector[] = [
        ...oldSelectors,
        createSelectorForVariable(variable, selectorType, typeDefinitions, true)
    ];

    // todo check/set frame.variables
    if (!frame.definedVariables?.some(frameVar => frameVar.variableId === variableId)) {
        frame = {
            ...frame,
            definedVariables: [...(frame.definedVariables ?? []), createFrameDefinedVariable(variableId)]
        };
    }

    frame = setWith(cloneDeep(frame), pathToSelectorsFromFrame, newSelectors, cloneDeep);
    return frame;
};

export const getFieldVariableId = (frame: IFrame, objectId: string, fieldName: string) => {
    if (frame?.objectFieldVariableBindings) {
        const bindings = frame.objectFieldVariableBindings[objectId];

        if (bindings) {
            const varId = bindings[fieldName];

            return varId;
        }
    }

    return undefined;
};

export const getUniqueVariableName = (
    scriptVariables: IScriptVariable[] | undefined,
    varName: string,
    idx: number = 0
): string => {
    if (scriptVariables?.some(v => v.name === varName)) {
        return getUniqueVariableName(scriptVariables, `${varName} (${idx})`, idx++);
    } else {
        return varName;
    }
};

export const fieldHasVariable = (frame: IFrame, objectId: string, fieldName: string) => {
    return !!frame.objectFieldVariableBindings?.[objectId]?.[fieldName];
};

export const createNewVariable = (
    script: IScript,
    valueDefinition: IValueDefinition,
    name: string,
    scriptDispatch: React.Dispatch<ScriptReducerAction>
) => {
    const varName = getUniqueVariableName(script.variables, name);

    const variable: IScriptVariable = {
        id: uniqueMotiveId(),
        type: MotiveTypes.SCRIPT_VARIABLE,
        isGlobal: false,
        isInput: false,
        isNetwork: false,
        isOutput: false,
        name: varName,
        valueDefinition: {
            id: uniqueMotiveId(),
            type: MotiveTypes.VALUE_DEFINITION,
            isArray: valueDefinition.isArray,
            isExternalReference: valueDefinition.isExternalReference,
            isNullable: valueDefinition.isNullable,
            typeName: valueDefinition.typeName,
            useIdOnly: valueDefinition.useIdOnly,
            referenceScope: valueDefinition.referenceScope
        }
    };

    const newVariables = script.variables ? [...script.variables, variable] : [variable];

    const varAction = {
        type: ScriptActionType.UPDATE_SCRIPT_VARIABLES,
        variables: newVariables
    };

    scriptDispatch(varAction);

    return variable;
};

export const createNewFieldVariable = (
    script: IScript,
    frame: IFrame,
    variableName: string | undefined,
    valueDefinition: IValueDefinition,
    objectId: string,
    fieldName: string,
    scriptDispatch: React.Dispatch<ScriptReducerAction>
) => {
    const variable = createNewVariable(
        script,
        valueDefinition,
        variableName ?? `${objectId}.${fieldName}`,
        scriptDispatch
    );

    const newFrame = setFieldVariable(frame, objectId, fieldName, variable.id);

    const action: IScriptFrameAction = {
        type: ScriptActionType.FRAME_UPDATE,
        frame: newFrame
    };

    scriptDispatch(action);

    return variable;
};

export const isAppendable = (valueDefinition: IValueDefinition) => {
    return (
        valueDefinition.isArray ||
        valueDefinition.typeName === MotiveTypes.LOCALIZED_TEXT ||
        valueDefinition.typeName === MotiveTypes.STRING
    );
};

const eachSelectorNode = (branches: ISelectorBranch[] | undefined, call: (node: ISelectorNode) => void) => {
    branches?.forEach(b => {
        b.nodes?.forEach(n => {
            call(n);

            eachSelectorNode(n.branches, call);
        });
    });
};

export const removeFrameVariableBindings = (frame: IFrame, variableId: string): IFrame => {
    if (frame.objectFieldVariableBindings) {
        const newFrame: IFrame = {
            ...frame,
            objectFieldVariableBindings: cloneDeep(frame.objectFieldVariableBindings)
        };

        if (newFrame.objectFieldVariableBindings) {
            const resourceIds = Object.keys(newFrame.objectFieldVariableBindings);

            resourceIds.forEach(r => {
                const objMap = newFrame.objectFieldVariableBindings?.[r];

                if (objMap) {
                    const fields = Object.keys(r);

                    fields.forEach(f => {
                        if (objMap[f] === variableId) {
                            delete objMap[f];
                        }
                    });
                }
            });

            return newFrame;
        }
    }

    return frame;
};

export const removeFrameVariable = (frame: IFrame, variableId: string, removeObjectBindings?: boolean): IFrame => {
    const newFrame = {
        ...frame,
        selectorTree: cloneDeep(frame.selectorTree),
        definedVariables: cloneDeep(frame.definedVariables)
    };

    const removeSelectors = (selectors?: IVariableSelector[]) => {
        if (selectors) {
            return selectors.filter(s => s.variableReference?.objectId !== variableId);
        }
    };

    // Now iterate through all selectors looking for refs to this variable
    if (newFrame.selectorTree) {
        newFrame.selectorTree.defaultSelectors = removeSelectors(newFrame.selectorTree.defaultSelectors);

        eachSelectorNode(newFrame.selectorTree.branches, node => {
            node.selectors = removeSelectors(node.selectors);
        });
    }

    newFrame.definedVariables = frame.definedVariables
        ? frame.definedVariables.filter(v => v.variableId !== variableId)
        : undefined;

    if (removeObjectBindings) {
        return removeFrameVariableBindings(newFrame, variableId);
    }

    return newFrame;
};

export const removeFieldVariable = (frame: IFrame, objectId: string, fieldName: string): IFrame => {
    if (frame.objectFieldVariableBindings?.[objectId]?.[fieldName]) {
        const objBindings = { ...frame.objectFieldVariableBindings[objectId] };
        delete objBindings[fieldName];

        if (Object.keys(objBindings).length === 0) {
            const bindings = { ...frame.objectFieldVariableBindings };
            delete bindings[objectId];

            const newFrame = { ...frame };
            newFrame.objectFieldVariableBindings = bindings;

            return newFrame;
        } else {
            const newFrame = { ...frame };
            const bindings = { ...frame.objectFieldVariableBindings };

            bindings[objectId] = objBindings;

            newFrame.objectFieldVariableBindings = bindings;

            return newFrame;
        }
    }

    return frame;
};

export const setFieldVariable = (
    frame: IFrame,
    objectId: string,
    fieldName: string,
    variableId: string | undefined
) => {
    const newFrame = clone(frame);

    if (objectId && fieldName) {
        const bindings = newFrame.objectFieldVariableBindings ? { ...newFrame.objectFieldVariableBindings } : {};

        if (variableId) {
            const binding = { ...bindings[objectId] } ?? {};

            binding[fieldName] = variableId;

            bindings[objectId] = binding;

            newFrame.objectFieldVariableBindings = bindings;
        } else {
            const binding = { ...bindings[objectId] };

            if (binding) {
                delete binding[fieldName];

                if (Object.keys(binding).length === 0) {
                    delete bindings[objectId];
                }

                newFrame.objectFieldVariableBindings = bindings;
            }
        }
    }

    return newFrame;
};

export const getScriptVariableTypeName = (scriptVar: IScriptVariable, objectDefinitions: IObjectDefinitionMap) => {
    const { valueDefinition } = scriptVar;

    if (!valueDefinition?.typeName) return;

    const objectDefinitionName = valueDefinition.typeName;
    const typeDef = objectDefinitions[objectDefinitionName];
    const objectDefinitionTitle = typeDef?.title ?? typeDef?.editorInfo?.title;
    const nicePrimitiveName = PRIMITIVE_TYPES_NAME_MAP.get(valueDefinition.typeName as MotiveTypes);

    return nicePrimitiveName ?? objectDefinitionTitle ?? objectDefinitionName;
};
