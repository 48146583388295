import { css } from '@emotion/core';
import { useStyle } from '../../shared/hooks/useStyle';
import { useSpaces } from '../../shared/motive/hooks/useSpaces';
import * as React from 'react';
import { Card } from '../../core-ui/card';
import { SpaceItem } from '../spaceItem';
import { InputField } from '../../core-ui/inputField';
import { Size } from '../../core-ui/constants/Size';
import { debounce, times } from 'lodash-es';
import { SpacesGrid, InputText, SpacesListGridArea } from './Spaces.style';

export const Spaces: React.FC = () => {
    const { data: spaces, isLoading } = useSpaces();

    const [search, setSearch] = React.useState('');

    const handleSearch = debounce((value: string) => setSearch(value), 100, { maxWait: 300 });
    const searchString = search.toLowerCase().trim();

    return (
        <div css={SpacesGrid}>
            <InputField css={InputText} defaultValue={search} size={Size.LARGE} onChange={handleSearch}></InputField>
            <div css={SpacesListGridArea}>
                {isLoading
                    ? times(21, idx => <SpaceItem key={idx} />)
                    : spaces
                          .filter(space => search.length === 0 || space.fullName.includes(searchString))
                          .map(space => {
                              return (
                                  <Card key={space.fullName}>
                                      <SpaceItem space={space}></SpaceItem>
                                  </Card>
                              );
                          })}
            </div>
        </div>
    );
};
