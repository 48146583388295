import React from 'react';
import { IToken } from './interfaces/Token';
import { styleTokenDefaults } from '../../../stores/defaultStyleStore/styleTokenDefaults';

interface IStyleTokenContextValue {
    tokens: IToken;
    changePaletteColor: (color: string) => void;
}

export const StyleContext = React.createContext<IStyleTokenContextValue>({
    tokens: styleTokenDefaults,
    changePaletteColor: () => undefined
});

export const StyleProvider: React.FC<{ value: IStyleTokenContextValue }> = props => (
    <StyleContext.Provider {...props}></StyleContext.Provider>
);
