import { IScriptEditorInfo } from '../../models/ScriptEditorInfo';
import {
    IObjectDefinition,
    ITypeDefinitionMap,
    ITypeDefinition,
    IObjectDefinitionMap
} from '../../models/TypeDefinition';
import { Logger } from '../../../../util/logger';
import { ITypeBundle } from '../../models/TypeBundle';
import { MotiveTypes } from '../../../../constants/MotiveTypes';
import { useSelector, useDispatch } from 'react-redux';
import { IEnumDefinition } from '../../models/EnumDefinition';
import { IHookProvidedDataSource } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useCurrentSpace } from '../useCurrentSpace';
import { IScriptEditorInfoAction, ScriptEditorInfoStatus } from '../../reducers/ScriptEditorInfoReducer';
import { GetScriptEditorInfo } from '../../networking/SpacesService';
import { useNetworkCallForDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { SelectScriptEditorInfo } from '../../../../redux/spaceKeyed/editorInfo/EditorInfoSelectors';
import { scriptEditorInfoSetAction } from '../../../../redux/spaceKeyed/editorInfo/EditorInfoActions';

export const useScriptEditorInfo = (): IHookProvidedDataSource<IScriptEditorInfo> => {
    const projectDispatch = useDispatch();
    const [currentSpace] = useCurrentSpace();
    const data = useSelector(SelectScriptEditorInfo);
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallForDataSource(data);

    if (!currentSpace) {
        throw new Error('Can not use script editor info without a current space');
    }

    const editorInfoDispatch = (editorInfoAction: IScriptEditorInfoAction) => {
        if (editorInfoAction.status === ScriptEditorInfoStatus.ERROR) {
            Logger.error('get script editor info failed');
        } else {
            if (editorInfoAction.scriptEditorInfo) {
                projectDispatch(scriptEditorInfoSetAction(editorInfoAction.scriptEditorInfo));
            }
        }
    };

    const load = async () => {
        await triggerCall(GetScriptEditorInfo(currentSpace, getCancelableDispatch(editorInfoDispatch)));
    };

    return {
        data: data,
        load: load,
        ...rest
    };
};

const getTypeDefinition = (definitions: ITypeDefinitionMap, type: string): ITypeDefinition | undefined => {
    if (!type || type.length === 0) {
        return undefined;
    }

    const objectDefinition = definitions[type];

    if (!objectDefinition) {
        Logger.error(
            Object.keys(definitions).length > 0
                ? `An invalid type has been supplied for this space and the object definition for ${type} does not exist`
                : 'You are only allowed to use this hook once type definitions have loaded in'
        );
    }

    return objectDefinition;
};

const getObjectDefinition = (definitions: ITypeDefinitionMap, type: string): IObjectDefinition => {
    return getTypeDefinition(definitions, type) as IObjectDefinition;
};

export const useTypeDefinitions = (): ITypeDefinitionMap => {
    const {
        data: { typeDefinitions }
    } = useScriptEditorInfo();

    return typeDefinitions;
};

export const useGetTypeDefinitions = (): ((type: string) => ITypeDefinition | undefined) => {
    const typeDefinitions = useTypeDefinitions();

    return type => {
        if (Object.keys(typeDefinitions).length > 0) {
            return getTypeDefinition(typeDefinitions, type);
        }
        return;
    };
};

export const useTypeDefinition = <T extends ITypeDefinition>(type: string): T => {
    const typeDefinitions = useTypeDefinitions();

    return getTypeDefinition(typeDefinitions, type) as T;
};

export const useObjectDefinition = (type: string): IObjectDefinition => {
    return useTypeDefinition<IObjectDefinition>(type);
};

export const getObjectTypeAndImplementors = (objDef: IObjectDefinition, includeAbstract?: boolean): string[] => {
    const types = includeAbstract || !objDef.isAbstract ? [objDef.name] : [];
    // Special case: callers should look for this

    if (objDef?.implementors?.length > 0) {
        return types.concat(objDef.implementors);
    }

    return types;
};

export const useObjectTypeAndImplementors = (type: string, includeAbstract?: boolean): string[] => {
    const objDef = useObjectDefinition(type);

    if (type === MotiveTypes.OBJECT) {
        return [MotiveTypes.OBJECT];
    }

    return getObjectTypeAndImplementors(objDef, includeAbstract);
};

export const useObjectDefinitions = (types?: string[]): IObjectDefinitionMap => {
    const {
        data: { typeDefinitions }
    } = useScriptEditorInfo();

    return types
        ? types.reduce((state: IObjectDefinitionMap, curType: string) => {
              if (state[curType]) {
                  return state;
              }
              return {
                  ...state,
                  [curType]: getObjectDefinition(typeDefinitions, curType)
              };
          }, {})
        : (typeDefinitions as IObjectDefinitionMap);
};

export const useConditionTypes = (): ITypeBundle[] => {
    const {
        data: { conditionTypes }
    } = useScriptEditorInfo();

    return conditionTypes;
};

export const useEnumTypes = (): IEnumDefinition[] => {
    const {
        data: { typeDefinitions }
    } = useScriptEditorInfo();

    return Object.keys(typeDefinitions)
        .filter(name => typeDefinitions[name].dataType === 'enum')
        .map(t => typeDefinitions[t] as IEnumDefinition);
};
