import React, { useState } from 'react';
import { IVariableSelector, IFrame, IScript } from '../../shared/motive/models/Script';
import { IDropdownOption, Dropdown, EMPTY_DROPDOWN_OPTION } from '../../core-ui/dropdown';
import { IFrameInspectorDynamicsOnChange } from '../frameInspectorDynamicsContainer';
import { SelectorContainer } from '../selectorContainer';
import { useScriptAndGlobalVariables } from '../../shared/motive/hooks/useScriptAndGlobalVariables';
import { addVariableSelectorToSelectors } from '../../util/ScriptDynamicsUtil';
import { IScriptFrameAction, ScriptActionType } from '../../shared/motive/reducers/ScriptEditorReducers';
import { IObjectDefinition } from '../../shared/motive/models/TypeDefinition';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { getSmartTypeName } from '../../util/ObjectDefinitionsUtils';
import {
    useTypeDefinitions,
    useScriptEditorInfo
} from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { SelectScriptEditorCurrentScript } from '../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';
import { useDispatch, useSelector } from 'react-redux';

export interface IVariableSelectorsContainerProps extends IFrameInspectorDynamicsOnChange {
    selectors: IVariableSelector[] | undefined;
    frame: IFrame;
    script: IScript;
}

export const VariableSelectorsContainer: React.FC<IVariableSelectorsContainerProps> = ({
    subFramePath,
    selectors,
    onChange,
    frame
}) => {
    const scriptDispatch = useDispatch();
    const script = useSelector(SelectScriptEditorCurrentScript);
    const scriptEditorInfo = useScriptEditorInfo();

    const typeDefs = useTypeDefinitions();
    const scriptVariablesMap = useScriptAndGlobalVariables();
    const [choosingSelectorVariableId, setChoosingSelectorVariableId] = useState<string | undefined>(undefined);
    const [showSelectorOptions, setShowSelectorOptions] = useState(false);
    const [selectorOptions, setSelectorOptions] = useState<IDropdownOption[]>([]);

    const variableDropOptions: IDropdownOption[] = frame.definedVariables
        ? frame.definedVariables
              .filter(v => !selectors?.some(s => s.variableReference?.objectId === v.variableId))
              .map(v => {
                  return {
                      label: scriptVariablesMap[v.variableId].name,
                      value: v.variableId
                  };
              })
        : [];

    variableDropOptions.unshift(EMPTY_DROPDOWN_OPTION);

    const hasVariableSelectors = selectors && selectors.length > 0;
    const variablesAvailable = variableDropOptions?.length > 0;

    const handleAddScriptVariableDropChange = (value: string) => {
        setChoosingSelectorVariableId(value);

        if (!value) {
            setShowSelectorOptions(false);

            return;
        }

        const selectedVariable = scriptVariablesMap[value];
        const selectedTypeDef =
            selectedVariable &&
            (scriptEditorInfo.data.typeDefinitions[selectedVariable.valueDefinition.typeName] as IObjectDefinition);

        const selectorTypes = scriptEditorInfo.data.typedSelectors?.[selectedTypeDef.name];

        if (!selectorTypes || selectorTypes?.length === 0) {
            setShowSelectorOptions(false);

            const updatedFrame = addVariableSelectorToSelectors(
                value,
                selectors,
                subFramePath,
                script,
                frame,
                scriptEditorInfo.data.typeDefinitions,
                scriptVariablesMap
            );

            const scriptDispatchAction: IScriptFrameAction = {
                type: ScriptActionType.FRAME_UPDATE,
                frame: updatedFrame
            };
            scriptDispatch(scriptDispatchAction);
        } else {
            const opts: IDropdownOption[] = [
                {
                    value: MotiveTypes.DYNAMIC_VALUE_SELECTOR,
                    label: 'Set a Value'
                },
                ...selectorTypes.map(s => ({
                    label: getSmartTypeName(s.selectorTypeName, typeDefs),
                    value: s.selectorTypeName
                }))
            ];

            setSelectorOptions(opts);

            setShowSelectorOptions(true);

            setChoosingSelectorVariableId(value);
        }
    };

    const handleAddSelectorType = (type: string) => {
        if (choosingSelectorVariableId) {
            const updatedFrame = addVariableSelectorToSelectors(
                choosingSelectorVariableId,
                selectors,
                subFramePath,
                script,
                frame,
                scriptEditorInfo.data.typeDefinitions,
                scriptVariablesMap,
                type
            );

            const scriptDispatchAction: IScriptFrameAction = {
                type: ScriptActionType.FRAME_UPDATE,
                frame: updatedFrame
            };
            scriptDispatch(scriptDispatchAction);
        }

        setShowSelectorOptions(false);
        setChoosingSelectorVariableId(undefined);
    };

    const handleDeleteSelector = (selectorId: string) => {
        const toDeleteSelector = selectors?.find(selector => selector.id === selectorId);
        if (!toDeleteSelector) {
            throw new Error('no selector found');
        }
        const updatedSelectors = selectors?.filter(selector => selector.id !== selectorId);
        onChange(subFramePath, updatedSelectors);
    };

    return (
        <>
            <Dropdown
                instructions={variablesAvailable ? 'Choose a variable' : 'No variables available'}
                value={choosingSelectorVariableId}
                variant={'hollow'}
                onChange={handleAddScriptVariableDropChange}
                grouped={true}
                options={variableDropOptions}
            />
            {showSelectorOptions && selectorOptions && (
                <Dropdown
                    instructions={'Choose a selection mode'}
                    options={selectorOptions}
                    onChange={handleAddSelectorType}
                />
            )}
            {hasVariableSelectors &&
                selectors &&
                selectors
                    .filter(v => v.variableReference?.objectId)
                    .map((variableSelector, index) => (
                        <SelectorContainer
                            frame={frame}
                            script={script}
                            key={variableSelector.id}
                            scriptVariableId={variableSelector.variableReference?.objectId as string}
                            selector={variableSelector}
                            onChange={onChange}
                            subFramePath={`${subFramePath}.${index}`}
                            onDeleteSelector={handleDeleteSelector}
                            showVariableTitle={true}
                        />
                    ))}
        </>
    );
};
