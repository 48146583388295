import React, { useRef, useEffect, useState } from 'react';
import { IScrollZoneProps, ScrollZone } from '../../core-ui/scrollZone/ScrollZone';
import { forceOverflowAutoStyle } from './HeightUsedScrollContainer.style';
import { useDragContext } from '../../hooks/useDragContext';

export interface IHeightUsedScrollContainerProps extends IScrollZoneProps {
    scrollX?: boolean;
    scrollY?: boolean;
}

export const HeightUsedScrollContainer: React.FC<IScrollZoneProps> = props => {
    const style = { height: '100%', paddingRight: '10px', zIndex: 0, ...props.style };
    return <ScrollZone {...props} style={style}></ScrollZone>;
};

export const DraggableHeightUsedScrollContainer: React.FC<IHeightUsedScrollContainerProps> = ({
    children,
    scrollX = false,
    scrollY = true,
    ...propsRest
}) => {
    const { beforeCapture } = useDragContext();

    const [height, setHeight] = useState(0);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        !beforeCapture && ref.current && setHeight(ref.current.clientHeight);
    });

    return (
        <HeightUsedScrollContainer css={forceOverflowAutoStyle(!!scrollX, !!scrollY)} {...propsRest}>
            <div ref={ref} style={beforeCapture && { height: `${height}px` }}>
                {children}
            </div>
        </HeightUsedScrollContainer>
    );
};
