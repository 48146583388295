import { css, keyframes } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';
import chroma from 'chroma-js';
//@ts-ignore
import { tada } from 'react-animations';

export const flexWrapLayoutStyle = (token: IToken) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    & > span {
        max-width: 95%;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: ${token.spacings.smallest};
    }
`;
export const variableObjectWrapperStyle = (token: IToken, isEditing: boolean) => css`
    background: ${isEditing ? `${token.colors.background.light}` : 'white'};
    border-radius: ${token.borderRadii.small};
    padding: 0 ${token.spacings.smallest};
    margin: ${token.spacings.smallest} 0;
    &:hover {
        background: ${chroma(token.colors.background.darker)
            .alpha(0.1)
            .css()};
    }
`;

export const variableObjectWrapperBgStyle = css`
    background: white;
`;

export const animateVariableBgStyle = (token: IToken) => css`
    background: white;
    animation-name: changeBgColor;
    animation-duration: 4s;
    animation-delay: 0s;
    @keyframes changeBgColor {
        from,
        to {
            background-color: white;
        }
        40% {
            background-color: ${token.dynamicsColors.background.neutral};
        }
    }
`;

export const addScriptButtonStyle = (token: IToken) => css`
    width: 100%;
    border-radius: 0;
    margin: 0 -${token.spacings.smaller};
    text-align: center;
    color: ${token.colors.font.primary};
    box-sizing: content-box;
    background-color: ${chroma(token.dynamicsColors.background.neutral)
        .alpha(0.3)
        .css()};
`;
export const optionsEditorStyle = (token: IToken) => css`
    padding: ${token.spacings.smaller} 0;
    & .css-tya3pf-FieldEditorLabel {
        width: 80px;
    }
`;
export const headingStyle = css`
    font-weight: 600;
`;

export const buttonsWrapperStyle = css`
    display: flex;
    flex-flow: row;
`;

export const editButtonStyle = css`
    margin: 5px 3px 0;
`;

export const closeButtonStyle = css`
    margin: 5px 3px 0;
`;

export const textNoOverflowStyle = css`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const headingRenderContainerStyle = css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
export const variableObjectEditorStyle = (Token: IToken) => css`
    margin: 0 -${Token.spacings.small};
`;
export const outerHeadingsAndVarsLayoutStyle = (Token: IToken) => {
    const gridSpace = Token.spacings.smaller;
    return css`
        display: grid;
        grid-gap: ${gridSpace};
        grid-template-rows: 1fr 1fr;
        min-height: 100vh-170px;
        & > div {
            background: ${Token.colors.background.lighter};
            padding: ${gridSpace} ${Token.spacings.smaller};
        }
    `;
};

export const outerHeadingsAndVarsStyle = (Token: IToken) => {
    return css`
        background: ${Token.colors.background.lighter};
    `;
};

export const getAttentionAnimationStyle = css`
    animation: 1s ${keyframes`${tada}`} 1;
    animation-fill-mode: backwards;
`;

export const displayNoneStyle = css`
    display: none;
`;

export const variableOptionsWrapper = (token: IToken) => css`
    position: absolute;
    top: 30px;
    right: ${token.spacings.smaller};
    background: white;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: ${token.spacings.smaller};
    z-index: 100;
    box-shadow: 0 0 2px (0, 0, 0, 0.2);
`;

export const contentWrapper = css`
    width: calc(100% - 14px);
    padding: 4px;
`;

export const inputStickyPositionStyle = ({ colors, spacings }: IToken) => css`
    background-color: ${colors.background.dark};
    padding-left: ${spacings.smallest};
    padding-right: 10px;
    padding-bottom: ${spacings.smallest};
`;

export const inputStyle = css`
    margin: unset;
`;
