/* eslint-disable @typescript-eslint/no-explicit-any */
export const reduceArrayToObject: (state: any, item: any, index: number) => { [key: string]: string } = (
    state: any,
    item: any,
    index: number
) => {
    return {
        ...state,
        [index]: item
    };
};

export const arrayInsert = <T extends any = unknown>(array: T[], item: T, insertIndex: number = array.length) => {
    return [...array.slice(0, insertIndex), item, ...array.slice(insertIndex)];
};

export function formatBytes(a: number, b = 2) {
    if (0 === a) {
        return '0 Bytes';
    }

    const c = 0 > b ? 0 : b,
        d = Math.floor(Math.log(a) / Math.log(1024));

    return (
        parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
        ' ' +
        ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
    );
}
