import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';
import chroma from 'chroma-js';
import { stretchWidth } from '../../constants/HelperStyles';

export const rowItemCatalogTypeStyle = (token: IToken) => css`
    ${rowItemStyle(token)}
    background-color: ${token.colors.bar.catalogBarHeader};
    &:hover {
        filter: unset;
    }
`;

export const catalogTypeTextStyle = (token: IToken) => css`
    color: ${token.colors.staticColors.white};
`;
//Catalog Rows
export const rowItemStyle = ({ colors: { background }, spacings }: IToken) => css`
    margin: 1px 0px;
    padding: ${spacings.smaller};
    display: flex;
    background-color: ${chroma(background.neutral)
        .alpha(0.4)
        .css()};
    flex-direction: column;
`;
export const nameChangeInputStyle = css`
    width: 250px;
`;

export const inlineCreateContentStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & > input {
        width: 90%;
    }
`;

export const rowItemContentFlexStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & > button {
        margin-right: 0px;
    }
`;

export const parentStyle = ({ spacings }: IToken) => css`
    padding: ${spacings.smaller};
`;

export const itemButtonsContainer = css`
    display: flex;
`;

export const iconButtonStyle = (token: IToken) => css`
    background-color: ${token.colors.interactible.tertiary};
`;

export const fillSpaceStyle = css`
    flex: 1;
`;

export const catalogItemContainerStyle = css`
    display: flex;
    align-items: center;
`;

export const titleStyle = css`
    flex: 1;
`;

export const inlineButtonStyle = (token: IToken) => css`
    width: unset;
    color: ${token.colors.interactible.primary};
`;

export const toggleCreateImportContainer = (token: IToken) => css`
    ${stretchWidth}
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${token.fontSizes.medium};
    color: ${token.colors.font.primary};
    padding: ${token.spacings.smaller};
    color: ${token.colors.font.primary};
    font-size: 1rem;
`;
export const newCatButtonContentStyle = css`
    display: flex;
    justify-content: space-between;
`;

export const menuActionLinkStyle = css`
    margin-right: 5px;
`;
export const noCatSpacing = css`
    padding: 10px;
`;
export const toggleCreateImportButton = ({ spacings }: IToken) => css`
    padding: ${spacings.smallest} ${spacings.smaller};
`;

export const contextualItemStyle = css`
    flex-basis: 30%;
    text-align: right;
`;

export const catalogItemTitleStyle = css`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const searchStyle = css`
    width: 96%;
    margin: 2%;
`;
