import { useCurrentSpace } from '../../useCurrentSpace';
import { IScriptObjectModel } from '../../../models/ScriptObjectModel';
import { IHookProvidedCall } from '../../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../../hooks/useNetworkCallForDataSource';
import { ISaveCatalogItemParams } from '../useSaveCatalogItem/useSaveCatalogItem';
import { useDispatch } from 'react-redux';
import { postCatalogItemNetworkCall } from '../../../networking/CatalogItemService';

export const useAddCatalogItem = (): IHookProvidedCall<ISaveCatalogItemParams, IScriptObjectModel | undefined> => {
    const [currentSpace] = useCurrentSpace();
    const catalogDispatch = useDispatch();

    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const postCatalogItem = async (params: ISaveCatalogItemParams): Promise<IScriptObjectModel | undefined> => {
        return await triggerCall(
            postCatalogItemNetworkCall(
                currentSpace,
                params.catalogId,
                params.item,
                getCancelableDispatch(catalogDispatch)
            )
        );
    };

    return {
        execute: postCatalogItem,
        ...rest
    };
};
