import { createSubSelector } from '../spaceKeyedSelectors';
import { createSelector } from 'reselect';

const SelectMediaState = createSubSelector(state => state.media);

export const SelectAllMediaItems = createSelector(SelectMediaState, state => state.mediaItems);

export const SelectMediaStatus = createSelector(SelectMediaState, state => state.status);

export const SelectMediaItem = (id: string) => createSelector(SelectMediaState, state => state.mediaItems[id]);

export const SelectAllUploadingMediaItems = createSelector(SelectMediaState, state => state.uploadingMediaItems);
