import { css } from '@emotion/core';

export const modalStyle = css`
    width: 50%;
    height: 50%;
`;

export const modalCloseButtonStyle = css`
    display: flex;
    justify-content: flex-end;
`;
