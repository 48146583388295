import { IObjectReference } from './ObjectReference';
import { IDynamicValue } from './IDynamicValue';
import { MotiveTypes } from '../../../constants/MotiveTypes';
import { IEnumItemReference } from './TypeDefinition';

export const PRIMITIVE_TYPES_NAME_MAP: Map<MotiveTypes, string> = new Map([
    [MotiveTypes.BOOLEAN, 'Boolean'],
    [MotiveTypes.STRING, 'String'],
    [MotiveTypes.DATE_TIME, 'Date & Time'],
    [MotiveTypes.TIME_SPAN, 'Time Span'],
    [MotiveTypes.FLOAT, 'Number (Float)'],
    [MotiveTypes.INT, 'Number (Int)']
]);

export type ArrayFieldTypes =
    | string[]
    | number[]
    | boolean[]
    | IObjectReference[]
    | IScriptObjectModel[]
    | IScriptObjectDict[]
    | IDynamicValue[];

export type FieldTypes =
    | ArrayFieldTypes
    | string
    | number
    | boolean
    | IEnumItemReference
    | IObjectReference
    | IScriptObjectModel
    | IScriptObjectDict
    | IDynamicValue
    | undefined
    | null;

export interface IScriptObjectModel {
    id: string;
    type: string;
}

export interface IScriptObjectDict {
    [key: string]: FieldTypes;
}

export const getObjectDict = (obj: IScriptObjectModel | undefined): IScriptObjectDict => {
    return (obj as unknown) as IScriptObjectDict;
};
