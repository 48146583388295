import { ISpace } from '../../shared/motive/models/Space';

export const spaceAppendAction = (space: ISpace) => {
    return {
        type: `space/append`,
        space
    } as const;
};

export const spaceDeleteAction = (id: string) => {
    return {
        type: `space/delete`,
        id
    } as const;
};

export const spaceSetAction = (spaces: ISpace[]) => {
    return {
        type: `space/set`,
        spaces
    } as const;
};

export const spaceClearAction = () => {
    return {
        type: `space/clear`
    } as const;
};

export const spaceUpdateAction = (id: string, space: ISpace) => {
    return {
        type: `space/update`,
        id,
        space
    } as const;
};

export type SpaceActionTypes = ReturnType<
    | typeof spaceClearAction
    | typeof spaceSetAction
    | typeof spaceUpdateAction
    | typeof spaceAppendAction
    | typeof spaceDeleteAction
>;
