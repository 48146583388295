import React, { useReducer } from 'react';
import {
    ChooseMediaSourceContextStateProvider,
    ChooseMediaSourceContextDispatchProvider
} from '../../hooks/useChooseMediaSource/UseChooseMediaSource';
import { ChooseMediaSourceReducer } from '../../shared/motive/reducers/ChooseMediaSourceReducer';

export const ChooseMediaSourceContextContainer: React.FC = ({ children }) => {
    const [chooseMediaItemState, chooseMediaItemDispatch] = useReducer(ChooseMediaSourceReducer, {
        state: 'off'
    });

    return (
        <ChooseMediaSourceContextStateProvider value={chooseMediaItemState}>
            <ChooseMediaSourceContextDispatchProvider value={chooseMediaItemDispatch}>
                {children}
            </ChooseMediaSourceContextDispatchProvider>
        </ChooseMediaSourceContextStateProvider>
    );
};
