import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const baseCheckboxStyle = (token: IToken) => css`
    outline: 2px solid ${token.colors.border.primary};
    outline-offset: -2px;
    padding: 1px;
    cursor: pointer;
    background-color: white;
`;

export const baseLabelStyle = css`
    display: inline;
    margin: 0em 0.5em 0em 0.5em;
`;
