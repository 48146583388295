import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const timeSpanWrapperStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const timeSpanSignStyle = (token: IToken) => css`
    width: 20%;
    font-size: 1.1em;
    font-weight: 600;
    background: ${token.colors.bar.resourceBarHeader};
`;
