// istanbul ignore file MOTIVE_EXCEPTION
import React, { useState, FormEvent, useEffect } from 'react';
import {
    outerLayoutStyle,
    tabStyle,
    inspectorBodyStyle,
    tabListStyle,
    documentAndToolsLayoutStyle
} from './FrameInspectorContainer.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { FrameInspectorHeader } from '../../components/frameInspectorHeader';
import { FrameInspectorBody } from '../../components/frameInspectorBody';
import { Tabs, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/core';
import { defaultMainTabsProps, defaultTabProps } from '../../core-ui/tabs/tabs';
import { Icon } from '../../core-ui/icon';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { ScriptActionType, dispatchUpdateFrame } from '../../shared/motive/reducers/ScriptEditorReducers';
import { defaultTabListStyle, defaultTabPanelsStyle, defaultTabPanelStyle, defaultTabStyle } from '../../core-ui/tabs';
import { FrameInspectorDynamicsContainer } from '../frameInspectorDynamicsContainer';
import { FrameToolsContainer, FRAME_TOOLS_TAB_INDICES } from '../frameToolsContainer';
import { IFrame } from '../../shared/motive/models/Script';
import { useDispatch } from 'react-redux';
import { IScriptEditorSessionDependentProps } from '../../components/scriptEditor/ScriptLoader';
import { useSetFrameToolsTab } from '../../hooks/useFrameTools';

export type FrameInspectTabTypes = 'resources' | 'dynamics';

export const FrameInspectorContainer: React.FC<IScriptEditorSessionDependentProps> = function FrameInspectorContainer({
    selectedFrame: frame,
    script
}): React.ReactElement {
    const tokens = useStyle();
    const scriptDispatch = useDispatch();
    const [frameElementId, setFrameElementId] = useState<string>('');

    const [, setTabIndex] = useState<number | FormEvent>(0);

    const handleFrameRename = (oldName: string, newName: string) => {
        scriptDispatch({ targetFrameId: frame?.id, newName: newName, type: ScriptActionType.FRAME_RENAME });
    };

    const handleFrameToggleEnable = (isEnabled: boolean) => {
        if (!frame) {
            throw new Error('frame not found');
        }

        const newFrame: IFrame = {
            ...frame,
            isEnabled
        };

        dispatchUpdateFrame(scriptDispatch, newFrame);
    };

    const handleFrameToggleExclusive = (isExclusive: boolean) => {
        if (!frame) {
            throw new Error('frame not found');
        }

        const newFrame: IFrame = {
            ...frame,
            isExclusive
        };

        dispatchUpdateFrame(scriptDispatch, newFrame);
    };

    const handleFrameToggleLive = (isLive: boolean) => {
        if (!frame) {
            throw new Error('frame not found');
        }

        const newFrame: IFrame = {
            ...frame,
            isLive: isLive
        };

        dispatchUpdateFrame(scriptDispatch, newFrame);
    };

    const handleFrameToggleResetOnClose = (resetOnClose: boolean) => {
        if (!frame) {
            throw new Error('frame not found');
        }

        const newFrame: IFrame = {
            ...frame,
            resetOnClose: resetOnClose
        };

        dispatchUpdateFrame(scriptDispatch, newFrame);
    };

    const setFrameToolsTab = useSetFrameToolsTab();

    const handleShowVariableOrigin = (id: string) => {
        if (frameElementId !== id) {
            setFrameToolsTab(FRAME_TOOLS_TAB_INDICES.variables, false);
            setFrameElementId(id);
        }
    };

    const handleShowEventOrigin = (id: string) => {
        if (frameElementId !== id) {
            setFrameToolsTab(FRAME_TOOLS_TAB_INDICES.events, false);
            setFrameElementId(id);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => setFrameElementId(''), 4000);
        return () => clearTimeout(timer);
    }, [frameElementId]);

    const frameTabStyle = [defaultTabStyle(tokens), tabStyle];

    return (
        <>
            {frame && (
                <>
                    <Tabs
                        {...defaultMainTabsProps}
                        css={outerLayoutStyle(tokens)}
                        onChange={(index: number | FormEvent) => setTabIndex(index)}
                    >
                        <FrameInspectorHeader
                            frame={frame}
                            onChangeName={handleFrameRename}
                            onChangeEnabled={handleFrameToggleEnable}
                            onChangeExclusive={handleFrameToggleExclusive}
                            onChangeLive={handleFrameToggleLive}
                            onChangeResetOnClose={handleFrameToggleResetOnClose}
                        >
                            <TabList css={[defaultTabListStyle, tabListStyle]}>
                                <Tab css={frameTabStyle} {...defaultTabProps}>
                                    <Icon icon={IconTypes.FILM} />
                                </Tab>
                                <Tab css={frameTabStyle} {...defaultTabProps}>
                                    <Icon icon={IconTypes.ATOM} />
                                </Tab>
                            </TabList>
                        </FrameInspectorHeader>
                        <div css={documentAndToolsLayoutStyle}>
                            <TabPanels css={defaultTabPanelsStyle}>
                                <TabPanel css={defaultTabPanelStyle}>
                                    <FrameInspectorBody
                                        css={inspectorBodyStyle}
                                        frame={frame}
                                        script={script}
                                        onClickSetVariableId={handleShowVariableOrigin}
                                        onClickSetEventId={handleShowEventOrigin}
                                    />
                                </TabPanel>
                                <TabPanel css={defaultTabPanelStyle}>
                                    <FrameInspectorDynamicsContainer
                                        frame={frame}
                                        script={script}
                                        onClickSetVariableId={handleShowVariableOrigin}
                                    />
                                </TabPanel>
                            </TabPanels>
                            <FrameToolsContainer frameElementId={frameElementId} />
                        </div>
                    </Tabs>
                </>
            )}
        </>
    );
};
