import { get } from 'lodash-es';
import * as React from 'react';
import { useState, useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useNavbarItem, useNavbarUpdateTab } from '../../redux/navbar/NavbarHooks';
import { INavBarItem } from '../../redux/navbar/NavbarReducer';
import {
    SelectScriptEditorCurrentScript,
    SelectScriptEditorSelectedFrameId
} from '../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';
import { useCreateScriptObject } from '../../shared/motive/hooks/useCreateScriptObject';
import { useSaveScript } from '../../shared/motive/hooks/useSaveScript/useSaveScript';
import { useScript } from '../../shared/motive/hooks/useScript';
import { IFrame, IScript } from '../../shared/motive/models/Script';
import { ScriptActionType } from '../../shared/motive/reducers/ScriptEditorReducers';
import { DefaultScript } from '../../shared/motive/reducers/ScriptReducer';
import { uniqueMotiveId } from '../../util/MotiveUtils';
import { findFramePath } from '../../util/ScriptUtils';

export const EditorCssFix: React.FC = ({ children }) => {
    useEffect(() => {
        const originalOverflowYValue = document.body.style.overflowY;
        document.body.style.overflowY = 'hidden';

        return () => {
            document.body.style.overflowY = originalOverflowYValue;
        };
    }, []);

    return <>{children}</>;
};

export const useScriptEditorActions = () => {
    const dispatch = useDispatch();
    const { execute: saveScript, isLoading, error } = useSaveScript();

    const handleScriptRename = (changedValue: string) => {
        dispatch({
            type: ScriptActionType.SCRIPT_RENAME,
            newName: changedValue
        });
    };

    const handleSave = async (script: IScript, overwrite: boolean = false) => {
        if (!script) {
            throw new Error('Can not save without a script.');
        }
        await saveScript({ script, overwrite: overwrite });
    };

    return {
        save: handleSave,
        rename: handleScriptRename,
        isSaving: isLoading,
        error
    };
};

export const useScriptTitleAsTabTitle = (script: IScript) => {
    const scriptTabItem = useNavbarItem(script.id);
    const updateTabItem = useNavbarUpdateTab();

    useEffect(() => {
        if (scriptTabItem) {
            updateTabItem({
                ...scriptTabItem,
                title: script.name
            });
        }
    }, [script.id, script.name, !!scriptTabItem]);
};

const DEFAULT_EMPTY_ID = '_NONE';

const GetInitialScriptState: (
    script: IScript | undefined,
    createScriptObject: (type: MotiveTypes.FRAME) => IFrame
) => IScript | undefined = (script, createScriptObject) => {
    if (!script) {
        return undefined;
    }

    let initScript = { ...script };

    if (!initScript.rootFrame) {
        initScript = {
            ...script,
            rootFrame: { ...DefaultScript.scriptData.rootFrame, id: uniqueMotiveId() }
        };
    }

    if (!initScript.rootFrame.subFrames || !initScript.rootFrame.subFrames[0]) {
        initScript = {
            ...script,
            rootFrame: { ...initScript.rootFrame }
        };

        const subFrame = createScriptObject(MotiveTypes.FRAME);

        subFrame.isEnabled = true;
        subFrame.name = 'New Frame';

        initScript.rootFrame.subFrames = [subFrame];
    }

    return initScript;
};

export const useResetScriptOnDismount = (scriptId: string) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch({ type: ScriptActionType.RESET_STATE });
        };
    }, [scriptId]);
};

export const useEditScript = (scriptId: string) => {
    const { data: pristineScript, isLoading: loadingScript } = useScript(scriptId);

    const createScriptObject = useCreateScriptObject();

    const [workingScript, setWorkingScript] = useState(GetInitialScriptState(pristineScript, createScriptObject));

    const dispatch = useDispatch();

    const script = useSelector(SelectScriptEditorCurrentScript) ?? { id: DEFAULT_EMPTY_ID };
    const lastSelectedFrameId = useSelector(SelectScriptEditorSelectedFrameId);

    const defaultScriptId = get(
        script,
        ['rootFrame', 'subFrames', 0, 'id'],
        get(script, ['rootFrame', 'id'], DEFAULT_EMPTY_ID)
    );

    const [lastSelectedFramePathMap, setLastSelectedFramePath] = useState<{ [key: string]: string }>({
        [script.id]: defaultScriptId
    });

    useEffect(() => {
        if (script) {
            const path = findFramePath(script, lastSelectedFrameId);
            path && setLastSelectedFramePath({ ...lastSelectedFramePathMap, [script.id]: path });
        }
    }, [lastSelectedFrameId, script]);

    useLayoutEffect(() => {
        if (!pristineScript) {
            return undefined;
        }

        if (!workingScript) {
            const editCopy = GetInitialScriptState(pristineScript, createScriptObject);
            setWorkingScript(editCopy);
            dispatch({
                type: ScriptActionType.SET_SCRIPT,
                script: editCopy
            });
        } else if (
            workingScript.lastModifiedTime !== pristineScript.lastModifiedTime ||
            workingScript.id !== pristineScript.id
        ) {
            setWorkingScript(GetInitialScriptState(pristineScript, createScriptObject));
        }
    }, [loadingScript, pristineScript]);

    useLayoutEffect(() => {
        if (workingScript) {
            const lastFrameId = workingScript
                ? (get(workingScript, lastSelectedFramePathMap[workingScript.id]) as IFrame)?.id
                : undefined;

            dispatch({
                type: ScriptActionType.SET_SCRIPT,
                script: workingScript,
                targetFrameId: lastFrameId
            });
        }
    }, [!!workingScript, workingScript?.lastModifiedTime, workingScript?.id]);
};
