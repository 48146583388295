import React from 'react';
import {
    homeIconStyle,
    breadcrumbItemStyle,
    breadcrumbNameStyle,
    breadCrumbWrapperStyle,
    breadCrumbPanelStyle
} from './Breadcrumbs.style';
import { Icon } from '../../core-ui/icon/Icon';
import { IconTypes } from '../constants/IconTypes';

export interface IBreadCrumb {
    name: string | React.ReactNode;
    onClick?: () => void;
}

/**
 * Renders breadcrumbs from everything added to the Breadcrumb Provider
 * Use the useAddBreadcrumb hook in a page component to populate this component with more values
 */

interface IBreadCrumbProps {
    crumbs: IBreadCrumb[];
}

export const Breadcrumbs: React.FC<IBreadCrumbProps> = ({ crumbs }) => {
    return (
        <div css={breadCrumbPanelStyle}>
            <div css={breadCrumbWrapperStyle}>
                {crumbs.map((c, idx) => (
                    <span
                        css={() => breadcrumbItemStyle(idx === 0, idx + 1 === crumbs.length)}
                        onClick={c.onClick}
                        key={idx}
                    >
                        <span css={breadCrumbWrapperStyle}>
                            {idx === 0 && <Icon css={c.name && homeIconStyle} icon={IconTypes.HOME}></Icon>}
                            <span css={breadcrumbNameStyle}>{c.name}</span>
                        </span>
                    </span>
                ))}
            </div>
        </div>
    );
};
