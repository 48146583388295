import { css } from '@emotion/core';
import { FieldLayoutHint } from './ObjectEditor';
import { IToken } from '../../shared/contexts/styleContext';
import chroma from 'chroma-js';

export const fieldLayoutStyle = (layoutHint?: FieldLayoutHint) => css`
    display: flex;
    flex-direction: ${layoutHint === 'inline' ? 'row' : 'column'};
    align-items: ${layoutHint === 'inline' ? 'center' : 'left'};
    ${layoutHint === 'inline' && 'flex-wrap: wrap;'}
`;

export const fieldRowStyle = (token: IToken, isVariable: boolean) => css`
    ${isVariable &&
        `background-color:${chroma(token.dynamicsColors.background.neutral)
            .alpha(0.2)
            .css()};`};
    margin: 0 -${token.spacings.smaller};
    padding: ${token.spacings.smallest} ${token.spacings.smaller};
`;
