import React from 'react';
import { IFrame, IScript, IResourceWrapper } from '../../shared/motive/models/Script';
import { ResourceWrapperContainer } from '../../containers/ResourceWrapperContainer';
import { Text } from '../../core-ui/text';
import { useStyle } from '../../shared/hooks/useStyle';
import { resourcesWrapperStyle, sharedStyleBodyPanel, emptyResourceText } from './FrameInspectorResources.style';
import { IResourceReference } from '../../contexts/objectReferenceContext';
import { DroppableScriptEventContainer } from '../../containers/droppableScriptEventContainer';
import { IBaseDrag, Drop } from '../_common/DragAndDrop/Drop/Drop';
import { ScriptActionType } from '../../shared/motive/reducers/ScriptEditorReducers';
import { LargeDrag } from '../_common/DragAndDrop/LargeDrag/LargeDrag';
import { useDispatch } from 'react-redux';
import { DRAG_ITEM_TYPE_RESOURCE } from '../../constants/DragAndDrop';
import { Trans, useTranslation } from 'react-i18next';

export interface IFrameInspectorResourcesProps {
    script: IScript;
    frame: IFrame;
    resourceReferences: IResourceReference;
    onClickSetEventId: (eventId: string) => void;
}

export interface IResourceDrag extends IBaseDrag {
    objectId: string;
    objectType: string;
    sourceIndex: number;
}

export const FrameInspectorResources = React.memo(function FrameInspectorResources(
    props: IFrameInspectorResourcesProps
) {
    const { script, frame, onClickSetEventId } = props;

    const scriptDispatch = useDispatch();

    const onResourceDrop = (data: IResourceDrag, index: number) => {
        if (index !== data.sourceIndex) {
            scriptDispatch({
                type: ScriptActionType.RESOURCE_MOVE,
                destinationIndex: index,
                sourceIndex: data.sourceIndex,
                resourceId: data.objectId
            });
        }
    };

    return (
        <div>
            <DroppableScriptEventContainer frameId={frame.id}>
                <Drop
                    dropId={`droppable:move_resources`}
                    showPlaceHolder={true}
                    allowedDragList={[{ dragType: DRAG_ITEM_TYPE_RESOURCE, onDragEnd: onResourceDrop }]}
                >
                    <div css={[resourcesWrapperStyle, sharedStyleBodyPanel]}>
                        {frame.resources && frame.resources.length !== 0 ? (
                            frame.resources.map(
                                (resourceWrapper: IResourceWrapper, resourceIndex: number): React.ReactElement => {
                                    return (
                                        <div
                                            key={resourceWrapper.resource.id}
                                            ref={props.resourceReferences[resourceWrapper.resource.id]}
                                        >
                                            <LargeDrag<IResourceDrag>
                                                drag={{
                                                    dragType: DRAG_ITEM_TYPE_RESOURCE,
                                                    objectType: resourceWrapper.resource.type,
                                                    objectId: resourceWrapper.resource.id,
                                                    sourceIndex: resourceIndex
                                                }}
                                                index={resourceIndex}
                                            >
                                                {handle => (
                                                    <ResourceWrapperContainer
                                                        script={script}
                                                        frame={frame}
                                                        resourceWrapper={resourceWrapper}
                                                        index={resourceIndex}
                                                        dragHandleProps={handle}
                                                        onClickSetEventId={onClickSetEventId}
                                                    />
                                                )}
                                            </LargeDrag>{' '}
                                        </div>
                                    );
                                }
                            )
                        ) : (
                            <EmptyResourcesList />
                        )}
                    </div>
                </Drop>
            </DroppableScriptEventContainer>
        </div>
    );
});

const EmptyResourcesList: React.FC = (): React.ReactElement => {
    const token = useStyle();
    const { t } = useTranslation();
    return (
        <>
            <Text css={emptyResourceText(token)}>
                <Trans i18nKey="dragResourcesText">
                    Drag resources from the right side into this area to edit them. These resources will be activated by
                    your game or app when the pre-condition for this frame is met.
                </Trans>
            </Text>
        </>
    );
};
