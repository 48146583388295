import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const providerIconWrapperStyle = (token: IToken) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const providerIconStyle = (token: IToken, isItemSelected: boolean, isSelected?: boolean) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 200px;
    border: 2px solid ${token.colors.border.primary};
    border-radius: 6px;
    background-color: ${token.colors.background.lighter};
    cursor: pointer;
    ${isSelected && `box-shadow: 0px 0px 5px 0px rgba(61,132,255,0.75);`}
    ${isItemSelected && !isSelected && `opacity: 0.5;`}
    margin: 24px;
    transition-duration: 250ms;
`;

export const iconStyle = (token: IToken) => css`
    margin: ${token.spacings.medium};
    color: ${token.colors.interactible.tertiary};
`;

export const buttonWrapperStyle = css`
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
`;

export const previousButtonStyle = (token: IToken) => css`
    background-color: ${token.colors.interactible.tertiary};
`;

export const formSpacing = css`
    margin-top: 12px;
`;

export const loadingWrapperStyle = css`
    overflow: hidden;
`;
