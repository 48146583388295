/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useUploadMediaItem } from '../../shared/motive/hooks/useUploadMediaItem';
import { Logger } from '../../util/logger';
import { MediaUploadZone } from '../../components/mediaUploadZone/MediaUploadZone';
import { useSelector } from 'react-redux';
import { IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { SelectAllUploadingMediaItems } from '../../redux/spaceKeyed/media/MediaSelectors';

interface IMediaUploadContainerProps {
    onUpload?: (media: IHostedMediaSource) => void;
}

export const MediaUploadContainer: React.FC<IMediaUploadContainerProps> = ({ onUpload }) => {
    const { execute: uploadMediaItem } = useUploadMediaItem(true);

    const handleFileUpload = (acceptedFiles: any, overwrite: boolean = false, oldId?: string) => {
        acceptedFiles.forEach((file: any) => {
            const reader = new FileReader();

            reader.onabort = () => Logger.debug('File reading was aborted');
            reader.onerror = () => Logger.debug('File reading has failed');
            reader.onload = async () => {
                var res = await uploadMediaItem({ file, overwrite, oldAttemptId: oldId });

                if (!!res && onUpload) {
                    onUpload(res);
                }
            };

            reader.readAsArrayBuffer(file);
        });
    };

    const uploadingItems = Object.values(useSelector(SelectAllUploadingMediaItems));

    return <MediaUploadZone onFileUpload={handleFileUpload} uploadingItems={uploadingItems}></MediaUploadZone>;
};
