import React from 'react';
import { ICatalogInfo } from '../../shared/motive/models/Catalog';
import { Checkbox } from '../../core-ui/checkbox';
import { Text } from '../../core-ui/text';
import { importerText, importerItem } from './CatalogImporter.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { IToken } from '../../shared/contexts/styleContext';

interface ICatalogImporterProps {
    availableCatalogs: ICatalogInfo[];
    addCatalog: (catalogId: string) => void;
    removeCatalog: (catalogId: string) => void;
    selectedCatalogs: string[];
}

const renderCatalogItem = (
    catalog: ICatalogInfo,
    selectedCatalogs: string[],
    onItemSelected: (id: string) => void,
    onItemUnselected: (id: string) => void,
    tokens: IToken
) => {
    const isSelected = selectedCatalogs.find(c => c === catalog.id);

    return (
        <div css={importerItem(tokens)} key={catalog.id}>
            <Checkbox
                isChecked={!!isSelected}
                onClick={() => (isSelected ? onItemUnselected(catalog.id) : onItemSelected(catalog.id))}
            ></Checkbox>
            <Text css={importerText(tokens)}>{catalog.title}</Text>
        </div>
    );
};

export const CatalogImporter: React.FC<ICatalogImporterProps> = ({
    availableCatalogs,
    addCatalog,
    removeCatalog,
    selectedCatalogs
}) => {
    const tokens = useStyle();

    return (
        <>{availableCatalogs.map(cat => renderCatalogItem(cat, selectedCatalogs, addCatalog, removeCatalog, tokens))}</>
    );
};
