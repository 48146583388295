import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const emptyBox = (tokens: IToken, yPos: number, hasAction: boolean) => css`
    width: 50%;
    background-color: ${tokens.colors.background.lighter};
    border: 1px dashed lightgray;
    margin: auto;
    position: relative;
    top: ${yPos}%;
    transform: translateY(-${yPos}%);
    padding: ${tokens.spacings.medium};
    display: flex;
    align-items: center;
    text-align: center;

    ${hasAction &&
        `cursor: pointer;

    &: hover {
        filter: brightness(90%);
    }`}
`;

export const emptyMessageIconStyle = (token: IToken) => css`
    color: ${token.colors.background.lighter};
    margin-top: 64px;
    margin-bottom: 32px;
    transform: scale(7);
`;

export const emptyMessageWithIconTextStyle = (token: IToken) => css`
    color: ${token.colors.background.lighter};
    margin-bottom: 32px;
    font-size: 1.2em;
`;

export const messageStyle = (tokens: IToken) => css`
    font-size: ${tokens.fontSizes.medium};
    color: ${tokens.colors.font.secondary};
    width: 100%;
`;
