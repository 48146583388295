import { IBundleAction } from './BundleActions';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { Reducer } from 'redux';
import { IBundle } from '../../../shared/motive/models/Bundle';
import { IStatus } from '../../Status';

export interface IBundleEntity {
    bundle: IBundle;
    status: IStatus;
}

export interface IBundleState {
    bundleItems: Record<string, IBundleEntity>;
    status: IStatus;
    restoreStatus: IStatus;
}

export const defaultBundle: IBundleState = {
    bundleItems: {},
    status: {
        loadingState: 'initial'
    },
    restoreStatus: {
        loadingState: 'initial'
    }
};

const populateBundleItems = (items: IBundle[]) => {
    const bundleMap: Record<string, IBundleEntity> = {};

    items.forEach(i => {
        //backward compatibility for bundles that had no key embedded before.
        if (!i.key) {
            const segments = new URL(i.url).pathname.split('/');
            i.key = segments[segments.length - 1];
        }

        bundleMap[i.key] = {
            bundle: i,
            status: {
                loadingState: 'resolved'
            }
        };
    });

    return bundleMap;
};

export const BundleReducer: Reducer<IBundleState, IBundleAction> = produce(
    (state: IBundleState, action: IBundleAction) => {
        switch (action.type) {
            case 'bundle/set':
                state.bundleItems = populateBundleItems(action.bundles);
                state.status = {
                    loadingState: 'resolved',
                    error: undefined
                };
                break;
            case 'bundle/error':
                state.status = {
                    loadingState: 'resolved',
                    error: action.error
                };
                break;
            case 'bundle/load':
                state.status = {
                    loadingState: 'loading',
                    error: undefined
                };
                break;
            case 'bundle/itemLoad':
                state.bundleItems[action.id].status = {
                    loadingState: 'loading',
                    error: undefined
                };
                break;
            case 'bundle/itemDelete':
                delete state.bundleItems[action.id];
                break;
            case 'bundle/itemError':
                state.bundleItems[action.id].status = {
                    loadingState: 'resolved',
                    error: action.error
                };
                break;
            case 'bundle/itemAdd':
                state.bundleItems[action.bundle.key] = {
                    bundle: action.bundle,
                    status: {
                        loadingState: action.loading,
                        error: undefined
                    }
                };
                break;
            case 'bundle/restoreBegin':
                state.restoreStatus = {
                    loadingState: 'loading'
                };
                break;
            case 'bundle/restoreEnd':
                state.restoreStatus = {
                    loadingState: 'resolved',
                    error: action.error
                };
                break;
        }
    },
    defaultBundle
);

export const useBundleDispatch: () => React.Dispatch<IBundleAction> = () => {
    return useDispatch() as React.Dispatch<IBundleAction>;
};
