import React, { useState } from 'react';
import { IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { MediaItemPreview } from '../mediaItemPreview';
import { Text } from '../../core-ui/text';
import { Divider } from '../../core-ui/divider';
import {
    detailedViewGridStyle,
    mediaItemPreviewStyle,
    detailedViewMetadataWrapper,
    infoLabelStyle,
    infoTextStyle,
    infoWrapperStyle
} from './MediaSourceDetailedView.style';
import { prettierDate } from '../../util/DateUtils';
import { useStyle } from '../../shared/hooks/useStyle';
import { formatBytes } from '../../util/Utils';
import { getSmartMediaSourceName } from '../../util/ObjectDefinitionsUtils';
import { ButtonVariant, Button } from '../../core-ui/button';
import { DeleteModal } from '../../core-ui/deleteModal/DeleteModal';
import { useDeleteMediaItem } from '../../shared/motive/hooks/useDeleteMediaItem';
import { MediaSourceConflictModal } from '../../containers/mediaSourceConflictModal/MediaSourceConflictModal';
import { getErrorStatusCode } from '../../shared/motive/networking/HttpClient';
import { useTranslation } from 'react-i18next';

interface IMediaSourceDetailedViewProps {
    source?: IHostedMediaSource;
    onClose: () => void;
}

export const MediaSourceDetailedView: React.FC<IMediaSourceDetailedViewProps> = ({
    source,
    onClose
}): React.ReactElement => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isConflict, setIsConflict] = useState(false);
    const { execute: deleteItem } = useDeleteMediaItem(true);

    const token = useStyle();
    const { t } = useTranslation();

    const handleDeleteItem = async (force: boolean = false) => {
        setShowDeleteModal(false);

        if (source?.id) {
            try {
                await deleteItem({ id: source.id, force });
            } catch (e) {
                const status = getErrorStatusCode(e);

                if (status === 409) {
                    setIsConflict(true);

                    return;
                }
            }
        }

        onClose?.();
    };

    if (!source) {
        return <></>;
    }

    const handleOverwrite = async () => {
        setIsConflict(false);

        await handleDeleteItem(true);
    };

    return (
        <>
            <MediaSourceConflictModal
                isOpen={isConflict}
                onOverwrite={handleOverwrite}
                onCloseModal={() => setIsConflict(false)}
            ></MediaSourceConflictModal>
            <DeleteModal
                isOpen={showDeleteModal}
                onConfirm={() => handleDeleteItem()}
                onCloseModal={() => setShowDeleteModal(false)}
            >
                {t('confirmFileDelete')}
            </DeleteModal>
            <div css={detailedViewGridStyle}>
                <MediaItemPreview css={mediaItemPreviewStyle} mediaItem={source} withLabel={false} />
                <div css={detailedViewMetadataWrapper}>
                    <div>
                        <Divider />
                    </div>
                    <div css={infoWrapperStyle(token)}>
                        <Text bold css={infoLabelStyle(token)}>
                            {t('fileName')}:
                        </Text>
                        <Text css={infoTextStyle(token)}>{getSmartMediaSourceName(source)}</Text>
                    </div>
                    <div css={infoWrapperStyle(token)}>
                        <Text bold css={infoLabelStyle(token)}>
                            {t('fileType')}:
                        </Text>
                        <Text css={infoTextStyle(token)}>{source.contentType}</Text>
                    </div>
                    <div css={infoWrapperStyle(token)}>
                        <Text bold css={infoLabelStyle(token)}>
                            {t('lastModifiedDate')}:
                        </Text>
                        <Text css={infoTextStyle(token)}>{prettierDate(source.lastModified)}</Text>
                    </div>
                    <div css={infoWrapperStyle(token)}>
                        <Text bold css={infoLabelStyle(token)}>
                            {t('fileSize')}:
                        </Text>
                        <Text css={infoTextStyle(token)}>{formatBytes(source.contentLength)}</Text>
                    </div>
                    {/* <div css={infoWrapperStyle(token)}>
                <Text bold css={infoLabelStyle(token)}>
                    {`URL:`}
                </Text>
                <Text css={infoTextStyle(token)}>
                    {source.url}
                </Text>
            </div> */}
                    <Button onClick={() => setShowDeleteModal(true)} variant={ButtonVariant.SOLID}>
                        {t('delete')}
                    </Button>
                </div>
            </div>
        </>
    );
};
