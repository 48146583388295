// istanbul ignore file MOTIVE_EXCEPTION
import React, { memo } from 'react';
import { ResourcePanelContainer } from '../drawerResourcesTabContainer';
import { useStyle } from '../../shared/hooks/useStyle';
import { frameToolsContainerStyle, tabListStyle, tabMinWidthStyle, tabPanelsStyle } from './FrameToolsContainer.style';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/core';
import { ConditionsPanelContainer } from '../drawerConditionsTabContainer';
import { defaultTabListStyle, defaultTabPanelsStyle, defaultTabPanelStyle, defaultTabStyle } from '../../core-ui/tabs';
import { defaultTabProps, defaultMainTabsProps } from '../../core-ui/tabs/tabs';
import { ScriptVariablesOptionsContainer } from '../scriptVariablesOptionsContainer';
import { ScriptEventDefinitionsContainer } from '../scriptEventDefinitionsContainer/ScriptEventDefinitionsContainer';
import { useFrameToolsSelectedTab, useSetFrameToolsTab } from '../../hooks/useFrameTools';
import { useTranslation } from 'react-i18next';

export const FRAME_TOOLS_TAB_INDICES = {
    resources: 0,
    conditons: 1,
    variables: 2,
    events: 3
};

export type FrameToolsTab = keyof typeof FRAME_TOOLS_TAB_INDICES;

export interface IFrameElement {
    frameElementId?: string;
}
export const FrameToolsContainer: React.FC<IFrameElement> = memo(function FrameToolsContainer(props: IFrameElement) {
    const tokens = useStyle();
    const selectedTab = useFrameToolsSelectedTab();
    const setTab = useSetFrameToolsTab();
    const { t } = useTranslation();

    const tabStyle = [defaultTabStyle(tokens), tabMinWidthStyle(tokens)];

    return (
        <Tabs
            isFitted
            {...defaultMainTabsProps}
            css={frameToolsContainerStyle(tokens)}
            index={selectedTab.tabIndex}
            onChange={setTab}
        >
            <TabList css={[defaultTabListStyle, tabListStyle]}>
                <Tab {...defaultTabProps} css={tabStyle}>
                    {t('resources')}
                </Tab>
                <Tab {...defaultTabProps} css={tabStyle}>
                    {t('conditions')}
                </Tab>
                <Tab {...defaultTabProps} css={tabStyle}>
                    {t('variables')}
                </Tab>
                <Tab {...defaultTabProps} css={tabStyle}>
                    {t('events')}
                </Tab>
            </TabList>
            <TabPanels css={[defaultTabPanelsStyle, tabPanelsStyle(tokens)]}>
                <TabPanel css={defaultTabPanelStyle}>
                    {selectedTab.tabIndex === FRAME_TOOLS_TAB_INDICES.resources && <ResourcePanelContainer />}
                </TabPanel>
                <TabPanel css={defaultTabPanelStyle}>
                    {selectedTab.tabIndex === FRAME_TOOLS_TAB_INDICES.conditons && <ConditionsPanelContainer />}
                </TabPanel>
                <TabPanel css={defaultTabPanelStyle}>
                    {selectedTab.tabIndex === FRAME_TOOLS_TAB_INDICES.variables && (
                        <ScriptVariablesOptionsContainer variableId={props.frameElementId} />
                    )}
                </TabPanel>
                <TabPanel css={defaultTabPanelStyle}>
                    {selectedTab.tabIndex === FRAME_TOOLS_TAB_INDICES.events && (
                        <ScriptEventDefinitionsContainer eventId={props.frameElementId} />
                    )}
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
});
