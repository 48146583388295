import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const activityText = (token: IToken) => css`
    color: ${token.colors.font.secondary};
    font-size: ${token.fontSizes.smaller};
    display: block;
    margin-top: ${token.spacings.smaller};
`;

export const activityAction = (token: IToken, color: string) => css`
    ${activityActionText(token)}
    background-color: ${color};
    padding: 0 ${token.spacings.smallest};
    border-radius: ${token.borderRadii.small};
`;
export const activityActionText = (token: IToken) => css`
    color: #464a50;
    font-size: ${token.fontSizes.small};
`;

export const activityItemWrapper = (token: IToken) => css`
    display: flex;
    padding: ${token.spacings.smaller} 0;
`;

export const activityAvatar = css`
    align-self: flex-start;
    margin-right: 8px;
    flex: 0 0 25px;
`;

export const activityContent = css`
    flex-flow: column;
`;
