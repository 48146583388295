import { errorHelperTextStyle, FormControl } from '../../core-ui/formControl';
import { Field, Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { Size } from '../../core-ui/constants/Size';
import { Heading } from '../../core-ui/heading';
import { InputField } from '../../core-ui/inputField';
import { Button, ButtonVariant } from '../../core-ui/button';
import { buttonWrapperStyle } from './CreateIntentCatalog.style';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { rotating } from '../../constants/AnimationStyles';
import { Divider } from '../../core-ui/divider';
import { useStyle } from '../../shared/hooks/useStyle';
import { useTranslation } from 'react-i18next';

interface ICreateIntentCatalogProps {
    onCatalogCreate: (catalogTitle: string) => void;
    isLoading?: boolean;
}

interface ICreateIntentCatalogForm {
    catalogName: string;
}

const validate = (values: ICreateIntentCatalogForm) => {
    const errors: any = {};

    if (!values.catalogName) {
        errors.catalogName = 'Required';
    }

    return errors;
};

export const CreateIntentCatalog: React.FC<ICreateIntentCatalogProps> = ({ onCatalogCreate, isLoading }) => {
    const token = useStyle();
    const { t } = useTranslation();
    const handleCreateCatalog = (formValues: ICreateIntentCatalogForm) => {
        onCatalogCreate && onCatalogCreate(formValues.catalogName);
    };

    const initValues: ICreateIntentCatalogForm = {
        catalogName: ''
    };

    const createIntentCatalogRender: React.FC<FormikProps<ICreateIntentCatalogForm>> = props => {
        const intentCatalogNameId = 'catalogName';

        return (
            <Form>
                <Heading size={Size.MEDIUM}>Intent Catalog Creation</Heading>
                <Divider />
                <FormControl
                    label={t('catalogName')}
                    fieldId={intentCatalogNameId}
                    required={true}
                    helperText={props.errors.catalogName}
                    helperTextStyle={errorHelperTextStyle(token)}
                >
                    <Field
                        required={true}
                        id={intentCatalogNameId}
                        name={intentCatalogNameId}
                        type="text"
                        as={InputField}
                        useNativeOnChange={true}
                        placeholder={t('name')}
                        isInvalid={props.errors.catalogName}
                    />
                </FormControl>
                <div css={buttonWrapperStyle}>
                    {!isLoading ? (
                        <Button type="submit" variant={ButtonVariant.SOLID} onClick={() => props.handleSubmit()}>
                            {t('createCatalog')}
                        </Button>
                    ) : (
                        <Button
                            disabled
                            icon={IconTypes.LOAD_SPINNER}
                            iconStyle={rotating}
                            variant={ButtonVariant.SOLID}
                        >
                            {t('creating')}
                        </Button>
                    )}
                </div>
            </Form>
        );
    };

    return (
        <Formik validate={validate} initialValues={initValues} onSubmit={handleCreateCatalog} validateOnBlur={false}>
            {createIntentCatalogRender}
        </Formik>
    );
};
