/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCurrentSpace } from '../useCurrentSpace';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { createTextToSpeechMediaCall } from '../../networking/TextToSpeechService';
import { ITextToSpeechCreateMediaRequest } from '../../models/TextToSpeechRequest';
import { IHostedMediaSource } from '../../models/MediaItem';
import { useDispatch } from 'react-redux';

export const useCreateTextToSpeechMedia = (): IHookProvidedCall<
    ITextToSpeechCreateMediaRequest,
    IHostedMediaSource | undefined
> => {
    const [currentSpace] = useCurrentSpace();
    const mediaDispatch = useDispatch();

    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const postCreateMedia = async (
        request: ITextToSpeechCreateMediaRequest
    ): Promise<IHostedMediaSource | undefined> => {
        return await triggerCall(
            createTextToSpeechMediaCall(currentSpace, request, getCancelableDispatch(mediaDispatch))
        );
    };
    return {
        execute: postCreateMedia,
        ...rest
    };
};
