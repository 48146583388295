import * as React from 'react';

import { IBaseDrag } from '../Drop/Drop';
import { IAdvancedDragProps, AdvancedDrag } from '../AdvancedDrag/AdvancedDrag';
import {
    DraggingStyle,
    NotDraggingStyle,
    DraggableStateSnapshot,
    DraggableProvidedDragHandleProps
} from 'react-beautiful-dnd';

export interface ILargeDragProps<T extends IBaseDrag> extends Omit<IAdvancedDragProps<T>, 'children'> {
    children?: (props: DraggableProvidedDragHandleProps | null) => React.ReactElement;
    enableDropAnimation?: boolean;
}

function getStyle(
    style: DraggingStyle | NotDraggingStyle | undefined,
    snapshot: DraggableStateSnapshot,
    enableAnimation: boolean
) {
    if (!snapshot.isDropAnimating || enableAnimation) {
        return style;
    }
    return {
        ...style,
        // cannot be 0, but make it super tiny
        transitionDuration: `0.0001s`
    };
}

/**
 * Large Drag is for objects where the drag object is significantly large and need to assign a handle to a child component
 */
export function LargeDrag<T extends IBaseDrag>({ children, enableDropAnimation = true, ...props }: ILargeDragProps<T>) {
    return (
        <AdvancedDrag<T> {...props}>
            {(dragProvided, snapshot) => (
                <div
                    {...dragProvided.draggableProps}
                    ref={dragProvided.innerRef}
                    style={{
                        ...getStyle(dragProvided.draggableProps.style, snapshot, enableDropAnimation),
                        outline: 'none'
                    }}
                >
                    {dragProvided.dragHandleProps && children && children(dragProvided.dragHandleProps)}
                </div>
            )}
        </AdvancedDrag>
    );
}
