/* eslint-disable @typescript-eslint/no-empty-interface */
import { useContext } from 'react';
import React from 'react';
import { IChooseState, IChooseAction } from '../../shared/motive/reducers/ChooseMediaSourceReducer';

export interface IChooseMediaSourceContextStateValue extends IChooseState {}
export interface IChooseMediaSourceContextDispatchValue extends React.Dispatch<IChooseAction> {}

const chooseMediaSourceContextState = React.createContext<IChooseMediaSourceContextStateValue>({
    state: 'off'
});
const chooseMediaSourceContextDispatch = React.createContext<IChooseMediaSourceContextDispatchValue>(() => undefined);

export const ChooseMediaSourceContextStateProvider = chooseMediaSourceContextState.Provider;
export const ChooseMediaSourceContextDispatchProvider = chooseMediaSourceContextDispatch.Provider;

export const useChooseMediaSourceState = () => useContext(chooseMediaSourceContextState);
export const useChooseMediaSourceDispatch = () => useContext(chooseMediaSourceContextDispatch);
