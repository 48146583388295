import { get } from 'lodash-es';
import { ICatalog } from '../../models/Catalog';
import { IScriptObjectModel } from '../../models/ScriptObjectModel';
import {
    getCatalogByIdNetworkCall,
    getCatalogsByTypeNetworkCall,
    getCatalogsNetworkCall,
    getCatalogTypesNetworkCall
} from '../../networking/CatalogService';
import { ITypeBundle } from '../../models/TypeBundle';
import { useNetworkCallForDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedDataSource } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useSelector, useDispatch } from 'react-redux';
import { SelectCurrentSpace } from '../../../../redux/spaceKeyed/spaceKeyedSelectors';
import { SelectCatalogTypesState } from '../../../../redux/spaceKeyed/catalogTypes/CatalogTypesSelector';
import { SelectAllCatalogs } from '../../../../redux/spaceKeyed/catalog/CatalogSelectors';
import { AllCatalogActions } from '../../../../redux/spaceKeyed/catalog/CatalogsReducer';
import { CatalogTypeActions } from '../../../../redux/spaceKeyed/catalogTypes/CatalogTypeActions';

const ID_KEY = 'id';
const TYPE_KEY = 'type';
const PROJECT_KEY = 'projectId';

export interface ICatalogHookFilter {
    [ID_KEY]?: string;
    [TYPE_KEY]?: string[];
    [PROJECT_KEY]?: string;
}

const loadCatalogsById = async (spaceName: string, dispatch: (action: AllCatalogActions) => void, id: string) =>
    await getCatalogByIdNetworkCall(spaceName, id, dispatch);
const loadCatalogsByType = async (spaceName: string, dispatch: (action: AllCatalogActions) => void, type: string[]) =>
    await getCatalogsByTypeNetworkCall(spaceName, type, dispatch);
const loadCatalogTypes = async (spaceName: string, dispatch: (action: CatalogTypeActions) => void) => {
    await getCatalogTypesNetworkCall(spaceName, dispatch);
};

export const loadData = async (
    spaceName: string,
    dispatch: (action: AllCatalogActions) => void,
    filter?: ICatalogHookFilter
) => {
    const id = get(filter, [ID_KEY], undefined);
    const type = get(filter, [TYPE_KEY], undefined);

    if (!!id) {
        await loadCatalogsById(spaceName, dispatch, id);
    } else if (!!type) {
        await loadCatalogsByType(spaceName, dispatch, type);
    } else {
        await getCatalogsNetworkCall(spaceName, dispatch);
    }
};

export const loadTypes = async (spaceName: string, dispatch: (action: CatalogTypeActions) => void) => {
    await loadCatalogTypes(spaceName, dispatch);
};

export const useCatalogsBaseHook = (
    filter?: ICatalogHookFilter
): IHookProvidedDataSource<ICatalog<IScriptObjectModel>[]> => {
    const currentSpace = useSelector(SelectCurrentSpace);

    const dispatch = useDispatch();
    const data = useSelector(SelectAllCatalogs);

    const { triggerCall, ...rest } = useNetworkCallForDataSource(data);

    const call = async () => {
        if (currentSpace) {
            await triggerCall(loadData(currentSpace, dispatch, filter));
        }
    };

    return {
        load: call,
        data: data,
        ...rest
    };
};

export const useCatalogTypesBaseHook = (): IHookProvidedDataSource<ITypeBundle[]> => {
    const currentSpace = useSelector(SelectCurrentSpace);
    const catalogTypesState = useSelector(SelectCatalogTypesState);
    const dispatch = useDispatch();

    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallForDataSource(catalogTypesState);

    const call = async () => {
        if (currentSpace) await triggerCall(loadTypes(currentSpace, getCancelableDispatch(dispatch)));
    };

    return {
        data: catalogTypesState.catalogTypes,
        load: call,
        ...rest
    };
};
