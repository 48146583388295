import { LocalizationKeys } from '../LocalizationKeys';

export const frStrings: Map<LocalizationKeys, string> = new Map<LocalizationKeys, string>()
    .set(LocalizationKeys.Account, 'Compte')
    .set(LocalizationKeys.View, 'Voir')
    .set(LocalizationKeys.Login, 'Login')
    .set(LocalizationKeys.LogOut, 'Se déconnecter')
    .set(LocalizationKeys.CreateProject, 'Créer un nouveau projet')
    .set(LocalizationKeys.ExistingProject, 'Ouvrir un projet existant')
    .set(LocalizationKeys.Recent, 'Récent')
    .set(LocalizationKeys.Save, 'Save')
    .set(LocalizationKeys.Cancel, 'Annuler')
    .set(LocalizationKeys.SignIntoMotive, 'Se Connecter à Motive')
    .set(LocalizationKeys.LastSavedOn, 'Dernier enregistrement le')
    .set(LocalizationKeys.Scripting, 'Scripting')
    .set(LocalizationKeys.Catalog, 'Catalogue')
    .set(LocalizationKeys.Catalogs, 'Catalogues')
    .set(LocalizationKeys.Resource, 'Ressource ')
    .set(LocalizationKeys.Resources, 'Ressources ')
    .set(LocalizationKeys.Script, 'Script')
    .set(LocalizationKeys.Scripts, 'Scripts')
    .set(LocalizationKeys.CourseDescription, 'Description du Cours')
    .set(LocalizationKeys.CourseTitle, 'Titre de Cours')
    .set(LocalizationKeys.EnterADescription, 'Entrez une description')
    .set(LocalizationKeys.OutputOptions, 'Options de Sortie')
    .set(LocalizationKeys.LaunchOptions, 'Options de Lancement')
    .set(LocalizationKeys.Properties, 'Propriétés')
    .set(LocalizationKeys.LaunchWithAQuickCode, 'Lancer avec Code')
    .set(LocalizationKeys.LaunchInHeadset, 'Lancement en VR')
    .set(LocalizationKeys.MotiveHosted, 'Motive Hosted')
    .set(LocalizationKeys.LaunchServerOptions, 'Lancer les Options du Serveur')
    .set(LocalizationKeys.LaunchServerAuthentication, 'Authentification du serveur')
    .set(LocalizationKeys.LaunchServerURL, 'URL du Serveur')
    .set(LocalizationKeys.Download, 'Télécharger')
    .set(LocalizationKeys.AutomaticUpload, 'Téléchargement automatique')
    .set(LocalizationKeys.AddNote, 'Ajouter une note')
    .set(LocalizationKeys.Note, 'Note')
    .set(LocalizationKeys.RemoveNote, 'Supprimer la note')
    .set(LocalizationKeys.ManageAccount, 'Gérer son compte')
    .set(LocalizationKeys.MySpaces, 'Espaces de travail')
    .set(LocalizationKeys.AddCustomEvent, 'Ajouter un événement personnalisé')
    .set(LocalizationKeys.Delete, 'Delete')
    .set(LocalizationKeys.ProjectName, 'fr Project Name')
    .set(LocalizationKeys.ProjectNameIsTooLong, 'fr The project name is too long')
    .set(
        LocalizationKeys.ProjectNameIsTooShort,
        'fr The project name is too short, it must be greater than 5 characters'
    )
    .set(LocalizationKeys.ProjectNameIsTaken, 'fr The project name is already taken')
    .set(LocalizationKeys.AdvancedSettings, 'fr Advanced Settings')
    .set(LocalizationKeys.CatalogOptions, 'fr Catalog Options')
    .set(LocalizationKeys.AutoGenerateCatalogs, 'fr Auto Generate Default Catalogs')
    .set(LocalizationKeys.ImportCatalogs, 'fr Import Existing Catalogs')
    .set(LocalizationKeys.Create, 'fr Create')
    .set(LocalizationKeys.Scenarios, 'fr Scenarios')
    .set(LocalizationKeys.Media, 'fr Media')
    .set(LocalizationKeys.Show, 'fr Show')
    .set(LocalizationKeys.Hide, 'fr Hide')
    .set(LocalizationKeys.Copy, 'fr Copy')
    .set(LocalizationKeys.Team, 'fr Team')
    .set(LocalizationKeys.AddTeamMember, 'fr Add Team Member')
    .set(LocalizationKeys.PendingInvites, 'fr Pending Invites')
    .set(LocalizationKeys.ConfigName, 'fr Config Name')
    .set(LocalizationKeys.AppName, 'fr App Name')
    .set(LocalizationKeys.ApiKey, 'fr Api Key')
    .set(LocalizationKeys.Project, 'fr Project')
    .set(LocalizationKeys.Edited, 'fr edited')
    .set(LocalizationKeys.Activity, 'fr Activity');
