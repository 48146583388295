import { ICatalog } from '../../../models/Catalog';
import { IScriptObjectModel } from '../../../models/ScriptObjectModel';
import { useObjectDefinition } from '../../useScriptEditorInfo';
import { IHookProvidedDataSource } from '../../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { getObjectTypeAndImplementors } from '../../useScriptEditorInfo/useScriptEditorInfo';
import { useNetworkCallForDataSource } from '../../../../hooks/useNetworkCallForDataSource';
import { getCatalogsByTypeNetworkCall } from '../../../networking/CatalogService';
import { useCurrentSpace } from '../../useCurrentSpace';
import { SelectCatalogsWithTypeFilter } from '../../../../../redux/spaceKeyed/catalog/CatalogSelectors';
import { useSelector, useDispatch } from 'react-redux';

export const useGetAllCatalogsByType = (type: string): IHookProvidedDataSource<ICatalog<IScriptObjectModel>[]> => {
    const objDef = useObjectDefinition(type);
    const implementors = getObjectTypeAndImplementors(objDef, false);
    const data = useSelector(SelectCatalogsWithTypeFilter(implementors));
    const [space] = useCurrentSpace();
    const catalogDispatch = useDispatch();

    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallForDataSource(data);

    const call = async () => {
        await triggerCall(getCatalogsByTypeNetworkCall(space, [type], getCancelableDispatch(catalogDispatch)));
    };

    return {
        load: call,
        data: data,
        ...rest
    };
};
