import { SPACE_ENDPOINT } from '../constants/ApiEndpoint';
import { longRunningHttpClient } from './HttpClient';
import {
    IBundleAction,
    bundleBeginRestoreAction,
    bundleEndRestoreAction
} from '../../../redux/spaceKeyed/bundle/BundleActions';

export const restorePath = (spaceName: string, projectName: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/${projectName}/restore`;
};

export const restoreBundle = async (
    spaceName: string,
    projectName: string,
    zipFile: File,
    bundleDispatch: (params: IBundleAction) => void
) => {
    const payload = new FormData();
    payload.append('file', zipFile);

    try {
        bundleDispatch(bundleBeginRestoreAction());

        await longRunningHttpClient.post(restorePath(spaceName, projectName), payload);

        bundleDispatch(bundleEndRestoreAction());
    } catch (e) {
        bundleDispatch(bundleEndRestoreAction(e));
    }
};
