import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const localizedTextLayout = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const cultureCodeStyle = (token: IToken) => css`
    font-size: ${token.fontSizes.small};
`;
