import React, { useState } from 'react';
import { Intents } from '../intents/Intents';
import { InputField } from '../../core-ui/inputField';
import { Button, ButtonVariant } from '../../core-ui/button';
import { Divider } from '../../core-ui/divider';
import { Collapsible } from '../../core-ui/collapse';
import { Text } from '../../core-ui/text';
import { Size } from '../../core-ui/constants/Size';
import { useStyle } from '../../shared/hooks/useStyle';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { IntentCatalogSetting } from '../intentCatalogSetting/IntentCatalogSetting';
import {
    headingWrapperStyle,
    buttonWrapperStyle,
    buttonStyle,
    catalogSettingsWrapperStyle,
    actionBarWrapper,
    searchBarStyle,
    emptyPanelStyle,
    disabledButtonStyle,
    deleteButtonStyle,
    catalogSettingButtonStyle
} from './DashboardIntents.style';
import { ConversationalAgent } from '../../shared/motive/models/ConversationalAgent';
import { CreateIntentItem } from '../createIntentItem';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { EmptyCollection } from '../../core-ui/emptyCollection';
import { DeleteModal } from '../../core-ui/deleteModal/DeleteModal';
import { ConversationalIntent } from '../../shared/motive/models/ConversationalIntent';

export interface IDashboardIntentManagementProps {
    intentCatalog?: ICatalog;
    agents?: ConversationalAgent[];
    searchFilter?: string;
    agent?: ConversationalAgent;
    onSelectIntent?: (intent: string) => void;
    onUpdateAgent?: (agentId: string) => void;
    onCreateIntent?: (intent: ConversationalIntent) => void;
    onDeleteIntents?: (intents: string[]) => void;
    onUpdateIntent?: (intentId: string, name?: string, description?: string) => Promise<void>;
    onMultiSelectIntents?: (selectedIntents: string[]) => void;
    onSearchIntent?: (filter: string | undefined) => void;
    multiSelectedIntents?: string[];
    isCreateIntentLoading?: boolean;
    isUpdatePivotFieldLoading?: boolean;
    isDeleteIntentLoading?: boolean;
}

export const DashboardIntentManagement: React.FC<IDashboardIntentManagementProps> = props => {
    const [isCatalogSettingsShowing, setIsCatalogsSettingsShowing] = useState<boolean>(false);
    const [isCreateIntentShowing, setIsCreateIntentShowing] = useState<boolean>(false);
    const [isDeleteIntentModalOpen, setIsDeleteIntentModalOpen] = useState<boolean>(false);

    const token = useStyle();

    const handleSearchFilter = (filter: string | undefined) => {
        props.onSearchIntent && props.onSearchIntent(filter);
    };

    const handleToggleCatalogSettings = () => {
        setIsCatalogsSettingsShowing(!isCatalogSettingsShowing);
        setIsCreateIntentShowing(false);
    };

    const handleToggleCreateIntent = () => {
        setIsCatalogsSettingsShowing(false);
        setIsCreateIntentShowing(!isCreateIntentShowing);
    };

    const handleCreateIntent = (intent: ConversationalIntent) => {
        props.onCreateIntent && props.onCreateIntent(intent);
    };

    const handleUpdateAgent = (agentId: string) => {
        props.onUpdateAgent && props.onUpdateAgent(agentId);
    };

    const handleDeleteIntents = async () => {
        props.onDeleteIntents && props.onDeleteIntents(props.multiSelectedIntents ?? []);
        setIsDeleteIntentModalOpen(false);
    };

    return (
        <>
            <div css={headingWrapperStyle}>
                <Text size={Size.LARGEST}>{props.intentCatalog?.title}</Text>
                <div css={buttonWrapperStyle}>
                    <Button
                        icon={IconTypes.COG}
                        css={[buttonStyle(token), catalogSettingButtonStyle(token)]}
                        variant={ButtonVariant.SOLID}
                        onClick={handleToggleCatalogSettings}
                    />
                    <Button
                        icon={IconTypes.PLUS}
                        css={buttonStyle(token)}
                        variant={ButtonVariant.SOLID}
                        onClick={handleToggleCreateIntent}
                    >
                        Create New Intent
                    </Button>
                </div>
            </div>
            <Collapsible isShowing={isCatalogSettingsShowing}>
                <div css={catalogSettingsWrapperStyle(token)}>
                    <IntentCatalogSetting
                        currentAgent={props.agent}
                        agents={props.agents}
                        onUpdateAgent={handleUpdateAgent}
                        isEmpty={props.intentCatalog?.items && props.intentCatalog?.items?.length > 0}
                    />
                </div>
            </Collapsible>
            <Collapsible isShowing={isCreateIntentShowing}>
                <div css={catalogSettingsWrapperStyle(token)}>
                    <CreateIntentItem
                        onIntentItemCreation={handleCreateIntent}
                        agents={props.agents ?? []}
                        currentAgent={props.agent}
                        isCreateIntentItemLoading={props.isCreateIntentLoading}
                        disableAgentSelection={props.intentCatalog?.items && props.intentCatalog?.items.length > 0}
                    />
                </div>
            </Collapsible>
            <Divider />
            <div css={actionBarWrapper}>
                <InputField
                    css={searchBarStyle(token)}
                    placeholder={'Search Intents'}
                    onChange={handleSearchFilter}
                    value={props.searchFilter}
                />
                <Button
                    onClick={() => setIsDeleteIntentModalOpen(true)}
                    icon={IconTypes.DELETE}
                    variant={ButtonVariant.SOLID}
                    css={deleteButtonStyle(token)}
                    disabled={!!(props.multiSelectedIntents && props.multiSelectedIntents?.length === 0)}
                    disabledStyle={disabledButtonStyle(token)}
                />
            </div>
            <>
                <DeleteModal
                    isOpen={isDeleteIntentModalOpen}
                    onCloseModal={() => setIsDeleteIntentModalOpen(false)}
                    onConfirm={handleDeleteIntents}
                >
                    {`Are you sure you want to delete the ${props.multiSelectedIntents?.length} intent(s)?`}
                </DeleteModal>
            </>
            {props.intentCatalog?.items && props.intentCatalog.items.length > 0 ? (
                <Intents
                    agents={props.agents ?? []}
                    intents={(props.intentCatalog?.items as ConversationalIntent[]) ?? []}
                    catalogId={props.intentCatalog.id}
                    onUpdateIntent={props.onUpdateIntent}
                    onSelectIntent={props.onSelectIntent}
                    searchFilter={props.searchFilter}
                    isCreateIntentLoading={props.isCreateIntentLoading}
                    isUpdatingPivotField={props.isUpdatePivotFieldLoading}
                    isDeleteIntentsLoading={props.isDeleteIntentLoading}
                    selectedIntents={props.multiSelectedIntents}
                    onMultiSelectIntent={props.onMultiSelectIntents}
                />
            ) : (
                <EmptyCollection
                    css={emptyPanelStyle}
                    onClickAction={handleToggleCreateIntent}
                    message={'Create your first Intent by clicking here or pressing the Create New Intent Button'}
                />
            )}
        </>
    );
};
