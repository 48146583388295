import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const loginContainerStyle = ({ colors }: IToken) => css`
    height: 100vh;
    width: 100vw;
    background-color: ${colors.background.colorLighter};
`;

export const storyFlowLogoStyle = ({ colors, fontSizes }: IToken) => css`
    color: ${colors.font.primary};
    font-size: ${fontSizes.largest};
`;

export const centerLoginForm = () => css`
    position: fixed;
    width: 30vw;
    height: 40vh;
    top: calc(50% - 20vh);
    left: calc(50% - 15vw);
`;
