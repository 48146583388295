import { useState } from 'react';
import { IEnumDefinitionItem } from '../../shared/motive/models/EnumDefinition';
import { useDeleteEnumDefinitionItem } from '../../shared/motive/hooks/useDeleteEnumDefinitionItem/useDeleteEnumDefinitionItem';
import { useUpdateEnumDefinitionItem } from '../../shared/motive/hooks/useUpdateEnumDefinitionItem/useUpdateEnumDefinitinItem';
import { useCurrentSpace } from '../../shared/motive/hooks/useCurrentSpace';
import { RowItem } from '../../core-ui/rowItem';
import { EditableText } from '../../core-ui/editableText';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import React from 'react';
import { Button } from '../../core-ui/button';
import {
    spaceEnumItemStyle,
    editableTextWrapperStyle,
    editableTextTextStyle,
    errorMessageStyle
} from './EnumDefinitionItem.style';
import { useTranslation } from 'react-i18next';
import { Text } from '../../core-ui/text';
import { Size } from '../../core-ui/constants/Size';

interface IEnumDefinitionItemProps {
    enumId: string;
    item: IEnumDefinitionItem;
    enumDefItemNames?: string[];
}

export const EnumDefinitionItem: React.FC<IEnumDefinitionItemProps> = ({ enumId, item, enumDefItemNames }) => {
    const deleteEnumItem = useDeleteEnumDefinitionItem();
    const updateEnumItem = useUpdateEnumDefinitionItem();
    const [currentSpace] = useCurrentSpace();
    const [enumItemStatus, setEnumItemStatus] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [newEnumItemName, setNewEnumItemName] = useState<string>('');

    const { t } = useTranslation();

    const handleDelete = () => {
        setEnumItemStatus(t('deleting'));

        deleteEnumItem
            .execute({ enumId: enumId, enumItemId: item.id ? item.id : '' })
            .then(() => setEnumItemStatus(''));
    };

    const handleUpdate = () => {
        if (!newEnumItemName) {
            return;
        }

        if (newEnumItemName === item.name || newEnumItemName === item.label) {
            return;
        }

        setErrorMessage('');

        if (enumDefItemNames?.includes(newEnumItemName.toLowerCase())) {
            setErrorMessage(t('enumItemNameError'));
            return;
        }

        setEnumItemStatus(t('updating'));

        updateEnumItem
            .execute({
                enumId: enumId,
                enumItemId: item.id ?? '',
                updatedItem: { ...item, label: newEnumItemName, name: newEnumItemName }
            })
            .then(() => setEnumItemStatus(''));
    };
    return (
        <RowItem>
            <div css={spaceEnumItemStyle}>
                <EditableText
                    wrapperStyle={editableTextWrapperStyle}
                    textStyle={editableTextTextStyle}
                    inputStyle={editableTextTextStyle}
                    placeholder={enumItemStatus ? enumItemStatus : ''}
                    defaultValue={!enumItemStatus ? (item.label ? item.label : item.name) : ''}
                    onEditComplete={handleUpdate}
                    onChange={newName => setNewEnumItemName(newName.trim())}
                />
                {item.space === currentSpace && <Button onClick={handleDelete} icon={IconTypes.DELETE} />}
            </div>
            {errorMessage && (
                <Text size={Size.SMALL} css={errorMessageStyle}>
                    {errorMessage}
                </Text>
            )}
        </RowItem>
    );
};
