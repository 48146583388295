import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const scriptHeadingStyle = (token: IToken) => css`
    background-color: ${token.colors.background.lighter};
    &:hover {
        background-color: ${token.colors.background.light};
    }
    padding: ${token.spacings.small};
    margin-top: ${token.spacings.smaller};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const scriptControlWrapper = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const catalogRowWrapper = css`
    cursor: pointer;
`;
