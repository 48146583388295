import React from 'react';
import { ObjectEditor, IObjectEditorProps } from '../objectEditor';
import { IVariableSelector, IFrame, IScript } from '../../shared/motive/models/Script';
import { useScriptAndGlobalVariables } from '../../shared/motive/hooks/useScriptAndGlobalVariables';
import { ObjectEditorBox } from '../../components/objectEditorBox';
import { isAppendable } from '../../util/ScriptDynamicsUtil';

export interface ISelectorContainerProps {
    scriptVariableId: string;
    selector: IVariableSelector;
    onDeleteSelector: (selectorId: string) => void;
    onChange: IObjectEditorProps['onChange'];
    subFramePath: string;
    showVariableTitle?: boolean;
    frame: IFrame;
    script: IScript;
}

export const SelectorContainer: React.FC<ISelectorContainerProps> = ({
    onChange,
    subFramePath,
    selector,
    scriptVariableId,
    onDeleteSelector,
    showVariableTitle,
    frame,
    script
}) => {
    const variableMap = useScriptAndGlobalVariables();
    const scriptVariable = variableMap[scriptVariableId];
    if (!scriptVariable) {
        //throw new Error('uh oh variable not found');
    }

    const appendable = isAppendable(scriptVariable.valueDefinition);

    return (
        <>
            <ObjectEditorBox
                title={showVariableTitle ? scriptVariable.name : undefined}
                id={` (${scriptVariable.id})`}
                onClickDelete={() => onDeleteSelector(selector.id)}
                depth={0}
            >
                <ObjectEditor
                    frame={frame}
                    script={script}
                    isReadOnly={false}
                    onChange={onChange}
                    path={subFramePath}
                    hideFields={appendable ? undefined : ['append']}
                    value={selector}
                    type={selector.type}
                    depth={0}
                    editingVariableId={scriptVariable.id}
                />
            </ObjectEditorBox>
        </>
    );
};
