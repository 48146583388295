import React from 'react';
import { IProjectsRouteParams } from '../projectsRoute';
import { useRoute } from '../useRoute';
import { PublishingContainer } from '../../containers/publishingContainer';
import { Publishing } from '../../components/publishing';

export const usePublishingRoute = () => useRoute<IProjectsRouteParams>('Publishing');

export const PublishingRoute: React.FC = () => {
    const {
        params: { projectName }
    } = usePublishingRoute();

    return (
        <>
            {projectName && (
                <PublishingContainer>
                    {publishingProps => <Publishing {...publishingProps}></Publishing>}
                </PublishingContainer>
            )}
        </>
    );
};
