/* eslint-disable @typescript-eslint/no-explicit-any */
// istanbul ignore file MOTIVE_EXCEPTION
import React from 'react';
import {
    thumbContainerStyle,
    sectionCollectionStyle,
    inputStickyPositionStyle,
    inputStyle,
    frameToolPanelLayoutStyle
} from './FrameToolsPanel.style';
import { Collection } from '../../core-ui/collection';
import { useStyle } from '../../shared/hooks/useStyle';
import { sectionGroupStyle } from './FrameToolsPanel.style';
import { sectionGroupTitleStyle } from './FrameToolsPanel.style';
import { ListOrGrid } from '../../core-ui/collection/Collection';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { checkFilterObject } from '../../util/FilterUtils';
import { InputField } from '../../core-ui/inputField';
import { Size } from '../../core-ui/constants/Size';
import { HeightUsedScrollContainer } from '../../containers/heightUsedScrollContainer';
import { IBaseDrag, Drop } from '../_common/DragAndDrop/Drop/Drop';
import { CloneDrag } from '../_common/DragAndDrop/CloneDrag/CloneDrag';

library.add(fas);

export interface IFrameToolsSection<T extends IBaseDrag> {
    title: string;
    items: IFrameToolsItem<T>[];
}

export interface IFrameToolsItem<T extends IBaseDrag> {
    drag: T;
    renderItem: () => React.ReactElement;
    renderDraggable?: () => React.ReactElement;
    onDoubleClick?: () => void;
    key: string;
    keywords?: string[];
}

interface ISectionProps<T extends IBaseDrag> {
    display: ListOrGrid;
    title: string;
    section: IFrameToolsSection<T>;
    filter?: string;
    index: number;
}

function Section<T extends IBaseDrag>({ filter, section, display, title, index }: ISectionProps<T>) {
    const token = useStyle();

    const filtered = !!filter
        ? section.items && section.items.filter(item => checkFilterObject(item.keywords ?? item, filter))
        : section.items;

    const renderItem = (item: IFrameToolsItem<T>, index: number) => {
        return <div key={`item-${index}`}>{item.renderItem()}</div>;
    };

    const renderThumb = (item: IFrameToolsItem<T>, index: number) => {
        return (
            <div key={`thumb-${index}`} css={thumbContainerStyle}>
                {renderItem(item, index)}
            </div>
        );
    };

    const collectionItems =
        filtered &&
        filtered.map(
            (item: IFrameToolsItem<T>, index): React.ReactElement => (
                <Drop
                    isDisabled={true}
                    key={item.key}
                    dropId={`clonedDraggableDroppable:${item.key}`}
                    dragType={item.drag.dragType}
                >
                    <CloneDrag<T>
                        drag={item.drag}
                        index={index}
                        thumb={renderThumb(item, index)}
                        onDoubleClick={item.onDoubleClick}
                    >
                        {renderItem(item, index)}
                    </CloneDrag>
                </Drop>
            )
        );

    return (
        <div key={`${section.title}-${index}`}>
            {collectionItems && collectionItems.length > 0 && (
                <div css={sectionGroupStyle(token)}>
                    <div css={sectionGroupTitleStyle(token)}>{title}</div>

                    <Collection css={sectionCollectionStyle(token)} display={display}>
                        {collectionItems}
                    </Collection>
                </div>
            )}
        </div>
    );
}

export interface IFrameToolsPanelProps<T extends IBaseDrag> {
    sections: IFrameToolsSection<T>[];
    filter: string | undefined;
    display: ListOrGrid;
    onSearchInputChange: (value: string) => void;
}

export function FrameToolsPanel<T extends IBaseDrag>({
    sections,
    filter,
    onSearchInputChange,
    display = 'grid'
}: IFrameToolsPanelProps<T>) {
    const tokens = useStyle();
    return (
        <div css={frameToolPanelLayoutStyle}>
            <div css={inputStickyPositionStyle(tokens)}>
                <InputField css={inputStyle} onChange={onSearchInputChange} size={Size.MEDIUM} />
            </div>
            <HeightUsedScrollContainer>
                {sections.map((s, i) => {
                    return (
                        <Section<T> key={i} filter={filter} title={s.title} section={s} display={display} index={i} />
                    );
                })}
            </HeightUsedScrollContainer>
        </div>
    );
}
