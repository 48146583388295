/**
 * Defines all possible enum values for facial blend shapes
 */
export enum FacialBlendShape {
    BrowDropLeft = `browDropLeft`,
    BrowDropRight = `browDropRight`,
    BrowRaiseLeft = `browRaiseLeft`,
    BrowRaiseRight = `browRaiseRight`,
    BrowRaiseInnerLeft = `browRaiseInnerLeft`,
    BrowRaiseInnerRight = `browRaiseInnerRight`,
    BrowRaiseOuterLeft = `browRaiseOuterLeft`,
    BrowRaiseOuterRight = `browRaiseOuterRight`,
    EyesBlink = `eyesBlink`,
    EyeBlinkLeft = `eyeBlinkLeft`,
    EyeBlinkRight = `eyeBlinkRight`,
    EyeWideLeft = `eyeWideLeft`,
    EyeWideRight = `eyeWideRight`,
    EyeSquintLeft = `eyeSquintLeft`,
    EyeSquintRight = `eyeSquintRight`,
    NoseScrunch = `noseScrunch`,
    NoseFlanksRaise = `noseFlanksRaise`,
    NoseFlankRaiseLeft = `noseFlankRaiseLeft`,
    NoseFlankRaiseRight = `noseFlankRaiseRight`,
    NoseNostrilsFlare = `noseNostrilsFlare`,
    CheekRaiseLeft = `cheekRaiseLeft`,
    CheekRaiseRight = `cheekRaiseRight`,
    CheeksSuck = `cheeksSuck`,
    CheekBlowLeft = `cheekBlowLeft`,
    CheekBlowRight = `cheekBlowRight`,
    MouthSmile = `mouthSmile`,
    MouthSmileLeft = `mouthSmileLeft`,
    MouthSmileRight = `mouthSmileRight`,
    MouthFrown = `mouthFrown`,
    MouthFrownLeft = `mouthFrownLeft`,
    MouthFrownRight = `mouthFrownRight`,
    MouthBlow = `mouthBlow`,
    MouthPucker = `mouthPucker`,
    MouthPuckerOpen = `mouthPuckerOpen`,
    MouthWiden = `mouthWiden`,
    MouthWidenSides = `mouthWidenSides`,
    MouthDimpleLeft = `mouthDimpleLeft`,
    MouthDimpleRight = `mouthDimpleRight`,
    MouthPlosive = `mouthPlosive`,
    MouthLipsTight = `mouthLipsTight`,
    MouthLipsTuck = `mouthLipsTuck`,
    MouthLipsOpen = `mouthLipsOpen`,
    MouthLipsPart = `mouthLipsPart`,
    MouthBottomLipDown = `mouthBottomLipDown`,
    MouthTopLipUp = `mouthTopLipUp`,
    MouthTopLipUnder = `mouthTopLipUnder`,
    MouthBottomLipUnder = `mouthBottomLipUnder`,
    MouthSnarlUpperLeft = `mouthSnarlUpperLeft`,
    MouthSnarlUpperRight = `mouthSnarlUpperRight`,
    MouthSnarlLowerLeft = `mouthSnarlLowerLeft`,
    MouthSnarlLowerRight = `mouthSnarlLowerRight`,
    MouthBottomLipBite = `mouthBottomLipBite`,
    MouthDown = `mouthDown`,
    MouthUp = `mouthUp`,
    MouthLeft = `mouthLeft`,
    MouthRight = `mouthRight`,
    MouthLipsJawAdjust = `mouthLipsJawAdjust`,
    MouthBottomLipTrans = `mouthBottomLipTrans`,
    MouthSkewer = `mouthSkewer`,
    MouthOpen = `mouthOpen`,
    MoveJawLeftRight = `moveJawLeftRight`,
    MoveJawUpDown = `moveJawUpDown`
}
