import React, { useState } from 'react';
import { ISelectorBranch, ISelectorTree, IFrame, IScript } from '../../shared/motive/models/Script';
import { getSelectorTreeMaxDepth } from '../../util/MotiveUtils';
import { DynamicRowBranch } from '../../components/dynamicRowBranch';
import { DynamicRowNode } from '../../components/dynamicRowNode';
import {
    IFrameInspectorDynamicsOnChange,
    ILeftColWidth,
    IOnClickCreateSelectorTree,
    IRecursiveDataProps,
    ISelectedIndex
} from '../../containers/frameInspectorDynamicsContainer';
import { Collapsible } from '../../core-ui/collapse';
import { Drop } from '../_common/DragAndDrop/Drop/Drop';
import { DRAG_ITEM_DYNAMIC_NODE, DROPPABLE_ID_DYNAMIC_BRANCH, generateDropId } from '../../constants/DragAndDrop';
import { IDynamicNodeDrag } from '../_common/DragAndDrop/DragModels/DragModels';
import { uniqueId } from 'lodash-es';
import { LargeDrag } from '../_common/DragAndDrop/LargeDrag/LargeDrag';

export interface IRecursiveBranchThenScenariosProps extends IRecursiveDataProps, IRecursiveSelectiveTreeRootProps {
    selectorBranch: ISelectorBranch;
    onDeleteBranch: () => void;
}

export const RecursiveBranchThenScenarios: React.FC<IRecursiveBranchThenScenariosProps> = ({
    indexDepth,
    maxDepth,
    selectorBranch,
    subFramePath,
    onDeleteBranch,
    onLeftColWidthChange,
    onClickCreateVariable,
    ...restProps
}) => {
    const [uniqueBranch] = useState(uniqueId('branch'));
    const hasScenarioNodes = selectorBranch.nodes && selectorBranch.nodes.length > 0;
    const [isShowing, setIsShowing] = useState(true);
    const dragId = generateDropId(DRAG_ITEM_DYNAMIC_NODE, uniqueBranch);
    const dropList = [
        {
            dragType: dragId,
            onDragEnd: (data: IDynamicNodeDrag, index: number) => {
                if (subFramePath === data?.parentBranchPath) {
                    if (data.nodeIndex !== index && selectorBranch.nodes) {
                        const nodes = [...selectorBranch.nodes];
                        const [removed] = nodes.splice(data.nodeIndex, 1);
                        nodes.splice(index, 0, removed);
                        restProps.onChange(`${subFramePath}.nodes`, nodes);
                    }
                }
            }
        }
    ];
    return (
        <Drop
            dropId={generateDropId(DROPPABLE_ID_DYNAMIC_BRANCH, uniqueBranch)}
            showPlaceHolder
            allowedDragList={dropList}
        >
            <DynamicRowBranch
                {...restProps}
                branch={selectorBranch}
                indexDepth={indexDepth}
                maxDepth={maxDepth}
                subFramePath={subFramePath}
                onDeleteBranch={onDeleteBranch}
                isSelectorTreeRoot={true}
                onLeftColWidthChange={onLeftColWidthChange}
                onClickCreateVariable={onClickCreateVariable}
                onRowClick={() => setIsShowing(!isShowing)}
            />
            <Collapsible isShowing={isShowing}>
                {hasScenarioNodes &&
                    selectorBranch.nodes?.map((scenarioNode, scenarioIndex) => {
                        const scenarioHasBranches = scenarioNode.branches && scenarioNode.branches.length > 0;
                        const scenarioPath = `${subFramePath}.nodes.${scenarioIndex}`;

                        return (
                            <LargeDrag<IDynamicNodeDrag>
                                drag={{
                                    dragType: dragId,
                                    nodePath: scenarioPath,
                                    parentBranchPath: subFramePath,
                                    nodeIndex: scenarioIndex
                                }}
                                key={scenarioIndex}
                                index={scenarioIndex}
                            >
                                {handle => (
                                    <>
                                        <DynamicRowNode
                                            {...restProps}
                                            scenarioNode={scenarioNode}
                                            scenarioNodeIndex={scenarioIndex}
                                            parentBranch={selectorBranch}
                                            parentBranchPath={subFramePath}
                                            indexDepth={indexDepth}
                                            maxDepth={maxDepth}
                                            subFramePath={scenarioPath}
                                            onDeleteBranch={onDeleteBranch}
                                            dragHandler={handle}
                                        />
                                        {scenarioHasBranches &&
                                            scenarioNode.branches?.map((scenarioBranch, scenarioBranchIndex) => {
                                                const handleDeleteBranch = () => {
                                                    const updatedBranches = [
                                                        ...(scenarioNode.branches as ISelectorBranch[])
                                                    ];
                                                    updatedBranches.splice(scenarioBranchIndex, 1);
                                                    const updatedNode = { ...scenarioNode, branches: updatedBranches };

                                                    restProps.onChange(`${scenarioPath}`, updatedNode);
                                                };

                                                const path = `${scenarioPath}.branches.${scenarioBranchIndex}`;

                                                return (
                                                    <RecursiveBranchThenScenarios
                                                        key={path}
                                                        selectorBranch={scenarioBranch}
                                                        indexDepth={indexDepth + 1}
                                                        maxDepth={maxDepth}
                                                        subFramePath={path}
                                                        onDeleteBranch={handleDeleteBranch}
                                                        {...restProps}
                                                    />
                                                );
                                            })}
                                    </>
                                )}
                            </LargeDrag>
                        );
                    })}
            </Collapsible>
        </Drop>
    );
};

export interface IRecursiveSelectiveTreeRootProps
    extends ISelectedIndex,
        IFrameInspectorDynamicsOnChange,
        ILeftColWidth,
        IOnClickCreateSelectorTree {
    selectorTree?: ISelectorTree | null;
    frame: IFrame;
    script: IScript;
}

export const RecursiveSelectiveTreeRoot: React.FC<IRecursiveSelectiveTreeRootProps> = ({
    selectorTree,
    subFramePath,
    onLeftColWidthChange,
    onClickCreateVariable,
    ...restProps
}) => {
    const hasBranches = selectorTree?.branches && selectorTree.branches.length > 0;
    let maxDepth = getSelectorTreeMaxDepth(selectorTree ?? undefined) ?? 1;
    maxDepth += 1;

    const startIndexDepth = 0;

    return (
        <div style={{ margin: '0px 16px' }}>
            <DynamicRowNode
                isSelectorTreeRoot={true}
                selectorTree={selectorTree}
                indexDepth={startIndexDepth}
                maxDepth={maxDepth}
                subFramePath={subFramePath}
                onLeftColWidthChange={onLeftColWidthChange}
                onClickCreateVariable={onClickCreateVariable}
                {...restProps}
            />
            {hasBranches &&
                selectorTree?.branches.map((branch, branchIndex) => {
                    const handleDeleteRootBranch = () => {
                        const updatedBranches = [...(selectorTree.branches as ISelectorBranch[])];
                        updatedBranches.splice(branchIndex, 1);
                        const updatedNode = { ...selectorTree, branches: updatedBranches };

                        restProps.onChange(`${subFramePath}`, updatedNode);
                    };

                    const path = `${subFramePath}.branches.${branchIndex}`;

                    return (
                        <RecursiveBranchThenScenarios
                            key={path}
                            selectorBranch={branch}
                            indexDepth={startIndexDepth + 1}
                            maxDepth={maxDepth}
                            subFramePath={path}
                            onClickCreateVariable={onClickCreateVariable}
                            onDeleteBranch={handleDeleteRootBranch}
                            {...restProps}
                        />
                    );
                })}
        </div>
    );
};
