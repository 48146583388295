import React from 'react';
import { IFieldEditorProps } from '../ObjectEditor';
import { IEnumItemReference } from '../../../shared/motive/models/TypeDefinition';
import { FieldTypes } from '../../../shared/motive/models/ScriptObjectModel';
import { EnumItemReferenceEditor } from './EnumItemReferenceEditor';
import { EnumItemStringEditor } from './EnumItemStringEditor';
import { useCurrentProjectConfig } from '../../../redux/spaceKeyed/SpaceKeyedHooks';
import { VersionDates } from '../../../util/ScriptUtils';

export const EnumEditor: React.FC<IFieldEditorProps<FieldTypes>> = props => {
    const config = useCurrentProjectConfig();

    const useEnumItemRef = VersionDates.checkUseEnumRef(config?.typeVersionDate);

    const enumField = useEnumItemRef ? (
        <EnumItemReferenceEditor {...props} value={props.value as IEnumItemReference} />
    ) : (
        <EnumItemStringEditor {...props} value={props.value as string} />
    );

    return enumField;
};
