import React from 'react';
import { HeightUsedScrollContainer } from '../../containers/heightUsedScrollContainer';
import { useStyle } from '../../shared/hooks/useStyle';
import { selectOptionStyle, projectManageWrapperStyle, optionIconBox, optionIcon } from './SidebarProjects.style';
import { Icon } from '../../core-ui/icon';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Size } from '../../core-ui/constants/Size';
import { Text } from '../../core-ui/text';
import { ProjectOption } from '../projectManagement/ProjectManagement';
import { useTranslation } from 'react-i18next';

interface ISidebarProjectsProps {
    setOption: (option: ProjectOption) => void;
    option: ProjectOption;
}

export const SidebarProjects: React.FC<ISidebarProjectsProps> = ({ setOption, option }) => {
    const tokens = useStyle();
    const { t } = useTranslation();

    return (
        <HeightUsedScrollContainer>
            <div css={projectManageWrapperStyle(tokens)}>
                <div
                    css={selectOptionStyle(tokens, option === ProjectOption.CREATE)}
                    onClick={() => setOption(ProjectOption.CREATE)}
                >
                    <Icon
                        size={Size.SMALL}
                        css={optionIconBox}
                        iconStyle={optionIcon}
                        icon={IconTypes.PLUS}
                        isCircle={true}
                    ></Icon>
                    <Text size={Size.LARGE}>{t('createProject')}</Text>
                </div>
                <div
                    css={selectOptionStyle(tokens, option === ProjectOption.EXISTING)}
                    onClick={() => setOption(ProjectOption.EXISTING)}
                >
                    <Icon
                        size={Size.SMALL}
                        css={optionIconBox}
                        iconStyle={optionIcon}
                        icon={IconTypes.FOLDER_OPEN}
                        isCircle={true}
                    ></Icon>
                    <Text size={Size.LARGE}>{t('openExistingProject')}</Text>
                </div>
            </div>
        </HeightUsedScrollContainer>
    );
};
