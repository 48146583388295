/** @jsx jsx */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, findIconDefinition, IconName } from '@fortawesome/fontawesome-svg-core';
import {
    baseWrapperStyle,
    smallIconStyle,
    mediumIconStyle,
    largeIconStyle,
    smallWrapperStyle,
    mediumWrapperStyle,
    largeWrapperStyle,
    baseIconStyle,
    smallerWrapperStyle,
    smallerIconStyle,
    largerIconStyle,
    largerWrapperStyle,
    circleStyle,
    hollowVariantStyle,
    ghostVariantStyle,
    clickIconWrapperStyle
} from './Icon.style';
import { jsx, SerializedStyles } from '@emotion/core';
import { IconSize, Size } from '../constants/Size';
import { IToken } from '../../shared/contexts/styleContext';
import { IconTypes } from '../constants/IconTypes';
import { iconMappings } from '../utils/IconMapping';
import { useStyle } from '../../shared/hooks/useStyle';
import { ButtonVariant } from '../button';

export interface IIconProps {
    className?: string;
    icon?: IconTypes;
    faIcon?: IconProp;
    onClick?: () => void;
    size?: IconSize;
    isCircle?: boolean;
    isReadOnly?: boolean;
    wrapperStyle?: SerializedStyles;
    iconStyle?: SerializedStyles;
    variant?: ButtonVariant;
}

const getIconSizeStyle = (size: Size, token: IToken) => {
    switch (size) {
        case Size.SMALLER:
            return smallerIconStyle(token);
        case Size.SMALL:
            return smallIconStyle(token);
        case Size.MEDIUM:
            return mediumIconStyle(token);
        case Size.LARGE:
            return largeIconStyle(token);
        case Size.LARGER:
            return largerIconStyle(token);
        default:
            return mediumIconStyle(token);
    }
};

const getWrapperSizeStyle = (size: Size) => {
    switch (size) {
        case Size.SMALLER:
            return smallerWrapperStyle();
        case Size.SMALL:
            return smallWrapperStyle();
        case Size.MEDIUM:
            return mediumWrapperStyle();
        case Size.LARGE:
            return largeWrapperStyle();
        case Size.LARGER:
            return largerWrapperStyle();
        default:
            return mediumWrapperStyle();
    }
};

const getVariantStyle = (variant: ButtonVariant, tokens: IToken) => {
    switch (variant) {
        case ButtonVariant.HOLLOW:
            return hollowVariantStyle(tokens);
        case ButtonVariant.GHOST:
            return ghostVariantStyle(tokens);
        default:
            return undefined;
    }
};

/**
 * Description of props
 * @props onClick -> a callback function that is executed on click
 * @props className -> used for applying Emotion styling
 * @props size -> the size of the icon
 * @props isCircle -> applies a circular style
 * @props icon -> the icon type
 * @props iconStyle -> override styling for the icon
 * @props wrapperStyle -> override styling for the wrapper
 */

export const Icon: React.FC<IIconProps> = ({
    onClick,
    className,
    size = Size.MEDIUM,
    isCircle,
    icon,
    faIcon,
    iconStyle: iconStyles,
    wrapperStyle,
    variant = ButtonVariant.SOLID
}): React.ReactElement => {
    const token = useStyle();

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        onClick && onClick();
    };

    const appliedWrapperStyle = [
        baseWrapperStyle,
        getWrapperSizeStyle(size),
        isCircle && circleStyle(),
        onClick && clickIconWrapperStyle,
        wrapperStyle
    ];

    const appliedIconStyle = [
        baseIconStyle(),
        getIconSizeStyle(size, token),
        getVariantStyle(variant, token),
        iconStyles
    ];

    const faSize =
        size === Size.LARGER
            ? '2x'
            : size === Size.LARGE
            ? 'lg'
            : size === Size.MEDIUM
            ? '1x'
            : size === Size.SMALL
            ? 'sm'
            : size === Size.INHERIT
            ? '2x'
            : 'xs';

    let toShow: IconProp = 'question';

    if (faIcon) {
        if (typeof faIcon === 'string') {
            const iconDef = findIconDefinition({ iconName: faIcon as IconName, prefix: 'fas' });
            if (iconDef) {
                toShow = faIcon;
            }
        } else {
            toShow = faIcon;
        }
    } else if (icon) {
        toShow = iconMappings[icon] as IconProp;
    }

    return (
        <div className={className} css={[appliedWrapperStyle]} onClick={handleClick}>
            <FontAwesomeIcon size={faSize} css={appliedIconStyle} icon={toShow} />
        </div>
    );
};
