import { useUIStack } from '../../shared/hooks/useUIStack';
import React from 'react';
import { Button } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { navigateHistoryButtonStyle, containerStyle } from './DrawerHistoryButtonsContainer.style';

const HISTORY_CONTAINER_HEIGHT = 32;

export const DrawerHistoryButtonsContainer: React.FC = ({ children }) => {
    const { navigateBack, navigateForwards, canNavigateBack, canNavigateForwards } = useUIStack();

    const canNavBack = canNavigateBack();
    const canNavForward = canNavigateForwards();
    const canNav = canNavForward || canNavBack;

    return (
        <>
            {canNav ? (
                <>
                    <div css={containerStyle(HISTORY_CONTAINER_HEIGHT)}>
                        <Button onClick={navigateBack} css={navigateHistoryButtonStyle} icon={IconTypes.CHEVRON_LEFT} />
                        <Button
                            onClick={navigateForwards}
                            css={navigateHistoryButtonStyle}
                            icon={IconTypes.CHEVRON_RIGHT}
                        />
                    </div>
                    {children}
                </>
            ) : (
                children
            )}
        </>
    );
};
