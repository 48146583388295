import produce from 'immer';
import { combineReducers } from 'redux';
import { ScriptEditorReducer } from './scriptEditor/ScriptEditorReducer';
import { SpaceKeyedStateActionType } from './spaceKeyedStateActions';
import { CatalogsReducer } from './catalog/CatalogsReducer';
import { TextToSpeechReducer } from './textToSpeech/TextToSpeechReducer';
import { CollaboratorReducer } from './collaborator/CollaboratorReducer';
import { ProjectConfigReducer } from './projectConfig/ProjectConfigReducer';
import { ActivityReducer } from './activity/ActivityReducer';
import { CatalogTypesReducer } from './catalogTypes/CatalogTypesReducer';
import { BundleReducer } from './bundle/BundleReducer';
import { MediaReducer } from './media/MediaReducer';
import { EditorInfoReducer } from './editorInfo/EditorInfoReducer';

const spaceKeyReducers = {
    scriptEditor: ScriptEditorReducer,
    bundle: BundleReducer,
    media: MediaReducer,
    textToSpeech: TextToSpeechReducer,
    catalog: CatalogsReducer,
    project: EditorInfoReducer,
    collaborator: CollaboratorReducer,
    projectConfig: ProjectConfigReducer,
    activity: ActivityReducer,
    catalogTypes: CatalogTypesReducer
};

export type SpaceKeyReducerTypes = keyof typeof spaceKeyReducers;

const subReducers = combineReducers(spaceKeyReducers);

const defaultState: IScriptKeyedState = {
    currentSpace: undefined,
    currentProject: undefined,
    lastScript: undefined,
    spaceKeyState: {}
};

export type subReducerTypes = ReturnType<typeof subReducers>;

interface IScriptKeyedState {
    currentSpace: string | undefined;
    currentProject: string | undefined;
    lastScript: string | undefined;
    spaceKeyState: Record<string, subReducerTypes>;
}

export const SpaceKeyedReducer = (state: IScriptKeyedState = defaultState, action: SpaceKeyedStateActionType) => {
    let nextState: IScriptKeyedState;

    switch (action.type) {
        case 'currentProject/set': {
            nextState = { ...state, currentProject: action.project };
            break;
        }
        case 'currentProject/unset': {
            nextState = { ...state, currentProject: undefined };
            break;
        }
        case 'currentSpace/set': {
            nextState = { ...state, currentSpace: action.space };
            break;
        }
        case 'currentSpace/unset': {
            nextState = { ...state, currentSpace: undefined };
            break;
        }
        case 'lastScript/set': {
            nextState = { ...state, lastScript: action.lastScript };
            break;
        }
        case 'lastScript/unset': {
            nextState = { ...state, lastScript: undefined };
            break;
        }
        default: {
            nextState = state;
        }
    }

    if (!!nextState.currentSpace) {
        // We are just pasing the action types through to the subreducers.
        //@ts-ignore
        const space = subReducers(state.spaceKeyState[state.currentSpace], action);

        return {
            ...nextState,
            spaceKeyState: {
                ...nextState.spaceKeyState,
                [nextState.currentSpace]: space
            }
        };
    } else {
        return nextState;
    }
};
