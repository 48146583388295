import { useCurrentSpace } from '../useCurrentSpace';
import { saveScriptNetworkCall } from '../../networking/ScriptService';
import { IScript } from '../../models/Script';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { useDispatch } from 'react-redux';

interface ISaveScriptParams {
    script: IScript;
    overwrite?: boolean;
}
export const useSaveScript = (): IHookProvidedCall<ISaveScriptParams> => {
    const [currentSpace] = useCurrentSpace();
    const catalogDispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const postScript = async (params: ISaveScriptParams) => {
        await triggerCall(
            saveScriptNetworkCall(currentSpace, params.script, getCancelableDispatch(catalogDispatch), params.overwrite)
        );
    };

    return {
        execute: postScript,
        ...rest
    };
};
