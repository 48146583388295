import * as React from 'react';

import { IBaseDrag } from '../Drop/Drop';
import { IAdvancedDragProps, AdvancedDrag } from '../AdvancedDrag/AdvancedDrag';

export interface IEasyDragProps<T extends IBaseDrag> extends Omit<IAdvancedDragProps<T>, 'children'> {
    children?: React.ReactNode;
}

/**
 * Easy Drag makes the entire child component draggable.
 */
export function EasyDrag<T extends IBaseDrag>({ children, ...props }: IEasyDragProps<T>) {
    return (
        <AdvancedDrag<T> {...props}>
            {dragProvided => (
                <div
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                    ref={dragProvided.innerRef}
                    style={{
                        ...dragProvided.draggableProps.style,
                        outline: 'none'
                    }}
                >
                    {children}
                </div>
            )}
        </AdvancedDrag>
    );
}
