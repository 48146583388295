import React, { useState } from 'react';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { useTypeDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { useCurrentLanguageSettings } from '../../shared/motive/stores/editorLocale/EditorLocale';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { getSmartName } from '../../util/ObjectDefinitionsUtils';
import { useStyle } from '../../shared/hooks/useStyle';
import { scriptItemContentStyle, actionButtonsStyle, catalogItemsOptionsWrapper } from './CatalogItemList.style';
import { ButtonVariant, Button } from '../../core-ui/button';
import { Size } from '../../core-ui/constants/Size';
import { useClickOutElement } from '../../shared/hooks/useClickOutElement/useClickOutElement';
import { useRenameScriptCatalogItem } from '../../shared/motive/hooks/useRenameScriptCatalogItem/useRenameScriptCatalogItem';
import { EditableText } from '../../core-ui/editableText';
import { scriptNameStyle, optionMenuRow, optionMenuIcon } from './CatalogScriptItem.style';
import { textPulse, pulse } from '../../constants/AnimationStyles';
import { useDeleteScript } from '../../shared/motive/hooks/useDeleteScript/useDeleteScript';
import { Icon } from '../../core-ui/icon';
import { Text } from '../../core-ui/text';
import { DeleteModal } from '../../core-ui/deleteModal/DeleteModal';
import { useDuplicateScript } from '../../shared/motive/hooks/useDuplicateScript/useDuplicateScript';

interface ICatalogScriptItemProps {
    catalogId: string;
    script: IScriptObjectModel;
}

export const CatalogScriptItem = ({ script, catalogId }: ICatalogScriptItemProps) => {
    const token = useStyle();
    const typeDefs = useTypeDefinitions();
    const language = useCurrentLanguageSettings();

    const renameAction = useRenameScriptCatalogItem();
    const deleteAction = useDeleteScript();
    const duplicateAction = useDuplicateScript();

    const [isRenaming, setRenaming] = useState(false);
    const [isConfirmDeleting, setConfirmDeleting] = useState(false);

    const [showOptions, setOptions] = useState(false);

    const handleClickOutMenu = () => {
        setOptions(false);
    };

    const title = getSmartName(script, typeDefs, language);

    const ref = useClickOutElement<HTMLDivElement>(handleClickOutMenu, true);

    const handleRenameScript = (newName: string) => {
        setRenaming(false);

        if (title !== newName) {
            renameAction.execute({ catalogId, scriptId: script.id, scriptName: newName });
        }
    };

    const handleDeleteScript = () => {
        setConfirmDeleting(false);
        deleteAction.execute({ catalogId: catalogId, scriptId: script.id });
    };

    const handleDuplicateScript = () => {
        duplicateAction.execute({ catalogId: catalogId, scriptId: script.id });
    };

    return (
        <>
            <DeleteModal
                isOpen={isConfirmDeleting}
                onCloseModal={() => setConfirmDeleting(false)}
                onConfirm={handleDeleteScript}
            >
                {`Are you sure you wish to delete the script "${title}"?`}
            </DeleteModal>
            <div css={[scriptItemContentStyle(token), deleteAction.isLoading && pulse]}>
                <div css={scriptNameStyle}>
                    <EditableText
                        disabled={!isRenaming}
                        active={isRenaming}
                        onEditComplete={handleRenameScript}
                        defaultValue={title}
                        textStyle={renameAction.isLoading ? textPulse : undefined}
                    ></EditableText>
                </div>
                <div css={actionButtonsStyle(token)}>
                    <Button
                        variant={ButtonVariant.CIRCULAR}
                        icon={IconTypes.ELLIPSES}
                        onClick={() => {
                            setOptions(true);
                        }}
                    ></Button>
                    {showOptions && (
                        <div ref={ref} css={catalogItemsOptionsWrapper(token)}>
                            <Button
                                size={Size.SMALL}
                                onClick={() => {
                                    setRenaming(true);
                                    setOptions(false);
                                }}
                                variant={ButtonVariant.ACTIONLINK}
                            >
                                <div css={optionMenuRow}>
                                    <Icon css={optionMenuIcon} icon={IconTypes.EDIT} /> <Text>Rename</Text>
                                </div>
                            </Button>
                            <Button
                                size={Size.SMALL}
                                onClick={() => {
                                    handleDuplicateScript();
                                    setOptions(false);
                                }}
                                variant={ButtonVariant.ACTIONLINK}
                            >
                                <div css={optionMenuRow}>
                                    <Icon css={optionMenuIcon} icon={IconTypes.COPY} /> <Text>Duplicate</Text>
                                </div>
                            </Button>
                            <Button
                                size={Size.SMALL}
                                onClick={() => {
                                    setConfirmDeleting(true);
                                    setOptions(false);
                                }}
                                variant={ButtonVariant.ACTIONLINK}
                            >
                                <div css={optionMenuRow}>
                                    <Icon css={optionMenuIcon} icon={IconTypes.DELETE_H} />
                                    <Text>Delete</Text>
                                </div>
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
