import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const scenarioButtonWrapper = css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

export const scenarioButtonStyle = (token: IToken) => css`
    margin-left: ${token.spacings.smaller};
    width: 80px;
`;

export const localizedTitleStyle = ({ colors: { background }, fontSizes }: IToken) => css`
    border: none;
    background-color: ${background.lighter};
    font-size: ${fontSizes.small};
    &:hover {
        background-color: ${background.light};
        border: none;
    }

    &:focus {
        background-color: ${background.light};
        border: none;
    }
`;

export const localizedDescriptionStyle = ({ colors: { background }, fontSizes, spacings }: IToken) => css`
    border: none;
    font-size: ${fontSizes.small};
    padding: ${spacings.smaller};
    background-color: ${background.lighter};

    &:hover {
        background-color: ${background.light};
        border: none;
    }

    &:focus {
        background-color: ${background.light};
        border: none;
        outline: none;
        shadow: none;
    }
`;

export const loadingIconStyle = css`
    padding: 0 !important;
`;
