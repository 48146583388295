import { css } from '@emotion/core';

export const navigateHistoryButtonStyle = css`
    width: unset;
`;

export const containerStyle = (height: number) => css`
    width: 100%;
    height: ${height}px;
    transition: all 0.3s ease;
`;
