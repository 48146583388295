import { IBlendShapeFacialExpression } from '../../shared/motive/models/BlendShapeFacialExpression';
import { BaseFacialExpressionModel } from './BaseFacialExpressionModel';
import React from 'react';

interface IGenericCharacterFacialExpressionProps {
    value?: IBlendShapeFacialExpression;
}

const genericCharacter = '/GenericCharacter_Face.glb';

export const GenericCharacterFacialExpression = ({ value }: IGenericCharacterFacialExpressionProps) => {
    return <BaseFacialExpressionModel value={value} url={genericCharacter} position={[0, -0.2, 0]} scale={[2, 2, 2]} />;
};
