import React, { useState, useMemo } from 'react';
import { IScriptVariable, IScript } from '../../shared/motive/models/Script';
import { ObjectEditor, IObjectEditorProps } from '../objectEditor';
import { useObjectDefinitions, useScriptEditorInfo } from '../../shared/motive/hooks/useScriptEditorInfo';
import { getScriptVariableTypeName, getUniqueVariableName, createNewVariable } from '../../util/ScriptDynamicsUtil';
import { Size } from '../../core-ui/constants/Size';
import {
    flexWrapLayoutStyle,
    editButtonStyle,
    textNoOverflowStyle,
    headingRenderContainerStyle,
    variableObjectWrapperStyle,
    optionsEditorStyle,
    addScriptButtonStyle,
    variableObjectEditorStyle,
    variableOptionsWrapper,
    outerHeadingsAndVarsStyle,
    inputStickyPositionStyle,
    inputStyle,
    headingStyle,
    animateVariableBgStyle,
    variableObjectWrapperBgStyle,
    closeButtonStyle,
    buttonsWrapperStyle
} from './ScriptVariablesOptionsContainer.style';
import { Button, ButtonVariant } from '../../core-ui/button';
import { ScriptActionType } from '../../shared/motive/reducers/ScriptEditorReducers';
import { clone, setWith } from 'lodash-es';
import { Collapsible } from '../../core-ui/collapse';
import { useGetAllCatalogsByType } from '../../shared/motive/hooks/useCatalogs';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { IObjectDefinitionMap } from '../../shared/motive/models/TypeDefinition';
import { Text } from '../../core-ui/text';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { useStyle } from '../../shared/hooks/useStyle';
import { IScriptEditorInfo } from '../../shared/motive/models/ScriptEditorInfo';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { QuickObjectEditor } from '../../components/quickObjectEditor';
import { Logger } from '../../util/logger';
import { contentWrapper } from './ScriptVariablesOptionsContainer.style';
import { IHookProvidedDataSource } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import {
    useGroupedScriptAndGlobalVariables,
    IScriptGlobalVariables
} from '../../shared/motive/hooks/useGroupedScriptAndGlobalVariables/useGroupedScriptAndGlobalVariables';
import { deleteScriptVariable } from '../../redux/spaceKeyed/scriptEditor/ScriptEditorReducer';
import { optionMenuIcon, optionMenuRow } from '../../components/catalogItemList/CatalogScriptItem.style';
import { Icon } from '../../core-ui/icon';
import { InputField } from '../../core-ui/inputField';
import { checkFilter } from '../../util/FilterUtils';
import { HeightUsedScrollContainer } from '../heightUsedScrollContainer';
import { SelectScriptEditorCurrentScript } from '../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { Drop } from '../../components/_common/DragAndDrop/Drop/Drop';
import { CloneDrag } from '../../components/_common/DragAndDrop/CloneDrag/CloneDrag';
import { IObjectInstanceDrag } from '../../components/_common/DragAndDrop/DragModels/DragModels';
import { DRAG_ITEM_TYPE_SCRIPT_VARIABLE, DROPPABLE_ID_VARIABLES_PANEL } from '../../constants/DragAndDrop';
import { frameToolPanelLayoutStyle } from '../../components/frameToolsPanel/FrameToolsPanel.style';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IScriptVariableOptionCollectionProps extends IScriptVariable {
    subScriptPath: string;
    objectDefinitions: IObjectDefinitionMap;
    currentScript?: IScript;
    scripts?: (IScript | undefined)[];
    onChange?: IObjectEditorProps['onChange'];
    variableRef?: React.RefObject<HTMLDivElement>;
}

interface IScriptVariablesOptionsContainerPrivateProps {
    script: IScript;
    scriptEditorInfo: IHookProvidedDataSource<IScriptEditorInfo>;
    variableId?: string;
}

const ScriptVariablesOptionsContainerPrivate = React.memo(
    function ScriptVariablesOptionsContainerPrivate(props: IScriptVariablesOptionsContainerPrivateProps) {
        const { script, variableId } = props;
        const tokens = useStyle();
        const scriptDispatch = useDispatch();
        const objectDefinitions = useObjectDefinitions();
        const { data } = useGetAllCatalogsByType(MotiveTypes.MOTIVE_SCRIPT);
        const [isAddingItem, setIsAddingItem] = useState(false);
        const [itemFilter, setItemFilter] = useState('');

        const token = useStyle();

        const handleAddNewItem = (item: IScriptObjectModel) => {
            const scriptVar = item as IScriptVariable;

            if (!scriptVar.name || !scriptVar.valueDefinition) {
                Logger.error('Must set name or value definition');
            }
            scriptVar.name = getUniqueVariableName(script.variables, scriptVar.name);

            const variables = script.variables ?? [];
            const action = {
                type: ScriptActionType.UPDATE_SCRIPT_VARIABLES,
                variables: [...variables, scriptVar]
            };
            scriptDispatch(action);

            setIsAddingItem(false);
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const scripts = useMemo(() => (data as ICatalog<IScript>[]).flatMap(cat => cat.items?.map(item => item)), [
            data
        ]);

        const projectVariables = useGroupedScriptAndGlobalVariables();

        const globalVars = useMemo(() => {
            if (!itemFilter) return projectVariables.scriptGlobalVariables;

            const globals: IScriptGlobalVariables[] = [];

            projectVariables.scriptGlobalVariables.forEach(gv => {
                const vars = gv.globalVariables.filter(v => checkFilter(v.name, itemFilter));

                if (vars && vars.length > 0) {
                    globals.push({
                        globalVariables: vars,
                        scriptInfo: gv.scriptInfo
                    });
                }
            });

            return globals;
        }, [projectVariables, itemFilter]);

        const scriptVars = useMemo(() => {
            if (!itemFilter) return script.variables;

            return script.variables?.filter(v => checkFilter(v.name, itemFilter));
        }, [script, itemFilter]);

        const variableRef = useMemo(() => {
            return React.createRef<HTMLDivElement>();
        }, [scriptVars, globalVars, variableId]);

        const handleChange: IObjectEditorProps['onChange'] = (path, value) => {
            const variables = setWith(clone(script.variables ?? []), path, value, clone);
            const action = {
                type: ScriptActionType.UPDATE_SCRIPT_VARIABLES,
                variables
            };
            scriptDispatch(action);
        };

        const renderScriptVar = (scriptVar: IScriptVariable, scriptVarIndex: number) => (
            <CloneDrag<IObjectInstanceDrag>
                key={scriptVar.id}
                drag={{
                    dragType: DRAG_ITEM_TYPE_SCRIPT_VARIABLE,
                    objectId: scriptVar.id,
                    objectType: scriptVar.type
                }}
                index={scriptVarIndex}
            >
                <ScriptVariableOptionCollection
                    {...scriptVar}
                    key={scriptVar.id}
                    currentScript={script}
                    scripts={scripts}
                    objectDefinitions={objectDefinitions}
                    subScriptPath={`${scriptVarIndex}`}
                    onChange={handleChange}
                    variableRef={scriptVar.id === variableId ? variableRef : undefined}
                />
            </CloneDrag>
        );

        const renderGlobalVar = (scriptVar: IScriptVariable, scriptVarIndex: number) => (
            <CloneDrag<IObjectInstanceDrag>
                key={scriptVar.id}
                drag={{
                    dragType: DRAG_ITEM_TYPE_SCRIPT_VARIABLE,
                    objectId: scriptVar.id,
                    objectType: scriptVar.type
                }}
                index={scriptVarIndex}
            >
                <ScriptVariableOptionCollection
                    {...scriptVar}
                    currentScript={script}
                    scripts={scripts}
                    objectDefinitions={objectDefinitions}
                    subScriptPath={`${scriptVarIndex}`}
                    variableRef={scriptVar.id === variableId ? variableRef : undefined}
                />
            </CloneDrag>
        );

        const renderScriptGlobalVariables = (scriptVar: IScriptGlobalVariables) => (
            <div key={scriptVar.scriptInfo.id}>
                <Text size={Size.LARGER}>Script: {scriptVar.scriptInfo.name}</Text>
                {scriptVar.globalVariables.map(renderGlobalVar)}
            </div>
        );

        return (
            <div css={frameToolPanelLayoutStyle}>
                <div css={inputStickyPositionStyle(tokens)}>
                    <InputField css={inputStyle} onChange={setItemFilter} size={Size.MEDIUM} />
                </div>
                <HeightUsedScrollContainer scrollRef={variableRef}>
                    <Drop
                        dropId={DROPPABLE_ID_VARIABLES_PANEL}
                        dragType={DRAG_ITEM_TYPE_SCRIPT_VARIABLE}
                        isDisabled={true}
                    >
                        <div css={outerHeadingsAndVarsStyle(tokens)}>
                            <div css={contentWrapper} key={'script'}>
                                <Text size={Size.LARGER} css={headingStyle}>
                                    Script Variables
                                </Text>
                                {scriptVars?.map(renderScriptVar)}
                                {isAddingItem ? (
                                    <div>
                                        <QuickObjectEditor
                                            onSave={handleAddNewItem}
                                            onCancel={() => setIsAddingItem(false)}
                                            type={MotiveTypes.SCRIPT_VARIABLE}
                                            css={variableObjectEditorStyle(token)}
                                            requiredFields={['name', 'valueDefinition']}
                                        />
                                    </div>
                                ) : (
                                    <Button
                                        variant={ButtonVariant.SOLID}
                                        size={Size.SMALL}
                                        icon={IconTypes.PLUS}
                                        css={addScriptButtonStyle(token)}
                                        onClick={() => setIsAddingItem(true)}
                                    >
                                        Add New
                                    </Button>
                                )}
                            </div>
                            <div css={contentWrapper} key={'globals'}>
                                <Text size={Size.LARGER} css={headingStyle}>
                                    Global Variables
                                </Text>
                                {globalVars?.map(renderScriptGlobalVariables)}
                            </div>
                        </div>
                    </Drop>
                </HeightUsedScrollContainer>
            </div>
        );
    },
    (lastProps, nextProps) => {
        return (
            lastProps.scriptEditorInfo === nextProps.scriptEditorInfo &&
            lastProps.script.variables === nextProps.script.variables
        );
    }
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IScriptVariablesOptionsContainerProps {
    variableId?: string;
}

export const ScriptVariablesOptionsContainer: React.FC<IScriptVariablesOptionsContainerProps> = props => {
    const script = useSelector(SelectScriptEditorCurrentScript);
    const scriptEditorInfo = useScriptEditorInfo();

    return (
        <ScriptVariablesOptionsContainerPrivate
            script={script}
            scriptEditorInfo={scriptEditorInfo}
            variableId={props.variableId}
        />
    );
};

const ScriptVariableOptionCollection: React.FC<IScriptVariableOptionCollectionProps> = ({
    objectDefinitions,
    variableRef,
    ...restProps
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const scriptVariable: IScriptVariable = restProps;
    const { name, id } = scriptVariable;
    const smartTypeName = getScriptVariableTypeName(scriptVariable, objectDefinitions);
    const script = useSelector(SelectScriptEditorCurrentScript);

    const scriptDispatch = useDispatch();

    const currentScriptOwns = script && script.variables?.find(scriptVar => scriptVar.id === id);

    const isReadOnly = !currentScriptOwns || !isEditing;

    // let owningScript: IScript | undefined = currentScript;
    // if (!currentScriptOwns) {
    //     owningScript = scripts?.find(script => script?.variables?.find(scriptVar => scriptVar.id === id)) ?? undefined;
    // }
    const token = useStyle();

    const handleDelete = () => {
        scriptDispatch(deleteScriptVariable(id));
    };

    const handleCopy = () => {
        if (script) {
            const newVar = createNewVariable(
                script,
                scriptVariable.valueDefinition,
                scriptVariable.name,
                scriptDispatch
            );

            newVar.isGlobal = scriptVariable.isGlobal;
            newVar.isInput = scriptVariable.isInput;
            newVar.isNetwork = scriptVariable.isNetwork;
            newVar.isOutput = scriptVariable.isOutput;
        }
    };

    return (
        <>
            <div
                ref={variableRef}
                css={[
                    variableObjectWrapperStyle(token, isEditing),
                    variableRef ? animateVariableBgStyle(token) : variableObjectWrapperBgStyle
                ]}
            >
                <div css={headingRenderContainerStyle}>
                    <Text css={textNoOverflowStyle} size={Size.LARGE}>
                        {name}
                    </Text>
                    {restProps.onChange && (
                        <>
                            <div css={buttonsWrapperStyle}>
                                {isEditing && (
                                    <Button
                                        variant={ButtonVariant.CIRCULAR}
                                        size={Size.SMALL}
                                        icon={IconTypes.TIMES}
                                        css={closeButtonStyle}
                                        onClick={() => {
                                            setIsEditing(false);
                                            setShowMenu(false);
                                        }}
                                    />
                                )}
                                <Button
                                    variant={ButtonVariant.CIRCULAR}
                                    icon={currentScriptOwns ? IconTypes.ELLIPSES : IconTypes.SCRIPT}
                                    css={editButtonStyle}
                                    size={Size.MEDIUM}
                                    onClick={() => setShowMenu(!showMenu)}
                                />
                            </div>
                            {showMenu && (
                                <div css={variableOptionsWrapper(token)}>
                                    <Button
                                        size={Size.SMALL}
                                        onClick={() => {
                                            setIsEditing(true);
                                            setShowMenu(false);
                                        }}
                                        variant={ButtonVariant.ACTIONLINK}
                                    >
                                        <div css={optionMenuRow}>
                                            <Icon css={optionMenuIcon} icon={IconTypes.EDIT} /> <Text>Edit</Text>
                                        </div>
                                    </Button>
                                    <Button
                                        size={Size.SMALL}
                                        onClick={() => {
                                            setIsEditing(false);
                                            setShowMenu(false);
                                            handleDelete();
                                        }}
                                        variant={ButtonVariant.ACTIONLINK}
                                    >
                                        <div css={optionMenuRow}>
                                            <Icon css={optionMenuIcon} icon={IconTypes.DELETE} /> <Text>Delete</Text>
                                        </div>
                                    </Button>
                                    <Button
                                        size={Size.SMALL}
                                        onClick={() => {
                                            setIsEditing(false);
                                            setShowMenu(false);
                                            handleCopy();
                                        }}
                                        variant={ButtonVariant.ACTIONLINK}
                                    >
                                        <div css={optionMenuRow}>
                                            <Icon css={optionMenuIcon} icon={IconTypes.COPY} /> <Text>Duplicate</Text>
                                        </div>
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div css={flexWrapLayoutStyle(token)}>
                    <Text size={Size.SMALL}>{smartTypeName}</Text>
                </div>
                {/* <div css={flexWrapLayoutStyle}>
                    <Text>Script </Text>
                    <Text css={inlineStyle} size={Size.MEDIUM}>
                        {owningScript?.name}
                    </Text>
                </div> */}

                {restProps.onChange && (
                    <Collapsible isShowing={isEditing}>
                        <div title={'Options'} css={optionsEditorStyle(token)}>
                            {currentScriptOwns && (
                                <ObjectEditor
                                    path={restProps.subScriptPath}
                                    onChange={restProps.onChange}
                                    value={scriptVariable}
                                    type={scriptVariable.type}
                                    hideFields={['valueDefinition']}
                                    isReadOnly={isReadOnly}
                                />
                            )}
                            {!currentScriptOwns && (
                                <>
                                    Go to script to edit this variables options?
                                    <Button>Go to script</Button>
                                    <Button>Cancel</Button>
                                </>
                            )}
                        </div>
                    </Collapsible>
                )}
            </div>
        </>
    );
};
