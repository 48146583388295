import React from 'react';
import { LocalizationKeys } from '../../localization/LocalizationKeys';
import { LocalizationText } from '../../localization/Localization';
import { LocaleContext } from '../../shared/motive/stores/locale';
import { useStore } from '../../shared/hooks/useStore/useStore';

/**
 * Renders localized text for the current culture key.
 * Otherwise renders node children.
 * @param props
 */
export const LocalizedText: React.FC<{ textKey: LocalizationKeys }> = (props): React.ReactElement => {
    const { language } = useStore(LocaleContext);

    const text: string | undefined = LocalizationText(language, props.textKey);

    return <>{text ? text : props.children}</>;
};
