import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const titleStyle = () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const enumListParentStyle = (token: IToken) => css`
    padding: 0 ${token.spacings.small} 0 ${token.spacings.small};
`;

export const enumListStyle = (token: IToken) => css`
    padding-left: ${token.spacings.small};
`;

export const enumItemListStyle = (token: IToken) => css`
    padding-left: ${token.spacings.small};
`;

export const importedEnumItemStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const spaceEnumItemStyle = css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const iconAndNameStyle = css`
    width: 55%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const checkboxAndButtonStyle = css`
    display: flex;
    flex-diection: row;
    align-items: center;
    justify-content: space-between;
`;

export const checkboxStyle = css`
    border-color: #5a5e66;
`;

export const editableTextWrapperStyle = () => css`
    border: none;
    outline: none;
    &:hover {
        outline: none;
        border: none;
    }
`;

export const editableTextTextStyle = css`
    font-size: 1em;
    &:focus {
        font-size: 1em;
        box-shadow: 0px 0px 8px -4px #6bbaba;
    }
`;

export const disabledButtonStyle = css`
    color: #999;
    background-color: transparent;
`;
