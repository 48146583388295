import * as React from 'react';
import { IBaseDrag } from '../Drop/Drop';
import { Draggable, DraggableProps, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { getDraggableId } from '../DragAndDropProvider/DragAndDropProvider';

interface IPrivateAdvancedDragProps<T extends IBaseDrag> extends Omit<DraggableProps, 'draggableId' | 'children'> {
    drag: T;
    children?: (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => React.ReactElement;
    className?: string;
    onClick?: () => void;
    onDoubleClick?: () => void;
}

export type IAdvancedDragProps<T extends IBaseDrag> = Omit<IPrivateAdvancedDragProps<T>, 'children'>;

/**
 * The AdvancedDrag class is then base class for all Drag components. It takes a prop "drag" of type T and sends this data when a drag action has occurred
 * It is not recommended to use this class directly, treat it like an abstract component for other Drag components
 *
 * NOTE: ALL DRAGS MUST BE USED WITHIN A DROP
 */
export function AdvancedDrag<T extends IBaseDrag>({
    drag,
    className,
    onClick,
    onDoubleClick,
    children,
    ...props
}: IPrivateAdvancedDragProps<T>) {
    return (
        <Draggable
            {...props}
            draggableId={getDraggableId(drag)}
            key={getDraggableId(drag)}
            index={props.index}
            type={drag.dragType}
        >
            {(dragProvided, snapshot) => (
                <>
                    {children && children(dragProvided, snapshot)}
                    {dragProvided.placeholder}
                </>
            )}
        </Draggable>
    );
}
