import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';
import chroma from 'chroma-js';

export const accordianContainerStyle = (token: IToken) => css`
    //background: ${token.colors.background.lighter};
    padding: ${token.spacings.smaller};
`;

export const accordianHeaderStyle = (token: IToken) => css`
    color: white;
    background-color: ${chroma(token.colors.bar.catalogBarHeader)
        .alpha(0.8)
        .css()};
}

    padding: ${token.spacings.smaller};
    border: none;
    display: flex;
    &:hover {
        cursor: pointer;
        background: ${token.colors.interactible.highlight};
    }
`;
export const accordianPanelStyle = (token: IToken) => css`
    background-color: ${token.colors.background.lighter};
    font-size: ${token.fontSizes.medium};
`;

export const accordionTextStyle = css`
    margin-left: 5px;
    font-size: 1rem;
`;
