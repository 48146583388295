/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { IFieldEditorProps } from '../ObjectEditor';
import { IDropdownOption, Dropdown } from '../../../core-ui/dropdown/Dropdown';
import {
    getAllFramesFilteredByResourceTypes,
    getAllFramesWithParent,
    IDepthAndParentFrame
} from '../../../util/FrameOperationUtils';
import { useTypeDefinition } from '../../../shared/motive/hooks/useScriptEditorInfo';
import {
    IObjectDefinition,
    ITypeDefinitionMap,
    IObjectDefinitionMap
} from '../../../shared/motive/models/TypeDefinition';
import { IFrame, IScript } from '../../../shared/motive/models/Script';
import { IObjectReference } from '../../../shared/motive/models/ObjectReference';
import { removeFieldArrayItem, findFrame, findOrAddFrameByName, addResource } from '../../../util/ScriptUtils';
import { getSmartName } from '../../../util/ObjectDefinitionsUtils';
import {
    useObjectTypeAndImplementors,
    useObjectDefinitions
} from '../../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { MotiveTypes } from '../../../constants/MotiveTypes';
import { Button, ButtonVariant } from '../../../core-ui/button';
import { IconTypes } from '../../../core-ui/constants/IconTypes';
import { useDashboardDrawer } from '../../../hooks/useDashboardDrawer';
import { Pill } from '../../../core-ui/pill';
import { Size } from '../../../core-ui/constants/Size';
import { Text } from '../../../core-ui/text';
import { useStyle } from '../../../shared/hooks/useStyle';
import {
    pillWrapperStyle,
    pillIconStyle,
    pillTextStyle,
    flexStyle,
    buttonStyle,
    pillContainerStyle
} from './ExternalScriptEditor.style';
import { IScriptObjectModel } from '../../../shared/motive/models/ScriptObjectModel';
import { createObjectReferenceFromObject, createScriptObject } from '../../../util/MotiveUtils';
import { ISceneWorldObject } from '../../../shared/motive/models/SceneWorldObject';
import { ScriptActionType, IScripCustomUpdateAction } from '../../../shared/motive/reducers/ScriptEditorReducers';
import { ExternalGlobalEditorFieldSelectContainer } from '../externalGlobalEditor/ExternalGlobalEditor';

import {
    CatalogItemResolver,
    useCatalogItemResolver
} from '../../../shared/motive/hooks/useCatalogs/useCatalogItem/useCatalogItem';
import { ILanguageSettings, useCurrentLanguageSettings } from '../../../shared/motive/stores/editorLocale/EditorLocale';
import { ICatalog } from '../../../shared/motive/models/Catalog';
import { useDispatch, useSelector } from 'react-redux';
import { SelectCatalogsWithTypeFilter } from '../../../redux/spaceKeyed/catalog/CatalogSelectors';
import {
    SelectScriptEditorCurrentScript,
    SelectScriptEditorSelectedFrameId
} from '../../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';

interface IScriptResourceInfo {
    dropOptions: IDropdownOption[];
    objLabelMap: { [key: string]: string };
    objModelMap: { [key: string]: IScriptObjectModel };
}

export const generateResourceDropOptions = (
    frames: IFrame[],
    typeDefs: ITypeDefinitionMap,
    language: ILanguageSettings,
    catalogItemResolver: CatalogItemResolver,
    script: IScript
): IScriptResourceInfo => {
    const dropOptions: IDropdownOption[] = [];
    const objLabelMap: { [key: string]: string } = {};
    const objModelMap: { [key: string]: IScriptObjectModel } = {};

    dropOptions.push({
        label: '-',
        value: undefined
    });
    frames.forEach((frame: IFrame): void => {
        if (frame.resources) {
            frame.resources.forEach(wrapper => {
                const label = wrapper.label
                    ? wrapper.label
                    : getSmartName(wrapper.resource, typeDefs, language, catalogItemResolver, script);
                objLabelMap[wrapper.resource.id] = label;
                objModelMap[wrapper.resource.id] = wrapper.resource;

                dropOptions.push({
                    label: label,
                    group: `${frame.name} (${frame.id})`,
                    value: wrapper.resource.id as string
                });
            });
        }
    });

    return {
        dropOptions,
        objLabelMap,
        objModelMap
    };
};

const generateScriptEventDropOptions = (script: IScript, scriptCatalogs: ICatalog<IScript>[]): IScriptResourceInfo => {
    const dropOptions: IDropdownOption[] = [];
    const objLabelMap: { [key: string]: string } = {};
    const objModelMap: { [key: string]: IScriptObjectModel } = {};

    dropOptions.push({
        label: '',
        value: undefined
    });

    script.events?.forEach((event): void => {
        dropOptions.push({
            label: `${event.name} (${event.id})`,
            // group: `${groupDepthStr}${frame.parentFrame?.name}`,
            value: event.id
        });
        objLabelMap[event.id] = event.name;
        objModelMap[event.id] = event;
    });

    scriptCatalogs?.forEach(c => {
        c.items?.forEach(s => {
            if (s.id === script.id) return;

            s.events
                ?.filter(e => e.isGlobal)
                .forEach(e => {
                    dropOptions.push({
                        group: s.name,
                        label: `${e.name} (${e.id})`,
                        // group: `${groupDepthStr}${frame.parentFrame?.name}`,
                        value: e.id
                    });

                    objLabelMap[e.id] = `[${s.name}] ${e.name}`;
                    objModelMap[e.id] = e;
                });
        });
    });

    return {
        dropOptions,
        objLabelMap,
        objModelMap
    };
};

const generateFrameDropOptions = (frames: IDepthAndParentFrame[]): IScriptResourceInfo => {
    const dropOptions: IDropdownOption[] = [];
    const objLabelMap: { [key: string]: string } = {};
    const objModelMap: { [key: string]: IScriptObjectModel } = {};

    dropOptions.push({
        label: '',
        value: undefined
    });
    frames.forEach((frame): void => {
        dropOptions.push({
            label: `${frame.name} (${frame.id})`,
            // group: `${groupDepthStr}${frame.parentFrame?.name}`,
            value: frame.id as string
        });
        objLabelMap[frame.id] = frame.name;
        objModelMap[frame.id] = frame;
    });

    return {
        dropOptions,
        objLabelMap,
        objModelMap
    };
};

export type ExternalScriptEditorValueType = IObjectReference | IObjectReference[];

const addNewSceneObject = (
    script: IScript,
    selectedItem: IScriptObjectModel,
    typeDefs: IObjectDefinitionMap
): {
    script: IScript;
    resource: IScriptObjectModel;
    frame: IFrame | undefined;
} => {
    const { script: newScript1, frame: newFrame } = findOrAddFrameByName(script, 'Scene Objects');

    // Do a quick check to see if this object already exists
    const currResource = newFrame?.resources?.find(r => {
        const obj = r.resource as ISceneWorldObject;

        if (obj.type === MotiveTypes.SCENE_WORLD_OBJECT && obj?.namedWorldObject?.id === selectedItem.id) {
            return true;
        }
    });

    if (currResource) return { script, resource: currResource.resource, frame: newFrame };

    const resource = createScriptObject<ISceneWorldObject>(MotiveTypes.SCENE_WORLD_OBJECT, typeDefs);

    resource.namedWorldObject = selectedItem;

    const { script: newScript2 } = addResource(newScript1, newFrame.id, resource);

    const newFrame2 = findFrame(newScript2.rootFrame, newFrame.id);

    return { script: newScript2, resource, frame: newFrame2 };
};

enum QuickCreateType {
    SceneObject = 'quickCreate.sceneObject'
}

interface IQuickCreateInfo {
    catalogType: string;
}

interface IQuickCreateLookupMap {
    [createType: string]: IQuickCreateInfo;
}

const QuickCreateLookup: IQuickCreateLookupMap = {
    [QuickCreateType.SceneObject]: {
        catalogType: MotiveTypes.NAMED_WORLD_OBJECT
    }
};

// Note: all script references should be ID only, although this isn't enforced by the back end.
export const ExternalScriptEditor: React.FC<IFieldEditorProps<ExternalScriptEditorValueType>> = fieldProps => {
    const { implementors } = useTypeDefinition<IObjectDefinition>(fieldProps.type);
    const { handleSelectExternalScriptScopeField } = useDashboardDrawer();
    const language = useCurrentLanguageSettings();
    const typeDefs = useObjectDefinitions();
    const token = useStyle();
    const allowedTypes = useObjectTypeAndImplementors(fieldProps.type);
    const allowAllTypes = fieldProps.type === MotiveTypes.OBJECT;
    const [quickCreateSelectType, setQuickCreateSelectType] = useState<string | undefined>(undefined);
    const script = useSelector(SelectScriptEditorCurrentScript);
    const selectedFrameId = useSelector(SelectScriptEditorSelectedFrameId);
    const scriptDispatch = useDispatch();
    const catItemResolver = useCatalogItemResolver();
    const scriptCatalogs = useSelector(SelectCatalogsWithTypeFilter([MotiveTypes.MOTIVE_SCRIPT]));

    const frame = script.rootFrame && findFrame(script.rootFrame, selectedFrameId);

    const { dropOptions, objLabelMap, objModelMap } = useMemo<IScriptResourceInfo>(() => {
        const allTypes = implementors ? [...implementors, fieldProps.type] : [fieldProps.type];
        if (allTypes.some(type => type === MotiveTypes.FRAME)) {
            return generateFrameDropOptions(getAllFramesWithParent(script));
        } else if (fieldProps.type === MotiveTypes.SCRIPT_EVENT_DEFINITION) {
            return generateScriptEventDropOptions(script, scriptCatalogs as ICatalog<IScript>[]);
        }

        const validFrames = getAllFramesFilteredByResourceTypes(script, allTypes);
        const opts = generateResourceDropOptions(validFrames, typeDefs, language, catItemResolver, script);

        if (fieldProps.type === MotiveTypes.WORLD_OBJECT || fieldProps.type === MotiveTypes.WORLD_ANCHOR) {
            opts.dropOptions.push({
                label: 'ADD SCENE OBJECT',
                value: QuickCreateType.SceneObject
            });
        }

        return opts;
    }, [script, implementors, fieldProps.type, typeDefs, language]);

    // const ensureNewTypeIsValid: (newValueType: string) => boolean = newValueType => {
    //     const allTypes = implementors ? [...implementors, fieldProps.type] : [fieldProps.type];
    //     if (!allTypes.find(type => type === newValueType || type === MotiveTypes.OBJECT)) {
    //         Logger.error(`found invalid type selected: ${newValueType}`);
    //         return false;
    //     }

    //     return true;
    // };

    const handleChange = (newValue: IScriptObjectModel | undefined) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!newValue || !(allowAllTypes || allowedTypes.includes(newValue.type))) {
            fieldProps.onChange(undefined);

            return;
        }

        const useIdOnly = fieldProps.fieldDefinition.useIdOnly;
        const isArray = fieldProps.fieldDefinition.isArray;

        const newFieldVal = useIdOnly ? createObjectReferenceFromObject(newValue, typeDefs, language) : newValue;

        if (isArray) {
            const curr = fieldProps.value ? [...(fieldProps.value as any[])] : [];

            curr.push(newFieldVal);

            fieldProps.onChange(curr);
        } else {
            fieldProps.onChange(newFieldVal);
        }
    };

    const handleSelectEnd = (selectedItem: IScriptObjectModel | undefined) => {
        const createType = quickCreateSelectType;

        setQuickCreateSelectType(undefined);

        if (selectedItem) {
            if (createType === QuickCreateType.SceneObject) {
                const { script: newScript, resource } = addNewSceneObject(script, selectedItem, typeDefs);

                const action: IScripCustomUpdateAction = {
                    type: ScriptActionType.SCRIPT_UPDATE_CUSTOM,
                    update: () => {
                        return newScript;
                    }
                };

                scriptDispatch(action);

                handleChange(resource);
            }
        }

        return;
    };

    const handleHover = (resourceId?: string) => {
        scriptDispatch({
            type: ScriptActionType.SIGNAL_RESOURCE,
            externalHighlight: { resourceId: resourceId }
        });
    };

    const quickCreateInfo = quickCreateSelectType ? QuickCreateLookup[quickCreateSelectType] : undefined;

    // if (quickCreateSelectType) {
    //     const info = QuickCreateLookup[quickCreateSelectType];

    //     if (info?.catalogType) {
    //         return (
    //             <ExternalGlobalEditorFieldSelectContainer
    //                 {...fieldProps}
    //                 type={info.catalogType}
    //                 onSelectEnd={handleSelectEnd}
    //             />
    //         );
    //     }
    // }

    const handleClickPick = () => {
        const allTypes = implementors ? [...implementors, fieldProps.type] : [fieldProps.type];
        handleSelectExternalScriptScopeField(allTypes, { ...fieldProps, frame: frame }, handleChange);
    };

    if (fieldProps.fieldDefinition.isArray) {
        const elems = [];

        const array = fieldProps.value as IObjectReference[];
        const addItem = (changedValue: string) => {
            if (!changedValue || (array && array.some(val => val.objectId === changedValue))) {
                return;
            }

            if (changedValue === QuickCreateType.SceneObject) {
                setQuickCreateSelectType(QuickCreateType.SceneObject);

                return;
            }

            const obj = objModelMap[changedValue];

            handleChange(obj);
        };

        if (fieldProps.value) {
            if (array.length > 0) {
                elems.push(
                    <div css={pillContainerStyle} key="pillContainer">
                        {array.map((item, index) => (
                            <div key={`${index}.${item.objectId}`} css={pillWrapperStyle(token)}>
                                <Pill
                                    iconStyle={pillIconStyle}
                                    size={Size.SMALL}
                                    onClickClose={() => {
                                        removeFieldArrayItem(fieldProps.onChange, array, index);
                                        handleHover();
                                    }}
                                    onMouseEnter={() => handleHover(item.objectId)}
                                    onMouseLeave={() => handleHover()}
                                >
                                    <Text size={Size.SMALL} css={pillTextStyle}>
                                        {objLabelMap[array[index].objectId] ?? item.objectId}
                                    </Text>
                                    {fieldProps.type === MotiveTypes.SCRIPT_EVENT_DEFINITION && (
                                        <Button
                                            icon={IconTypes.EYE}
                                            variant={ButtonVariant.HOLLOW}
                                            title="Show event's origin"
                                            size={Size.SMALL}
                                            iconStyle={pillIconStyle}
                                            onClick={() =>
                                                fieldProps.parentObjectEditorProps.onClickSetEventId &&
                                                fieldProps.parentObjectEditorProps.onClickSetEventId(item.objectId)
                                            }
                                        />
                                    )}
                                </Pill>
                            </div>
                        ))}
                    </div>
                );
            }
        }

        elems.push(
            <div css={flexStyle} key={'picker'}>
                {quickCreateInfo?.catalogType ? (
                    <ExternalGlobalEditorFieldSelectContainer
                        {...fieldProps}
                        type={quickCreateInfo.catalogType}
                        onSelectEnd={handleSelectEnd}
                    />
                ) : (
                    <Dropdown key="dropdown" options={dropOptions} grouped={true} onChange={addItem} />
                )}
                <Button
                    css={buttonStyle(token)}
                    size={Size.SMALL}
                    icon={IconTypes.BULLSEYE_POINTER}
                    variant={ButtonVariant.CIRCULAR}
                    onClick={handleClickPick}
                />
            </div>
        );

        return <React.Fragment key={fieldProps.fieldDefinition.name}>{elems}</React.Fragment>;
    } else {
        const fieldVal = fieldProps.value as IObjectReference | undefined;
        const handleClickDelete = () => {
            fieldProps.onChange(undefined);
        };

        const selectItem = (changedValue: string) => {
            if (changedValue === QuickCreateType.SceneObject) {
                setQuickCreateSelectType(QuickCreateType.SceneObject);

                return;
            }

            const obj = objModelMap[changedValue];

            handleChange(obj);
        };

        return (
            <React.Fragment key={fieldProps.fieldDefinition.name}>
                {quickCreateInfo?.catalogType ? (
                    <ExternalGlobalEditorFieldSelectContainer
                        {...fieldProps}
                        type={quickCreateInfo.catalogType}
                        onSelectEnd={handleSelectEnd}
                    />
                ) : (
                    <>
                        {fieldVal && (
                            <div css={pillContainerStyle}>
                                <Pill
                                    iconStyle={pillIconStyle}
                                    onClickClose={() => {
                                        handleClickDelete();
                                        handleHover();
                                    }}
                                    onMouseEnter={() => handleHover(fieldVal.objectId)}
                                    onMouseLeave={() => handleHover()}
                                    size={Size.SMALL}
                                >
                                    <Text size={Size.SMALL} css={pillTextStyle}>
                                        {objLabelMap[fieldVal.objectId] ?? fieldVal.objectId}
                                    </Text>
                                    {fieldProps.type === MotiveTypes.SCRIPT_EVENT_DEFINITION && (
                                        <Button
                                            icon={IconTypes.EYE}
                                            variant={ButtonVariant.HOLLOW}
                                            title="Show event's origin"
                                            size={Size.SMALL}
                                            iconStyle={pillIconStyle}
                                            onClick={() =>
                                                fieldProps.parentObjectEditorProps.onClickSetEventId &&
                                                fieldProps.parentObjectEditorProps.onClickSetEventId(fieldVal.objectId)
                                            }
                                        />
                                    )}
                                </Pill>
                            </div>
                        )}
                        {!fieldVal && (
                            <div css={flexStyle}>
                                <Dropdown
                                    key="dropdown"
                                    options={dropOptions}
                                    grouped={true}
                                    onChange={v => selectItem(v)}
                                />
                                <Button
                                    css={buttonStyle(token)}
                                    size={Size.SMALL}
                                    icon={IconTypes.BULLSEYE_POINTER}
                                    variant={ButtonVariant.CIRCULAR}
                                    onClick={handleClickPick}
                                />
                            </div>
                        )}
                    </>
                )}
            </React.Fragment>
        );
    }
};
