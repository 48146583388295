import { isEqual } from 'lodash-es';

export const UseStaleWhileFetchingStrategy = false;
const MOTIVE_CACHE_NAME = 'MOTIVE_CACHE';

// eslint-disable-next-line no-restricted-globals
const IsBrowserCacheEnabled = 'caches' in self;

export const ClearCachedData = async () => {
    if (IsBrowserCacheEnabled) {
        caches.delete(MOTIVE_CACHE_NAME);
    } else {
        sessionStorage.clear();
    }
};

const StoreItem = async <K>(url: string, item: K): Promise<void> => {
    IsBrowserCacheEnabled ? StoreItemInBrowserCache(url, item) : StoreItemInSessionStorage(url, item);
};

const RetrieveItem = async <K>(url: string): Promise<K | null | undefined> => {
    return IsBrowserCacheEnabled ? RetrieveItemFromBrowserCache(url) : RetrieveItemInSessionStorage(url);
};

const DeleteItem = async (url: string) => {
    IsBrowserCacheEnabled ? DeleteItemFromBrowserCache(url) : DeleteItemFromSessionStorage(url);
};

const DeleteItemFromSessionStorage = async (url: string) => {
    sessionStorage.removeItem(url);
};

const DeleteItemFromBrowserCache = async (url: string) => {
    const cache = await caches.open(MOTIVE_CACHE_NAME);
    await cache.delete(new Request(url));
};

const RetrieveItemFromBrowserCache = async <K>(url: string): Promise<K | null | undefined> => {
    const cache = await caches.open(MOTIVE_CACHE_NAME);
    const match = await cache.match(new Request(url));
    const json = await match?.json();
    return match ? json : null;
};

const StoreItemInBrowserCache = async <K>(url: string, item: K) => {
    const cache = await caches.open(MOTIVE_CACHE_NAME);
    cache.put(new Request(url), new Response(JSON.stringify(item)));
};

const RetrieveItemInSessionStorage = async <K>(url: string): Promise<K | null | undefined> => {
    const attempt = sessionStorage.getItem(url);
    return attempt !== null ? JSON.parse(attempt) : null;
};

const StoreItemInSessionStorage = async <K>(url: string, item: K) => {
    sessionStorage.setItem(url, JSON.stringify(item));
};

/**
 * @param url
 * @param promise
 * @param dispatch
 * @param fail
 * @param dontRequestIfMatch
 */
export const tryUseCachedForGetReq = async <K>(
    url: string,
    promise: Promise<{ data: K }>,
    dispatch: (newer: K) => void,
    fail: (e: Error) => void,
    dontRequestIfMatch = false,
    dontCheckCache = false
): Promise<K | Error | undefined> => {
    const match = await RetrieveItem<K>(url);

    return new Promise(async (resolve, reject) => {
        if (match !== null && !dontCheckCache) {
            dispatch(match as K);
            resolve(match as K);
            if (dontRequestIfMatch) {
                return;
            }
        }

        try {
            const res = await promise;
            const data = res.data;
            // TODO:: Diff last modified time here.

            if (isEqual(match, data) && !dontCheckCache) {
                return;
            }

            StoreItem<K>(url, data);
            dispatch(data);
            resolve(data);
        } catch (e) {
            DeleteItem(url);
            fail(e);
            reject(e);
        }
    });
};
