import React, { useEffect, useState } from 'react';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useToast } from '../../core-ui/hooks/useToast';
import { useCreateCatalog, useGetAllCatalogsByType, useSaveCatalog } from '../../shared/motive/hooks/useCatalogs';
import { useAddCatalogItem } from '../../shared/motive/hooks/useCatalogs/useAddCatalogItem/useAddCatalogItem';
import { useAddProjectCatalogs } from '../../shared/motive/hooks/useCatalogs/useAddProjectCatalogs/useAddProjectCatalogs';
import { useCurrentProject } from '../../shared/motive/hooks/useCurrentProject';
import { useProjectConfig } from '../../shared/motive/hooks/useProjectConfigs/useProjectConfigs';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { ConversationalAgent } from '../../shared/motive/models/ConversationalAgent';
import { IWatsonAssistant } from '../../shared/motive/models/WatsonAssistant';
import { mergeCatalogItemsIntoSingleArray } from '../../util/CatalogUtils';
import { filterCatalogsByProject } from '../../util/CatalogUtils';
import { DashboardAgentManagement } from '../../components/dashboardAgentManagement';
import { useConvoAIAgentCreationRoute } from '../../routes/convoAIAgentCreationRoute/ConvoAIAgentCreationRoute';
import { IAgentsProps } from '../../components/agents/Agents';
import { IWatsonServiceConfiguration } from '../../shared/motive/models/WatsonServiceConfiguration';
import { useConvoAIAgentManagementRoute } from '../../routes/convoAIAgentRoute/ConvoAIAgentRoute';

interface IAgentsContainerProps {
    children?: (props: IAgentsProps) => React.ReactNode;
    onSelectAgent?: (selectedAgent: string) => void;
    agents?: ICatalog[];
}

export const AgentsContainer: React.FC<IAgentsContainerProps> = props => {
    // Routing
    const { goTo: goToAgentCreation } = useConvoAIAgentCreationRoute();
    const {
        goTo: goToAgentManagement,
        params: { agentId }
    } = useConvoAIAgentManagementRoute();

    // State
    const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);

    // Network Calls
    const [currentProjectId] = useCurrentProject();
    const { data: currentProject } = useProjectConfig(currentProjectId);
    const { execute: addCatalogToProject, error: addCatalogToProjectError } = useAddProjectCatalogs();

    const {
        execute: createAgentCatalogCall,
        error: createAgentCatalogError,
        isLoading: isCreateAgentCatalogLoading
    } = useCreateCatalog();

    const {
        execute: createWatsonServiceConfigCatalogCall,
        error: createWatsonServiceConfigCatalogError,
        isLoading: isCreateWatsonServiceConfigCatalogLoading
    } = useCreateCatalog();

    const {
        execute: updateWatsonServiceConfigCatalogCall,
        error: updateWatsonServiceConfigCatalogError,
        isLoading: isUpdateWatsonServiceConfigCatalogCall
    } = useSaveCatalog();

    const { execute: addNewAgentItem, error: addNewAgentItemItemError } = useAddCatalogItem();
    const {
        execute: addNewWatsonServiceConfigItem,
        error: addNewWatsonServiceConfigItemItemError
    } = useAddCatalogItem();
    const { data: watsonAgentCatalogs } = useGetAllCatalogsByType(MotiveTypes.WATSON_ASSISTANT);
    const { data: watsonServiceConfigurationCatalogs } = useGetAllCatalogsByType(
        MotiveTypes.WATSON_SERVICE_CONFIGURATION
    );

    // Filtered Data
    const watsonAgentCatalogsForProject = filterCatalogsByProject(watsonAgentCatalogs, currentProject);
    const watsonServiceConfigurationCatalogsForProject = filterCatalogsByProject(
        watsonServiceConfigurationCatalogs,
        currentProject
    );
    const agentCatalogs: ICatalog[] = [...watsonAgentCatalogsForProject];

    useEffect(() => {
        // Ensure the space has a Watson Agent catalog & a watson service credential catalog in the project
        if (watsonAgentCatalogsForProject.length === 0) {
            createAgentCatalogCall({
                catalogTitle: `[${currentProject?.title}] Watson Assistants`,
                objectType: MotiveTypes.WATSON_ASSISTANT,
                importToCurrentProject: true
            });
        }
        if (watsonServiceConfigurationCatalogsForProject.length === 0) {
            createWatsonServiceConfigCatalogCall({
                catalogTitle: `[${currentProject?.title}] Watson Service Configuration`,
                objectType: MotiveTypes.WATSON_SERVICE_CONFIGURATION,
                importToCurrentProject: true
            });
        }
    }, []);

    // UI Hooks
    const toast = useToast();

    // Effects
    useEffect(() => {
        if (createAgentCatalogError) {
            toast({
                description: 'There was an error in creating a catalog.',
                isClosable: true,
                position: 'bottom-right',
                status: 'error',
                title: 'Error: Creating Catalog'
            });
        }
    }, [toast, createAgentCatalogError]);

    useEffect(() => {
        if (addNewAgentItemItemError) {
            toast({
                description: 'There was an error in creating an agent.',
                isClosable: true,
                position: 'bottom-right',
                status: 'error',
                title: 'Error: Creating Agent'
            });
        }
    }, [toast, addNewAgentItemItemError]);

    useEffect(() => {
        if (addNewAgentItemItemError) {
            toast({
                description: 'There was an error in creating a Watson Service Config.',
                isClosable: true,
                position: 'bottom-right',
                status: 'error',
                title: 'Error: Creating Service Configuration'
            });
        }
    }, [toast, addNewAgentItemItemError]);

    //  Handlers
    const handleSelectAgent = (agentId: string) => {
        props.onSelectAgent && props.onSelectAgent(agentId);
    };

    const handleGoToAgentCreation = () => {
        goToAgentCreation();
    };

    const handleCreateAgent = async (agent: ConversationalAgent): Promise<void> => {
        // Handle Watson Agents only for now.
        switch (agent.type) {
            case MotiveTypes.WATSON_ASSISTANT:
                const targetAgentCatalogId: string = watsonAgentCatalogsForProject[0]?.id;

                if (targetAgentCatalogId) {
                    const newItem: IWatsonAssistant = agent as IWatsonAssistant;

                    await addNewAgentItem({ catalogId: targetAgentCatalogId, item: newItem }).then(obj => {
                        if (obj) {
                            toast({
                                description: `Successfully created agent ${agent.name}.`,
                                isClosable: true,
                                position: 'bottom-right',
                                status: 'success',
                                title: 'Success: Created an Agent'
                            });
                        }
                    });
                }
                break;
        }
    };

    // TODO: Create Watson Configuration.
    const handleCreateWatsonConfiguration = async (config: IWatsonServiceConfiguration): Promise<void> => {
        // Add to the first catalog available (there should only be one)
        const targetCatalogId = watsonServiceConfigurationCatalogsForProject[0]?.id;

        if (targetCatalogId) {
            await addNewWatsonServiceConfigItem({ catalogId: targetCatalogId, item: config }).then(res => {
                if (res) {
                    toast({
                        description: `Successfully created Watson Service Configuration.`,
                        isClosable: true,
                        position: 'bottom-right',
                        status: 'success',
                        title: 'Success: Created a Service Configuration'
                    });
                }
            });
        }
    };

    const handleUpdateWatsonConfiguration = async (config: IWatsonServiceConfiguration): Promise<void> => {
        const targetCatalog = watsonServiceConfigurationCatalogsForProject[0];

        if (targetCatalog) {
            await updateWatsonServiceConfigCatalogCall({ catalog: { ...targetCatalog, items: [config] } }).then(res => {
                if (res) {
                    toast({
                        description: `Successfully updated Watson Service Configuration.`,
                        isClosable: true,
                        position: 'bottom-right',
                        status: 'success',
                        title: 'Success: Updated Service Configuration'
                    });
                }
            });
        }
    };

    const handleSearchAgent = (filter?: string) => {
        setSearchFilter(filter);
    };

    return (
        <>
            {props.children ? (
                props.children({
                    agents: mergeCatalogItemsIntoSingleArray(agentCatalogs),
                    watsonConfigurations: mergeCatalogItemsIntoSingleArray(
                        watsonServiceConfigurationCatalogsForProject
                    ),
                    onSelectAgent: handleSelectAgent,
                    onCreateAgent: handleCreateAgent,
                    onCreateWatsonConfiguration: handleCreateWatsonConfiguration,
                    onUpdateWatsonConfiguration: handleUpdateWatsonConfiguration
                })
            ) : (
                <DashboardAgentManagement
                    selectedAgent={agentId}
                    agents={mergeCatalogItemsIntoSingleArray(agentCatalogs)}
                    onSelectAgent={handleSelectAgent}
                    goToAgentCreation={handleGoToAgentCreation}
                    searchFilter={searchFilter}
                    onSearchAgent={handleSearchAgent}
                />
            )}
        </>
    );
};
