import React from 'react';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { RowItem } from '../../core-ui/rowItem';
import { ObjectEditor, IObjectEditorProps } from '../../containers/objectEditor';
import { getSmartName } from '../../util/ObjectDefinitionsUtils';
import { useTypeDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { useCurrentLanguageSettings } from '../../shared/motive/stores/editorLocale/EditorLocale';
import { Text } from '../../core-ui/text';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICatalogItemEditorProps {
    item: IScriptObjectModel;
    onClick?: () => void;
    onChange: IObjectEditorProps['onChange'];
    path: string;
}

export const CatalogItemEditor: React.FC<ICatalogItemEditorProps> = ({ onClick, onChange, item, path }) => {
    const { type } = item;
    const typeDefs = useTypeDefinitions();
    const language = useCurrentLanguageSettings();
    const name = getSmartName(item, typeDefs, language);

    return (
        <RowItem onClick={onClick}>
            <Text>{name}</Text>
            <ObjectEditor type={type} value={item} onChange={onChange} path={path} />
        </RowItem>
    );
};
