import produce from 'immer';
import { filter, get, noop } from 'lodash-es';
import { itemButtonsContainer } from '../../components/catalogs/Catalogs.style';
import { NavbarActionTypes } from './NavbarActions';
import { ScriptActionType } from '../../shared/motive/reducers/ScriptEditorReducers';

export interface INavBarItem {
    title: string;
    id: string;
    // onClick: () => void;
    closeable?: boolean;
    promptBeforeUnload?: {
        shouldPrompt: boolean;
        message: string;
        onBeforeClose?: () => void;
    };
}

export interface INavbarState {
    items: INavBarItem[];
    currentItemId: string;
    routeToTabId?: string;
    currentProject?: string;
}

export const DEFAULT_STATE: INavbarState = {
    items: [
        {
            id: 'project',
            title: 'Project',
            closeable: false
        }
    ],
    currentItemId: 'project'
};

export interface INavBarAction {
    type: NavbarActionTypes;
    tabId?: string;
    tabItem?: INavBarItem;
}

export const NavBarReducer = produce((state: INavbarState, action: NavbarActionTypes) => {
    switch (action.type) {
        case ScriptActionType.SET_SCRIPT: {
            if (action.script) {
                const navItem = state.items.find(c => c.id === action.script.id);

                if (state.currentItemId !== action.script.id) {
                    state.currentItemId = action.script.id;
                }

                if (navItem) {
                    navItem.title = action.script.name;
                } else {
                    state.items.push({
                        id: action.script.id,
                        title: action.script.name,
                        closeable: true
                    });
                }
            }
            break;
        }
        case 'currentProject/set': {
            if (state.currentProject !== action.project) {
                state.currentItemId = 'project';
                state.currentProject = action.project;
                state.items = state.items.filter(c => c.id === 'project');
            }
            break;
        }
        case 'navbar/tabs/current/set':
            if (action.id) {
                state.currentItemId = action.id;
            }
            break;
        case 'navbar/tabs/append':
            // state.items.push(action.item);
            // if (action.isSelected) {
            //     state.currentItemId = action.item.id;
            // }
            break;
        case 'navbar/tabs/update':
            state.items = state.items.map(i => {
                return i.id === action.item.id ? action.item : i;
            });
            break;
        case 'navbar/tabs/routeToTab/clear':
            state.routeToTabId = undefined;
            break;
        case 'navbar/tabs/delete':
            state.items = state.items.filter(item => item.id !== action.id);
            break;
    }
}, DEFAULT_STATE);
