import { cloneDeep } from 'lodash-es';
import React, { useRef, useState } from 'react';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { HeightUsedScrollContainer } from '../../containers/heightUsedScrollContainer';
import { IObjectEditorProps } from '../../containers/objectEditor';
import { useResizeDimension } from '../../shared/hooks/useResizeObserver/useResizeObserver';
import {
    IBlendShapeFacialExpression,
    IFacialBlendShapeSetting
} from '../../shared/motive/models/BlendShapeFacialExpression';
import { getEnumItemName, uniqueMotiveId } from '../../util/MotiveUtils';
import { CatalogExpressionPlayer } from './FacialExpressionPlayerCanvas';
import { FacialBlendShape } from './FacialBlendshapes';
import {
    catalogExpressionPlayerContainerStyle,
    expressionCategoryButtonIconStyle,
    expressionContainerStyle,
    expressionFieldStyle,
    expressionLeftColStyle,
    expressionSlidersContainerstyle,
    expressionSliderStyle,
    expressionsSettingsLayoutStyle,
    expressionWeightTitleStyle,
    facialExpressionTabsStyle,
    tabButtonStyle,
    expressionNumberStyle
} from './FacialExpressionsPlayer.style';
import { VersionDates } from '../../util/ScriptUtils';
import { useCurrentProjectConfig } from '../../redux/spaceKeyed/projectConfig/ProjectConfigSelectors';
import { useTypeDefinition } from '../../shared/motive/hooks/useScriptEditorInfo';
import { IEnumDefinition } from '../../shared/motive/models/EnumDefinition';
import { css } from '@emotion/core';

interface IFacialExpressionProps {
    label: string;
    enum: FacialBlendShape;
    min?: number;
    max?: number;
    default?: number;
}

type ExpressionCategories = 'mouth' | 'nose' | 'brow' | 'eye' | 'misc';

const ExpressionSet: Record<ExpressionCategories, IFacialExpressionProps[]> = {
    mouth: [
        { label: 'Blow', enum: FacialBlendShape.MouthBlow },
        { label: 'Bottom Lip Bite', enum: FacialBlendShape.MouthBottomLipBite },
        { label: 'Bottom Lip Down', enum: FacialBlendShape.MouthBottomLipDown },
        { label: 'Bottom Lip Trans', enum: FacialBlendShape.MouthBottomLipTrans, min: -100, max: 100 },
        { label: 'Bottom Lip Under', enum: FacialBlendShape.MouthBottomLipUnder },
        { label: 'Dimple Left', enum: FacialBlendShape.MouthDimpleLeft },
        { label: 'Dimple Right', enum: FacialBlendShape.MouthDimpleRight },
        { label: 'Down', enum: FacialBlendShape.MouthDown },
        { label: 'Left', enum: FacialBlendShape.MouthLeft },
        { label: 'Right', enum: FacialBlendShape.MouthRight },
        { label: 'Up', enum: FacialBlendShape.MouthUp },
        { label: 'Frown', enum: FacialBlendShape.MouthFrown },
        { label: 'Frown Left', enum: FacialBlendShape.MouthFrownLeft },
        { label: 'Frown Right', enum: FacialBlendShape.MouthFrownRight },
        { label: 'Lips Jaw Adjust', enum: FacialBlendShape.MouthLipsJawAdjust },
        { label: 'Lips Open', enum: FacialBlendShape.MouthLipsOpen },
        { label: 'Lips Apart', enum: FacialBlendShape.MouthLipsPart },
        { label: 'Lips Tight', enum: FacialBlendShape.MouthLipsTight },
        { label: 'Lips Tuck', enum: FacialBlendShape.MouthLipsTuck },
        { label: 'Open', enum: FacialBlendShape.MouthOpen },
        { label: 'Plosive', enum: FacialBlendShape.MouthPlosive },
        { label: 'Pucker', enum: FacialBlendShape.MouthPucker },
        { label: 'Pucker Open', enum: FacialBlendShape.MouthPuckerOpen },
        { label: 'Skewer', enum: FacialBlendShape.MouthSkewer },
        { label: 'Smile', enum: FacialBlendShape.MouthSmile },
        { label: 'Smile Left', enum: FacialBlendShape.MouthSmileLeft },
        { label: 'Smile Right', enum: FacialBlendShape.MouthSmileRight },
        { label: 'Snarl Lower Left', enum: FacialBlendShape.MouthSnarlLowerLeft },
        { label: 'Snarl Lower Right', enum: FacialBlendShape.MouthSnarlLowerRight },
        { label: 'Snarl Upper Left', enum: FacialBlendShape.MouthSnarlUpperLeft },
        { label: 'Snarl Upper Right', enum: FacialBlendShape.MouthSnarlUpperRight },
        { label: 'Top Lip Under', enum: FacialBlendShape.MouthTopLipUnder },
        { label: 'Top Lip Up', enum: FacialBlendShape.MouthTopLipUp },
        { label: 'Widen', enum: FacialBlendShape.MouthWiden },
        { label: 'Widen Sides', enum: FacialBlendShape.MouthWidenSides }
    ],
    brow: [
        { label: 'Drop Left', enum: FacialBlendShape.BrowDropLeft },
        { label: 'Drop Right', enum: FacialBlendShape.BrowDropRight },
        { label: 'Raise Left', enum: FacialBlendShape.BrowRaiseLeft },
        { label: 'Raise Right', enum: FacialBlendShape.BrowRaiseRight },
        { label: 'Raise Inner Left', enum: FacialBlendShape.BrowRaiseInnerLeft },
        { label: 'Raise Inner Right', enum: FacialBlendShape.BrowRaiseInnerRight },
        { label: 'Raise Outer Left', enum: FacialBlendShape.BrowRaiseOuterLeft },
        { label: 'Raise Outer Right', enum: FacialBlendShape.BrowRaiseOuterRight }
    ],
    nose: [
        { label: 'Flank Raise Left', enum: FacialBlendShape.NoseFlankRaiseLeft },
        { label: 'Flank Raise Right', enum: FacialBlendShape.NoseFlankRaiseRight },
        { label: 'Flank Raise', enum: FacialBlendShape.NoseFlanksRaise },
        { label: 'Nostrils Flare', enum: FacialBlendShape.NoseNostrilsFlare },
        { label: 'Scrunch', enum: FacialBlendShape.NoseScrunch }
    ],
    misc: [
        { label: 'Blow Left', enum: FacialBlendShape.CheekBlowLeft },
        { label: 'Blow Right', enum: FacialBlendShape.CheekBlowRight },
        { label: 'Raise Left', enum: FacialBlendShape.CheekRaiseLeft },
        { label: 'Raise Right', enum: FacialBlendShape.CheekRaiseRight },
        { label: 'Suck', enum: FacialBlendShape.CheeksSuck },
        { label: 'Move Left-Right', enum: FacialBlendShape.MoveJawLeftRight, default: 50 },
        { label: 'Move Up-Down', enum: FacialBlendShape.MoveJawUpDown }
    ],
    eye: [
        { label: 'Blink Left', enum: FacialBlendShape.EyeBlinkLeft },
        { label: 'Blink Right', enum: FacialBlendShape.EyeBlinkRight },
        { label: 'Squint Left', enum: FacialBlendShape.EyeSquintLeft },
        { label: 'Squint Right', enum: FacialBlendShape.EyeSquintRight },
        { label: 'Wide Left', enum: FacialBlendShape.EyeWideLeft },
        { label: 'Wide Right', enum: FacialBlendShape.EyeWideRight },
        { label: 'Blink', enum: FacialBlendShape.EyesBlink }
    ]
};

interface IFacialExpressionTabProps extends IFacialExpressionsDisplayProps {
    category: ExpressionCategories;
    width?: number;
}

const FacialExpressionTab: React.FC<IFacialExpressionTabProps> = ({ category, value, onChange, width }) => {
    const expressions = ExpressionSet[category];
    const getBlendShapeValue = (expression: IFacialExpressionProps) => {
        return (
            value?.blendShapeSettings?.find(
                shape => shape.blendShape && getEnumItemName(shape.blendShape) === expression.enum
            )?.value ??
            expression.default ??
            0
        );
    };

    return (
        <div css={[expressionLeftColStyle(width), expressionSlidersContainerstyle(width)]}>
            <HeightUsedScrollContainer style={{ minHeight: '150px' }}>
                <div css={expressionsSettingsLayoutStyle}>
                    {expressions.map(expression => {
                        return (
                            <div key={expression.enum} css={expressionFieldStyle}>
                                <div>
                                    <label css={expressionWeightTitleStyle}>{expression.label}</label>
                                </div>
                                <div>
                                    <input
                                        type="range"
                                        min={expression.min ?? 0}
                                        max={expression.max ?? 100}
                                        value={getBlendShapeValue(expression)}
                                        onChange={e => onChange(expression.enum, parseInt(e.currentTarget.value))}
                                        css={expressionSliderStyle}
                                    />
                                    <input
                                        type="number"
                                        min={expression.min ?? 0}
                                        max={expression.max ?? 100}
                                        value={getBlendShapeValue(expression)}
                                        onChange={e => onChange(expression.enum, parseInt(e.currentTarget.value))}
                                        css={expressionNumberStyle}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </HeightUsedScrollContainer>
        </div>
    );
};

interface IFacialExpressionsCategoriesProps {
    currentCategory: ExpressionCategories;
    setCurrentCategory: React.Dispatch<React.SetStateAction<ExpressionCategories>>;
    width?: number;
}

const FacialExpressionCategories: React.FC<IFacialExpressionsCategoriesProps> = ({
    currentCategory,
    setCurrentCategory,
    width
}) => {
    return (
        <div css={expressionLeftColStyle(width)}>
            <div css={facialExpressionTabsStyle}>
                <button onClick={() => setCurrentCategory('mouth')} css={tabButtonStyle(currentCategory === 'mouth')}>
                    <img src={'/img/mouth-icon.svg'} css={expressionCategoryButtonIconStyle} />
                </button>
                <button onClick={() => setCurrentCategory('nose')} css={tabButtonStyle(currentCategory === 'nose')}>
                    <img src={'/img/nose-icon.svg'} css={expressionCategoryButtonIconStyle} />
                </button>
                <button onClick={() => setCurrentCategory('brow')} css={tabButtonStyle(currentCategory === 'brow')}>
                    <img src={'/img/brow-icon.svg'} css={expressionCategoryButtonIconStyle} />
                </button>
                <button onClick={() => setCurrentCategory('eye')} css={tabButtonStyle(currentCategory === 'eye')}>
                    <img src={'/img/eye-icon.svg'} css={expressionCategoryButtonIconStyle} />
                </button>
                <button onClick={() => setCurrentCategory('misc')} css={tabButtonStyle(currentCategory === 'misc')}>
                    <img src={'/img/misc-icon.svg'} css={expressionCategoryButtonIconStyle} />
                </button>
            </div>
        </div>
    );
};

interface IFacialExpressionsDisplayProps {
    value?: IBlendShapeFacialExpression;
    onChange: (shape: FacialBlendShape, value: number) => void;
}

export const FacialExpressionsPlayer: React.FC<IObjectEditorProps<IBlendShapeFacialExpression>> = ({
    value,
    path = '',
    onChange
}) => {
    const [currentCategory, setCurrentCategory] = useState<ExpressionCategories>('mouth');

    const ref = useRef<HTMLDivElement>(null);
    const [width] = useResizeDimension(ref);

    const config = useCurrentProjectConfig();
    const enumDef = useTypeDefinition(MotiveTypes.FACIAL_BLEND_SHAPE) as IEnumDefinition;
    const isEnumRef = VersionDates.checkUseEnumRef(config?.typeVersionDate);

    const onFacialChange = (shape: FacialBlendShape, blendShapeWeight: number) => {
        let settings: IFacialBlendShapeSetting[];
        if (!value?.blendShapeSettings) {
            settings = [];
        } else {
            settings = cloneDeep(value.blendShapeSettings);
        }

        const shapeIndex = settings.findIndex(b => b.blendShape && getEnumItemName(b.blendShape) === shape);
        if (shapeIndex >= 0) {
            settings[shapeIndex].value = blendShapeWeight;
        } else {
            const enumItemDef = enumDef.items?.find(i => i.name === shape);

            if (enumItemDef?.id) {
                settings.push({
                    blendShape: isEnumRef ? { itemId: enumItemDef.id, name: enumItemDef.name } : shape,
                    value: blendShapeWeight,
                    type: MotiveTypes.BLEND_SHAPE_FACIAL_SETTING,
                    id: uniqueMotiveId()
                });
            }
        }

        const fieldPath = path ? `${path}.blendShapeSettings` : 'blendShapeSettings';

        onChange(fieldPath, settings);
    };

    return (
        <div css={catalogExpressionPlayerContainerStyle(width)} ref={ref}>
            <div css={expressionContainerStyle}>
                <FacialExpressionCategories
                    currentCategory={currentCategory}
                    setCurrentCategory={setCurrentCategory}
                    width={width}
                />
                <CatalogExpressionPlayer value={value} onChange={onChange} path={path} width={width} />
                <FacialExpressionTab
                    category={currentCategory}
                    onChange={onFacialChange}
                    width={width}
                    value={value}
                ></FacialExpressionTab>
            </div>
        </div>
    );
};
