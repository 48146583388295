/* eslint-disable @typescript-eslint/no-empty-interface */
import { useContext } from 'react';
import React from 'react';
import { IChooseState, IChooseAction } from '../../shared/motive/reducers/ChooseExternalGlobalReducer';

export interface IChooseGlobalContextStateValue extends IChooseState {}
export interface IChooseGlobalContextDispatchValue extends React.Dispatch<IChooseAction> {}

const chooseGlobalContextState = React.createContext<IChooseGlobalContextStateValue>({
    state: 'off'
});
const chooseGlobalContextDispatch = React.createContext<IChooseGlobalContextDispatchValue>(() => undefined);

export const ChooseGlobalContextStateProvider = chooseGlobalContextState.Provider;
export const ChooseGlobalContextDispatchProvider = chooseGlobalContextDispatch.Provider;

export const useChooseGlobalState = () => useContext(chooseGlobalContextState);
export const useChooseGlobalDispatch = () => useContext(chooseGlobalContextDispatch);
