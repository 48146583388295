import { ScriptEditorInfoStatus, IScriptEditorInfoAction } from '../reducers/ScriptEditorInfoReducer';
import { SPACE_ENDPOINT } from '../constants/ApiEndpoint';
import { httpClient } from './HttpClient';
import { tryUseCachedForGetReq, UseStaleWhileFetchingStrategy } from './StaleWhileFetching';
import { ISpace } from '../models/Space';
import { IScriptEditorInfo } from '../models/ScriptEditorInfo';
import { Dispatch } from 'redux';
import { spaceSetAction } from '../../../redux/spaces/SpaceActions';

export const GetSpacesPath = 'api/spaces';

const GetSpacesLegacy = async (dispatch: Dispatch): Promise<void> => {
    try {
        const spaceResponse = await httpClient.get(GetSpacesPath);

        dispatch(spaceSetAction(spaceResponse.data));
    } catch (error) {}
};

const GetSpacesSWF = async (dispatch: Dispatch): Promise<void> => {
    await tryUseCachedForGetReq(
        GetSpacesPath,
        httpClient.get(GetSpacesPath),
        data => dispatch(spaceSetAction(data as ISpace[])),
        () => {}
    );
};

export const GetSpaces = UseStaleWhileFetchingStrategy ? GetSpacesSWF : GetSpacesLegacy;

export const GetScriptEditorInfoPath = (spaceName: string): string =>
    `${SPACE_ENDPOINT}/${spaceName}/StoryFlow/ScriptEditorInfo`;

const GetScriptEditorInfoLegacy = async (
    spaceName: string,
    dispatch: (param: IScriptEditorInfoAction) => void
): Promise<void> => {
    try {
        dispatch({ status: ScriptEditorInfoStatus.FETCHING });

        const response = await httpClient.get(GetScriptEditorInfoPath(spaceName));

        dispatch({
            status: ScriptEditorInfoStatus.SUCCESS,
            scriptEditorInfo: response.data
        });
    } catch (error) {
        dispatch({ status: ScriptEditorInfoStatus.ERROR, error: error });
    }
};

const GetScriptEditorInfoSWF = async (
    spaceName: string,
    dispatch: (param: IScriptEditorInfoAction) => void
): Promise<void> => {
    dispatch({ status: ScriptEditorInfoStatus.FETCHING });
    const url = GetScriptEditorInfoPath(spaceName);
    await tryUseCachedForGetReq(
        url,
        httpClient.get(url),
        data =>
            dispatch({
                status: ScriptEditorInfoStatus.SUCCESS,
                scriptEditorInfo: data as IScriptEditorInfo
            }),
        error => dispatch({ status: ScriptEditorInfoStatus.ERROR, error: error })
    );
};

export const GetScriptEditorInfo = UseStaleWhileFetchingStrategy ? GetScriptEditorInfoSWF : GetScriptEditorInfoLegacy;
