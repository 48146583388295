import React from 'react';
import { Agent } from '../../components/agent';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useToast } from '../../core-ui/hooks/useToast';
import { useGetAllCatalogsByType } from '../../shared/motive/hooks/useCatalogs';
import { useDeleteCatalogItem } from '../../shared/motive/hooks/useCatalogs/useDeleteCatalogItem/useDeleteCatalogItem';
import { useSaveCatalogItem } from '../../shared/motive/hooks/useCatalogs/useSaveCatalogItem/useSaveCatalogItem';
import { useCurrentProject } from '../../shared/motive/hooks/useCurrentProject';
import { useProjectConfig } from '../../shared/motive/hooks/useProjectConfigs/useProjectConfigs';
import { ConversationalAgent } from '../../shared/motive/models/ConversationalAgent';
import { filterCatalogsByProject } from '../../util/CatalogUtils';

interface IAgentContainerProps {
    agent: ConversationalAgent;
}

export const AgentContainer: React.FC<IAgentContainerProps> = props => {
    const toast = useToast();
    const [projectConfigId] = useCurrentProject();
    const { data: projectConfig } = useProjectConfig(projectConfigId);

    const { data: watsonAssistantCatalogs } = useGetAllCatalogsByType(MotiveTypes.WATSON_ASSISTANT);
    const watsonAssistantCatalogsForProject = filterCatalogsByProject(watsonAssistantCatalogs, projectConfig);

    const { execute: updateAgent, isLoading: isUpdateAgentLoading, error: updateAgentError } = useSaveCatalogItem();
    const { execute: deleteAgent, isLoading: isDeleteAgentLoading, error: deleteAgentError } = useDeleteCatalogItem();

    const handleUpdateAgent = async (agent: ConversationalAgent) => {
        const targetCatalogId = watsonAssistantCatalogsForProject.find(cat =>
            cat.items?.some(item => item.id === agent.id)
        )?.id;

        if (targetCatalogId) {
            switch (agent.type) {
                case MotiveTypes.WATSON_ASSISTANT:
                    await updateAgent({ catalogId: targetCatalogId, item: agent }).then(res => {
                        if (res) {
                            toast({
                                description: `Successfully updated ${agent.name}.`,
                                isClosable: true,
                                position: 'bottom-right',
                                status: 'success',
                                title: 'Success: Updated Watson Assistant'
                            });
                        }
                    });
                    break;
            }
        }
    };

    const handleDeleteAgent = async (agent: ConversationalAgent) => {
        const targetCatalogId = watsonAssistantCatalogsForProject.find(cat =>
            cat.items?.find(item => item.id === agent.id)
        )?.id;

        if (targetCatalogId) {
            switch (agent.type) {
                case MotiveTypes.WATSON_ASSISTANT:
                    deleteAgent({ catalogId: targetCatalogId, catalogItemId: agent.id }).then(() => {
                        toast({
                            description: `Successfully deleted ${agent.name}.`,
                            isClosable: true,
                            position: 'bottom-right',
                            status: 'success',
                            title: 'Success: Deleted Watson Assistant'
                        });
                    });
                    break;
            }
        }
    };

    return <Agent agent={props.agent} onUpdateAgent={handleUpdateAgent} onDeleteAgent={handleDeleteAgent} />;
};
