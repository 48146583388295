import { IFrame, IScript } from '../../shared/motive/models/Script';
import * as React from 'react';
import { useEditScript, useResetScriptOnDismount, useScriptTitleAsTabTitle } from './ScriptEditorHooks';
import { useSelector } from 'react-redux';
import {
    SelectScriptEditorCurrentScript,
    SelectScriptEditorFrame,
    SelectScriptEditorSelectedFrameId
} from '../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';
import { ScriptEditor } from './ScriptEditor';

export interface IScriptEditorSessionProps {
    scriptId: string;
}

export interface IScriptEditorSessionDependentProps {
    script: IScript;
    selectedFrame: IFrame | null;
}

export const ScriptEditorSession: React.FC<IScriptEditorSessionProps> = ({ scriptId }) => {
    const editScript = useSelector(SelectScriptEditorCurrentScript);
    const selectedFrameId = useSelector(SelectScriptEditorSelectedFrameId);
    const selectedFrame = useSelector(SelectScriptEditorFrame(selectedFrameId));

    useScriptTitleAsTabTitle(editScript);

    // Start tracking this script id in an edit session.
    useEditScript(scriptId);

    return <ScriptEditor script={editScript} selectedFrame={selectedFrame} />;
};
