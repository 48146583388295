import React, { useReducer } from 'react';
import { ChooseGlobalContextStateProvider, ChooseGlobalContextDispatchProvider } from '../../hooks/useChooseGlobal';
import { ChooseExternalGlobalReducer } from '../../shared/motive/reducers/ChooseExternalGlobalReducer';

export const ChooseGlobalContextContainer: React.FC = ({ children }) => {
    const [chooseGlobalState, chooseGlobalDispatch] = useReducer(ChooseExternalGlobalReducer, {
        state: 'off'
    });

    return (
        <ChooseGlobalContextStateProvider value={chooseGlobalState}>
            <ChooseGlobalContextDispatchProvider value={chooseGlobalDispatch}>
                {children}
            </ChooseGlobalContextDispatchProvider>
        </ChooseGlobalContextStateProvider>
    );
};
