import { ICatalog } from '../shared/motive/models/Catalog';

export const getDefaultScriptDirectory = (catalogs: ICatalog[], currentProject: string): ICatalog | undefined => {
    if (catalogs.length > 0) {
        const findCatalog = catalogs.find(c => c.title === `[${currentProject}] Scenarios`);

        if (findCatalog) {
            return findCatalog;
        }

        return catalogs[0];
    }
};
