import { useCurrentSpace } from '../useCurrentSpace';
import { GetCollaborators, GetPendingCollaborators } from '../../networking/CollaboratorService';
import { IHookProvidedDataSource } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallForDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { SelectCollaboratorState } from '../../../../redux/spaceKeyed/collaborator/CollaboratorSelector';
import { useSelector, useDispatch } from 'react-redux';
import { ICollaboratorState } from '../../../../redux/spaceKeyed/collaborator/CollaboratorReducer';

export const useCollaborators = (): IHookProvidedDataSource<ICollaboratorState> => {
    const [currentSpace] = useCurrentSpace();

    const dispatch = useDispatch();
    const collabState = useSelector(SelectCollaboratorState);

    const { triggerMultipleCalls, ...rest } = useNetworkCallForDataSource(collabState);

    async function call() {
        await triggerMultipleCalls([
            GetCollaborators(currentSpace, dispatch),
            GetPendingCollaborators(currentSpace, dispatch)
        ]);
    }

    return { data: collabState, load: call, ...rest };
};
