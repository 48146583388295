import { GenerateSubSelector } from '../spaceKeyedSelectors';
import { ICollaboratorState } from './CollaboratorReducer';
import { createSelector } from 'reselect';

export const SelectCollaboratorState = GenerateSubSelector<ICollaboratorState>('collaborator');

export const selectCollaborators = createSelector(SelectCollaboratorState, collabState => collabState.collaborators);

export const selectPendingCollaborators = createSelector(
    SelectCollaboratorState,
    collabState => collabState.pendingCollaborators
);
