export enum Size {
    SMALLEST,
    SMALLER,
    SMALL,
    MEDIUM,
    LARGE,
    LARGER,
    LARGEST,
    INHERIT
}

export type ButtonSize = Size.SMALLER | Size.SMALL | Size.MEDIUM | Size.LARGE | Size.LARGER | Size.INHERIT;
export type TextSize = Size;
export type HeadingSize = Size;
export type EditableTextSize = Size.SMALL | Size.MEDIUM | Size.LARGE;
export type RowItemSize = Size.SMALLER | Size.SMALL | Size.MEDIUM | Size.LARGE;
export type ToggleSize = Size.SMALL | Size.MEDIUM | Size.LARGE;
export type InputSize = Size.SMALL | Size.MEDIUM | Size.LARGE;
export type PillSize = Size.SMALL | Size.MEDIUM | Size.LARGE;
export type CheckboxSize = Size.SMALL | Size.MEDIUM | Size.LARGE;
export type IconSize = Size.SMALLER | Size.SMALL | Size.MEDIUM | Size.LARGE | Size.LARGER | Size.INHERIT;
