import { IDragEndHandlerAction } from './DragAndDropActions';
import { useContext, createContext } from 'react';

const dragHandlerContext = createContext<(action: IDragEndHandlerAction) => void>(() => undefined);
const checkDragStateContext = createContext<(dropId: string, dragType?: string, checkDomainOnly?: boolean) => boolean>(
    () => false
);

export const DragHandlerProvider = dragHandlerContext.Provider;
export const DragHoverProvider = checkDragStateContext.Provider;

/**
 * Dispatch an action (from actions in DragAndDropActions) with this dispatch function
 */
export const useDragEndDispatch = () => useContext(dragHandlerContext);

/**
 * Provides a function to check if a particular dropId has a particular dragType hovering over it
 * An additional "checkDomainOnly" optional boolean is provided if you want to not match the drop ID exactly
 * Drop IDs should be defined as "someDroppableDomain:uniqueId", uniqueId being optional.
 * checkDomainOnly param will only match the "domain" of the droppable so it can be used for example as "if this particular drag type is hovering over any frame thumb"
 */
export const useCheckDragHover = () => useContext(checkDragStateContext);
