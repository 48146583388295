import React from 'react';
import { Logger } from '../../util/logger';
import { MediaUploadZone } from '../../components/mediaUploadZone/MediaUploadZone';
import { restoreBundle } from '../../shared/motive/networking/RestoreService';
import { useCurrentProject } from '../../shared/motive/hooks/useCurrentProject';
import { useCurrentSpace } from '../../shared/motive/hooks/useCurrentSpace';
import { useDispatch, useSelector } from 'react-redux';
import { IStatus } from '../../redux/Status';
import { EmptyCollection } from '../../core-ui/emptyCollection';
import { loadingMessageStyle } from './RestoreUploadContainer.style';
import { SelectBundleRestoreStatus } from '../../redux/spaceKeyed/bundle/BundleSelectors';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

interface IRestoreUploadContainerProps {
    onUpload?: () => void;
}

const getUploadMessage = (status: IStatus) => {
    if (status.loadingState === 'initial') {
        return i18next.t('dragBundleText');
    } else if (status.loadingState === 'resolved') {
        if (status.error) {
            return i18next.t('errorMessage', { message: status.error.message });
        } else {
            return i18next.t('projectHasBeenClonedText');
        }
    }
    return ``;
};

export const RestoreUploadContainer: React.FC<IRestoreUploadContainerProps> = ({ onUpload }) => {
    const [currentProject] = useCurrentProject();
    const [currentSpace] = useCurrentSpace();
    const bundleDispatch = useDispatch();
    const restoreStatus = useSelector(SelectBundleRestoreStatus);
    const { t } = useTranslation();

    const handleFileUpload = (acceptedFiles: File[]) => {
        acceptedFiles.forEach((file: File) => {
            if (file.type === 'application/x-zip-compressed') {
                const reader = new FileReader();

                reader.onabort = () => Logger.debug('File reading was aborted');
                reader.onerror = () => Logger.debug('File reading has failed');
                reader.onload = async () => {
                    await restoreBundle(currentSpace, currentProject, file, bundleDispatch);
                    onUpload && onUpload();
                };

                reader.readAsArrayBuffer(file);
            }
        });
    };

    return (
        <>
            {restoreStatus.loadingState === 'loading' ? (
                <EmptyCollection css={loadingMessageStyle} message={t('bundleIsBeingRestoredText')} />
            ) : (
                <MediaUploadZone
                    onFileUpload={handleFileUpload}
                    multipleFiles={false}
                    uploadMessage={getUploadMessage(restoreStatus)}
                ></MediaUploadZone>
            )}
        </>
    );
};
