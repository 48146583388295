import { IScriptObjectModel } from '../../../../shared/motive/models/ScriptObjectModel';
import { ITypeDefinitionMap } from '../../../../shared/motive/models/TypeDefinition';
import { ILanguageSettings } from '../../../../shared/motive/stores/editorLocale/EditorLocale';
import { MotiveTypes } from '../../../../constants/MotiveTypes';
import { createScriptObject, createObjectReferenceFromObject } from '../../../../util/MotiveUtils';
import { IScriptLauncher } from '../../../../shared/motive/models/ScriptLauncher';
import { ISceneWorldObject } from '../../../../shared/motive/models/SceneWorldObject';
import { ISceneWorldAnchor } from '../../../../shared/motive/models/SceneWorldAnchor';
import { IAssetSpawner } from '../../../../shared/motive/models/AssetSpawner';
import { IAssetInstance } from '../../../../shared/motive/models/AssetInstance';
import { IMediaItem, isAudio } from '../../../../shared/motive/models/MediaItem';
import { IPlayableContent, ILocalizedAudioContent } from '../../../../shared/motive/models/IPlayableContent';
import { IEquipTarget } from '../../../../shared/motive/models/EquipTarget';
import { IBehaviourModelBindings, IBehaviourModel } from '../../../../shared/motive/models/IBehaviourModel';
import { Dispatch } from 'redux';
import { ICatalogObjectInstanceDrag } from '../DragModels/DragModels';
import { ICatalog } from '../../../../shared/motive/models/Catalog';
import { ScriptActionType } from '../../../../shared/motive/reducers/ScriptEditorReducers';
import { IWatsonIntent } from '../../../../shared/motive/models/WatsonIntent';
import { ISpeechRecognitionCondition } from '../../../../shared/motive/models/SpeechRecognitionCondition';
import { IIntentRecognizer } from '../../../../shared/motive/models/SpeechRecognizer';
import { IVoiceInput, IVoiceInputOption } from '../../../../shared/motive/models/VoiceInput';
import { ICharacterActionPlayer } from '../../../../shared/motive/models/ICharacterActionPlayer';
import { IFacialExpressionAction } from '../../../../shared/motive/models/IFacialExpressionAction';

const CREATE_RESOURCE_TYPES: string[] = [
    MotiveTypes.MOTIVE_SCRIPT,
    MotiveTypes.NAMED_WORLD_OBJECT,
    MotiveTypes.NAMED_WORLD_ANCHOR,
    MotiveTypes.UNITY_ASSET,
    MotiveTypes.MEDIA_ITEM,
    MotiveTypes.NAMED_EQUIP_TARGET,
    MotiveTypes.BEHAVIOUR_MODEL,
    MotiveTypes.WATSON_INTENT,
    MotiveTypes.BLEND_SHAPE_FACIAL_EXPRESSION
];

const CREATE_CONDITION_TYPES: string[] = [MotiveTypes.WATSON_INTENT];

export const onDragCatalogItem = (
    dispatch: Dispatch,
    catalogs: ICatalog<IScriptObjectModel>[],
    objDefs: ITypeDefinitionMap,
    language: ILanguageSettings,
    frameId: string,
    type: 'resource' | 'condition' = 'resource'
) => {
    return (dragObjInfo: ICatalogObjectInstanceDrag) => {
        const catItem = catalogs
            ?.find(c => c.id === dragObjInfo.catalogId)
            ?.items?.find(i => i.id === dragObjInfo.objectId);

        if (!catItem) {
            throw new Error('cat item not found');
        }

        if (type === 'resource' && CREATE_RESOURCE_TYPES.includes(catItem.type)) {
            onDragCatalogItemCreateResource(dispatch, catItem, objDefs, language, frameId);
        } else if (type === 'condition' && CREATE_CONDITION_TYPES.includes(catItem.type)) {
            onDragCatalogItemCreateCondition(dispatch, catItem, objDefs, language, frameId);
        }
    };
};

export const onDragCatalogItemCreateResource = (
    dispatch: Dispatch,
    catItem: IScriptObjectModel,
    objDefs: ITypeDefinitionMap,
    language: ILanguageSettings,
    frameId: string
) => {
    const resource = getResourceFromCatalog(catItem, objDefs, language);

    if (resource) {
        dispatch({
            type: ScriptActionType.RESOURCE_ADD,
            resource,
            destinationIndex: -1,
            destinationFrameId: frameId
        });
    }
};

export const onDragCatalogItemCreateCondition = (
    dispatch: Dispatch,
    catItem: IScriptObjectModel,
    objDefs: ITypeDefinitionMap,
    language: ILanguageSettings,
    frameId: string
) => {
    const condition = getConditionFromCatalog(catItem, objDefs, language);

    if (condition) {
        dispatch({
            type: ScriptActionType.PRE_CONDITION_ADD,
            objectDefinitions: objDefs,
            object: condition,
            destinationFrameId: frameId
        });
    }
};

export function getResourceFromCatalog(
    catalogItem: IScriptObjectModel,
    objDefs: ITypeDefinitionMap,
    language: ILanguageSettings
): IScriptObjectModel | undefined {
    switch (catalogItem.type) {
        case MotiveTypes.MOTIVE_SCRIPT: {
            const launcher = createScriptObject<IScriptLauncher>(MotiveTypes.SCRIPT_LAUNCHER, objDefs);
            launcher.scriptReference = createObjectReferenceFromObject(catalogItem, objDefs, language);
            return launcher;
        }
        case MotiveTypes.NAMED_WORLD_OBJECT: {
            const sceneObj = createScriptObject<ISceneWorldObject>(MotiveTypes.SCENE_WORLD_OBJECT, objDefs);
            sceneObj.namedWorldObject = catalogItem;
            return sceneObj;
        }
        case MotiveTypes.NAMED_WORLD_ANCHOR:
        case MotiveTypes.NAMED_POSITIONAL_ANCHOR: {
            const sceneObj = createScriptObject<ISceneWorldAnchor>(MotiveTypes.SCENE_WORLD_ANCHOR, objDefs);
            sceneObj.namedWorldAnchor = catalogItem;
            return sceneObj;
        }
        case MotiveTypes.UNITY_ASSET:
        case MotiveTypes.BINARY_ASSET: {
            const spawner = createScriptObject<IAssetSpawner>(MotiveTypes.ASSET_SPAWNER, objDefs);
            spawner.assetInstance = createScriptObject<IAssetInstance>(MotiveTypes.ASSET_INSTANCE, objDefs);
            spawner.assetInstance.asset = catalogItem;

            return spawner;
        }
        case MotiveTypes.MEDIA_ITEM: {
            const mediaItem = catalogItem as IMediaItem;
            if (isAudio(mediaItem?.source)) {
                const playable = createScriptObject<IPlayableContent>(MotiveTypes.PLAYABLE_CONTENT, objDefs);

                const localizedAudio = createScriptObject<ILocalizedAudioContent>(
                    MotiveTypes.PLAYABLE_AUDIO_CONTENT,
                    objDefs
                );
                localizedAudio.localizedMedia = createScriptObject(MotiveTypes.LOCALIZED_MEDIA, objDefs);
                localizedAudio.localizedMedia.localizations = [
                    {
                        cultureCode: language.contentLanguage,
                        mediaItem: mediaItem
                    }
                ];

                playable.content = localizedAudio;

                return playable;
            }

            break;
        }
        case MotiveTypes.NAMED_EQUIP_TARGET: {
            const equipTarget = createScriptObject<IEquipTarget>(MotiveTypes.EQUIP_TARGET, objDefs);

            equipTarget.target = catalogItem;

            return equipTarget;
        }
        case MotiveTypes.BEHAVIOUR_MODEL: {
            const bindings = createScriptObject<IBehaviourModelBindings>(MotiveTypes.BEHAVIOUR_MODEL_BINDINGS, objDefs);

            bindings.behaviourModel = catalogItem as IBehaviourModel;

            return bindings;
        }
        case MotiveTypes.WATSON_INTENT: {
            const intentItem = catalogItem as IWatsonIntent;
            const voiceInput = createScriptObject<IVoiceInput>(MotiveTypes.VOICE_INPUT, objDefs);
            const voiceInputOption = createScriptObject<IVoiceInputOption>(MotiveTypes.VOICE_INPUT_OPTION, objDefs);
            const intentRecognizer = createScriptObject<IIntentRecognizer>(MotiveTypes.INTENT_RECOGNIZER, objDefs);

            intentRecognizer.intent = intentItem;
            voiceInputOption.recognizer = intentRecognizer;
            voiceInput.options = [voiceInputOption];

            return voiceInput;
        }
        case MotiveTypes.BLEND_SHAPE_FACIAL_EXPRESSION: {
            const characterAction = createScriptObject<ICharacterActionPlayer>(MotiveTypes.CHARACTER_ACTION_PLAYER, objDefs);
            const expressionAction = createScriptObject<IFacialExpressionAction>(MotiveTypes.FACIAL_EXPRESSION_ACTION, objDefs);
            expressionAction.facialExpression = catalogItem;
            characterAction.actions = [expressionAction];
            return characterAction;
        }
    }

    return undefined;
}

export function getConditionFromCatalog(
    catalogItem: IScriptObjectModel,
    objDefs: ITypeDefinitionMap,
    language: ILanguageSettings
): IScriptObjectModel | undefined {
    switch (catalogItem.type) {
        case MotiveTypes.WATSON_INTENT: {
            const intentItem = catalogItem as IWatsonIntent;

            const speechRecognition = createScriptObject<ISpeechRecognitionCondition>(
                MotiveTypes.SPEECH_RECOGNITION_CONDITION,
                objDefs
            );

            const intentRecognizer = createScriptObject<IIntentRecognizer>(MotiveTypes.INTENT_RECOGNIZER, objDefs);
            intentRecognizer.intent = intentItem;

            speechRecognition.recognizer = intentRecognizer;

            return speechRecognition;
        }
    }

    return undefined;
}
