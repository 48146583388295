import { ICatalog } from '../../../models/Catalog';
import { IScriptObjectModel } from '../../../models/ScriptObjectModel';
import { IHookProvidedDataSource } from '../../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallForDataSource } from '../../../../hooks/useNetworkCallForDataSource';
import { useCurrentSpace } from '../../useCurrentSpace';
import { getCatalogByIdNetworkCall } from '../../../networking/CatalogService';
import { useDispatch, useSelector } from 'react-redux';
import { SelectCatalogById } from '../../../../../redux/spaceKeyed/catalog/CatalogSelectors';

export const useGetCatalogById = (id: string): IHookProvidedDataSource<ICatalog<IScriptObjectModel> | undefined> => {
    const data = useSelector(SelectCatalogById(id));
    const [space] = useCurrentSpace();
    const dispatch = useDispatch();

    const { getCancelableDispatch, triggerCall, ...rest } = useNetworkCallForDataSource(data);

    const load = async () => {
        await triggerCall(getCatalogByIdNetworkCall(space, id, getCancelableDispatch(dispatch)));
    };

    return {
        data: data,
        load: load,
        ...rest
    };
};
