import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const headingWrapperStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const buttonWrapperStyle = css`
    display: flex;
`;

export const buttonStyle = (token: IToken) => css`
    margin-left: ${token.spacings.smallest};
`;

export const catalogSettingButtonStyle = (token: IToken) => css`
    background-color: ${token.colors.interactible.secondary};
`;

export const catalogSettingsWrapperStyle = (token: IToken) => css`
    padding: ${token.spacings.small};
    margin: ${token.spacings.small} 0;
    border: 1px solid ${token.colors.border.primary};
`;

export const agentSettingWrapperStyle = (token: IToken) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
`;

export const createIntentFieldWrapperStyle = (token: IToken) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const settingTextStyle = (token: IToken) => css`
    margin-right: ${token.spacings.small};
`;

export const createIntentButtonWrapperStyle = css`
    display: flex;
    justify-content: flex-end;
`;

export const actionBarWrapper = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const searchBarStyle = (token: IToken) => css`
    margin-right: ${token.spacings.small};
`;

export const rowStyle = css`
    display: flex;
    flex-direction: row;
`;

export const emptyPanelStyle = css`
    width: 75%;
`;

export const disabledButtonStyle = (token: IToken) => css`
    background-color: unset;
`;

export const deleteButtonStyle = (token: IToken) => css`
    background-color: ${token.colors.interactible.warning};
`;
