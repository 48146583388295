import React, { useEffect } from 'react';
import { useLoadDataOnMount } from '../../shared/hooks/useLoadDataOnMount';
import { useStyle } from '../../shared/hooks/useStyle';
import { loginContainerStyle, storyFlowLogoStyle, centerLoginForm } from './Login.style';
import { MotiveLogo } from '../../core-ui/motiveLogo';
import { Button, ButtonVariant } from '../../core-ui/button';
import { BASE_URL } from '../../shared/motive/networking/HttpClient';
import * as FullStory from '@fullstory/browser';
import { useUserInfo } from '../../shared/motive/hooks/useUserInfo';
import { UserLoginStatus } from '../../redux/userInfo/UserInfoReducer';
import { useTranslation, Trans } from 'react-i18next';

export const LoginContainer: React.FC = () => {
    const tokens = useStyle();
    const { t, i18n } = useTranslation();

    const login = async () => {
        window.location.href = `${BASE_URL}/Login?ReturnUrl=${document.URL}`;
    };

    return (
        <div css={loginContainerStyle(tokens)}>
            <div css={storyFlowLogoStyle(tokens)}>
                <div css={centerLoginForm()}>
                    <MotiveLogo unselectable={'on'}></MotiveLogo>
                    <Trans i18nKey="notLoggedInText">
                        <p>It looks like you are not logged in yet.</p>
                        <p>Please log in here.</p>
                    </Trans>
                    <Button onClick={login} variant={ButtonVariant.SOLID}>
                        {i18n.format(t('login'), 'uppercase')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export const UserAuthContainer: React.FC = ({ children }): React.ReactElement => {
    const userInfoDataSource = useUserInfo();

    useLoadDataOnMount(userInfoDataSource);

    const {
        data: { status },
        isLoading
    } = userInfoDataSource;

    useEffect(() => {
        if (status === UserLoginStatus.LoggedIn) {
            var user = userInfoDataSource?.data?.userInfo?.user;

            if (user) {
                if (user.email.endsWith('motive.io')) {
                    FullStory.shutdown();
                } else {
                    FullStory.setUserVars({
                        displayName: user.name,
                        email: user.email
                    });
                }
            }
        }
    }, [userInfoDataSource, status]);

    const GetRenderChild = () => {
        switch (status) {
            case UserLoginStatus.LoggedIn:
                return <>{children}</>;
            case UserLoginStatus.Pending:
            case UserLoginStatus.Initial:
                return <></>;
            case UserLoginStatus.AuthFailure:
            case UserLoginStatus.Unknown:
                return <LoginContainer />;
            default:
                break;
        }
    };

    if (isLoading) {
        return <></>;
    }

    return <>{GetRenderChild()}</>;
};
