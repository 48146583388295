// istanbul ignore file MOTIVE_EXCEPTION
import React from 'react';
import { Bar } from '../../core-ui/bar';
import { Button } from '../../core-ui/button';
import { BarVariant } from '../../core-ui/bar/Bar';
import { useStyle } from '../../shared/hooks/useStyle';
import { tabStyle, selectedTabStyle, containerStyle } from './DashboardNavBar.style';
import { useGetCatalogTypes } from '../../shared/motive/hooks/useCatalogs/useCatalogTypes/useGetCatalogTypes';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useTranslation } from 'react-i18next';

enum TabsEnum {
    Scenarios,
    Catalogs,
    Media,
    Backup,
    ConvoAI
}
export type DashboardTab = keyof typeof TabsEnum;

interface IDashboardNavBarProps {
    height: number;
    selectedTab?: DashboardTab;
    onTabPress?: (tab: DashboardTab) => void;
}

export const DashboardNavBar: React.FC<IDashboardNavBarProps> = ({ onTabPress, selectedTab, height }) => {
    const token = useStyle();
    const { t, i18n } = useTranslation();

    const handleTabPress = (tab: DashboardTab) => {
        if (onTabPress) {
            onTabPress(tab);
        }
    };

    const { data: typeData } = useGetCatalogTypes();

    const isAIFeatureSetAvailable = typeData.some(type => type.bundleName === MotiveTypes.AI);

    const handleCatalogsTabClick = () => handleTabPress('Catalogs');
    const handleScenariosTabClick = () => handleTabPress('Scenarios');
    const handleMediaTabClick = () => handleTabPress('Media');
    const handleBackupTabClick = () => handleTabPress('Backup');
    const handleAiTabClick = () => handleTabPress('ConvoAI');

    return (
        <>
            <Bar css={containerStyle(token, height)} variant={BarVariant.SECONDARY}>
                <Button
                    css={selectedTab === 'Scenarios' ? selectedTabStyle(token) : tabStyle(token)}
                    onClick={handleScenariosTabClick}
                >
                    {i18n.format(t('scenarios'), 'uppercase')}
                </Button>
                <Button
                    css={selectedTab === 'Catalogs' ? selectedTabStyle(token) : tabStyle(token)}
                    onClick={handleCatalogsTabClick}
                >
                    {i18n.format(t('catalogs'), 'uppercase')}
                </Button>
                <Button
                    css={selectedTab === 'Media' ? selectedTabStyle(token) : tabStyle(token)}
                    onClick={handleMediaTabClick}
                >
                    {i18n.format(t('media'), 'uppercase')}
                </Button>
                <Button
                    css={selectedTab === 'Backup' ? selectedTabStyle(token) : tabStyle(token)}
                    onClick={handleBackupTabClick}
                >
                    {i18n.format(t('bundles'), 'uppercase')}
                </Button>
                {isAIFeatureSetAvailable && (
                    <Button
                        css={selectedTab === 'ConvoAI' ? selectedTabStyle(token) : tabStyle(token)}
                        onClick={handleAiTabClick}
                    >
                        {i18n.format(t('ai'), 'uppercase')}
                    </Button>
                )}
            </Bar>
        </>
    );
};
