import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const createButtonStyle = (token: IToken) => css`
    margin-left: ${token.spacings.smaller};
`;
export const createButtonWrapperStyle = css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const createCatalogWrapperStyle = (token: IToken) => css`
    padding: ${token.spacings.small};
    border: 1px solid ${token.colors.border.primary};
`;
