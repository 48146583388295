/* eslint-disable @typescript-eslint/no-explicit-any */
import { saveCatalogNetworkCall } from '../../../networking/CatalogService';
import { useCurrentSpace } from '../../useCurrentSpace';
import { ICatalog } from '../../../models/Catalog';
import { IScriptObjectModel } from '../../../models/ScriptObjectModel';
import { IHookProvidedCall } from '../../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../../hooks/useNetworkCallForDataSource';
import { useDispatch } from 'react-redux';

export interface ISaveCatalogParams {
    catalog: ICatalog<any>;
}
export const useSaveCatalog = (): IHookProvidedCall<ISaveCatalogParams, ICatalog<IScriptObjectModel> | undefined> => {
    const [currentSpace] = useCurrentSpace();
    const catalogDispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const postCatalog = async (params: ISaveCatalogParams) => {
        return await triggerCall(
            saveCatalogNetworkCall(currentSpace, params.catalog, getCancelableDispatch(catalogDispatch))
        );
    };

    return {
        execute: postCatalog,
        ...rest
    };
};
