import React, { useRef, useEffect } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export interface IScrollZoneProps extends React.HTMLAttributes<HTMLDivElement> {
    autoHide?: boolean;
    scrollRef?: React.RefObject<HTMLDivElement>;
}

export const ScrollZone: React.FC<IScrollZoneProps> = ({ children, autoHide = true, scrollRef, ...props }) => {
    const ref = useRef<OverlayScrollbarsComponent>(null);

    useEffect(() => {
        if (scrollRef && scrollRef.current) {
            const osInstance = ref.current?.osInstance();
            osInstance?.scroll({ el: scrollRef?.current, block: { y: 'begin', x: 'nearest' } }, 750);
        }
    }, [scrollRef]);

    return (
        <OverlayScrollbarsComponent
            options={{
                scrollbars: { autoHide: autoHide ? 'scroll' : 'never' },
                overflowBehavior: { y: 'scroll' },
                sizeAutoCapable: false
            }}
            ref={ref}
            {...props}
        >
            {children}
        </OverlayScrollbarsComponent>
    );
};
