import { httpClient } from './HttpClient';
import { SPACE_ENDPOINT } from '../constants/ApiEndpoint';
import { tryUseCachedForGetReq, UseStaleWhileFetchingStrategy } from './StaleWhileFetching';
import { IBundle } from '../models/Bundle';
import {
    IBundleAction,
    bundleSetAction,
    bundleLoadAction,
    bundleErrorAction,
    bundleLoadItemAction,
    bundleDeleteItemAction,
    bundleErrorItemAction
} from '../../../redux/spaceKeyed/bundle/BundleActions';

const bundlePath = (spaceName: string, projectId: string) => `${SPACE_ENDPOINT}/${spaceName}/${projectId}/bundle`;

export const getBundlesLegacy = async (
    spaceName: string,
    projectId: string,
    dispatch: (action: IBundleAction) => void
): Promise<void> => {
    try {
        dispatch(bundleLoadAction());

        const response = await httpClient.get<IBundle[]>(bundlePath(spaceName, projectId));

        dispatch(bundleSetAction(response.data));
    } catch (error) {
        dispatch(bundleErrorAction(error));
    }
};

export const getBundlesSWF = async (
    spaceName: string,
    projectId: string,
    dispatch: (action: IBundleAction) => void
): Promise<void> => {
    await tryUseCachedForGetReq(
        bundlePath(spaceName, projectId),
        httpClient.get(bundlePath(spaceName, projectId)),
        data => dispatch(bundleSetAction(data as IBundle[])),
        e => dispatch(bundleErrorAction(e))
    );
};

export const getBundles = UseStaleWhileFetchingStrategy ? getBundlesSWF : getBundlesLegacy;

const createBundlesPath = (spaceName: string, projectId: string, bundleName: string) =>
    `${SPACE_ENDPOINT}/${spaceName}/${projectId}/bundle?name=${bundleName}`;

export const createBundle = async (
    spaceName: string,
    projectId: string,
    bundleName: string,
    dispatch: (action: IBundleAction) => void
): Promise<void> => {
    await httpClient.post(createBundlesPath(spaceName, projectId, bundleName));
    await getBundles(spaceName, projectId, dispatch);
};

const deleteBundlePath = (spacename: string, projectId: string, bundleId: string) =>
    `${bundlePath(spacename, projectId)}/${bundleId}/`;

export const deleteBundle = async (
    spaceName: string,
    projectId: string,
    bundle: IBundle,
    dispatch: (action: IBundleAction) => void
): Promise<void> => {
    try {
        dispatch(bundleLoadItemAction(bundle.key));

        await httpClient.delete(deleteBundlePath(spaceName, projectId, bundle.key));

        dispatch(bundleDeleteItemAction(bundle.key));
    } catch (error) {
        dispatch(bundleErrorItemAction(bundle.key, error));
    }
};
