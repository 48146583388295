export interface IChosenValue {
    chooseType: 'mediaSource' | 'clearValue';
    chosenItem?: string;
}

export interface IChooseState {
    state: 'off' | 'choosing' | 'hasChosenValue';
    chosenValue?: IChosenValue;
}

export type ChooseActionType = 'cancelChoose' | 'beginChoose' | 'choose';
export interface IChooseAction {
    actionType: ChooseActionType;
    chooseType?: string;
    chosenValue?: IChosenValue;
}

export const ChooseMediaSourceReducer: <T extends IChooseAction = IChooseAction>(
    state: IChooseState,
    action: T
) => IChooseState = (state, { actionType, chooseType, chosenValue }) => {
    switch (actionType) {
        case 'beginChoose': {
            if (!chooseType) {
                throw new Error('must set choose type');
            }
            return { ...state, chooseType: chooseType, chosenValue: undefined, state: 'choosing' };
        }
        case 'cancelChoose': {
            return {
                ...state,
                chooseType: undefined,
                chosenValue: undefined,
                state: 'off'
            };
        }
        case 'choose': {
            return {
                ...state,
                chosenValue,
                state: 'hasChosenValue'
            };
        }
    }
};
