/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { IFieldEditorProps, IObjectEditorProps, FieldLayoutHint } from '../ObjectEditor';
import { StringEditor } from '../../../components/stringEditor';
import { MotiveTypes } from '../../../constants/MotiveTypes';
import { IntEditor } from '../../../components/intEditor';
import { LocalizedTextEditor } from '../../../components/localizedTextEditor';
import { BooleanEditor } from '../../../components/booleanEditor/BooleanEditor';
import { FloatEditor } from '../../../components/floatEditor/FloatEditor';
import { IScriptObjectModel } from '../../../shared/motive/models/ScriptObjectModel';
import { DateTimeEditor } from '../../../components/dateTimeEditor';
import { TimeSpanEditor } from '../../../components/timeSpanEditor/TimeSpanEditor';
import { ColorEditor } from '../../../components/colorEditor/ColorEditor';
import { MediaItemEditor } from '../../../components/mediaItemEditor';
import { ValueDefinitionEditor } from '../../../components/valueDefinitionEditor/valueDefinitionEditor';
import { LocalizedMediaEditor } from '../localizedMediaEditor';
import { BehaviourModelBindingsEditor } from '../../../components/behaviourModelBindingsEditor';
import { BehaviourStateInputEditor } from '../../../components/behaviourStateInputEditor';
import { VariableValueConditionEditor } from '../../../components/variableValueConditionEditor';
import { ScriptLauncherEditor } from '../../../components/scriptLauncherEditor';
import { CustomEventEditor } from '../../../components/customEventEditor';
import { FacialExpressionsPlayer } from '../../../components/facialExpressionsPlayer/FacialExpressionsPlayer';

export interface ICustomFieldEditorInfo {
    layoutHint?: FieldLayoutHint | undefined;
    editor: React.FC<IFieldEditorProps<any>>;
}

const defaultFieldEditorMap = new Map<string, ICustomFieldEditorInfo>();
defaultFieldEditorMap.set(MotiveTypes.STRING, {
    layoutHint: 'newline',
    editor: StringEditor
});
defaultFieldEditorMap.set(MotiveTypes.LOCALIZED_TEXT, {
    layoutHint: 'newline',
    editor: LocalizedTextEditor
});
defaultFieldEditorMap.set(MotiveTypes.BOOLEAN, {
    layoutHint: 'inline',
    editor: BooleanEditor
});
defaultFieldEditorMap.set(MotiveTypes.INT, {
    layoutHint: 'newline',
    editor: IntEditor
});
defaultFieldEditorMap.set(MotiveTypes.FLOAT, {
    layoutHint: 'newline',
    editor: FloatEditor
});
defaultFieldEditorMap.set(MotiveTypes.DATE_TIME, {
    layoutHint: 'newline',
    editor: DateTimeEditor
});
defaultFieldEditorMap.set(MotiveTypes.TIME_SPAN, {
    layoutHint: 'newline',
    editor: TimeSpanEditor
});
defaultFieldEditorMap.set(MotiveTypes.COLOR, {
    layoutHint: 'newline',
    editor: ColorEditor
});
defaultFieldEditorMap.set(MotiveTypes.CUSTOM_EVENT, {
    layoutHint: 'newline',
    editor: CustomEventEditor
});
defaultFieldEditorMap.set(MotiveTypes.VALUE_DEFINITION, {
    layoutHint: 'newline',
    editor: ValueDefinitionEditor
});
defaultFieldEditorMap.set(MotiveTypes.LOCALIZED_MEDIA, {
    layoutHint: 'inline',
    editor: LocalizedMediaEditor
});
export const CustomFieldEditorFactory = (type: string): ICustomFieldEditorInfo | undefined => {
    return defaultFieldEditorMap.get(type);
};

export interface ICustomObjectEditorInfo {
    showFields?: string[];
    editor: React.FC<IObjectEditorProps<IScriptObjectModel>> | undefined;
}

const customObjectEditorMap = new Map<string, ICustomObjectEditorInfo>();

customObjectEditorMap.set(MotiveTypes.MOTIVE_SCRIPT, { editor: undefined, showFields: ['name'] });
customObjectEditorMap.set(MotiveTypes.BEHAVIOUR_MODEL_BINDINGS, {
    editor: BehaviourModelBindingsEditor as React.FC<IObjectEditorProps<IScriptObjectModel>>
});
customObjectEditorMap.set(MotiveTypes.BEHAVIOUR_STATE_INPUT, {
    editor: BehaviourStateInputEditor as React.FC<IObjectEditorProps<IScriptObjectModel>>
});
customObjectEditorMap.set(MotiveTypes.VARIABLE_VALUE_CONDITION, {
    editor: VariableValueConditionEditor as React.FC<IObjectEditorProps<IScriptObjectModel>>
});
customObjectEditorMap.set(MotiveTypes.MEDIA_ITEM, {
    editor: MediaItemEditor as React.FC<IObjectEditorProps<IScriptObjectModel>>
});
customObjectEditorMap.set(MotiveTypes.VALUE_DEFINITION, {
    editor: ValueDefinitionEditor as React.FC<IObjectEditorProps<IScriptObjectModel>>
});
customObjectEditorMap.set(MotiveTypes.SCRIPT_LAUNCHER, {
    editor: ScriptLauncherEditor as React.FC<IObjectEditorProps<IScriptObjectModel>>
});

customObjectEditorMap.set(MotiveTypes.BLEND_SHAPE_FACIAL_EXPRESSION, {
    editor: FacialExpressionsPlayer as React.FC<IObjectEditorProps<IScriptObjectModel>>
});

export const CustomObjectEditorFactory = (type: string): ICustomObjectEditorInfo | undefined => {
    return customObjectEditorMap.get(type);
};
