import css from '@emotion/css';
import { IToken } from '../../shared/contexts/styleContext';
import chroma from 'chroma-js';
import { SIDEBAR_MIN_WIDTH } from '../../containers/sidebarContainer/SidebarContainer.style';

export const containerStyle = css`
    display: flex;
    height: 100%;
    width: 100%;
    min-width: fit-content;
`;

export const fillStyle = css`
    flex: 1;
`;

export const navBarUserIcon = (token: IToken) => css`
    display: flex;
    align-items: center;
    border-radius: ${token.spacings.medium};
    margin-right: ${token.spacings.small};
`;
export const closeIconStyle = css`
    height: 24px;
    min-height: 24px;
    max-height: 24px;
`;
export const navBarUserHover = (token: IToken) => css`
    cursor: pointer;
    transition-duration: 0.1s;
    user-select: none;
    border-radius: ${token.spacings.large};
    white-space: nowrap;

    &:hover {
        background: rgba(255, 255, 255, 0.3);
    }
`;

export const navBarUserIconWrapper = css`
    display: inline-flex;
    align-items: center;
`;

export const navBarNameText = (token: IToken) => css`
    align-items: center;
    padding-left: ${token.spacings.smaller};
    padding-right: ${token.spacings.small};
    color: ${token.colors.staticColors.white};
`;

export const navBarFixedStyle = css`
    position: fixed;
`;

export const topNavContainer = (token: IToken) => css`
    color: ${token.colors.font.tertiary};
    align-self: flex-end;
    margin-right: 30px;
    display: flex;
`;

export const topTabMenu = (token: IToken, isActive: boolean) => css`
    font-size: ${token.fontSizes.medium};
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: auto;
    border-radius: ${token.borderRadii.small} ${token.borderRadii.small} 0 0;
    padding: 4px ${token.spacings.small};
    cursor: pointer;
    display: flex;
    & span {
        display: flex;
        align-items: center;
        white-space: no-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    & button {
        margin-left: 3px;
        color: white;
        width: 15%;
    }
    ${isActive &&
        `background-color: ${chroma(token.colors.bar.dashboardNavbar)
            .darken()
            .css()};`}
    ${!isActive &&
        `&:hover {
    background-color: rgba(0, 0, 0, 0.2);
}`}
`;

export const logoContainerStyle = css`
    width: 20vw;
    min-width: ${SIDEBAR_MIN_WIDTH}px;
    display: flex;
`;

export const navbarContextMenuStyle = (token: IToken) => css`
    margin-top: 10px;
    border-radius: ${token.borderRadii.small};
`;
