import React from 'react';
import { Checkbox } from '../../core-ui/checkbox';
import { Size } from '../../core-ui/constants/Size';
import { IFieldEditorProps } from '../../containers/objectEditor';
import { EditModeAware } from '../editableOnFocus/EditModeAware';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IBooleanEditorProps extends IFieldEditorProps<boolean> {}

export const DATA_TEST_ID = 'booleanEditor';

export const BooleanEditor: React.FC<IBooleanEditorProps> = ({ onChange, value, className }) => {
    const isChecked = !!value;

    const handleClick = () => {
        onChange && onChange(!isChecked);
    };

    const renderEditor = () => {
        return (
            <Checkbox
                dataTestId={DATA_TEST_ID}
                isChecked={isChecked}
                onClick={handleClick}
                size={Size.SMALL}
                className={className}
            />
        );
    };

    const renderReadOnly = () => {
        return (
            <Checkbox
                dataTestId={DATA_TEST_ID}
                isChecked={isChecked}
                onClick={() => {}}
                size={Size.SMALL}
                className={className}
            />
        );
    };

    return <EditModeAware readonlyModeRender={renderReadOnly} editModeRender={renderEditor} />;
};
