import * as React from 'react';
import { IResourceWrapper } from '../../../../shared/motive/models/Script';
import { IScriptObjectEventEditorProps, ScriptObjectEventEditor } from './ScriptObjectEventEditor';
import { useCurrentLanguageSettings } from '../../../../shared/motive/stores/editorLocale/EditorLocale';
import { LocalizationText } from '../../../../localization/Localization';
import { LocalizationKeys } from '../../../../localization/LocalizationKeys';
import { useCatalogItemResolver } from '../../../../shared/motive/hooks/useCatalogs/useCatalogItem/useCatalogItem';
import { useCreateObjectEventLinkFrame } from '../../../../shared/motive/hooks/useCreateObjectEventLinkFrame/useCreateObjectEventLinkFrame';
import { getResourceDisplayName } from '../../../../util/ObjectDefinitionsUtils';
import { IScriptFrameAction, ScriptActionType } from '../../../../shared/motive/reducers/ScriptEditorReducers';
import { useTypeDefinitions } from '../../../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { useDispatch, useSelector } from 'react-redux';
import {
    SelectScriptEditorSelectedFrameId,
    SelectScriptEditorCurrentScript
} from '../../../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';
import { useState, useCallback, useEffect, useLayoutEffect } from 'react';
import { Button, ButtonVariant } from '../../../../core-ui/button';
import { InputField } from '../../../../core-ui/inputField';
import { enumInlineLayout, createEventConditionButtonStyle } from '../EnumEditor.style';
import { Size } from '../../../../core-ui/constants/Size';
import { IconTypes } from '../../../../core-ui/constants/IconTypes';
import { MotiveTypes } from '../../../../constants/MotiveTypes';
import { uniqueMotiveId } from '../../../../util/MotiveUtils';
import { IEnumItemReference } from '../../../../shared/motive/models/TypeDefinition';
import { useStyle } from '../../../../shared/hooks/useStyle';
import { resourceEventLink } from '../../../../components/resourceWrapper/ResourceWrapper.style';

export interface IResourceScriptObjectEventEditorProps extends IScriptObjectEventEditorProps {
    resourceWrapper: IResourceWrapper;
}

const CUSTOM_EVENT_ID = '+item';

/**
 * Child class of ScriptObjectEventEditor, adds functionality to create custom events and create event links
 */
export const ResourceScriptObjectEventEditor = ({
    resourceWrapper,
    ...props
}: IResourceScriptObjectEventEditorProps) => {
    const [isAddingItem, setIsAddingItem] = useState(false);
    const [isEditingItem, setIsEditingItem] = useState(false);
    const [newEventName, setNewEventName] = useState<string>();
    const [pendingEvent, setPendingEvent] = useState<IEnumItemReference | undefined>(undefined);

    const language = useCurrentLanguageSettings();
    const catalogItemResolver = useCatalogItemResolver();
    const createEventLink = useCreateObjectEventLinkFrame();
    const typeDefs = useTypeDefinitions();
    const scriptDispatch = useDispatch();
    const selectedFrameId = useSelector(SelectScriptEditorSelectedFrameId);
    const script = useSelector(SelectScriptEditorCurrentScript);
    const token = useStyle();

    // Ignore any system events, shouldn't be able to fire these from a Resource
    const ignoreEvents = ['open', 'close', 'activate', 'deactivate'];

    const enumOpts = props.options.filter(o => !ignoreEvents.includes(o.label));

    resourceWrapper?.events?.forEach(e => {
        enumOpts.push({ value: e.id, label: e.name });
        props.itemRefs.push({ itemId: e.id, name: e.name });
    });

    const text: string =
        LocalizationText(language.editorLanguage, LocalizationKeys.AddCustomEvent) ?? 'Add Custom Event';

    enumOpts.push({ value: CUSTOM_EVENT_ID, label: text });
    props.itemRefs.push({ itemId: CUSTOM_EVENT_ID, name: 'ADD ITEM' });

    const handleChange = useCallback(
        (event?: IEnumItemReference) => {
            if (event && CUSTOM_EVENT_ID === event.itemId) {
                setIsAddingItem(true);
            } else {
                props.onChange(event);
            }
        },
        [props.onChange]
    );

    const handleEventLink = () => {
        if (props.value) {
            const displayName = getResourceDisplayName(
                resourceWrapper,
                typeDefs,
                language,
                catalogItemResolver,
                script
            );
            const action: IScriptFrameAction = {
                type: ScriptActionType.FRAME_ADD,
                targetFrameId: selectedFrameId,
                frame: createEventLink(`On ${displayName} ${props.value.name}`, resourceWrapper.resource, props.value)
            };

            scriptDispatch(action);
        }
    };
    const handleEditEventName = () => {
        if (newEventName) {
            const events = resourceWrapper?.events?.map(e => {
                if (e.id === props.value?.itemId) {
                    return {
                        id: e.id,
                        name: newEventName
                    };
                } else {
                    return e;
                }
            });

            const updatedResource = { ...resourceWrapper, events: events };

            scriptDispatch({
                type: ScriptActionType.RESOURCE_UPDATE,
                resourceWrapper: updatedResource,
                targetFrameId: selectedFrameId
            });
        }

        setNewEventName('');
        setIsEditingItem(false);
    };

    const handleStartEditItem = () => {
        setNewEventName(props.value?.name);
        setIsEditingItem(true);
    };

    const handleCreateNewEvent = () => {
        const eventExists = enumOpts.find(c => c.label === newEventName);

        if (newEventName) {
            const evtId = eventExists?.value ?? uniqueMotiveId();

            if (!eventExists) {
                const evt = {
                    type: MotiveTypes.RESOURCE_EVENT,
                    id: evtId,
                    name: newEventName
                };

                const events = resourceWrapper?.events ? [...resourceWrapper.events] : [];

                events.push(evt);

                const updatedResource = { ...resourceWrapper, events: events };

                scriptDispatch({
                    type: ScriptActionType.RESOURCE_UPDATE,
                    resourceWrapper: updatedResource,
                    targetFrameId: selectedFrameId
                });
            }

            setPendingEvent({ itemId: evtId, name: newEventName });
            setNewEventName('');
            setIsAddingItem(false);
        }
    };

    useLayoutEffect(() => {
        if (pendingEvent) {
            handleChange(pendingEvent);
            setPendingEvent(undefined);
        }
    }, [pendingEvent, handleChange]);

    const renderAddItem = () => {
        return (
            <div css={enumInlineLayout}>
                <InputField defaultValue={newEventName} onChange={setNewEventName}></InputField>
                <>
                    <Button
                        onClick={() => setIsAddingItem(false)}
                        variant={ButtonVariant.CIRCULAR}
                        size={Size.SMALL}
                        icon={IconTypes.TIMES}
                        css={() => `margin-left:5px`}
                    ></Button>
                    <Button
                        onClick={handleCreateNewEvent}
                        variant={ButtonVariant.CIRCULAR}
                        size={Size.SMALL}
                        icon={IconTypes.CHECKMARK}
                        css={() => `margin-left:5px`}
                    ></Button>
                </>
            </div>
        );
    };

    const renderEditItem = () => {
        return (
            <div css={enumInlineLayout}>
                <InputField defaultValue={newEventName} onChange={setNewEventName}></InputField>
                <>
                    <Button
                        onClick={() => setIsEditingItem(false)}
                        variant={ButtonVariant.CIRCULAR}
                        size={Size.SMALL}
                        icon={IconTypes.TIMES}
                        css={() => `margin-left:5px`}
                    ></Button>
                    <Button
                        onClick={handleEditEventName}
                        variant={ButtonVariant.CIRCULAR}
                        size={Size.SMALL}
                        icon={IconTypes.CHECKMARK}
                        css={() => `margin-left:5px`}
                    ></Button>
                </>
            </div>
        );
    };

    const isResourceEvent = resourceWrapper?.events?.some(e => e.id === props.value?.itemId);

    const renderSelectItem = () => {
        return (
            <>
                <div css={enumInlineLayout}>
                    <ScriptObjectEventEditor {...props} options={enumOpts} onChange={handleChange} />
                    {isResourceEvent && (
                        <Button
                            onClick={() => handleStartEditItem()}
                            variant={ButtonVariant.CIRCULAR}
                            size={Size.SMALL}
                            icon={IconTypes.EDIT}
                            css={() => `margin-left:5px`}
                        ></Button>
                    )}
                    <Button
                        disabled={!props.value}
                        icon={IconTypes.PLUS}
                        onClick={() => props.value && handleEventLink()}
                    ></Button>
                </div>
            </>
        );
    };

    return <>{isAddingItem ? renderAddItem() : isEditingItem ? renderEditItem() : renderSelectItem()}</>;
};
