// istanbul ignore file MOTIVE_EXCEPTION
import React, { useMemo } from 'react';
import { storyFlowStyle } from './StoryFlowContainer.style';
import { FrameInspectorContainer } from '../frameInspectorContainer/FrameInspectorContainer';
import { useStyle } from '../../shared/hooks/useStyle';
import { ObjectReferenceContextContainer } from '../objectReferenceContextContainer/ObjectReferenceContextContainer';
import { FrameToolsContextContainer } from '../../hooks/useFrameTools';
import { FrameTree } from '../../components/frameTree';
import { IScriptEditorSessionDependentProps } from '../../components/scriptEditor/ScriptLoader';

export const StoryFlowContainer: React.FC<IScriptEditorSessionDependentProps> = ({
    script,
    selectedFrame
}): React.ReactElement => {
    const tokens = useStyle();

    const frameInspector = useMemo(() => {
        return <FrameInspectorContainer script={script} selectedFrame={selectedFrame} />;
    }, [selectedFrame]);

    return (
        <ObjectReferenceContextContainer selectedFrame={selectedFrame}>
            <FrameToolsContextContainer>
                <div css={storyFlowStyle(tokens)}>
                    <FrameTree script={script} selectedFrame={selectedFrame} />
                    {frameInspector}
                </div>
            </FrameToolsContextContainer>
        </ObjectReferenceContextContainer>
    );
};
