import { useEffect, DependencyList } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useBeforeUnload = (value: ((evt: BeforeUnloadEvent) => any) | string, dependencies: DependencyList) => {
    const handleBeforeunload = (evt: BeforeUnloadEvent) => {
        let returnValue;
        if (typeof value === 'function') {
            returnValue = value(evt);
        } else {
            returnValue = value;
        }
        if (returnValue) {
            evt.preventDefault();
            evt.returnValue = returnValue;
        }
        return returnValue;
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeunload);
        return () => window.removeEventListener('beforeunload', handleBeforeunload);
    }, [dependencies]);
};
