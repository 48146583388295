/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { Button, ButtonVariant } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Size } from '../../core-ui/constants/Size';
import { createScriptObject } from '../../util/MotiveUtils';
import { useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo';
import { useGetCatalogById } from '../../shared/motive/hooks/useCatalogs';
import { useAddCatalogItem } from '../../shared/motive/hooks/useCatalogs/useAddCatalogItem/useAddCatalogItem';
import { CatalogItemList } from '../../components/catalogItemList';
import { QuickObjectEditor } from '../../components/quickObjectEditor';
import { IMediaItem, IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { HeightUsedScrollContainer } from '../heightUsedScrollContainer';
import { MediaUploadContainer } from '../mediaUploadContainer/MediaUploadContainer';

export interface IDrawerSelectCatalogItemContainerProps {
    catalogId: string;
    title: string;
    onSelectItem: (item: IScriptObjectModel) => void;
}

export const DrawerSelectCatalogItemContainer: React.FC<IDrawerSelectCatalogItemContainerProps> = ({
    catalogId,
    onSelectItem
}) => {
    const [isAddingItem, setIsAddingItem] = useState(false);
    const { execute: saveItem } = useAddCatalogItem();
    const typeDefs = useObjectDefinitions();
    const { data: catalog } = useGetCatalogById(catalogId);

    const handleSelectItem = (item: IScriptObjectModel) => {
        onSelectItem(item);
    };

    const handleAddNewItem = async (object: IScriptObjectModel) => {
        const newItem = await saveItem({ catalogId: catalogId, item: object });

        if (!!newItem) {
            handleSelectItem(newItem);
        }
    };

    const handleOnUpload = (res: IHostedMediaSource) => {
        const newMediaItem: IMediaItem = {
            ...createScriptObject(MotiveTypes.MEDIA_ITEM, typeDefs),
            title: (res as IHostedMediaSource).title as string,
            source: res as IHostedMediaSource
        };
        handleAddNewItem(newMediaItem);
    };

    return (
        <HeightUsedScrollContainer>
            {catalog && (
                <>
                    {/* <div>{catalog.title}</div> */}
                    {catalog.objectType === MotiveTypes.MEDIA_ITEM && (
                        <MediaUploadContainer onUpload={handleOnUpload} />
                    )}
                    <div>
                        {catalog.items && (
                            <CatalogItemList
                                catalogId={catalog.id}
                                items={catalog.items}
                                onSelectItem={handleSelectItem}
                            />
                        )}
                    </div>
                    {isAddingItem ? (
                        <>
                            <div>
                                <QuickObjectEditor
                                    type={catalog.objectType}
                                    onSave={handleAddNewItem}
                                    title={'Add Item'}
                                    onCancel={() => {
                                        setIsAddingItem(false);
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <Button
                            variant={ButtonVariant.CIRCULAR}
                            size={Size.SMALL}
                            icon={IconTypes.PLUS}
                            onClick={() => setIsAddingItem(true)}
                        />
                    )}
                </>
            )}
        </HeightUsedScrollContainer>
    );
};
