import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';
import chroma from 'chroma-js';

export const dashboardSection = (token: IToken) => css`
    padding: 0 ${token.spacings.small};
    background-color: #2f2e41;
`;

export const sidebarLayoutStyle = (token: IToken, height: number) => css`
    grid-template-rows: ${height}px 1fr;
    background-color: ${chroma(token.colors.bar.dashboardNavbar)
        .darken()
        .css()};
    display: grid;
    align-items: center;
    height: 100%;
`;

export const spaceSectionStyle = (token: IToken) => css`
    padding: 0 ${token.spacings.small} ${token.spacings.small} ${token.spacings.small};
`;
