import { SPACE_ENDPOINT } from '../constants/ApiEndpoint';
import { IScriptObjectModel } from '../models/ScriptObjectModel';
import { httpClient } from './HttpClient';
import {
    catalogItemAppendAction,
    catalogItemDeleteAction,
    catalogItemUpdateAction
} from '../../../redux/spaceKeyed/catalog/CatalogItemActions';
import { AllCatalogActions } from '../../../redux/spaceKeyed/catalog/CatalogsReducer';

export const postItemPath = (spaceName: string, catalogId: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/catalogs/${catalogId}/items`;
};

export const putItemPath = (spaceName: string, catalogId: string, itemId: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/catalogs/${catalogId}/items/${itemId}/`;
};

export const deleteItemPath = putItemPath;

export const postCatalogItemNetworkCall = async (
    spaceName: string,
    catalogId: string,
    item: IScriptObjectModel,
    dispatch: (action: AllCatalogActions) => void
): Promise<IScriptObjectModel | undefined> => {
    try {
        const response = await httpClient.post(postItemPath(spaceName, catalogId), item);

        dispatch(catalogItemAppendAction(catalogId, response.data));

        return response.data as IScriptObjectModel;
    } catch (error) {}

    return undefined;
};

export const putCatalogItemNetworkCall = async (
    spaceName: string,
    catalogId: string,
    item: IScriptObjectModel,
    dispatch: (action: AllCatalogActions) => void
): Promise<IScriptObjectModel | undefined> => {
    try {
        const response = await httpClient.put(putItemPath(spaceName, catalogId, item.id), item);

        dispatch(catalogItemUpdateAction(item));

        return response.data as IScriptObjectModel;
    } catch (error) {}

    return undefined;
};

export const deleteCatalogItemNetworkCall = async (
    spaceName: string,
    catalogId: string,
    catalogItemId: string,
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    try {
        await httpClient.delete(deleteItemPath(spaceName, catalogId, catalogItemId));
        dispatch(catalogItemDeleteAction(catalogId, catalogItemId));
    } catch (error) {
        throw error;
    }
};
