import React from 'react';
import { IActivitiesProps } from '../../containers/activitiesContainer';
import { UserIcon } from '../../core-ui/userIcon';
import { IActivity } from '../../shared/motive/models/Activity';
import { useStyle } from '../../shared/hooks/useStyle';
import {
    activityText,
    activityActionText,
    activityItemWrapper,
    activityAvatar,
    activityContent,
    activityAction
} from './Activities.style';
import { IToken } from '../../shared/contexts/styleContext';
import { dateYMD } from '../../util/DateUtils';
import { times } from 'lodash-es';
import { LoadingSkeleton } from '../../core-ui/loadingSkeleton/LoadingSkeleton';
import i18next from 'i18next';

interface IFormattedAction {
    color: string;
    text: string;
}
//Determines a color and the text for a particular action
//this will be more useful when we track more activity actions
//we should look at storing the action as a properly constructed verb
const getActionColor = (action: string, type: string): IFormattedAction => {
    const formattedAction: IFormattedAction = {
        color: `rgba(0, 0, 0, 0.2)`,
        text: i18next.t('edited')
    };

    switch (type.toLowerCase()) {
        case 'script':
            formattedAction.text = i18next.t('script');
            formattedAction.color = `rgb(227, 252, 239)`;
            break;
        case 'catalog':
            formattedAction.text = i18next.t('catalog');
            formattedAction.color = `rgb(245, 229, 255)`;
            break;
    }

    return formattedAction;
};

const renderActivityItem = (idx: number, token: IToken, activity?: IActivity) => {
    const fullName = `${activity?.UserFirstName} ${activity?.UserLastName}`;

    return (
        <div css={activityItemWrapper(token)} key={idx}>
            <div css={activityAvatar}>
                {activity ? (
                    <UserIcon size={`24`} name={fullName}></UserIcon>
                ) : (
                    <LoadingSkeleton circle height={24} width={24} />
                )}
            </div>
            <div css={activityContent}>
                {activity ? (
                    <>
                        <span css={activityActionText(token)}>
                            {i18next.t('edited')}
                            <span
                                css={activityAction(token, getActionColor(activity.Action, activity.TargetType).color)}
                            >
                                {getActionColor(activity.Action, activity.TargetType).text}
                            </span>
                        </span>
                        <span css={activityActionText(token)}>{` ${activity.TargetName}`}</span>
                        <span css={activityText(token)}>{`${fullName} · ${dateYMD(activity.Timestamp)}`}</span>
                    </>
                ) : (
                    <>
                        <LoadingSkeleton width={80} />
                        <LoadingSkeleton width={120} />
                    </>
                )}
            </div>
        </div>
    );
};

export const Activities: React.FC<IActivitiesProps> = ({ activityStatus }) => {
    const token = useStyle();

    return (
        <>
            {!activityStatus.isLoading
                ? activityStatus.data.map((activity, idx) => renderActivityItem(idx, token, activity))
                : times(16, idx => renderActivityItem(idx, token))}
        </>
    );
};
