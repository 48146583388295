import { css } from '@emotion/core';

export const ErrorStyle = css`
    margin-left: auto;
    margin-right: auto;
    margin-top: 20em;
    width: fit-content;
    text-align: center;
`;

export const TextStyle = css`
    font-weight: bold;
`;
