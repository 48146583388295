/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { matchPath, useLocation } from 'react-router';
import { DashboardConvoAI } from '../../components/dashboardConvoAI';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useConvoAIAgentManagementRoute } from '../../routes/convoAIAgentRoute/ConvoAIAgentRoute';
import { useConvoAIIntentsRoute } from '../../routes/convoAIIntentsRoute';
import { useGetAllCatalogsByType } from '../../shared/motive/hooks/useCatalogs';
import { useCurrentProject } from '../../shared/motive/hooks/useCurrentProject';
import { useProjectConfig } from '../../shared/motive/hooks/useProjectConfigs/useProjectConfigs';
import { ConversationalAgent } from '../../shared/motive/models/ConversationalAgent';
import { ConversationalIntent } from '../../shared/motive/models/ConversationalIntent';
import { filterCatalogsByProject, mergeCatalogItemsIntoSingleArray } from '../../util/CatalogUtils';

export const DashboardConvoAIContainer: React.FC = props => {
    // Routing
    const location = useLocation();
    const { goTo: goToIntentCatalogsRoute, fullPath: intentsFullPath } = useConvoAIIntentsRoute();
    const { goTo: gotoAgentManagementRoute, fullPath: agentsFullPath } = useConvoAIAgentManagementRoute();
    const { catalogId, intentId } = (matchPath(location.pathname, { path: intentsFullPath })?.params as any) ?? {};
    const { agentId } = (matchPath(location.pathname, { path: agentsFullPath })?.params as any) ?? {};

    // Network Calls
    const { data: intentCatalogs } = useGetAllCatalogsByType(MotiveTypes.WATSON_INTENT);
    const { data: agentCatalogs } = useGetAllCatalogsByType(MotiveTypes.WATSON_ASSISTANT);
    const [projectId] = useCurrentProject();
    const { data: projectConfig } = useProjectConfig(projectId);

    const handleGoToIntentManagement = (catalogId?: string, intentId?: string) => {
        goToIntentCatalogsRoute({ pathParams: { catalogId: catalogId, intentId: intentId } });
    };

    const handleGoToAgentManagement = (agentId?: string) => {
        gotoAgentManagementRoute({ pathParams: { agentId: agentId } });
    };

    // Filtered Data
    const intentCatalog =
        catalogId && filterCatalogsByProject(intentCatalogs, projectConfig).find(cat => cat.id === catalogId);
    const intent =
        intentCatalog &&
        (intentCatalog.items?.find(intent => intent.id === intentId) as ConversationalIntent | undefined);
    const agent =
        agentId &&
        mergeCatalogItemsIntoSingleArray(filterCatalogsByProject(agentCatalogs, projectConfig)).find(
            (agent: ConversationalAgent) => agent.id === agentId
        );

    return (
        <DashboardConvoAI
            goToAgentManagement={handleGoToAgentManagement}
            goToIntentManagement={handleGoToIntentManagement}
            intentCatalog={intentCatalogs.find(cat => cat.id === catalogId)}
            intent={intent}
            agent={agent}
        >
            {props.children}
        </DashboardConvoAI>
    );
};
