import { ICollaborator, IPendingCollaborator } from '../../../shared/motive/models/Collaborator';
import { CollaboratorActions } from './CollaboratorActions';
import produce from 'immer';

export interface ICollaboratorState {
    collaborators: ICollaborator[];
    pendingCollaborators: IPendingCollaborator[];
}

export const defaultCollaboratorState: ICollaboratorState = {
    collaborators: [],
    pendingCollaborators: []
};

const sanitizeId = (id: number) => `${id}`;

export const CollaboratorReducer = produce((state: ICollaboratorState, action: CollaboratorActions) => {
    switch (action.type) {
        case 'collaborator/set': {
            state.collaborators = action.collaborators;
            break;
        }
        case 'collaborator/add': {
            if (state.collaborators.some(collab => collab.id === action.collaborator.id)) {
                throw new Error(
                    'Unable to add a collaborator, a collaborator with this ID already exists, did you mean to update?'
                );
            }

            state.collaborators.push(action.collaborator);
            break;
        }
        case 'collaborator/delete': {
            state.collaborators = state.collaborators.filter(collab => sanitizeId(collab.id) !== action.id);
            break;
        }
        case 'collaborator/clear': {
            state.collaborators = [];
            break;
        }

        case 'pendingCollaborator/set': {
            state.pendingCollaborators = action.pendingCollabs;
            break;
        }
        case 'pendingCollaborator/append': {
            if (!state.pendingCollaborators.some(pc => pc.InviteeEmail === action.pendingCollab.InviteeEmail)) {
                state.pendingCollaborators.push(action.pendingCollab);
            }
            break;
        }
        case 'pendingCollaborator/delete': {
            state.pendingCollaborators = state.pendingCollaborators.filter(pc => pc.InviteeEmail === action.email);
            break;
        }
    }
}, defaultCollaboratorState);
