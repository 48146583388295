import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const emptyStateIconStyle = (token: IToken) => css`
    color: ${token.colors.font.secondary};
    margin-top: 64px;
    margin-bottom: 32px;
`;

export const emptyStateMessageStyle = (token: IToken) => css`
    color: ${token.colors.font.secondary};
    margin-bottom: 32px;
    font-size: 1.2em;
`;
