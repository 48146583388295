import React from 'react';
import { NavBarContainer } from '../../containers/navBar';
import { AccountContainer } from '../../containers/accountContainer';

export const AccountRoute: React.FC = (): React.ReactElement => {
    return (
        <>
            <NavBarContainer>
                <AccountContainer />
            </NavBarContainer>
        </>
    );
};
