import React, { Dispatch, FC, useState } from 'react';
import { IAccountManagerViewModel } from '../../shared/motive/models/AccountManagerViewModel';
import { Heading } from '../../core-ui/heading';
import { FormControl } from '../../core-ui/formControl';
import { Size } from '../../core-ui/constants/Size';
import { setTwoFactorStatus } from '../../shared/motive/networking/AccountService';
import { Icon } from '../../core-ui/icon';
import { Loading } from '../../core-ui/loading';
import { Toggle } from '../../core-ui/toggle';
import { UserInfoActionType } from '../../redux/userInfo/UserInfoActions';

export const ToggleTwoFactorForm: FC<IAccountManagerViewModel & { dispatch: Dispatch<UserInfoActionType> }> = ({
    user: { isTwoFactorAuthenticationEnabled },
    dispatch
}) => {
    const [isToggleInteractable, setToggleInteractable] = useState(true);

    const toggleId = 'change-2fa-toggle';
    const setStatus = async () => {
        setToggleInteractable(false);
        await setTwoFactorStatus(!isTwoFactorAuthenticationEnabled, dispatch);
        setToggleInteractable(true);
    };

    const renderLoading = () => (
        <Heading size={Size.SMALL}>
            {isTwoFactorAuthenticationEnabled
                ? 'Disabling Two Factor Authentication'
                : 'Enabling Two Factor Authentication'}
        </Heading>
    );

    return (
        <form>
            <Heading size={Size.MEDIUM}>
                <Icon size={Size.MEDIUM} faIcon={isTwoFactorAuthenticationEnabled ? 'lock' : 'unlock'}></Icon>
                &nbsp; &nbsp;
                {isTwoFactorAuthenticationEnabled ? 'Enabled' : 'Disabled'}
            </Heading>
            <br></br>
            <Loading isLoaded={isToggleInteractable} loadingRender={renderLoading}>
                <FormControl
                    fieldId={toggleId}
                    required={true}
                    label="Two Factor Authentication"
                    helperText="Two Factor authentication keeps your account secure."
                >
                    <Toggle id={toggleId} value={!!isTwoFactorAuthenticationEnabled} onClick={setStatus}></Toggle>
                </FormControl>
            </Loading>
        </form>
    );
};
