import React from 'react';
import { HeadingSize, Size } from '../constants/Size';
import {
    baseLargestStyle,
    baseLargerStyle,
    baseLargeStyle,
    baseMediumStyle,
    baseSmallStyle,
    baseSmallerStyle,
    baseSmallestStyle
} from './Heading.style';
import { VisibleRef } from '../../shared/hooks/useResizeObserver/useResizeObserver';

export interface IHeadingProps {
    size?: HeadingSize;
    className?: string;
    visibleRef?: VisibleRef;
}

export const Heading: React.FC<IHeadingProps> = ({
    size = Size.MEDIUM,
    children,
    visibleRef,
    ...rest
}): React.ReactElement => {
    switch (size) {
        case Size.LARGEST:
            return (
                <h1 ref={visibleRef} css={baseLargestStyle} {...rest}>
                    {children}
                </h1>
            );
        case Size.LARGER:
            return (
                <h2 ref={visibleRef} css={baseLargerStyle} {...rest}>
                    {children}
                </h2>
            );
        case Size.LARGE:
            return (
                <h2 ref={visibleRef} css={baseLargeStyle} {...rest}>
                    {children}
                </h2>
            );
        case Size.MEDIUM:
            return (
                <h3 ref={visibleRef} css={baseMediumStyle} {...rest}>
                    {children}
                </h3>
            );
        case Size.SMALL:
            return (
                <h4 ref={visibleRef} css={baseSmallStyle} {...rest}>
                    {children}
                </h4>
            );
        case Size.SMALLER:
            return (
                <h5 ref={visibleRef} css={baseSmallerStyle} {...rest}>
                    {children}
                </h5>
            );
        case Size.SMALLEST:
            return (
                <h6 ref={visibleRef} css={baseSmallestStyle} {...rest}>
                    {children}
                </h6>
            );
        default:
            throw new Error('An invalid heading level was passed in.');
    }
};
