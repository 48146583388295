import React from 'react';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { ICatalogItemEditorProps, CatalogItemEditor } from '../catalogItemEditor';
import { IObjectEditorProps } from '../../containers/objectEditor';

export interface ICatalogProps {
    catalog?: ICatalog<IScriptObjectModel>;
    onClickCatalogItem?: ICatalogItemEditorProps['onClick'];
    onChange: IObjectEditorProps['onChange'];
    isLoading?: boolean;
    path: string;
}

export const Catalog: React.FC<ICatalogProps> = ({ catalog, onChange, path, onClickCatalogItem }) => {
    return (
        <>
            {catalog && (
                <div>
                    {catalog.items &&
                        catalog.items.map((catalogItem, index) => {
                            return (
                                <CatalogItemEditor
                                    key={catalogItem.id}
                                    onChange={onChange}
                                    path={`${path}[${index}]`}
                                    onClick={onClickCatalogItem}
                                    item={catalogItem}
                                />
                            );
                        })}
                </div>
            )}
        </>
    );
};
