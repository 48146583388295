import css from '@emotion/css';
import {
    containerFlexStyle,
    leftFlexStyle,
    rightFlexStyle,
    middleFlexStyle,
    subContentFlexStyle
} from './RowItem.layout.style';
import { IToken } from '../../shared/contexts/styleContext';
import chroma from 'chroma-js';

export const containerStyle = css`
    ${containerFlexStyle}
    padding: 12px 0px;
    outline: none;
    transition-duration: 0.1s;
    align-items: center;
`;

export const leftStyle = css`
    ${leftFlexStyle}
    margin-right: 4px;
    align-self: stretch;
`;
export const middleStyle = css`
    ${middleFlexStyle}
`;
export const rightStyle = css`
    ${rightFlexStyle}
    margin-left: 4px;
`;
export const subContentStyle = css`
    ${subContentFlexStyle}
`;

export const containerInactiveStyle = css`
    :focus {
        background-color: Gainsboro;
    }
`;

export const containerActiveStyle = css`
    background-color: green;
    color: white;

    :focus {
        filter: brightness(90%);
    }
`;

export const smallRowItemStyle = css`
    height: 16px;
`;

export const mediumRowItemStyle = css`
    height: 48px;
`;

export const largeRowItemStyle = css`
    height: 72px;
`;
export const onPressStyle = ({ colors }: IToken) => css`
    cursor: pointer;
    &:hover {
        background-color: ${chroma(colors.bar.tertiary)
            .alpha(0.2)
            .css()};
    }
`;
