import React from 'react';
import { ConversationalAgent } from '../../shared/motive/models/ConversationalAgent';
import { Text } from '../../core-ui/text';
import { Divider } from '../../core-ui/divider';
import { Size } from '../../core-ui/constants/Size';
import { agentSettingWrapperStyle, settingTextStyle } from './IntentCatalogSetting.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { Dropdown, IDropdownOption } from '../../core-ui/dropdown';
import { Heading } from '../../core-ui/heading';
import { CopyTextField } from '../../core-ui/copyTextField/CopyTextField';
import { IDialogflowAgent } from '../../shared/motive/models/DialogflowAgent';
import { IWatsonAssistant } from '../../shared/motive/models/WatsonAssistant';
import { MotiveTypes } from '../../constants/MotiveTypes';

interface IIntentCatalogSettingProps {
    currentAgent?: ConversationalAgent;
    agents?: ConversationalAgent[];
    onUpdateAgent: (agentId: string) => void;
    isEmpty?: boolean;
}

export const IntentCatalogSetting: React.FC<IIntentCatalogSettingProps> = props => {
    const token = useStyle();

    const agentDropdownOptions: IDropdownOption[] = [{ value: 'None', label: 'None' }];

    props.agents &&
        agentDropdownOptions.push(
            ...props.agents.map(
                (agent: ConversationalAgent): IDropdownOption => {
                    return {
                        value: agent.id,
                        label: agent.name
                    };
                }
            )
        );

    let agentInfoRender: React.ReactElement = <></>;

    switch (props.currentAgent?.type) {
        case MotiveTypes.DIALOGFLOW_AGENT:
            agentInfoRender = (
                <>
                    <CopyTextField
                        label={`Parent Project ID`}
                        value={(props.currentAgent as IDialogflowAgent).parentProjectId ?? `<None>`}
                    />
                </>
            );
            break;
        case MotiveTypes.WATSON_ASSISTANT:
            agentInfoRender = (
                <>
                    <CopyTextField
                        key={(props.currentAgent as IWatsonAssistant).assistantId}
                        label={`Assistant ID`}
                        value={(props.currentAgent as IWatsonAssistant).assistantId}
                    />
                </>
            );
            break;
    }

    return (
        <>
            <Heading size={Size.MEDIUM}>Catalog Settings</Heading>
            <Divider />
            <div css={agentSettingWrapperStyle}>
                <Text css={settingTextStyle(token)}>{`Agent: `}</Text>
                <Dropdown
                    value={props.currentAgent?.id ?? 'None'}
                    onChange={props.onUpdateAgent}
                    options={agentDropdownOptions}
                    isDisabled={!props.isEmpty}
                />
            </div>
            <div>{agentInfoRender}</div>
        </>
    );
};
