import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const uploadButtonStyle = css`
    width: 100%;
`;

export const catalogQuickEditorButtonStyle = (token: IToken) => css`
    width: 100%;
    border-radius: none;
    background: ${token.colors.interactible.highlight};
    text-align: center;
    border-radius: 0;
    color: white;
`;
