import { css } from '@emotion/core';
import { SerializedStyles } from '@emotion/css';
import { IToken } from '../../shared/contexts/styleContext';
import { ZIndex } from '../../constants/ZIndex';
import { PseudoBoxProps } from '@chakra-ui/core';
import { DropdownSize, DropdownVariant } from './Dropdown';
import { Size } from '../constants/Size';

export const baseDropdownStyle = (): SerializedStyles => {
    return css`
        background-color: #f0f0f0;
        z-index: ${ZIndex.coreUIDropdown};
        border-color: #ccc;
        padding: 0 4px;
        min-width: 150px;
    `;
};

export const disabledDropdownItemStyle: SerializedStyles = css`
    font-weight: bold;
    color: black;
`;

export const instructionsDropdownItemStyle: SerializedStyles = css`
    font-weight: bold;
    color: black;
`;

export const selectOverrideProps = (
    { spacings, colors }: IToken,
    size: DropdownSize,
    variant: DropdownVariant
): PseudoBoxProps | React.HTMLAttributes<HTMLSelectElement> | undefined => {
    let overrideProps: PseudoBoxProps | React.HTMLAttributes<HTMLSelectElement> | undefined = {
        borderRadius: '5px',
        backgroundColor: variant === 'solid' ? colors.background.default : 'transparent'
    };
    if (size === Size.SMALL) {
        overrideProps = {
            ...overrideProps,
            height: spacings.medium
        };
    }

    return overrideProps;
};
