import React, { useState } from 'react';
import { CatalogEditor } from '../../components/catalogEditor/CatalogEditor';
import { useDashboardDrawer } from '../../hooks/useDashboardDrawer';
import { useChooseGlobalState } from '../../hooks/useChooseGlobal/UseChooseGlobal';
import { ICatalogContainerProps, CatalogContainer } from '../catalogContainer';
import { EditableOnlyProvider } from '../../components/editableOnFocus/EditableOnFocus';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { DashboardPanel } from '../../components/dashboardPanel';
import { useProjectDashboard } from '../../contexts/projectDashboardContext';
import { useTranslation } from 'react-i18next';

export const CatalogEditorContainer: React.FC<ICatalogContainerProps> = (props): React.ReactElement => {
    const [selectedItemId, setSelectedItemId] = useState<string>('');
    const { handleSelectGlobalCatalogItem } = useDashboardDrawer();
    const { setSelectedTab } = useProjectDashboard();
    const { state: chooseState } = useChooseGlobalState();
    const { t } = useTranslation();

    const handleSelectItem = (item: IScriptObjectModel) => {
        setSelectedItemId(item.id);
    };

    const handleAfterSave: ICatalogContainerProps['onAfterSave'] = () => {
        setSelectedItemId('');
    };

    const handleConfirmChoose = (item: IScriptObjectModel) => {
        handleSelectGlobalCatalogItem(props.catalogId, item);
    };

    const handleGoBackToCatalogs = () => {
        setSelectedTab('Catalogs');
    };

    return (
        <CatalogContainer catalogId={props.catalogId} onAfterSave={handleAfterSave}>
            {renderProps => (
                <>
                    {renderProps.catalog && (
                        <EditableOnlyProvider>
                            <DashboardPanel headingTitle={t('catalogs')}>
                                <CatalogEditor
                                    {...renderProps}
                                    isChoosingExternal={chooseState === 'choosing'}
                                    selectedItem={renderProps.catalog.items?.find(c => c.id === selectedItemId)}
                                    onSelectItem={handleSelectItem}
                                    onConfirmChoose={handleConfirmChoose}
                                    onGoBackToCatalogs={handleGoBackToCatalogs}
                                />
                            </DashboardPanel>
                        </EditableOnlyProvider>
                    )}
                </>
            )}
        </CatalogContainer>
    );
};
