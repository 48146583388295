import { ACCOUNT_ENDPOINT } from '../constants/ApiEndpoint';
import { httpClient } from './HttpClient';
import { IAccountManagerViewModel } from '../models/AccountManagerViewModel';
import { IMVCModelError } from './MVCModelError';
import { Dispatch } from 'react';
import {
    UserInfoActionType,
    userInfoSetAction,
    userLoginStatusSet,
    userLoginStatusUnset
} from '../../../redux/userInfo/UserInfoActions';
import { UserLoginStatus } from '../../../redux/userInfo/UserInfoReducer';

export const accountUserInfoPath = `${ACCOUNT_ENDPOINT}/info`;
const setTwoFactorStatusEndpoint = `User/TwoFactorAuthentication`;
const changePasswordEndpoint = `${ACCOUNT_ENDPOINT}/ChangePassword`;
const addPhoneNumberEndpoint = `User/PhoneDetails`;
const verifyPhoneNumberEndpoint = `User/VerifyPhoneDetails`;
const logoutEndpoint = `Account/LogOff`;

export interface IChangePasswordForm {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export interface IChangePasswordFormErrors {
    message?: string;
    oldPassword?: IMVCModelError[];
    newPassword?: IMVCModelError[];
    confirmPassword?: IMVCModelError[];
}

export interface IPhoneNumberForm {
    phoneNumber?: string;
    verificationCode?: string;
}

export interface IPhoneNumberFormErrors {
    message?: string;
    phoneNumber?: IMVCModelError[];
    verificationCode?: IMVCModelError[];
}
export const logout = async (dispatch: Dispatch<UserInfoActionType>) => {
    try {
        await httpClient.post(logoutEndpoint, {});
        dispatch(userLoginStatusUnset());
    } catch (e) {
        // WORKAROUND - We 403 but still log out.
        dispatch(userLoginStatusUnset());
    }
};

export const confirmPhoneNumber = async (
    phoneForm: IPhoneNumberForm,
    dispatch: Dispatch<UserInfoActionType>
): Promise<IPhoneNumberFormErrors | undefined> => {
    try {
        const response = await httpClient.post<IPhoneNumberFormErrors>(verifyPhoneNumberEndpoint, phoneForm);
        getUserInfoNetworkCall(dispatch);

        return response.data as IPhoneNumberFormErrors;
    } catch (e) {
        console.log(e);
    }
};

export const addPhoneNumber = async (
    phoneForm: IPhoneNumberForm,
    dispatch: (param: UserInfoActionType) => void
): Promise<IPhoneNumberFormErrors | undefined> => {
    try {
        const response = await httpClient.post<IPhoneNumberFormErrors>(addPhoneNumberEndpoint, phoneForm);
        await getUserInfoNetworkCall(dispatch);

        return response.data as IPhoneNumberFormErrors;
    } catch (e) {
        console.log(e);
    }
};

export const changePassword = async (
    passwordForm: IChangePasswordForm
): Promise<IChangePasswordFormErrors | undefined> => {
    try {
        const response = await httpClient.post<IChangePasswordFormErrors>(changePasswordEndpoint, passwordForm);
        return response.data as IChangePasswordFormErrors;
    } catch (e) {
        console.log(e);
    }
};

export const setTwoFactorStatus = async (
    status: boolean,
    dispatch: (param: UserInfoActionType) => void
): Promise<IAccountManagerViewModel | Error> => {
    try {
        const response = await httpClient.put<IAccountManagerViewModel>(setTwoFactorStatusEndpoint, {
            Enabled: status
        });

        await getUserInfoNetworkCall(dispatch);

        return response.data;
    } catch (error) {
        dispatch(userLoginStatusUnset());

        return error;
    }
};

// 2020-04-13 Dropped out due to security concerns around the kind of sensitive data this returns.
// We are avoiding caching this for now.
// const getUserInfoNetworkCallSWF = async (
//     dispatch: (param: IMotiveAction) => void,
//     dontCheckCache = false
// ): Promise<any> => {
//     dispatch({ type: MotiveActionTypes.UPDATE_STATE, userInfoAction: { status: UserInfoStatus.FETCHING } });
//     tryUseCachedForGetReq(
//         accountUserInfoPath,
//         httpClient.get(accountUserInfoPath),
//         data => {
//             dispatch({
//                 type: MotiveActionTypes.UPDATE_STATE,
//                 userInfoAction: {
//                     status: UserInfoStatus.SUCCESS,
//                     userInfo: data as IAccountManagerViewModel
//                 }
//             });
//         },
//         error => {
//             dispatch({
//                 type: MotiveActionTypes.UPDATE_STATE,
//                 userInfoAction: { status: UserInfoStatus.ERROR, error: error }
//             });
//         },
//         false,
//         dontCheckCache
//     );
// };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getUserInfoNetworkCallLegacy = async (dispatch: Dispatch<UserInfoActionType>): Promise<any> => {
    try {
        const userInfoResponse = await httpClient.get(accountUserInfoPath, {
            validateStatus: () => {
                return true;
            }
        });

        if (userInfoResponse.status === 200) {
            dispatch(userInfoSetAction(userInfoResponse.data));
            dispatch(userLoginStatusSet(UserLoginStatus.LoggedIn));
        } else {
            dispatch(userLoginStatusSet(UserLoginStatus.AuthFailure));
            dispatch(userLoginStatusUnset());
        }
    } catch (error) {
        dispatch(userLoginStatusSet(UserLoginStatus.AuthFailure));
        dispatch(userLoginStatusUnset());
    }
};

export const getUserInfoNetworkCall = getUserInfoNetworkCallLegacy;
