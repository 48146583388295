import React from 'react';
import { emptyBox, messageStyle } from './EmptyCollection.style';
import { useStyle } from '../../shared/hooks/useStyle';

interface IEmptyCollectionProps {
    positionY?: number;
    message?: string;
    onClickAction?: () => void;
    className?: string;
}

export const EmptyCollection: React.FC<IEmptyCollectionProps> = ({
    message,
    positionY = 0,
    onClickAction,
    className
}) => {
    const tokens = useStyle();

    return (
        <div onClick={onClickAction} className={className} css={emptyBox(tokens, positionY, !!onClickAction)}>
            <div css={messageStyle(tokens)}>
                <span>{message}</span>
            </div>
        </div>
    );
};
