import * as React from 'react';
import { IAdvancedDragProps } from '../AdvancedDrag/AdvancedDrag';
import { IBaseDrag } from '../Drop/Drop';
import styled from '@emotion/styled';
import { getStyleOverrideNoDrop } from './CloneDrag.style';
import { AdvancedDrag } from '../AdvancedDrag/AdvancedDrag';

export interface ICloneDragProps<T extends IBaseDrag> extends IAdvancedDragProps<T> {
    children?: React.ReactNode;
    thumb?: React.ReactNode;
    enableDropAnimation?: boolean;
}
const Clone = styled.div`
    ~ div {
        transform: none !important;
    }
`;

/**
 * A Clone Drag creates a clone of the element you are dragging in place of where the original Drag was.

 */
export function CloneDrag<T extends IBaseDrag>({ enableDropAnimation, thumb, children, ...props }: ICloneDragProps<T>) {
    return (
        <>
            <AdvancedDrag<T> {...props}>
                {(provided, snapshot) => (
                    <>
                        <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            style={{
                                ...getStyleOverrideNoDrop(provided.draggableProps.style, snapshot, enableDropAnimation),
                                outline: 'none'
                            }}
                        >
                            {snapshot.isDragging && thumb ? thumb : children}
                        </div>
                        {snapshot.isDragging && <Clone>{children}</Clone>}
                    </>
                )}
            </AdvancedDrag>
        </>
    );
}
