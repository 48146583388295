import React, { useState } from 'react';
import { IFieldEditorProps } from '../../containers/objectEditor/ObjectEditor';
import { inRange } from 'lodash-es';
import { Size } from '../../core-ui/constants/Size';
import { InputField } from '../../core-ui/inputField';
import { Text } from '../../core-ui/text';
import { EditModeAware } from '../editableOnFocus/EditModeAware';

interface IFloatEditorProps extends IFieldEditorProps<number | undefined> {
    minRange?: number;
    maxRange?: number;
    placeholder?: string;
}

export const FloatEditor: React.FC<IFloatEditorProps> = ({
    value = '',
    onChange,
    maxRange = Number.MAX_VALUE,
    minRange = -Number.MAX_VALUE,
    placeholder
}): React.ReactElement => {
    const [isInvalid, setInvalid] = useState(false);

    const handleChange = (changedValue: string) => {
        const value = parseFloat(changedValue);

        if (isNaN(value)) {
            onChange(undefined);
            setInvalid(true);
            return;
        }

        const isInRange = inRange(value, minRange, maxRange + 1);

        if (isInRange) {
            onChange(value);
        }

        setInvalid(!isInRange);
    };

    const renderEditor = () => {
        return (
            <InputField
                type="number"
                size={Size.MEDIUM}
                onChange={handleChange}
                value={value}
                placeholder={placeholder}
                isInvalid={isInvalid}
            />
        );
    };

    const renderReadonly = () => {
        return <Text>{value}</Text>;
    };

    return <EditModeAware readonlyModeRender={renderReadonly} editModeRender={renderEditor} />;
};
