import React, { useState } from 'react';
import { ButtonVariant, Button } from '../../core-ui/button';
import { Size } from '../../core-ui/constants/Size';
import { Dropdown, IDropdownOption } from '../../core-ui/dropdown';
import { IScript, IFrame, IScriptVariable, IVariableSelector } from '../../shared/motive/models/Script';
import { useScriptEditorInfo } from '../../shared/motive/hooks/useScriptEditorInfo';
import { IScriptFrameAction } from '../../redux/spaceKeyed/scriptEditor/ScriptEditorReducer';
import { addVariableSelectorToSelectors, getSelectorForVariable } from '../../util/ScriptDynamicsUtil';
import { IObjectDefinition } from '../../shared/motive/models/TypeDefinition';
import { ScriptActionType } from '../../shared/motive/reducers/ScriptEditorReducers';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { getSmartTypeName } from '../../util/ObjectDefinitionsUtils';
import { IObjectEditorProps } from '../objectEditor';
import { SelectorContainer } from '../selectorContainer';
import { useScriptAndGlobalVariables } from '../../shared/motive/hooks/useScriptAndGlobalVariables';
import { useDispatch } from 'react-redux';

const DEFAULT_SELECTORS_PATH = 'selectorTree.defaultSelectors';

export interface IVariableSelectorContainerEditorProps {
    script: IScript;
    frame: IFrame;
    variable: IScriptVariable;
    showVariableTitle?: boolean;
    subFramePath?: string;
    selectors?: IVariableSelector[];
}

export const VariableSelectorEditorContainer: React.FC<IVariableSelectorContainerEditorProps> = ({
    script,
    frame,
    variable,
    subFramePath = DEFAULT_SELECTORS_PATH,
    selectors: selectorsIn
}): React.ReactElement => {
    const scriptEditorInfoDataSource = useScriptEditorInfo();
    const { data: scriptEditorInfo } = scriptEditorInfoDataSource;
    const scriptDispatch = useDispatch();
    const selectors = selectorsIn ?? frame?.selectorTree?.defaultSelectors;
    const { selector, index } = getSelectorForVariable(selectors, variable.id);
    const [isAddingSelector, setIsAddingSelector] = useState(false);
    const [selectorOptions, setSelectorOptions] = useState<IDropdownOption[]>([]);
    const varMap = useScriptAndGlobalVariables();

    const handleAddDefault = () => {
        const selectedTypeDef =
            variable && (scriptEditorInfo.typeDefinitions[variable.valueDefinition.typeName] as IObjectDefinition);

        if (!selectedTypeDef) {
            return;
        }

        const selectorTypes = scriptEditorInfo.typedSelectors?.[selectedTypeDef.name];

        if ((!selectorTypes || selectorTypes?.length === 0) && !variable.valueDefinition.isArray) {
            setIsAddingSelector(false);

            const updatedFrame = addVariableSelectorToSelectors(
                variable.id,
                selectors,
                subFramePath,
                script,
                frame,
                scriptEditorInfo.typeDefinitions,
                varMap
            );

            const scriptDispatchAction: IScriptFrameAction = {
                type: ScriptActionType.FRAME_UPDATE,
                frame: updatedFrame
            };

            scriptDispatch(scriptDispatchAction);
        } else {
            setIsAddingSelector(true);

            const opts: IDropdownOption[] = [
                {
                    value: MotiveTypes.DYNAMIC_VALUE_SELECTOR,
                    label: 'Set a Value'
                }
            ];

            if (selectorTypes && selectorTypes?.length > 0) {
                const slctOpts: IDropdownOption[] = [
                    ...selectorTypes.map(s => ({
                        label: getSmartTypeName(s.selectorTypeName, scriptEditorInfo.typeDefinitions),
                        value: s.selectorTypeName
                    }))
                ];

                opts.push(...slctOpts);
            }

            if (variable.valueDefinition.isArray) {
                opts.push({
                    value: MotiveTypes.SET_OPERATION_SELECTOR,
                    label: 'Set Operation'
                });
            }

            setSelectorOptions(opts);
        }
    };

    const handleDeleteSelector = () => {
        if (selector && frame.selectorTree) {
            const updatedFrame = {
                ...frame,
                selectorTree: {
                    ...frame.selectorTree,
                    defaultSelectors: frame.selectorTree.defaultSelectors?.filter((v, _idx) => _idx !== index)
                }
            };

            const scriptDispatchAction: IScriptFrameAction = {
                type: ScriptActionType.FRAME_UPDATE,
                frame: updatedFrame
            };

            scriptDispatch(scriptDispatchAction);
        }
    };

    const handleAddSelectorType = (type: string) => {
        const updatedFrame = addVariableSelectorToSelectors(
            variable.id,
            frame?.selectorTree?.defaultSelectors,
            DEFAULT_SELECTORS_PATH,
            script,
            frame,
            scriptEditorInfo.typeDefinitions,
            varMap,
            type
        );

        const scriptDispatchAction: IScriptFrameAction = {
            type: ScriptActionType.FRAME_UPDATE,
            frame: updatedFrame
        };

        scriptDispatch(scriptDispatchAction);
    };

    const selectorObjPath = `${subFramePath}.${index}`;

    const handleFrameChange: IObjectEditorProps['onChange'] = (subFramePath, value) => {
        if (index !== undefined) {
            const action = {
                type: ScriptActionType.OBJECT_UPDATE,
                targetFrameId: frame.id,
                updatedObject: value,
                updatedObjectPath: subFramePath
            };

            scriptDispatch(action);
        }
    };

    return (
        <>
            {selector ? (
                <SelectorContainer
                    onChange={handleFrameChange}
                    onDeleteSelector={handleDeleteSelector}
                    scriptVariableId={variable.id}
                    subFramePath={selectorObjPath}
                    selector={selector}
                    frame={frame}
                    script={script}
                ></SelectorContainer>
            ) : (
                <>
                    {isAddingSelector && selectorOptions ? (
                        <>
                            <div>
                                <Button onClick={() => setIsAddingSelector(false)}>cancel</Button>
                            </div>
                            <Dropdown
                                instructions={'Choose a selection mode'}
                                options={selectorOptions}
                                onChange={handleAddSelectorType}
                            />
                        </>
                    ) : (
                        <div>
                            <Button onClick={handleAddDefault} size={Size.SMALL} variant={ButtonVariant.ACTIONLINK}>
                                set value
                            </Button>
                        </div>
                    )}
                </>
            )}
        </>
    );
};
