import { MotiveTypes } from '../../../constants/MotiveTypes';
import { IFieldEditorProps } from '../../../containers/objectEditor';
import { IObjectReference } from '../models/ObjectReference';
import { IScriptObjectModel } from '../models/ScriptObjectModel';

export interface IChosenValue {
    chooseType: 'references' | 'clearValue';
    objectReferences?: IObjectReference[];
}

export interface IChooseState {
    state: 'off' | 'choosing' | 'hasChosenValue';
    chooseTypes?: string[];
    chosenValue?: IScriptObjectModel;
    chooseTypePrettified?: 'Resource' | 'Frame';
    chooseFieldProps?: IFieldEditorProps<IObjectReference | IObjectReference[]>;
    onChoose?: (updatedValue?: IScriptObjectModel) => void;
}

export type ChooseActionType = 'cancelChoose' | 'beginChoose' | 'choose';

export interface IChooseAction {
    actionType: ChooseActionType;
    chooseTypes?: string[];
    chosenValue?: IScriptObjectModel;
    chooseFieldProps?: IChooseState['chooseFieldProps'];
    onChoose?: IChooseState['onChoose'];
}

export const DEFAULT_STATE: IChooseState = {
    state: 'off',
    chooseTypes: undefined,
    chosenValue: undefined,
    chooseFieldProps: undefined,
    chooseTypePrettified: undefined
};

export const ChooseExternalScriptScopeReducer: <T extends IChooseAction = IChooseAction>(
    state: IChooseState,
    action: T
) => IChooseState = (state, { actionType, chooseTypes, chosenValue, chooseFieldProps, onChoose }) => {
    switch (actionType) {
        case 'beginChoose': {
            if (!chooseTypes || chooseTypes.length < 1) {
                throw new Error('must set choose type');
            } else if (!chooseFieldProps) {
                throw new Error('choose field props required when start selecting.');
            }

            let chooseTypePrettified: IChooseState['chooseTypePrettified'] = undefined;
            if (chooseTypes?.some(type => type === MotiveTypes.FRAME)) {
                chooseTypePrettified = 'Frame';
            } else {
                chooseTypePrettified = 'Resource';
            }

            return {
                ...state,
                chooseTypes,
                chooseTypePrettified,
                chooseFieldProps,
                chosenValue: undefined,
                state: 'choosing',
                onChoose
            };
        }
        case 'cancelChoose': {
            return {
                ...DEFAULT_STATE
            };
        }
        case 'choose': {
            if (state.onChoose && chosenValue) {
                state.onChoose(chosenValue);
            }

            return {
                ...state,
                chosenValue,
                state: 'hasChosenValue',
                onChoose: undefined
            };
        }
    }
};
