import produce from 'immer';
import { CatalogTypeActions } from './CatalogTypeActions';
import { ITypeBundle } from '../../../shared/motive/models/TypeBundle';

export interface ICatalogTypesState {
    catalogTypes: ITypeBundle[];
}

const defaultCatalogTypeState: ICatalogTypesState = {
    catalogTypes: []
};

export const CatalogTypesReducer = produce((state: ICatalogTypesState, action: CatalogTypeActions) => {
    switch (action.type) {
        case 'catalogTypes/set': {
            state.catalogTypes = action.catalogTypes;
            break;
        }
        case 'catalogTypes/unset': {
            state.catalogTypes = [];
            break;
        }
    }
}, defaultCatalogTypeState);
