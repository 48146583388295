import { MotiveTypes } from '../../../constants/MotiveTypes';
import { mainFrame } from '../hooks/useCreateScript/useCreateScript';
import { IScript } from '../models/Script';
import { uniqueMotiveId } from '../../../util/MotiveUtils';

export enum IScriptStatus {
    FETCHING,
    FETCHING_BY_ID,
    SUCCESS_BY_ID,
    REMOVE_BY_ID,
    ERROR
}

export interface IScriptState {
    scriptData: IScript;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
}

export interface IScriptAction {
    scriptData?: IScript;
    scriptId?: string;
    type: IScriptStatus;
    error?: Error;
}

export const DefaultScript: IScriptState = {
    scriptData: {
        id: uniqueMotiveId(),
        name: 'Script',
        type: MotiveTypes.MOTIVE_SCRIPT,
        rootFrame: mainFrame
    },
    error: null
};

// ScriptReducer vs ScriptEditorReducer
// This deals with metadata of a script rather than the modification of the frames or resources within a frame

export const ScriptReducer = (state: IScriptState, action: IScriptAction): IScriptState => {
    switch (action.type) {
        case IScriptStatus.FETCHING_BY_ID:
            return { ...state };
        case IScriptStatus.SUCCESS_BY_ID:
            if (!action.scriptId) throw new Error('ScriptId not found');

            return {
                ...state,
                scriptData: action.scriptData
                    ? action.scriptData
                    : {
                          id: '',
                          name: '',
                          type: MotiveTypes.MOTIVE_SCRIPT,
                          rootFrame: mainFrame
                      }
            };
        case IScriptStatus.ERROR:
            return {
                ...state,
                error: { ...action.error }
            };
    }

    return state;
};
