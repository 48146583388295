import { IToken } from '../../shared/contexts/styleContext';
import { css } from '@emotion/core';
import chroma from 'chroma-js';

export const quickObjectEditorWrapper = (token: IToken) => css`
    background: ${token.colors.background.lighter};
    padding: ${token.spacings.small};
`;

export const quickObjectEditorHeader = (token: IToken) => css`
    background: ${token.colors.bar.catalogBarHeader};
    padding: ${token.spacings.smaller};
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    & > button {
        margin-right: 2px;
    }
`;

export const saveButton = (token: IToken) => css`
    background-color: ${chroma(token.colors.background.default)
        .alpha(0.9)
        .css()};
    margin: 0 ${token.spacings.smallest};
`;

export const cancelButton = (token: IToken) => css`
    background-color: ${chroma(token.colors.background.default)
        .alpha(0.9)
        .css()};
`;

export const headingStyle = css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
