import { useDeleteScript, IDeleteScriptParams } from '../../shared/motive/hooks/useDeleteScript/useDeleteScript';
import React from 'react';
import {
    IRenameScriptParams,
    useRenameScriptCatalogItem
} from '../../shared/motive/hooks/useRenameScriptCatalogItem/useRenameScriptCatalogItem';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';

interface IScriptItemProps {
    deleteScriptAction: IHookProvidedCall<IDeleteScriptParams>;
    renameScriptAction: IHookProvidedCall<IRenameScriptParams>;
}

interface IScriptItemContainerProps {
    children: (props: IScriptItemProps) => React.ReactNode;
}

export const ScriptItemContainer: React.FC<IScriptItemContainerProps> = ({ children }) => {
    const deleteScript = useDeleteScript();
    const renameScript = useRenameScriptCatalogItem();

    return <>{children({ deleteScriptAction: deleteScript, renameScriptAction: renameScript })}</>;
};
