import { css, SerializedStyles } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const baseInputFieldStyle = ({ colors: { interactibleBorder }, spacings }: IToken): SerializedStyles => {
    return css`
        box-sizing: border-box;
        border: 0.5px solid #ccc;
        border-radius: 5px;
        background-color: #f0f0f0;
        padding: 5px;
        margin: ${spacings.smallest} 0 ${spacings.smallest} 0;
        transition-duration: 0.25s;
        box-shadow: inset 0px 0px 16px px rgba(0, 0, 0, 0.02);
        line-height: unset;
        text-overflow: ellipsis;

        &:hover {
            border-color: ${interactibleBorder.secondary};
        }

        &:focus {
            box-shadow: 0px 0px 8px -4px #6bbaba;
        }
        &:[type = number] {
            max-width: 100px;
        }
    `;
};

export const invalidInputFieldStyle = ({ colors: { staticColors } }: IToken): SerializedStyles => {
    return css`
        border: 1px solid ${staticColors.red};
        background-color: ${staticColors.pink};

        &:hover {
            border: 1px solid ${staticColors.red};
        }

        &:focus {
            box-shadow: 0px 0px 8px -4px ${staticColors.red};
        }
    `;
};

export const disabledInputFieldStyle = ({ colors: { staticColors } }: IToken): SerializedStyles => {
    return css`
        color: ${staticColors.gray};

        &:hover {
            border: 1px solid ${staticColors.gray};
        }
    `;
};

export const readOnlyInputFieldStyle = (): SerializedStyles => {
    return css`
        cursor: default;
        &:focus {
            box-shadow: none;
        }
    `;
};

export const smallInputFieldStyle = ({ spacings, fontSizes }: IToken): SerializedStyles => {
    return css`
        height: ${spacings.small};
        padding: ${spacings.smaller} ${spacings.smallest} ${spacings.smaller} ${spacings.smallest};
        font-size: ${fontSizes.small};
    `;
};

export const mediumInputFieldStyle = ({ spacings, fontSizes }: IToken): SerializedStyles => {
    return css`
        height: ${spacings.medium};
        padding: ${spacings.smallest} ${spacings.smaller} ${spacings.smallest} ${spacings.smaller};
        font-size: ${fontSizes.small};
    `;
};

export const largeInputFieldStyle = ({ spacings, fontSizes }: IToken): SerializedStyles => {
    return css`
        height: ${spacings.larger};
        padding: ${spacings.medium} ${spacings.smaller} ${spacings.medium} ${spacings.smaller};
        font-size: ${fontSizes.large};
    `;
};
