import { useSelector, useDispatch } from 'react-redux';
import { SelectCurrentProjectId } from '../../../../redux/spaceKeyed/spaceKeyedSelectors';
import { setLastScriptAction, unsetLastScriptAction } from '../../../../redux/spaceKeyed/spaceKeyedStateActions';

export const useLastScript = (): [string | undefined, (scriptId?: string) => void] => {
    const lastScript = useSelector(SelectCurrentProjectId) as string;
    const dispatch = useDispatch();

    const setLastScript = (lastScript?: string) => {
        if (lastScript) {
            dispatch(setLastScriptAction(lastScript));
        } else {
            dispatch(unsetLastScriptAction());
        }
    };

    return [lastScript, setLastScript];
};
