//istanbul ignore file MOTIVE_EXCEPTION
import React from 'react';
import { renderSubRoute } from '../editorRoutes';
import { IProjectsRouteParams } from '../projectsRoute';
import { useEditorRouteMatch, useRoute } from '../useRoute';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useGetCatalogTypes } from '../../shared/motive/hooks/useCatalogs/useCatalogTypes/useGetCatalogTypes';
import { useScenariosRoute } from '../scenariosRoute';
import { AgentsContainer } from '../../containers/agentsContainer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConvoAIRouteParams extends IProjectsRouteParams {
    agentId?: string;
}

export const useConvoAIAgentManagementRoute = () => useRoute<IConvoAIRouteParams>('ConvoAIAgentManagement');
export const useConvoAIAgentManagementRouteMatch = () =>
    useEditorRouteMatch<IConvoAIRouteParams>('ConvoAIAgentManagement');

export const ConvoAIAgentManagementRoute: React.FC = () => {
    const { subRoutes } = useConvoAIAgentManagementRoute();

    const {
        goTo,
        params: { agentId }
    } = useConvoAIAgentManagementRoute();

    const { goTo: redirectToScenarios } = useScenariosRoute();

    const { data: typeData } = useGetCatalogTypes();
    const isAIFeatureSetAvailable = typeData.some(type => type.bundleName === MotiveTypes.AI);

    const handleSelectAgent = (selectedAgent: string) => {
        goTo({ pathParams: { agentId: selectedAgent } });
    };

    if (isAIFeatureSetAvailable) {
        return (
            <>
                <AgentsContainer onSelectAgent={handleSelectAgent} />
                {subRoutes.map(renderSubRoute)}
            </>
        );
    }

    return <>{redirectToScenarios()}</>;
};
