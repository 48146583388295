export type LoadingState = 'initial' | 'loading' | 'resolved';

/**
 * Util function that returns if a state is loading. NOTE: !isLoading IS NOT isResolved. Initial state exists so we can block calls if loading.
 */
export const isLoading = (state: LoadingState) => state === 'loading';

/**
 * Util function that returns if a state is resolved. NOTE: !isResolved IS NOT isLoading. Initial state exists so we can block calls if loading.
 */
export const isResolved = (state: LoadingState) => state === 'resolved';

/**
 * Extendable interface for tracking status
 */
export interface IStatus {
    loadingState: LoadingState;
    error?: Error;
}
