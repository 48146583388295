import React from 'react';
import { useStyle } from '../../shared/hooks/useStyle';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { RowItem } from '../../core-ui/rowItem';
import { Button } from '../../core-ui/button';
import { IScript } from '../../shared/motive/models/Script';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { Size } from '../../core-ui/constants/Size';
import { Icon } from '../../core-ui/icon';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { EditableText } from '../../core-ui/editableText';
import { LoadingSkeleton } from '../../core-ui/loadingSkeleton/LoadingSkeleton';
import { IRenameScriptParams } from '../../shared/motive/hooks/useRenameScriptCatalogItem/useRenameScriptCatalogItem';
import { IDeleteScriptParams } from '../../shared/motive/hooks/useDeleteScript/useDeleteScript';
import { rowItemStyle, rowContentStyle, scriptIconStyle, deleteIconWrapperStyle } from './ScriptItem.style';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';

interface IScriptItemProps {
    catalogItem?: ICatalog<IScript>;
    script?: IScript;
    onScriptClicked?: (script: IScriptObjectModel) => void;
    renameScriptAction?: IHookProvidedCall<IRenameScriptParams, void>;
    deleteScriptAction?: IHookProvidedCall<IDeleteScriptParams, void>;
}

export const ScriptItem: React.FC<IScriptItemProps> = ({
    catalogItem,
    script,
    onScriptClicked,
    renameScriptAction,
    deleteScriptAction
}): React.ReactElement => {
    const tokens = useStyle();
    return (
        <RowItem
            css={rowItemStyle(tokens)}
            onClick={() => {
                onScriptClicked && script && onScriptClicked(script);
            }}
        >
            {() => ({
                content: (
                    <div css={rowContentStyle}>
                        {!script || !catalogItem ? (
                            <LoadingSkeleton width={150} />
                        ) : (
                            <>
                                <Icon
                                    size={Size.LARGE}
                                    iconStyle={scriptIconStyle(tokens)}
                                    icon={IconTypes.FILE}
                                ></Icon>

                                <EditableText
                                    defaultValue={script.name}
                                    onEditComplete={newName => {
                                        renameScriptAction &&
                                            catalogItem &&
                                            script &&
                                            renameScriptAction.execute({
                                                catalogId: catalogItem.id,
                                                scriptId: script.id,
                                                scriptName: newName
                                            });
                                    }}
                                />
                            </>
                        )}
                    </div>
                ),
                rightContent: catalogItem && (
                    <span>
                        <Button
                            onClick={() => {
                                deleteScriptAction &&
                                    catalogItem &&
                                    script &&
                                    deleteScriptAction.execute({
                                        catalogId: catalogItem.id,
                                        scriptId: script.id
                                    });
                            }}
                            size={Size.LARGE}
                            css={deleteIconWrapperStyle(tokens)}
                            icon={IconTypes.DELETE}
                        ></Button>
                    </span>
                )
            })}
        </RowItem>
    );
};
