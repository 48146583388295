import { IScriptsProps, ScriptCatalogs } from '../scriptCatalogs/ScriptCatalogs';
import React from 'react';
import { modalScriptStyle, pageBackground, storyFlowSelectStyle } from './StoryFlowSelectScript.style';
import { Heading } from '../../core-ui/heading';
import { Divider } from '../../core-ui/divider';
import { HeightUsedScrollContainer } from '../../containers/heightUsedScrollContainer';
import { useScriptsRoute } from '../../routes/scriptsRoute/ScriptsRoute';
import { useLastScript } from '../../shared/motive/hooks/useLastScript/useLastScript';

export const StoryFlowSelectScript: React.FC<IScriptsProps> = (props: IScriptsProps) => {
    const { goTo } = useScriptsRoute();
    const [lastScript] = useLastScript();

    if (lastScript) {
        goTo({ pathParams: { scriptId: lastScript } });
    }

    return (
        <>
            <div css={storyFlowSelectStyle}></div>
            <div css={pageBackground}></div>
            <HeightUsedScrollContainer css={modalScriptStyle}>
                <Heading>Select Script</Heading>
                <Divider />
                <ScriptCatalogs {...props}></ScriptCatalogs>
            </HeightUsedScrollContainer>
        </>
    );
};
