import { css } from '@emotion/core';
export const baseStyle = css`
    display: block;
    margin: unset;
    font-weight: 400;
    text-transform: uppercase;
`;

export const baseLargestStyle = css`
    ${baseStyle}
    font-size: 1.83rem;
`;

export const baseLargerStyle = css`
    ${baseStyle}
    font-size: 1.5rem;
`;
export const baseLargeStyle = css`
    ${baseStyle}
    font-size: 1.33rem;
`;
export const baseMediumStyle = css`
    ${baseStyle}
    font-size: 1.17rem;
`;
export const baseSmallStyle = css`
    ${baseStyle}
    font-size: 1rem;
`;
export const baseSmallerStyle = css`
    ${baseStyle}
    font-size: 0.9rem;
    text-transform: none;
`;

export const baseSmallestStyle = css`
    ${baseStyle}
    font-size: 0.5rem;
`;
