import { FacialBlendShape } from './FacialBlendshapes';

/**
 * Maps all possible facial blendshapes to the equivalent name of the morphTargets defined on the GLTF
 */
export const FacialBlendshapeMap: Record<FacialBlendShape, string> = {
    //Brow
    [FacialBlendShape.BrowDropLeft]: 'Brow_Drop_L',
    [FacialBlendShape.BrowDropRight]: 'Brow_Drop_R',
    [FacialBlendShape.BrowRaiseInnerLeft]: 'Brow_Raise_Inner_L',
    [FacialBlendShape.BrowRaiseInnerRight]: 'Brow_Raise_Inner_R',
    [FacialBlendShape.BrowRaiseLeft]: 'Brow_Raise_L',
    [FacialBlendShape.BrowRaiseOuterLeft]: 'Brow_Raise_Outer_L',
    [FacialBlendShape.BrowRaiseOuterRight]: 'Brow_Raise_Outer_R',
    [FacialBlendShape.BrowRaiseRight]: 'Brow_Raise_R',

    //Cheek
    [FacialBlendShape.CheekBlowLeft]: 'Cheek_Blow_L',
    [FacialBlendShape.CheekBlowRight]: 'Cheek_Blow_R',
    [FacialBlendShape.CheekRaiseLeft]: 'Cheek_Raise_L',
    [FacialBlendShape.CheekRaiseRight]: 'Cheek_Raise_R',
    [FacialBlendShape.CheeksSuck]: 'Cheek_Suck',

    //Eye
    [FacialBlendShape.EyeBlinkLeft]: 'Eye_Blink_L',
    [FacialBlendShape.EyeBlinkRight]: 'Eye_Blink_R',
    [FacialBlendShape.EyesBlink]: 'Eye_Blink',
    [FacialBlendShape.EyeSquintLeft]: 'Eye_Squint_L',
    [FacialBlendShape.EyeSquintRight]: 'Eye_Squint_R',
    [FacialBlendShape.EyeWideLeft]: 'Eye_Wide_L',
    [FacialBlendShape.EyeWideRight]: 'Eye_Wide_R',

    //Mouth
    [FacialBlendShape.MouthBlow]: 'Mouth_Blow',
    [FacialBlendShape.MouthBottomLipBite]: 'Mouth_Bottom_Lip_Bite',
    [FacialBlendShape.MouthBottomLipDown]: 'Mouth_Bottom_Lip_Down',
    [FacialBlendShape.MouthBottomLipTrans]: 'Mouth_Bottom_Lip_Trans',
    [FacialBlendShape.MouthBottomLipUnder]: 'Mouth_Bottom_Lip_Under',
    [FacialBlendShape.MouthDimpleLeft]: 'Mouth_Dimple_L',
    [FacialBlendShape.MouthDimpleRight]: 'Mouth_Dimple_R',
    [FacialBlendShape.MouthDown]: 'Mouth_Down',
    [FacialBlendShape.MouthFrown]: 'Mouth_Frown',
    [FacialBlendShape.MouthFrownLeft]: 'Mouth_Frown_L',
    [FacialBlendShape.MouthFrownRight]: 'Mouth_Frown_R',
    [FacialBlendShape.MouthLeft]: 'Mouth_L',
    [FacialBlendShape.MouthLipsJawAdjust]: 'Mouth_Lips_Jaw_Adjust',
    [FacialBlendShape.MouthLipsOpen]: 'Mouth_Lips_Open',
    [FacialBlendShape.MouthLipsPart]: 'Mouth_Lips_Part',
    [FacialBlendShape.MouthLipsTight]: 'Mouth_Lips_Tight',
    [FacialBlendShape.MouthLipsTuck]: 'Mouth_Lips_Tuck',
    [FacialBlendShape.MouthOpen]: 'Mouth_Open',
    [FacialBlendShape.MouthPlosive]: 'Mouth_Plosive',
    [FacialBlendShape.MouthPucker]: 'Mouth_Pucker',
    [FacialBlendShape.MouthPuckerOpen]: 'Mouth_Pucker_Open',
    [FacialBlendShape.MouthRight]: 'Mouth_R',
    [FacialBlendShape.MouthSkewer]: 'Mouth_Skewer',
    [FacialBlendShape.MouthSmile]: 'Mouth_Smile',
    [FacialBlendShape.MouthSmileLeft]: 'Mouth_Smile_L',
    [FacialBlendShape.MouthSmileRight]: 'Mouth_Smile_R',
    [FacialBlendShape.MouthSnarlLowerLeft]: 'Mouth_Snarl_Lower_L',
    [FacialBlendShape.MouthSnarlLowerRight]: 'Mouth_Snarl_Lower_R',
    [FacialBlendShape.MouthSnarlUpperLeft]: 'Mouth_Snarl_Upper_L',
    [FacialBlendShape.MouthSnarlUpperRight]: 'Mouth_Snarl_Upper_R',
    [FacialBlendShape.MouthTopLipUnder]: 'Mouth_Top_Lip_Under',
    [FacialBlendShape.MouthTopLipUp]: 'Mouth_Top_Lip_Up',
    [FacialBlendShape.MouthUp]: 'Mouth_Up',
    [FacialBlendShape.MouthWiden]: 'Mouth_Widen',
    [FacialBlendShape.MouthWidenSides]: 'Mouth_Widen_Sides',

    //Jaw
    [FacialBlendShape.MoveJawLeftRight]: 'Mouth_Jaw_Left_Right',
    [FacialBlendShape.MoveJawUpDown]: 'Mouth_Jaw_Up_Down',

    //Nose
    [FacialBlendShape.NoseFlankRaiseLeft]: 'Nose_Flank_Raise_L',
    [FacialBlendShape.NoseFlankRaiseRight]: 'Nose_Flank_Raise_R',
    [FacialBlendShape.NoseFlanksRaise]: 'Nose_Flanks_Raise',
    [FacialBlendShape.NoseNostrilsFlare]: 'Nose_Nostrils_Flare',
    [FacialBlendShape.NoseScrunch]: 'Nose_Scrunch'
};
