import { MotiveTypes } from '../constants/MotiveTypes';
import { ICatalog } from '../shared/motive/models/Catalog';
import { IWatsonIntent } from '../shared/motive/models/WatsonIntent';
import { ConversationalIntent } from '../shared/motive/models/ConversationalIntent';
import { IObjectReference } from '../shared/motive/models/ObjectReference';
import { ConversationalAgent } from '../shared/motive/models/ConversationalAgent';

export const getAgentReferenceFromIntentCatalog = (catalog?: ICatalog): IObjectReference | undefined => {
    if (!catalog) {
        return undefined;
    }
    switch (catalog.objectType) {
        case MotiveTypes.WATSON_INTENT:
            return catalog.items && (catalog.items[0] as IWatsonIntent)?.assistantReference;
        default:
            return undefined;
    }
};

export const getAgentIdFromIntent = (intent?: ConversationalIntent): string | undefined => {
    if (!intent) {
        return undefined;
    }
    switch (intent.type) {
        case MotiveTypes.WATSON_INTENT:
            return (intent as IWatsonIntent)?.assistantReference?.objectId;
        default:
            return undefined;
    }
};
