import React, { useEffect } from 'react';
import { useRoute, useEditorRouteMatch } from '../useRoute';
import { renderSubRoute } from '../editorRoutes';
import { ScriptCatalogsContainer } from '../../containers/scriptCatalogsContainer/ScriptCatalogsContainer';
import { IProjectsRouteParams } from '../projectsRoute';
import { StoryFlowSelectScript } from '../../components/storyFlowSelectScript';
import { ScriptEditorSession } from '../../components/scriptEditor/ScriptLoader';

export interface IScriptsRouteParams extends IProjectsRouteParams {
    scriptId?: string;
}

export const useScriptsRoute = () => useRoute<IScriptsRouteParams>('Scripts');
export const useScriptsRouteMatch = () => useEditorRouteMatch<IScriptsRouteParams>('Scripts');

export const ScriptsRoute: React.FC = () => {
    const {
        subRoutes,
        params: { scriptId }
    } = useScriptsRoute();

    if (scriptId) {
        return (
            <>
                <ScriptEditorSession scriptId={scriptId} />
                {subRoutes.map(renderSubRoute)}
            </>
        );
    } else {
        return (
            <>
                <ScriptCatalogsContainer>
                    {props => <StoryFlowSelectScript {...props}></StoryFlowSelectScript>}
                </ScriptCatalogsContainer>
                {subRoutes.map(renderSubRoute)}
            </>
        );
    }
};
