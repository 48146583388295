import React, { useState } from 'react';
import { useScriptEditorInfo, useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo';
import { ConditionsPanel } from '../../components/conditionsPanel';
import { ScriptActionType, IScriptFrameObjectAction } from '../../shared/motive/reducers/ScriptEditorReducers';
import { createScriptObject } from '../../util/MotiveUtils';
import { useDispatch } from 'react-redux';

export const ConditionsPanelContainer: React.FC = () => {
    const [searchValue, setSearchValue] = useState('');
    const {
        data: { conditionTypes }
    } = useScriptEditorInfo();
    const scriptDispatch = useDispatch();
    const objectDefinitions = useObjectDefinitions();

    const handleDoubleClick = (type: string) => {
        const action: IScriptFrameObjectAction = {
            type: ScriptActionType.PRE_CONDITION_ADD,
            object: createScriptObject(type, objectDefinitions),
            objectDefinitions: objectDefinitions
        };

        scriptDispatch(action);
    };

    return (
        <>
            <ConditionsPanel
                filter={searchValue}
                conditionTypes={conditionTypes}
                onDoubleClick={handleDoubleClick}
                onSearchChange={setSearchValue}
            />
        </>
    );
};
