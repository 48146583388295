import { useDispatch, useSelector } from 'react-redux';
import {
    appendNavbarTabAction,
    clearRouteToTab,
    deleteNavbarTabAction,
    setNavbarCurrentTabAction,
    updateNavbarTabAction
} from './NavbarActions';
import { INavBarItem } from './NavbarReducer';
import {
    SelectCurrentNavbarItem,
    SelectCurrentNavbarTabId,
    SelectNavbarItems,
    SelectNavbarRouteToTab,
    SelectNavbarTab
} from './NavbarSelectors';

export const useNavbarCurrentTab = () => useSelector(SelectCurrentNavbarItem);
export const useNavbarCurrentTabId = () => useSelector(SelectCurrentNavbarTabId);
export const useNavbarTabs = () => useSelector(SelectNavbarItems);
export const useNavbarItem = (itemId: string) => useSelector(SelectNavbarTab(itemId));
export const useNavbarRouteToTab = () => useSelector(SelectNavbarRouteToTab);

export const useClearRouteToTab = () => {
    const dispatch = useDispatch();

    return () => dispatch(clearRouteToTab());
};

export const useNavbarSetCurrentTab = () => {
    const dispatch = useDispatch();

    return (id: string) => {
        dispatch(setNavbarCurrentTabAction(id));
    };
};

export const useNavbarRemoveTab = () => {
    const dispatch = useDispatch();

    return (id: string) => {
        dispatch(deleteNavbarTabAction(id));
    };
};

export const useNavbarAppendTab = () => {
    const dispatch = useDispatch();

    return (item: INavBarItem, isSelected?: boolean) => {
        dispatch(appendNavbarTabAction(item, isSelected));
    };
};

export const useNavbarUpdateTab = () => {
    const dispatch = useDispatch();

    return (item: INavBarItem) => {
        dispatch(updateNavbarTabAction(item));
    };
};
