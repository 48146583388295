import React from 'react';
import { IFieldEditorProps, FieldLayoutHint, RenderFieldEditorCallback } from '../ObjectEditor';
import { useTypeDefinition } from '../../../shared/motive/hooks/useScriptEditorInfo';
import { EnumEditor } from '../enumEditor';
import { FieldTypes, IScriptObjectModel } from '../../../shared/motive/models/ScriptObjectModel';
import { ExternalScriptEditor } from '../externalScriptEditor';
import { ExternalGlobalEditor } from '../externalGlobalEditor';
import { FieldObjectEditor } from '../../../components/fieldObjectEditor';
import { IObjectReference } from '../../../shared/motive/models/ObjectReference';
import { IEnumItemReference, IFieldDefinition } from '../../../shared/motive/models/TypeDefinition';
import { CustomFieldEditorFactory } from '../customEditor';
import { customEventTypes, CustomEventEditor } from '../../../components/customEventEditor/CustomEventEditor';
import { MotiveTypes } from '../../../constants/MotiveTypes';
import { FieldVariableEditor } from '../fieldVariableEditor';
import { IFieldVariableEditorProps } from '../fieldVariableEditor/FieldVariableEditor';
import { useCurrentProjectConfig } from '../../../redux/spaceKeyed/SpaceKeyedHooks';
import { VersionDates } from '../../../util/ScriptUtils';

export const UnknownEditor: React.FC = () => <span>unknown primitive type!</span>;

const getFieldLabel = (fieldDef: IFieldDefinition): string => {
    if (fieldDef?.editorInfo?.label) return fieldDef.editorInfo.label;

    return fieldDef.name;
};

export const FieldEditor: React.FC<IFieldEditorProps<FieldTypes> & { renderEditor: RenderFieldEditorCallback }> = ({
    renderEditor,
    ...props
}): React.ReactElement => {
    const typeDef = useTypeDefinition(props.type);
    const { fieldDefinition } = props;

    const customEditorInfo = CustomFieldEditorFactory(props.type);

    let layoutHint: FieldLayoutHint = 'newline';

    const unknownEditor = () => <UnknownEditor />;

    const renderEditorFactory: () => () => React.ReactElement = () => {
        if (!typeDef) {
            return unknownEditor;
        }

        if (!!props.fieldDefinition.isOutput) {
            const varProps: IFieldVariableEditorProps = {
                ...props,
                value: props.value as IObjectReference
            };

            const outputField = () => <>{props.script && <FieldVariableEditor {...varProps} />}</>;

            return outputField;
        }

        const { dataType, name } = typeDef;

        switch (dataType) {
            case 'enum':
                const enumField = () => <EnumEditor {...props} value={props.value as string} />;

                return enumField;
            case 'primitive':
                layoutHint = name === MotiveTypes.BOOLEAN ? 'inline' : 'newline';
                const isCustomEventEditor =
                    name === MotiveTypes.STRING && customEventTypes.includes(props.parentObjectEditorProps.type);

                if (isCustomEventEditor) {
                    const customEvent = () => <CustomEventEditor {...props} value={props.value as string} />;

                    return customEvent;
                }

                const CustomEditor = customEditorInfo?.editor;

                if (CustomEditor) {
                    layoutHint = customEditorInfo?.layoutHint ?? layoutHint;

                    const customEditor = () => <CustomEditor {...props}></CustomEditor>;
                    return customEditor;
                }

                break;
            case 'object':
                if (!fieldDefinition.isExternalReference) {
                    // Always use inline and use flex-basis to control where the editor box lives.
                    layoutHint = customEditorInfo?.layoutHint ?? 'inline'; // CustomEditor || props.value || objDef.isAbstract ? 'newline' : 'inline';
                    const CustomEditor = customEditorInfo?.editor;

                    const objEditor = CustomEditor
                        ? () => <CustomEditor {...props} />
                        : () => (
                              <FieldObjectEditor
                                  {...props}
                                  value={props.value as IScriptObjectModel | IScriptObjectModel[]}
                              />
                          );

                    return objEditor;
                }

                switch (fieldDefinition.referenceScope) {
                    case 'script':
                        // Only show script-scoped fields if we have a frame.
                        const scriptEditor = () => (
                            <ExternalScriptEditor
                                {...props}
                                value={props.value as IObjectReference | IObjectReference[]}
                            />
                        );

                        return scriptEditor;
                    case 'global':
                        layoutHint = 'inline'; // props.value ? 'newline' : 'inline';
                        const globalEditor = () => (
                            <ExternalGlobalEditor
                                {...props}
                                value={
                                    props.value as
                                        | IScriptObjectModel
                                        | IObjectReference
                                        | IScriptObjectModel[]
                                        | IObjectReference[]
                                }
                            />
                        );

                        return globalEditor;
                    case 'frame':
                    default:
                        break;
                }
                // Explicitly return so no case-fall-through.
                return unknownEditor;
            default:
                return unknownEditor;
        }

        return unknownEditor;
    };

    const renderer = renderEditorFactory();

    //return <EditModeAware readonlyModeRender={renderReadonlyMode} editModeRender={renderEditMode} />;
    return renderEditor(getFieldLabel(fieldDefinition), renderer, props.value, layoutHint);
};
