import { motiveLogoStyle } from './MotiveLogo.style';
import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import React from 'react';

export const MotiveLogo = (
    props: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
): React.ReactElement => {
    return (
        <div css={motiveLogoStyle}>
            <img {...props} src="/img/storyflowByMotive.svg" alt="Motive" />
        </div>
    );
};
