import React, { useReducer, useContext } from 'react';
import { TabsProps } from '@chakra-ui/core';
import { FRAME_TOOLS_TAB_INDICES } from '../../containers/frameToolsContainer';

export interface IFrameToolsContextValue {
    selectedTab: {
        tabIndex: TabsProps['tabIndex'];
        // for animating on click
        selectedCount: number;
    };
}

const frameToolsStateContext = React.createContext<IFrameToolsContextValue>({
    selectedTab: {
        tabIndex: FRAME_TOOLS_TAB_INDICES.resources,
        selectedCount: 0
    }
});
const frameToolsDispatchContext = React.createContext<React.Dispatch<FrameToolsContextReducerAction>>(() => undefined);

const FrameToolsStateContextProvider = frameToolsStateContext.Provider;
const FrameToolsDispatchContextProvider = frameToolsDispatchContext.Provider;

interface IFrameToolContextSetTab {
    operation: 'setFrameToolsTab';
    tabIndex: number;
    // set true if want animation to be triggered
    updateSelectedCount?: boolean;
}

export type FrameToolsContextReducerAction = IFrameToolContextSetTab;

const FrameToolsContextReducer: (
    state: IFrameToolsContextValue,
    action: FrameToolsContextReducerAction
) => IFrameToolsContextValue = (state, action) => {
    const selectedCount = !action.updateSelectedCount
        ? state.selectedTab?.selectedCount
        : (state.selectedTab?.selectedCount ?? 0) + 1;

    switch (action.operation) {
        case 'setFrameToolsTab':
            state = {
                ...state,
                selectedTab: {
                    tabIndex: action.tabIndex,
                    selectedCount
                }
            };
    }
    return state;
};

export const FrameToolsContextContainer: React.FC = ({ children }) => {
    const [value, dispatch] = useReducer(FrameToolsContextReducer, {
        selectedTab: {
            tabIndex: FRAME_TOOLS_TAB_INDICES.resources,
            selectedCount: 0
        }
    });

    return (
        <FrameToolsStateContextProvider value={value}>
            <FrameToolsDispatchContextProvider value={dispatch}>{children}</FrameToolsDispatchContextProvider>
        </FrameToolsStateContextProvider>
    );
};

export const useSetFrameToolsTab = () => {
    const dispatch = useContext(frameToolsDispatchContext);

    return (tabIndex: IFrameToolContextSetTab['tabIndex'], updateSelectedCount?: boolean) => {
        dispatch({
            operation: 'setFrameToolsTab',
            tabIndex,
            updateSelectedCount
        });
    };
};

export const useFrameToolsSelectedTab = () => useContext(frameToolsStateContext).selectedTab;
