import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';
import { DRAWER_WIDTH_XS, DRAWER_WIDTH_SM } from '../../core-ui/drawer/Drawer.style';

export const GRID_DRAWER_CONTEXT_MENU = 'drawerContextMenuGrid';
export const GRID_DRAWER_BODY = 'drawerBodyGrid';

export const allDrawerContentContainerStyle = ({ colors }: IToken) => css`
    box-sizing: border-box;
    background-color: ${colors.background.default};
    white-space: nowrap;
    display: flex;
    flex-direction: column;
`;

export const drawerButtonsWidth = css`
    width: 200px;
`;

export const mainAreaStyle = css`
    width: 500px;
`;

export const menuStyle = css``;

export const drawerLayoutStyle = css`
    position: relative;
`;

export const drawerPanelStyle = (token: IToken, bottom: number) => css`
    position: absolute;
    right: 75px;
    background-color: white;
    top: 5px;
    // border-top: 2px solid ${token.colors.interactibleBorder.primary};
    // border-right: 3px solid ${token.colors.interactibleBorder.primary};
    // border-bottom: 1px solid ${token.colors.interactibleBorder.primary};
    // border-left: 2px solid ${token.colors.interactibleBorder.primary};
    border-radius: 5px 0px 0px 5px;
    min-width: 350px;
    bottom: ${bottom}px;
    
    box-shadow: -2px 0 2px rgba(0, 0, 0, 0.2);
    display: grid;
    grid-template-rows: auto 1fr;
`;

export const navigateHistoryRowStyle = css`
    width: 100%;
    flex-basis: auto;
`;

const sideMenuWidth = DRAWER_WIDTH_XS;
const minDrawerWidth = DRAWER_WIDTH_SM;

export const drawerParentStyle = css`
    position: absolute;
    height: 100%;
    min-width: ${minDrawerWidth}px;
    display: flex;
    margin: 5px 0px 5px 5px;
    flex-direction: column;
    right: ${sideMenuWidth}px;
`;
