import React from 'react';
import { FC } from 'react';
import { useUserInfo } from '../../shared/motive/hooks/useUserInfo';
import { Account } from '../../components/account';

export const AccountContainer: FC = () => {
    const { data, isLoading, error, dispatch } = useUserInfo();

    if (!data.userInfo) {
        return <></>;
    }

    if (!!error) {
        return <>{error}</>;
    }

    return <Account isLoading={isLoading || !data} {...data.userInfo} dispatch={dispatch} />;
};
