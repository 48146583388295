//istanbul ignore file MOTIVE_EXCEPTION
import React, { useEffect } from 'react';
import { NavBarContainer } from '../../containers/navBar';
import { Spaces } from '../../components/spaces/Spaces';
import { useRoute, useEditorRouteMatch } from '../useRoute';
import { useCurrentSpace } from '../../shared/motive/hooks/useCurrentSpace';
import { useSpaces } from '../../shared/motive/hooks/useSpaces';
import { VisualError } from '../../components/visualError';
import { Link } from 'react-router-dom';
import { renderSubRoute } from '../editorRoutes';
import { HeightUsedScrollContainer } from '../../containers/heightUsedScrollContainer';
import { useLoadDataOnMount } from '../../shared/hooks/useLoadDataOnMount';
import { SpaceContainer } from '../../containers/spaceContainer';
import { UserAuthContainer } from '../../containers/userAuthContainer/UserAuthContainer';
import { DragAndDropProvider } from '../../components/_common/DragAndDrop/DragAndDropProvider/DragAndDropProvider';
import { spaceRouteLayoutStyle, spaceSubRouteLayout, rootLayoutStyle } from './SpacesRoute.style';

export interface ISpacesRouteParams {
    spaceName?: string;
}

export const useSpacesRoute = () => useRoute<ISpacesRouteParams>('Spaces');
export const useSpacesRouteMatch = () => useEditorRouteMatch<ISpacesRouteParams>('Spaces');

export const AuthenticatedSpacesRoute: React.FC = () => {
    const {
        subRoutes,
        params: { spaceName: spaceNameParam },
        buildPath: buildSpacesPath
    } = useSpacesRoute();
    const spaceDataSource = useSpaces();
    const [currentSpace, setCurrentSpace] = useCurrentSpace();

    useLoadDataOnMount(spaceDataSource);

    useEffect(() => {
        setCurrentSpace(spaceNameParam);
    }, [spaceNameParam]);

    const isCurrentSpaceValid = currentSpace && spaceDataSource.data?.some(s => s.fullName === currentSpace);
    const isSpaceSelected = spaceNameParam && currentSpace;
    const spacesPath = buildSpacesPath();

    return (
        <div css={rootLayoutStyle}>
            <DragAndDropProvider>
                <div css={spaceRouteLayoutStyle}>
                    <NavBarContainer> </NavBarContainer>
                    <div css={spaceSubRouteLayout}>
                        {!spaceDataSource.isLoading && (
                            <>
                                {!isSpaceSelected && (
                                    <HeightUsedScrollContainer>
                                        <Spaces />
                                    </HeightUsedScrollContainer>
                                )}
                                {isSpaceSelected && (
                                    <>
                                        {isCurrentSpaceValid && (
                                            <SpaceContainer space={currentSpace}>
                                                {subRoutes.map(renderSubRoute)}
                                            </SpaceContainer>
                                        )}
                                        {!isCurrentSpaceValid && (
                                            <VisualError message={'No such space exists'}>
                                                <Link to={spacesPath}>Spaces</Link>
                                            </VisualError>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </DragAndDropProvider>
        </div>
    );
};

export const SpacesRoute: React.FC = () => {
    return (
        <UserAuthContainer>
            <AuthenticatedSpacesRoute></AuthenticatedSpacesRoute>
        </UserAuthContainer>
    );
};
