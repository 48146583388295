import React, { Dispatch, FC } from 'react';
import { IAccountManagerViewModel } from '../../shared/motive/models/AccountManagerViewModel';
import { IChangePasswordForm, changePassword } from '../../shared/motive/networking/AccountService';
import { FormikHelpers, FormikProps, FieldValidator, Form, Field, Formik } from 'formik';
import { IMVCModelError } from '../../shared/motive/networking/MVCModelError';
import { Heading } from '../../core-ui/heading';
import { FormControl } from '../../core-ui/formControl';
import { Size } from '../../core-ui/constants/Size';
import { InputField } from '../../core-ui/inputField';
import { Button, ButtonVariant } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Divider } from '../../core-ui/divider';
import { useToast } from '../../core-ui/hooks/useToast';
import { UserInfoActionType } from '../../redux/userInfo/UserInfoActions';

const RenderForm: React.FC<FormikProps<IChangePasswordForm>> = ({
    handleSubmit,
    errors,
    values: { oldPassword: currentPassword, newPassword: nextPassword }
}) => {
    const currentPasswordId = 'currentPassword';
    const nextPasswordId = 'nextPasswordId';
    const confirmPasswordId = 'confirmPasswordId';

    const REQUIRED_FIELD_ERROR = 'Password is required.';
    const MUST_NOT_MATCH_ERROR = 'Password can not be the same as the current password.';
    const CONFIRM_MUST_MATCH_ERROR = 'Confirmation password must match new password.';
    const PASSWORD_LENGTH_ERROR = 'Password must be at least 6 characters long...';

    const validateCurrentPassword: FieldValidator = (value: string) => {
        if (!value) {
            return REQUIRED_FIELD_ERROR;
        }
    };

    const validateNextPassword: FieldValidator = (value: string) => {
        if (!value) {
            return REQUIRED_FIELD_ERROR;
        }

        if (value === currentPassword) {
            return MUST_NOT_MATCH_ERROR;
        }

        if (value.length < 6) {
            return PASSWORD_LENGTH_ERROR;
        }
    };

    const validateConfirmPassword: FieldValidator = (value: string) => {
        if (value !== nextPassword) {
            return CONFIRM_MUST_MATCH_ERROR;
        }

        if (!!validateNextPassword(value)) {
            return validateNextPassword(value);
        }
    };

    return (
        <Form>
            <Heading size={Size.MEDIUM}>Change Password:</Heading>
            <hr></hr>
            <FormControl
                label="Current Password"
                fieldId={currentPasswordId}
                required={true}
                helperText={errors.oldPassword ? errors.oldPassword : 'Your current password.'}
            >
                <Field
                    validate={validateCurrentPassword}
                    required={true}
                    id={currentPasswordId}
                    name="oldPassword"
                    type="password"
                    as={InputField}
                    useNativeOnChange={true}
                    placeholder="Password"
                    isInvalid={errors.oldPassword}
                />
            </FormControl>
            <FormControl
                label="New Password"
                fieldId={nextPasswordId}
                required={true}
                helperText={errors.newPassword ? errors.newPassword : 'Your new password.'}
            >
                <Field
                    validate={validateNextPassword}
                    required={true}
                    id={nextPasswordId}
                    name="newPassword"
                    type="password"
                    as={InputField}
                    useNativeOnChange={true}
                    placeholder="New Password"
                    isInvalid={errors.newPassword}
                />
            </FormControl>
            <FormControl
                fieldId={confirmPasswordId}
                required={true}
                helperText={errors.confirmPassword ? errors.confirmPassword : 'Confirm your password.'}
                label="Cofirm New Password"
            >
                <Field
                    validate={validateConfirmPassword}
                    required={true}
                    id={confirmPasswordId}
                    name="confirmPassword"
                    type="password"
                    as={InputField}
                    useNativeOnChange={true}
                    placeholder="Confirm Password"
                    isInvalid={errors.confirmPassword}
                />
            </FormControl>

            <Button type="submit" variant={ButtonVariant.SOLID} icon={IconTypes.EDIT} onClick={() => handleSubmit()}>
                Change
            </Button>
            <Divider></Divider>
        </Form>
    );
};

export const ChangePasswordForm: FC<IAccountManagerViewModel & { dispatch: Dispatch<UserInfoActionType> }> = () => {
    const toast = useToast();

    const CallChangePassword = async (
        values: IChangePasswordForm,
        { setErrors, resetForm, setSubmitting }: FormikHelpers<IChangePasswordForm>
    ) => {
        setSubmitting(true);
        var errors = await changePassword(values);
        setSubmitting(false);

        if (errors) {
            const { confirmPassword, newPassword, oldPassword, message } = errors;

            const getMessageString = (errorArray?: IMVCModelError[]) => {
                if (!errorArray) {
                    return '';
                }
                return errorArray?.map(m => m.ErrorMessage).join('\n');
            };

            setErrors({
                confirmPassword: getMessageString(confirmPassword),
                newPassword: getMessageString(newPassword),
                oldPassword: message ? message : getMessageString(oldPassword)
            });

            return;
        }

        toast({
            description: 'Successfully changed password',
            isClosable: true,
            position: 'bottom',
            status: 'success',
            title: 'Success'
        });

        resetForm();
    };

    const initialValues: IChangePasswordForm = {
        confirmPassword: '',
        oldPassword: '',
        newPassword: ''
    };

    return (
        <Formik initialValues={initialValues} onSubmit={CallChangePassword}>
            {RenderForm}
        </Formik>
    );
};
