import { IToken } from '../../shared/contexts/styleContext';
import { css } from '@emotion/core';

export const collapsibleHeader = (tokens: IToken) => css`
    color: ${tokens.colors.font.tertiary};
    padding: ${tokens.spacings.small} ${tokens.spacings.small};
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin-top: ${tokens.spacings.small};
    border-radius: 4px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
    width: 100%;
    border: none;
    outline: none;
`;
export const infoContainerStyle = (tokens: IToken) => css`
    color: ${tokens.colors.font.tertiary};
    padding: ${tokens.spacings.small} ${tokens.spacings.smaller} 0 ${tokens.spacings.smaller};
    user-select: none;
`;
