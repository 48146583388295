import { IEditModeAwareProps } from './IEditableAwareProps';
import React from 'react';

export const EditModeAware: React.FC<IEditModeAwareProps> = ({ editModeRender }) => {
    /*
    const { isEditMode } = useContext(EditableContext);

    return isEditMode ? (
        <>
            {editModeRender()}
            {children}
        </>
    ) : (
        <>
            {readonlyModeRender()}
            {children}
        </>
    );*/
    return editModeRender();
};
