import { IFontSizes } from '../../../shared/contexts/styleContext';

export const FontSizes: IFontSizes = {
    smallest: '0.4rem',
    smaller: '0.6rem',
    small: '0.8rem',
    medium: '1rem',
    large: '1.2rem',
    larger: '1.4rem',
    largest: '1.6rem'
};
