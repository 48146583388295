import { IToken } from './../../shared/contexts/styleContext';
import { css } from '@emotion/core';
import chroma from 'chroma-js';

export const collapsibleContentWrapperStyle = (token: IToken, isDragActive: boolean) => css`
    display: flex;
    flex-direction: column;
    background-color: ${isDragActive
        ? `${chroma(token.colors.interactible.primary)
              .alpha(0.1)
              .css()}`
        : `${token.colors.background.lighter}`};
    border: ${isDragActive
        ? `3px dashed ${token.colors.background.neutral}`
        : `3px dashed ${token.colors.background.light}`};
    justify-content: center;
    align-items: center;
    padding: ${token.spacings.medium};
    min-height: 6vh;
    cursor: pointer;
    outline: none;
    margin: ${token.spacings.small} 0;

    &:hover {
        background-color: #e5f1ff;
    }
`;

export const headingStyle = css`
    text-align: center;
    width: 100%;
`;

export const uploadItemStyle = (token: IToken) => css`
    text-align: left;
    padding: ${token.spacings.small};
`;

export const uploadLoadingBarStyle = css`
    width: 100%;
    background-color: #a98fd430;
    position: relative;
    border-radius: 2px;
`;
export const uploadLoadingItemStyle = (progress: number) => css`
    width: ${progress}%;
    background-color: #9980c3;
    transition: width 0.3s;
    height: 20px;
`;

export const uploadPercentStyle = css`
    position: absolute;
    left: 50%;
`;

export const uploadErrorStyle = (token: IToken) => css`
    padding: ${token.spacings.small};
    background-color: #a98fd430;
    font-weight: 600;
`;

export const uploadErrorButtonStyle = (token: IToken) => css`
    margin-right: ${token.spacings.smaller};
`;

export const uploadErrorMessageStyle = (token: IToken) => css`
    margin-bottom: ${token.spacings.smaller};
`;
