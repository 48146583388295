import React, { useEffect, useState } from 'react';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { IntentsContainer } from '../../containers/intentsContainer';
import { IntentCatalogList } from '../intentCatalogs';
import { ConversationalIntent } from '../../shared/motive/models/ConversationalIntent';
import { mergeCatalogItemsIntoSingleArray } from '../../util/CatalogUtils';
import { Button, ButtonVariant } from '../../core-ui/button';
import {
    createButtonStyle,
    createButtonWrapperStyle,
    createCatalogWrapperStyle
} from './DashboardIntentManagement.style';
import { InputField } from '../../core-ui/inputField';
import { useStyle } from '../../shared/hooks/useStyle';
import { Divider } from '../../core-ui/divider';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Collapsible } from '../../core-ui/collapse';
import { CreateIntentCatalog } from '../createIntentCatalog';

export interface IIntentManagementProps {
    agentCatalogs: ICatalog[];
    intentCatalogs: ICatalog[];
    selectedCatalog?: string;
    selectedIntent?: string;
    onSelectCatalog: (catalogId: string) => void;
    onSelectIntent: (catalogId: string, intentId: string) => void;
    onCreateCatalog?: (catalogTitle: string) => void;
    onRenameIntentCatalog?: (id: string, title: string) => Promise<void>;
    onDeleteIntentCatalog?: (intentCatalog: ICatalog) => Promise<void>;
    onUpdateAgentPivotField?: (intentCatalogId: string, agentId: string) => void;
    onGoBackToCatalogs?: () => void;
    isCreateCatalogLoading?: boolean;
    searchFilter?: string;
    onIntentSearch?: (str?: string) => void;
}

type AIViewState = 'viewIntentCatalogs' | 'viewIntent' | 'viewIntents';

export const DashboardIntentManagement: React.FC<IIntentManagementProps> = props => {
    const [viewState, setViewState] = useState<AIViewState>('viewIntentCatalogs');
    const [showCreateCatalog, setShowCreateCatalog] = useState<boolean>(false);

    const token = useStyle();

    // Keep view state in sync with route
    useEffect(() => {
        if (!props.selectedCatalog && !props.selectedIntent) {
            setViewState('viewIntentCatalogs');
        } else if (props.selectedCatalog && !props.selectedIntent) {
            setViewState('viewIntents');
        }
    }, [props.selectedCatalog, props.selectedIntent, setViewState]);

    const handleCreateCatalog = (catalogTitle: string) => {
        props.onCreateCatalog && props.onCreateCatalog(catalogTitle);
    };

    const handleSelectCatalog = (catalogId: string) => {
        props.onSelectCatalog && props.onSelectCatalog(catalogId);
        setShowCreateCatalog(false);
        setViewState('viewIntents');
    };

    const handleSelectIntent = (catalogId?: string, intentId?: string) => {
        // TODO: Pull training phrases from Dialogflow/Watson
        // catalogId && intentId && props.onSelectIntent && props.onSelectIntent(catalogId, intentId);
        // setViewState('viewIntent');
    };

    let viewContent = <></>;

    switch (viewState) {
        case 'viewIntentCatalogs':
            viewContent = (
                <IntentCatalogList
                    cats={props.intentCatalogs}
                    searchFilter={props.searchFilter}
                    agents={mergeCatalogItemsIntoSingleArray(props.agentCatalogs)}
                    onSelectCat={handleSelectCatalog}
                    onDeleteCat={props.onDeleteIntentCatalog}
                    onRenameCat={props.onRenameIntentCatalog}
                    isCreateCatalogLoading={props.isCreateCatalogLoading}
                />
            );
            break;
        case 'viewIntents':
            viewContent = (
                <IntentsContainer
                    intentCatalog={props.intentCatalogs.find(cat => cat.id === props.selectedCatalog)}
                    onSelectIntent={handleSelectIntent}
                    agentCatalogs={props.agentCatalogs}
                />
            );
            break;
        case 'viewIntent':
            const intentCatalog = props.intentCatalogs.find(cat => cat.id === props.selectedCatalog);
            const intent = intentCatalog?.items?.find(item => item.id === props.selectedIntent) as
                | ConversationalIntent
                | undefined;

            viewContent = intent ? (
                <>{`This should display the training phrases for ${props.selectedIntent}`}</>
            ) : (
                <>Intent Not Found.</>
            );
            break;
    }

    return (
        <div>
            {viewState === 'viewIntentCatalogs' && (
                <>
                    <div css={createButtonWrapperStyle}>
                        <InputField
                            value={props.searchFilter}
                            onChange={props.onIntentSearch}
                            placeholder={`Search for Intent Catalogs`}
                        />
                        <Button
                            icon={IconTypes.FOLDER}
                            css={createButtonStyle(token)}
                            variant={ButtonVariant.SOLID}
                            onClick={() => setShowCreateCatalog(!showCreateCatalog)}
                        >{`Create`}</Button>
                    </div>
                    <Divider />
                </>
            )}
            <Collapsible isShowing={showCreateCatalog}>
                <div css={createCatalogWrapperStyle(token)}>
                    <CreateIntentCatalog
                        isLoading={props.isCreateCatalogLoading}
                        onCatalogCreate={handleCreateCatalog}
                    />
                </div>
            </Collapsible>
            <>{viewContent}</>
        </div>
    );
};
