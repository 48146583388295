import { ICatalog } from '../../shared/motive/models/Catalog';
import React from 'react';
import { catalogTitleStyle, createScenarioCardStyle } from './CreateScenario.style';
import { IScriptDirectoryItem } from '../../shared/motive/models/ScriptDirectoryItem';
import { ScenarioItemContainer } from '../../containers/scenarioItemContainer';
import { ScenarioEditor } from '../scenarioEditor';
import { useCurrentProject } from '../../shared/motive/hooks/useCurrentProject';
import { Text } from '../../core-ui/text';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { Card } from '../../core-ui/card';
import { Divider } from '../../core-ui/divider';
import { uniqueMotiveId } from '../../util/MotiveUtils';
import { useAddCatalogItem } from '../../shared/motive/hooks/useCatalogs/useAddCatalogItem/useAddCatalogItem';
import { getDefaultScriptDirectory } from '../../util/DefaultScenario';

interface ICreateScenarioProps {
    catalogs: ICatalog[];
    onScenarioCreation?: () => void;
}

export const CreateScenario: React.FC<ICreateScenarioProps> = ({ catalogs, onScenarioCreation }) => {
    const [currentProject] = useCurrentProject();
    const createScenario = useAddCatalogItem();

    const currentCatalog = getDefaultScriptDirectory(catalogs, currentProject);

    const tempScenario: IScriptDirectoryItem = {
        id: uniqueMotiveId(),
        type: MotiveTypes.SCRIPT_DIRECTORY_ITEM
    };

    return (
        <Card css={createScenarioCardStyle}>
            {currentCatalog && (
                <>
                    <Text css={catalogTitleStyle}>{currentCatalog.title}</Text>
                    <div>
                        <Divider></Divider>
                    </div>

                    <div>
                        <ScenarioItemContainer>
                            {() => (
                                <ScenarioEditor
                                    scenario={tempScenario}
                                    catalog={currentCatalog}
                                    onSave={createScenario}
                                    onScenarioCreation={onScenarioCreation}
                                ></ScenarioEditor>
                            )}
                        </ScenarioItemContainer>
                    </div>
                </>
            )}
        </Card>
    );
};
