export enum LocalizationKeys {
    UnimplementedTestCase,
    Account,
    File,
    Login,
    LogOut,
    View,
    CreateProject,
    ExistingProject,
    Recent,
    Published,
    PublishedSettings,
    Draft,
    Menu,
    Save,
    Cancel,
    SignIntoMotive,
    LastSavedOn,
    Scripting,
    Catalog,
    Catalogs,
    Resource,
    Resources,
    Script,
    Scripts,
    CourseDescription,
    CourseTitle,
    EnterADescription,
    OutputOptions,
    LaunchOptions,
    Properties,
    LaunchWithAQuickCode,
    LaunchInHeadset,
    MotiveHosted,
    LaunchServerOptions,
    LaunchServerAuthentication,
    LaunchServerURL,
    Download,
    AutomaticUpload,
    AddNote,
    RemoveNote,
    ManageAccount,
    MySpaces,
    Note,
    AddCustomEvent,
    Delete,
    ProjectName,
    ProjectNameIsTooLong,
    ProjectNameIsTooShort,
    ProjectNameIsTaken,
    AdvancedSettings,
    CatalogOptions,
    AutoGenerateCatalogs,
    ImportCatalogs,
    Create,
    Scenarios,
    Media,
    Show,
    Hide,
    Copy,
    Team,
    AddTeamMember,
    PendingInvites,
    ConfigName,
    AppName,
    ApiKey,
    Project,
    Edited,
    Activity
}
