/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { extractDraggableInfo } from '../DragAndDropProvider/DragAndDropProvider';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { useEffect, useMemo } from 'react';
import { SerializedStyles } from '@emotion/core';
import { addDragEndAction, removeDropAction, setDragHoverAction } from '../DragAndDropProvider/DragAndDropActions';
import { useDragBeforeCapture } from '../../../../hooks/useDragContext';
import { useDragEndDispatch } from '../DragAndDropProvider/DragAndDropContext';

export interface IBaseDrag {
    dragType: string;
}

/**
 * @param onDragEnd A function that is fired when an accepted draggable is dropped onto this drop zone
 * @param onDragOverStyle A style that is applied when an accepted draggable is hovering over this drop zone. NOTE: This style is applied only to the
 * root level component, for more custom styling to inner elements within a drop zone, see the useCheckDragHover hook
 */
export interface IAllowedDrag<T extends IBaseDrag> extends IBaseDrag {
    onDragEnd: (data: T | undefined, index: number) => void;
    onDragOverStyle?: SerializedStyles;
}

export interface IDropProps {
    children?: React.ReactNode;
    dropId: string;
    allowedDragList?: IAllowedDrag<any>[];
    className?: string;
    showPlaceHolder?: boolean;
    direction?: 'horizontal' | 'vertical';
    isDisabled?: boolean;

    dragType?: string;
}

interface IDroppableState {
    dragType?: string;
    provided: DroppableProvided;
    snapshot: DroppableStateSnapshot;
}

interface IPrivateDropRecursiveProps extends IDropProps {
    currentIdx: number;
    droppableStates: IDroppableState[];
    currentDrag?: string;
}

const PrivateDropFinal: React.FC<IPrivateDropRecursiveProps> = (props: IPrivateDropRecursiveProps) => {
    const dispatch = useDragEndDispatch();

    //All snapshots should be the same
    const currentSnapshot = props.droppableStates.length > 0 && props.droppableStates[0].snapshot;
    const currentDragHover = useMemo(() => {
        return currentSnapshot && currentSnapshot.isDraggingOver && currentSnapshot.draggingOverWith
            ? extractDraggableInfo<IBaseDrag>(currentSnapshot.draggingOverWith)
            : undefined;
    }, [currentSnapshot]);

    useEffect(() => {
        dispatch(setDragHoverAction(props.dropId, currentDragHover ? currentDragHover : undefined));
    }, [dispatch, currentDragHover]);

    useEffect(() => {
        return () => {
            dispatch(setDragHoverAction(props.dropId, undefined));
        };
    }, [dispatch]);

    return (
        <div
            ref={ref => {
                props.droppableStates.forEach(p => p.provided.innerRef(ref));
            }}
            className={props.className}
            css={
                props.allowedDragList &&
                props.allowedDragList.find(item => currentDragHover && item.dragType === currentDragHover.dragType)
                    ?.onDragOverStyle
            }
        >
            {props.children}

            {props.droppableStates.map((state, i) => (
                //All placeholders are the same but we need to render them, so only display the first one if we want to show placeholder
                <div key={i} style={!props.showPlaceHolder || i > 0 ? { display: 'none' } : undefined}>
                    {state.provided.placeholder}
                </div>
            ))}
        </div>
    );
};

const PrivateDropRecursive: React.FC<IPrivateDropRecursiveProps> = (props: IPrivateDropRecursiveProps) => {
    return (
        <>
            {props.allowedDragList && props.currentIdx >= props.allowedDragList.length && props.currentIdx !== 0 ? (
                <PrivateDropFinal {...props} />
            ) : (
                <Droppable
                    droppableId={props.dropId}
                    direction={props.direction}
                    isDropDisabled={props.isDisabled}
                    //Set the droppable type dynamically from what is being dragged unless it was explicitly passed in. Draggables inherit the drag type from the nearest droppable parent.
                    type={
                        props.dragType
                            ? props.dragType
                            : props.allowedDragList?.find(c => c.dragType === props.currentDrag)?.dragType
                    }
                >
                    {(droppableProvided, state) => (
                        <PrivateDropRecursive
                            {...props}
                            droppableStates={[
                                ...props.droppableStates,
                                {
                                    provided: droppableProvided,
                                    snapshot: state,
                                    dragType: props.allowedDragList?.[props.currentIdx]?.dragType
                                }
                            ]}
                            currentIdx={props.currentIdx + 1}
                        />
                    )}
                </Droppable>
            )}
        </>
    );
};

/**
 * A drop component is should surround an area that wants to allow drag components of a particular drag ID to be accepted by it.
 */
export const Drop: React.FC<IDropProps> = ({ allowedDragList = [], ...props }: IDropProps) => {
    const dispatch = useDragEndDispatch();

    const beforeCapture = useDragBeforeCapture();
    const currentDragType = beforeCapture && extractDraggableInfo<IBaseDrag>(beforeCapture.draggableId)?.dragType;

    //Subscribe the functions from the allowed drag list to the drag handlers, unsub on unmount.
    useEffect(() => {
        if (allowedDragList.length > 0) {
            dispatch(
                addDragEndAction(
                    props.dropId,
                    allowedDragList.map(handler => {
                        return (data: IBaseDrag | undefined, index: number) => {
                            if (data && data.dragType !== handler.dragType) {
                                return;
                            }
                            handler.onDragEnd(data, index);
                        };
                    })
                )
            );
        }
    }, [dispatch, allowedDragList]);

    useEffect(() => {
        return () => {
            dispatch(removeDropAction(props.dropId));
        };
    }, [dispatch]);

    return (
        <PrivateDropRecursive
            {...props}
            allowedDragList={allowedDragList}
            currentIdx={0}
            droppableStates={[]}
            className={props.className}
            currentDrag={currentDragType}
        />
    );
};
