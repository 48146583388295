import { SelectCurrentProjectId } from '../../../../redux/spaceKeyed/spaceKeyedSelectors';
import { useSelector, useDispatch } from 'react-redux';
import {
    setCurrentProjectAction,
    unsetCurrentProjectAction
} from '../../../../redux/spaceKeyed/spaceKeyedStateActions';
import { useCallback } from 'react';

export const useCurrentProject: () => [string, (currentProject?: string) => void] = () => {
    const currentProject = useSelector(SelectCurrentProjectId) as string;
    const dispatch = useDispatch();

    const setCurrentProject = useCallback(
        (newProject?: string) => {
            if (newProject) {
                dispatch(setCurrentProjectAction(newProject));
            } else {
                dispatch(unsetCurrentProjectAction());
            }
        },
        [dispatch]
    );

    return [currentProject, setCurrentProject];
};
