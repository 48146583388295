import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { StoryFlowContainer } from '../../containers/storyFlowContainer';
import {
    ChooseScriptScopeContextStateProvider,
    ChooseScriptScopeContextDispatchProvider
} from '../../hooks/useChooseScriptScope';
import { useNavbarItem, useNavbarUpdateTab } from '../../redux/navbar/NavbarHooks';
import { SelectScriptEditorIsDirty } from '../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';
import { useBeforeUnload } from '../../shared/hooks/useBeforeUnload/useBeforeUnload';
import {
    ChooseExternalScriptScopeReducer,
    DEFAULT_STATE
} from '../../shared/motive/reducers/ChooseExternalScriptScopeReducer';

import { SaveErrorModal } from './SaveErrorModal';
import { ScriptEditorBar } from './ScriptEditorBar';
import { EditorCssFix, useScriptEditorActions } from './ScriptEditorHooks';
import { IScriptEditorSessionDependentProps } from './ScriptLoader';
import { noop } from 'lodash-es';

export const ScriptEditor: React.FC<IScriptEditorSessionDependentProps> = ({ script, selectedFrame }) => {
    const { rename, save, isSaving, error } = useScriptEditorActions();
    const isDirty = useSelector(SelectScriptEditorIsDirty);

    const updateTab = useNavbarUpdateTab();
    const tab = useNavbarItem(script.id);

    const [userForceClose, setUserForceClose] = useState(false);

    const warningMessage = `Are you sure you wish to leave? Any unsaved changes will be discarded.`;

    useBeforeUnload(() => isDirty, [isDirty]);

    useEffect(() => {
        if (!!tab) {
            updateTab({
                ...tab,
                promptBeforeUnload: {
                    shouldPrompt: isDirty,
                    message: warningMessage,
                    onBeforeClose: () => setUserForceClose(true)
                }
            });
        }
    }, [isDirty, script.id, !!tab]);

    const [chooseScriptScopeState, chooseScriptScopeDispatch] = React.useReducer(
        ChooseExternalScriptScopeReducer,
        DEFAULT_STATE
    );

    const isSelecting = chooseScriptScopeState?.state === 'choosing';

    return (
        <EditorCssFix>
            <ChooseScriptScopeContextStateProvider value={chooseScriptScopeState}>
                <ChooseScriptScopeContextDispatchProvider value={chooseScriptScopeDispatch}>
                    <Prompt message={warningMessage} when={isDirty && !userForceClose} />
                    <>
                        <SaveErrorModal
                            forceSave={() => save(script, true)}
                            isSaving={isSaving}
                            script={script}
                            error={error}
                        />
                    </>
                    <ScriptEditorBar
                        scriptScopeState={chooseScriptScopeState}
                        chooseScriptScopeDispatch={chooseScriptScopeDispatch}
                        isSelecting={isSelecting}
                        script={script}
                        save={save}
                        isSaving={isSaving}
                        rename={rename}
                    />
                    <StoryFlowContainer selectedFrame={selectedFrame} script={script} />
                </ChooseScriptScopeContextDispatchProvider>
            </ChooseScriptScopeContextStateProvider>
        </EditorCssFix>
    );
};
