import { frameNameInputStyle } from '../../../../components/frameInspectorHeader/FrameInspectorHeader.style';
import { useCurrentProjectConfig } from '../../../../redux/spaceKeyed/SpaceKeyedHooks';
import { createObjectEventLinkFrame, VersionDates } from '../../../../util/ScriptUtils';
import { IFrame } from '../../models/Script';
import { IScriptObjectModel } from '../../models/ScriptObjectModel';
import { IEnumItemReference } from '../../models/TypeDefinition';
import { useCurrentProjectId, useProjectConfig } from '../useProjectConfigs/useProjectConfigs';
import { useObjectDefinitions } from '../useScriptEditorInfo';

export const useCreateObjectEventLinkFrame = (): ((
    frameName: string,
    scriptObj: IScriptObjectModel,
    event: IEnumItemReference
) => IFrame) => {
    const objectTypeDefinitions = useObjectDefinitions();

    const config = useCurrentProjectConfig();

    const useItemRef = VersionDates.checkUseEnumRef(config?.typeVersionDate);

    return (frameName: string, scriptObj: IScriptObjectModel, event: IEnumItemReference) => {
        return createObjectEventLinkFrame(
            frameName,
            scriptObj,
            useItemRef ? event : event?.name,
            objectTypeDefinitions
        );
    };
};
