import { DragEndHandler } from './DragAndDropReducer';
import { IBaseDrag } from '../Drop/Drop';

export type IDragEndHandlerAction = ReturnType<
    typeof addDragEndAction | typeof removeDropAction | typeof setDragHoverAction
>;

export const addDragEndAction = (dropId: string, handler: DragEndHandler[]) => {
    return {
        type: 'dragDrop/addHandler',
        dropId,
        handler
    } as const;
};

export const removeDropAction = (dropId: string) => {
    return {
        type: 'dragDrop/delete',
        dropId
    } as const;
};

export const setDragHoverAction = (dropId: string, drag?: IBaseDrag) => {
    return {
        type: 'dragDrop/hover',
        dropId,
        drag
    } as const;
};
