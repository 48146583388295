import { ICatalog } from '../../../models/Catalog';
import { IScriptObjectModel } from '../../../models/ScriptObjectModel';
import { IHookProvidedDataSource } from '../../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useProjectConfig, useCurrentProjectId } from '../../useProjectConfigs/useProjectConfigs';
import { useGetAllCatalogsByType } from '..';

// Replaced by hook below 2020-06-16
// export const useGetAllCatalogsByType = (
//     type: string,
//     projectId?: string
// ): IHookProvidedDataSource<ICatalog<IScriptObjectModel>[]> => {
//     const objDef = useObjectDefinition(type);
//     const implementors = objDef?.implementors;
//     const types = [type].concat(implementors ? implementors : []);
//     return useCatalogsBaseHook({ type: types, projectId: projectId });
// };

export const useGetAllProjectCatalogsByType = (
    type: string
): IHookProvidedDataSource<ICatalog<IScriptObjectModel>[]> => {
    const result = useGetAllCatalogsByType(type);
    const projectId = useCurrentProjectId();
    const { data: projectConfig } = useProjectConfig(projectId);

    return {
        ...result,
        data: result.data.filter(c => projectConfig?.catalogs?.some(pc => pc.id === c.id))
    };
};
