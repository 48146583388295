import { patchCatalogNetworkCall } from '../../../networking/CatalogService';
import { useCurrentSpace } from '../../useCurrentSpace';
import { getSystemName } from '../../../../../util/MotiveUtils';
import { IHookProvidedCall } from '../../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../../hooks/useNetworkCallForDataSource';
import { SelectAllCatalogs } from '../../../../../redux/spaceKeyed/catalog/CatalogSelectors';
import { useSelector, useDispatch } from 'react-redux';

export interface IRenameCatalogParams {
    catalogId: string;
    catalogTitle: string;
}
export const useRenameCatalog = (): IHookProvidedCall<IRenameCatalogParams> => {
    const [currentSpace] = useCurrentSpace();
    const catalogDispatch = useDispatch();
    const catalogs = useSelector(SelectAllCatalogs);
    const getCatalog = (id: string) => catalogs.find(c => c.id === id);
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const patchCatalog = async (params: IRenameCatalogParams) => {
        const catalog = getCatalog(params.catalogId);
        catalog &&
            (await triggerCall(
                patchCatalogNetworkCall(
                    currentSpace,
                    {
                        id: catalog.id,
                        objectType: catalog.objectType,
                        title: params.catalogTitle,
                        name: getSystemName(params.catalogTitle)
                    },
                    getCancelableDispatch(catalogDispatch)
                )
            ));
    };

    return { execute: patchCatalog, ...rest };
};
