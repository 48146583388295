import React from 'react';
import { useUIStack, IStackItem } from '../../shared/hooks/useUIStack';
import { ICatalogContainerProps } from '../../containers/catalogContainer';
import {
    DrawerCatalogsTabContainer,
    IDrawerCatalogsTabContainerProps
} from '../../containers/drawerCatalogsTabContainer';
import { useChooseGlobalState, useChooseGlobalDispatch } from '../useChooseGlobal';
import { DrawerScriptsTabContainer } from '../../containers/drawerScriptsTabContainer';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { IChooseState, IChooseAction } from '../../shared/motive/reducers/ChooseExternalScriptScopeReducer';
import { useChooseMediaSourceState, useChooseMediaSourceDispatch } from '../useChooseMediaSource/UseChooseMediaSource';
import { useChooseScriptScopeDispatch } from '../useChooseScriptScope';
import { IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import {
    IDrawerSelectCatalogItemListContainerProps,
    DrawerSelectCatalogItemListContainer
} from '../../containers/drawerSelectCatalogItemListContainer/DrawerSelectCatalogItemListContainer';
import {
    IDrawerSelectCatalogItemContainerProps,
    DrawerSelectCatalogItemContainer
} from '../../containers/drawerSelectCatalogItemContainer/DrawerSelectCatalogItemContainer';
import { CatalogQuickEditorContainer } from '../../containers/catalogQuickEditorContainer';
import { EnumDefinitionsContainer } from '../../containers/enumDefinitinonsContainer';
import { HeightUsedScrollContainer } from '../../containers/heightUsedScrollContainer';
import { ICatalogInfo } from '../../shared/motive/models/Catalog';
import { MediaSourceCollectionContainer } from '../../containers/mediaSourceCollectionContainer';
import { DrawerIntentCatalogsContainer } from '../../containers/drawerIntentCatalogsContainer';
import {
    DrawerIntentsContainer,
    IDrawerIntentsContainerProps
} from '../../containers/drawerIntentsContainer/DrawerIntentsContainer';

export const DASHBOARD_DRAWER_SCRIPTS_KEY = 'scriptsTab';
export const DASHBOARD_DRAWER_CATALOGS_KEY = 'catalogsTab';
export const DASHBOARD_DRAWER_MEDIA_KEY = 'mediaTab';
export const DASHBOARD_DRAWER_ENUMS_KEY = 'enumsTab';
export const DASHBOARD_DRAWER_CAI_KEY = 'caiTab';

export const useGetDrawerStackItem = () => getDrawerStackItem;

type GetDrawerStackItemProps =
    | undefined
    | ICatalogContainerProps
    | IDrawerCatalogsTabContainerProps
    | IDrawerSelectCatalogItemListContainerProps
    | IDrawerSelectCatalogItemContainerProps
    | IDrawerIntentsContainerProps;

const getDrawerStackItem: (
    key:
        | 'catalogs'
        | 'catalog'
        | 'scriptCatalog'
        | 'scripts'
        | 'media'
        | 'mediaItemCatalogs'
        | 'selectCatalogItemList'
        | 'selectCatalogItem'
        | 'enums'
        | 'intentCatalogs'
        | 'intents',
    props?: GetDrawerStackItemProps
) => IStackItem = (key, props) => {
    switch (key) {
        case 'scripts':
            return {
                key: DASHBOARD_DRAWER_SCRIPTS_KEY,
                element: <DrawerScriptsTabContainer />,
                elementTitle: <span>scripts</span>
            };

        case 'catalogs': {
            let type: string | undefined = undefined;
            if (props) {
                ({ type } = props as IDrawerCatalogsTabContainerProps);
            }
            return {
                key: DASHBOARD_DRAWER_CATALOGS_KEY,
                element: (
                    <DrawerCatalogsTabContainer
                        showItemFilter={true}
                        type={type}
                        allowQuickObjectEdit={true}
                        blackListTypes={[
                            MotiveTypes.MOTIVE_SCRIPT,
                            MotiveTypes.MEDIA_ITEM,
                            MotiveTypes.SCRIPT_DIRECTORY_ITEM,
                            MotiveTypes.WATSON_ASSISTANT,
                            MotiveTypes.WATSON_SERVICE_CONFIGURATION,
                            MotiveTypes.WATSON_INTENT
                        ]}
                    />
                ),
                elementTitle: <span>{type ?? 'catalogs'}</span>
            };
        }
        case 'catalog': {
            const { catalogId, title } = props as ICatalogContainerProps;
            return {
                key: `catalog:${catalogId}`,
                elementTitle: <span>{title}</span>,
                element: <CatalogQuickEditorContainer catalogId={catalogId} />
            };
        }

        case 'scriptCatalog': {
            const { catalogId, title } = props as ICatalogContainerProps;
            return {
                key: `catalog:${catalogId}`,
                elementTitle: <span>{title}</span>,
                element: <CatalogQuickEditorContainer allowQuickObjectEdit={false} catalogId={catalogId} />
            };
        }
        case 'mediaItemCatalogs':
            return {
                key: `mediaItemCatalogs`,
                elementTitle: <span>Media Items</span>,
                element: (
                    <DrawerCatalogsTabContainer
                        allowQuickObjectEdit={true}
                        showItemFilter={true}
                        type={MotiveTypes.MEDIA_ITEM}
                    />
                )
            };
        case 'media':
            return {
                key: 'media',
                elementTitle: <span>Media</span>,
                element: <MediaSourceCollectionContainer />
            };
        case 'selectCatalogItemList': {
            const { type, onChoose } = props as IDrawerSelectCatalogItemListContainerProps;

            return {
                key: `selectCatalogItem`,
                elementTitle: <span>Select Item</span>,
                element: <DrawerSelectCatalogItemListContainer type={type} onChoose={onChoose} />
            };
        }
        case 'selectCatalogItem': {
            const { catalogId, onSelectItem, title } = props as IDrawerSelectCatalogItemContainerProps;

            return {
                key: `catalog:${catalogId}`,
                elementTitle: <span>{title}</span>,
                element: (
                    <DrawerSelectCatalogItemContainer title={title} catalogId={catalogId} onSelectItem={onSelectItem} />
                )
            };
        }
        case 'enums':
            return {
                key: `enums`,
                elementTitle: <span>Enum Definitions</span>,
                element: (
                    <HeightUsedScrollContainer>
                        <EnumDefinitionsContainer></EnumDefinitionsContainer>
                    </HeightUsedScrollContainer>
                )
            };
        case 'intentCatalogs':
            return {
                key: `intentCatalogs`,
                elementTitle: <span>Conversational AI</span>,
                element: (
                    <HeightUsedScrollContainer>
                        <DrawerIntentCatalogsContainer />
                    </HeightUsedScrollContainer>
                )
            };
        case 'intents':
            const { intentCatalogId } = props as IDrawerIntentsContainerProps;
            return {
                key: 'intents',
                elementTitle: <span>Conversational AI</span>,
                element: (
                    <HeightUsedScrollContainer>
                        <DrawerIntentsContainer intentCatalogId={intentCatalogId} />
                    </HeightUsedScrollContainer>
                )
            };
        default:
            throw new Error('dashboard drawer ui item not found');
    }
};

export const useDashboardDrawer = () => {
    const { pushItem, popItem } = useUIStack();
    const { state: chooseGlobalState } = useChooseGlobalState();
    const chooseGlobalDispatch = useChooseGlobalDispatch();
    const chooseMediaItemDispatch = useChooseMediaSourceDispatch();
    const { state: chooseMediaItemState } = useChooseMediaSourceState();
    const chooseScriptScopeDispatch = useChooseScriptScopeDispatch();

    const handleClickCatalogForSelectItem = (
        catalogInfo: ICatalogInfo,
        onSelectItem: (item: IScriptObjectModel | undefined) => void
    ) => {
        pushItem(
            'clearAfterCurrentIndex',
            getDrawerStackItem('selectCatalogItem', {
                title: catalogInfo.title,
                catalogId: catalogInfo.id,
                onSelectItem
            })
        );
    };

    const handleClickCatalog = (catalogInfo: ICatalogInfo) => {
        pushItem(
            'clearAfterCurrentIndex',
            getDrawerStackItem('catalog', { title: catalogInfo.title, catalogId: catalogInfo.id })
        );
    };

    const handleClickIntentCatalog = (catalogInfo: ICatalogInfo) => {
        pushItem('clearAfterCurrentIndex', getDrawerStackItem('intents', { intentCatalogId: catalogInfo.id }));
    };

    const handleClickScriptCatalog = (catalogId: string) => {
        pushItem('clearAfterCurrentIndex', getDrawerStackItem('scriptCatalog', { catalogId }));
    };

    const handleSelectGlobalCatalogItem = (catalogId: string, item: IScriptObjectModel) => {
        chooseGlobalDispatch({
            actionType: 'choose',
            chosenValue: item
        });

        popItem();
    };

    const popDrawerItem = (stackItem?: IStackItem) => {
        popItem(stackItem);
    };

    const handleSelectMediaSource = () => {
        chooseMediaItemDispatch({
            actionType: 'beginChoose',
            chooseType: MotiveTypes.MEDIA_ITEM
        });

        //const toPush = getDrawerStackItem('media');

        //pushItem('clearAfterCurrentIndex', toPush);
    };

    const handleChooseMediaSource = (mediaSource: IHostedMediaSource) => {
        if (chooseMediaItemState === 'choosing') {
            chooseMediaItemDispatch({
                actionType: 'choose',
                chosenValue: {
                    chooseType: 'mediaSource',
                    chosenItem: mediaSource.id
                }
            });

            popItem();
        }
    };

    const handleCancelSelectMediaSource = () => {
        chooseMediaItemDispatch({
            actionType: 'cancelChoose'
        });
    };

    const handleSelectExternalGlobalField = (
        choosingType: string,
        onChoose: (action: IChooseAction) => void
    ): IStackItem | undefined => {
        if (chooseGlobalState === 'choosing') {
            return undefined;
        }

        chooseGlobalDispatch({
            actionType: 'beginChoose',
            chooseType: choosingType
        });

        const toPush = getDrawerStackItem('selectCatalogItemList', { type: choosingType, onChoose });

        pushItem('clearAfterCurrentIndex', toPush);

        return toPush;
    };

    const handleCancelSelectExternalGlobalField = () => {
        chooseGlobalDispatch({
            actionType: 'cancelChoose'
        });
    };

    const handleSelectExternalScriptScopeField = (
        chooseTypes: string[],
        chooseFieldProps: IChooseState['chooseFieldProps'],
        onChoose?: IChooseState['onChoose']
    ) => {
        chooseScriptScopeDispatch({
            actionType: 'beginChoose',
            chooseTypes,
            chooseFieldProps,
            onChoose
        });
    };

    const handleCancelSelectExternalScriptScopeField = () => {
        chooseScriptScopeDispatch({
            actionType: 'cancelChoose'
        });
    };

    return {
        handleClickScriptCatalog,
        handleClickCatalog,
        handleClickIntentCatalog,
        handleSelectExternalGlobalField,
        handleCancelSelectExternalGlobalField,
        handleSelectMediaSource,
        handleChooseMediaSource,
        handleCancelSelectMediaSource,
        handleSelectGlobalCatalogItem,
        handleSelectExternalScriptScopeField,
        handleCancelSelectExternalScriptScopeField,
        handleClickCatalogForSelectItem,
        popDrawerItem
    };
};
