import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const rowItemStyle = ({ colors, spacings }: IToken, isLast: boolean) => css`
    ${!isLast && `border-bottom: 1px solid ${colors.border.primary};`}
    padding: ${spacings.small};
    &:hover {
        background-color: ${colors.background.lighter};
    }
`;

export const rowContentStyle = css`
    display: flex;
    align-items: center;
`;

export const scriptIconStyle = (token: IToken) => css`
    color: ${token.colors.background.light};
    margin-right: ${token.spacings.small};
`;

export const deleteIconWrapperStyle = (token: IToken) => css`
    float: right;
    color: ${token.colors.background.light};
    cursor: pointer;

    &:hover {
        filter: brightness(90%);
    }
`;
