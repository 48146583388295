import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const detailfolderIcon = css`
    font-size: 8em;
    color: gray;
`;

export const folderSection = (token: IToken) => css`
    padding: ${token.spacings.medium} 0;
    text-align: center;
    width: 100%;
    border-bottom: 1px solid ${token.colors.border.primary};
`;

export const listInformation = (token: IToken) => css`
    padding: ${token.spacings.smaller} 0;
    margin: 0 ${token.spacings.medium};
`;

export const listKey = (token: IToken) => css`
    color: ${token.colors.font.secondary};
    font-size: 0.9rem;
    margin-bottom: ${token.spacings.smallest};
`;

export const detailList = (token: IToken) => css`
    margin: ${token.spacings.medium} 0;
`;

export const externalCatalogItem = (token: IToken) => css`
    padding: ${token.spacings.smaller} 0;
`;

export const externalCatalogName = (token: IToken) => css`
    color: white;
    padding: ${token.spacings.smallest} ${token.spacings.smaller};
    border-radius: ${token.borderRadii.small};
    background-color: ${token.colors.interactible.tertiary};
    cursor: pointer;
    &:hover {
        filter: brightness(110%);
    }
`;
