import { css } from '@emotion/core';

export const spaceRouteLayoutStyle = css`
    display: grid;
    grid-template-rows: 60px 1fr;
    height: 100%;
    position: relative;
`;

export const spaceSubRouteLayout = css`
    height: 100%;
`;

export const rootLayoutStyle = css`
    height: 100vh;
    z-index: 0;
`;
