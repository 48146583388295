import React from 'react';
import { ConversationalAgent } from '../../shared/motive/models/ConversationalAgent';
import { EmptyCollection } from '../../core-ui/emptyCollection';
import { RowItem } from '../../core-ui/rowItem';
import { chevronIconStyle, rowStyle, subcontentStyle } from './Agents.style';
import { Icon } from '../../core-ui/icon';
import { iconWrapperStyle } from '../../core-ui/button/Button.style';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Size } from '../../core-ui/constants/Size';
import { useStyle } from '../../shared/hooks/useStyle';
import { Text } from '../../core-ui/text';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { IWatsonServiceConfiguration } from '../../shared/motive/models/WatsonServiceConfiguration';

export interface IAgentsProps {
    agents?: ConversationalAgent[];
    onSelectAgent?: (agent: string) => void;
    onCreateAgent?: (props: ConversationalAgent) => Promise<void>;
    onCreateWatsonConfiguration?: (config: IWatsonServiceConfiguration) => Promise<void>;
    onUpdateWatsonConfiguration?: (config: IWatsonServiceConfiguration) => Promise<void>;
    watsonConfigurations?: IWatsonServiceConfiguration[];
    searchFilter?: string;
    onSearchAgents?: (str?: string) => void;
}

interface IAgentItemProps {
    agent: ConversationalAgent;
    onSelectAgent?: (agent: string) => void;
}

const agentTypeToProviderName = (type: string) => {
    switch (type) {
        case MotiveTypes.WATSON_ASSISTANT:
            return 'IBM Watson';
    }
};

export const Agents: React.FC<IAgentsProps> = (props): React.ReactElement => {
    if (!props.agents?.length) {
        return <EmptyCollection message={'No agents found for this project. Would you like to create one?'} />;
    }

    const filteredAgentCats = props.searchFilter
        ? props.agents.filter(agents =>
              agents.name.toLocaleUpperCase().includes((props.searchFilter as string).toLocaleUpperCase())
          )
        : props.agents;

    const agentItems = filteredAgentCats.map(agent => {
        return <AgentItem key={agent.id} agent={agent} onSelectAgent={props.onSelectAgent} />;
    });

    return <>{agentItems}</>;
};

const AgentItem: React.FC<IAgentItemProps> = (props): React.ReactElement => {
    const token = useStyle();
    const handleAgentSelect = () => {
        props.onSelectAgent && props.onSelectAgent(props.agent.id);
    };

    return (
        <RowItem css={rowStyle(token)} size={Size.MEDIUM} onClick={handleAgentSelect}>
            {() => ({
                content: (
                    <Text size={Size.LARGEST}>{props.agent.name.length > 0 ? props.agent.name : props.agent.id}</Text>
                ),
                subContent: <span css={subcontentStyle(token)}>{agentTypeToProviderName(props.agent.type)}</span>,
                rightContent: (
                    <Icon css={iconWrapperStyle} iconStyle={chevronIconStyle} icon={IconTypes.CHEVRON_DOWN}></Icon>
                )
            })}
        </RowItem>
    );
};
