import React from 'react';
import { IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { Text } from '../../core-ui/text/Text';
import { times } from 'lodash-es';
import { RowItem } from '../../core-ui/rowItem';
import { MediaItemPreview } from '../mediaItemPreview';
import { useStyle } from '../../shared/hooks/useStyle';
import { prettierSimpleDate } from '../../util/DateUtils';
import { formatBytes } from '../../util/Utils';
import { LoadingSkeleton } from '../../core-ui/loadingSkeleton/LoadingSkeleton';
import {
    rowItemStyle,
    gridRowStyle,
    thumbnailWrapperStyle,
    mediaItemPreviewStyle,
    textTitleWrapperStyle,
    textMetadataWrapperStyle
} from './MediaItemRow.style';
import { IMediaSourceEntity } from '../../redux/spaceKeyed/media/MediaReducer';

interface IMediaItemRow {
    mediaEntity?: IMediaSourceEntity;
    onClick?: (item: IHostedMediaSource) => void;
}

export const MediaItemRow: React.FC<IMediaItemRow> = ({ mediaEntity, onClick }) => {
    const token = useStyle();

    return (
        <RowItem
            css={rowItemStyle(token, !!onClick)}
            onClick={() => onClick && mediaEntity && onClick(mediaEntity.mediaSource)}
        >
            {() => ({
                content: (
                    <div css={gridRowStyle}>
                        <>
                            {mediaEntity ? (
                                <>
                                    <div css={thumbnailWrapperStyle(token)}>
                                        <MediaItemPreview
                                            css={mediaItemPreviewStyle(token)}
                                            mediaItem={mediaEntity.mediaSource}
                                            loadImagesOnly
                                        />
                                    </div>
                                    <Text css={textTitleWrapperStyle(token)}>{mediaEntity.mediaSource.title}</Text>
                                </>
                            ) : (
                                <>
                                    <LoadingSkeleton wrapper={MediaItemPreview} /> <LoadingSkeleton width={50} />
                                </>
                            )}
                        </>
                        {mediaEntity ? (
                            <>
                                <Text css={textMetadataWrapperStyle(token)}>
                                    {prettierSimpleDate(mediaEntity.mediaSource.lastModified)}
                                </Text>
                                <Text css={textMetadataWrapperStyle(token)}>{mediaEntity.mediaSource.contentType}</Text>
                                <Text css={textMetadataWrapperStyle(token)}>
                                    {formatBytes(mediaEntity.mediaSource.contentLength)}
                                </Text>
                            </>
                        ) : (
                            <>
                                {times(3, idx => (
                                    <LoadingSkeleton key={idx} width={70} />
                                ))}
                            </>
                        )}
                    </div>
                )
            })}
        </RowItem>
    );
};
