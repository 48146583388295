import { IToken } from '../../shared/contexts/styleContext';
import { css } from '@emotion/core';
import chroma from 'chroma-js';

export const scriptEditorBarStyle = (token: IToken) => css`
    display: flex;
    flex-direction: row;
    padding: ${token.spacings.smaller} 0px;
    align-items: center;
    width: 100%;
    background-color: ${chroma(token.colors.bar.dashboardNavbar)
        .darken()
        .css()};
`;

export const flexGroupStyle = css`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: start;
`;

export const saveButtonFlex = css`
    // flex: 1;
    // display: flex;
    // flex-direction: row-reverse;
    // align-items: center;
`;

export const saveButtonWrapper = css`
    width: 150px;
    margin: 0 5px;
    color: white;
    & > button {
        width: 100%;
        //height: 24px;
    }
`;

export const scriptNameWrapperStyle = css``;

export const flexSelectingGroupStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const languageSelector = css`
    padding-right: 10px;
    color: white;
    display: flex;
    flex-direction: row;
`;

export const languageSelectorDropdown = (token: IToken) => css`
    color: ${token.colors.font.primary};
    margin-left: ${token.spacings.smallest};
`;

export const scriptEditorNameStyle = (token: IToken) => css`
    margin: 0px ${token.spacings.smaller};
    width: 300px;
    height: 30px;
`;

export const scriptEditorLastModifiedStyle = (token: IToken) => css`
    color: ${token.colors.font.tertiary};
    width: 50%;
    white-space: nowrap;
    font-style: italic;
`;

export const upperCaseStyle = css`
    text-transform: uppercase;
`;
