import i18n from 'i18next';

import translationsEn from './locales/en/translations.json';
import translationsFr from './locales/fr/translations.json';

import { initReactI18next } from 'react-i18next';

export const resources = {
    en: {
        translations: translationsEn
    },
    fr: {
        translations: translationsFr
    }
} as const;

i18n.use(initReactI18next).init({
    lng: 'en',
    supportedLngs: ['en', 'fr'],
    resources,
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
        formatSeparator: ',',
        format: function(value, format, lng) {
            if (format === 'uppercase') return value.toUpperCase();
            if (format === 'lowercase') return value.toLowerCase();
            return value;
        }
    },
    react: {
        useSuspense: true
    }
});
