/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ScriptActionType,
    IScriptEditorAddDefinedVariablesAction
} from '../../../shared/motive/reducers/ScriptEditorReducers';
import { Action } from 'redux';
import {
    getDefaultStateHistory,
    trackStateHistoryReducer,
    IStateHistoryUpdate,
    StateHistoryAction,
    IStateHistoryTrackable
} from '../../../shared/motive/reducers/StateHistoryTree';
import { MotiveTypes } from '../../../constants/MotiveTypes';
import { Logger } from '../../../util/logger';
import {
    addResource,
    removeResource,
    moveResource,
    updateResource,
    updateFrameObject,
    updateFrame,
    addFrame,
    removeFrame,
    findFrame,
    renameFrame,
    addPreCondition,
    eachFrame,
    addResourceWrapper
} from '../../../util/ScriptUtils';
import {
    IFrame,
    IScript,
    IResourceWrapper,
    IScriptVariable,
    IScriptEventDefinition
} from '../../../shared/motive/models/Script';
import { IScriptEditorInfo } from '../../../shared/motive/models/ScriptEditorInfo';
import { IScriptObjectModel } from '../../../shared/motive/models/ScriptObjectModel';
import { ITypeDefinitionMap } from '../../../shared/motive/models/TypeDefinition';
import { addDefinedVariableToFrame, removeFrameVariable } from '../../../util/ScriptDynamicsUtil';

export interface IFrameIdTree {
    frameId: string;
    subFrameIds: IFrameIdTree[];
    checkIsEnabled: () => boolean;
}

export interface IFrameMap {
    [key: string]: IFrame;
}

export interface IExternalHighlight {
    resourceId?: string;
}

export interface IScriptEditorStore extends IStateHistoryTrackable<IScriptEditorStore> {
    script: IScript;
    selectedFrameId: string;
    isDirty: boolean;
    frameIdTree: IFrameIdTree;
    frameMap: IFrameMap;
    externalHighlight?: IExternalHighlight;
}

const DEFAULT_SCRIPT_EDITOR_STORE_VALUE: IScriptEditorStore = {
    script: {
        id: '',
        name: '',
        type: '',
        rootFrame: {
            name: '',
            isExclusive: false,
            isEnabled: true,
            isLive: false,
            resetOnClose: false,
            id: '',
            type: MotiveTypes.FRAME
        }
    },
    stateHistory: getDefaultStateHistory(),
    selectedFrameId: '',
    isDirty: false,
    frameIdTree: { frameId: '', subFrameIds: [], checkIsEnabled: () => true },
    frameMap: {}
};

export interface IScriptEditorAction extends Action<ScriptActionType> {
    //operation: ScriptActionType;
    script?: IScript;
    scriptEditorInfo?: IScriptEditorInfo;
    resource?: IScriptObjectModel;
    resourceWrapper?: IResourceWrapper;
    destinationIndex?: number;
    sourceIndex?: number;
    targetFrameId?: string;
    resourceId?: string;
    destinationFrameId?: string;
    newName?: string;
    isDirty?: boolean;
    state?: IScriptEditorStore;
    externalHighlight?: IExternalHighlight;
}

export interface IScripCustomUpdateAction extends IScriptEditorAction {
    type: ScriptActionType.SCRIPT_UPDATE_CUSTOM;
    update: (script: IScript) => IScript;
}

export interface IScriptEditorVariablesAction extends IScriptEditorAction {
    type: ScriptActionType.UPDATE_SCRIPT_VARIABLES;
    variables: IScriptVariable[] | undefined;
}

export interface IScriptEditorEventsAction extends IScriptEditorAction {
    type: ScriptActionType.UPDATE_SCRIPT_EVENTS;
    events: IScriptEventDefinition[] | undefined;
}

export interface IScriptFrameAction extends IScriptEditorAction {
    frame?: IFrame;
}

export interface IResourceWrapperAction extends IScriptEditorAction {
    type: ScriptActionType.RESOURCE_WRAPPER_ADD;
    resourceWrapper: IResourceWrapper;
}

export interface IScriptObjectUpdateAction extends IScriptEditorAction {
    updatedObject: any;
    updatedObjectPath: string;
    targetFrameId: string;
}

export interface IScriptFrameObjectAction extends IScriptFrameAction {
    object: IScriptObjectModel;
    objectDefinitions: ITypeDefinitionMap;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IScriptEditorActionExternalScriptScopeUpdate extends IScriptObjectUpdateAction {
    targetFrameId: string;
}

export interface IScriptEditorActionObjectIdAction extends IScriptObjectUpdateAction {
    objectId: string;
}

function buildFrameMap(frame: IFrame): IFrameMap {
    const map: IFrameMap = {};

    eachFrame(frame, f => (map[f.id] = f));

    return map;
}

export type ScriptEditorReducerAction =
    | IScriptEditorAction
    | IScriptFrameAction
    | IScriptObjectUpdateAction
    | IScriptEditorActionExternalScriptScopeUpdate
    | IStateHistoryUpdate<IScriptEditorStore>;

export function PerformScriptEditorReducer(
    state: IScriptEditorStore,
    action: ScriptEditorReducerAction
): IScriptEditorStore {
    switch (action.type) {
        case StateHistoryAction.UPDATE: {
            if (!action.state) {
                throw new Error('Cannot do update with no state provided in action.');
            }
            return { ...action.state };
        }
        case ScriptActionType.SCRIPT_UPDATE_CUSTOM: {
            const updateAction = action as IScripCustomUpdateAction;

            const newScript = updateAction?.update(state.script);

            if (newScript) {
                return {
                    ...state,
                    script: newScript,
                    frameMap: buildFrameMap(newScript.rootFrame)
                };
            } else {
                throw new Error('Custom update did not return a Script.');
            }
        }
        case ScriptActionType.SET_SCRIPT: {
            if (!action.script) {
                throw new Error('Cannot set script. A new script was not provided.');
            }

            return {
                ...state,
                script: action.script,
                selectedFrameId:
                    action.targetFrameId ?? action.script.rootFrame.subFrames?.[0]?.id ?? action.script.rootFrame.id,
                isDirty: false,
                stateHistory: getDefaultStateHistory(),
                frameMap: buildFrameMap(action.script.rootFrame)
            };
        }
        case ScriptActionType.UPDATE_SCRIPT_VARIABLES: {
            const variableAction = action as IScriptEditorVariablesAction;
            if (!state.script) {
                throw new Error('Cannot set variables. A script was not provided.');
            }

            return {
                ...state,
                script: {
                    ...state.script,
                    variables: variableAction.variables
                },
                isDirty: true
            };
        }
        case ScriptActionType.UPDATE_SCRIPT_EVENTS: {
            const variableAction = action as IScriptEditorEventsAction;
            if (!state.script) {
                throw new Error('Cannot set events. A script was not provided.');
            }

            return {
                ...state,
                script: {
                    ...state.script,
                    events: variableAction.events
                },
                isDirty: true
            };
        }

        case ScriptActionType.SCRIPT_RENAME: {
            if (!state.script) {
                Logger.error('Cannot rename a script. A script was not provided.');
                break;
            }

            if (!action.newName) {
                Logger.error('Cannot rename a script. A new name was not provided.');
                break;
            }

            return { ...state, script: { ...state.script, name: action.newName }, isDirty: true };
        }

        case ScriptActionType.RESOURCE_WRAPPER_ADD: {
            var wrapperAction = action as IResourceWrapperAction;

            if (!state.script) {
                Logger.error('Cannot add a resource. A script was not provided.');
                break;
            }

            if (state.script.rootFrame.id === state.selectedFrameId) {
                Logger.error('Cannot add a resource. You cannot add a resource to the main frame.');
                break;
            }

            const { script, frameId } = addResourceWrapper(
                state.script,
                state.selectedFrameId,
                wrapperAction.resourceWrapper,
                wrapperAction.destinationIndex
            );

            return {
                ...state,
                script: script,
                selectedFrameId: frameId,
                isDirty: true,
                frameMap: buildFrameMap(script.rootFrame)
            };
        }

        case ScriptActionType.RESOURCE_ADD: {
            if (!state.script) {
                Logger.error('Cannot add a resource. A script was not provided.');
                break;
            }

            if (state.script.rootFrame.id === state.selectedFrameId) {
                Logger.error('Cannot add a resource. You cannot add a resource to the main frame.');
                break;
            }

            const { script, frameId } = addResource(
                state.script,
                state.selectedFrameId,
                action.resource,
                action.destinationIndex
            );

            return {
                ...state,
                script: script,
                selectedFrameId: frameId,
                isDirty: true,
                frameMap: buildFrameMap(script.rootFrame)
            };
        }

        case ScriptActionType.RESOURCE_REMOVE: {
            if (!state.script) {
                Logger.error('Cannot remove a resource. A script was not provided.');
                break;
            }

            if (action.resourceId === undefined) {
                Logger.error('Cannot remove a resource. An id was not provided.');
                break;
            }

            const { script, frameId } = removeResource(state.script, state.selectedFrameId, action.resourceId);
            return {
                ...state,
                script: script,
                selectedFrameId: frameId,
                isDirty: true,
                frameMap: buildFrameMap(script.rootFrame)
            };
        }

        case ScriptActionType.RESOURCE_MOVE: {
            if (!state.script) {
                Logger.error('Cannot move a resource. A script was not provided.');
                break;
            }

            if (action.sourceIndex === undefined) {
                Logger.error('Cannot move a resource. A source index was not provided.');
                break;
            }

            if (action.destinationIndex === undefined) {
                Logger.error('Cannot move a resource. A destination index was not provided.');
                break;
            }

            if (action.resourceId === undefined) {
                Logger.error('Cannot remove a resource. An id was not provided.');
                break;
            }

            if (action.destinationFrameId && action.destinationFrameId !== state.selectedFrameId) {
                if (action.destinationFrameId === state.script.rootFrame.id) {
                    break;
                }
                const { script: removeScript, resourceWrapper } = removeResource(
                    state.script,
                    state.selectedFrameId,
                    action.resourceId
                );

                if (resourceWrapper) {
                    const { script: addScript } = addResourceWrapper(
                        removeScript,
                        action.destinationFrameId,
                        resourceWrapper
                    );

                    return {
                        ...state,
                        script: addScript,
                        isDirty: true,
                        frameMap: buildFrameMap(addScript.rootFrame)
                    };
                } else {
                    break;
                }
            } else {
                const { script, frameId } = moveResource(
                    state.script,
                    state.selectedFrameId,
                    action.sourceIndex,
                    action.destinationIndex
                );

                return {
                    ...state,
                    script: script,
                    selectedFrameId: frameId,
                    isDirty: true,
                    frameMap: buildFrameMap(script.rootFrame)
                };
            }
        }

        case ScriptActionType.RESOURCE_UPDATE: {
            if (!state.script) {
                Logger.error('Cannot update a resource. A script was not provided.');
                break;
            }

            if (!action.resourceWrapper) {
                Logger.error('Cannot update a resource. A resource was not provided.');
                break;
            }

            if (!action.targetFrameId) {
                throw new Error('target frame id required');
            }

            const { script } = updateResource(state.script, action.resourceWrapper, action.targetFrameId);

            return {
                ...state,
                script: script,
                isDirty: true,
                selectedFrameId: action.targetFrameId,
                frameMap: buildFrameMap(script.rootFrame)
            };
        }

        case ScriptActionType.OBJECT_UPDATE: {
            const update = action as IScriptObjectUpdateAction;

            if (update.updatedObjectPath !== undefined) {
                const script = updateFrameObject(
                    state.script,
                    update.targetFrameId,
                    update.updatedObject,
                    update.updatedObjectPath
                );

                return {
                    ...state,
                    script: script,
                    isDirty: true,
                    selectedFrameId: update.targetFrameId,
                    frameMap: buildFrameMap(script.rootFrame)
                };
            } else {
                Logger.debug('A resource was not provided.');
            }

            break;
        }

        case ScriptActionType.FRAME_UPDATE: {
            const update = action as IScriptFrameAction;

            if (update.frame !== undefined) {
                const script = updateFrame(state.script, update.frame);

                return {
                    ...state,
                    script: script,
                    isDirty: true,
                    frameMap: buildFrameMap(script.rootFrame)
                };
            } else {
                Logger.debug('A resource was not provided.');
            }

            break;
        }

        case ScriptActionType.FRAME_SELECT: {
            if (!state.script) {
                Logger.error('Cannot select a frame. A script was not provided.');
                break;
            }

            if (!action.targetFrameId) {
                Logger.error('Can not select frame without a frame id');
                break;
            }

            return { ...state, selectedFrameId: action.targetFrameId };
        }

        case ScriptActionType.FRAME_ADD: {
            const faction = action as IScriptFrameAction;
            if (!state.script) {
                Logger.error('Cannot add a frame. A script was not provided.');
                break;
            }

            if (!faction.targetFrameId) {
                Logger.error('Cannot add a frame. A target frame Id was not provided');
                break;
            }

            const { script, frameId } = addFrame(state.script, faction.targetFrameId, faction.frame);
            return {
                ...state,
                script: script,
                selectedFrameId: faction?.frame?.id ?? frameId,
                frameMap: buildFrameMap(script.rootFrame),
                isDirty: true
            };
        }

        case ScriptActionType.FRAME_REMOVE: {
            if (!state.script) {
                Logger.error('Cannot remove frame. A script was not provided.');
                break;
            }

            if (!action.targetFrameId) {
                Logger.error('Cannot remove a frame. A target frame Id was not provided');
                break;
            }

            const { script, frameId } = removeFrame(state.script, action.targetFrameId);
            return {
                ...state,
                script: script,
                selectedFrameId: frameId,
                frameMap: buildFrameMap(script.rootFrame),
                isDirty: true
            };
        }

        case ScriptActionType.FRAME_MOVE: {
            const oldSelectedFrameId = state.selectedFrameId;

            if (!state.script) {
                Logger.error('Cannot move frames. A script was not provided.');
                break;
            }

            if (!action.destinationFrameId) {
                Logger.error('Cannot move frames. A destination frame Id was not provided.');
                break;
            }

            const frame = findFrame(state.script.rootFrame, action.targetFrameId ? action.targetFrameId : '');
            const scriptWithoutFrame = removeFrame(state.script, action.targetFrameId);
            const { script } = addFrame(
                scriptWithoutFrame.script,
                action.destinationFrameId,
                frame as IFrame,
                action.destinationIndex
            );

            return {
                ...state,
                script: script,
                selectedFrameId: oldSelectedFrameId,
                isDirty: true
            };
        }
        case ScriptActionType.FRAME_RENAME: {
            if (!action.targetFrameId) {
                Logger.error('Cannot rename frame. A target frame ID was not provided.');
                break;
            }

            if (!action.newName) {
                Logger.error('Cannot rename frame. A new name was not provided.');
                break;
            }

            if (!state.script) {
                Logger.error('Cannot rename frame. A script was not provided.');
                break;
            }

            const newScript = renameFrame(state.script, action.targetFrameId, action.newName);

            return {
                ...state,
                script: newScript,
                isDirty: true,
                frameMap: buildFrameMap(newScript.rootFrame)
            };
        }

        case ScriptActionType.PRE_CONDITION_ADD: {
            const faction = action as IScriptFrameObjectAction;

            if (!state.script) {
                Logger.error('Cannot add a resource. A script was not provided.');
                break;
            }

            const conditionAddFrameId = action.destinationFrameId ?? state.selectedFrameId;
            if (state.script.rootFrame.id === conditionAddFrameId) {
                Logger.error('Cannot add a resource. You cannot add a resource to the main frame.');
                break;
            }

            const { script, frameId } = addPreCondition(
                state.script,
                conditionAddFrameId,
                faction.object,
                faction.objectDefinitions
            );

            return {
                ...state,
                script: script,
                selectedFrameId: frameId,
                isDirty: true,
                frameMap: buildFrameMap(script.rootFrame)
            };
        }

        case ScriptActionType.RESET_STATE: {
            return { ...DEFAULT_SCRIPT_EDITOR_STORE_VALUE, stateHistory: getDefaultStateHistory() };
        }

        case ScriptActionType.ADD_DEFINED_VARIABLES_TO_FRAME: {
            const { variableIds, targetFrameId } = (action as unknown) as IScriptEditorAddDefinedVariablesAction;
            if (!targetFrameId) {
                throw new Error('no frame id');
            }
            const nullableFrame = findFrame(state.script.rootFrame, targetFrameId);
            if (!nullableFrame) {
                throw new Error('no frame found');
            }
            let frame: IFrame = nullableFrame;
            variableIds.forEach(varId => {
                frame = addDefinedVariableToFrame(varId, frame);
            });
            const script = updateFrame(state.script, frame);
            return {
                ...state,
                script,
                isDirty: true,
                frameMap: buildFrameMap(script.rootFrame)
            };
        }

        case ScriptActionType.VARIABLE_DELETE: {
            var _action = action as IScriptEditorActionObjectIdAction;

            const variables = state.script.variables?.filter(v => v.id !== _action.objectId);

            const removeVariable = (frame: IFrame) => {
                const newFrame = removeFrameVariable(frame, _action.objectId);

                if (newFrame.subFrames) {
                    newFrame.subFrames = newFrame.subFrames.map(f => removeVariable(f));
                }

                return newFrame;
            };

            const script: IScript = {
                ...state.script,
                variables: variables,
                rootFrame: removeVariable(state.script.rootFrame)
            };

            return {
                ...state,
                script,
                isDirty: true,
                frameMap: buildFrameMap(script.rootFrame)
            };
        }
        case ScriptActionType.SIGNAL_RESOURCE: {
            return { ...state, externalHighlight: action.externalHighlight };
        }
    }

    return state;
}

function isScriptActionType(action: Action<any>) {
    return Object.values(ScriptActionType).includes(action.type);
}

// Currently just wraps calls to the old reducer, we can migrate that code over here in time.
export function ScriptEditorReducer(
    state = DEFAULT_SCRIPT_EDITOR_STORE_VALUE,
    action: Action<StateHistoryAction | ScriptActionType>
) {
    //filter invalid types so we don't track state history on it.
    if (!isScriptActionType(action) && action.type !== StateHistoryAction.UPDATE) {
        return state;
    }

    const next = trackStateHistoryReducer(
        PerformScriptEditorReducer,
        action as IStateHistoryUpdate<IScriptEditorStore>,
        [ScriptActionType.SET_TYPE_DEFINITIONS, ScriptActionType.RESET_STATE, ScriptActionType.SIGNAL_RESOURCE],
        state,
        action
    );

    return next;
}

export const deleteScriptVariable = (variableId: string) => {
    return {
        type: ScriptActionType.VARIABLE_DELETE,
        objectId: variableId
    };
};
