import { css } from '@emotion/core';

export const renamingScriptStyle = css`
    opacity: 0.7;
`;

export const scriptNameStyle = css`
    display: flex;
    align-items: center;
`;

export const optionMenuRow = css`
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
`;

export const optionMenuIcon = css`
    margin-right: 6px;
    width: 18px;
`;

export const optionMenuDividerStyle = css`
    width: 100%;
`;
