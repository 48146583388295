import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const conflictModalStyle = (token: IToken) => css`
    padding: ${token.spacings.medium};
`;

export const conflictModalButtonStyle = (token: IToken) => css`
    display: flex;
    justify-content: flex-end;
    margin-top: ${token.spacings.small};
`;
