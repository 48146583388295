import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconTypes } from './../constants/IconTypes';
import {
    faThList,
    faHistory,
    faComment,
    faUser,
    faUsers,
    faPlay,
    faTrash,
    faMagic,
    faCopy,
    faCog,
    faCogs,
    faChartBar,
    faPlus,
    faFolderOpen,
    faChevronDown,
    faHeadphones,
    faExternalLink,
    faSave,
    faChevronLeft,
    faFilm,
    faSlidersV,
    faCheck,
    faMinus,
    faFile,
    faEllipsisH,
    faUndo,
    faRedo,
    faFolder,
    faUpload,
    faEye,
    faEyeSlash
} from '@fortawesome/pro-solid-svg-icons';
import {
    faPersonDolly,
    faAtom,
    faAnchor,
    faCopy as faCopyHollow,
    faCube,
    faCubes,
    faTh,
    faTrash as faTrashHollow,
    faPhotoVideo,
    faSortAlt,
    faWalking,
    faArrowUp,
    faArrowDown,
    faSyncAlt,
    faCircleNotch,
    faExclamationCircle,
    faCommentLines,
    faEllipsisV,
    faWindowClose,
    faTimes,
    faChevronRight,
    faPencilAlt,
    faBullseyePointer,
    faEdit,
    faScroll,
    faStickyNote,
    faHome
} from '@fortawesome/pro-regular-svg-icons';

type IconTypeMapping = { [key in IconTypes]?: IconProp };

export const iconMappings: IconTypeMapping = {
    [IconTypes.EDIT]: faPencilAlt as IconProp,
    [IconTypes.ANALYTICS]: faChartBar as IconProp,
    [IconTypes.ANCHOR]: faAnchor as IconProp,
    [IconTypes.ATOM]: faAtom as IconProp,
    [IconTypes.BULLSEYE_POINTER]: faBullseyePointer as IconProp,
    [IconTypes.CHECKMARK]: faCheck as IconProp,
    [IconTypes.CHEVRON_DOWN]: faChevronDown as IconProp,
    [IconTypes.CHEVRON_LEFT]: faChevronLeft as IconProp,
    [IconTypes.CHEVRON_RIGHT]: faChevronRight as IconProp,
    [IconTypes.COGS]: faCogs as IconProp,
    [IconTypes.COG]: faCog as IconProp,
    [IconTypes.COMMENT]: faCommentLines as IconProp,
    [IconTypes.COPY]: faCopy as IconProp,
    [IconTypes.CUBES]: faCubes as IconProp,
    [IconTypes.CUBE]: faCube as IconProp,
    [IconTypes.DELETE]: faTrash as IconProp,
    [IconTypes.DOWN]: faArrowDown as IconProp,
    [IconTypes.EDIT]: faEdit as IconProp,
    [IconTypes.ELLIPSES]: faEllipsisV as IconProp,
    [IconTypes.EXCLAMATION]: faExclamationCircle as IconProp,
    [IconTypes.EXTERNAL_LINK]: faExternalLink as IconProp,
    [IconTypes.FOLDER]: faFolderOpen as IconProp,
    [IconTypes.GRID]: faTh as IconProp,
    [IconTypes.HEADPHONES]: faHeadphones as IconProp,
    [IconTypes.HISTORY]: faHistory as IconProp,
    [IconTypes.LIST]: faThList as IconProp,
    [IconTypes.LOAD_SPINNER]: faCircleNotch as IconProp,
    [IconTypes.MAGIC]: faMagic as IconProp,
    [IconTypes.MEDIA]: faPhotoVideo as IconProp,
    [IconTypes.MOVE]: faPersonDolly as IconProp,
    [IconTypes.PHOTO_VIDEO]: faPhotoVideo as IconProp,
    [IconTypes.PLAY]: faPlay as IconProp,
    [IconTypes.PLUS]: faPlus as IconProp,
    [IconTypes.SAVE]: faSave as IconProp,
    [IconTypes.SCRIPT]: faScroll as IconProp,
    [IconTypes.FILM]: faFilm as IconProp,
    [IconTypes.SLIDERS_VERTICAL]: faSlidersV as IconProp,
    [IconTypes.SORT]: faSortAlt as IconProp,
    [IconTypes.SPEECH]: faComment as IconProp,
    [IconTypes.STICKY_NOTE]: faStickyNote as IconProp,
    [IconTypes.SYNC]: faSyncAlt as IconProp,
    [IconTypes.UP]: faArrowUp as IconProp,
    [IconTypes.UPLOAD]: faUpload as IconProp,
    [IconTypes.USERS]: faUsers as IconProp,
    [IconTypes.USER]: faUser as IconProp,
    [IconTypes.WALK]: faWalking as IconProp,
    [IconTypes.CLOSE]: faWindowClose as IconProp,
    [IconTypes.TIMES]: faTimes as IconProp,
    [IconTypes.TIMES]: faTimes as IconProp,
    [IconTypes.MINUS]: faMinus as IconProp,
    [IconTypes.FILE]: faFile as IconProp,
    [IconTypes.ELLIPSES_H]: faEllipsisH as IconProp,
    [IconTypes.UNDO]: faUndo as IconProp,
    [IconTypes.REDO]: faRedo as IconProp,
    [IconTypes.FOLDER]: faFolder as IconProp,
    [IconTypes.FOLDER_OPEN]: faFolderOpen as IconProp,
    [IconTypes.DELETE_H]: faTrashHollow as IconProp,
    [IconTypes.COPY_H]: faCopyHollow as IconProp,
    [IconTypes.HOME]: faHome as IconProp,
    [IconTypes.EYE]: faEye as IconProp,
    [IconTypes.EYESLASH]: faEyeSlash as IconProp
};
