import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { logout } from '../../networking/AccountService';
import { UseStaleWhileFetchingStrategy, ClearCachedData } from '../../networking/StaleWhileFetching';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useLogout = (): IHookProvidedCall<any> => {
    const dispatch = useDispatch();

    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const execute = async () => {
        await triggerCall(logout(getCancelableDispatch(dispatch)));
        if (UseStaleWhileFetchingStrategy) {
            ClearCachedData();
        }
    };

    return {
        execute: execute,
        ...rest
    };
};
