import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const agentSettingWrapperStyle = (token: IToken) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
`;

export const settingTextStyle = (token: IToken) => css`
    margin-right: ${token.spacings.small};
`;
