import React, { useState } from 'react';
import { ResourcePanel } from '../../components/resourcePanel';
import { useScriptEditorInfo, useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo';
import { ScriptActionType } from '../../shared/motive/reducers/ScriptEditorReducers';
import { createScriptObject } from '../../util/MotiveUtils';
import { useObjectReferenceContext } from '../../contexts/objectReferenceContext';
import { debounce } from 'lodash-es';
import { useDispatch } from 'react-redux';

export const ResourcePanelContainer: React.FC = () => {
    const {
        data: { resourceTypes }
    } = useScriptEditorInfo();
    const [searchValue, setSearchValue] = useState('');
    const scriptDispatch = useDispatch();
    const objectDefinitions = useObjectDefinitions();
    const { scrollToResource } = useObjectReferenceContext();

    const handleDoubleClick = (type: string) => {
        const resource = createScriptObject(type, objectDefinitions);

        scriptDispatch({
            type: ScriptActionType.RESOURCE_ADD,
            resource: resource
        });
        scrollToResource(resource.id);
    };

    const handleSearchResources = debounce((filterValue: string) => {
        setSearchValue(filterValue);
    }, 100);

    return (
        <>
            <ResourcePanel
                resourceTypes={resourceTypes}
                filter={searchValue}
                onDoubleClick={handleDoubleClick}
                onSearchChange={handleSearchResources}
            />
        </>
    );
};
