import { useSelector } from 'react-redux';
import { SelectAllCatalogs } from '../../../../redux/spaceKeyed/catalog/CatalogSelectors';
import { getSystemName } from '../../../../util/MotiveUtils';

export const useCheckSystemNameConflict = () => {
    const catalogData = useSelector(SelectAllCatalogs);

    const checkSystemNameConflict = (newName: string) => {
        const sysName = getSystemName(newName);

        return catalogData.find(c => c.name === sysName);
    };

    return checkSystemNameConflict;
};
