import React from 'react';
import { BaseModal, IBaseModalProps } from '../../core-ui/baseModal/BaseModal';
import { conflictModalStyle, conflictModalButtonStyle } from './MediaSourceConflictModal.style';
import { ButtonVariant, Button } from '../../core-ui/button';
import { useStyle } from '../../shared/hooks/useStyle';

export interface IMediaSourceConflictModalProps extends IBaseModalProps {
    isOpen: boolean;
    onOverwrite: () => void;
    onRename?: (newName: string) => void;
}

export const MediaSourceConflictModal: React.FC<IMediaSourceConflictModalProps> = props => {
    const token = useStyle();

    return (
        <BaseModal isOpen={props.isOpen} onCloseModal={props.onCloseModal}>
            <div css={conflictModalStyle(token)}>
                <div>
                    <span>There is already a file with the same name. Do you wish to overwrite the existing file?</span>
                </div>
                <div css={conflictModalButtonStyle(token)}>
                    <Button variant={ButtonVariant.HOLLOW} onClick={props.onCloseModal}>
                        Cancel
                    </Button>
                    <Button
                        variant={ButtonVariant.SOLID}
                        onClick={() => {
                            props.onOverwrite();
                        }}
                    >
                        Overwrite
                    </Button>
                </div>
            </div>
        </BaseModal>
    );
};
