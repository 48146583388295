import { css } from '@emotion/core';
import { IToken } from '../../../shared/contexts/styleContext';

export const enumInlineLayout = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const createEventConditionButtonStyle = (token: IToken) => css`
    margin-left: ${token.spacings.smaller};
    min-height: 100%;
    background-color: ${token.conditionColors.background.neutral};
    color: white;
    font-size: 0.7rem;

    &:hover {
        color: white;
    }
`;
