import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const importerText = (tokens: IToken) => css`
    margin-left: ${tokens.spacings.smaller};
`;

export const importerItem = (tokens: IToken) => css`
    margin: ${tokens.spacings.small} 0;
`;
