import React, { useEffect, useState } from 'react';
import {
    configInformationStyle,
    createServiceConfigButtonWrapperStyle,
    fieldRowStyle,
    loadingSkeletonWrapper,
    selectedRowStyle,
    serviceConfigurationRowItemStyle,
    serviceSelectionWrapperStyle,
    listWrapperStyle,
    infoWrapperStyle,
    emptyCollectionWrapper,
    serviceConfigRowSpacingStyle,
    newServiceConfigRowStyle,
    plusIconStyle,
    credentialsSectionStyle,
    enableCreateWatsonServiceConfig,
    disabledButtonStyle,
    resetButtonStyle
} from './WatsonConfiguration.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { IWatsonServiceConfiguration } from '../../shared/motive/models/WatsonServiceConfiguration';
import { uniqueMotiveId } from '../../util/MotiveUtils';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { Heading } from '../../core-ui/heading';
import { Text } from '../../core-ui/text';
import { LoadingSkeleton } from '../../core-ui/loadingSkeleton/LoadingSkeleton';
import { InputField } from '../../core-ui/inputField';
import { Button, ButtonVariant } from '../../core-ui/button';
import { HeightUsedScrollContainer } from '../../containers/heightUsedScrollContainer';
import { RowItem } from '../../core-ui/rowItem';
import { EmptyCollection } from '../../core-ui/emptyCollection';
import { Size } from '../../core-ui/constants/Size';
import { Checkbox } from '../../core-ui/checkbox';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Icon } from '../../core-ui/icon';

interface IWatsonServiceConfigurationEditorProps {
    watsonConfigurations: IWatsonServiceConfiguration[];
    selectedWatsonConfigurationId?: string;
    onCreateWatsonConfiguration?: (config: IWatsonServiceConfiguration) => Promise<void>;
    onUpdateWatsonConfiguration?: (config: IWatsonServiceConfiguration) => Promise<void>;
    onSelectWatsonConfiguration?: (config?: string) => void;
}

const CREATE_WATSON_SERVICE_CONFIGURATION = 'newWatsonConfig';

const initialWatsonServiceConfiguration: IWatsonServiceConfiguration = {
    id: uniqueMotiveId(),
    type: MotiveTypes.WATSON_SERVICE_CONFIGURATION,
    assistantCredentials: {
        id: uniqueMotiveId(),
        type: MotiveTypes.WATSON_SERVICE_CREDENTIALS,
        apiKey: '',
        url: ''
    },
    speechToTextCredentials: {
        id: uniqueMotiveId(),
        type: MotiveTypes.WATSON_SERVICE_CREDENTIALS,
        apiKey: '',
        url: ''
    }
};

export const WatsonConfigurationEditor: React.FC<IWatsonServiceConfigurationEditorProps> = props => {
    const token = useStyle();

    const [updatedWatsonConfig, setUpdatedWatsonConfig] = useState<IWatsonServiceConfiguration>(
        props.watsonConfigurations[0] ?? initialWatsonServiceConfiguration
    );
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const canCreateNewConfig = props.watsonConfigurations.length === 0;

    // Effects
    useEffect(() => {
        if (props.watsonConfigurations[0]) {
            setUpdatedWatsonConfig(props.watsonConfigurations[0]);
            props.onSelectWatsonConfiguration && props.onSelectWatsonConfiguration(props.watsonConfigurations[0].id);
        }
    }, [props.watsonConfigurations]);

    // Handlers
    const handleCreateServiceConfiguration = async () => {
        if (props.onCreateWatsonConfiguration) {
            setIsCreating(true);
            props.onCreateWatsonConfiguration(updatedWatsonConfig).then(res => {
                setIsCreating(false);
                setIsDirty(false);
                props.onSelectWatsonConfiguration && props.onSelectWatsonConfiguration(undefined);
            });
        }
    };

    const handleUpdateServiceConfiguration = async () => {
        if (props.onUpdateWatsonConfiguration) {
            setIsUpdating(true);
            setIsDirty(false);
            props.onUpdateWatsonConfiguration(updatedWatsonConfig).then(() => {
                setIsUpdating(false);
            });
        }
    };

    const handleSelectWatsonConfiguration = (str?: string) => {
        props.onSelectWatsonConfiguration && props.onSelectWatsonConfiguration(str);
    };

    const handleResetServiceChanges = () => {
        setUpdatedWatsonConfig(props.watsonConfigurations[0]);
        setIsDirty(false);
    };

    const handleUpdateSttUrl = (newVal: string) => {
        setIsDirty(true);
        setUpdatedWatsonConfig({
            ...updatedWatsonConfig,
            speechToTextCredentials: { ...updatedWatsonConfig.speechToTextCredentials, url: newVal }
        });
    };

    const handleUpdateSttApiKey = (newVal: string) => {
        setIsDirty(true);
        setUpdatedWatsonConfig({
            ...updatedWatsonConfig,
            speechToTextCredentials: { ...updatedWatsonConfig.speechToTextCredentials, apiKey: newVal }
        });
    };

    const handleUpdateAstUrl = (newVal: string) => {
        setIsDirty(true);
        setUpdatedWatsonConfig({
            ...updatedWatsonConfig,
            assistantCredentials: { ...updatedWatsonConfig.assistantCredentials, url: newVal }
        });
    };

    const handleUpdateAstApiKey = (newVal: string) => {
        setIsDirty(true);
        setUpdatedWatsonConfig({
            ...updatedWatsonConfig,
            assistantCredentials: { ...updatedWatsonConfig.assistantCredentials, apiKey: newVal }
        });
    };

    return (
        <div css={serviceSelectionWrapperStyle(token)}>
            <div css={listWrapperStyle(token)}>
                <HeightUsedScrollContainer>
                    {props.watsonConfigurations.map(config => (
                        <RowItem
                            css={selectedRowStyle(config.id === props.selectedWatsonConfigurationId, token)}
                            onClick={() => handleSelectWatsonConfiguration(config.id)}
                            key={config.id}
                        >
                            {() => ({
                                content: (
                                    <div css={serviceConfigRowSpacingStyle(token)}>
                                        <Checkbox isChecked={config.id === props.selectedWatsonConfigurationId} />
                                        <Text css={serviceConfigRowSpacingStyle(token)} size={Size.LARGER}>
                                            {config.id}
                                        </Text>
                                    </div>
                                )
                            })}
                        </RowItem>
                    ))}
                    {isCreating && (
                        <RowItem>
                            {() => ({
                                content: (
                                    <div css={loadingSkeletonWrapper(token)}>
                                        <LoadingSkeleton
                                            css={serviceConfigurationRowItemStyle(token)}
                                            height={'18px'}
                                        />
                                    </div>
                                )
                            })}
                        </RowItem>
                    )}
                    <RowItem
                        css={[
                            selectedRowStyle(
                                props.selectedWatsonConfigurationId === CREATE_WATSON_SERVICE_CONFIGURATION,
                                token
                            ),
                            enableCreateWatsonServiceConfig(canCreateNewConfig)
                        ]}
                        onClick={
                            canCreateNewConfig
                                ? () =>
                                      props.onSelectWatsonConfiguration &&
                                      props.onSelectWatsonConfiguration(CREATE_WATSON_SERVICE_CONFIGURATION)
                                : undefined
                        }
                        key={CREATE_WATSON_SERVICE_CONFIGURATION}
                    >
                        {() => ({
                            content: (
                                <div css={[newServiceConfigRowStyle(token)]}>
                                    <Icon css={plusIconStyle(token)} icon={IconTypes.PLUS} />
                                    <Text size={Size.LARGE} css={[serviceConfigRowSpacingStyle(token)]}>
                                        {'New Watson Service Configuration'}
                                    </Text>
                                </div>
                            )
                        })}
                    </RowItem>
                </HeightUsedScrollContainer>
            </div>

            {props.selectedWatsonConfigurationId ? (
                <div css={infoWrapperStyle(token)}>
                    <div css={configInformationStyle(token)}>
                        <div>
                            <Heading>Speech to Text Credentials</Heading>
                            <div css={fieldRowStyle(token)}>
                                <Text>URL: </Text>
                                {!isUpdating ? (
                                    <InputField
                                        value={updatedWatsonConfig.speechToTextCredentials.url}
                                        onChange={handleUpdateSttUrl}
                                    />
                                ) : (
                                    <LoadingSkeleton height={'36px'} />
                                )}
                            </div>
                            <div css={fieldRowStyle(token)}>
                                <Text>API Key: </Text>
                                {!isUpdating ? (
                                    <InputField
                                        value={updatedWatsonConfig.speechToTextCredentials.apiKey}
                                        onChange={handleUpdateSttApiKey}
                                    />
                                ) : (
                                    <LoadingSkeleton height={'36px'} />
                                )}
                            </div>
                        </div>
                        <div css={credentialsSectionStyle(token)}>
                            <Heading>Assistant Credentials</Heading>
                            <div css={fieldRowStyle(token)}>
                                <Text>URL: </Text>
                                {!isUpdating ? (
                                    <InputField
                                        value={updatedWatsonConfig.assistantCredentials.url}
                                        onChange={handleUpdateAstUrl}
                                    />
                                ) : (
                                    <LoadingSkeleton height={'36px'} />
                                )}
                            </div>
                            <div css={fieldRowStyle(token)}>
                                <Text>API Key: </Text>
                                {!isUpdating ? (
                                    <InputField
                                        value={updatedWatsonConfig.assistantCredentials.apiKey}
                                        onChange={handleUpdateAstApiKey}
                                    />
                                ) : (
                                    <LoadingSkeleton height={'36px'} />
                                )}
                            </div>

                            {props.selectedWatsonConfigurationId === CREATE_WATSON_SERVICE_CONFIGURATION ? (
                                <div css={createServiceConfigButtonWrapperStyle(token)}>
                                    <Button
                                        disabled={isCreating}
                                        variant={ButtonVariant.SOLID}
                                        onClick={handleCreateServiceConfiguration}
                                    >
                                        Create Service Configuration
                                    </Button>
                                </div>
                            ) : (
                                <div css={createServiceConfigButtonWrapperStyle(token)}>
                                    <Button
                                        css={resetButtonStyle(token)}
                                        disabled={!isDirty}
                                        disabledStyle={disabledButtonStyle(token)}
                                        variant={ButtonVariant.SOLID}
                                        onClick={handleResetServiceChanges}
                                    >
                                        Reset Changes
                                    </Button>
                                    <Button
                                        disabled={!isDirty}
                                        css={serviceConfigRowSpacingStyle(token)}
                                        variant={ButtonVariant.SOLID}
                                        onClick={handleUpdateServiceConfiguration}
                                    >
                                        Update Service Configuration
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div css={[emptyCollectionWrapper, infoWrapperStyle(token)]}>
                    <EmptyCollection message="Select or Create a Service Configuration from the List" />
                </div>
            )}
        </div>
    );
};
