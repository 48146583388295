import React, { useEffect, useState } from 'react';
import { useLoader } from 'react-three-fiber';
import { SkinnedMesh, BufferAttribute, BufferGeometry } from 'three';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { IBlendShapeFacialExpression } from '../../shared/motive/models/BlendShapeFacialExpression';
import { getEnumItemName } from '../../util/MotiveUtils';
import { FacialBlendShape } from './FacialBlendshapes';
import { FacialBlendshapeMap } from './FacialBlendshapesMap';

/* TypeScript definitions for GLTF Humanoids */
interface IGLTFAvatarNode {
    Body: SkinnedMesh;
}

interface IGLTFAvatar extends GLTF {
    nodes: IGLTFAvatarNode;
}

type Vector = [number, number, number];

enum MorphAttributes {
    Normal = 'normal',
    Position = 'position',
    OriginalNormal = '_originalNormal',
    OriginalPosition = '_originalPosition'
}

function getArrayFromArrayLike<T>(arrayLike: ArrayLike<T>): T[] {
    const array = [];

    for (let i = 0; i < arrayLike.length; i++) {
        array.push(arrayLike[i]);
    }

    return array;
}

/**
 * Logic similar to how a morph target vertex shader works.
 * morphWeights array needs to be the same length as morphBuffer
 */
const applyMorph = (geometry: BufferGeometry, morphWeights: number[]): void => {
    //Prechecks
    Object.values(MorphAttributes).forEach(a => {
        if (geometry.morphAttributes[a] && geometry.morphAttributes[a].length !== morphWeights.length) {
            throw new Error(
                'Invalid use of morph shader, geometry.morphAttributes lengths must match morphWeights length'
            );
        }
    });

    // Assuming length of these attributes are the same, if not for other cases in the future, will need to iterate on each one
    // but it'll be more performant if this is always the case
    const attributeLength: number = geometry.attributes[MorphAttributes.OriginalNormal].array.length;

    //Each attribute holds the array of it's data
    const newBufferValues: Record<string, number[]> = {
        [MorphAttributes.Normal]: getArrayFromArrayLike(geometry.attributes[MorphAttributes.OriginalNormal].array),
        [MorphAttributes.Position]: getArrayFromArrayLike(geometry.attributes[MorphAttributes.OriginalPosition].array)
    };

    morphWeights.forEach((weight, morphIdx) => {
        if (weight !== 0) {
            const currentMorphNormalAttributes = geometry.morphAttributes[MorphAttributes.Normal]?.[morphIdx];
            const currentMorphPositionAttributes = geometry.morphAttributes[MorphAttributes.Position]?.[morphIdx];

            for (let i = 0; i < attributeLength; i++) {
                if (currentMorphNormalAttributes) {
                    newBufferValues.normal[i] += currentMorphNormalAttributes.array[i] * weight;
                }

                if (currentMorphPositionAttributes) {
                    newBufferValues.position[i] += currentMorphPositionAttributes.array[i] * weight;
                }
            }
        }
    });

    //Set any newBufferValues to the geometry
    Object.entries(newBufferValues).forEach(([attr, values]) => {
        geometry.setAttribute(
            attr,
            new BufferAttribute(
                new Float32Array(values),
                geometry.attributes[attr].itemSize,
                geometry.attributes[attr].normalized
            )
        );
    });
};

/**
 * @param url The path to the the GLTF/GLB file
 * @param position The vector [x,y,z] position applied on the mesh
 * @param scale The [x,y,z] scale applied on the mesh
 * @param value Object defining the weights of the blendshapes
 * @param blendShapeMap Custom override map of the blendshape. Leave blank to use the default map, some gltf models may have names on them
 */
interface IBaseFacialExpressionModelProps {
    url: string;
    position: Vector;
    scale: Vector;
    value?: IBlendShapeFacialExpression;
    blendshapeMap?: Record<FacialBlendShape, string>;
}

/*
 * Parent component for loading a GLTF model and controlling its blendshapes.
 * This component should be rendered as a child of Reacts <Suspense> component
 */
export const BaseFacialExpressionModel = ({
    url,
    value,
    position,
    scale,
    blendshapeMap = FacialBlendshapeMap
}: IBaseFacialExpressionModelProps) => {
    const gltf = useLoader<IGLTFAvatar>(GLTFLoader, url);

    //Stash the original BufferAttributes onto the GLTF model itself as they are shared across
    useEffect(() => {
        var geometry = gltf.nodes.Body.geometry as BufferGeometry;

        if (!geometry.attributes[MorphAttributes.OriginalNormal]) {
            const normal = geometry.attributes[MorphAttributes.Normal];

            geometry.setAttribute(
                MorphAttributes.OriginalNormal,
                new BufferAttribute(normal.array, normal.itemSize, normal.normalized)
            );
        }

        if (!geometry.attributes[MorphAttributes.OriginalPosition]) {
            const position = geometry.attributes[MorphAttributes.Position];

            geometry.setAttribute(
                MorphAttributes.OriginalPosition,
                new BufferAttribute(position.array, position.itemSize, position.normalized)
            );
        }
    }, [gltf]);

    useEffect(() => {
        var geometry = gltf.nodes.Body.geometry as BufferGeometry;

        if (gltf.nodes.Body) {
            const morphInfluences = gltf.nodes.Body.morphTargetInfluences?.map(() => 0);

            if (morphInfluences) {
                if (value?.blendShapeSettings) {
                    value.blendShapeSettings.forEach(setting => {
                        const blendShapeEnum =
                            setting.blendShape &&
                            blendshapeMap[getEnumItemName(setting.blendShape) as FacialBlendShape];
                        const openIdx = blendShapeEnum && gltf.nodes.Body.morphTargetDictionary?.[blendShapeEnum];

                        if (openIdx) {
                            morphInfluences[openIdx] = setting.value / 100;
                        }
                    });
                }

                applyMorph(geometry, morphInfluences);
            }
        }
    }, [value]);

    return (
        <mesh position={position} scale={scale}>
            <primitive object={gltf.scene} dispose={null} />
        </mesh>
    );
};
