import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext/interfaces/Token';

export const baseConditionItemStyle = (token: IToken) => `
    background-color: ${token.conditionColors.background.light};
    transition-duration: 250ms;
    border-radius: 6px;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 4x;
    &:hover {
        filter: brightness(90%);
    }
    height: 88px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    color: ${token.conditionColors.background.neutral};
`;

export const thumbContainerStyle = css`
    opacity: 0.5;
`;

export const conditionListItemStyle = (token: IToken) => css`
    ${baseConditionItemStyle(token)}
    display: flex;
    align-content: center;
    align-items: center;
`;

export const conditionGridItemStyle = (token: IToken) => css`
    ${baseConditionItemStyle(token)}
    height: 88px;
    width: 88px;
`;

export const conditionItemTextStyle = (token: IToken, display?: 'grid' | 'list') => css`
    text-align: ${display !== 'list' ? 'center' : 'left'};
    padding: ${display === 'list' ? `0px ${token.spacings.small}` : `0px`};
    white-space: normal;
    font-size: ${token.fontSizes.small};
`;

export const conditionItemIconStyle = (token: IToken, display?: 'grid' | 'list') => css`
    padding: ${display === 'list'
        ? `${token.spacings.small} ${token.spacings.medium}`
        : `${token.spacings.smaller} ${token.spacings.small}`};
    color: ${token.conditionColors.font.primary};
`;

export const conditionsCollectionStyle = (token: IToken) => css`
    grid-gap: ${token.spacings.smaller};
`;

export const conditionsGroupStyle = (token: IToken) => css`
    background: ${token.colors.background.lighter};
    padding: 5px;
    margin: 1px 0;
`;

export const conditionsGroupTitleStyle = (token: IToken) => css`
    text-transform: uppercase;
    color: ${token.colors.font.secondary};
    padding-bottom: 5px;
`;

export const conditionsListCollectionStyle = (token: IToken) => css`
    grid-gap: ${token.spacings.smaller};
    grid-template-columns: repeat(auto-fit);
`;

export const conditionGroupContainerStyle = (token: IToken) => css`
    background: dimgray;
    color: white;
    padding: ${token.spacings.smaller};
    border-bottom: solid;
    border-width: 1px;
    cursor: pointer;
`;

export const conditionIconStyle = (token: IToken) => css`
    padding: ${token.spacings.smaller};
    transform: scale(0.8);
`;
