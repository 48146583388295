//istanbul ignore file MOTIVE_EXCEPTION
import React from 'react';
import { renderSubRoute } from '../editorRoutes';
import { IProjectsRouteParams } from '../projectsRoute';
import { useEditorRouteMatch, useRoute } from '../useRoute';
import { IntentCatalogsContainer } from '../../containers/intentsCatalogContainer';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useGetCatalogTypes } from '../../shared/motive/hooks/useCatalogs/useCatalogTypes/useGetCatalogTypes';
import { useScenariosRoute } from '../scenariosRoute';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConvoAIIntentsRouteParams extends IProjectsRouteParams {
    catalogId?: string;
    intentId?: string;
}

export const useConvoAIIntentsRoute = () => useRoute<IConvoAIIntentsRouteParams>('ConvoAIIntentManagement');
export const useConvoAIIntentsRouteMatch = () =>
    useEditorRouteMatch<IConvoAIIntentsRouteParams>('ConvoAIIntentManagement');

export const ConvoAIIntentsRoute: React.FC = () => {
    const { subRoutes } = useConvoAIIntentsRoute();

    const {
        goTo,
        params: { catalogId, intentId }
    } = useConvoAIIntentsRoute();

    const { goTo: redirectToScenarios } = useScenariosRoute();

    const { data: typeData } = useGetCatalogTypes();
    const isAIFeatureSetAvailable = typeData.some(type => type.bundleName === MotiveTypes.AI);

    const handleSelectCatalog = (selectedCatalog: string) => {
        goTo({ pathParams: { catalogId: selectedCatalog } });
    };

    const handleSelectIntent = (selectedCatalog: string, selectedIntent: string) => {
        goTo({ pathParams: { catalogId: selectedCatalog, intentId: selectedIntent } });
    };

    if (isAIFeatureSetAvailable) {
        return (
            <>
                <IntentCatalogsContainer onSelectCatalog={handleSelectCatalog} onSelectIntent={handleSelectIntent} />
                {subRoutes.map(renderSubRoute)}
            </>
        );
    }

    return <>{redirectToScenarios()}</>;
};
