import React, { useCallback, useEffect } from 'react';
import { IScriptEditorStateProps } from '../../shared/motive/reducers/ScriptEditorReducers';
import { StateHistoryAction } from '../../shared/motive/reducers/StateHistoryTree';
import { FRAME_HEADER_HEIGHT } from '../frameInspectorHeader/FrameInspectorHeader';
import { scriptEditorHistoryWrapper, historyIcon, historyWrapper } from './ScriptEditorHistoryButtons.style';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Icon } from '../../core-ui/icon';
import { useDispatch, useSelector } from 'react-redux';
import { SelectScriptEditorStateHistory } from '../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';

export const BAR_HEIGHT = FRAME_HEADER_HEIGHT;
export const ScriptEditorHistoryButtons: React.FC = () => {
    const { hasForward, hasBack, redo, undo } = useSelector(SelectScriptEditorStateHistory);
    const dispatch = useDispatch();

    const handleUpdate = useCallback(
        (state: IScriptEditorStateProps) => {
            dispatch({
                type: StateHistoryAction.UPDATE,
                state: state
            });
        },
        [dispatch]
    );

    const handleGoForward = useCallback(() => {
        if (hasForward()) {
            redo(handleUpdate);
        }
    }, [redo, handleUpdate, hasForward]);

    const handleGoBack = useCallback(() => {
        if (hasBack()) {
            undo(handleUpdate);
        }
    }, [undo, handleUpdate, hasBack]);

    useEffect(() => {
        const keydownHandler = (e: KeyboardEvent) => {
            if (e.key === 'z' && e.ctrlKey) {
                handleGoBack();
            }
            if ((e.key === 'y' && e.ctrlKey) || (e.key === 'z' && e.ctrlKey && e.shiftKey)) {
                handleGoForward();
            }
        };

        window.addEventListener('keydown', keydownHandler);

        return () => {
            window.removeEventListener('keydown', keydownHandler);
        };
    }, [handleGoBack, handleGoForward]);

    return (
        <div css={scriptEditorHistoryWrapper(BAR_HEIGHT)}>
            <Icon wrapperStyle={historyWrapper(hasForward())} icon={IconTypes.REDO} onClick={handleGoForward}>
                Redo
            </Icon>
            <Icon
                wrapperStyle={historyWrapper(hasBack())}
                iconStyle={historyIcon}
                icon={IconTypes.UNDO}
                onClick={handleGoBack}
            ></Icon>
        </div>
    );
};
