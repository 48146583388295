import React, { useEffect, useState } from 'react';
import { IObjectEditorProps } from '../../containers/objectEditor';
import { IMediaItem, IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { InputField } from '../../core-ui/inputField';
import { Size } from '../../core-ui/constants/Size';
import { Text } from '../../core-ui/text';
import { Button } from '../../core-ui/button';
import { useDashboardDrawer } from '../../hooks/useDashboardDrawer';
import { useMediaItems } from '../../shared/motive/hooks/useMediaItems';
import { useChooseMediaSourceState } from '../../hooks/useChooseMediaSource/UseChooseMediaSource';
import { MediaItemPreview } from '../mediaItemPreview/MediaItemPreview';
import { mediaItemEditorStyle } from './MediaItemEditor.style';
import { EditModeAware } from '../editableOnFocus/EditModeAware';
import { IMediaSourceEntity } from '../../redux/spaceKeyed/media/MediaReducer';

type IMediaEditorProps = IObjectEditorProps<IMediaItem>;

const getMediaItem = (data: IMediaSourceEntity[], id: string): IHostedMediaSource | undefined => {
    const foundItem = data.find(item => item.mediaSource.id === id);

    return foundItem ? foundItem.mediaSource : undefined;
};

export const MediaItemEditor: React.FC<IMediaEditorProps> = ({ onChange, value, ...props }): React.ReactElement => {
    const [title] = [value?.title, value?.source?.url];
    const { state: chooseState, chosenValue } = useChooseMediaSourceState();
    const { handleSelectMediaSource, handleCancelSelectMediaSource } = useDashboardDrawer();
    const [isSelecting, setIsSelecting] = useState<boolean>(false);
    const { data: mediaItems } = useMediaItems();

    useEffect(() => {
        if (isSelecting && chooseState === 'hasChosenValue' && chosenValue) {
            const retrievedMediaItem = getMediaItem(mediaItems, chosenValue.chosenItem as string);

            const path = props.path ? `${props.path}.source` : `source`;

            onChange(path, retrievedMediaItem);

            handleCancelSelectMediaSource();
            setIsSelecting(false);
        }
    }, [chooseState, mediaItems]);

    const handleTitleChange = (newValue: string) => {
        const path = props.path ? `${props.path}.title` : `title`;

        onChange(path, newValue);
    };

    const handleChooseMedia = () => {
        setIsSelecting(true);
        handleSelectMediaSource && handleSelectMediaSource();
    };

    const handleRemoveItem = () => {
        const path = props.path ? `${props.path}.source` : `source`;
        onChange(path, undefined);
    };

    const titleElement = (
        <>
            <Text>{`Title`}</Text>
            <InputField size={Size.MEDIUM} value={title ?? ''} onChange={handleTitleChange} />
        </>
    );
    const fileElement = value?.source?.url ? (
        <>
            <Text>{`File`}</Text>
            <MediaItemPreview
                css={mediaItemEditorStyle}
                mediaItem={value.source}
                withLabel={true}
                onLabelClick={handleRemoveItem}
            />
        </>
    ) : (
        <>
            <Button onClick={handleChooseMedia}>Choose Media</Button>
        </>
    );

    const renderEditor = () => {
        return (
            <>
                {titleElement}
                {fileElement}
            </>
        );
    };

    const renderReadonly = () => {
        return <Text>{String(title)}</Text>;
    };

    return <EditModeAware readonlyModeRender={renderReadonly} editModeRender={renderEditor} />;
};
