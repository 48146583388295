import React from 'react';
import { useCollaborators } from '../../shared/motive/hooks/useCollaborators/useCollaborators';
import { ICollaborator, IPendingCollaborator } from '../../shared/motive/models/Collaborator';
import {
    useAddCollaborators,
    IAddCollaboratorParams
} from '../../shared/motive/hooks/useAddCollaborators/useAddCollaborators';
import {
    useDeleteCollaborator,
    IDeleteCollaboratorParams
} from '../../shared/motive/hooks/useDeleteCollaborator/useDeleteCollaborator';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useLoadDataOnMount } from '../../shared/hooks/useLoadDataOnMount';

export interface ICollaboratorProps {
    isLoading?: boolean;
    collaborators?: ICollaborator[];
    pendingCollaborators?: IPendingCollaborator[];
    addCollaboratorAction?: IHookProvidedCall<IAddCollaboratorParams>;
}

export interface ICollaboratorItemProps {
    deleteCollaboratorAction: IHookProvidedCall<IDeleteCollaboratorParams>;
}

interface ICollaboratorContainerProps {
    children: (props: ICollaboratorProps) => React.ReactNode;
    isLoading?: boolean;
}

interface ICollaboratorItemContainerProps {
    children: (props: ICollaboratorItemProps) => React.ReactNode;
}

export const CollaboratorContainer: React.FC<ICollaboratorContainerProps> = ({ children, isLoading }) => {
    const collaboratorDataSource = useCollaborators();
    const addCollaboratorAction = useAddCollaborators();

    useLoadDataOnMount(collaboratorDataSource);

    const { isLoading: isCollaboratorLoading, data: collaboratorData } = collaboratorDataSource;

    return (
        <>
            {children({
                isLoading: isLoading || isCollaboratorLoading,
                collaborators: collaboratorData.collaborators,
                pendingCollaborators: collaboratorData.pendingCollaborators,
                addCollaboratorAction
            })}
        </>
    );
};

export const CollaboratorItemContainer: React.FC<ICollaboratorItemContainerProps> = ({ children }) => {
    const deleteCollaboratorAction = useDeleteCollaborator();

    return (
        <>
            {children({
                deleteCollaboratorAction
            })}
        </>
    );
};
