import { getScriptAndGlobalVariablesMap } from '../../../../util/ScriptDynamicsUtil';
import { useScriptVariableMap } from '../useScriptVariables';
import { MotiveTypes } from '../../../../constants/MotiveTypes';
import { ICatalog } from '../../models/Catalog';
import { IScript } from '../../models/Script';
import { useCurrentProjectCatalogsWithTypes } from '../useCatalogs/useProjectCatalogsWithTypes/useProjectCatalogsWithTypes';

export const useScriptAndGlobalVariables = () => {
    const scriptVars = useScriptVariableMap();
    const catalogs = useCurrentProjectCatalogsWithTypes([MotiveTypes.MOTIVE_SCRIPT]);

    return getScriptAndGlobalVariablesMap(scriptVars, catalogs as ICatalog<IScript>[]);
};
