import React, { useState } from 'react';
import { ConversationalAgent } from '../../shared/motive/models/ConversationalAgent';
import {
    agentDetailsWrapperStyle,
    buttonStyle,
    buttonWrapperStyle,
    headingWrapperStyle,
    resetButtonStyle
} from './Agent.style';
import { Text } from '../../core-ui/text';
import { Size } from '../../core-ui/constants/Size';
import { Button, ButtonVariant } from '../../core-ui/button';
import { Divider } from '../../core-ui/divider';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { InputField } from '../../core-ui/inputField';
import { IWatsonAssistant } from '../../shared/motive/models/WatsonAssistant';
import { Heading } from '../../core-ui/heading';
import { useStyle } from '../../shared/hooks/useStyle';
import { LoadingSkeleton } from '../../core-ui/loadingSkeleton/LoadingSkeleton';
import { Field, Form, Formik, FormikProps } from 'formik';
import { errorHelperTextStyle, FormControl } from '../../core-ui/formControl';

interface IAgentProps {
    agent: ConversationalAgent;
    onUpdateAgent: (agent: ConversationalAgent) => Promise<void>;
    onDeleteAgent: (agent: ConversationalAgent) => void;
}

export const Agent: React.FC<IAgentProps> = ({ agent, onUpdateAgent, onDeleteAgent }) => {
    let content = <></>;

    const token = useStyle();

    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const handleSaveWatsonAssistant = async (formValues: IWatsonAssistant) => {
        setIsUpdating(true);
        await onUpdateAgent({ ...formValues }).then(() => {
            setIsUpdating(false);
        });
    };

    switch (agent.type) {
        case MotiveTypes.WATSON_ASSISTANT:
            const watsonAssistant = agent as IWatsonAssistant;
            const validate = (values: IWatsonAssistant) => {
                const errors: any = {};

                if (!values.name) {
                    errors.name = 'Required';
                }

                if (!values.assistantId) {
                    errors.assistantId = 'Required';
                }

                return errors;
            };

            const isDirty = (newName: string, newAssistantId: string) => {
                return newName !== watsonAssistant.name || newAssistantId !== watsonAssistant.assistantId;
            };

            const watsonAssistantFormRender: React.FC<FormikProps<IWatsonAssistant>> = props => {
                const watsonAssistantNameFieldId = 'name';
                const watsonAssistantIdFieldId = 'assistantId';

                return (
                    <Form>
                        <Heading size={Size.MEDIUM}>Agent Details</Heading>
                        <Divider />
                        <FormControl
                            label="Agent Name"
                            fieldId={watsonAssistantNameFieldId}
                            required={true}
                            helperText={props.errors.name}
                            helperTextStyle={errorHelperTextStyle()}
                        >
                            {!isUpdating ? (
                                <Field
                                    required={true}
                                    id={watsonAssistantNameFieldId}
                                    name={watsonAssistantNameFieldId}
                                    type="text"
                                    as={InputField}
                                    useNativeOnChange={true}
                                    placeholder="Name"
                                    isInvalid={props.errors.name}
                                />
                            ) : (
                                <LoadingSkeleton height={'34px'} />
                            )}
                        </FormControl>
                        <FormControl
                            label="Assistant ID"
                            fieldId={watsonAssistantIdFieldId}
                            required={true}
                            helperText={props.errors.assistantId}
                            helperTextStyle={errorHelperTextStyle()}
                        >
                            {!isUpdating ? (
                                <Field
                                    required={true}
                                    id={watsonAssistantIdFieldId}
                                    name={watsonAssistantIdFieldId}
                                    type="text"
                                    as={InputField}
                                    useNativeOnChange={true}
                                    placeholder="Name"
                                    isInvalid={props.errors.assistantId}
                                />
                            ) : (
                                <LoadingSkeleton height={'34px'} />
                            )}
                        </FormControl>
                        <div css={buttonWrapperStyle}>
                            <Button
                                css={resetButtonStyle(token)}
                                type="reset"
                                variant={ButtonVariant.SOLID}
                                onClick={() => props.handleReset()}
                                disabled={!isDirty(props.values.name, props.values.assistantId) || isUpdating}
                            >
                                Reset Changes
                            </Button>
                            <Button
                                css={buttonStyle(token)}
                                type="submit"
                                variant={ButtonVariant.SOLID}
                                onClick={() => props.handleSubmit()}
                                disabled={!isDirty(props.values.name, props.values.assistantId) || isUpdating}
                            >
                                Update Agent
                            </Button>
                        </div>
                    </Form>
                );
            };

            content = (
                <div css={agentDetailsWrapperStyle(token)}>
                    <Formik validate={validate} onSubmit={handleSaveWatsonAssistant} initialValues={watsonAssistant}>
                        {watsonAssistantFormRender}
                    </Formik>
                </div>
            );
    }
    return (
        <>
            <div css={headingWrapperStyle}>
                <Text size={Size.LARGEST}>{agent.name}</Text>
            </div>
            <Divider />
            {content}
        </>
    );
};
