// istanbul ignore file MOTIVE_EXCEPTION
import React, { useState } from 'react';
import { IScriptDirectoryItem } from '../../shared/motive/models/ScriptDirectoryItem';
import { Button, ButtonVariant } from '../../core-ui/button';
import { Size } from '../../core-ui/constants/Size';
import { ObjectEditor, IFieldEditorProps } from '../../containers/objectEditor';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { setWith, clone } from 'lodash-es';
import { useStyle } from '../../shared/hooks/useStyle';
import {
    scenarioButtonWrapper,
    scenarioButtonStyle,
    localizedTitleStyle,
    localizedDescriptionStyle,
    loadingIconStyle
} from './ScenarioEditor.style';
import { FieldTypes, IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { ILocalizedText } from '../../shared/motive/models/LocalizedText';
import { LocalizedTextEditor } from '../localizedTextEditor';
import { BooleanEditor } from '../booleanEditor';
import { ISaveCatalogItemParams } from '../../shared/motive/hooks/useCatalogs/useSaveCatalogItem/useSaveCatalogItem';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { rotating } from '../../constants/AnimationStyles';
import { Icon } from '../../core-ui/icon';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { useTranslation } from 'react-i18next';

const LocalizedTitleEditor: React.FC<IFieldEditorProps<ILocalizedText>> = props => {
    const token = useStyle();

    return <LocalizedTextEditor {...props} css={localizedTitleStyle(token)}></LocalizedTextEditor>;
};

const LocalizedDescriptionEditor: React.FC<IFieldEditorProps<ILocalizedText>> = props => {
    const token = useStyle();

    return (
        <LocalizedTextEditor {...props} size={Size.LARGE} css={localizedDescriptionStyle(token)}></LocalizedTextEditor>
    );
};

const AutoplayEditor: React.FC<IFieldEditorProps<boolean>> = props => {
    return <BooleanEditor {...props}></BooleanEditor>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ScenarioEditorFieldMap = new Map<string, React.FC<IFieldEditorProps<any>>>();
ScenarioEditorFieldMap.set('localizedTitle', LocalizedTitleEditor);
ScenarioEditorFieldMap.set('localizedDescription', LocalizedDescriptionEditor);
ScenarioEditorFieldMap.set('autoplay', AutoplayEditor);

interface IScenarioInfoProps {
    scenario: IScriptDirectoryItem;
    catalog: ICatalog;
    onSave: IHookProvidedCall<ISaveCatalogItemParams, IScriptObjectModel | undefined>;
    onCancel?: () => void;
    onScenarioCreation?: () => void;
}

export const saveTestId = 'save_test';
export const cancelTestId = 'cancel_test';

export const ScenarioEditor: React.FC<IScenarioInfoProps> = ({
    scenario,
    catalog,
    onSave,
    onCancel,
    onScenarioCreation
}): React.ReactElement => {
    const token = useStyle();
    const [selectedScenario, setSelectedScenario] = useState<IScriptDirectoryItem>(scenario);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const { t } = useTranslation();

    const handleChange = (path: string, typeValue: FieldTypes) => {
        setSelectedScenario(setWith(clone(selectedScenario), path, typeValue, clone));
        setIsDirty(true);
    };

    const handleSave = async () => {
        await onSave.execute({ item: selectedScenario, catalogId: catalog.id as string });
        setSelectedScenario(scenario);
        setIsDirty(false);
        onScenarioCreation && onScenarioCreation();
    };

    const handleCancel = () => {
        setSelectedScenario(scenario);
        setIsDirty(false);
        onCancel && onCancel();
    };

    return (
        <>
            <ObjectEditor
                type={MotiveTypes.SCRIPT_DIRECTORY_ITEM}
                value={selectedScenario}
                onChange={(path, typeValue) => {
                    handleChange(path, typeValue);
                }}
                showFields={[
                    'scriptReference',
                    'localizedTitle',
                    'localizedDescription',
                    'publishingStatus',
                    'order',
                    'autoplay'
                ]}
                customFieldMap={ScenarioEditorFieldMap}
            />

            <div css={scenarioButtonWrapper}>
                {onCancel && (
                    <Button
                        onClick={handleCancel}
                        data-testid={cancelTestId}
                        variant={ButtonVariant.GHOST}
                        size={Size.MEDIUM}
                    >
                        {t('cancel')}
                    </Button>
                )}
                {onSave && (
                    <Button
                        disabled={!isDirty || onSave?.isLoading}
                        variant={isDirty ? ButtonVariant.SOLID : ButtonVariant.HOLLOW}
                        onClick={handleSave}
                        data-testid={saveTestId}
                        size={Size.MEDIUM}
                        css={scenarioButtonStyle(token)}
                    >
                        {onSave?.isLoading ? (
                            <Icon icon={IconTypes.LOAD_SPINNER} wrapperStyle={loadingIconStyle} css={rotating} />
                        ) : (
                            t('save')
                        )}
                    </Button>
                )}
            </div>
        </>
    );
};
