import React from 'react';
import { CatalogsContainer } from '../catalogsContainer';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useCurrentProjectId } from '../../shared/motive/hooks/useProjectConfigs/useProjectConfigs';
import { useScriptsRoute } from '../../routes/scriptsRoute/ScriptsRoute';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { HeightUsedScrollContainer } from '../heightUsedScrollContainer';

export const DrawerScriptsTabContainer: React.FC = () => {
    const projectConfigId = useCurrentProjectId();

    const { goTo: goToScriptsRoute } = useScriptsRoute();

    const handleScriptClicked = (catalogId: string, item: IScriptObjectModel) => {
        goToScriptsRoute({
            pathParams: {
                scriptId: item.id
            }
        });
    };

    return (
        <HeightUsedScrollContainer>
            <CatalogsContainer
                whiteListTypes={[MotiveTypes.MOTIVE_SCRIPT]}
                projectConfigId={projectConfigId as string}
                allowQuickObjectEdit={false}
                allowQuickAddItem={true}
                collapsible={true}
                itemsAreDraggable={true}
                showItemSearch={true}
                quickEditTitle={'Edit Script Name'}
                quickAddTitle={'Add New Script'}
                onSelectItem={handleScriptClicked}
            />
        </HeightUsedScrollContainer>
    );
};
