import { useState } from 'react';

export const useToggle = (initialState: boolean = false): [boolean, () => void] => {
    const [isToggled, setToggle] = useState<boolean>(initialState);

    const toggleState = (): void => {
        setToggle(prevState => !prevState);
    };

    return [isToggled, toggleState];
};
