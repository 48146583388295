import React from 'react';
import { useStyle } from '../../shared/hooks/useStyle';
import {
    panelLayout,
    headingWrapperStyle,
    headingTextStyle,
    dividerStyle,
    panelBodyStyle,
    panelPadding
} from './DashboardPanel.style';
import { Card } from '../../core-ui/card';
import { Divider } from '../../core-ui/divider';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Size } from '../../core-ui/constants/Size';
import { ButtonVariant, Button } from '../../core-ui/button';
import { Heading } from '../../core-ui/heading';
import { HeightUsedScrollContainer } from '../../containers/heightUsedScrollContainer';
import { useTranslation } from 'react-i18next';

interface IDashboardPanelProps {
    headingTitle: string;
    onClickCreateButton?: () => void;
    headingActionIcon?: IconTypes;
    buttonText?: string;
}

export const DashboardPanel: React.FC<IDashboardPanelProps> = ({
    headingActionIcon,
    headingTitle,
    onClickCreateButton,
    buttonText,
    children
}) => {
    const token = useStyle();
    const { t } = useTranslation();
    return (
        <div css={panelPadding(token)}>
            <Card css={panelLayout(token)}>
                <div>
                    <div css={headingWrapperStyle(token)}>
                        <Heading css={headingTextStyle} size={Size.LARGE}>
                            {headingTitle}
                        </Heading>
                        {onClickCreateButton && (
                            <Button
                                size={Size.MEDIUM}
                                icon={headingActionIcon}
                                variant={ButtonVariant.SOLID}
                                onClick={onClickCreateButton}
                            >
                                {buttonText ?? t('create')}
                            </Button>
                        )}
                    </div>
                    <Divider css={dividerStyle(token)} />
                </div>
                <HeightUsedScrollContainer>
                    <div css={panelBodyStyle(token)}>{children}</div>
                </HeightUsedScrollContainer>
            </Card>
        </div>
    );
};
