import { OrbitControls } from 'drei';
import React, { Suspense, useRef } from 'react';
import { Canvas, useFrame } from 'react-three-fiber';
import { sRGBEncoding, Color, Vector3 } from 'three';
import { InputField } from '../../core-ui/inputField';
import { IBlendShapeFacialExpression } from '../../shared/motive/models/BlendShapeFacialExpression';
import { FieldTypes } from '../../shared/motive/models/ScriptObjectModel';
import {
    expressionCanvasWrapper,
    expressionNameStyle,
    expressionNameWrapperStyle
} from './FacialExpressionPlayerCanvas.style';
import { GenericCharacterFacialExpression } from './GenericCharacterFacialExpression';

interface ICatalogExpressionPlayerProps {
    value: IBlendShapeFacialExpression | undefined;
    path: string;
    onChange: (fieldPath: string, updatedValue: FieldTypes) => void;
    width?: number;
}

interface IMeshProps {
    rotation: Vector3;
}

const LoadingBox = () => {
    const mesh1 = useRef<IMeshProps>();

    useFrame(() => {
        if (mesh1 != null && mesh1.current) {
            mesh1.current.rotation.z += 0.01;
            mesh1.current.rotation.x += 0.01;
        }
    });

    return (
        <mesh ref={mesh1} scale={[0.05, 0.05, 0.05]}>
            <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
            <meshStandardMaterial attach="material" color={'#ff6a00'} />
        </mesh>
    );
};

export const CatalogExpressionPlayer: React.FC<ICatalogExpressionPlayerProps> = ({
    value,
    path = '',
    onChange,
    width
}) => {
    const onNameChange = (newName: string) => {
        const fieldPath = path ? `${path}.name` : 'name';

        if (value && value.name !== newName) {
            onChange(`${fieldPath}`, newName);
        }
    };
    return (
        <div css={expressionCanvasWrapper(width)}>
            {value && (
                <div css={expressionNameWrapperStyle}>
                    <InputField
                        css={expressionNameStyle}
                        defaultValue={value?.name}
                        onChange={val => {
                            onNameChange(val);
                        }}
                    ></InputField>
                </div>
            )}
            <Canvas
                concurrent
                pixelRatio={window.devicePixelRatio}
                camera={{ position: [0, 0, 0.6], fov: 50 }}
                gl={{ antialias: true }}
                onCreated={({ gl, scene }) => {
                    gl.outputEncoding = sRGBEncoding;
                    gl.physicallyCorrectLights = true;
                    gl.setClearColor(0xcccccc);
                    scene.background = new Color('#242424');
                }}
            >
                <pointLight position={[10, 10, 10]} />
                <ambientLight intensity={2} color="white" />
                <hemisphereLight intensity={2} position={[0, 0, 3]} />
                <Suspense fallback={<LoadingBox />}>
                    <GenericCharacterFacialExpression value={value} />
                </Suspense>
                <OrbitControls />
            </Canvas>
        </div>
    );
};
