/* istanbul ignore file MOTIVE_EXCEPTION*/

import React from 'react';
import { IFieldEditorProps } from '../../containers/objectEditor';
import { DatePicker } from '../../core-ui/datePicker/DatePicker';
import { Text } from '../../core-ui/text';
import { useCurrentEditorLanguage } from '../../shared/motive/stores/editorLocale/EditorLocale';
import { EditModeAware } from '../editableOnFocus/EditModeAware';

export const dateTimeEditorTestId = 'dateTimeEditorTest';

export const DateTimeEditor: React.FC<IFieldEditorProps<string>> = ({ value, onChange }): React.ReactElement => {
    const language = useCurrentEditorLanguage();

    const renderEditor = () => (
        <DatePicker value={value ?? ''} onDateChange={(date: string) => onChange(date)} locale={language} />
    );

    const renderReadonly = () => <Text>{value}</Text>;

    return <EditModeAware editModeRender={renderEditor} readonlyModeRender={renderReadonly} />;
};
