import React from 'react';
import {
    createButtonStyle,
    dashboardConvoAIBodyContentStyle,
    dashboardConvoAIBodyStyle,
    dashboardConvoAIWrapperStyle
} from './DashboardConvoAI.style';
import {
    dividerStyle,
    headingTextStyle,
    headingWrapperStyle,
    panelBodyStyle,
    panelLayout,
    panelPadding
} from '../dashboardPanel/DashboardPanel.style';
import { Card } from '../../core-ui/card';
import { Heading } from '../../core-ui/heading';
import { LocalizedText } from '../localizedText';
import { Button, ButtonVariant } from '../../core-ui/button';
import { LocalizationKeys } from '../../localization/LocalizationKeys';
import { Size } from '../../core-ui/constants/Size';
import { Breadcrumbs, IBreadCrumb } from '../../core-ui/breadcrumbs/Breadcrumbs';
import { ScrollZone } from '../../core-ui/scrollZone/ScrollZone';
import { HeightUsedScrollContainer } from '../../containers/heightUsedScrollContainer';
import { Divider } from '../../core-ui/divider';
import { useStyle } from '../../shared/hooks/useStyle';
import { useConvoAIAgentManagementRouteMatch } from '../../routes/convoAIAgentRoute/ConvoAIAgentRoute';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { ConversationalIntent } from '../../shared/motive/models/ConversationalIntent';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { useConvoAIAgentCreationRouteMatch } from '../../routes/convoAIAgentCreationRoute/ConvoAIAgentCreationRoute';
import { ConversationalAgent } from '../../shared/motive/models/ConversationalAgent';

interface IDashboardConvoAIProps {
    goToIntentManagement: (catalogId?: string, intentId?: string) => void;
    goToAgentManagement: (agentId?: string) => void;
    intentCatalog?: ICatalog;
    intent?: ConversationalIntent;
    agent?: ConversationalAgent;
}

export const DashboardConvoAI: React.FC<IDashboardConvoAIProps> = props => {
    // UI Hooks
    const token = useStyle();

    // Routing
    const AIAgentManagementRouteMatch = useConvoAIAgentManagementRouteMatch();
    const AIAgentCreationRouteMatch = useConvoAIAgentCreationRouteMatch();

    // Breadcrumb logic
    const breadcrumbs: IBreadCrumb[] = [
        {
            name: 'Intent Catalogs',
            onClick: props.goToIntentManagement
        }
    ];

    if (AIAgentManagementRouteMatch) {
        breadcrumbs.push({ name: 'Agents', onClick: props.goToAgentManagement });

        if (props.agent) {
            breadcrumbs.push({ name: props.agent.name, onClick: () => props.goToAgentManagement(props.agent?.id) });
        }
    } else if (props.intentCatalog) {
        breadcrumbs.push({
            name: props.intentCatalog.title,
            onClick: () => props.goToIntentManagement(props.intentCatalog?.id, undefined)
        });

        if (props.intent) {
            breadcrumbs.push({
                name: props.intent.intentName,
                onClick: () => props.goToIntentManagement(props.intentCatalog?.id, props.intent?.id)
            });
        }
    }

    if (AIAgentCreationRouteMatch) {
        breadcrumbs.push({ name: 'Agents', onClick: props.goToAgentManagement });
        breadcrumbs.push({ name: 'Agent Management' });
    }

    return (
        <div css={panelPadding(token)}>
            <Card css={panelLayout(token)}>
                <div>
                    <div css={headingWrapperStyle(token)}>
                        <Heading css={headingTextStyle} size={Size.LARGE}>
                            <LocalizedText textKey={LocalizationKeys.Menu}>{`Conversational AI`}</LocalizedText>
                        </Heading>
                        <div>
                            <>
                                <Button
                                    css={createButtonStyle(token)}
                                    icon={IconTypes.USERS}
                                    size={Size.MEDIUM}
                                    variant={ButtonVariant.SOLID}
                                    onClick={() => props.goToAgentManagement()}
                                    disabled={!!AIAgentManagementRouteMatch}
                                >
                                    {'Manage Agents'}
                                </Button>
                            </>
                        </div>
                    </div>
                    <Divider css={dividerStyle(token)} />
                </div>
                <HeightUsedScrollContainer>
                    <div css={panelBodyStyle(token)}>
                        <div css={dashboardConvoAIWrapperStyle(token)}>
                            <Breadcrumbs crumbs={breadcrumbs} />
                            <ScrollZone css={dashboardConvoAIBodyStyle(token)}>
                                <div css={dashboardConvoAIBodyContentStyle(token)}>{props.children}</div>
                            </ScrollZone>
                        </div>
                    </div>
                </HeightUsedScrollContainer>
            </Card>
        </div>
    );
};
