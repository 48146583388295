import { ICatalog, ICatalogInfo } from '../models/Catalog';
import { IScriptObjectModel } from '../models/ScriptObjectModel';
import { SPACE_ENDPOINT } from '../constants/ApiEndpoint';
import { httpClient } from './HttpClient';
import { tryUseCachedForGetReq, UseStaleWhileFetchingStrategy } from './StaleWhileFetching';
import { ITypeBundle } from '../models/TypeBundle';
import { IScriptDirectoryItem } from '../models/ScriptDirectoryItem';
import {
    projectConfigAddCatalogAction,
    ProjectConfigAction
} from '../../../redux/spaceKeyed/projectConfig/ProjectConfigActions';
import { catalogTypeSet, CatalogTypeActions } from '../../../redux/spaceKeyed/catalogTypes/CatalogTypeActions';
import {
    catalogSetAction,
    catalogSetByIdAction,
    catalogSetByTypesAction,
    catalogAppendAction,
    catalogUpdateAction,
    catalogDeleteAction,
    catalogRenameAction
} from '../../../redux/spaceKeyed/catalog/CatalogActions';
import { catalogItemAppendAction } from '../../../redux/spaceKeyed/catalog/CatalogItemActions';
import { AllCatalogActions } from '../../../redux/spaceKeyed/catalog/CatalogsReducer';

export const catalogsPath = (spaceName: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/catalogs`;
};

export const catalogByIdPath = (spaceName: string, catalogId: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/catalogs/${catalogId}/`;
};

export const catalogsByTypePath = (spaceName: string, catalogType: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/Catalogs?type=${catalogType}`;
};

export const catalogsByProjectPath = (spaceName: string, projectId: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/Catalogs?projectId=${projectId}`;
};

export const createCatalogPath = (spaceName: string, projectId?: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/catalogs/${projectId ? `?projectId=${projectId}` : ``}`;
};

export const createScriptLauncherPath = (spaceName: string, catalogId: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/catalogs/${catalogId}/items`;
};

export const saveCatalogPath = (spaceName: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/CatalogEditor/Save`;
};

export const deleteCatalogPath = (spaceName: string, catalogId: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/catalogs/${catalogId}/`;
};

export const patchCatalogPath = (spaceName: string, catalogId: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/catalogs/${catalogId}/metadata`;
};

export const catalogTypesPath = (spaceName: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/catalogs/types`;
};

export const postItemPath = (spaceName: string, catalogId: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/catalogs/${catalogId}/items`;
};

export const putItemPath = (spaceName: string, catalogId: string, itemId: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/catalogs/${catalogId}/items/${itemId}/`;
};

const getCatalogsNetworkCallLegacy = async (
    spaceName: string,
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    try {
        const response = await httpClient.get(catalogsPath(spaceName));
        dispatch(catalogSetAction(response.data));
    } catch (error) {}
};

const getCatalogsNetworkCallSWF = async (
    spaceName: string,
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    const url = catalogsPath(spaceName);
    await tryUseCachedForGetReq(
        url,
        httpClient.get(url),
        data => dispatch(catalogSetAction(data as ICatalog<IScriptObjectModel>[])),
        () => {}
    );
};

export const getCatalogsNetworkCall = UseStaleWhileFetchingStrategy
    ? getCatalogsNetworkCallSWF
    : getCatalogsNetworkCallLegacy;

const getCatalogByIdNetworkCallLegacy = async (
    spaceName: string,
    catalogId: string,
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    try {
        const response = await httpClient.get(catalogByIdPath(spaceName, catalogId));
        dispatch(catalogSetByIdAction(response.data));
    } catch (error) {}
};

const getCatalogByIdNetworkCallSWF = async (
    spaceName: string,
    catalogId: string,
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    const url = catalogByIdPath(spaceName, catalogId);
    await tryUseCachedForGetReq(
        url,
        httpClient.get(url),
        data => dispatch(catalogSetByIdAction(data as ICatalog<IScriptObjectModel>)),
        () => {}
    );
};

export const getCatalogByIdNetworkCall = UseStaleWhileFetchingStrategy
    ? getCatalogByIdNetworkCallSWF
    : getCatalogByIdNetworkCallLegacy;

const getCatalogsByTypeNetworkCallLegacy = async (
    spaceName: string,
    catalogType: string[],
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    try {
        const response = await httpClient.get(catalogsByTypePath(spaceName, catalogType[0]));
        dispatch(catalogSetByTypesAction(catalogType, response.data));
    } catch (error) {}
};

const getCatalogsByTypeNetworkCallSWF = async (
    spaceName: string,
    catalogType: string[],
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    const url = catalogsByTypePath(spaceName, catalogType[0]);
    await tryUseCachedForGetReq(
        url,
        httpClient.get(url),
        data => dispatch(catalogSetByTypesAction(catalogType, data as ICatalog<IScriptObjectModel>[])),
        () => {}
    );
};

export const getCatalogsByTypeNetworkCall = UseStaleWhileFetchingStrategy
    ? getCatalogsByTypeNetworkCallSWF
    : getCatalogsByTypeNetworkCallLegacy;

const getCatalogsByProjectNetworkCallLegacy = async (
    spaceName: string,
    projectId: string,
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    try {
        const response = await httpClient.get(catalogsByProjectPath(spaceName, projectId));

        //TODO: come back to this... even the old behaviour was replacing the catalog data
        dispatch(catalogSetAction(response.data));
    } catch (error) {}
};

const getCatalogsByProjectNetworkCallSWF = async (
    spaceName: string,
    projectId: string,
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    const url = catalogsByProjectPath(spaceName, projectId);
    await tryUseCachedForGetReq(
        url,
        httpClient.get(url),
        data => dispatch(catalogSetAction(data as ICatalog<IScriptObjectModel>[])),
        () => {}
    );
};

export const getCatalogsByProjectNetworkCall = UseStaleWhileFetchingStrategy
    ? getCatalogsByProjectNetworkCallSWF
    : getCatalogsByProjectNetworkCallLegacy;

export const createCatalogNetworkCall = async (
    spaceName: string,
    catalog: ICatalog<IScriptObjectModel>,
    dispatch: (action: AllCatalogActions | ProjectConfigAction) => void,
    projectId?: string
): Promise<ICatalogInfo | undefined> => {
    try {
        const response = await httpClient.post(createCatalogPath(spaceName, projectId), catalog);
        dispatch(catalogAppendAction(response.data));

        if (projectId) {
            //Auto import so we dispatch to project config reducer as well
            dispatch(projectConfigAddCatalogAction(projectId, response.data));
        }

        return response.data as ICatalogInfo;
    } catch (error) {
        throw error;
    }
};

export const createScriptLauncherNetworkCall = async (
    spaceName: string,
    catalogId: string,
    scenario: IScriptDirectoryItem,
    dispatch: (action: AllCatalogActions) => void
): Promise<IScriptDirectoryItem | void> => {
    try {
        const response = await httpClient.post(createScriptLauncherPath(spaceName, catalogId), scenario);
        dispatch(catalogItemAppendAction(catalogId, response.data));
        return response.data;
    } catch (error) {}
};

export const saveCatalogNetworkCall = async (
    spaceName: string,
    catalog: ICatalog<IScriptObjectModel>,
    dispatch: (action: AllCatalogActions) => void
): Promise<ICatalog<IScriptObjectModel> | undefined> => {
    try {
        const response = await httpClient.post(saveCatalogPath(spaceName), catalog);

        dispatch(catalogUpdateAction(response.data));

        return response.data;
    } catch (error) {
        return;
    }
};

export const deleteCatalogNetworkCall = async (
    spaceName: string,
    catalogId: string,
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    try {
        await httpClient.delete(deleteCatalogPath(spaceName, catalogId));
        dispatch(catalogDeleteAction(catalogId));
    } catch (error) {}
};

export const patchCatalogNetworkCall = async (
    spaceName: string,
    catalog: ICatalogInfo,
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    try {
        dispatch(catalogRenameAction(catalog.id, catalog.title ?? '', catalog.name));

        const response = await httpClient.patch(patchCatalogPath(spaceName, catalog.id), catalog);

        if (response) {
            dispatch(catalogRenameAction(catalog.id, response.data.title, response.data.name));
        }
    } catch (error) {
        throw error;
    }
};

const getCatalogTypesNetworkCallLegacy = async (
    spaceName: string,
    dispatch: (action: CatalogTypeActions) => void
): Promise<void> => {
    try {
        const response = await httpClient.get(catalogTypesPath(spaceName));

        dispatch(catalogTypeSet(response.data));
    } catch (error) {}
};

const getCatalogTypesNetworkCallSWF = async (
    spaceName: string,
    dispatch: (action: CatalogTypeActions) => void
): Promise<void> => {
    const url = catalogTypesPath(spaceName);

    await tryUseCachedForGetReq(
        url,
        httpClient.get(url),
        data => dispatch(catalogTypeSet(data as ITypeBundle[])),
        () => {}
    );
};

export const getCatalogTypesNetworkCall = UseStaleWhileFetchingStrategy
    ? getCatalogTypesNetworkCallSWF
    : getCatalogTypesNetworkCallLegacy;
