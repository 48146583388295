import React, { useState, useEffect } from 'react';
import { IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { Collection } from '../../core-ui/collection';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import {
    mediaItemGridCollectionStyle,
    mediaitemListCollectionStyle,
    gridRowStyle,
    textMetadataWrapperStyle,
    mediaCollectionWrapperStyle,
    paginationWrapper,
    detailedViewModalWrapper,
    detailedViewHeaderStyle,
    textTitleWrapperStyle,
    listHeadingStyle,
    listHeadingTextStyle,
    emptyMediaMessageWrapper,
    nextPrevIconStyle
} from './MediaSourceCollection.style';
import { ListOrGrid } from '../../core-ui/collection/Collection';
import { RowItem } from '../../core-ui/rowItem';
import { useStyle } from '../../shared/hooks/useStyle';
import ReactPaginate from 'react-paginate';
import { Modal } from '../modal';
import { Button } from '../../core-ui/button';
import { MediaSourceDetailedView } from '../mediaSourceDetailedView/MediaSourceDetailedView';
import { Heading } from '../../core-ui/heading';
import { MediaItemsCollection } from '../mediaItemsCollection';
import './pagination.css';
import { EmptyCollection } from '../../core-ui/emptyCollection';
import { Loading } from '../../core-ui/loading';
import { Icon } from '../../core-ui/icon';
import { Text } from '../../core-ui/text';
import { IMediaSourceEntity } from '../../redux/spaceKeyed/media/MediaReducer';
import { useTranslation } from 'react-i18next';

export interface IMediaSourceCollectionProps {
    data: IMediaSourceEntity[];
    display?: ListOrGrid;
    onItemSelect?: (item: IHostedMediaSource) => void;
    typeFilter?: string;
    searchFilter?: string;
    isLoading?: boolean;
}

const numGridItemsShown = 36;
const numListItemsShown = 10;

const resolveItemsShown = (display?: ListOrGrid) => (display === 'grid' ? numGridItemsShown : numListItemsShown);

const filterData = (data: IMediaSourceEntity[], typeFilter?: string, searchFilter?: string) => {
    let filteredData = data;

    if (typeFilter !== 'none' && typeFilter !== 'other') {
        filteredData = data?.filter(
            item => item && item.mediaSource.contentType && item.mediaSource.contentType.split('/')[0] === typeFilter
        );
    }

    if (typeFilter === 'other') {
        filteredData = data?.filter(item => {
            const contentType = item.mediaSource.contentType.split('/')[0];
            return (
                item &&
                item.mediaSource.contentType &&
                contentType !== 'image' &&
                contentType !== 'audio' &&
                contentType !== 'video'
            );
        });
    }

    if (searchFilter) {
        filteredData = data?.filter(
            item =>
                item &&
                item.mediaSource &&
                item.mediaSource.title &&
                item.mediaSource.title.toLocaleUpperCase().includes(searchFilter.toLocaleUpperCase())
        );
    }

    return filteredData;
};

export const MediaSourceCollection: React.FC<IMediaSourceCollectionProps> = ({
    data,
    display,
    onItemSelect,
    typeFilter = 'none',
    searchFilter,
    isLoading
}): React.ReactElement => {
    const [pageNum, setPageNum] = useState<number>(0);
    const [numItemsShown, setNumItemsShown] = useState(resolveItemsShown(display));
    const [showDetailedView, setShowDetailedView] = useState<IHostedMediaSource | undefined>(undefined);
    const token = useStyle();
    const { t } = useTranslation();

    const handleShowDetailedView = (item: IHostedMediaSource) => {
        setShowDetailedView(item);
    };

    const handleCloseDetailedView = () => {
        setShowDetailedView(undefined);
    };

    useEffect(() => {
        setNumItemsShown(resolveItemsShown(display));
        setPageNum(0);
    }, [display]);

    const onPageChange = (selectedItem: { selected: number }): void => {
        setPageNum(selectedItem.selected);
    };

    const handleItemClick = (item: IHostedMediaSource) => {
        onItemSelect ? onItemSelect(item) : handleShowDetailedView(item);
    };
    // const itemOverflow = mediaItemElements.length % numItemsShown === 0 ? 0 : 1;

    const filteredData = filterData(data, typeFilter, searchFilter);
    const paginatedData = filteredData.slice(pageNum * numItemsShown, pageNum * numItemsShown + numItemsShown);
    const numPages = Math.ceil(filteredData.length / numItemsShown);

    return (
        <>
            <Modal isOpen={!!showDetailedView}>
                <div css={detailedViewModalWrapper(token)}>
                    <div css={detailedViewHeaderStyle(token)}>
                        <Heading>{t('mediaItemDetails')}</Heading>
                        <Button icon={IconTypes.TIMES} onClick={handleCloseDetailedView} />
                    </div>
                    <MediaSourceDetailedView source={showDetailedView} onClose={() => setShowDetailedView(undefined)} />
                </div>
            </Modal>
            <div css={mediaCollectionWrapperStyle}>
                {display === 'list' && (
                    <RowItem css={listHeadingStyle(token)}>
                        {() => ({
                            content: (
                                <div css={gridRowStyle}>
                                    <Text css={[textTitleWrapperStyle(token), listHeadingTextStyle(token)]}>
                                        {t('title')}
                                    </Text>
                                    <div />
                                    <Text css={[textMetadataWrapperStyle, listHeadingTextStyle(token)]}>
                                        {t('lastModifiedDate')}
                                    </Text>
                                    <Text css={[textMetadataWrapperStyle, listHeadingTextStyle(token)]}>
                                        {t('type')}
                                    </Text>
                                    <Text css={[textMetadataWrapperStyle, listHeadingTextStyle(token)]}>
                                        {t('size')}
                                    </Text>
                                </div>
                            )
                        })}
                    </RowItem>
                )}
                <Loading isLoaded={!isLoading} loadingRender={() => <MediaItemsCollection isLoading={isLoading} />}>
                    {data && data.length > 0 ? (
                        <Collection
                            display={display}
                            css={display === 'grid' ? mediaItemGridCollectionStyle : mediaitemListCollectionStyle}
                        >
                            {data && (
                                <MediaItemsCollection
                                    display={display}
                                    list={paginatedData}
                                    onClick={handleItemClick}
                                ></MediaItemsCollection>
                            )}
                        </Collection>
                    ) : (
                        <EmptyCollection
                            css={emptyMediaMessageWrapper}
                            message={t('noMediaItemsText')}
                        ></EmptyCollection>
                    )}
                </Loading>
                <div css={paginationWrapper}>
                    {numPages > 0 && (
                        <ReactPaginate
                            previousLabel={<Icon icon={IconTypes.CHEVRON_LEFT} iconStyle={nextPrevIconStyle}></Icon>}
                            nextLabel={<Icon icon={IconTypes.CHEVRON_RIGHT} iconStyle={nextPrevIconStyle}></Icon>}
                            breakClassName={'break'}
                            pageCount={numPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={onPageChange}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            forcePage={pageNum}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
