import React from 'react';
import { Textarea } from '@chakra-ui/core';
import { textAreaStyle } from './TextArea.style';

interface ITextAreaProps {
    className?: string;
    placeholder?: string;
    value?: string;
    defaultValue?: string;
    isInvalid?: boolean;
    onChange?: (changedValue: string) => void;
}

export const TextArea: React.FC<ITextAreaProps> = ({
    placeholder,
    isInvalid,
    onChange,
    value,
    defaultValue,
    ...rest
}): React.ReactElement => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event.target.value);
    };

    return (
        <Textarea
            {...rest}
            isInvalid={isInvalid}
            value={value}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={handleChange}
            css={textAreaStyle}
        />
    );
};
