import React from 'react';
import { renderSubRoute } from '../editorRoutes';
import { IProjectsRouteParams } from '../projectsRoute';
import { useEditorRouteMatch, useRoute } from '../useRoute';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useGetCatalogTypes } from '../../shared/motive/hooks/useCatalogs/useCatalogTypes/useGetCatalogTypes';
import { useScenariosRoute } from '../scenariosRoute';
import { DashboardConvoAIContainer } from '../../containers/dashboardConvoAIContainer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConvoAIRouteParams extends IProjectsRouteParams {}

export const useConvoAIRoute = () => useRoute<IConvoAIRouteParams>('ConvoAI');
export const useConvoAIRouteMatch = () => useEditorRouteMatch<IConvoAIRouteParams>('ConvoAI');

export const ConvoAIRoute: React.FC = () => {
    const { subRoutes } = useConvoAIRoute();

    const { goTo } = useConvoAIRoute();

    const { goTo: redirectToScenarios } = useScenariosRoute();

    const { data: typeData } = useGetCatalogTypes();
    const isAIFeatureSetAvailable = typeData.some(type => type.bundleName === MotiveTypes.AI);

    if (isAIFeatureSetAvailable) {
        return <DashboardConvoAIContainer>{subRoutes.map(renderSubRoute)}</DashboardConvoAIContainer>;
    }

    return <>{redirectToScenarios()}</>;
};
