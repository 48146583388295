import { keyframes, css } from '@emotion/core';
import { stretchWidth } from '../../constants/HelperStyles';
import { IToken } from '../../shared/contexts/styleContext';

const changeColor = keyframes`
    0% { background-color: gray; }
    100% { background-color: none; }
`;

export const animatedChange = (time: number) => css`
    animation: ${changeColor} ${time}ms linear;
`;

export const copyStyle = (tokens: IToken) => css`
    padding: ${tokens.spacings.smallest};
    background-color: ${tokens.colors.background.light};
    border-radius: 4px;
    border: none;
    ${stretchWidth}
    outline: none;

    &::selection {
        background: ${tokens.colors.background.default};
    }
`;

export const copyIconStyle = (token: IToken) => css`
    color: ${token.colors.background.lighter};
    font-size: 14px;
`;

export const copyFieldLayout = css`
    display: flex;
    align-items: center;
`;

export const obscureStyle = css`
    color: transparent;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
`;

export const ctfButtonStyle = css`
    padding: 0 8px;
    width: 24px;
`;
