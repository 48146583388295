import { useCurrentSpace } from '../../useCurrentSpace';
import { IScriptObjectModel } from '../../../models/ScriptObjectModel';
import { useNetworkCallWithoutDataSource } from '../../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useDispatch } from 'react-redux';
import { putCatalogItemNetworkCall } from '../../../networking/CatalogItemService';

export interface ISaveCatalogItemParams {
    catalogId: string;
    item: IScriptObjectModel;
}
export const useSaveCatalogItem = (): IHookProvidedCall<ISaveCatalogItemParams, IScriptObjectModel | undefined> => {
    const [currentSpace] = useCurrentSpace();
    const catalogDispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const putCatalogItem = async (params: ISaveCatalogItemParams): Promise<IScriptObjectModel | undefined> => {
        return await triggerCall(
            putCatalogItemNetworkCall(
                currentSpace,
                params.catalogId,
                params.item,
                getCancelableDispatch(catalogDispatch)
            )
        );
    };

    return {
        execute: putCatalogItem,
        ...rest
    };
};
