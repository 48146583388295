import { css } from '@emotion/core';

export const scenarioLayout = css`
    flex: 1;
    flex-grow: 2;
`;

export const activityLayout = css`
    flex: 1;
    flex-grow: 1;
`;
export const containerStyle = css`
    height: 100%;
    display: flex;
`;
