import { createSelector } from 'reselect';
import { GenerateSubSelector } from '../spaceKeyedSelectors';
import { IBundleState } from './BundleReducer';

const selectBundleState = GenerateSubSelector<IBundleState>('bundle');

export const SelectBundles = createSelector(selectBundleState, bundleState => bundleState.bundleItems);

export const SelectBundleStatus = createSelector(selectBundleState, bundleState => bundleState.status);

export const SelectBundleItem = (id: string) =>
    createSelector(selectBundleState, bundleState => bundleState.bundleItems[id]);

export const SelectBundleRestoreStatus = createSelector(selectBundleState, bundleState => bundleState.restoreStatus);
