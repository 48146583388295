import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const backupRowStyle = (token: IToken) => css`
    padding: ${token.spacings.medium};
    justify-content: space-between;
    display: flex;
    align-items: center;
`;

export const backupItemStyle = css`
    flex-basis: 100%;
`;

export const contextualItemStyle = css`
    flex-basis: 30%;
    text-align: right;
`;

export const alternateColorStyle = (token: IToken) => css`
    background-color: ${token.colors.background.lighter};
`;

export const bottomSectionStyle = (token: IToken) => css`
    font-size: ${token.fontSizes.small};
`;

export const restoreButtonStyle = (token: IToken) => css`
    background-color: none;
    padding: 2px ${token.spacings.small} 0 ${token.spacings.small};
`;

export const backupMenuItemStyle = (token: IToken) => css`
    text-align: center;
    text-decoration: none;
    color: ${token.colors.font.primary};
`;

export const backupSectionStyle = (token: IToken) => css`
    margin: ${token.spacings.medium};
`;

export const backupHeaderStyle = (token: IToken) => css`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${token.colors.border.primary};
    padding: ${token.spacings.medium} ${token.spacings.small} ${token.spacings.medium} ${token.spacings.medium};
`;

export const backupTitleStyle = css`
    flex-basis: 100%;
`;

export const addBackupStyle = css`
    width: 32px;
    height: 32px;
`;

export const bundleLayout = css`
    flex: 1;
    flex-grow: 2;
`;

export const publishingLayout = css`
    flex: 1;
    flex-grow: 1;
`;
export const containerStyle = css`
    display: flex;
    height: 100%;
`;

export const bundlesEmptyCollectionStyle = css`
    margin-top: 50px;
    height: 100px;
`;

export const restoreUploadStyle = css`
    min-width: 600px;
`;
