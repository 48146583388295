import { useState } from 'react';

//TODO:: RENAME THIS AT THE END OF THE PRS.
export const useNetworkCallWithoutDataSource = (throwErrors: boolean = false) =>
    useNetworkCallForDataSource(undefined, () => false, throwErrors);

export const useNetworkCallForDataSource = <K>(
    data: K,
    isDataLoadingFn: (data: K) => boolean = data => !data,
    throwErrors: boolean = false
) => {
    const [callIsPending, setCallIsPending] = useState(isDataLoadingFn(data));
    const [error, setError] = useState(undefined);

    let canTriggerStateUpdates = true;

    // Triggers a network call in a cancelable promise.
    const triggerCall = async <K>(call: Promise<K>) => {
        // Clear error status
        setError(undefined);

        try {
            canTriggerStateUpdates && setCallIsPending(true);
            const ret = await call;
            canTriggerStateUpdates && setCallIsPending(false);
            return ret;
        } catch (e) {
            if (canTriggerStateUpdates) {
                setError(e);
                setCallIsPending(false);
            }

            if (throwErrors) {
                throw e;
            }
        }
    };

    // Convenience method, triggers n number of calls in parallel.
    // This is useful if a hook needs to grab several pieces of data.
    const triggerMultipleCalls = async <K>(calls: Promise<K>[]) => {
        try {
            canTriggerStateUpdates && setCallIsPending(true);
            const ret = await Promise.all(calls);
            canTriggerStateUpdates && setCallIsPending(false);
            return ret;
        } catch (e) {
            if (canTriggerStateUpdates) {
                setError(e);
                setCallIsPending(false);
            }
        }
    };

    // Cancel calls method will cancel all pending calls and dispatches for state updates.
    // Optional override method allows for inversion of control on when to actually disconnect.
    const cancelCalls = (shouldDisconnectStateUpdates: () => boolean = () => true) => {
        canTriggerStateUpdates = !shouldDisconnectStateUpdates();
    };

    // Returns a wrapper for dispatch call that will disconnect the dispatch if the cancelation token has gone through.
    const getCancelableDispatch = <X>(dispatch: React.Dispatch<X>) => {
        return (args: X) => {
            if (canTriggerStateUpdates) {
                dispatch(args);
            }
        };
    };

    return {
        isLoading: callIsPending,
        error: error,
        triggerCall: triggerCall,
        triggerMultipleCalls: triggerMultipleCalls,
        cancel: cancelCalls,
        getCancelableDispatch: getCancelableDispatch
    };
};
