import { IToken } from '../../../shared/contexts/styleContext';
import { css } from '@emotion/core';

export const pillWrapperStyle = (token: IToken) => css`
    margin-right: ${token.spacings.smallest};
    margin-bottom: ${token.spacings.smallest};
`;

export const pillIconStyle = css`
    color: white;
`;

export const pillTextStyle = css`
    color: white;
`;

export const flexStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const buttonStyle = (token: IToken) => css`
    margin: 0px ${token.spacings.smallest};
`;

export const pillContainerStyle = css`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 20px;
`;
