import React from 'react';
import {
    IRenameCatalogParams,
    useRenameCatalog
} from '../../shared/motive/hooks/useCatalogs/useRenameCatalog/useRenameCatalog';
import {
    IDeleteCatalogParams,
    useDeleteCatalog
} from '../../shared/motive/hooks/useCatalogs/useDeleteCatalog/useDeleteCatalog';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';

interface ICatalogControlProps {
    renameCatalog: IHookProvidedCall<IRenameCatalogParams>;
    deleteCatalog: IHookProvidedCall<IDeleteCatalogParams>;
}

export interface ICatalogItemContainerProps {
    children: (props: ICatalogControlProps) => React.ReactNode;
}

export const CatalogItemContainer: React.FC<ICatalogItemContainerProps> = ({ children }) => {
    const renameCatalog = useRenameCatalog();
    const deleteCatalog = useDeleteCatalog();

    return <>{children({ renameCatalog, deleteCatalog })}</>;
};
