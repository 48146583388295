import { css } from '@emotion/core';

export const scrollContainerStyle = css`
    height: 100%;
`;

/** Drag and drop library only works with scroll if set to 'auto' */
export const forceOverflowAutoStyle = (scrollX: boolean, scrollY: boolean) => {
    return css`
        overflow-x: ${scrollX ? `auto` : `hidden`};
        overflow-y: ${scrollY ? `auto` : `hidden`};

        &:hover {
            overflow-x: ${scrollX ? 'auto' : 'hidden'};
            overflow-y: ${scrollY ? 'auto' : 'hidden'};
        }
    `;
};
