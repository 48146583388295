import { httpClient } from './HttpClient';
import { Dispatch } from 'redux';
import { activitySetAction } from '../../../redux/spaceKeyed/activity/ActivityActions';

const activityPath = (spaceName: string) => `api/space/${spaceName}/PortalActivity`;
export const getActivities = async (spaceName: string, dispatch: Dispatch): Promise<void> => {
    const response = await httpClient.get(activityPath(spaceName));

    dispatch(activitySetAction(response.data));
};
