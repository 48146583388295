import { Checkbox } from '../../core-ui/checkbox';
import React from 'react';
import { RowItem } from '../../core-ui/rowItem';
import { useStyle } from '../../shared/hooks/useStyle';
import {
    checkboxWrapperStyle,
    draggableRowItemStyle,
    editFieldWrapperStyle,
    listHeadingStyle,
    listItemStyle,
    nameOnlyGridRowStyle,
    spinnerIconStyle,
    spinnerOffsetStyle,
    spinnerWrapperStyle
} from './Intents.style';
import { checkboxStyle } from './Intents.style';
import { LoadingSkeleton } from '../../core-ui/loadingSkeleton/LoadingSkeleton';
import { gridRowStyle, textMetadataWrapperStyle, textTitleWrapperStyle } from './Intents.style';
import { Text } from '../../core-ui/text';
import { EmptyCollection } from '../../core-ui/emptyCollection';
import { EditableText } from '../../core-ui/editableText';
import { useState } from 'react';
import { rotating } from '../../constants/AnimationStyles';
import { Icon } from '../../core-ui/icon';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { ConversationalIntent } from '../../shared/motive/models/ConversationalIntent';
import { getAgentIdFromIntent } from '../../util/AIUtils';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { ConversationalAgent } from '../../shared/motive/models/ConversationalAgent';
import { CloneDrag } from '../_common/DragAndDrop/CloneDrag/CloneDrag';
import { ICatalogObjectInstanceDrag } from '../_common/DragAndDrop/DragModels/DragModels';
import { DRAG_ITEM_TYPE_CATALOG_ITEM } from '../../constants/DragAndDrop';
import { Drop } from '../_common/DragAndDrop/Drop/Drop';

export interface IIntentsProps {
    agents?: ConversationalAgent[];
    intents: ConversationalIntent[];
    catalogId: string;
    searchFilter?: string;
    onSelectIntent?: (intentId: string) => void;
    onMultiSelectIntent?: (intents: string[], filteredIntents?: string[]) => void;
    onUpdateIntent?: (intentId: string, intentName?: string, intentDescription?: string) => Promise<void>;
    isCreateIntentLoading?: boolean;
    isUpdatingPivotField?: boolean;
    isDeleteIntentsLoading?: boolean;
    selectedIntents?: string[];
    isRenameIntentLoading?: boolean;
    nameOnly?: boolean;
    isDraggable?: boolean;
}

interface IIntentRowProps {
    idx: number;
    intentId: string;
    intentType: string;
    catalogId: string;
    name?: string;
    description?: string;
    agentName?: string;
    numExamples?: number;
    onClick?: (intentId: string) => void;
    onMultiSelect?: (intents: string[]) => void;
    onUpdate?: (intentId: string, name?: string, description?: string) => Promise<void>;
    isSelected?: boolean;
    nameOnly?: boolean;
    isDraggable?: boolean;
}

const IntentRow: React.FC<IIntentRowProps> = (props): React.ReactElement => {
    const token = useStyle();
    const [isUpdating, setIsUpdating] = useState<boolean>();
    const [optimisticName, setOptimisticName] = useState<string | undefined>(props.name);
    const [optimisticDesc, setOptimisticDesc] = useState<string | undefined>(props.description);

    const handleSelectIntent = () => {
        props.onClick && props.onClick(props.intentId);
    };

    const handleChangeOptimisticName = (name: string) => {
        setOptimisticName(name);
    };

    const handleChangeOptimisticDesc = (desc: string) => {
        setOptimisticDesc(desc);
    };

    const handleUpdateIntent = async (name?: string, description?: string) => {
        setIsUpdating(true);

        if (!name) {
            setOptimisticName(props.name);
        }

        props.onUpdate &&
            (await props.onUpdate(props.intentId, name, description).then(() => {
                setIsUpdating(false);
            }));
    };

    const handleMultiSelect = () => {
        props.onMultiSelect && props.onMultiSelect([props.intentId]);
    };

    const rowItem = (
        <RowItem onClick={handleSelectIntent} css={listItemStyle(token)}>
            {() => ({
                content: (
                    <div css={!props.nameOnly ? gridRowStyle : nameOnlyGridRowStyle}>
                        <div css={checkboxWrapperStyle(token)}>
                            <Checkbox
                                onClick={handleMultiSelect}
                                css={checkboxStyle(token)}
                                isChecked={!!props.isSelected}
                            />
                        </div>
                        <div css={spinnerWrapperStyle}>
                            <span css={spinnerOffsetStyle}>
                                {isUpdating && (
                                    <Icon css={[spinnerIconStyle(token), rotating]} icon={IconTypes.LOAD_SPINNER} />
                                )}
                            </span>
                        </div>
                        <div css={editFieldWrapperStyle(token)}>
                            <EditableText
                                value={optimisticName}
                                onChange={handleChangeOptimisticName}
                                onEditComplete={name => handleUpdateIntent(name, props.description)}
                                css={textTitleWrapperStyle(token, !!isUpdating)}
                            />
                        </div>
                        {!props.nameOnly && (
                            <div css={editFieldWrapperStyle(token)}>
                                <EditableText
                                    value={optimisticDesc}
                                    onChange={handleChangeOptimisticDesc}
                                    onEditComplete={desc => handleUpdateIntent(props.name, desc)}
                                    css={textTitleWrapperStyle(token, !!isUpdating)}
                                />
                            </div>
                        )}
                        {!props.nameOnly && <Text css={textMetadataWrapperStyle(token)}>{props.agentName}</Text>}
                    </div>
                )
            })}
        </RowItem>
    );

    if (props.isDraggable) {
        const dragThumbContent = (
            <RowItem css={[listItemStyle(token), draggableRowItemStyle(token)]}>
                {() => ({
                    content: (
                        <div css={nameOnlyGridRowStyle}>
                            <div />
                            <div />
                            <Text css={textTitleWrapperStyle(token)}>{optimisticName}</Text>
                        </div>
                    )
                })}
            </RowItem>
        );

        return (
            <CloneDrag<ICatalogObjectInstanceDrag>
                index={props.idx}
                thumb={dragThumbContent}
                drag={{
                    dragType: DRAG_ITEM_TYPE_CATALOG_ITEM,
                    catalogId: props.catalogId,
                    objectId: props.intentId,
                    objectType: MotiveTypes.WATSON_INTENT
                }}
            >
                {rowItem}
            </CloneDrag>
        );
    }

    return rowItem;
};

const LoadingIntentRow: React.FC = (): React.ReactElement => {
    const token = useStyle();

    return (
        <RowItem css={listItemStyle(token)}>
            {() => ({
                content: <LoadingSkeleton height={token.spacings.medium} />
            })}
        </RowItem>
    );
};

export const Intents: React.FC<IIntentsProps> = ({
    agents,
    searchFilter,
    intents,
    onSelectIntent,
    onMultiSelectIntent,
    onUpdateIntent,
    isCreateIntentLoading,
    isUpdatingPivotField,
    isDeleteIntentsLoading,
    selectedIntents,
    nameOnly,
    catalogId,
    isDraggable
}) => {
    const token = useStyle();

    if (!intents?.length) {
        return <EmptyCollection message={'No Intents were found in this catalog. You can create an intent here.'} />;
    }

    let content;

    const filteredIntents = searchFilter
        ? intents.filter(intent => intent.intentName?.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase()))
        : intents;

    const isIntentRowLoading = (intentId: string) => {
        if (isUpdatingPivotField || (selectedIntents?.includes(intentId) && !!isDeleteIntentsLoading)) {
            return true;
        } else {
            return false;
        }
    };

    switch (intents[0].type) {
        case MotiveTypes.WATSON_INTENT:
            content = filteredIntents.map((intent: ConversationalIntent, idx) =>
                !isIntentRowLoading(intent.id) ? (
                    <IntentRow
                        idx={idx}
                        key={intent.id}
                        intentId={intent.id}
                        catalogId={catalogId}
                        name={intent.intentName}
                        description={intent.description}
                        agentName={agents?.find(agent => agent.id === getAgentIdFromIntent(intent))?.name}
                        onClick={onSelectIntent}
                        onMultiSelect={onMultiSelectIntent}
                        isSelected={selectedIntents?.includes(intent.id)}
                        onUpdate={onUpdateIntent}
                        nameOnly={nameOnly}
                        intentType={intent.type}
                        isDraggable={isDraggable}
                    />
                ) : (
                    <LoadingIntentRow key={intent.id} />
                )
            );
            break;
        default:
        case MotiveTypes.DIALOGFLOW_INTENT:
            content = filteredIntents.map((intent: ConversationalIntent, idx) =>
                !isIntentRowLoading(intent.id) ? (
                    <IntentRow
                        idx={idx}
                        key={intent.id}
                        intentId={intent.id}
                        catalogId={catalogId}
                        name={intent.intentName}
                        description={intent.description}
                        agentName={getAgentIdFromIntent(intent)}
                        onClick={onSelectIntent}
                        onMultiSelect={onMultiSelectIntent}
                        isSelected={selectedIntents?.includes(intent.id)}
                        onUpdate={onUpdateIntent}
                        nameOnly={nameOnly}
                        intentType={intent.type}
                        isDraggable={isDraggable}
                    />
                ) : (
                    <LoadingIntentRow key={intent.id} />
                )
            );
            break;
    }

    if (isDraggable) {
        content = (
            <Drop dropId={catalogId} dragType={DRAG_ITEM_TYPE_CATALOG_ITEM} isDisabled>
                {content}
            </Drop>
        );
    }

    return (
        <>
            <RowItem css={listHeadingStyle(token)}>
                {() => ({
                    content: (
                        <div css={nameOnly ? nameOnlyGridRowStyle : gridRowStyle}>
                            <div css={checkboxWrapperStyle(token)}>
                                <Checkbox
                                    onClick={() =>
                                        onMultiSelectIntent && onMultiSelectIntent(filteredIntents.map(i => i.id))
                                    }
                                    css={checkboxStyle(token)}
                                    isChecked={selectedIntents?.length === intents.length}
                                />
                            </div>
                            <span />

                            <Text css={textTitleWrapperStyle(token)}>Name</Text>
                            {!nameOnly && <Text css={textMetadataWrapperStyle(token)}>Description</Text>}
                            {!nameOnly && <Text css={textMetadataWrapperStyle(token)}>Agent</Text>}
                        </div>
                    )
                })}
            </RowItem>
            {content}
            {isCreateIntentLoading && <LoadingIntentRow />}
        </>
    );
};
