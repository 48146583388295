/** @jsx jsx */
import React from 'react';
import { generateGridLayout, generateListLayout } from './Collection.style';
import { jsx } from '@emotion/core';
import { DroppableProvided } from 'react-beautiful-dnd';

export type ListOrGrid = 'list' | 'grid';

interface ICollectionProps {
    className?: string;
    display?: ListOrGrid;
    minColumnWidth?: string;
    maxColumnWidth?: string;
    repeatColumns?: string;
    droppableProvided?: DroppableProvided;
    gridAutoRows?: string;
}

export const Collection: React.FC<ICollectionProps> = ({
    className,
    display = 'grid',
    children,
    minColumnWidth = '85px',
    maxColumnWidth = '85px',
    repeatColumns = '3',
    gridAutoRows = 'auto-fit',
    droppableProvided
}): React.ReactElement => {
    const generatedStyle =
        display === 'grid'
            ? generateGridLayout(minColumnWidth, maxColumnWidth, repeatColumns, gridAutoRows)
            : generateListLayout(minColumnWidth, maxColumnWidth);

    return (
        <div
            className={className}
            css={generatedStyle}
            ref={droppableProvided?.innerRef}
            {...(droppableProvided?.droppableProps ?? {})}
        >
            {children}
            {droppableProvided?.placeholder}
        </div>
    );
};
