import React from 'react';
import { IEnumEditorDropdownProps, EnumItemReferenceEditorDropdown } from '../EnumItemReferenceEditor';

export type IScriptObjectEventEditorProps = IEnumEditorDropdownProps;

/**
 * Script object event editor base class.
 * Currently just an alias for EnumItemReferenceEditorDropdown, but if in the future more common base functionality is needed
 * this component can be fleshed out further.
 */
export const ScriptObjectEventEditor = (props: IScriptObjectEventEditorProps) => {
    return <EnumItemReferenceEditorDropdown {...props} />;
};
