import React from 'react';
import { ILocalizedMedia } from '../../../shared/motive/models/LocalizedMedia';
import { IFieldEditorProps } from '..';
import { useDashboardDrawer } from '../../../hooks/useDashboardDrawer';
import { MotiveTypes } from '../../../constants/MotiveTypes';
import { useGetAllCatalogsByType } from '../../../shared/motive/hooks/useCatalogs/useGetAllCatalogsByType/useGetAllCatalogsByType';
import { IScriptObjectModel } from '../../../shared/motive/models/ScriptObjectModel';
import { MediaItemPreview } from '../../../components/mediaItemPreview';
import { uniqueMotiveId } from '../../../util/MotiveUtils';
import {
    localizedMediaEditorStyle,
    itemDropStyle,
    cardStyle,
    cardHeadingTextStyle,
    draggingTextStyle,
    flexLineBreakDiv
} from './LocalizedMediaEditor.style';
import { Size } from '../../../core-ui/constants/Size';
import { Text } from '../../../core-ui/text';
import { IHostedMediaSource } from '../../../shared/motive/models/MediaItem';
import { IChooseAction } from '../../../shared/motive/reducers/ChooseExternalScriptScopeReducer';

import { useDragBeforeCapture } from '../../../hooks/useDragContext';
import { useStyle } from '../../../shared/hooks/useStyle';
import { ObjectWrapperBox } from '../../../components/objectWrapperBox';
import { IconTypes } from '../../../core-ui/constants/IconTypes';
import { BarVariant } from '../../../core-ui/bar/Bar';
import { Button, ButtonVariant } from '../../../core-ui/button';
import { getSmartName } from '../../../util/ObjectDefinitionsUtils';
import { useObjectDefinitions } from '../../../shared/motive/hooks/useScriptEditorInfo';
import { dragZoneStyle, spacingDivStyle, objectBoxWrapper } from './LocalizedMediaEditor.style';
import { useCurrentLanguageSettings } from '../../../shared/motive/stores/editorLocale/EditorLocale';
import { ICatalogObjectInstanceDrag } from '../../../components/_common/DragAndDrop/DragModels/DragModels';
import { Drop } from '../../../components/_common/DragAndDrop/Drop/Drop';
import { useCheckDragHover } from '../../../components/_common/DragAndDrop/DragAndDropProvider/DragAndDropContext';
import { DRAG_ITEM_TYPE_CATALOG_ITEM } from '../../../constants/DragAndDrop';
import { extractDraggableInfo } from '../../../components/_common/DragAndDrop/DragAndDropProvider/DragAndDropProvider';

interface ILocalizedMediaObjectModel extends ILocalizedMedia, IScriptObjectModel {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILocalizedMediaEditorProps extends IFieldEditorProps<ILocalizedMediaObjectModel> {}

export const LocalizedMediaEditor: React.FC<ILocalizedMediaEditorProps> = fieldProps => {
    const token = useStyle();
    const language = useCurrentLanguageSettings();
    const { value, onChange } = fieldProps;
    const { handleSelectExternalGlobalField, handleCancelSelectExternalGlobalField } = useDashboardDrawer();

    const checkDrag = useCheckDragHover();
    const beforeCapture = useDragBeforeCapture();

    const dragItemType =
        beforeCapture && extractDraggableInfo<ICatalogObjectInstanceDrag>(beforeCapture.draggableId)?.objectType;

    const { data: catalogs } = useGetAllCatalogsByType(MotiveTypes.MEDIA_ITEM);

    const objDefs = useObjectDefinitions();

    const localization = value?.localizations?.find(
        localization => localization.cultureCode === language.contentLanguage
    );

    const handleChange = (retrievedMediaItem: IScriptObjectModel) => {
        const newLocalizedItem = {
            cultureCode: language.contentLanguage as string,
            mediaItem: retrievedMediaItem
        };

        if (value) {
            const localizations = value.localizations?.filter(
                localizedMediaItem => localizedMediaItem.cultureCode !== language.contentLanguage
            );

            retrievedMediaItem &&
                onChange({
                    id: value.id,
                    type: MotiveTypes.LOCALIZED_MEDIA,
                    localizations: localizations ? [...localizations, newLocalizedItem] : [newLocalizedItem]
                });
        } else {
            retrievedMediaItem &&
                onChange({
                    id: uniqueMotiveId(),
                    type: MotiveTypes.LOCALIZED_MEDIA,
                    localizations: [newLocalizedItem]
                });
        }
    };

    const handleChoose = (action: IChooseAction) => {
        action.chosenValue && handleChange(action.chosenValue);
        handleCancelSelectExternalGlobalField();
    };

    const handleAdd = () => {
        handleSelectExternalGlobalField && handleSelectExternalGlobalField(MotiveTypes.MEDIA_ITEM, handleChoose);
    };

    const handleRemove = () => {
        if (fieldProps.value) {
            const filteredLocalizations = fieldProps.value?.localizations.filter(
                localization => localization.cultureCode !== language.contentLanguage
            );

            // eslint-disable-next-line
            fieldProps.onChange({
                ...fieldProps.value,
                localizations: filteredLocalizations
            } as IScriptObjectModel);
        }
    };

    const onCatalogItemDrop = (objDragInfo: ICatalogObjectInstanceDrag) => {
        const item = catalogs
            .find(c => c.id === objDragInfo.catalogId)
            ?.items?.find(i => i.id === objDragInfo.objectId);

        if (item) {
            handleChange(item);
        }
    };

    const dropId = fieldProps.parentObjectEditorProps.value?.id + '.' + fieldProps.path;

    const dropList = [
        {
            dragType: DRAG_ITEM_TYPE_CATALOG_ITEM,
            onDragEnd: onCatalogItemDrop
        }
    ];

    const isDragOver = checkDrag(dropId, DRAG_ITEM_TYPE_CATALOG_ITEM);

    return (
        <>
            {localization && localization.mediaItem ? (
                <>
                    <div css={flexLineBreakDiv}></div>
                    <Drop dropId={dropId} allowedDragList={dropList} css={flexLineBreakDiv}>
                        <>
                            <div css={spacingDivStyle} />
                            <>
                                <div css={objectBoxWrapper}>
                                    {!dragItemType || dragItemType !== MotiveTypes.MEDIA_ITEM ? (
                                        <ObjectWrapperBox
                                            cardStyle={cardStyle}
                                            deleteButtonSize={Size.SMALL}
                                            deleteButtonIcon={IconTypes.TIMES}
                                            headerRender={
                                                <Text css={cardHeadingTextStyle(token)} size={Size.SMALL}>
                                                    {getSmartName(localization.mediaItem, objDefs, language)}
                                                </Text>
                                            }
                                            barVariant={BarVariant.SECONDARY}
                                            onClickDelete={handleRemove}
                                        >
                                            <MediaItemPreview
                                                css={localizedMediaEditorStyle(
                                                    localization.mediaItem.source?.contentType
                                                )}
                                                mediaItem={localization.mediaItem.source as IHostedMediaSource}
                                                title={localization.mediaItem.title}
                                                withLabel={false}
                                                onLabelClick={handleRemove}
                                            />
                                        </ObjectWrapperBox>
                                    ) : (
                                        <div css={[itemDropStyle(token, isDragOver)]}>
                                            <div css={dragZoneStyle(token)}>
                                                <span css={draggingTextStyle(token)}>
                                                    Drag media item here to replace
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div />
                                </div>
                            </>
                        </>
                    </Drop>
                </>
            ) : (
                <Drop dropId={dropId} allowedDragList={dropList}>
                    <div css={[itemDropStyle(token, isDragOver)]}>
                        <Button
                            size={Size.SMALL}
                            icon={IconTypes.PLUS}
                            variant={ButtonVariant.CIRCULAR}
                            onClick={handleAdd}
                        />
                    </div>
                </Drop>
            )}
        </>
    );
};
