import React, { useEffect, useState } from 'react';
import { IWatsonIntent } from '../../shared/motive/models/WatsonIntent';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { DashboardIntentManagement } from '../../components/dashboardIntents';
import { ConversationalAgent } from '../../shared/motive/models/ConversationalAgent';
import { useAddCatalogItem } from '../../shared/motive/hooks/useCatalogs/useAddCatalogItem/useAddCatalogItem';
import { useToast } from '../../core-ui/hooks/useToast';
import { useSaveCatalog } from '../../shared/motive/hooks/useCatalogs';
import { without, findIndex, union } from 'lodash-es';
import { getAgentReferenceFromIntentCatalog } from '../../util/AIUtils';
import { ConversationalIntent } from '../../shared/motive/models/ConversationalIntent';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { IWatsonAssistant } from '../../shared/motive/models/WatsonAssistant';
import { mergeCatalogItemsIntoSingleArray } from '../../util/CatalogUtils';
import { IDashboardIntentManagementProps } from '../../components/dashboardIntents/DashboardIntents';

interface IIntentsContainer {
    children?: (props: IDashboardIntentManagementProps) => React.ReactNode;
    intentCatalog?: ICatalog;
    agentCatalogs?: ICatalog[];
    onSelectIntent?: (catalogId?: string, selectedIntent?: string) => void;
}

export const IntentsContainer: React.FC<IIntentsContainer> = props => {
    // Filtered Data
    const mergedAgents = mergeCatalogItemsIntoSingleArray(props.agentCatalogs) as ConversationalAgent[];
    const agentRef = getAgentReferenceFromIntentCatalog(props.intentCatalog)?.objectId as string;

    // State
    const [agent, setAgent] = useState<ConversationalAgent | undefined>(
        mergedAgents?.find(agent => agent.id === agentRef)
    );
    const [multiSelectedIntents, setMultiSelectedIntents] = useState<string[]>([]);
    const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);

    // UI Hooks
    const toast = useToast();

    // Network Calls
    const {
        execute: addNewIntentItem,
        isLoading: isAddNewIntentItemLoading,
        error: createCatalogItemError
    } = useAddCatalogItem();

    const {
        execute: updateIntentCatalogPivotField,
        error: updateIntentCatalogPivotFieldError,
        isLoading: isUpdateIntentCatalogPivotFieldLoading
    } = useSaveCatalog();

    const { execute: renameIntentCall, error: renameIntentError, isLoading: isRenameIntentLoading } = useSaveCatalog();

    const {
        execute: deleteIntentItems,
        error: deleteIntentItemsError,
        isLoading: isDeletingIntentItemsLoading
    } = useSaveCatalog();

    // Effects
    useEffect(() => {
        props.intentCatalog && setAgent(mergedAgents?.find(agent => agent.id === agentRef));
    }, [props.intentCatalog, setAgent, getAgentReferenceFromIntentCatalog]);

    useEffect(() => {
        if (createCatalogItemError) {
            toast({
                description: 'There was an error in changing the agent.',
                isClosable: true,
                position: 'bottom-right',
                status: 'error',
                title: 'Error: Setting Agent'
            });
        }
    }, [toast, updateIntentCatalogPivotFieldError]);

    useEffect(() => {
        if (renameIntentError) {
            toast({
                description: 'There was an error in renaming the intent.',
                isClosable: true,
                position: 'bottom-right',
                status: 'error',
                title: 'Error: Renaming Intent'
            });
        }
    }, [toast, renameIntentError]);

    // Handlers
    const handleSelectIntent = (selectedIntent: string) => {
        props.onSelectIntent && props.onSelectIntent(props.intentCatalog?.id, selectedIntent);
    };

    const handleSearchFilter = (filter: string | undefined) => {
        setSearchFilter(filter);
    };

    const handleCreateIntent = async (intent: ConversationalIntent) => {
        // TODO: Network request to update catalog
        if (props.intentCatalog) {
            addNewIntentItem({ catalogId: props.intentCatalog?.id, item: intent }).then(obj => {
                if (obj) {
                    toast({
                        description: `Successfully created intent ${intent.intentName}.`,
                        isClosable: true,
                        position: 'bottom-right',
                        status: 'success',
                        title: 'Success: Created an Intent'
                    });
                }
            });
        }
    };

    const handleMultiSelectIntent = (selectedIntents: string[]) => {
        if (props.intentCatalog?.items?.length === multiSelectedIntents.length) {
            setMultiSelectedIntents(without(multiSelectedIntents, ...selectedIntents));
        } else if (selectedIntents.length === 1) {
            const idx = findIndex(multiSelectedIntents, intent => intent === selectedIntents[0]);
            const arr = [...multiSelectedIntents];

            if (idx !== -1) {
                arr.splice(idx, 1);
            } else {
                arr.push(selectedIntents[0]);
            }

            setMultiSelectedIntents(arr);
        } else if (selectedIntents.length > 1) {
            setMultiSelectedIntents(union(multiSelectedIntents, selectedIntents));
        }
    };

    const handleDeleteIntents = async (intents: string[]) => {
        if (intents.length > 0 && props.intentCatalog) {
            const newCatalog: ICatalog = {
                ...props.intentCatalog,
                items: props.intentCatalog.items?.filter(item => !intents.includes(item.id))
            };
            deleteIntentItems({ catalog: newCatalog }).then(res => {
                if (res) {
                    setMultiSelectedIntents([]);
                    toast({
                        description: `Successfully deleted ${multiSelectedIntents.length} intents.`,
                        isClosable: true,
                        position: 'bottom-right',
                        status: 'success',
                        title: 'Success: Deleted Intents'
                    });
                }
            });
        }
    };

    const handleUpdateAgentPivotField = (agentId: string) => {
        if (props.intentCatalog) {
            switch (props.intentCatalog.objectType) {
                case MotiveTypes.WATSON_INTENT:
                    const targetAssistant = mergedAgents.find(agent => agent.id === agentId) as IWatsonAssistant;

                    const newCatalog: ICatalog<IWatsonIntent> = {
                        ...props.intentCatalog,
                        items: (props.intentCatalog as ICatalog<IWatsonIntent>).items?.map(item => {
                            return {
                                ...item,
                                assistantReference: {
                                    objectId: targetAssistant?.id,
                                    objectType: MotiveTypes.WATSON_ASSISTANT
                                }
                            };
                        })
                    };

                    updateIntentCatalogPivotField({ catalog: newCatalog }).then(res => {
                        if (res) {
                            toast({
                                description: `Successfully changed agent to ${targetAssistant?.name ?? 'None'}.`,
                                isClosable: true,
                                position: 'bottom-right',
                                status: 'success',
                                title: 'Success: Changed Agent'
                            });
                        }
                    });

                    break;
            }
        }
    };

    const handleUpdateIntent = async (intentId: string, name?: string, description?: string) => {
        switch (props.intentCatalog?.objectType) {
            case MotiveTypes.WATSON_INTENT:
                const targetItem = props.intentCatalog?.items?.find(item => item.id === intentId) as IWatsonIntent;
                if (
                    targetItem &&
                    (targetItem.intentName !== name || targetItem.description !== description) &&
                    name != ''
                ) {
                    props.intentCatalog &&
                        (await renameIntentCall({
                            catalog: {
                                ...props.intentCatalog,
                                items: (props.intentCatalog.items as IWatsonIntent[])?.map(item => {
                                    if (item.id === intentId) {
                                        return {
                                            ...item,
                                            intentName: name ?? item.intentName,
                                            description: description ?? item.description
                                        };
                                    }

                                    return item;
                                })
                            }
                        }).then(res => {
                            if (res) {
                                toast({
                                    description: `Successfully updated intent.`,
                                    isClosable: true,
                                    position: 'bottom-right',
                                    status: 'success',
                                    title: 'Success: Updated Intent'
                                });
                            }
                        }));
                }

                break;
        }
    };

    if (props.children) {
        return (
            <>
                {props.children({
                    intentCatalog: props.intentCatalog,
                    agents: mergedAgents,
                    agent: agent,
                    searchFilter: searchFilter,
                    multiSelectedIntents: multiSelectedIntents,
                    onSelectIntent: handleSelectIntent,
                    onCreateIntent: handleCreateIntent,
                    onUpdateIntent: handleUpdateIntent,
                    onUpdateAgent: handleUpdateAgentPivotField,
                    onSearchIntent: handleSearchFilter,
                    onDeleteIntents: handleDeleteIntents,
                    onMultiSelectIntents: handleMultiSelectIntent,
                    isCreateIntentLoading: isAddNewIntentItemLoading,
                    isDeleteIntentLoading: isDeletingIntentItemsLoading,
                    isUpdatePivotFieldLoading: isUpdateIntentCatalogPivotFieldLoading
                })}
            </>
        );
    }

    return (
        <DashboardIntentManagement
            intentCatalog={props.intentCatalog}
            agents={mergedAgents}
            agent={agent}
            searchFilter={searchFilter}
            multiSelectedIntents={multiSelectedIntents}
            onSelectIntent={handleSelectIntent}
            onCreateIntent={handleCreateIntent}
            onUpdateIntent={handleUpdateIntent}
            onUpdateAgent={handleUpdateAgentPivotField}
            onSearchIntent={handleSearchFilter}
            onDeleteIntents={handleDeleteIntents}
            onMultiSelectIntents={handleMultiSelectIntent}
            isCreateIntentLoading={isAddNewIntentItemLoading}
            isDeleteIntentLoading={isDeletingIntentItemsLoading}
            isUpdatePivotFieldLoading={isUpdateIntentCatalogPivotFieldLoading}
        />
    );
};
