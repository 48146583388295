import { IFieldEditorProps, IObjectEditorProps, ObjectEditor } from '../../containers/objectEditor';
import { IScriptObjectModel, FieldTypes } from '../../shared/motive/models/ScriptObjectModel';
import React, { useState } from 'react';
import { useObjectDefinition } from '../../shared/motive/hooks/useScriptEditorInfo';
import { Button, ButtonVariant } from '../../core-ui/button';
import { Dropdown } from '../../core-ui/dropdown';
import { IDropdownOption } from '../../core-ui/dropdown/Dropdown';
import { removeFieldArrayItem, addFieldArrayItem } from '../../util/ScriptUtils';
import { getSmartTypeName } from '../../util/ObjectDefinitionsUtils';
import {
    useTypeDefinitions,
    useObjectDefinitions,
    useObjectTypeAndImplementors
} from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { Size } from '../../core-ui/constants/Size';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { useStyle } from '../../shared/hooks/useStyle';
import { useCreateScriptObject } from '../../shared/motive/hooks/useCreateScriptObject';
import { ObjectEditorBox, IObjectEditorBoxTool } from '../objectEditorBox';
import { flexStyle, buttonStyle, fieldElementStyle } from './FieldObjectEditor.style';

const AddObjectEditorElement: React.FC<IFieldEditorProps<IScriptObjectModel> & { implementors: string[] }> = props => {
    const objectDefinition = useObjectDefinition(props.type);
    const createScriptObject = useCreateScriptObject();
    const token = useStyle();
    const typeDefs = useObjectDefinitions();
    const [quickAddType, setQuickAddType] = useState<string | undefined>(undefined);

    const addObj = (type: string) => {
        setQuickAddType(type);

        const newObj = createScriptObject(type);

        props.onChange(newObj);
    };

    if (props.implementors && props.implementors.length > 1) {
        const opts: IDropdownOption[] = props.implementors.map(i => ({
            label: getSmartTypeName(i, typeDefs),
            value: i
        }));
        const quickAdd = quickAddType ?? (opts[0].value as string);

        return (
            <div css={flexStyle}>
                <Dropdown options={opts} onChange={_t => addObj(_t)}></Dropdown>
                <Button
                    css={buttonStyle(token)}
                    size={Size.SMALL}
                    icon={IconTypes.PLUS}
                    variant={ButtonVariant.CIRCULAR}
                    onClick={() => {
                        addObj(quickAdd);
                    }}
                />
            </div>
        );
    } else if (
        objectDefinition.isAbstract &&
        objectDefinition.implementors &&
        objectDefinition.implementors.length === 1
    ) {
        const type = objectDefinition.implementors[0];

        return (
            <Button
                size={Size.SMALL}
                icon={IconTypes.PLUS}
                variant={ButtonVariant.CIRCULAR}
                onClick={() => addObj(type)}
            />
        );
    } else if (!objectDefinition.isAbstract) {
        return (
            <Button
                size={Size.SMALL}
                icon={IconTypes.PLUS}
                variant={ButtonVariant.CIRCULAR}
                onClick={() => addObj(props.type)}
            />
        );
    } else {
        return <></>;
    }
};

const FieldObjectEditorElement: React.FC<IFieldEditorProps<IScriptObjectModel> & {
    implementors: string[];
    tools?: IObjectEditorBoxTool[];
}> = props => {
    const typeDefs = useTypeDefinitions();

    var fieldObj = props.value as IScriptObjectModel;

    if (fieldObj === undefined || fieldObj === null) {
        return <>object should not be null!</>;
    }

    // TODO: need to propagate the changes properly...needs a bit of thought--we need the top-level object props and
    // not just the field object props. I think we can just add these? This might not be very "react"-y though.
    const objProps: IObjectEditorProps = {
        ...props,
        showFields: undefined, // Reset these from the caller
        hideFields: undefined,
        hideAdvanced: false,
        type: fieldObj.type,
        value: fieldObj,
        depth: (props.parentObjectEditorProps?.depth ?? 0) + 1,
        onChange: props.parentObjectEditorProps.onChange
    };

    const deleteObj = (): void => {
        props.onChange(undefined);
    };

    const hasMultipleImplementors = props.implementors && props.implementors.length > 1;

    const showClose = props.fieldDefinition.isArray || props.fieldDefinition.isNullable || hasMultipleImplementors;

    // TODO: look up field obj type title
    return (
        <div css={fieldElementStyle}>
            <ObjectEditorBox
                title={hasMultipleImplementors ? getSmartTypeName(fieldObj.type, typeDefs) : undefined}
                onClickDelete={showClose ? deleteObj : undefined}
                depth={objProps.depth as number}
                tools={props.tools}
            >
                <ObjectEditor key={fieldObj.id} {...objProps} />
            </ObjectEditorBox>
        </div>
    );
};

export const FieldObjectEditor: React.FC<IFieldEditorProps<IScriptObjectModel | IScriptObjectModel[]>> = (
    props: IFieldEditorProps<IScriptObjectModel | IScriptObjectModel[]>
) => {
    const implementors = props.overrideImplementors ?? useObjectTypeAndImplementors(props.type, false);

    const isArray = props.fieldDefinition.isArray;
    const array = props.value as IScriptObjectModel[];

    const handleAddItem = (item: FieldTypes) => {
        addFieldArrayItem(
            newArr => {
                props.onChange(newArr);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            props.value as any[],
            item
        );
    };

    const handleMoveIndex = (item: FieldTypes, fromIndex: number, toIndex: number) => {
        const newArray = [...array];

        const fromVal = newArray[fromIndex];
        newArray[fromIndex] = newArray[toIndex];
        newArray[toIndex] = fromVal;

        props.onChange(newArray);
    };

    return (
        <>
            {!isArray && (
                <>
                    {props.value && (
                        <FieldObjectEditorElement
                            {...(props as IFieldEditorProps<IScriptObjectModel>)}
                            implementors={implementors}
                        />
                    )}
                    {!props.value && (
                        <AddObjectEditorElement
                            {...(props as IFieldEditorProps<IScriptObjectModel>)}
                            implementors={implementors}
                        />
                    )}
                </>
            )}
            {isArray && (
                <>
                    <AddObjectEditorElement
                        {...props}
                        value={undefined}
                        path={props.path}
                        onChange={handleAddItem}
                        implementors={implementors}
                    />
                    {array &&
                        array.map((item, idx) => {
                            const path = `${props.path}.${idx}`;
                            const handleChange = () => {
                                // Only called for delete..?
                                removeFieldArrayItem(
                                    newArr => {
                                        props.onChange(newArr);
                                    },
                                    array,
                                    idx
                                );
                            };

                            const tools: IObjectEditorBoxTool[] | undefined = array.length === 1 ? undefined : [];

                            if (tools && idx > 0) {
                                tools.push({
                                    icon: IconTypes.UP,
                                    key: 'up',
                                    onClick: () => {
                                        handleMoveIndex(item, idx, idx - 1);
                                    }
                                });
                            }

                            if (tools && idx < array.length - 1) {
                                tools.push({
                                    icon: IconTypes.DOWN,
                                    key: 'down',
                                    onClick: () => {
                                        handleMoveIndex(item, idx, idx + 1);
                                    }
                                });
                            }

                            return (
                                <FieldObjectEditorElement
                                    {...props}
                                    key={idx}
                                    value={item}
                                    path={path}
                                    onChange={handleChange}
                                    implementors={implementors}
                                    tools={tools}
                                />
                            );
                        })}
                </>
            )}
        </>
    );
};
