import { ICatalog } from '../../../shared/motive/models/Catalog';
import { IScriptObjectModel } from '../../../shared/motive/models/ScriptObjectModel';

export type CatalogActions = ReturnType<
    | typeof catalogAppendAction
    | typeof catalogUpdateAction
    | typeof catalogSetAction
    | typeof catalogSetByTypesAction
    | typeof catalogSetByIdAction
    | typeof catalogDeleteAction
    | typeof catalogRenameAction
>;

export const catalogAppendAction = (catalog: ICatalog<IScriptObjectModel>) => {
    return {
        type: 'catalog/append',
        catalog
    } as const;
};

export const catalogUpdateAction = (catalog: ICatalog<IScriptObjectModel>) => {
    return {
        type: 'catalog/update',
        catalog
    } as const;
};

export const catalogSetAction = (catalogs: ICatalog<IScriptObjectModel>[]) => {
    return {
        type: 'catalog/set',
        catalogs
    } as const;
};

export const catalogSetByIdAction = (catalog: ICatalog<IScriptObjectModel>) => {
    return {
        type: 'catalog/set/id',
        catalog
    } as const;
};

export const catalogSetByTypesAction = (catalogType: string[], catalogs: ICatalog<IScriptObjectModel>[]) => {
    return {
        type: 'catalog/set/types',
        catalogType,
        catalogs
    } as const;
};

export const catalogDeleteAction = (id: string) => {
    return {
        type: 'catalog/delete',
        id
    } as const;
};

export const catalogRenameAction = (id: string, title: string, name: string) => {
    return {
        type: 'catalog/rename',
        id,
        title,
        name
    } as const;
};
