import React from 'react';
import { IObjectEditorRendererProps } from '../../containers/objectEditor';
import { useObjectDefinition } from '../../shared/motive/hooks/useScriptEditorInfo';
import { Dropdown } from '../../core-ui/dropdown';
import { createObjectReference } from '../../util/MotiveUtils';

import { DynamicValueComparerEditor } from '../dynamicValueComparerEditor';
import { IVariableValueCondition } from '../../shared/motive/models/IVariableValueCondition';
import { useScriptAndGlobalVariables } from '../../shared/motive/hooks/useScriptAndGlobalVariables';
import { useGroupedScriptVariableDropdownOptions } from '../../shared/motive/hooks/useGroupedScriptAndGlobalVariables/useGroupedScriptAndGlobalVariables';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IVariableReferenceConditionEditorProps extends IObjectEditorRendererProps<IVariableValueCondition> {}

const VARIABLE_REFERENCE_FIELD = 'variableReference';
const NEGATE_RESULT_FIELD = 'negateResult';
const COMPARER_FIELD = 'comparer';

export const VariableValueConditionEditor: React.FC<IVariableReferenceConditionEditorProps> = (
    props
): React.ReactElement => {
    const objectDefinition = useObjectDefinition(props.type);
    const value = props.value as IVariableValueCondition;
    const varMap = useScriptAndGlobalVariables();
    const opts = useGroupedScriptVariableDropdownOptions();

    const negateDef = objectDefinition.fieldDefinitions[NEGATE_RESULT_FIELD];
    const variableRefDef = objectDefinition.fieldDefinitions[VARIABLE_REFERENCE_FIELD];
    const comparerDef = objectDefinition.fieldDefinitions[COMPARER_FIELD];

    const selectedVariable = value?.variableReference?.objectId ? varMap[value.variableReference.objectId] : undefined;

    const handleVariableChanged = (varId: string | string) => {
        const path = `${props.path}.${VARIABLE_REFERENCE_FIELD}`;
        var selectedVar = varMap[varId];

        props.onChange(
            path,
            selectedVar ? createObjectReference(selectedVar.type, selectedVar.id, selectedVar.name) : undefined
        );
    };

    const renderVariables = () => {
        return (
            <Dropdown
                grouped={true}
                instructions={'Select Variable'}
                options={opts}
                value={selectedVariable?.id}
                onChange={handleVariableChanged}
            />
        );
    };

    return (
        <>
            {props.renderField(props, negateDef, value.negateResult)}
            {props.renderFieldEditor(
                variableRefDef.editorInfo?.label ?? variableRefDef.name,
                renderVariables,
                value?.variableReference
            )}
            {selectedVariable && (
                <DynamicValueComparerEditor
                    {...props}
                    valueDefinition={selectedVariable.valueDefinition}
                    comparerFieldDefinition={comparerDef}
                />
            )}
        </>
    );
};
