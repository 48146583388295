import { getScriptVariablesMap } from '../../../../util/ScriptDynamicsUtil';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SelectScriptEditorCurrentScript } from '../../../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';

export const useScriptVariables = () => {
    const script = useSelector(SelectScriptEditorCurrentScript);
    return script.variables;
};

export const useScriptVariableMap = () => {
    const vars = useScriptVariables();

    const varMap = useMemo(() => {
        return getScriptVariablesMap(vars);
    }, [vars]);

    return varMap;
};
