import { DraggableProvidedDraggableProps, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { CSSProperties } from 'react';

export const getStyleOverrideNoDrop = (
    style: DraggableProvidedDraggableProps['style'],
    snapshot: DraggableStateSnapshot,
    enableAnimDrop?: boolean
) => {
    const dragProperties: CSSProperties = snapshot.isDragging ? {} : { transform: 'none' };

    const isDropAnimatingProperties =
        !snapshot.isDropAnimating || enableAnimDrop
            ? {}
            : {
                  transitionDuration: '0.00001s'
              };

    const newStyle: CSSProperties = {
        ...style,
        ...isDropAnimatingProperties,
        ...dragProperties
    };

    return newStyle;
};
