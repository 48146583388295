import { httpClient } from './HttpClient';
import { SPACE_ENDPOINT } from '../constants/ApiEndpoint';
import {
    ITextToSpeechRequest,
    ITextToSpeechPreviewResponse,
    ITextToSpeechCreateMediaRequest
} from '../models/TextToSpeechRequest';
import { IHostedMediaSource } from '../models/MediaItem';

import { Dispatch } from 'redux';

import {
    IMediaAction,
    mediaUploadCompleteAction,
    mediaUploadBeginAction,
    mediaUploadErrorAction
} from '../../../redux/spaceKeyed/media/MediaActions';
import { uniqueMotiveId } from '../../../util/MotiveUtils';
import { textToSpeechSetAction } from '../../../redux/spaceKeyed/textToSpeech/TextToSpeechActions';

export const textToSpeechPath = (spaceName: string) => `${SPACE_ENDPOINT}/${spaceName}/textToSpeech`;

export const textToSpeechPreviewPath = (spaceName: string) => {
    return `${textToSpeechPath(spaceName)}/preview`;
};

export const textToSpeechMediaPath = (spaceName: string, overwrite: boolean) => {
    var url = `${textToSpeechPath(spaceName)}/media`;

    if (overwrite) {
        url += '?overwrite=true';
    }

    return url;
};

export const textToSpeechVoicesPath = (spaceName: string, languageCode?: string) => {
    let url = `${textToSpeechPath(spaceName)}/voices`;

    if (languageCode) {
        url += `?languageCode=${languageCode}`;
    }

    return url;
};

export const textToSpeechPreviewCall = async (
    spaceName: string,
    request: ITextToSpeechRequest
): Promise<ITextToSpeechPreviewResponse> => {
    try {
        const response = await httpClient.post<ITextToSpeechPreviewResponse>(
            `${textToSpeechPreviewPath(spaceName)}`,
            request
        );

        return response.data;
    } catch (e) {
        throw e;
    }
};

export const createTextToSpeechMediaCall = async (
    spaceName: string,
    request: ITextToSpeechCreateMediaRequest,
    dispatch: (param: IMediaAction) => void
): Promise<IHostedMediaSource | undefined> => {
    const fileId = uniqueMotiveId();

    try {
        dispatch(mediaUploadBeginAction(fileId, undefined));

        const response = await httpClient.post<IHostedMediaSource>(
            `${textToSpeechMediaPath(spaceName, request.overwrite)}`,
            request
        );

        if (response.status === 200 && response.data) {
            dispatch(mediaUploadCompleteAction(fileId, response.data as IHostedMediaSource));

            return response.data;
        } else {
            dispatch(mediaUploadErrorAction(fileId, new Error()));
        }
    } catch (error) {
        dispatch(mediaUploadErrorAction(fileId, error));

        throw error;
    }
};

export const getTextToSpeechVoicesCall = async (
    spaceName: string,
    dispatch: Dispatch,
    languageCode?: string
): Promise<void> => {
    try {
        const response = await httpClient.get(textToSpeechVoicesPath(spaceName, languageCode));
        dispatch(textToSpeechSetAction(response.data));
    } catch (e) {
        throw e;
    }
};
