import { IScriptInfo, IScriptVariable } from '../../models/Script';
import { useGetAllCatalogsByType } from '../useCatalogs';
import { MotiveTypes } from '../../../../constants/MotiveTypes';
import { useMemo } from 'react';
import { ITypeDefinition, IObjectDefinition } from '../../models/TypeDefinition';
import { isTypeOrImplementor, isNumberType } from '../../../../util/MotiveUtils';
import { useTypeDefinition } from '../useScriptEditorInfo';
import { IDropdownOption, EMPTY_DROPDOWN_OPTION } from '../../../../core-ui/dropdown';
import { useSelector } from 'react-redux';
import { SelectScriptEditorCurrentScript } from '../../../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';

export interface IScriptGlobalVariables {
    scriptInfo: IScriptInfo;
    globalVariables: IScriptVariable[];
}

export interface IGroupedVariables {
    currentScriptVariables?: IScriptVariable[];
    scriptGlobalVariables: IScriptGlobalVariables[];
}

function isType(typeDefinition: ITypeDefinition, variable: IScriptVariable) {
    if (typeDefinition.dataType === 'object') {
        const objDef = typeDefinition as IObjectDefinition;

        // We want to know if objDef *implements* the variable type
        return isTypeOrImplementor(variable.valueDefinition.typeName, objDef);
    } else {
        return (
            (isNumberType(typeDefinition.name) && isNumberType(variable.valueDefinition.typeName)) ||
            typeDefinition.name === variable.valueDefinition.typeName
        );
    }
}

export const useGroupedScriptAndGlobalVariables = (typeDefinition?: ITypeDefinition) => {
    const script = useSelector(SelectScriptEditorCurrentScript);
    const { data: scriptCats } = useGetAllCatalogsByType(MotiveTypes.MOTIVE_SCRIPT);

    return useMemo(() => {
        const scriptGlobals: IGroupedVariables = {
            currentScriptVariables: script.variables?.filter(v => !typeDefinition || isType(typeDefinition, v)),
            scriptGlobalVariables: []
        };

        scriptCats.forEach(cat => {
            cat.items?.forEach(obj => {
                const _script = obj as IScriptInfo;

                if (obj.id === script.id) {
                    return;
                }

                const globals = _script?.variables?.filter(
                    v => v.isGlobal && (!typeDefinition || isType(typeDefinition, v))
                );

                if (globals && globals.length > 0) {
                    scriptGlobals.scriptGlobalVariables.push({
                        scriptInfo: { ..._script },
                        globalVariables: globals
                    });
                }
            });
        });

        return scriptGlobals;
    }, [script, scriptCats, typeDefinition]);
};

export const useGroupedScriptVariableDropdownOptions = (typeName?: string) => {
    const typeDefinition = useTypeDefinition(typeName ?? '');

    const variables = useGroupedScriptAndGlobalVariables(typeDefinition);

    const currVarOpts = variables.currentScriptVariables
        ? variables.currentScriptVariables.map(v => {
              return {
                  value: v.id,
                  label: `${v.name} (${v.id})`
              };
          })
        : undefined;

    const dropOptions: IDropdownOption[] = currVarOpts
        ? [EMPTY_DROPDOWN_OPTION, ...currVarOpts]
        : [EMPTY_DROPDOWN_OPTION];

    if (variables.scriptGlobalVariables) {
        variables.scriptGlobalVariables.forEach(sv => {
            sv.globalVariables.forEach(v => {
                dropOptions.push({
                    label: `${v.name} (${v.id})`,
                    value: v.id,
                    group: sv.scriptInfo.name
                });
            });
        });
    }

    return dropOptions;
};
