import { css } from '@emotion/core';

export const baseStyle = css`
    flex: 1 1 0px;
    min-width: 0;
    overflow: hidden;
    margin: 0px 5px;
`;

export const stackPanelItem = (hidden: boolean) => css`
    width: ${hidden ? '0px' : '100%'};
    display: ${hidden ? 'none' : 'block'};
    height: 100%;
`;

export const uiStackContainerStyleSingleItem = css`
    flex: 1;
    transition: transform 0.6s ease;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    width: 100%;
    height: 100%;
`;

export const uiStackContainerStyle = (stackLength: number, selectedIndex: number) => css`
    flex: 1;
    transition: transform 0.6s ease;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    transform: translateX(100%);

    ${stackLength > 0 &&
        `
        transform: translateX(-${(100 / stackLength) * (selectedIndex - 1)}%);      
        width: ${100 * stackLength}%; //  50 * (stackLength - 1)
        `}
`;
