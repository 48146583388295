import React from 'react';
import ReactModal from 'react-modal';
import { Icon } from '../icon/Icon';
import { IconTypes } from '../constants/IconTypes';
import { modalCloseButtonStyle } from './BaseModal.style';

export interface IBaseModalProps {
    children?: React.ReactNode;
    style?: ReactModal.Styles;
    showCloseButton?: boolean;
    isOpen: boolean;
    onCloseModal: () => void;
}

/**
 * Wrapper around react-modal's modal. Extend this component's props to provide more specialized modals for specific purposes.
 * Note: Some styling comes from modal.css to modify the packages style
 */
export const BaseModal: React.FC<IBaseModalProps> = ({
    isOpen,
    onCloseModal,
    children,
    style,
    showCloseButton = true
}): React.ReactElement => {
    return (
        <div onClick={e => e.stopPropagation()}>
            <ReactModal
                isOpen={isOpen}
                ariaHideApp={false}
                onRequestClose={onCloseModal}
                style={{
                    ...style,
                    content: {
                        margin: '0 auto',
                        height: 'fit-content',
                        top: '30%',
                        border: 'none',
                        overflow: 'visible',
                        maxWidth: 'max-content',
                        ...style?.content
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)'
                    }
                }}
            >
                {showCloseButton && (
                    <div css={modalCloseButtonStyle}>
                        <Icon icon={IconTypes.CLOSE} onClick={onCloseModal}></Icon>
                    </div>
                )}
                {children}
            </ReactModal>
        </div>
    );
};
