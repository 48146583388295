import React, { useState, useEffect, Fragment } from 'react';
import { anchorContainerStyle, menuContainerStyle, menuItemStyle, menuDividerStyle } from './ContextualMenu.style';
import { Divider } from '../divider';
import { useClickOutElement } from '../../shared/hooks/useClickOutElement/useClickOutElement';
import { useStyle } from '../../shared/hooks/useStyle';

export interface IContextualMenuItem {
    children?: React.ReactNode;
    onPress?: () => void;
    openOnPress?: boolean;
}

export interface IContextualMenuProps {
    menuItems: IContextualMenuItem[];
    active?: boolean;
    onCloseEnd?: () => void;
    alignLeft?: boolean;
    className?: string;
}

export const ContextualMenu: React.FC<IContextualMenuProps> = ({
    children,
    menuItems,
    onCloseEnd,
    alignLeft = true,
    active: activeProp = false,
    className
}) => {
    const [active, setActive] = useState(activeProp);
    const token = useStyle();

    const handleOnClickOut = (): void => {
        if (active && activeProp) {
            setActive(false);

            if (onCloseEnd) {
                onCloseEnd();
            }
        }
    };

    const anchorRef = useClickOutElement<HTMLDivElement>(handleOnClickOut, true);

    useEffect((): void => {
        setActive(activeProp);

        if (active && !activeProp && onCloseEnd) {
            onCloseEnd();
        }
    }, [activeProp]);

    return (
        <div css={anchorContainerStyle}>
            {children}
            {menuItems && active && (
                <div ref={anchorRef} className={className} css={menuContainerStyle(token, alignLeft)}>
                    {menuItems.map((mItem, index) => (
                        <Fragment key={index}>
                            {index > 0 && <Divider css={menuDividerStyle} />}
                            <div
                                css={menuItemStyle(token, !!mItem.onPress)}
                                onClick={e => {
                                    if (mItem.onPress) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        mItem.onPress && mItem.onPress();
                                        if (!mItem.openOnPress) {
                                            onCloseEnd && onCloseEnd();
                                        }
                                    }
                                }}
                            >
                                {mItem.children}
                            </div>
                        </Fragment>
                    ))}
                </div>
            )}
        </div>
    );
};
