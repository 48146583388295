/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import './style.css';

import {
    nodeTitleStyle,
    frameThumbContentContainerStyle,
    iconStyle,
    nodeWrapperStyle,
    nodeInputStyle,
    verticalDividerStyle,
    verticalDividerParent,
    frameTitleIconWrapper,
    frameTreeActions,
    frameActionButtonStyle,
    iconStyleDisabled,
    frameTreeContainerStyle,
    iconStyleCondition,
    iconStyleDynamic,
    variableNumber,
    eventLinkBoxStyle,
    overflowEllipsisStyle,
    iconEnabledStyle,
    frameThumbGrid,
    frameThumbOnDragConditionStyle,
    frameThumbOnDragResourceStyle,
    signalledFrameStyle,
    signalledResourceIconStyle
} from './FrameTree.style';
import SortableTree, {
    OnMovePreviousAndNextLocation,
    FullTree,
    NodeData,
    TreeItem,
    OnVisibilityToggleData
} from 'react-sortable-tree';
import { useStyle } from '../../shared/hooks/useStyle/useStyle';
import { ButtonVariant, Button } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Size } from '../../core-ui/constants/Size';
import { EditableText } from '../../core-ui/editableText';
import { IFrame, IResourceWrapper, FRAME_PRE_CONDITION, IConditionWrapper } from '../../shared/motive/models/Script';
import { IToken } from '../../shared/contexts/styleContext';

import { useDragContext } from '../../hooks/useDragContext';
import { Icon } from '../../core-ui/icon';
import { IconProp, IconName } from '@fortawesome/fontawesome-svg-core';
import { useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';

import { library } from '@fortawesome/fontawesome-svg-core';
import { TooltipBox } from '../../core-ui/tooltip';
import { useCurrentLanguageSettings } from '../../shared/motive/stores/editorLocale/EditorLocale';
import { useChooseScriptScopeDispatch, useChooseScriptScopeState } from '../../hooks/useChooseScriptScope';
import { ScriptActionType, ScriptReducerAction } from '../../shared/motive/reducers/ScriptEditorReducers';
import { Logger } from '../../util/logger';
import { ScriptEditorHistoryButtons, BAR_HEIGHT } from '../scriptEditor/ScriptEditorHistoryButtons';
import { getResourceIcons, getConditionIcons } from '../../util/IconUtils';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { eachCondition, VersionDates } from '../../util/ScriptUtils';
import { IScriptObjectEventCondition } from '../../shared/motive/models/IScriptObjectEventCondition';
import { IScriptEventCondition } from '../../shared/motive/models/IScriptEventCondition';
import { IScriptEvent } from '../../shared/motive/models/IScriptEvent';
import { Drop } from '../_common/DragAndDrop/Drop/Drop';
import { CloneDrag } from '../_common/DragAndDrop/CloneDrag/CloneDrag';
import { ScrollZone } from '../../core-ui/scrollZone/ScrollZone';

import { useDispatch, useSelector } from 'react-redux';
import { IObjectInstanceDrag } from '../_common/DragAndDrop/DragModels/DragModels';
import {
    onDragCreateCondition,
    onDragCreateConditionOfInstance
} from '../_common/DragAndDrop/OnDragHandlers/ConditionActions';
import { onDragMoveResource } from '../_common/DragAndDrop/OnDragHandlers/ResourceActions';
import {
    DRAG_ITEM_TYPE_CREATE_OBJECT_CONDITION,
    DRAG_ITEM_TYPE_RESOURCE,
    DRAG_ITEM_TYPE_CREATE_CONDITION,
    generateDropId,
    DROPPABLE_ID_FRAME_THUMB
} from '../../constants/DragAndDrop';
import { IScriptEditorSessionDependentProps } from '../scriptEditor/ScriptLoader';
import { selectSignalledResource } from '../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';
import { IExternalHighlight } from '../../redux/spaceKeyed/scriptEditor/ScriptEditorReducer';
import { useCurrentProject } from '../../shared/motive/hooks/useCurrentProject';
import { useCurrentProjectConfig } from '../../redux/spaceKeyed/projectConfig/ProjectConfigSelectors';

library.add(fas);

export interface IFrameIdMap {
    [frameId: string]: boolean;
}

export type ChooseMode = 'Resource' | 'Frame' | undefined;

const FrameNodeTitle: React.FC<{
    frame: IFrame;
    handleRename: (frameId: string, newName: string) => void;
}> = ({ frame, handleRename }): React.ReactElement => {
    const handleEditComplete = (value: string) => {
        if (frame.name !== value) {
            handleRename(frame.id, value);
        }
    };
    return (
        <EditableText
            wrapperStyle={nodeWrapperStyle}
            textStyle={nodeTitleStyle}
            inputStyle={nodeInputStyle}
            size={Size.SMALL}
            defaultValue={frame.name}
            placeholder={frame.id}
            onEditComplete={handleEditComplete}
        />
    );
};

interface IGenerateTreeArgs {
    frame: IFrame;
    token: IToken;
    currentDragType?: string;
    collapsedFrames: IFrameIdMap;
    scriptDispatch: React.Dispatch<ScriptReducerAction>;
    selectedFrame: IFrame | null;
    checkParentIsEnabled: () => boolean;
}

interface IFrameThumbProps {
    frame: IFrame;
    selectedFrame: IFrame | null;
    scriptDispatch: React.Dispatch<ScriptReducerAction>;
    checkIsEnabled: () => boolean;
}

interface IFrameThumbRenderProps {
    frame: IFrame;
    isSelected: boolean;
    isEnabled: boolean;
    isExclusive: boolean;
    triggersCurrentFrame: boolean;
    triggeredByCurrentFrame: boolean;
    signalledResource?: IExternalHighlight;
}

const FrameThumbRender = React.memo(function FrameThumbRender(props: IFrameThumbRenderProps) {
    const scriptDispatch = useDispatch();
    const { isSelected, frame, triggeredByCurrentFrame, triggersCurrentFrame } = props;
    const chooseScriptScopeDispatch = useChooseScriptScopeDispatch();
    const typeDefinitions = useObjectDefinitions();
    const language = useCurrentLanguageSettings();
    const token = useStyle();

    const config = useCurrentProjectConfig();
    const isEnumRef = VersionDates.checkUseEnumRef(config?.typeVersionDate);

    const resourceIcons = useMemo(() => getResourceIcons(typeDefinitions, language, frame.resources), [
        typeDefinitions,
        language,
        frame.resources
    ]);
    const conditionWrapper = frame[FRAME_PRE_CONDITION] as IConditionWrapper;
    const condition = (frame[FRAME_PRE_CONDITION] as IConditionWrapper)?.condition || frame[FRAME_PRE_CONDITION];
    const preConditionIcons = useMemo(() => getConditionIcons(typeDefinitions, condition), [
        frame[FRAME_PRE_CONDITION]
    ]);
    const varCount = frame?.definedVariables ? Object.keys(frame.definedVariables).length : 0;
    const { state, chooseTypes, chooseTypePrettified } = useChooseScriptScopeState();

    const chooseMode = state === 'choosing' ? chooseTypePrettified : undefined;

    const frameThumbDropList = [
        {
            dragType: DRAG_ITEM_TYPE_CREATE_OBJECT_CONDITION,
            onDragEnd: onDragCreateConditionOfInstance(scriptDispatch, frame.id, typeDefinitions, isEnumRef),
            onDragOverStyle: frameThumbOnDragConditionStyle(token)
        },
        {
            dragType: DRAG_ITEM_TYPE_RESOURCE,
            onDragEnd: onDragMoveResource(scriptDispatch, frame),
            onDragOverStyle: frameThumbOnDragResourceStyle(token)
        },
        {
            dragType: DRAG_ITEM_TYPE_CREATE_CONDITION,
            onDragEnd: onDragCreateCondition(scriptDispatch, frame.id, typeDefinitions),
            onDragOverStyle: frameThumbOnDragConditionStyle(token)
        }
    ];

    const handleResourceClick = (resourceWrapper: IResourceWrapper) => {
        chooseScriptScopeDispatch({
            actionType: 'choose',
            chosenValue: resourceWrapper.resource
        });
    };

    const handleFrameAdd = (parentFrameId: string) => {
        scriptDispatch({
            type: ScriptActionType.FRAME_ADD,
            targetFrameId: parentFrameId
        });
    };

    const handleFrameRemove = (frameId: string) => {
        scriptDispatch({
            type: ScriptActionType.FRAME_REMOVE,
            targetFrameId: frameId
        });
    };

    const handleFrameRename = (frameId: string, newName: string): void => {
        scriptDispatch({
            type: ScriptActionType.FRAME_RENAME,
            targetFrameId: frameId,
            newName: newName
        });
    };

    const handleFrameClicked = () => {
        if (state === 'choosing' && chooseTypePrettified === 'Frame') {
            if (!frame.id) {
                Logger.error('no framed id supplied, canceling choose.');
                chooseScriptScopeDispatch({
                    actionType: 'cancelChoose'
                });
                return;
            }

            chooseScriptScopeDispatch({
                actionType: 'choose',
                chosenValue: frame as IScriptObjectModel
            });
            return;
        }

        if (state !== 'choosing') {
            scriptDispatch({
                type: ScriptActionType.FRAME_SELECT,
                targetFrameId: frame.id
            });
        }
    };

    return (
        <div
            onClick={handleFrameClicked}
            css={[frameThumbContentContainerStyle(isSelected, props.isEnabled, props.isExclusive)]}
        >
            {(triggeredByCurrentFrame || triggersCurrentFrame) && (
                <div css={eventLinkBoxStyle(token, triggeredByCurrentFrame)}></div>
            )}
            <Drop
                dropId={generateDropId(DROPPABLE_ID_FRAME_THUMB, frame.id)}
                css={[frameThumbGrid(isSelected), props.signalledResource && signalledFrameStyle(isSelected)]}
                allowedDragList={frameThumbDropList}
                direction="horizontal"
            >
                <div css={frameTitleIconWrapper}>
                    <FrameNodeTitle frame={frame} handleRename={handleFrameRename} />
                    <div css={verticalDividerParent}>
                        <span hidden={preConditionIcons.icon ? false : true}>
                            {chooseMode === 'Frame' || chooseMode === 'Resource' ? (
                                <span color="gray">
                                    <Icon
                                        size={Size.SMALLER}
                                        css={iconStyleDisabled(token)}
                                        faIcon={preConditionIcons.icon as IconProp}
                                    />
                                </span>
                            ) : (
                                <TooltipBox
                                    hasArrow={true}
                                    placement={'top'}
                                    label={preConditionIcons.name}
                                    color={'gray.600'}
                                >
                                    <span css={iconEnabledStyle(token, conditionWrapper?.isEnabled as boolean)}>
                                        <Icon
                                            size={Size.SMALLER}
                                            css={iconStyleCondition(token)}
                                            faIcon={preConditionIcons.icon as IconProp}
                                        ></Icon>
                                    </span>
                                </TooltipBox>
                            )}
                        </span>
                        {preConditionIcons.icon && <div css={verticalDividerStyle}></div>}

                        {resourceIcons?.map((resource, idx) => {
                            const i = resource.icon as IconName;

                            if (
                                chooseMode === 'Frame' ||
                                (chooseMode === 'Resource' &&
                                    !(
                                        chooseTypes?.includes(resource.resourceWrapper.resource.type) ||
                                        chooseTypes?.includes(MotiveTypes.OBJECT)
                                    ))
                            ) {
                                return (
                                    <span color="gray">
                                        <Icon
                                            size={Size.SMALLER}
                                            css={iconStyleDisabled(token)}
                                            faIcon={i as IconProp}
                                        />
                                    </span>
                                );
                            } else {
                                return (
                                    <CloneDrag<IObjectInstanceDrag>
                                        drag={{
                                            dragType: DRAG_ITEM_TYPE_CREATE_OBJECT_CONDITION,
                                            objectId: resource.resourceWrapper.resource.id,
                                            objectType: resource.resourceWrapper.resource.type
                                        }}
                                        index={idx}
                                        thumb={
                                            <Icon size={Size.SMALLER} css={[iconStyle(token)]} faIcon={i as IconProp} />
                                        }
                                        key={idx}
                                    >
                                        <TooltipBox
                                            hasArrow={true}
                                            placement={'top'}
                                            label={resource.name}
                                            color={'black'}
                                            css={overflowEllipsisStyle}
                                        >
                                            <span
                                                onClick={() => {
                                                    handleResourceClick(resource.resourceWrapper);
                                                }}
                                            >
                                                <span
                                                    css={iconEnabledStyle(
                                                        token,
                                                        conditionWrapper?.isEnabled as boolean
                                                    )}
                                                ></span>
                                                <Icon
                                                    size={Size.SMALLER}
                                                    css={[
                                                        iconStyle(token),
                                                        iconEnabledStyle(token, resource.resourceWrapper.isEnabled),
                                                        props.signalledResource?.resourceId ===
                                                            resource.resourceWrapper.resource.id &&
                                                            signalledResourceIconStyle
                                                    ]}
                                                    faIcon={i as IconProp}
                                                />
                                            </span>
                                        </TooltipBox>
                                    </CloneDrag>
                                );
                            }
                        })}
                        {varCount > 0 && (
                            <div css={verticalDividerParent}>
                                {resourceIcons?.length > 0 && <div css={verticalDividerStyle}></div>}
                                <Icon size={Size.SMALLER} css={iconStyleDynamic(token)} faIcon={'atom'}></Icon>
                                <span css={variableNumber}>({varCount})</span>
                            </div>
                        )}
                    </div>
                </div>
                <div css={frameTreeActions}>
                    <Button
                        key="remove"
                        icon={IconTypes.DELETE}
                        size={Size.MEDIUM}
                        onClick={() => handleFrameRemove(frame.id)}
                        variant={ButtonVariant.HOLLOW}
                        css={frameActionButtonStyle(token)}
                    />
                    <Button
                        key="add"
                        icon={IconTypes.PLUS}
                        size={Size.MEDIUM}
                        variant={ButtonVariant.HOLLOW}
                        onClick={() => handleFrameAdd(frame.id)}
                        css={frameActionButtonStyle(token)}
                    />
                </div>
            </Drop>
        </div>
    );
});

// Does this frame have a condition that is triggered by a resource in
// the selected frame
function isTriggeredByFrame(callee?: IFrame, caller?: IFrame) {
    if (!caller || !callee || !caller.resources || !callee[FRAME_PRE_CONDITION] || caller.id === callee?.id)
        return false;

    const otherScriptObjs: string[] = [];
    const otherEvents: string[] = [];

    const wrapper = callee[FRAME_PRE_CONDITION] as IConditionWrapper;

    if (!!wrapper.isEnabled) {
        eachCondition(callee[FRAME_PRE_CONDITION], cond => {
            switch (cond.type) {
                case MotiveTypes.SCRIPT_OBJECT_CONDITION:
                    const scriptObjCondition = cond as IScriptObjectEventCondition;

                    if (scriptObjCondition?.objectReference?.objectId) {
                        otherScriptObjs.push(scriptObjCondition.objectReference.objectId);
                    }
                    break;
                case MotiveTypes.CUSTOM_EVENT_CONDITION:
                    const scriptEvent = cond as IScriptEventCondition;

                    // todo: should actually be event name, but this is good for now
                    if (scriptEvent?.eventReference?.objectId) {
                        otherEvents.push(scriptEvent.eventReference.objectId);
                    }
                    break;
            }
        });
    }

    return caller.resources.some(r => {
        if (otherScriptObjs.includes(r.resource.id)) {
            return true;
        }

        if (r.resource.type === MotiveTypes.SCRIPT_EVENT) {
            const scriptEvent = r.resource as IScriptEvent;

            return otherEvents.includes(scriptEvent.eventReference?.objectId);
        }

        return false;
    });
}

const FrameThumb: React.FC<IFrameThumbProps> = ({ frame, selectedFrame, checkIsEnabled }) => {
    const signalledResource = useSelector(selectSignalledResource);

    const props: IFrameThumbRenderProps = {
        frame: frame,
        isSelected: frame.id === selectedFrame?.id,
        isExclusive: frame.isExclusive,
        triggeredByCurrentFrame: isTriggeredByFrame(frame, selectedFrame as IFrame),
        triggersCurrentFrame: isTriggeredByFrame(selectedFrame as IFrame, frame),
        isEnabled: checkIsEnabled(),
        signalledResource: frame.resources?.some(resource => resource.resource.id === signalledResource?.resourceId)
            ? signalledResource
            : undefined
    };

    return useMemo(() => {
        return <FrameThumbRender {...props} />;
    }, [
        props.frame.definedVariables,
        props.frame.id,
        props.frame.resources?.length,
        props.frame.name,
        props.frame[FRAME_PRE_CONDITION],
        props.frame.isExclusive,
        props.isEnabled,
        props.isExclusive,
        props.isSelected,
        props.triggeredByCurrentFrame,
        props.triggersCurrentFrame,
        props.frame.name,
        props.signalledResource
    ]);
};

export const generateTreeNode = ({ frame, selectedFrame, collapsedFrames, checkParentIsEnabled, ...rest }: IGenerateTreeArgs): TreeItem => {
    const isEnabled = frame.isEnabled && checkParentIsEnabled();

    return {
        title: (
            <FrameThumb checkIsEnabled={() => isEnabled} frame={frame} selectedFrame={selectedFrame} {...rest} />
        ),
        expanded: !collapsedFrames[frame.id],
        frameId: frame.id,
        children:
            frame.subFrames?.map(sf =>
                generateTreeNode({
                    ...rest,
                    checkParentIsEnabled: () => isEnabled,
                    selectedFrame,
                    collapsedFrames,
                    frame: sf
                })
            ) ?? []
    };
};

export const FrameTree: React.FC<IScriptEditorSessionDependentProps> = ({ script, selectedFrame }) => {
    const scriptDispatch = useDispatch();
    const token = useStyle();
    const { dragStart } = useDragContext();
    const [collapsedFrames, setCollapsedFrames] = useState<IFrameIdMap>({});

    const treeData = useMemo(() => {
        return generateTreeNode({
            token,
            frame: script.rootFrame,
            currentDragType: dragStart?.type,
            collapsedFrames,
            scriptDispatch,
            selectedFrame,
            checkParentIsEnabled: () => { return true; }
        });
    }, [script, selectedFrame?.id, collapsedFrames]);

    const scaffoldAmount = Number(`${token.spacings.small}`.trimRight().slice(0, -2));

    const handleFrameMove = (data: NodeData & FullTree & OnMovePreviousAndNextLocation): void => {
        if (data.nextParentNode) {
            const index = (data.nextParentNode.children as TreeItem[]).indexOf(data.node);

            scriptDispatch({
                type: ScriptActionType.FRAME_MOVE,
                targetFrameId: data.node.frameId,
                destinationFrameId: data.nextParentNode.frameId,
                destinationIndex: index
            });
        }
    };

    const handleFrameCollapse = (data: OnVisibilityToggleData) => {
        const dictionary = { ...collapsedFrames };
        dictionary[data.node.frameId] = !dictionary[data.node.frameId];
        setCollapsedFrames(dictionary);
    };

    return (
        <div css={frameTreeContainerStyle}>
            <ScriptEditorHistoryButtons />
            <ScrollZone style={{ overflowY: 'auto', overflowX: 'hidden', height: `calc(100% - ${BAR_HEIGHT}px` }}>
                <SortableTree
                    onChange={() => undefined}
                    onMoveNode={handleFrameMove}
                    onVisibilityToggle={handleFrameCollapse}
                    treeData={[treeData]}
                    scaffoldBlockPxWidth={scaffoldAmount}
                    style={{
                        height: `100%`
                    }}
                    //IMPORTANT: Virtualized means that anything outside the viewport will not render, with draggables they MUST always rendered.
                    //Frames are never in the thousands either, so the virtualization optimization is unnecessary
                    isVirtualized={false}
                />
            </ScrollZone>
        </div>
    );
};
