import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const collaboratorStyle = (tokens: IToken) => css`
    margin: ${tokens.spacings.smaller} ${tokens.spacings.smaller};
    text-align: center;
    cursor: pointer;
    position: relative;
    :hover {
        .controls {
            opacity: 1;
        }
    }
`;

export const controlStyle = css`
    background-color: gray;
    margin: -8px -8px 0 0;
    top: 0;
    right: 0;
    transition: opacity 0.1s ease-out;
    opacity: 0;
    position: absolute;
    border-radius: 16px;
`;

export const collaboratorTextStyle = (tokens: IToken) => css`
    color: ${tokens.colors.font.tertiary};
    align-items: center;
    padding-top: ${tokens.spacings.smaller};
`;

export const addCollaboratorWrapper = (tokens: IToken) => css`
    margin: ${tokens.spacings.medium};
`;

export const removeCollaboratorButton = (tokens: IToken) => css`
    margin: auto;
    padding: 0 ${tokens.spacings.smaller};
    border-radius: 0;
    &:hover {
        filter: brightness(110%);
    }
    :hover {
        color: white;
    }
`;
