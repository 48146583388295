import React, { useState } from 'react';
import { useStyle } from '../../shared/hooks/useStyle';
import { getAgentReferenceFromIntentCatalog } from '../../util/AIUtils';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { EmptyCollection } from '../../core-ui/emptyCollection';
import { RowItem } from '../../core-ui/rowItem';
import { Size } from '../../core-ui/constants/Size';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Text } from '../../core-ui/text';
import { Icon } from '../../core-ui/icon';
import { ConversationalAgent } from '../../shared/motive/models/ConversationalAgent';
import { LoadingSkeleton } from '../../core-ui/loadingSkeleton/LoadingSkeleton';
import {
    catalogTitleWrapperStyle,
    deleteButtonIconStyle,
    deleteButtonStyle,
    deleteButtonWrapperStyle,
    exclamationIconStyle,
    renameButtonStyle,
    renameWrapperStyle,
    rowStyle,
    subcontentStyle
} from './IntentCatalogs.style';
import { Button, ButtonVariant } from '../../core-ui/button';
import { DeleteModal } from '../../core-ui/deleteModal/DeleteModal';
import { InputField } from '../../core-ui/inputField';

interface IIntentCatalogListProps {
    cats: ICatalog[];
    onSelectCat: (catalogId: string) => void;
    onDeleteCat?: (intentCatalog: ICatalog) => Promise<void>;
    onRenameCat?: (catalogId: string, catalogTitle: string) => Promise<void>;
    searchFilter?: string;
    isCreateCatalogLoading?: boolean;
    agents?: ConversationalAgent[];
}

interface IIntentCatalogRowItemProps {
    cat: ICatalog;
    onSelectCat: (catalogId: string) => void;
    onDeleteCat?: (intentCatalog: ICatalog) => Promise<void>;
    onRenameCatalog?: (catalogId: string, catalogTitle: string) => Promise<void>;
    agent?: ConversationalAgent;
}
export const TEST_ID_RENAME = 'renameButton';

const DEFAULT_NEW_NAME = '';

const IntentCatalogRowItem: React.FC<IIntentCatalogRowItemProps> = (props): React.ReactElement => {
    const token = useStyle();
    const handleCatalogSelect = () => {
        if (!isRenameCatalogShown) {
            props.onSelectCat && props.onSelectCat(props.cat.id);
        }
    };
    const [deleteModal, setDeleteModal] = useState(false);
    const [isRenameCatalogShown, setIsRenameCatalogShown] = useState(false);
    const [isUpdating, setIsUpdating] = useState<boolean>();
    const [newName, setNewName] = useState(DEFAULT_NEW_NAME);

    const handleDeleteCatalog = () => {
        setDeleteModal(false);
        setIsUpdating(true);
        props.onDeleteCat && props.onDeleteCat(props.cat).then(() => setIsUpdating(false));
    };

    const cancelRename = () => {
        setNewName(DEFAULT_NEW_NAME);
        setIsRenameCatalogShown(false);
    };

    const handleRename = () => {
        if (!newName) {
            cancelRename();
        } else {
            setIsUpdating(true);

            props.onRenameCatalog &&
                props.onRenameCatalog(props.cat.id, newName).then(() => {
                    setIsUpdating(false);
                });

            setIsRenameCatalogShown(false);
        }
    };

    return (
        <>
            <DeleteModal
                isOpen={deleteModal}
                onCloseModal={() => setDeleteModal(false)}
                onConfirm={handleDeleteCatalog}
            >
                Are you sure you wish to delete this catalog?
            </DeleteModal>
            {!isUpdating ? (
                <RowItem css={rowStyle(token)} size={Size.MEDIUM} onClick={handleCatalogSelect}>
                    {() => ({
                        content: isRenameCatalogShown ? (
                            <div css={renameWrapperStyle}>
                                <InputField
                                    size={Size.LARGE}
                                    placeholder={props.cat.title}
                                    onChange={catalogName => {
                                        setNewName(catalogName);
                                    }}
                                />
                                <Button
                                    css={renameButtonStyle(token)}
                                    data-testid={TEST_ID_RENAME}
                                    size={Size.LARGE}
                                    variant={ButtonVariant.SOLID}
                                    icon={IconTypes.CHECKMARK}
                                    onClick={handleRename}
                                />
                                <Button
                                    css={renameButtonStyle(token)}
                                    size={Size.LARGE}
                                    variant={ButtonVariant.GHOST}
                                    icon={IconTypes.TIMES}
                                    onClick={cancelRename}
                                />
                            </div>
                        ) : (
                            <div>
                                <Text
                                    onClick={() => {
                                        setIsRenameCatalogShown(true);
                                    }}
                                    isPropagationDisabled={true}
                                    css={catalogTitleWrapperStyle(token)}
                                    size={Size.LARGEST}
                                >
                                    {props.cat.title}
                                </Text>
                            </div>
                        ),
                        subContent:
                            !isRenameCatalogShown &&
                            (props.agent?.name ? (
                                <span css={subcontentStyle(token)}>{`${props.agent.name}`}</span>
                            ) : (
                                <span css={subcontentStyle(token)}>
                                    <Icon iconStyle={exclamationIconStyle} icon={IconTypes.EXCLAMATION}></Icon>
                                    {'No Agent'}
                                </span>
                            )),
                        rightContent: !isRenameCatalogShown && (
                            <div css={deleteButtonWrapperStyle}>
                                <Button
                                    css={deleteButtonStyle(token)}
                                    variant={ButtonVariant.HOLLOW}
                                    onClick={() => setDeleteModal(true)}
                                    icon={IconTypes.TIMES}
                                    iconStyle={deleteButtonIconStyle(token)}
                                />
                            </div>
                        )
                    })}
                </RowItem>
            ) : (
                <LoadingCatalogRowItem />
            )}
        </>
    );
};

const LoadingCatalogRowItem: React.FC = (): React.ReactElement => {
    const token = useStyle();

    return (
        <RowItem css={rowStyle(token)} size={Size.MEDIUM}>
            {() => ({
                content: <LoadingSkeleton height={token.spacings.large} />
            })}
        </RowItem>
    );
};

export const IntentCatalogList: React.FC<IIntentCatalogListProps> = ({ searchFilter, ...props }) => {
    if (!props.cats.length) {
        return (
            <EmptyCollection
                message={'No Intent Catalogs found for this project. You can create or import Intent Catalogs.'}
            />
        );
    }

    const filteredIntentCatalogs = searchFilter
        ? props.cats.filter(cat => cat.title?.toLocaleUpperCase().includes(searchFilter.toLocaleUpperCase()))
        : props.cats;

    const catalogRowItems = (
        <>
            {filteredIntentCatalogs.map(cat => {
                return (
                    <IntentCatalogRowItem
                        key={cat.id}
                        agent={props.agents?.find(
                            agent => agent.id === getAgentReferenceFromIntentCatalog(cat)?.objectId
                        )}
                        cat={cat}
                        onSelectCat={props.onSelectCat}
                        onDeleteCat={props.onDeleteCat}
                        onRenameCatalog={props.onRenameCat}
                    />
                );
            })}
            {props.isCreateCatalogLoading && <LoadingCatalogRowItem />}
        </>
    );

    return <>{catalogRowItems}</>;
};
