import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const catalogItemInspectorStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    vertical-align: center;
`;

export const catalogInspectorStyle = css`
    width: 70%;
    margin: 5% 0;
    height: fit-content;
`;

export const objectEditorStyle = (token: IToken) => css`
    background-color: ${token.colors.background.lighter};
    border: 1px solid ${token.colors.border.primary};
    padding: ${token.spacings.medium};
`;

export const catalogInspectorBarStyle = (token: IToken) => css`
    background-color: ${token.colors.background.light};
    padding: ${token.spacings.small};
    display: flex;
    justify-content: space-between;
`;

export const catalogItemRowStyle = css`
    &:hover {
        cursor: pointer;
        background-color: gainsboro;
    }
`;

export const selectedCatalogItemRowStyle = css`
    filter: brightness(0.9);
    &:hover {
        cursor: pointer;
    }
`;

export const catalogEditorWrapperStyle = css`
    display: grid;
    grid-template-columns: 30% 70%;
    grid-auto-flow: column dense;
    height: 100%;
    border-radius: 0px;
    border-top: transparent;
`;

export const catalogItemsWrapperStyle = (token: IToken) => css`
    border-right: 1px solid ${token.colors.border.primary};
`;

export const catalogEditorLayout = css`
    display: grid;
    grid-template-rows: auto 1fr;
    height: 97%;
`;

export const saveButtonStyle = css`
    min-width: 80px;
`;

export const topBarStyle = (token: IToken) => css`
    padding: ${token.spacings.smaller};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    border-bottom: 1px solid ${token.colors.border.primary};
`;

export const buttonLayoutStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const buttonSpanStyle = (token: IToken) => css`
    margin: 0 ${token.spacings.smallest};
`;

export const catalogEditorEmptyCollectionStyle = css`
    text-align: center;
`;

export const catalogEditorHeaderWrapper = css`
    display: flex;
    flex-direction: row;
`;
