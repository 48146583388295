// Current Space
export const setCurrentSpaceAction = (space: string) => {
    return {
        type: `currentSpace/set`,
        space
    } as const;
};

export const unsetCurrentSpaceAction = () => {
    return {
        type: `currentSpace/unset`
    } as const;
};

//Current Project
export const setCurrentProjectAction = (project: string) => {
    return {
        type: `currentProject/set`,
        project
    } as const;
};

export const unsetCurrentProjectAction = () => {
    return {
        type: `currentProject/unset`
    } as const;
};

//Last script
export const setLastScriptAction = (lastScript: string) => {
    return {
        type: `lastScript/set`,
        lastScript
    } as const;
};

export const unsetLastScriptAction = () => {
    return {
        type: `lastScript/unset`
    } as const;
};

export type SpaceKeyedStateActionType = ReturnType<
    | typeof setCurrentSpaceAction
    | typeof unsetCurrentSpaceAction
    | typeof unsetCurrentProjectAction
    | typeof setCurrentProjectAction
    | typeof setLastScriptAction
    | typeof unsetLastScriptAction
>;
