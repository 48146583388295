import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const mediaCollectionWrapperStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
`;

export const mediaItemGridCollectionStyle = css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    justify-content: flex-start;
    margin: 16px 0;
    grid-gap: 16px;
`;

export const mediaitemListCollectionStyle = css`
    grid-template-columns: repeat(1, 100%);
`;

export const listHeadingStyle = (token: IToken) => css`
    margin-top: 15px;
    background-color: ${token.colors.background.dark};
    padding: ${token.spacings.smaller};
`;

export const listHeadingTextStyle = (token: IToken) => css`
    color: ${token.colors.staticColors.white};
`;

export const gridRowStyle = css`
    padding-left: 10px;
    display: grid;
    grid-template-columns: 0.5fr 50% 3fr 3fr 3fr;
`;

export const textTitleWrapperStyle = (token: IToken) => css`
    padding: 0px ${token.spacings.small};
    align-self: center;
`;

export const textMetadataWrapperStyle = css`
    padding-left: 10px;
    align-self: center;
    justify-self: center;
`;

export const paginationWrapper = css`
    display: flex;
    align-self: center;
    pading: unset;
`;

export const detailedViewModalWrapper = (token: IToken) => css`
    padding: ${token.spacings.smaller};
    overflow: hidden;
`;

export const detailedViewHeaderStyle = (token: IToken) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${token.colors.border.primary};
`;

export const emptyMediaMessageWrapper = css`
    margin-top: 10px;
    width: 80%;
`;

export const nextPrevIconStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 10px;
    width: 10px;
`;
