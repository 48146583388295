import React, { useState } from 'react';
import { IFieldEditorProps } from '../../containers/objectEditor';
import { InputField } from '../../core-ui/inputField';
import { Size } from '../../core-ui/constants/Size';
import { inRange, isInteger } from 'lodash-es';
import { Text } from '../../core-ui/text';
import { Button } from '../../core-ui/button';
import { timeSpanSignStyle, timeSpanWrapperStyle } from './TimeSpanEditor.style';
import { ButtonVariant } from '../../core-ui/button/Button';
import { EditModeAware } from '../editableOnFocus/EditModeAware';
import { useStyle } from '../../shared/hooks/useStyle';

/**
 * RULES:
 * Hour must be 0 <= n <= 24, integer
 * Minute must be 0 <= n < 60, integer
 * Seconds must be 0 <= n < 60, float
 */

const checkIfValid = (stringVal?: string, type?: string): boolean => {
    const val = stringVal && parseFloat(stringVal);

    if (!val) return true;
    if (type === 'hour' && inRange(val, 0, 25) && isInteger(val)) return true;
    if (type === 'minute' && inRange(val, 0, 60) && isInteger(val)) return true;
    if (type === 'second' && inRange(val, 0, 60)) return true;

    return false;
};

// Prepend '0' if hh || mm || ss is single digit.
const formatNumber = (stringVal?: string) => {
    const val = stringVal && parseFloat(stringVal);
    if (!val) {
        return '00';
    }

    if (val > 0 && val <= 9) {
        return `0${val}`;
    }

    return val;
};

const getHMS = (value?: string) => {
    if (value) {
        let hms = [];
        if (value.startsWith('-')) {
            hms = value.substring(1).split(':');
        } else {
            hms = value.split(':');
        }

        return hms.map(c => (parseFloat(c) > 0 ? (c.startsWith('0') ? c.substring(1) : c) : ''));
    }
    return ['', '', ''];
};

export const TimeSpanEditor: React.FC<IFieldEditorProps<string>> = ({ value, onChange }): React.ReactElement => {
    const [hr, min, sec] = getHMS(value);

    const [isPositive, setPositive] = useState<boolean>(!value || (!!value && !value.startsWith('-')));

    const token = useStyle();

    const onTimeChange = (hour?: string, minute?: string, second?: string) => {
        if (!hour && !minute && !second) {
            onChange(undefined);
        }

        if (hour !== undefined || minute !== undefined || second !== undefined) {
            const time = [
                isPositive ? '' : '-',
                [formatNumber(hour ?? hr), formatNumber(minute ?? min), formatNumber(second ?? sec)].join(':')
            ].join('');
            onChange(time);
        }
    };

    const onHourChange = (hour: string) => onTimeChange(hour);
    const onMinuteChange = (minute: string) => onTimeChange(undefined, minute);
    const onSecondChange = (second: string) => onTimeChange(undefined, undefined, second);

    const onSignChange = () => {
        //need to set the value of the field to what it's going to be after this toggle
        const nextIsPositive = !isPositive;

        if (value) {
            if (nextIsPositive && value.startsWith('-')) {
                onChange(value.substring(1));
            } else if (!nextIsPositive && !value.startsWith('-')) {
                onChange(`-${value}`);
            }
        }

        setPositive(nextIsPositive);
    };

    const renderEditor = () => {
        return (
            <div css={timeSpanWrapperStyle}>
                <Button
                    variant={ButtonVariant.SOLID}
                    css={timeSpanSignStyle(token)}
                    size={Size.SMALL}
                    onClick={onSignChange}
                >
                    {isPositive ? '+' : '-'}
                </Button>
                <InputField
                    type="number"
                    size={Size.MEDIUM}
                    value={hr}
                    isInvalid={!checkIfValid(hr, 'hour')}
                    onChange={(val: string) => onHourChange(val)}
                    placeholder={'Hour'}
                />
                <InputField
                    type="number"
                    size={Size.MEDIUM}
                    value={min}
                    isInvalid={!checkIfValid(min, 'minute')}
                    onChange={(val: string) => onMinuteChange(val)}
                    placeholder={'Minute'}
                />
                <InputField
                    type="number"
                    size={Size.MEDIUM}
                    value={sec}
                    isInvalid={!checkIfValid(sec, 'second')}
                    onChange={(val: string) => onSecondChange(val)}
                    placeholder={'Second'}
                />
                <Text>{'±HH:MM:SS.xxx'}</Text>
            </div>
        );
    };

    const renderReadOnly = () => {
        return <Text>{value}</Text>;
    };

    return <EditModeAware readonlyModeRender={renderReadOnly} editModeRender={renderEditor} />;
};
