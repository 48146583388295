import { IToken } from '../../shared/contexts/styleContext';
import { css } from '@emotion/core';

export const SIDEBAR_MIN_WIDTH = 121;

export const sidebarSection = (token: IToken, sidebarWidth: number) => css`
    width: ${sidebarWidth}vw;
    background-color: #2f2e41;
    min-width: ${SIDEBAR_MIN_WIDTH}px;
`;

export const dashboardSection = css`
    flex: 1;
`;

export const outerStyle = css`
    display: flex;
    height: 100%;
`;
