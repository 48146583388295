/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file MOTIVE_EXCEPTION */
import React, { useState, useEffect } from 'react';
import { Button, ButtonVariant } from '../../../core-ui/button';
import { IScriptObjectModel } from '../../../shared/motive/models/ScriptObjectModel';
import { getSmartName } from '../../../util/ObjectDefinitionsUtils';
import {
    pillIconStyle,
    flexButtonStyle,
    textStyle,
    pillFlexChildrenStyle,
    itemDropStyle,
    selectingTextStyle,
    pillContainerStyle
} from './ExternalGlobalEditor.style';
import { useStyle } from '../../../shared/hooks/useStyle';
import { useGetAllCatalogs } from '../../../shared/motive/hooks/useCatalogs';
import { useScriptsRoute } from '../../../routes/scriptsRoute/ScriptsRoute';
import { IconTypes } from '../../../core-ui/constants/IconTypes';
import { Size } from '../../../core-ui/constants/Size';
import { IObjectReference } from '../../../shared/motive/models/ObjectReference';
import { IFieldEditorProps } from '../ObjectEditor';
import { removeFromArray, createObjectReferenceFromObject } from '../../../util/MotiveUtils';
import {
    useTypeDefinitions,
    useObjectTypeAndImplementors
} from '../../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { useDashboardDrawer } from '../../../hooks/useDashboardDrawer';
import { Pill } from '../../../core-ui/pill';
import { MotiveTypes } from '../../../constants/MotiveTypes';
import { Text } from '../../../core-ui/text';
import { IChooseAction } from '../../../shared/motive/reducers/ChooseExternalScriptScopeReducer';
import { useCatalogItem } from '../../../shared/motive/hooks/useCatalogs/useCatalogItem/useCatalogItem';
import { useCurrentLanguageSettings } from '../../../shared/motive/stores/editorLocale/EditorLocale';
import { css } from '@emotion/core';
import { Drop } from '../../../components/_common/DragAndDrop/Drop/Drop';
import { ICatalogObjectInstanceDrag } from '../../../components/_common/DragAndDrop/DragModels/DragModels';
import { useCheckDragHover } from '../../../components/_common/DragAndDrop/DragAndDropProvider/DragAndDropContext';
import { DRAG_ITEM_TYPE_CATALOG_ITEM } from '../../../constants/DragAndDrop';
import { useTranslation } from 'react-i18next';

interface IObjectOrReference {
    id?: string;
    type?: string;
    objectId?: string;
    objectType?: string;
    editorLabel?: string;
}

const ObjectRefEditor: React.FC<IFieldEditorProps<IScriptObjectModel | IObjectReference>> = ({ value, onChange }) => {
    const tokens = useStyle();
    const { goTo } = useScriptsRoute();
    const objOrRef = value as IObjectOrReference;
    const typeDefs = useTypeDefinitions();
    const language = useCurrentLanguageSettings();

    const handleRedirect = (id: string | undefined) => {
        if (id) {
            goTo({
                pathParams: {
                    scriptId: id
                }
            });
        }
    };

    const objId = objOrRef.id || objOrRef.objectId;

    const liveObject = useCatalogItem(objId as string);

    const label = liveObject
        ? getSmartName(liveObject, typeDefs, language)
        : objOrRef.id
        ? getSmartName(value as IScriptObjectModel, typeDefs, language)
        : objOrRef.editorLabel ?? objOrRef.objectId;

    return (
        <div css={pillContainerStyle}>
            <Pill iconStyle={pillIconStyle(tokens)} size={Size.SMALL} onClickClose={() => onChange(undefined)}>
                <div css={pillFlexChildrenStyle}>
                    <Text size={Size.SMALL} css={textStyle(tokens)}>
                        {label}
                    </Text>
                    {objOrRef.objectType === MotiveTypes.MOTIVE_SCRIPT && (
                        <Button
                            css={flexButtonStyle(tokens)}
                            size={Size.SMALL}
                            variant={ButtonVariant.HOLLOW}
                            icon={IconTypes.EXTERNAL_LINK}
                            iconStyle={pillIconStyle(tokens)}
                            onClick={() => handleRedirect(objId)}
                        />
                    )}
                </div>
            </Pill>
        </div>
    );
};

export type ExternalGlobalFieldValueType =
    | IScriptObjectModel
    | IObjectReference
    | IScriptObjectModel[]
    | IObjectReference[];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IExternalGlobalEditorProps extends IFieldEditorProps<ExternalGlobalFieldValueType> {}

const ExternalGlobalEditorInner: React.FC<IExternalGlobalEditorProps & {
    handleChange: (newValue: IScriptObjectModel | undefined) => void;
}> = fieldProps => {
    const [isSelecting, setIsSelecting] = useState(false);
    const typeDefs = useTypeDefinitions();
    const language = useCurrentLanguageSettings();
    const tokens = useStyle();

    const handleClickSelectExternal = () => {
        setIsSelecting(true);
    };
    const handleSelectEnd = (selectedItem: IScriptObjectModel | undefined) => {
        setIsSelecting(false);

        if (selectedItem) {
            fieldProps.handleChange(selectedItem);
        }
    };

    const renderSelectingOrAddButton = () => {
        return (
            <>
                {isSelecting ? (
                    <ExternalGlobalEditorFieldSelectContainer {...fieldProps} onSelectEnd={handleSelectEnd} />
                ) : (
                    <Button
                        key="add"
                        size={Size.SMALL}
                        icon={IconTypes.PLUS}
                        variant={ButtonVariant.CIRCULAR}
                        onClick={handleClickSelectExternal}
                    />
                )}
            </>
        );
    };

    if (!fieldProps.value) {
        return (
            // <div
            //     css={[itemDropStyle(tokens, fieldProps.state.isDraggingOver)]}
            //     ref={fieldProps.provided.innerRef}
            //     {...fieldProps.provided.droppableProps}
            // >
            //     {renderSelectingOrAddButton()}
            // </div>
            renderSelectingOrAddButton()
        );
    }

    if (fieldProps.fieldDefinition.isArray) {
        const elements = [];

        if (fieldProps.fieldDefinition.useIdOnly) {
            const array = fieldProps.value as IObjectReference[];

            for (let i = 0; i < array.length; i++) {
                const elem = array[i];

                const elemProps: IFieldEditorProps<IObjectReference> = {
                    ...(fieldProps as IFieldEditorProps<IObjectReference>)
                };

                elemProps.value = elem as IObjectReference;
                elemProps.onChange = () => {
                    // Only called on delete--use object instead of index
                    // in case we remove multiple or the index above gets out of
                    // sync for any other reason.
                    removeFromArray(array, elem);

                    fieldProps.onChange(array);
                };

                elements.push(<ObjectRefEditor key={elem.objectId ?? i} {...elemProps}></ObjectRefEditor>);
            }
        } else {
            const array = (fieldProps.value as IScriptObjectModel[]) ?? [];

            if (array.length > 0) {
                elements.push(
                    <div css={pillContainerStyle}>
                        {array.map((scriptObject, index) => (
                            <Pill
                                key={`${index}.${scriptObject.id}`}
                                onClickClose={() =>
                                    fieldProps.onChange([...array].filter(item => item && item.id !== scriptObject.id))
                                }
                                size={Size.SMALL}
                            >
                                <Text size={Size.SMALL} css={textStyle(tokens)}>
                                    {getSmartName(scriptObject, typeDefs, language) ?? scriptObject.id}
                                </Text>
                            </Pill>
                        ))}
                    </div>
                );
            }
        }

        return (
            <>
                {renderSelectingOrAddButton()}
                <div>{elements}</div>
            </>
        );
    } else {
        return (
            <>
                <ObjectRefEditor
                    {...(fieldProps as IFieldEditorProps<IScriptObjectModel | IObjectReference>)}
                ></ObjectRefEditor>
            </>
        );
    }
};

interface IExternalGlobalEditorFieldSelectProps {
    onSelectEnd: (selectedItem: IScriptObjectModel | undefined) => void;
    type: string;
}

// when "add from catalog", this container is mounted

export const ExternalGlobalEditorFieldSelectContainer: React.FC<IExternalGlobalEditorFieldSelectProps & {}> = ({
    onSelectEnd,
    type
}) => {
    const {
        handleSelectExternalGlobalField,
        handleCancelSelectExternalGlobalField,
        popDrawerItem
    } = useDashboardDrawer();
    const [, setMountedSelect] = useState(false);

    const handleChoose = (action: IChooseAction) => {
        switch (action.actionType) {
            case 'beginChoose':
                break;
            case 'cancelChoose':
                onSelectEnd(undefined);
                break;
            case 'choose':
                onSelectEnd(action.chosenValue);
                break;
        }
    };

    useEffect(() => {
        const stackItem = handleSelectExternalGlobalField(type, handleChoose);

        return () => {
            handleCancelSelectExternalGlobalField();

            popDrawerItem(stackItem);
        };
    }, []);

    useEffect(() => {
        setMountedSelect(true);
    }, [setMountedSelect]);
    const token = useStyle();
    const { t } = useTranslation();
    return (
        <>
            <span css={selectingTextStyle(token)}>{t('selecting')}...</span>
        </>
    );
};

export const ExternalGlobalEditor: React.FC<IExternalGlobalEditorProps> = fieldProps => {
    const typeDefs = useTypeDefinitions();
    const language = useCurrentLanguageSettings();
    const { data: catalogs } = useGetAllCatalogs();
    const tokens = useStyle();
    const dropId = fieldProps.parentObjectEditorProps.value?.id + '.' + fieldProps.path;

    const checkDrag = useCheckDragHover();

    const handleChange = (newValue: IScriptObjectModel | undefined) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!newValue || !allowedTypes.includes(newValue.type)) {
            fieldProps.onChange(undefined);

            return;
        }

        const useIdOnly = fieldProps.fieldDefinition.useIdOnly;
        const isArray = fieldProps.fieldDefinition.isArray;

        const newFieldVal = useIdOnly ? createObjectReferenceFromObject(newValue, typeDefs, language) : newValue;

        if (isArray) {
            const curr = fieldProps.value ? [...(fieldProps.value as any[])] : [];

            curr.push(newFieldVal);

            fieldProps.onChange(curr);
        } else {
            fieldProps.onChange(newFieldVal);
        }
    };

    const allowedTypes = useObjectTypeAndImplementors(fieldProps.type);

    const dropList = [
        {
            dragType: DRAG_ITEM_TYPE_CATALOG_ITEM,
            onDragEnd: (objDragInfo: ICatalogObjectInstanceDrag) => {
                if (allowedTypes.includes(objDragInfo.objectType)) {
                    const item = catalogs
                        .find(c => c.id === objDragInfo.catalogId)
                        ?.items?.find(i => i.id === objDragInfo.objectId);

                    if (item) {
                        handleChange(item);
                    }
                }
            }
        }
    ];

    return (
        <Drop dropId={dropId} isDisabled={false} allowedDragList={dropList}>
            <div
                css={css`
                    flex-grow: 0.5;
                `}
            >
                <div css={[itemDropStyle(tokens, checkDrag(dropId, DRAG_ITEM_TYPE_CATALOG_ITEM))]}>
                    <ExternalGlobalEditorInner {...fieldProps} handleChange={handleChange} />
                </div>
            </div>
        </Drop>
    );
};
