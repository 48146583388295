/**
 * @file ZIndex
 * @description Keeps different z-index styles organized
 * in one place for debugging/development.
 */

export const ZIndex = {
    coreUIDropdown: 0,

    dynamicsStickyBar: 20,

    drawer: 50
};
