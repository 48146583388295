import winston, { format } from 'winston';

/**
 * Terminology:
 * Transports: essentially a storage device for your logs. E.g. Console, File, Http, etc.
 * Logging Levels: these levels are based on severity, from most important to least important
 *      npm logging levels: error:: 0, warn: 1, info: 2, verbose: 3, debug: 4
 */

// App Environtment
export const APP_ENVIRONMENT = process.env.NODE_ENV;

// Logger Configurations
const logLevel = APP_ENVIRONMENT === 'production' ? 'warn' : 'verbose';
const logLevels = {
    levels: {
        error: 0,
        warn: 1,
        info: 2,
        debug: 3,
        verbose: 4
    },
    colors: {
        error: 'red',
        warn: 'yellow',
        info: 'gray',
        debug: 'gray',
        verbose: 'gray'
    }
};

// Formatting Configuration
const { combine, label, printf, timestamp } = format;
const motiveLogFormat = printf(({ level, message, timestamp }) => {
    return `[${timestamp}] [${level}]: ${message}`;
});

// Transports Configuration
const consoleTransport = new winston.transports.Console({
    format: combine(
        winston.format.colorize({ level: true, colors: logLevels.colors }),
        label({ label: 'MotiveLog' }),
        timestamp({ format: 'h:mm:ss' }),
        motiveLogFormat
    )
});

export const Logger = winston.createLogger({
    level: logLevel,
    levels: logLevels.levels,
    format: winston.format.json(),
    transports: [consoleTransport],
    defaultMeta: { service: 'user-service' }
});
