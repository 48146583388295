import { RouteProps } from 'react-router';

type Key = string;
type IRouteMap<K extends Key> = Record<K, IRoute<K>>;
type WithParentPath<T> = T & { parentPath?: string };

/**
 * A utility type
 * The pre-processed route type;
 * Is missing computed properties (fullPath);
 * Some type differences to satisfy compile (self type recursion);
 */
interface IRoutePartial<K extends Key> extends RouteProps {
    key: K;
    path: string;

    subRoutes?: IRoutePartial<K>[];
    needsAuth?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component?: React.ComponentType<any>;
}

/**
 * A route object.
 *
 * @property fullPath This `path` string with the full ancestor path prefixed.
 */
export interface IRoute<K extends Key> extends IRoutePartial<K> {
    fullPath: string;
    subRoutes: IRoute<K>[];
}

export const routeFactory: <K extends Key>(routesPartial: IRoutePartial<K>[]) => [IRoute<K>[], IRouteMap<K>] = <
    K extends Key
>(
    routesPartial: IRoutePartial<K>[]
) => {
    const routeMapObjPartial: Partial<IRouteMap<K>> = {};
    const buildRouteWithFullPath: (route: WithParentPath<IRoutePartial<K>>, index: number) => IRoute<K> = (
        route: WithParentPath<IRoutePartial<K>>
    ) => {
        const fullPath = `${route.parentPath}${route.path}`;

        const builtRoute: IRoute<K> = {
            ...route,
            fullPath,
            subRoutes:
                (route.subRoutes &&
                    route.subRoutes
                        .map(subRoute => {
                            return { ...subRoute, parentPath: fullPath };
                        })
                        .map(buildRouteWithFullPath)) ||
                []
        };

        routeMapObjPartial[route.key] = builtRoute;
        return builtRoute;
    };

    const routes: IRoute<K>[] = routesPartial
        .map(topLevelRoute => {
            return { ...topLevelRoute, parentPath: '' };
        })
        .map(buildRouteWithFullPath);

    const routeMap: IRouteMap<K> = routeMapObjPartial as IRouteMap<K>;

    return [routes, routeMap];
};
