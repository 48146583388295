import { ISaveCatalogItemParams, useSaveCatalogItem } from '../useCatalogs/useSaveCatalogItem/useSaveCatalogItem';
import { useDispatch } from 'react-redux';
import { catalogItemUpdateAction } from '../../../../redux/spaceKeyed/catalog/CatalogItemActions';

export const useSaveScenarioItem = () => {
    const catalogDispatch = useDispatch();
    const { execute: executeSave } = useSaveCatalogItem();

    const dispatchItem = (params: ISaveCatalogItemParams) => {
        catalogDispatch(catalogItemUpdateAction(params.item));
        executeSave(params);
    };

    return {
        execute: dispatchItem
    };
};
