import { useCurrentSpace } from '../useCurrentSpace';
import { duplicateScriptNetworkCall } from '../../networking/ScriptService';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useDispatch } from 'react-redux';

export interface IDuplicateScriptParams {
    catalogId: string;
    scriptId: string;
}
export const useDuplicateScript = (): IHookProvidedCall<IDuplicateScriptParams> => {
    const [currentSpace] = useCurrentSpace();
    const catalogDispatch = useDispatch();
    const { getCancelableDispatch, triggerCall, ...rest } = useNetworkCallWithoutDataSource();

    const duplicateScript = async (params: IDuplicateScriptParams) => {
        await triggerCall(
            duplicateScriptNetworkCall(
                currentSpace,
                params.scriptId,
                params.catalogId,
                getCancelableDispatch(catalogDispatch)
            )
        );
    };

    return { execute: duplicateScript, ...rest };
};
