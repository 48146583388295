import { css } from '@emotion/core';
import { stretchWidth } from '../../constants/HelperStyles';

export const labelWrapperStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const labelTextStyle = css`
    color: white;
`;
export const localizedVideoStyle = css`
    width: 100%;
`;

export const localizedAudioStyle = css`
    max-height: 36px;
    width: 100%;
    position: relative;
    outline: none;
`;
export const localizedImageStyle = css`
    width: 100%;
`;

export const simpleIconStyle = css`
    margin: auto;
`;

export const pillStyle = css`
    color: white;
`;

export const mediaFlexStyle = css`
    display: flex;
    flex-direction: column;
`;
