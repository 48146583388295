import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const subTitleStyle = ({ colors: { font }, fontSizes }: IToken) => css`
    color: ${font.primary};
    font-size: ${fontSizes.small};
`;

export const rowStyle = ({ colors: { border }, spacings }: IToken) => css`
    border-bottom: 1px solid ${border.primary};
    padding: ${spacings.medium};
`;
