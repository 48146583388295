import { IScriptEditorInfo } from '../models/ScriptEditorInfo';

export enum ScriptEditorInfoStatus {
    FETCHING,
    SUCCESS,
    ERROR
}

export interface IScriptEditorInfoState {
    scriptEditorInfo: IScriptEditorInfo;
}

export interface IScriptEditorInfoAction {
    status: ScriptEditorInfoStatus;
    scriptEditorInfo?: IScriptEditorInfo;
    error?: Error;
}

export const DefaultScriptEditorInfoState: IScriptEditorInfoState = {
    scriptEditorInfo: {
        typeDefinitions: {},
        conditionTypes: [],
        resourceTypes: []
    }
};

export const ScriptEditorInfoReducer = (
    state: IScriptEditorInfoState,
    action: IScriptEditorInfoAction
): IScriptEditorInfoState => {
    switch (action.status) {
        case ScriptEditorInfoStatus.FETCHING:
            return { ...state };
        case ScriptEditorInfoStatus.SUCCESS:
            if (!action.scriptEditorInfo) throw new Error('No response data provided in the action');
            return {
                ...state,
                scriptEditorInfo: action.scriptEditorInfo
            };
        case ScriptEditorInfoStatus.ERROR:
            return { ...state };
    }
};
