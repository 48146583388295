import { css } from '@emotion/core';

export const spaceEnumItemStyle = css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const editableTextWrapperStyle = css`
    border: none;
    outline: none;
    &:hover {
        outline: none;
        border: none;
    }
`;

export const editableTextTextStyle = css`
    font-size: 1em;
    &:focus {
        font-size: 1em;
        box-shadow: 0px 0px 8px -4px #6bbaba;
    }
`;

export const errorMessageStyle = css`
    color: red;
`;
