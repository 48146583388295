import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';
import { ButtonSize } from '../constants/Size';
import chroma from 'chroma-js';

export const smallerButtonStyle = (token: IToken) => css`
    border-radius: 3px;
    min-height: ${token.spacings.small};
    max-height: ${token.spacings.small};
    font-size: ${token.fontSizes.smaller};
    padding: 2px 6px;
`;

export const smallButtonStyle = (token: IToken) => css`
    border-radius: 4px;
    min-height: ${token.spacings.medium};
    max-height: ${token.spacings.medium};
    font-size: ${token.fontSizes.small};
    padding: 4px 10px;
`;

export const mediumButtonStyle = (token: IToken) => css`
    border-radius: 5px;
    min-height: ${token.spacings.large};
    max-height: ${token.spacings.large};
    font-size: ${token.fontSizes.medium};
    padding: 6px 14px;
`;

export const largeButtonStyle = (token: IToken) => css`
    border-radius: 5px;
    min-height: ${token.spacings.larger};
    max-height: ${token.spacings.larger};
    padding: 8px 18px;
`;

export const largerButtonStyle = (token: IToken) => css`
    border-radius: 5px;
    min-height: ${token.spacings.largest};
    max-height: ${token.spacings.largest};
    padding: 10px 22px;
`;

export const inheritButtonStyle = () => css`
    border-radius: 5px;
    height: 100%;
    width: 100%;
`;

export const ghostButtonStyle = ({ colors: { interactible, font } }: IToken) => css`
    background-color: transparent;
    color: ${font.secondary};
    border: 1px solid ${interactible.secondary};
    box-sizing: border-box;

    &:hover {
        background-color: ${interactible.tertiary};
        color: ${font.primary};
    }
`;

export const linkButtonStyle = (token: IToken) => css`
    background-color: transparent;
    color: ${token.colors.font.primary};
    box-sizing: border-box;
    padding: 0;
    margin-right: 5px;
    & :last-child {
        margin-right: 0;
    }
    &:hover {
        text-decoration: underline;
    }
`;

export const tabButtonStyle = ({ colors: { background, interactible, font } }: IToken, selected?: boolean) => css`
    background-color: ${selected ? background.darker : background.dark};
    color: ${font.tertiary};
    border: 1px solid ${interactible.secondary};
    box-sizing: border-box;
    transition: all 500ms;
    &:hover {
        background-color: ${interactible.secondary};
    }
`;

export const hollowButtonStyle = ({ colors: { font, staticColors } }: IToken) => css`
    background-color: ${staticColors.none};
    border: unset;
    color: ${font.primary};

    &:hover {
        color: ${font.secondary};
    }
`;

export const solidButtonStyle = ({ colors: { interactible } }: IToken) => css`
    color: white;
    background-color: ${interactible.primary};
    box-sizing: border-box;
    &:hover:not(:disabled) {
        filter: brightness(105%);
        &:active {
            filter: brightness(95%);
        }
    }

    &:focus {
        filter: brightness(95%);
    }
`;

export const baseButtonStyle = (iconOnly: boolean) => css`
    width: ${iconOnly ? 'unset' : 'auto'};
    cursor: pointer;
    border: none;
    outline: none;
    box-sizing: border-box;
    font-size: 1rem;
`;

export const baseContentWrapperStyle = (tokens: IToken, size: ButtonSize, usingIcon: boolean) => css`
    display: ${usingIcon ? 'flex' : 'block'};
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    // overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    pointer-events: none;
    color: unset;
`;

export const iconWrapperStyle = css`
    background-color: transparent;
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
`;

export const disabledStyle = (token: IToken) => css`
    &:hover {
        cursor: unset;
        color: ${token.colors.font.tertiary};
    }
    color: ${token.colors.font.tertiary};
    background-color: ${token.colors.interactible.primary};
    opacity: 0.6;
`;

export const childrenWithIconStyle = css`
    flex: 0 1 auto;
    min-width: 0;
`;

export const circularButtonStyle = (token: IToken) => css`
    border-radius: 50%;
    min-height: 18px;
    max-height: 18px;
    width: 18px;
    height: 18px;
    background-color: ${chroma(token.colors.bar.resourceBarHeader)
        .alpha(0.1)
        .css()};

    color: ${token.colors.font.secondary};
    font-size: ${token.fontSizes.small};
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: content-box;
    transition: 0.5s;
    margin: 0 1px;
    & :hover {
        background-color: ${chroma(token.colors.bar.resourceBarHeader)
            .alpha(0.6)
            .css()};
        color: ${token.colors.font.tertiary};
    }
`;
export const baseIconStyle = css`
    pointer-events: none;
`;

export const iconWithChildrenStyle = css`
    margin-left: -8px;
    margin-right: 8px;
`;

export const reverseCircularButton = (token: IToken) => css`
    margin-right: 12px;
    background-color: ${chroma(token.colors.background.default)
        .alpha(0.9)
        .css()};
    color: ${token.colors.font.secondary};
`;
