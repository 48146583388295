import React from 'react';
import { Button, ButtonVariant } from '../../core-ui/button';
import {
    mainButtonStyle,
    mainButtonHeadingStyle,
    containerStyle,
    navCloseStyle,
    navButtonStyle
} from './DashboardDrawerHeader.style';
import { Size } from '../../core-ui/constants/Size';
import { useStyle } from '../../shared/hooks/useStyle';
import { IconTypes } from '../../core-ui/constants/IconTypes';

interface IDashboardDrawerHeaderProps {
    selectedTitleElement?: JSX.Element;
    onClickClose?: () => void;
    //drawerSize: DrawerSize;
}

const HEADER_HEIGHT = 40;

export const DashboardDrawerHeader: React.FC<IDashboardDrawerHeaderProps> = ({
    selectedTitleElement,
    onClickClose,
    children
}) => {
    const tokens = useStyle();

    return (
        <>
            <div css={containerStyle(tokens, HEADER_HEIGHT)}>
                <div css={mainButtonStyle}>
                    <span css={mainButtonHeadingStyle(tokens)}>{selectedTitleElement}</span>

                    <div css={navCloseStyle}>
                        <Button
                            variant={ButtonVariant.GHOST}
                            size={Size.SMALL}
                            icon={IconTypes.CHEVRON_RIGHT}
                            css={navButtonStyle(tokens)}
                            onClick={onClickClose}
                        />
                    </div>
                </div>
            </div>
            <div>{children}</div>
        </>
    );
};
