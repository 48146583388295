import React, { useState } from 'react';
import { Button, ButtonVariant } from '../../core-ui/button';
import { Size } from '../../core-ui/constants/Size';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { ObjectEditor } from '../../containers/objectEditor';
import { FieldTypes, IScriptObjectModel, getObjectDict } from '../../shared/motive/models/ScriptObjectModel';
import { createScriptObject } from '../../util/MotiveUtils';
import { useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo';
import { setWith, clone } from 'lodash-es';
import { quickObjectEditorWrapper, quickObjectEditorHeader, headingStyle } from './QuickObjectEditor.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { flexStyleRow } from '../frameInspectorBody/FrameInspectorBody.style';
import { reverseCircularButton } from '../../core-ui/button/Button.style';
import { getSmartName } from '../../util/ObjectDefinitionsUtils';
import { useCurrentLanguageSettings } from '../../shared/motive/stores/editorLocale/EditorLocale';
import { Text } from '../../core-ui/text';

export interface IQuickObjectEditorProps {
    type: string;
    onSave: (object: IScriptObjectModel) => void;
    onCancel: () => void;
    object?: IScriptObjectModel;
    title?: string;
    requiredFields?: string[];
}

export const QuickObjectEditor: React.FC<IQuickObjectEditorProps> = ({
    object,
    onSave,
    type,
    onCancel,
    requiredFields
}) => {
    const typeDefs = useObjectDefinitions();
    const language = useCurrentLanguageSettings();

    const initEditable = object ? { ...object } : createScriptObject(type, typeDefs);

    const [editorObject, setEditorObject] = useState(initEditable);

    const handleUpdateObject = (path: string, value: FieldTypes) => {
        setEditorObject({ ...setWith(editorObject, path, value, clone) });
    };

    const token = useStyle();

    const objDict = getObjectDict(editorObject);

    const isRequiredSet = (curr: boolean, fieldName: string) => {
        return !!objDict[fieldName];
    };

    const canSave = !requiredFields || requiredFields.reduce(isRequiredSet, true);

    return (
        <>
            <div css={quickObjectEditorHeader(token)}>
                <Text size={Size.LARGER} css={headingStyle}>
                    {getSmartName(editorObject, typeDefs, language)}
                </Text>
                <div css={flexStyleRow}>
                    <Button
                        css={reverseCircularButton(token)}
                        variant={ButtonVariant.CIRCULAR}
                        size={Size.SMALL}
                        icon={IconTypes.CHECKMARK}
                        disabled={!canSave}
                        onClick={() => {
                            if (editorObject) {
                                onSave(editorObject);
                            }
                        }}
                    />
                    <Button
                        css={reverseCircularButton(token)}
                        variant={ButtonVariant.CIRCULAR}
                        size={Size.SMALL}
                        icon={IconTypes.TIMES}
                        onClick={onCancel}
                    />
                </div>
            </div>
            <div css={quickObjectEditorWrapper(token)}>
                <ObjectEditor type={type} value={editorObject} onChange={handleUpdateObject} />
            </div>
        </>
    );
};
