import { useState, useCallback } from 'react';

/**
 * @description use to assign a ref object onto a DOM element.
 * Similar to useRef(), but assigning function to ref={} is better
 * for measuring width/height on resize etc.
 */
export function useCallbackRef<T extends HTMLElement>(): [T | undefined, (node: T) => void] {
    const [ref, setRef] = useState<T>();
    const setRefCallback = useCallback((node: T) => {
        if (node) {
            setRef(node);
        }
        return () => setRef(undefined);
    }, []);

    return [ref, setRefCallback];
}
