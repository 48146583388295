/* eslint-disable @typescript-eslint/no-empty-interface */
import { useContext } from 'react';
import React from 'react';
import { IChooseState, IChooseAction } from '../../shared/motive/reducers/ChooseExternalScriptScopeReducer';

export interface IChooseScriptScopeContextStateValue extends IChooseState {}
export interface IChooseScriptScopeContextDispatchValue extends React.Dispatch<IChooseAction> {}

const chooseScriptScopeContextState = React.createContext<IChooseScriptScopeContextStateValue>({
    state: 'off'
});
const chooseScriptScopeContextDispatch = React.createContext<IChooseScriptScopeContextDispatchValue>(() => undefined);

export const ChooseScriptScopeContextStateProvider = chooseScriptScopeContextState.Provider;
export const ChooseScriptScopeContextDispatchProvider = chooseScriptScopeContextDispatch.Provider;

export const useChooseScriptScopeState = () => useContext(chooseScriptScopeContextState);
export const useChooseScriptScopeDispatch = () => useContext(chooseScriptScopeContextDispatch);
