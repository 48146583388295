import { IToken } from '../../shared/contexts/styleContext';
import { css } from '@emotion/core';

export const BORDER_RADIUS = '5px';
export const BORDER_WIDTH = '5px';
export const BORDER_RADIUS_INNER = `calc(${BORDER_RADIUS} - ${BORDER_WIDTH})`;

const innerBodyCurvedBorderStyle = (borderColor: string) => css`
    border-left: ${BORDER_WIDTH} solid ${borderColor};
    border-radius: ${BORDER_RADIUS};
`;

export const defaultCardStyle = ({
    colors: {
        bar: { resourceBarHeader }
    }
}: IToken) => css`
    position: relative;
    ${innerBodyCurvedBorderStyle(resourceBarHeader)}
`;

export const defaultBodyBackgroundStyle = ({ colors }: IToken) => css`
    background-color: ${colors.background.lighter};
    position: relative;
    border-radius: 0 0 ${BORDER_RADIUS_INNER} ${BORDER_RADIUS_INNER};
`;

export const flex1Style = css`
    flex: 1;
`;

export const headingBarStyle = (token: IToken) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    //font-size:0.8em;
    border-radius: ${BORDER_RADIUS_INNER} ${BORDER_RADIUS_INNER} 0 0;
    padding: ${token.spacings.smallest} ${token.spacings.smallest};
    & > button {
        color: ${token.colors.font.primary};
    }
`;

export const toggleStyle = ({ spacings }: IToken) => css`
    padding: 0 ${spacings.smaller};
`;

export const noteWrapperStyle = ({ spacings }: IToken) => css`
    padding: ${spacings.smaller} ${spacings.smaller} 0 ${spacings.smaller};
`;

export const noteTextAreaStyle = ({ spacings }: IToken) => css`
    height: ${spacings.small};
`;
export const iconStyle = (token: IToken) => css`
    color: ${token.colors.font.secondary};
    font-size: ${token.fontSizes.small};
`;
export const isDisabledOverlayStyle = (wrapperIsEnabled: boolean) => css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    transition: opacity 250ms ease;

    ${wrapperIsEnabled
        ? `
        opacity: 0;
        pointer-events: none;
    `
        : `
        opacity: 1;
    `}
`;

export const objectTypeIdStyle = (token: IToken) => css`
    padding-left: ${token.spacings.small};
    font-size: ${token.fontSizes.small};
    color: ${token.colors.font.secondary};
`;

export const defaultBodyPaddingStyle = ({ spacings }: IToken) => css`
    padding: ${spacings.smaller};
    padding-bottom: ${spacings.smaller};
`;

export const subHeadingToolsLayout = css`
    display: flex;
    flex-direction: row;
`;

export const subHeadingToolsLayoutRight = css`
    display: flex;
    flex-direction: row-reverse;
    flex-basis: 100%;
`;
