import { IToken } from '../../shared/contexts/styleContext';
import { css } from '@emotion/core';

export const subTitleStyle = ({ colors: { font }, fontSizes }: IToken) => css`
    color: ${font.primary};
    font-size: ${fontSizes.small};
`;

export const rowStyle = ({ colors, colors: { border }, spacings }: IToken) => css`
    border-bottom: 1px solid ${border.primary};
    padding: ${spacings.medium};

    &:hover {
        background-color: ${colors.background.lighter};
        .iconWrapper {
            opacity: 1;
        }
    }
`;

export const containerStyle = ({ spacings }: IToken) => css`
    padding: ${spacings.large} ${spacings.small};
`;

export const subcontentStyle = (tokens: IToken) => css`
    color: ${tokens.colors.font.secondary};
    font-size: ${tokens.fontSizes.small};
`;

export const projectItemIcon = css`
    transform: rotate(-90deg);
    color: lightgray;
`;

export const iconWrapperStyle = css`
    display: flex;
    flex-direction: row-reverse;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
`;
