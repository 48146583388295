import { SerializedStyles } from '@emotion/core';
import { PillSize, Size } from '../constants/Size';
import React from 'react';
import { Tag } from '@chakra-ui/core';
import { getChakraSize } from '../utils/SizeMapping';
import { Button, ButtonVariant } from '../button';
import { IconTypes } from '../constants/IconTypes';
import { useStyle } from '../../shared/hooks/useStyle';
import { pillStyle } from './Pill.style';

interface IPillProps {
    size?: PillSize;
    onClickClose?: () => void;
    className?: string;
    iconStyle?: SerializedStyles;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

export const Pill: React.FC<IPillProps> = ({
    children,
    onClickClose,
    size,
    iconStyle,
    onMouseEnter,
    onMouseLeave,
    className
}): React.ReactElement => {
    const { colors } = useStyle();

    return (
        <Tag
            className={className}
            size={getChakraSize(size) as 'sm' | 'md' | 'lg'}
            rounded={'20px'}
            variant={'solid'}
            color={colors.font.primary}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            css={pillStyle}
        >
            {children}
            {onClickClose && (
                <Button
                    iconStyle={iconStyle}
                    variant={ButtonVariant.HOLLOW}
                    size={Size.SMALL}
                    icon={IconTypes.TIMES}
                    onClick={onClickClose}
                />
            )}
        </Tag>
    );
};
