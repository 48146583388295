import { storyFlowLogoStyle, outerParentRatioStyle, constWidthContainer } from './StoryFlowLogo.style';
import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import React from 'react';
import { useWindowDimensions } from '../../shared/hooks/useWindowDimensions';
import { isWithinRangeNoFloor, BreakPoint } from '../constants/Breakpoints';

const storyFlowLogoBreakPoint: BreakPoint = BreakPoint.Small;

const srcLarge = '/img/storyflow-white.svg';
const srcSmall = '/logo512.png';

export interface IStoryFlowLogoProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    maxHeight?: number; // px
}
export const StoryFlowLogo: React.FC<IStoryFlowLogoProps> = ({ maxHeight, ...props }): React.ReactElement => {
    const { width } = useWindowDimensions();

    const isInSmallerRange = isWithinRangeNoFloor(storyFlowLogoBreakPoint, width);

    const src = isInSmallerRange ? srcSmall : srcLarge;

    return (
        <div css={constWidthContainer(isInSmallerRange, maxHeight)}>
            <div css={outerParentRatioStyle(isInSmallerRange)}>
                <img {...props} css={storyFlowLogoStyle} alt="StoryFlow" src={src} />
            </div>
        </div>
    );
};
