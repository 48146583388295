import { SerializedStyles } from '@emotion/core';
import { ReactNode } from 'react';
import {
    containerInactiveStyle,
    containerActiveStyle,
    containerStyle,
    leftStyle,
    middleStyle,
    rightStyle,
    subContentStyle,
    onPressStyle
} from './RowItem.style';
import { smallRowItemStyle, mediumRowItemStyle, largeRowItemStyle } from './RowItem.style';
import { RowItemSize, Size } from '../constants/Size';
import React from 'react';
import { useStyle } from '../../shared/hooks/useStyle';

const PROP_KEY_CONTENT = 'content';

interface IRowItemChildrenSlots {
    /**
     * @description The main content node.
     */
    [PROP_KEY_CONTENT]: ReactNode;
    /**
     * @description The secondary content node.
     */
    subContent?: ReactNode;
    /**
     * @description A *tertiary* content node, positioned on the left.
     */
    leftContent?: ReactNode;
    /**
     * @description A *tertiary* content node, positioned on the right.
     */
    rightContent?: ReactNode;
}
type RowItemGetSlotsFunction = () => IRowItemChildrenSlots;
type RowItemChildrenPropType = ReactNode | RowItemGetSlotsFunction;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isGetSlotsFunction(children: any): children is RowItemGetSlotsFunction {
    if (typeof children === 'function') {
        const ret = children();
        return ret && typeof ret === 'object' && PROP_KEY_CONTENT in ret;
    }
    return false;
}

const getRowItemSizeStyle = (size?: RowItemSize): SerializedStyles | undefined => {
    switch (size) {
        case Size.SMALL:
            return smallRowItemStyle;
        case Size.MEDIUM:
            return mediumRowItemStyle;
        case Size.LARGE:
            return largeRowItemStyle;
        default:
            throw new Error('An invalid size enumerator was passed into getRowItemSizeStyle().');
    }
};

export interface IRowItemProps {
    children: RowItemChildrenPropType;
    /**
     * @description Is this item *activated* (i.e. selected).
     *
     * This component provides a default style for active states.
     * These style values can be overrided with the `css` and `activeCss` props.
     */
    active?: boolean;
    /**
     * @description Override the default *not activated* styles.
     */
    /**
     * @description Override the default *activated* styles.
     */
    activeCss?: SerializedStyles;
    size?: RowItemSize;
    onClick?: () => void;
    testId?: string;
    className?: string;
    leftStyle?: SerializedStyles;
    middleStyle?: SerializedStyles;
    rightStyle?: SerializedStyles;
}

export const RowItem: React.FC<IRowItemProps> = ({
    active,
    activeCss,
    children,
    size,
    testId,
    className,
    onClick,
    leftStyle: leftStyleProp,
    middleStyle: middleStyleProp,
    rightStyle: rightStyleProp
}) => {
    const tokens = useStyle();
    let content: ReactNode = children;
    let subContent: ReactNode;
    let leftContent: ReactNode;
    let rightContent: ReactNode;

    if (isGetSlotsFunction(children)) {
        ({ content, subContent, leftContent, rightContent } = children());
    }

    return (
        <div
            onClick={onClick}
            css={[
                onClick && onPressStyle(tokens),
                containerStyle,
                size && getRowItemSizeStyle(size),
                ...(active ? [containerActiveStyle, activeCss] : [containerInactiveStyle])
            ]}
            className={className}
            data-testid={testId ? testId : ''}
        >
            {leftContent && <div css={[leftStyle, leftStyleProp]}>{leftContent}</div>}
            <div css={[middleStyle, middleStyleProp]}>
                <div>{content}</div>
                {subContent && <div css={subContentStyle}>{subContent}</div>}
            </div>
            {rightContent && <div css={[rightStyle, rightStyleProp]}>{rightContent}</div>}
        </div>
    );
};
