import React from 'react';
import { useRoute, useEditorRouteMatch } from '../useRoute';
import { IProjectsRouteParams } from '../projectsRoute';
import { BackupContainer } from '../../containers/backupContainer/BackupContainer';
import { renderSubRoute } from '../editorRoutes';

export const useBackupRoute = () => useRoute<IProjectsRouteParams>('Backup');
export const useBackupRouteMatch = () => useEditorRouteMatch<IProjectsRouteParams>('Backup');

export const BackupRoute: React.FC = () => {
    const {
        subRoutes,
        params: { projectName }
    } = useBackupRoute();

    return (
        <>
            {projectName && <BackupContainer projectId={projectName}></BackupContainer>}
            {subRoutes.map(renderSubRoute)}
        </>
    );
};
