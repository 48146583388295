import React from 'react';
import { useChooseMediaSourceState, useChooseMediaSourceDispatch } from '../../hooks/useChooseMediaSource';
import ReactModal from 'react-modal';
import { MediaManagementContainer } from '../mediaManagementContainer';
import { Button } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { IHostedMediaSource } from '../../shared/motive/models/MediaItem';

export const ChooseMediaSourceModal: React.FC = () => {
    const { state } = useChooseMediaSourceState();
    const dispatch = useChooseMediaSourceDispatch();

    const handleSelectItem = (item: IHostedMediaSource) => {
        dispatch({
            actionType: 'choose',
            chosenValue: {
                chooseType: 'mediaSource',
                chosenItem: item.id
            }
        });
    };

    return (
        <>
            <ReactModal isOpen={state === 'choosing'}>
                <Button icon={IconTypes.CLOSE} onClick={() => dispatch({ actionType: 'cancelChoose' })} />
                <MediaManagementContainer onSelectItem={handleSelectItem} />
            </ReactModal>
        </>
    );
};
