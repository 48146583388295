import { IToken } from '../../shared/contexts/styleContext';
import { css } from '@emotion/core';
import { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';

export const defaultConditionMessage = (token: IToken) => css`
    color: ${token.conditionColors.background.neutral};
    text-align: center;
    padding: 30px;
    background: ${token.conditionColors.background.lighter};
    border: 1px dashed ${token.conditionColors.background.neutral};
`;

export const dropPlaceHolderParentStyle = (
    provided: DroppableProvided,
    snapShot: DroppableStateSnapshot,
    hasCondition: boolean
) => css`
    ${hasCondition && 'display: none;'}
`;
