import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';
import { middleContentBaseStyle } from '../dynamicRowBase/DynamicRowBase.style';

const NODE_COLOR = '#4cadac';
const HOVER_NODE_COLOR = '#3fbfbe';

export const dynamicsWorkAreaNarrow = css`
    padding: 5px;
    max-width: 100%;
    width: 500px;
    margin: 0 auto;
`;

export const scenarioNodeRowStyle = (token: IToken) => css`
    margin-bottom: ${token.spacings.smallest};
    display: grid;
    align-items: center;
`;

export const rowMiddleItemStyle1 = css`
    width: 50%;
    font-size: 0.95rem;
    display: flex;
    flex-direction: column;
    padding-left: 2px;
`;

export const numberInputStyle = css`
    max-width: 10ex;
`;

export const rowRightContent = css`
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const nodeLineStyle = css`
    fill: none;
    stroke-width: 2;
    stroke: ${NODE_COLOR};
`;

export const scenarioMiniItemStyle = css`
    background-color: ${NODE_COLOR};
    border-radius: 50%;
    height: 30px;
    width: 30px;
`;

export const nodeButtonStyle = css`
    background-color: ${NODE_COLOR};
    color: white;
    border-radius: 50%;
    &:hover {
        background-color: ${HOVER_NODE_COLOR};
    }
`;

export const collapsibleLayoutStyle = css`
    padding: 0;
    display: grid;
    grid-template-columns: 9fr 1fr;
    font-size: 1rem;
    align-items: center;
    grid-template-areas: 'condition rightCol';
`;

export const rootNodeStyle = css`
    &:hover {
        cursor: pointer;
    }
`;

export const middleContentStyle = css`
    ${middleContentBaseStyle};
    background-color: ${NODE_COLOR};
    &:hover {
        background-color: ${HOVER_NODE_COLOR};
    }
`;

export const deleteButtonIconStyle = css`
    color: white;
    &:hover {
        color: #dcdcdc;
    }
`;

export const conditionArea = (offset: number) => css`
    grid-area: condition;
    background-color: white;
    border-radius: 8px;
    padding: 36px 0;
    margin-left: ${offset}px;
`;

const rowChildStyle = css`
    display: flex;
    align-items: center;
    height: 21px;
`;

export const topRowChildStyle = css`
    ${rowChildStyle};
`;

export const bottomRowChildStyle = css`
    ${rowChildStyle};
    margin-top: 4px;
    padding-right: 4px;
`;

export const scenarioNameStyle = css`
    font-size: 0.95rem;
`;

export const dynamicIconsStyle = css`
    font-size: 0.7rem;
`;

export const verticalDividerParent = css`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
`;

export const verticalDividerStyle = css`
    width: 2px;
    margin: 0 6px;
    height: 12px;
    background: #148c8a;
`;
