import { IScript, IScriptVariable } from '../../../shared/motive/models/Script';
import { ITypeDefinition, IObjectDefinition, IValueDefinition } from '../../../shared/motive/models/TypeDefinition';
import { createObjectReference, createObjectReferenceFromObject, isTypeOrImplementor } from '../../../util/MotiveUtils';
import { Dropdown } from '../../../core-ui/dropdown';
import React, { useState } from 'react';
import { useTypeDefinition } from '../../../shared/motive/hooks/useScriptEditorInfo';
import { Button, ButtonVariant } from '../../../core-ui/button';
import { IFieldEditorProps } from '../ObjectEditor';
import { IObjectReference } from '../../../shared/motive/models/ObjectReference';
import { MotiveTypes } from '../../../constants/MotiveTypes';
import { createNewVariable } from '../../../util/ScriptDynamicsUtil';
import { flexStyleRow } from '../../../components/frameInspectorBody/FrameInspectorBody.style';
import { Size } from '../../../core-ui/constants/Size';
import { useGroupedScriptVariableDropdownOptions } from '../../../shared/motive/hooks/useGroupedScriptAndGlobalVariables/useGroupedScriptAndGlobalVariables';
import { InputField } from '../../../core-ui/inputField';
import { IconTypes } from '../../../core-ui/constants/IconTypes';
import { useDispatch, useSelector } from 'react-redux';
import { SelectScriptEditorCurrentScript } from '../../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';

export const getScriptVariablesForType = (script: IScript, typeDefinition: ITypeDefinition): IScriptVariable[] => {
    if (script.variables) {
        return script.variables.filter(v => {
            if (typeDefinition.dataType === 'object') {
                const objDef = typeDefinition as IObjectDefinition;

                // We want to know if objDef *implements* the variable type
                return isTypeOrImplementor(v.valueDefinition?.typeName, objDef);
            } else {
                return typeDefinition.name === v.valueDefinition?.typeName;
            }
        });
    }

    return [];
};

export interface IFieldVariableDropdownProps {
    script: IScript;
    valueDefinition: IValueDefinition;
    variableId: string | undefined;
    onChange: (variableId?: string) => void;
    onCreateNew?: (name: string) => void;
    defaultVariableName?: string;
}

export const FieldVariableDropdown: React.FC<IFieldVariableDropdownProps> = ({
    valueDefinition,
    variableId,
    onChange,
    onCreateNew,
    defaultVariableName
}) => {
    const [isCreatingNew, setIsCreatingNew] = useState(false);

    const [newVarName, setNewVarName] = useState(defaultVariableName ?? 'New Variable');

    const typeDefinition = useTypeDefinition(valueDefinition?.typeName);

    const dropOptions = useGroupedScriptVariableDropdownOptions(valueDefinition?.typeName);

    if (!typeDefinition) return <>no typedef for {valueDefinition?.typeName}</>;

    const handleCreateNew = () => {
        onCreateNew?.(newVarName);
        setIsCreatingNew(false);
    };

    return (
        <>
            <div css={flexStyleRow}>
                {isCreatingNew ? (
                    <InputField defaultValue={newVarName} onChange={val => setNewVarName(val)}></InputField>
                ) : (
                    <Dropdown
                        grouped={true}
                        options={dropOptions}
                        value={variableId}
                        instructions="Select Variable"
                        onChange={onChange}
                    />
                )}
                {onCreateNew && (
                    <>
                        {isCreatingNew ? (
                            <>
                                <Button
                                    onClick={() => setIsCreatingNew(false)}
                                    variant={ButtonVariant.CIRCULAR}
                                    size={Size.SMALL}
                                    icon={IconTypes.TIMES}
                                    css={() => `margin-left:5px`}
                                ></Button>
                                <Button
                                    onClick={handleCreateNew}
                                    variant={ButtonVariant.CIRCULAR}
                                    size={Size.SMALL}
                                    icon={IconTypes.CHECKMARK}
                                    css={() => `margin-left:5px`}
                                ></Button>
                            </>
                        ) : (
                            <Button
                                onClick={() => setIsCreatingNew(true)}
                                variant={ButtonVariant.CIRCULAR}
                                size={Size.SMALL}
                                icon={IconTypes.PLUS}
                                css={() => `margin-left:5px`}
                            ></Button>
                        )}
                    </>
                )}
            </div>
        </>
    );
};
export interface IFieldVariableEditorProps extends IFieldEditorProps<IObjectReference> {
    valueDefinition?: IValueDefinition;
}

export const FieldVariableEditor: React.FC<IFieldVariableEditorProps> = ({
    parentObjectEditorProps,
    fieldDefinition,
    valueDefinition,
    value,
    onChange
}) => {
    const scriptDispatch = useDispatch();
    const script = useSelector(SelectScriptEditorCurrentScript);

    const handleCreateNew = (name?: string) => {
        if (script) {
            const variable = createNewVariable(
                script,
                valueDefinition ?? fieldDefinition,
                name ?? `${parentObjectEditorProps.value?.id}.${fieldDefinition.name}`,
                scriptDispatch
            );

            onChange(createObjectReferenceFromObject(variable));
        }
    };

    const handleUpdate = (varId: string | undefined) => {
        if (!!varId) {
            const objRef: IObjectReference = createObjectReference(MotiveTypes.SCRIPT_VARIABLE, varId);

            onChange(objRef);
        } else {
            onChange(undefined);
        }
    };

    return (
        <>
            {script && (
                <FieldVariableDropdown
                    onChange={handleUpdate}
                    onCreateNew={handleCreateNew}
                    script={script}
                    valueDefinition={valueDefinition ?? fieldDefinition}
                    variableId={value?.objectId}
                />
            )}
        </>
    );
};
