import React, { useState } from 'react';
import { IEnumDefinition } from '../../shared/motive/models/EnumDefinition';
import { RowItem } from '../../core-ui/rowItem';
import { Button } from '../../core-ui/button';
import { Size } from '../../core-ui/constants/Size';
import { EnumDefinitionItemContainer } from '../../containers/enumDefinitinonsContainer/EnumDefinitionsContainer';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { EditableText } from '../../core-ui/editableText';
import {
    editableTextWrapperStyle,
    titleStyle,
    enumListParentStyle,
    enumListStyle,
    enumItemListStyle,
    spaceEnumItemStyle,
    checkboxAndButtonStyle,
    iconAndNameStyle,
    checkboxStyle,
    editableTextTextStyle,
    importedEnumItemStyle,
    disabledButtonStyle
} from './EnumDefinitions.style';
import { getSystemName } from '../../util/MotiveUtils';
import { Checkbox } from '../../core-ui/checkbox';
import { Collapsible } from '../../core-ui/collapse';
import { Icon } from '../../core-ui/icon';
import { Text } from '../../core-ui/text';
import { useStyle } from '../../shared/hooks/useStyle';
import { rotateChevron } from '../../constants/AnimationStyles';
import { IUseCreateEnumDefinitionParams } from '../../shared/motive/hooks/useCreateEnumDefinition/useCreateEnumDefinition';
import { CreateEnumDefinition } from '../createEnumDefinition';
import { CreateEnumDefinitionItem } from '../createEnumDefinitionItem';
import { EnumDefinitionItem } from '../enumDefinitionItem';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useTranslation } from 'react-i18next';
import { useGetAllCatalogsByType } from '../../shared/motive/hooks/useCatalogs';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { IScript } from '../../shared/motive/models/Script';

interface IEnumDefinitionsProps {
    spaceEnums: IEnumDefinition[];
    importedEnums: IEnumDefinition[];
    onCreateEnum: IHookProvidedCall<IUseCreateEnumDefinitionParams, IEnumDefinition | undefined>;
}

export const EnumDefinitions: React.FC<IEnumDefinitionsProps> = ({ spaceEnums, importedEnums, onCreateEnum }) => {
    const [areSpaceEnumsShowing, setSpaceEnumsShowing] = useState(true);
    const [areImportedEnumsShowing, setImportedEnumsShowing] = useState(true);

    const chevron = IconTypes.CHEVRON_DOWN;

    const token = useStyle();
    const { t } = useTranslation();

    return (
        <>
            <div css={enumListParentStyle(token)}>
                <RowItem onClick={() => setSpaceEnumsShowing(!areSpaceEnumsShowing)}>
                    <div css={titleStyle}>
                        <Icon icon={chevron} iconStyle={rotateChevron(areSpaceEnumsShowing)} />
                        <span>{t('spaceEnums')}</span>
                    </div>
                </RowItem>
                <Collapsible isShowing={areSpaceEnumsShowing} css={enumListStyle(token)}>
                    {spaceEnums.map(definition => (
                        <SpaceEnumDefinitions enumDef={definition} key={definition.id} />
                    ))}
                    <CreateEnumDefinition createEnumCall={onCreateEnum} />
                </Collapsible>
            </div>
            <div css={enumListParentStyle(token)}>
                <RowItem onClick={() => setImportedEnumsShowing(!areImportedEnumsShowing)}>
                    <div css={titleStyle}>
                        <Icon icon={chevron} iconStyle={rotateChevron(areImportedEnumsShowing)} />
                        <span>{t('importedEnums')}</span>
                    </div>
                </RowItem>
                <Collapsible isShowing={areImportedEnumsShowing} css={enumListStyle(token)}>
                    {importedEnums
                        .filter(e => e.isExtendable)
                        .map(definition => (
                            <ImportedEnumDefinitions enumDef={definition} key={definition.id} />
                        ))}
                </Collapsible>
            </div>
        </>
    );
};

interface ISplitEnumsProps {
    enumDef: IEnumDefinition;
}

const SpaceEnumDefinitions: React.FC<ISplitEnumsProps> = ({ enumDef }) => {
    const [isItemShowing, setItemShowing] = useState(false);
    const [enumDefinitionStatus, setEnumDefinitionStatus] = useState<string>('');
    const { t } = useTranslation();

    const chevron = IconTypes.CHEVRON_DOWN;

    const token = useStyle();

    const { data } = useGetAllCatalogsByType(MotiveTypes.MOTIVE_SCRIPT);

    const scripts = (data as ICatalog<IScript>[]).flatMap(cat => cat.items?.map(item => item));

    const isScriptVariable = (scripts as IScript[]).some(script =>
        script.variables?.some(variable => variable.valueDefinition.typeName === enumDef.name)
    );

    const enumDefItemNames = enumDef.items?.map(item => item.name.toLowerCase());

    return (
        <EnumDefinitionItemContainer>
            {({ deleteEnumDefinition, updateEnumDefinition, createEnumDefinitionItem }) => (
                <>
                    <RowItem onClick={() => setItemShowing(!isItemShowing)}>
                        <div css={spaceEnumItemStyle}>
                            <div css={iconAndNameStyle}>
                                <Icon icon={chevron} iconStyle={rotateChevron(isItemShowing)} />
                                <EditableText
                                    wrapperStyle={editableTextWrapperStyle()}
                                    textStyle={editableTextTextStyle}
                                    inputStyle={editableTextTextStyle}
                                    placeholder={enumDefinitionStatus ? enumDefinitionStatus : ''}
                                    defaultValue={
                                        !enumDefinitionStatus
                                            ? enumDef.editorInfo?.title
                                                ? enumDef.editorInfo.title
                                                : enumDef.name.substring(enumDef.name.lastIndexOf('.') + 1)
                                            : ''
                                    }
                                    onEditComplete={val => {
                                        if (
                                            val === enumDef.editorInfo?.title ||
                                            val === enumDef.name.substring(enumDef.name.lastIndexOf('.') + 1)
                                        ) {
                                            return;
                                        }

                                        setEnumDefinitionStatus(t('updating'));

                                        const oldName = enumDef.name.substring(0, enumDef.name.lastIndexOf('.'));
                                        const name = `${oldName}.${getSystemName(val)}`;

                                        updateEnumDefinition
                                            .execute({
                                                updatedItem: { ...enumDef, name: name, editorInfo: { title: val } },
                                                enumId: enumDef.id
                                            })
                                            .then(() => setEnumDefinitionStatus(''));
                                    }}
                                    disabled={isScriptVariable}
                                />
                            </div>
                            <div css={checkboxAndButtonStyle}>
                                <Checkbox
                                    labelText="Extendable"
                                    css={checkboxStyle}
                                    isChecked={!!enumDef.isExtendable}
                                    size={Size.SMALL}
                                    onClick={() => {
                                        updateEnumDefinition.execute({
                                            updatedItem: { ...enumDef, isExtendable: !enumDef.isExtendable },
                                            enumId: enumDef.id
                                        });
                                    }}
                                ></Checkbox>
                                <Button
                                    onClick={() => {
                                        setEnumDefinitionStatus(t('deleting'));

                                        deleteEnumDefinition
                                            .execute({ enumId: enumDef.id })
                                            .then(() => setEnumDefinitionStatus(''));
                                    }}
                                    disabledStyle={disabledButtonStyle}
                                    icon={IconTypes.DELETE}
                                    disabled={isScriptVariable}
                                    title={isScriptVariable ? t('cannotBeDeleted') : t('delete')}
                                />
                            </div>
                        </div>
                    </RowItem>
                    <Collapsible isShowing={isItemShowing} css={enumItemListStyle(token)}>
                        {enumDef.items?.map(item => {
                            return (
                                <EnumDefinitionItem
                                    enumId={enumDef.id}
                                    item={item}
                                    key={item.name}
                                    enumDefItemNames={enumDefItemNames}
                                />
                            );
                        })}

                        <CreateEnumDefinitionItem
                            enumId={enumDef.id}
                            onCreateEnumItem={createEnumDefinitionItem}
                            enumDefItemNames={enumDefItemNames}
                        />
                    </Collapsible>
                </>
            )}
        </EnumDefinitionItemContainer>
    );
};

const ImportedEnumDefinitions: React.FC<ISplitEnumsProps> = ({ enumDef }) => {
    const [isItemShowing, setItemShowing] = useState(false);

    const chevron = IconTypes.CHEVRON_DOWN;

    const token = useStyle();

    return (
        <EnumDefinitionItemContainer>
            {({ createEnumDefinitionItem }) => (
                <>
                    <RowItem
                        onClick={() => {
                            setItemShowing(!isItemShowing);
                        }}
                    >
                        <div css={importedEnumItemStyle}>
                            <Icon icon={chevron} iconStyle={rotateChevron(isItemShowing)} />
                            <Text css={editableTextTextStyle}>
                                {enumDef.editorInfo?.title
                                    ? enumDef.editorInfo?.title
                                    : enumDef.name.substring(enumDef.name.lastIndexOf('.') + 1)}
                            </Text>
                        </div>
                    </RowItem>
                    <Collapsible isShowing={isItemShowing} css={enumItemListStyle(token)}>
                        {enumDef.items?.map(item => {
                            return <EnumDefinitionItem enumId={enumDef.id} item={item} key={item.name} />;
                        })}
                        <CreateEnumDefinitionItem onCreateEnumItem={createEnumDefinitionItem} enumId={enumDef.id} />
                    </Collapsible>
                </>
            )}
        </EnumDefinitionItemContainer>
    );
};
