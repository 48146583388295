import { useCurrentSpace } from '../useCurrentSpace';
import { getBundles } from '../../networking/BundleService';
import { IHookProvidedDataSource } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallForDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { useDispatch, useSelector } from 'react-redux';
import { SelectBundles, SelectBundleStatus } from '../../../../redux/spaceKeyed/bundle/BundleSelectors';
import { IBundleEntity } from '../../../../redux/spaceKeyed/bundle/BundleReducer';

export const useBundles = (projectId: string): IHookProvidedDataSource<IBundleEntity[]> => {
    const [currentSpace] = useCurrentSpace();

    const bundleDispatch = useDispatch();

    const bundles = useSelector(SelectBundles);
    const bundleStatus = useSelector(SelectBundleStatus);

    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallForDataSource(
        bundles,
        () => bundleStatus.loadingState === 'loading'
    );

    const call = async () => {
        await triggerCall(getBundles(currentSpace, projectId, getCancelableDispatch(bundleDispatch)));
    };

    return {
        ...rest,
        data: Object.values(bundles),
        load: call,
        isLoading: bundleStatus.loadingState === 'loading',
        error: bundleStatus.error
    };
};
