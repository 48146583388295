import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const rowStyle = ({ colors, colors: { border }, spacings }: IToken) => css`
    border-bottom: 1px solid ${border.primary};
    padding: ${spacings.medium};

    &:hover {
        background-color: ${colors.background.lighter};
        .iconWrapper {
            opacity: 1;
        }
    }
`;

export const subcontentStyle = (token: IToken) => css`
    color: ${token.colors.font.secondary};
    font-size: ${token.fontSizes.small};
    padding: ${token.spacings.smallest};
    margin: ${token.spacings.smallest};
    border: 2px solid transparent;
    display: flex;
    align-items: center;
`;

export const chevronIconStyle = css`
    transform: rotate(-90deg);
    color: lightgray;
`;

export const renameWrapperStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const renameButtonStyle = ({ spacings }: IToken) => css`
    margin-left: ${spacings.smaller};
`;

export const deleteButtonIconStyle = (token: IToken) => css`
    color: ${token.colors.font.secondary};
`;

export const deleteButtonStyle = (token: IToken) => css`
    width: 25%;
    border-radius: ${token.spacings.larger};
    transition-duration: 150ms;
    &:hover {
        background-color: rgba(255, 0, 0, 0.75);
    }
`;

export const deleteButtonWrapperStyle = css`
    display: flex;
    justify-content: flex-end;
`;

export const catalogTitleWrapperStyle = (token: IToken) => css`
    padding: ${token.spacings.smallest};
    margin: ${token.spacings.smallest};
    border: 1px solid transparent;
    border-radius: ${token.spacings.smallest};
    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: rgba(0, 0, 0, 0.1);
    }
`;
export const exclamationIconStyle = css`
    padding: 1px 3px 0 0;
    color: #ffa500c4;
`;
