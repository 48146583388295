import { ITypeDefinitionMap, IObjectDefinition } from '../shared/motive/models/TypeDefinition';
import { IResourceWrapper, ICondition } from '../shared/motive/models/Script';
import { getObjectIcon, RESOURCE_ICON_DEFAULT, CONDITION_ICON_DEFAULT, getSmartName } from './ObjectDefinitionsUtils';
import { ILanguageSettings } from '../shared/motive/stores/editorLocale/EditorLocale';

export const getResourceIcons = (
    typeDefs: ITypeDefinitionMap,
    language: ILanguageSettings,
    resources?: IResourceWrapper[] | null
): { icon: string; name: string; resourceWrapper: IResourceWrapper }[] => {
    const icons: { icon: string; name: string; resourceWrapper: IResourceWrapper }[] = [];
    resources?.forEach(resource => {
        if (resource) {
            const icon = getObjectIcon(typeDefs, resource.resource, RESOURCE_ICON_DEFAULT);

            icon &&
                icons.push({
                    icon: icon,
                    name: resource.label ?? getSmartName(resource.resource, typeDefs, language),
                    //getResourceDisplayName(resource, typeDefs, language) ?? '', TODO: in some cases resource display
                    // name would be better, but currently we can't get the script here without causing re-renders
                    // on any component calling this that uses a script as a prop
                    resourceWrapper: resource
                });
        }
    });

    return icons;
};

export const getConditionIcons = (
    typeDefs: ITypeDefinitionMap,
    preCondition?: ICondition | null
): { icon: string; name: string } => {
    if (preCondition) {
        const objectDef = typeDefs[preCondition.type] as IObjectDefinition;

        const icon = getObjectIcon(typeDefs, preCondition, CONDITION_ICON_DEFAULT);
        const icons = { icon: icon ?? '', name: objectDef.editorInfo?.title ?? '' };

        return icons;
    }

    return { icon: '', name: '' };
};
