import { setCurrentSpaceAction, unsetCurrentSpaceAction } from '../../../../redux/spaceKeyed/spaceKeyedStateActions';
import { SelectCurrentSpace } from '../../../../redux/spaceKeyed/spaceKeyedSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';

export const useCurrentSpace: () => [string, (currentSpace?: string) => void] = () => {
    const currentSpace = useSelector(SelectCurrentSpace) as string;
    const dispatch = useDispatch();

    const setCurrentSpace = useCallback(
        (newSpace?: string) => {
            if (newSpace) {
                dispatch(setCurrentSpaceAction(newSpace));
            } else {
                dispatch(unsetCurrentSpaceAction());
            }
        },
        [dispatch]
    );

    return [currentSpace, setCurrentSpace];
};
