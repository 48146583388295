import React, { useState, useMemo } from 'react';
import { IScript, IScriptEventDefinition, IScriptInfo } from '../../shared/motive/models/Script';
import { Heading } from '../../core-ui/heading';
import { ObjectEditor, IObjectEditorProps } from '../objectEditor';
import { useScriptEditorInfo } from '../../shared/motive/hooks/useScriptEditorInfo';
import { Size } from '../../core-ui/constants/Size';
import {
    inlineStyle,
    editButtonStyle,
    textNoOverflowStyle,
    headingRenderContainerStyle,
    outerHeadingsAndVarsLayoutStyle,
    eventObjectWrapperStyle,
    optionsEditorStyle,
    addScriptButtonStyle,
    variableObjectEditorStyle,
    inputStickyPositionStyle,
    inputStyle,
    eventHeadingStyle,
    animateEventBgStyle,
    eventObjectWrapperBgStyle
} from './ScriptEventDefinitionsContainer.style';
import { Button, ButtonVariant } from '../../core-ui/button';
import { ScriptActionType } from '../../shared/motive/reducers/ScriptEditorReducers';
import { clone, cloneDeep, setWith } from 'lodash-es';
import { Collapsible } from '../../core-ui/collapse';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { useStyle } from '../../shared/hooks/useStyle';
import { IScriptEditorInfo } from '../../shared/motive/models/ScriptEditorInfo';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { QuickObjectEditor } from '../../components/quickObjectEditor';
import { Logger } from '../../util/logger';
import { IHookProvidedDataSource } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { InputField } from '../../core-ui/inputField';
import { checkFilter } from '../../util/FilterUtils';
import { HeightUsedScrollContainer } from '../heightUsedScrollContainer';
import { Text } from '../../core-ui/text';
import { useDispatch, useSelector } from 'react-redux';
import { SelectCatalogsWithTypeFilter } from '../../redux/spaceKeyed/catalog/CatalogSelectors';
import { SelectScriptEditorCurrentScript } from '../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';
import { Drop } from '../../components/_common/DragAndDrop/Drop/Drop';
import { CloneDrag } from '../../components/_common/DragAndDrop/CloneDrag/CloneDrag';
import { IObjectInstanceDrag } from '../../components/_common/DragAndDrop/DragModels/DragModels';
import {
    DRAG_ITEM_TYPE_SCRIPT_EVENT,
    DROPPABLE_ID_EVENTS_PANEL,
    DROPPABLE_ID_GLOBAL_EVENTS_PANEL
} from '../../constants/DragAndDrop';
import { frameToolPanelLayoutStyle } from '../../components/frameToolsPanel/FrameToolsPanel.style';

interface IScriptEventDefinitionsCollectionProps extends IScriptEventDefinition {
    subScriptPath: string;
    currentScript?: IScript;
    scripts?: (IScript | undefined)[];
    onChange?: IObjectEditorProps['onChange'];
    eventRef?: React.RefObject<HTMLDivElement>;
}

interface IScriptEventDefinitionsContainerPrivateProps {
    script: IScript;
    scriptEditorInfo: IHookProvidedDataSource<IScriptEditorInfo>;
    eventId?: string;
}

const ScriptEventDefinitionsContainerPrivate = React.memo(
    function ScriptEventDefinitionsContainerPrivate(props: IScriptEventDefinitionsContainerPrivateProps) {
        const { script, eventId } = props;
        const tokens = useStyle();
        const scriptDispatch = useDispatch();
        const [isAddingItem, setIsAddingItem] = useState(false);
        const [itemFilter, setItemFilter] = useState('');

        const scriptCatalogs = useSelector(SelectCatalogsWithTypeFilter([MotiveTypes.MOTIVE_SCRIPT]));

        const handleAddNewItem = (item: IScriptObjectModel) => {
            const scriptEvent = item as IScriptEventDefinition;

            if (!scriptEvent.name) {
                Logger.error('Must set name or value definition');
            }

            const events = script.events ?? [];
            const action = {
                type: ScriptActionType.UPDATE_SCRIPT_EVENTS,
                events: [...events, scriptEvent]
            };
            scriptDispatch(action);

            setIsAddingItem(false);
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // const scripts = useMemo(() => (data as ICatalog<IScript>[]).flatMap(cat => cat.items?.map(item => item)), [
        //     data
        // ]);

        // const scriptVarsMap = getScriptVariablesMap(script.variables);

        // const allVarsMap = useScriptAndGlobalVariables();

        const handleChange: IObjectEditorProps['onChange'] = (path, value) => {
            const events = setWith(clone(script.events ?? []), path, value, clone);
            const action = {
                type: ScriptActionType.UPDATE_SCRIPT_EVENTS,
                events
            };
            scriptDispatch(action);
        };

        // const globalVars = useMemo(
        //     () =>
        //         Object.keys(allVarsMap)
        //             .filter(varId => !scriptVarsMap[varId])
        //             .map(globalVarId => allVarsMap[globalVarId]),
        //     [allVarsMap]
        // );

        const renderEvent = (scriptEvent: IScriptEventDefinition, scriptEventIndex: number) => (
            <CloneDrag<IObjectInstanceDrag>
                drag={{
                    dragType: DRAG_ITEM_TYPE_SCRIPT_EVENT,
                    objectId: scriptEvent.id,
                    objectType: scriptEvent.type
                }}
                index={scriptEventIndex}
                key={scriptEvent.id}
            >
                <ScriptEventDefinitionsCollection
                    {...scriptEvent}
                    currentScript={script}
                    //scripts={scripts}
                    //objectDefinitions={objectDefinitions}
                    subScriptPath={`${scriptEventIndex}`}
                    onChange={handleChange}
                    eventRef={scriptEvent.id === eventId ? eventRef : undefined}
                />
            </CloneDrag>
        );

        const renderScriptCatalogEvents = (catalog: ICatalog<IScriptObjectModel>) => {
            const scriptCatalog = catalog as ICatalog<IScript>;

            const scriptsWithEvents: IScriptInfo[] = [];

            scriptCatalog.items
                ?.filter(s => s.id !== script.id && s.events && s.events.length > 0)
                .forEach(s => {
                    const events = s.events?.filter(e => e.isGlobal && checkFilter(e.name, itemFilter));

                    if (events && events?.length > 0) {
                        scriptsWithEvents.push({
                            type: MotiveTypes.MOTIVE_SCRIPT,
                            id: s.id,
                            name: s.name,
                            events: events
                        });
                    }
                });

            if (scriptsWithEvents && scriptsWithEvents.length > 0) {
                return (
                    <div key={catalog.id}>
                        <div>{catalog.title}</div>
                        {scriptsWithEvents.map(s => {
                            return (
                                <div key={s.id}>
                                    <div>{s.name}</div>
                                    <div>{s.events?.map(renderEvent)}</div>
                                </div>
                            );
                        })}
                    </div>
                );
            }
        };

        const scriptEvents = useMemo(() => {
            if (!itemFilter) return script.events;

            return script.events?.filter(ev => checkFilter(ev.name, itemFilter));
        }, [script.events, itemFilter]);

        // const renderGlobalVar = (scriptVar: IScriptVariable, scriptVarIndex: number) => (
        //     <>
        //         <ScriptVariableOptionCollection
        //             {...scriptVar}
        //             currentScript={script}
        //             scripts={scripts}
        //             objectDefinitions={objectDefinitions}
        //             subScriptPath={`${scriptVarIndex}`}
        //         />
        //     </>
        // );
        const token = useStyle();

        const eventRef = useMemo(() => {
            return React.createRef<HTMLDivElement>();
        }, [scriptEvents, eventId]);

        return (
            <div css={frameToolPanelLayoutStyle}>
                <div css={inputStickyPositionStyle(tokens)}>
                    <InputField css={inputStyle} onChange={setItemFilter} size={Size.MEDIUM} />
                </div>
                <HeightUsedScrollContainer scrollRef={eventId ? eventRef : undefined}>
                    <Drop dropId={DROPPABLE_ID_EVENTS_PANEL} dragType={DRAG_ITEM_TYPE_SCRIPT_EVENT} isDisabled={true}>
                        <div key={script.id} css={outerHeadingsAndVarsLayoutStyle(tokens)}>
                            <div>
                                <Text size={Size.LARGER} css={eventHeadingStyle}>
                                    Script Events
                                </Text>
                                {scriptEvents?.map(renderEvent)}
                                {isAddingItem ? (
                                    <div>
                                        <QuickObjectEditor
                                            onSave={handleAddNewItem}
                                            onCancel={() => setIsAddingItem(false)}
                                            type={MotiveTypes.SCRIPT_EVENT_DEFINITION}
                                            css={variableObjectEditorStyle(token)}
                                            title={'Add Event'}
                                        />
                                    </div>
                                ) : (
                                    <Button
                                        variant={ButtonVariant.SOLID}
                                        size={Size.SMALL}
                                        icon={IconTypes.PLUS}
                                        css={addScriptButtonStyle(token)}
                                        onClick={() => setIsAddingItem(true)}
                                    >
                                        Add New
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Drop>
                    <Drop
                        key={'globals'}
                        dropId={DROPPABLE_ID_GLOBAL_EVENTS_PANEL}
                        dragType={DRAG_ITEM_TYPE_SCRIPT_EVENT}
                        isDisabled={true}
                    >
                        <div key={'globals'} css={outerHeadingsAndVarsLayoutStyle(tokens)}>
                            <div>
                                <Text size={Size.LARGER} css={eventHeadingStyle}>
                                    Global Events
                                </Text>
                                {scriptCatalogs.map(renderScriptCatalogEvents)}
                            </div>
                        </div>
                    </Drop>
                </HeightUsedScrollContainer>
            </div>
        );
    },
    (lastProps, nextProps) => {
        return (
            lastProps.scriptEditorInfo === nextProps.scriptEditorInfo &&
            lastProps.script.events === nextProps.script.events
        );
    }
);

export interface IScriptEventDefinitionsContainerProps {
    eventId?: string;
}
export const ScriptEventDefinitionsContainer: React.FC<IScriptEventDefinitionsContainerProps> = props => {
    const script = useSelector(SelectScriptEditorCurrentScript);
    const scriptEditorInfo = useScriptEditorInfo();

    return (
        <ScriptEventDefinitionsContainerPrivate
            script={script}
            scriptEditorInfo={scriptEditorInfo}
            eventId={props.eventId}
        />
    );
};

const ScriptEventDefinitionsCollection: React.FC<IScriptEventDefinitionsCollectionProps> = ({
    currentScript,
    //objectDefinitions,
    eventRef,
    ...restProps
}) => {
    const [isEditing, setIsEditing] = useState(false);

    const eventDefinition: IScriptEventDefinition = restProps;
    const { name, id } = eventDefinition;
    //const smartTypeName = getScriptVariableTypeName(scriptVariable, objectDefinitions);

    const currentScriptOwns = currentScript && currentScript.events?.find(scriptVar => scriptVar.id === id);

    const isReadOnly = !currentScriptOwns || !isEditing;

    // Leaving here as we may want to link to exported events at some point?
    // let owningScript: IScript | undefined = currentScript;
    // if (!currentScriptOwns) {
    //     owningScript = scripts?.find(script => script?.events?.find(e => e.id === id)) ?? undefined;
    // }
    const token = useStyle();
    return (
        <>
            <div
                ref={eventRef}
                css={[
                    eventObjectWrapperStyle(token),
                    eventRef ? animateEventBgStyle(token) : eventObjectWrapperBgStyle
                ]}
            >
                <div css={headingRenderContainerStyle}>
                    <Heading css={[inlineStyle, textNoOverflowStyle]} size={Size.SMALLER}>
                        {name}
                    </Heading>
                    {restProps.onChange && (
                        <Button
                            variant={ButtonVariant.CIRCULAR}
                            icon={currentScriptOwns ? IconTypes.ELLIPSES : IconTypes.SCRIPT}
                            css={[inlineStyle, editButtonStyle]}
                            size={Size.MEDIUM}
                            onClick={() => setIsEditing(!isEditing)}
                        />
                    )}
                </div>
                {/* <div css={flexWrapLayoutStyle}>
                    <Text>Type </Text>
                    <Heading css={inlineStyle} size={Size.SMALLER}>
                        {smartTypeName}
                    </Heading>
                </div>
                <div css={flexWrapLayoutStyle}>
                    <Text>Script </Text>
                    <Heading css={inlineStyle} size={Size.SMALLER}>
                        {owningScript?.name}
                    </Heading>
                </div> */}

                {restProps.onChange && (
                    <Collapsible isShowing={isEditing}>
                        <div title={'Options'} css={optionsEditorStyle(token)}>
                            {currentScriptOwns && (
                                <ObjectEditor
                                    path={restProps.subScriptPath}
                                    onChange={restProps.onChange}
                                    value={eventDefinition}
                                    type={eventDefinition.type}
                                    hideFields={['valueDefinition']}
                                    isReadOnly={isReadOnly}
                                />
                            )}
                            {/* {!currentScriptOwns && (
                                <>
                                    Go to script to edit this variables options?
                                    <Button>Go to script</Button>
                                    <Button>Cancel</Button>
                                </>
                            )} */}
                        </div>
                    </Collapsible>
                )}
            </div>
        </>
    );
};
