import React from 'react';
import { IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { ListOrGrid } from '../../core-ui/collection/Collection';
import { times } from 'lodash-es';
import { MediaItemThumbnail } from '../mediaItemThumbnail';
import { MediaItemRow } from '../mediaItemRow';
import { IMediaSourceEntity } from '../../redux/spaceKeyed/media/MediaReducer';

interface IMediaItemsProps {
    display?: ListOrGrid;
    list?: IMediaSourceEntity[];
    onClick?: (item: IHostedMediaSource) => void;
    isLoading?: boolean;
}

export const MediaItemsCollection: React.FC<IMediaItemsProps> = ({
    display,
    list = [],
    onClick,
    isLoading
}): React.ReactElement => {
    return isLoading ? (
        <>
            {times(36, idx => {
                if (display === 'grid') {
                    return <MediaItemThumbnail key={idx} />;
                } else {
                    return <MediaItemRow key={idx} />;
                }
            })}
        </>
    ) : (
        <>
            {list.map(item => {
                if (display === 'grid') {
                    return <MediaItemThumbnail key={item.mediaSource.id} mediaEntity={item} onClick={onClick} />;
                } else {
                    return <MediaItemRow key={item.mediaSource.id} mediaEntity={item} onClick={onClick} />;
                }
            })}
        </>
    );
};
