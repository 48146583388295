/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { MediaManagementBar } from '../../components/mediaManagementBar';
import { ListOrGrid } from '../../core-ui/collection/Collection';
import { useMediaItems } from '../../shared/motive/hooks/useMediaItems';
import { debounce } from 'lodash-es';
import { Card } from '../../core-ui/card';
import { mediaManagementContainerWrapperStyle } from './MediaManagementContainer.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { MediaSourceCollection } from '../../components/mediaSourceCollection';
import { IHostedMediaSource } from '../../shared/motive/models/MediaItem';

export interface IUploadState {
    type: 'clear' | 'uploading' | 'success' | 'error';
    message: string;
}

export interface IMediaManagementContainerProps {
    onSelectItem?: (mediaSource: IHostedMediaSource) => void;
}

export type MediaWizardZone = 'upload' | 'tts';

export const MediaManagementContainer: React.FC<IMediaManagementContainerProps> = ({ onSelectItem }) => {
    const { data, isLoading } = useMediaItems();
    const [display, setDisplay] = useState<ListOrGrid>('grid');
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [typeFilter, setTypeFilter] = useState<string>('none');

    const [showZone, setZone] = useState<MediaWizardZone | undefined>(undefined);

    const token = useStyle();

    const handleUpdateTypeFilter = (type: string) => {
        setTypeFilter(type);
    };

    const handleUpdateSearchFilter = debounce((str: string) => {
        setSearchFilter(str);
    }, 200);

    const handleShowListView = (): void => {
        setDisplay('list');
    };

    const handleShowGridView = (): void => {
        setDisplay('grid');
    };

    const handleZoneToggle = (zone?: MediaWizardZone): void => {
        if (zone && zone === showZone) {
            setZone(undefined);
        } else {
            setZone(zone);
        }
    };

    return (
        <>
            <Card css={mediaManagementContainerWrapperStyle(token)}>
                <MediaManagementBar
                    display={display}
                    currentZone={showZone}
                    onSelectItem={onSelectItem}
                    onShowGridView={handleShowGridView}
                    onShowListView={handleShowListView}
                    onToggleZone={handleZoneToggle}
                    onUpdateSearchFilter={handleUpdateSearchFilter}
                    onUpdateTypeFilter={handleUpdateTypeFilter}
                    isLoading={isLoading}
                />
                <MediaSourceCollection
                    data={data}
                    typeFilter={typeFilter}
                    display={display}
                    searchFilter={searchFilter}
                    onItemSelect={onSelectItem}
                    isLoading={isLoading}
                />
            </Card>
        </>
    );
};
