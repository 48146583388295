import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext/interfaces/Token';
import { BORDER_WIDTH, BORDER_RADIUS } from '../objectWrapperBox/ObjectWrapperBox.style';

export const cardAndEventLinkLayoutStyle = css`
    display: flex;
    flex-direction: column;
`;

export const resourceBorderStyle = (color: string) => css`
    border-left: ${BORDER_WIDTH} solid ${color};
    border-radius: ${BORDER_RADIUS} ${BORDER_RADIUS} 0 ${BORDER_RADIUS};
`;

export const resourceCardStyle = ({
    colors: {
        bar: { resourceBarHeader }
    }
}: IToken) => css`
    position: relative;
    //box-shadow: -3px 3px 38px -10px rgba(0, 0, 0, 0.75);
    ${resourceBorderStyle(resourceBarHeader)}
`;

export const resourceOuterPaddingStyle = css`
    padding: 0;
`;

export const resourceInnerPaddingStyle = ({ spacings }: IToken) => css`
    padding: ${spacings.smaller};
    padding-bottom: ${spacings.smaller};
`;

export const settingsTabContainerStyle = (token: IToken) => css`
    padding-top: ${token.spacings.smallest};
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
`;

export const outputTabContainerStyle = (token: IToken) => css`
    padding-top: ${token.spacings.smallest};
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
`;

export const settingsTabTextStyle = css`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5px;
`;

export const settingsTabStyle = (token: IToken) => css`
    padding: 3px;
    background-color: ${token.colors.background.lighter};
    font-size: ${token.fontSizes.small};
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const outputTabStyle = (token: IToken) => css`
    padding: 3px;
    background-color: ${token.colors.background.light};
    font-size: ${token.fontSizes.small};
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const resourceHeadingButtonWrapperStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const resourceLabelWrapperStyle = (token: IToken) => css`
    cursor: text;
    padding: ${token.spacings.smallest};
    margin: 0px ${token.spacings.smallest};
    background-color: white;
    &: hover {
        background-color: white;
    }
`;

export const resourceLabelTextStyle = (token: IToken) => css`
    color: ${token.colors.font.primary};
    font-size: ${token.fontSizes.small};
`;

export const resourceIconStyle = (token: IToken) => css`
    color: ${token.colors.bar.resourceBarHeader};
    margin-right: 5px;
`;

export const resourceEventLinkWrapper = css`
    position: relative;
`;

export const resourceEventLinkFlex = css`
    display: flex;
    flex-direction: row-reverse;
`;

export const resourceEventLink = (token: IToken) => css`
    background-color: ${token.colors.background.lighter};
    padding: 0 2px 2px 2px;
    border-radius: 0px 0px 5px 5px;
    width: 75px;
    position: relative;
    margin-top: -1px;
    cursor: pointer;
    & button {
        background: ${token.conditionColors.background.neutral};
        border-radius: 0 0 3px 3px;
    }
`;

export const resourceEventLinkIcon = (token: IToken) => css`
    color: ${token.conditionColors.font.primary};
`;

export const resourceEventLinkSelectAnchor = css`
    position: relative;
    width: 0px;
`;

export const resourceEventLinkSelectWrapper = (token: IToken) => css`
    position: absolute;
    background-color: ${token.colors.staticColors.white};
    bottom: 0px;
    left: 0px;
    max-height: 300px;
    min-width: 70px;
    overflow: auto;
    z-index: 1;
    border: 1px solid darkGray;
    border-radius: 4px;
`;

export const resourceEventLinkItemLayout = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const resourceEventLinkerItem = (token: IToken) => css`
    width: 100%;
    border-radius: 0;
    font-size: ${token.fontSizes.small};
    &:hover {
        background-color: ${token.colors.background.lighter};
    }
`;

export const resourceObjectEditorSettingsWrapper = (token: IToken) => css`
    padding: 0 ${token.spacings.smaller} ${token.spacings.smaller} ${token.spacings.smaller};
    background-color: ${token.colors.background.lighter};
    border-radius: 0 0 6px 6px;
`;

export const resourceObjectEditorContentWrapper = (token: IToken) => css`
    padding: 0 ${token.spacings.smaller} ${token.spacings.smaller} ${token.spacings.smaller};
    border-radius: 0 0 6px 6px;
`;

export const overlayStyle = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    max-height: unset;
    max-width: unset;
    width: 100%;
`;

export const noPointerEventsStyle = css`
    pointer-events: none;
`;

export const cursorPointerStyle = css`
    cursor: pointer;
`;

export const dropdownPointerStyle = css`
    height: 20px;
    & > select {
        cursor: pointer;
    }
`;
