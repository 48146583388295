import React, { useState } from 'react';
import { useStyle } from '../../shared/hooks/useStyle';
import { RowItem } from '../../core-ui/rowItem';
import { Size } from '../../core-ui/constants/Size';
import { Icon } from '../../core-ui/icon';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { EditableText } from '../../core-ui/editableText';
import { ICreateScriptParams } from '../../shared/motive/hooks/useCreateScript/useCreateScript';
import { pulse } from '../../constants/AnimationStyles';
import { rowItemStyle, rowContentStyle, scriptIconStyle } from './CreateScriptItem.style';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';

interface ICreateScriptProps {
    catalogId: string;
    addScript: IHookProvidedCall<ICreateScriptParams>;
    onScriptCreated: () => void;
}

export const CreateScriptItem: React.FC<ICreateScriptProps> = ({ addScript, onScriptCreated, catalogId }) => {
    const tokens = useStyle();
    const [newScript, setNewScript] = useState('');

    return (
        <>
            <RowItem css={[rowItemStyle(tokens, false), addScript.isLoading && pulse]}>
                {() => ({
                    content: (
                        <div css={rowContentStyle}>
                            <Icon size={Size.LARGE} iconStyle={scriptIconStyle(tokens)} icon={IconTypes.FILE}></Icon>
                            <EditableText
                                active={true}
                                value={newScript}
                                onChange={setNewScript}
                                onEditComplete={async newName => {
                                    if (newName.length > 0) {
                                        await addScript.execute({ scriptName: newName, catalogId });
                                    }
                                    onScriptCreated();
                                }}
                            />
                        </div>
                    )
                })}
            </RowItem>
        </>
    );
};
