import { useCurrentSpace } from '../../useCurrentSpace';
import { uniqueMotiveId } from '../../../../../util/MotiveUtils';
import { MotiveTypes } from '../../../../../constants/MotiveTypes';
import { createScriptLauncherNetworkCall } from '../../../networking/CatalogService';
import { IScriptDirectoryItem } from '../../../models/ScriptDirectoryItem';
import { setLocalizedText } from '../../../../../util/ObjectDefinitionsUtils';
import { useCreateCatalog } from '../useCreateCatalog/useCreateCatalog';
import { IHookProvidedCall } from '../../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../../hooks/useNetworkCallForDataSource';
import { useDispatch } from 'react-redux';

export interface ICreateScriptLauncherParams {
    catalogId?: string;
    catalogName?: string;
    scenarioTitle: string;
}

export const useCreateScriptLauncher = (): IHookProvidedCall<
    ICreateScriptLauncherParams,
    IScriptDirectoryItem | void
> => {
    const [currentSpace] = useCurrentSpace();
    const catalogDispatch = useDispatch();
    const createCatalog = useCreateCatalog();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const postCatalog = async (params: ICreateScriptLauncherParams) => {
        const createScriptLauncher = async (catId: string) => {
            const scenario: IScriptDirectoryItem = {
                id: uniqueMotiveId(),
                localizedTitle: setLocalizedText(params.scenarioTitle),
                type: MotiveTypes.SCRIPT_DIRECTORY_ITEM
            };

            await triggerCall(
                createScriptLauncherNetworkCall(currentSpace, catId, scenario, getCancelableDispatch(catalogDispatch))
            );
        };

        if (params.catalogName && !params.catalogId) {
            const newCatalog = await createCatalog.execute({
                catalogTitle: params.catalogName,
                objectType: MotiveTypes.SCRIPT_DIRECTORY_ITEM,
                importToCurrentProject: true
            });
            if (newCatalog) {
                createScriptLauncher(newCatalog.id);
            }
        } else if (params.catalogId) {
            createScriptLauncher(params.catalogId);
        }
    };

    return {
        execute: postCatalog,
        ...rest
    };
};
