import { IHostedMediaSource } from '../../../shared/motive/models/MediaItem';

export type IMediaAction = ReturnType<
    | typeof mediaLoadingAction
    | typeof mediaSetAction
    | typeof mediaErrorAction
    | typeof mediaItemLoadingAction
    | typeof mediaItemErrorAction
    | typeof mediaItemDeleteAction
    | typeof mediaUploadBeginAction
    | typeof mediaUploadCompleteAction
    | typeof mediaUploadErrorAction
    | typeof mediaUploadProgressAction
    | typeof mediaUploadRemoveAction
>;

export const mediaLoadingAction = () => {
    return {
        type: 'media/loading'
    } as const;
};

export const mediaSetAction = (mediaItems: IHostedMediaSource[]) => {
    return {
        type: 'media/set',
        mediaItems
    } as const;
};

export const mediaErrorAction = (error: Error) => {
    return {
        type: 'media/error',
        error
    } as const;
};

export const mediaItemErrorAction = (id: string, error: Error) => {
    return {
        type: 'media/itemError',
        error,
        id
    } as const;
};

export const mediaItemLoadingAction = (id: string) => {
    return {
        type: 'media/itemLoading',
        id
    } as const;
};

export const mediaItemDeleteAction = (id: string) => {
    return {
        type: 'media/itemDelete',
        id
    } as const;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mediaUploadBeginAction = (id: string, file: any) => {
    return {
        type: 'media/uploadBegin',
        id,
        file
    } as const;
};

export const mediaUploadCompleteAction = (oldId: string, source: IHostedMediaSource) => {
    return {
        type: 'media/uploadComplete',
        oldId,
        source
    } as const;
};

export const mediaUploadProgressAction = (id: string, progress: number) => {
    return {
        type: 'media/uploadProgress',
        id,
        progress
    } as const;
};

export const mediaUploadErrorAction = (id: string, error: Error) => {
    return {
        type: 'media/uploadError',
        id,
        error
    } as const;
};

export const mediaUploadRemoveAction = (id: string) => {
    return {
        type: 'media/uploadRemove',
        id
    } as const;
};
