/* eslint-disable @typescript-eslint/no-explicit-any */
// istanbul ignore file MOTIVE_EXCEPTION
import React, { memo, useMemo } from 'react';
import { ITypeReference, ITypeBundle } from '../../shared/motive/models/TypeBundle';
import { baseResourceItemStyle, resourceItemTextStyle } from './ResourcePanel.style';

import { Text } from '../../core-ui/text';
import { Size } from '../../core-ui/constants/Size';
import { useStyle } from '../../shared/hooks/useStyle';
import { IToken } from '../../shared/contexts/styleContext';
import { ListOrGrid } from '../../core-ui/collection/Collection';
import {
    useObjectDefinitions,
    getObjectTypeAndImplementors
} from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { IconProp, library, IconName } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { resourceIconStyle } from './ResourcePanel.style';
import { Icon } from '../../core-ui/icon';
import { getTypeIcon, RESOURCE_ICON_DEFAULT, getSmartObjectTypeName } from '../../util/ObjectDefinitionsUtils';
import { IFrameToolsSection, FrameToolsPanel } from '../frameToolsPanel/FrameToolsPanel';
import { IObjectDefinition, IObjectDefinitionMap } from '../../shared/motive/models/TypeDefinition';
import { ISubObjectDrag, ISubObjectInfo } from '../_common/DragAndDrop/DragModels/DragModels';
import { DRAG_ITEM_TYPE_CREATE_RESOURCE } from '../../constants/DragAndDrop';

library.add(fas);

export interface IResourcePanelProps {
    resourceTypes: ITypeBundle[];
    display?: ListOrGrid;
    filter?: string;
    onDoubleClick?: (resource: string) => void;
    onSearchChange: (value: string) => void;
}

interface IRenderSingleItem {
    resource: ITypeReference;
    display: ListOrGrid;
    styleToken: IToken;
    onDoubleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    editorIcon?: IconName;
}

const Item: React.FC<IRenderSingleItem> = ({ resource, styleToken: token, display, onDoubleClick, editorIcon }) => {
    return (
        <div css={[baseResourceItemStyle(token)]} onDoubleClick={onDoubleClick}>
            <Icon iconStyle={resourceIconStyle(token)} size={Size.LARGER} faIcon={editorIcon as IconProp} />
            <Text css={resourceItemTextStyle(token, display)}>{resource.displayName}</Text>
        </div>
    );
};

interface IResourceTypeReference extends ITypeReference {
    subObjectInfo?: ISubObjectInfo[];
}

interface IResourceSection {
    bundleName: string;
    typeReferences: IResourceTypeReference[];
}

function getResourceSections(bundles: ITypeBundle[], objectDefinitions: IObjectDefinitionMap): IResourceSection[] {
    const sections: IResourceSection[] = [];

    bundles.forEach(b => {
        const section: IResourceSection = {
            bundleName: b.bundleName,
            typeReferences: []
        };

        sections.push(section);

        const resourcesWithSubObjs: IObjectDefinition[] = [];

        b.typeReferences.forEach(r => {
            const objDef = objectDefinitions[r.fullName];

            if (objDef?.editorInfo?.subResourceFieldName) {
                resourcesWithSubObjs.push(objDef);
            } else {
                section.typeReferences.push(r);
            }
        });

        resourcesWithSubObjs.forEach(resType => {
            function buildSubObjTypeRefs(
                parentObjDef: IObjectDefinition,
                subObjInfos: ISubObjectInfo[],
                typeRefs: IResourceTypeReference[]
            ) {
                const subObjField = parentObjDef.fieldDefinitions[parentObjDef?.editorInfo?.subResourceFieldName ?? ''];
                const subObjDef = subObjField && objectDefinitions && objectDefinitions[subObjField.typeName];

                var types = subObjDef ? getObjectTypeAndImplementors(subObjDef, false) : [];

                types.forEach(t => {
                    const impDef = objectDefinitions[t];

                    const resourceRef: IResourceTypeReference = {
                        displayName: getSmartObjectTypeName(impDef),
                        fullName: resDef.name,
                        name: impDef.name
                    };

                    const subObjInfo: ISubObjectInfo = {
                        fieldName: subObjField.name,
                        fieldType: t,
                        isArray: subObjField.isArray
                    };

                    resourceRef.subObjectInfo = [...subObjInfos, subObjInfo];

                    if (impDef?.editorInfo?.subResourceFieldName) {
                        buildSubObjTypeRefs(impDef, resourceRef.subObjectInfo, typeRefs);

                        return;
                    }

                    typeRefs.push(resourceRef);
                });

                return typeRefs;
            }

            const _typeRefs: IResourceTypeReference[] = [];
            const resDef = objectDefinitions[resType.name];

            const section: IResourceSection = {
                bundleName: getSmartObjectTypeName(resDef),
                typeReferences: buildSubObjTypeRefs(resDef, [], _typeRefs)
            };

            sections.push(section);
        });
    });

    return sections;
}

export const ResourcePanel = memo(function ResourcePanel(props: IResourcePanelProps) {
    const { resourceTypes, filter, display = 'grid' } = props;
    const typeDefinitions = useObjectDefinitions();

    const token = useStyle();

    const resourceSections = useMemo(() => {
        return getResourceSections(resourceTypes, typeDefinitions);
    }, [resourceTypes]);

    let i = 0;
    const toolsData: IFrameToolsSection<ISubObjectDrag>[] = resourceSections.map(t => {
        return {
            title: t.bundleName,
            items: t.typeReferences.map(r => {
                const iconType = r.subObjectInfo ? r.subObjectInfo[r.subObjectInfo.length - 1].fieldType : r.fullName;

                const editorIcon = getTypeIcon(typeDefinitions, iconType, RESOURCE_ICON_DEFAULT) as IconName;

                const renderItem = () => (
                    <Item styleToken={token} editorIcon={editorIcon} key={i} resource={r} display={'grid'} />
                );

                return {
                    renderItem: renderItem,
                    drag: {
                        dragType: DRAG_ITEM_TYPE_CREATE_RESOURCE,
                        objectType: r.fullName,
                        subObjectInfo: r.subObjectInfo
                    },
                    key: `${r.fullName}.${i++}`,
                    onDoubleClick: () => props.onDoubleClick?.(r.fullName),
                    searchTerms: [r.fullName, r.displayName]
                };
            })
        };
    });

    return (
        <FrameToolsPanel<ISubObjectDrag>
            display={display}
            filter={filter}
            sections={toolsData}
            onSearchInputChange={props.onSearchChange}
        />
    );
});
