import { css } from '@emotion/core';

export const drawerContainerFlexStyle = css`
    display: flex;
    height: 100%;
    & > input {
        margin: 5px;
    }
`;

export const overFlowHiddenStyle = css`
    overflow: hidden;
    height: 100%;
`;
