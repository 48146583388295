import React, { useRef, useState, useEffect } from 'react';
import {
    animatedChange,
    copyStyle,
    copyIconStyle,
    copyFieldLayout,
    obscureStyle,
    ctfButtonStyle
} from './CopyTextField.style';
import { Button, ButtonVariant } from '../button';
import { IconTypes } from '../constants/IconTypes';
import { useStyle } from '../../shared/hooks/useStyle';
import { Text } from '../text';
import { useTranslation } from 'react-i18next';

interface ICopyTextFieldProps {
    label?: string;
    value?: string;
    feedbackTime?: number;
    obscure?: boolean;
}

export const CopyTextField: React.FC<ICopyTextFieldProps> = ({ label, value, feedbackTime = 1000, obscure }) => {
    const [recentlyCopied, setRecentlyCopied] = useState(false);
    const [isObscured, setObscured] = useState(obscure);

    const { t } = useTranslation();

    const token = useStyle();

    const ref = useRef<HTMLInputElement>(null);

    const copy = () => {
        if (ref && ref.current) {
            ref.current.select();
            document.execCommand('copy');
            setRecentlyCopied(true);
        }
    };

    useEffect(() => {
        if (recentlyCopied) {
            setTimeout(() => {
                setRecentlyCopied(false);
            }, feedbackTime);
        }
    }, [recentlyCopied]);

    return (
        <>
            {label && <Text>{label}</Text>}

            <div css={copyFieldLayout}>
                <input
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    css={[copyStyle(token), isObscured && obscureStyle]}
                    ref={ref}
                    value={value}
                    readOnly
                />
                {obscure && (
                    <Button
                        variant={ButtonVariant.HOLLOW}
                        css={ctfButtonStyle}
                        onClick={() => setObscured(o => !o)}
                        icon={isObscured ? IconTypes.EYE : IconTypes.EYESLASH}
                        iconStyle={copyIconStyle(token)}
                        title={isObscured ? t('show') : t('hide')}
                    ></Button>
                )}
                <Button
                    variant={ButtonVariant.HOLLOW}
                    css={[ctfButtonStyle, recentlyCopied && animatedChange(feedbackTime)]}
                    onClick={copy}
                    icon={IconTypes.COPY}
                    iconStyle={copyIconStyle(token)}
                    title={t('copy')}
                ></Button>
            </div>
        </>
    );
};
