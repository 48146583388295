import { useState, useMemo } from 'react';
import React from 'react';
import { InputField } from '../../core-ui/inputField';
import { Size } from '../../core-ui/constants/Size';
import { Dropdown } from '../../core-ui/dropdown';
import { TEST_ID_POST } from '../catalogs';
import { Button, ButtonVariant } from '../../core-ui/button';
import { ITypeBundle, ITypeReference } from '../../shared/motive/models/TypeBundle';
import { IDropdownOption } from '../../core-ui/dropdown/Dropdown';
import { useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo';
import { IObjectDefinition, ITypeDefinitionMap } from '../../shared/motive/models/TypeDefinition';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export interface ICreateCatalogProps {
    onPostCatalog: (catalogTitle: string, catalogType: string) => void;
    initType: string;
    flattenList?: boolean;
    catalogTypes: ITypeBundle[];
    whiteListTypes?: string[];
    blackListTypes?: string[];
}

const generateCatalogTypes = (
    objDefs: ITypeDefinitionMap,
    types: ITypeBundle[],
    whiteList?: string[],
    blackList?: string[],
    flattenList?: boolean
): IDropdownOption[] => {
    const options: IDropdownOption[] = [];
    const seen: Record<string, boolean> = {};

    options.push({ value: '', label: i18next.t('selectType'), disabled: false });

    function check(typeRef: ITypeReference): boolean {
        if (flattenList && seen[typeRef.fullName]) {
            return false;
        }

        seen[typeRef.fullName] = true;

        const type = typeRef.fullName;

        const objDef = objDefs[type] as IObjectDefinition;

        if (objDef?.isAbstract) {
            return false;
        }

        if (blackList && blackList.includes(type)) return false;

        if (whiteList) {
            if (whiteList.includes(type)) return true;

            return false;
        }

        return true;
    }

    types.forEach(item => {
        const typeRefs = item.typeReferences.filter(r => check(r));

        if (typeRefs.length > 0) {
            if (typeRefs) {
                typeRefs.forEach(types => {
                    options.push({ value: types.fullName, label: types.displayName, group: item.bundleName });
                });
            }
        }
    });

    return options;
};

const getInitialType = (initType: string, objDef?: IObjectDefinition, whiteList?: string[]) => {
    if (objDef && !objDef.isAbstract) {
        return initType;
    } else if (whiteList?.length === 1) {
        return whiteList[0];
    }
    return '';
};

export const QuickCreateCatalog: React.FC<ICreateCatalogProps> = ({
    onPostCatalog: createCatalog,
    initType,
    catalogTypes,
    whiteListTypes,
    blackListTypes,
    flattenList
}): React.ReactElement => {
    const { t } = useTranslation();
    const objDefs = useObjectDefinitions();
    const initObjDef = initType ? (objDefs[initType] as IObjectDefinition) : undefined;

    const [isButtonDisabled, setDisabled] = useState(true);
    const [title, setTitle] = useState('');
    const [type, setType] = useState(getInitialType(initType, initObjDef, whiteListTypes));

    const options = useMemo(
        () => catalogTypes && generateCatalogTypes(objDefs, catalogTypes, whiteListTypes, blackListTypes, flattenList),
        [catalogTypes, blackListTypes, flattenList, objDefs, whiteListTypes]
    );

    const handleTitleChange = (val: string) => {
        setDisabled(!val || !type);
        setTitle(val);
    };

    const handleTypeChange = (val: string) => {
        setDisabled(!val || !title);
        setType(val);
    };

    return (
        <>
            <div>
                {whiteListTypes?.length !== 1 && (
                    <Dropdown
                        options={options}
                        onChange={handleTypeChange}
                        value={type}
                        grouped={!flattenList}
                    ></Dropdown>
                )}
                {type && (
                    <>
                        <InputField
                            placeholder={t('catalogName')}
                            size={Size.MEDIUM}
                            onChange={handleTitleChange}
                        ></InputField>
                        <Button
                            data-testid={TEST_ID_POST}
                            variant={ButtonVariant.SOLID}
                            disabled={isButtonDisabled}
                            onClick={() => {
                                createCatalog(title, type);
                            }}
                        >
                            {t('create')}
                        </Button>
                    </>
                )}
            </div>
        </>
    );
};
