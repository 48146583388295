import { Colors, ConditionColors, DynamicsColors } from './Colors';
import { FontFamily } from './FontFamily';
import { BorderRadius } from './BorderRadii';
import { FontSizes } from './FontSizes';
import { Spacings } from './Spacings';

export const styleTokenDefaults = {
    colors: Colors,
    conditionColors: ConditionColors,
    dynamicsColors: DynamicsColors,
    fontSizes: FontSizes,
    fontFamily: FontFamily,
    borderRadii: BorderRadius,
    spacings: Spacings
};
