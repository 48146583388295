import React, { useState, useEffect } from 'react';
import { IProjectConfigsProps } from '../../containers/projectConfigs';
import { SidebarContainer } from '../../containers/sidebarContainer';
import { SidebarProjects } from '../sidebarProjects';
import { HeightUsedScrollContainer } from '../../containers/heightUsedScrollContainer';
import { ProjectConfigs } from '../projectConfigs';
import { CreateProject } from '../createProject';
import { EmptyCollection } from '../../core-ui/emptyCollection';

export enum ProjectOption {
    CREATE,
    EXISTING
}

export const ProjectManagement: React.FC<IProjectConfigsProps> = ({
    projectConfigs: projectsConfigs,
    onProjectSelect,
    addCatalogAction,
    addProjectAction
}) => {
    const [option, setOption] = useState(ProjectOption.EXISTING);
    const projects = projectsConfigs.data.projectConfigs;
    useEffect(() => {
        if (projects.length === 0) setOption(ProjectOption.CREATE);
    }, [projects]);

    return (
        <>
            {/* <CreateProjectFTUE
                projectConfigs={props.projects}
                isLoading={props.isLoadingProjects}
                createProjectAction={props.addProjectAction}
            /> */}
            <SidebarContainer
                sidebarContent={<SidebarProjects option={option} setOption={setOption}></SidebarProjects>}
            >
                <HeightUsedScrollContainer>
                    {option === ProjectOption.EXISTING &&
                        (projects.length > 0 ? (
                            <ProjectConfigs onProjectSelect={onProjectSelect} projects={projects} />
                        ) : (
                            <EmptyCollection
                                message={`There are no existing projects. Click here to get started`}
                                onClickAction={() => {
                                    setOption(ProjectOption.CREATE);
                                }}
                                positionY={30}
                            ></EmptyCollection>
                        ))}
                    {option === ProjectOption.CREATE && (
                        <CreateProject
                            availableCatalogs={projectsConfigs.data.availableCatalogs}
                            projects={projects}
                            createProjectAction={addProjectAction}
                            createCatalogAction={addCatalogAction}
                        ></CreateProject>
                    )}
                </HeightUsedScrollContainer>
            </SidebarContainer>
        </>
    );
};
