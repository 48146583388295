import { IToken } from '../../shared/contexts/styleContext';
import { css } from '@emotion/core';
import chroma from 'chroma-js';

export const outerStyle = ({ spacings, colors }: IToken, depth: number) => css`
    border-left: 5px solid ${chroma(colors.bar.secondary)
        .alpha(0.6)
        .darken(depth)
        .css()};   
    border-radius: 5px 0px 0px 5px;
    background-color: ${chroma(colors.background.colorLighter)
        .alpha(0.6)
        .css()};
    ${
        depth <= 1 ? `margin: ${spacings.smallest} 0;` : `margin: ${spacings.smallest} -${spacings.smaller} 0 -13px;`
    }     
    padding: ${spacings.smallest} ${spacings.smaller};
    position:relative;
`;

export const toolsParentContainerStyle = ({ spacings }: IToken) => css`
    position: absolute;
    top: 0px;
    right: ${spacings.smallest};
    display: flex;
    flex-direction: row;
`;

export const objectEditorTitleStyleStyle = css`
    font-weight: 600;
`;
