import { ILocalizedText } from '../shared/motive/models/LocalizedText';
import {
    IScriptObjectModel,
    IScriptObjectDict,
    FieldTypes,
    getObjectDict
} from '../shared/motive/models/ScriptObjectModel';
import { MotiveTypes } from '../constants/MotiveTypes';
import {
    ITypeDefinitionMap,
    IObjectDefinition,
    ITypeDefinition,
    IFieldDefinition
} from '../shared/motive/models/TypeDefinition';
import { IObjectReference } from '../shared/motive/models/ObjectReference';
import { getShortTypeName, getSpaceFromTypeName, uniqueMotiveId } from './MotiveUtils';
import { IconName } from '@fortawesome/pro-solid-svg-icons';
import { IResourceWrapper, IConditionWrapper, IScript } from '../shared/motive/models/Script';
import { IWorldObjectInteraction } from '../shared/motive/models/WorldObjectInteraction';
import { findResource } from './ScriptUtils';
import { IMediaItem, IHostedMediaSource } from '../shared/motive/models/MediaItem';
import { ILocalizedMedia } from '../shared/motive/models/LocalizedMedia';
import { CatalogItemResolver } from '../shared/motive/hooks/useCatalogs/useCatalogItem/useCatalogItem';
import { ILanguageSettings } from '../shared/motive/stores/editorLocale/EditorLocale';
import { IEnumDefinition } from '../shared/motive/models/EnumDefinition';

export function getLocalizedText(localizedText?: ILocalizedText, cultureCode: string = 'en'): string | undefined {
    if (localizedText) {
        const localizedTextItem = localizedText.localizations?.find(code => {
            return code.cultureCode.toUpperCase() === cultureCode.toUpperCase();
        });

        if (localizedTextItem) {
            return localizedTextItem.text ? localizedTextItem.text : undefined;
        }
    }
    return;
}

export function getLocalizedMedia(
    localizedMedia?: ILocalizedMedia,
    cultureCode: string = 'en'
): IMediaItem | undefined {
    if (localizedMedia) {
        const localizedMediaItem = localizedMedia.localizations?.find(code => {
            return code.cultureCode.toUpperCase() === cultureCode.toUpperCase();
        });

        if (localizedMediaItem) {
            return localizedMediaItem.mediaItem ?? undefined;
        }
    }
    return;
}

export function setLocalizedText(text: string, cultureCode: string = 'en'): ILocalizedText {
    const locText: ILocalizedText = {
        id: uniqueMotiveId(),
        type: MotiveTypes.LOCALIZED_TEXT,
        localizations: [{ text, cultureCode }]
    };

    return locText;
}

export const RESOURCE_ICON_DEFAULT = 'cubes';
export const CONDITION_ICON_DEFAULT = 'question-square';

const orderedKeysForName = ['name', 'localizedTitle', 'editorLabel', 'title'];

function getFieldTitle(
    fieldValue: FieldTypes,
    fieldDef: IFieldDefinition,
    typeDefs: ITypeDefinitionMap,
    languageSettings: ILanguageSettings,
    catalogResolver?: CatalogItemResolver,
    script?: IScript
): string | undefined {
    if (fieldValue && typeof fieldValue === 'string') {
        return fieldValue;
    }

    const fieldTypeDef = typeDefs[fieldDef.typeName];

    if (fieldTypeDef.dataType === 'object') {
        if (fieldDef.useIdOnly && fieldDef.referenceScope === 'script' && script) {
            const objRef = fieldValue as IObjectReference;

            // External lookups won't work, but we can do script ref lookups
            const resource = findResource(script, objRef.objectId);

            if (resource?.resource) {
                return getSmartName(resource.resource, typeDefs, languageSettings, catalogResolver, script);
            }
        } else if (fieldDef.useIdOnly && fieldDef.referenceScope === 'global' && catalogResolver) {
            const objRef = fieldValue as IObjectReference;

            const catItem = catalogResolver(objRef.objectId);

            if (catItem) {
                return getSmartName(catItem, typeDefs, languageSettings, catalogResolver, script);
            }
        } else {
            const scriptObj = fieldValue as IScriptObjectModel;

            if (scriptObj && scriptObj.id) {
                switch (scriptObj.type) {
                    case MotiveTypes.LOCALIZED_TEXT:
                        {
                            const localizedText = getLocalizedText(
                                scriptObj as ILocalizedText,
                                languageSettings.contentLanguage
                            );

                            if (localizedText) {
                                return localizedText;
                            }
                        }
                        break;
                    case MotiveTypes.LOCALIZED_MEDIA:
                        {
                            const localizedMedia = getLocalizedMedia(
                                scriptObj as ILocalizedMedia,
                                languageSettings.contentLanguage
                            );

                            if (localizedMedia) {
                                return getSmartName(localizedMedia, typeDefs, languageSettings);
                            }
                        }
                        break;
                    default:
                        return getSmartName(scriptObj, typeDefs, languageSettings);
                }
            }
        }
    }

    const objRef = fieldValue as IObjectReference;

    if (objRef) {
        return objRef.editorLabel;
    }

    return undefined;
}

export function getObjectReferenceLabel(objectReference: IObjectReference) {
    return objectReference.editorLabel ?? objectReference.objectId;
}

export function getSmartObjectTypeName(objectDefinition: IObjectDefinition) {
    if (objectDefinition.editorInfo) {
        return objectDefinition.editorInfo.title || objectDefinition.name;
    }

    return objectDefinition.name;
}

export function getSmartEnumTypeName(enumDefinition: IEnumDefinition) {
    if (enumDefinition?.editorInfo?.title) {
        return enumDefinition.editorInfo.title;
    }

    return getShortTypeName(enumDefinition.name);
}

// TODO: add language support
export function getSmartTypeName(type: string, typeDefs: ITypeDefinitionMap) {
    const typeDef = typeDefs[type] as ITypeDefinition;

    if (typeDef && typeDef.dataType === 'object') {
        const objDef = typeDef as IObjectDefinition;

        return getSmartObjectTypeName(objDef);
    }

    return typeDef?.name;
}

export function getFilename(urlString?: string) {
    if (!urlString) {
        return '';
    }

    const url = new URL(urlString);

    const fileName = url?.pathname.split('/').pop();

    if (fileName) {
        return unescape(fileName);
    }
}

export function getSmartMediaSourceName(mediaSource: IHostedMediaSource) {
    return mediaSource?.title ?? getFilename(mediaSource?.url ?? '');
}

export function getSmartName(
    object: IScriptObjectModel,
    typeDefs: ITypeDefinitionMap,
    languageSettings: ILanguageSettings,
    catalogResolver?: CatalogItemResolver,
    script?: IScript
): string {
    const dict = (object as unknown) as IScriptObjectDict;

    // Do some custom naming on the object level
    switch (object.type) {
        case MotiveTypes.MEDIA_ITEM:
            {
                const mi = object as IMediaItem;

                if (mi.title) {
                    return mi.title;
                }

                if (mi.source) {
                    const sourceTitle = getSmartMediaSourceName(mi.source);

                    if (sourceTitle) {
                        return sourceTitle;
                    }
                }
            }
            break;
        case MotiveTypes.WORLD_OBJECT_INTERACTION: {
            if (script) {
                return getSmarWorldObjectInteractionName(
                    object as IWorldObjectInteraction,
                    typeDefs,
                    languageSettings,
                    catalogResolver,
                    script
                );
            }
            break;
        }
    }

    const objType = typeDefs[object.type] as IObjectDefinition;

    let title: string | undefined = undefined;

    if (objType && objType.editorInfo && objType.editorInfo.titleFieldName) {
        const fieldVal = dict[objType.editorInfo.titleFieldName];

        if (fieldVal !== undefined) {
            title = getFieldTitle(
                fieldVal,
                objType.fieldDefinitions[objType.editorInfo.titleFieldName],
                typeDefs,
                languageSettings,
                catalogResolver,
                script
            );
        }

        if (title) return title;
    } else if (objType?.interfaces) {
        for (let i = 0; i < objType.interfaces.length; i++) {
            const itf = objType.interfaces[i];

            const itfDef = typeDefs[itf] as IObjectDefinition;

            if (itfDef && itfDef.editorInfo && itfDef.editorInfo.titleFieldName) {
                const fieldVal = dict[itfDef.editorInfo.titleFieldName];

                if (fieldVal !== undefined) {
                    title = getFieldTitle(
                        fieldVal,
                        objType.fieldDefinitions[itfDef.editorInfo.titleFieldName],
                        typeDefs,
                        languageSettings,
                        catalogResolver,
                        script
                    );
                }

                if (title) return title;
            }
        }
    }

    for (const key of orderedKeysForName) {
        const property = dict[key];

        if (property) {
            title = getFieldTitle(
                property,
                objType.fieldDefinitions[key],
                typeDefs,
                languageSettings,
                catalogResolver,
                script
            );

            if (title) return title;
        }
    }

    if (objType) {
        // return `${objType.title} (${object.id})`;
        return `${objType.editorInfo?.title}`;
    }

    return object.id;
}

export function getSmarWorldObjectInteractionName(
    object: IWorldObjectInteraction,
    typeDefs: ITypeDefinitionMap,
    languageSettings: ILanguageSettings,
    catalogResolver?: CatalogItemResolver,
    script?: IScript
): string {
    const objRef = object.worldObjectReferences?.[0];

    const input = object.inputOptions?.[0]?.input;

    const inputName = input ? getSmartName(input, typeDefs, languageSettings, catalogResolver, script) : undefined;

    if (script && catalogResolver) {
        const resourceWrapper = objRef ? findResource(script, objRef.objectId) : undefined;

        const objName = resourceWrapper
            ? getSmartName(resourceWrapper.resource, typeDefs, languageSettings)
            : undefined;

        if (objName || inputName) {
            if (objName && inputName) {
                return `${objName} - ${inputName}`;
            }

            return objName ?? (inputName as string);
        }
    }

    return inputName ?? getSmartName(object, typeDefs, languageSettings);
}

export function getSmartResourceName(
    object: IScriptObjectModel,
    typeDefs: ITypeDefinitionMap,
    languageSettings: ILanguageSettings,
    catalogResolver: CatalogItemResolver,
    script: IScript
) {
    return getSmartName(object, typeDefs, languageSettings, catalogResolver, script);
}

export function getResourceDisplayName(
    resourceWrapper: IResourceWrapper,
    typeDefs: ITypeDefinitionMap,
    languageSettings: ILanguageSettings,
    catalogResolver: CatalogItemResolver,
    script: IScript
): string {
    return resourceWrapper.label
        ? resourceWrapper.label
        : getSmartResourceName(resourceWrapper.resource, typeDefs, languageSettings, catalogResolver, script);
}

function getObjectIconPrivate(
    typeDefs: ITypeDefinitionMap,
    objectIn: IScriptObjectModel | IScriptObjectModel[]
): IconName | undefined {
    const object = Array.isArray(objectIn) ? objectIn[0] : objectIn;

    if (object === undefined) {
        return undefined;
    }

    if (object.type === MotiveTypes.CONDITION_WRAPPER) {
        const condition = (object as IConditionWrapper | undefined)?.condition;

        if (condition) {
            return condition && getObjectIconPrivate(typeDefs, condition);
        }
    }

    const objectDef = typeDefs[object.type] as IObjectDefinition;
    if (objectDef && objectDef.editorInfo && objectDef.editorInfo.subResourceFieldName) {
        const objDict = getObjectDict(object);

        const subResField = objDict[objectDef.editorInfo.subResourceFieldName];

        if (subResField) {
            const subResIcon = getObjectIconPrivate(typeDefs, subResField as IScriptObjectModel);

            if (subResIcon) return subResIcon;
        }
    }

    const parts = objectDef?.editorInfo?.editorIcon?.split('fa-');

    if (parts && parts.length > 1) {
        return parts[1] as IconName;
    }

    return undefined;
}

export function getTypeIcon(
    typeDefs: ITypeDefinitionMap,
    typeName: string,
    defaultIcon?: IconName
): IconName | undefined {
    const objectDef = typeDefs[typeName] as IObjectDefinition;

    const parts = objectDef.editorInfo?.editorIcon?.split('fa-');

    if (parts && parts.length > 1) {
        return parts[1] as IconName;
    }

    return defaultIcon;
}

export function getObjectIcon(
    typeDefs: ITypeDefinitionMap,
    object: IScriptObjectModel,
    defaultIcon?: IconName
): IconName | undefined {
    const icon = getObjectIconPrivate(typeDefs, object) ?? defaultIcon;

    return icon;
}
