import { Accordion, AccordionItem, AccordionPanel, AccordionHeader } from '@chakra-ui/core';
import React from 'react';
import { QuickCreateCatalog } from '../../components/quickCreateCatalog';
import { ImportCatalogs } from '../../components/importCatalogs';
import { useGetCatalogTypes } from '../../shared/motive/hooks/useCatalogs/useCatalogTypes/useGetCatalogTypes';
import { ICatalogInfo } from '../../shared/motive/models/Catalog';
import { IProjectConfig } from '../../shared/motive/models/ProjectConfig';
import { useStyle } from '../../shared/hooks/useStyle';
import {
    accordianContainerStyle,
    accordianHeaderStyle,
    accordianPanelStyle,
    accordionTextStyle
} from './quickCreateImportCatalogsContainer.style';
import { Icon } from '../../core-ui/icon/Icon';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Text } from '../../core-ui/text';
import { useTranslation } from 'react-i18next';

export interface IQuickCreateImportCatalogsContainerProps {
    whiteListTypes?: string[];
    blackListTypes?: string[];
    allSpaceCatalogs?: ICatalogInfo[];
    projectConfig?: IProjectConfig;
    onCreateCatalog: (catalogTitle: string, catalogType: string) => void;
    onImportCatalogs: (projectId: string, catalogIds: string[]) => void;
    quickCreateCatalogType?: string;
    flattenList?: boolean;
}

export const QuickCreateImportCatalogsContainer: React.FC<IQuickCreateImportCatalogsContainerProps> = ({
    whiteListTypes,
    blackListTypes,
    allSpaceCatalogs,
    projectConfig,
    onCreateCatalog,
    onImportCatalogs,
    quickCreateCatalogType,
    flattenList
}): React.ReactElement => {
    const { data: catalogTypes } = useGetCatalogTypes();

    const token = useStyle();
    const { t } = useTranslation();
    return (
        <Accordion allowToggle={true} /*defaultIndex={idx}*/>
            <AccordionItem css={accordianContainerStyle(token)}>
                <AccordionHeader css={accordianHeaderStyle(token)}>
                    <Icon icon={IconTypes.PLUS}></Icon>
                    <Text css={accordionTextStyle}>{t('createCatalog')}</Text>
                </AccordionHeader>
                <AccordionPanel css={accordianPanelStyle(token)}>
                    <QuickCreateCatalog
                        whiteListTypes={whiteListTypes}
                        catalogTypes={catalogTypes}
                        onPostCatalog={onCreateCatalog}
                        initType={quickCreateCatalogType ? quickCreateCatalogType : ''}
                        flattenList={flattenList}
                    />
                </AccordionPanel>
            </AccordionItem>
            {allSpaceCatalogs && projectConfig && (
                <AccordionItem css={accordianContainerStyle(token)}>
                    <AccordionHeader css={accordianHeaderStyle(token)}>
                        <Icon icon={IconTypes.PLUS}></Icon>
                        <Text css={accordionTextStyle}>{t('importCatalog')}</Text>
                    </AccordionHeader>
                    <AccordionPanel css={accordianPanelStyle(token)}>
                        <ImportCatalogs
                            onImportCatalogs={onImportCatalogs}
                            projectConfig={projectConfig}
                            allSpaceCatalogs={allSpaceCatalogs}
                            whiteListTypes={whiteListTypes}
                            blackListTypes={blackListTypes}
                        />
                    </AccordionPanel>
                </AccordionItem>
            )}
        </Accordion>
    );
};
