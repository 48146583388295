import React from 'react';
import { IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { Card } from '../../core-ui/card';
import { Icon } from '../../core-ui/icon';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Text } from '../../core-ui/text/Text';
import { Size } from '../../core-ui/constants/Size';
import { useSpacesRoute } from '../../routes/spacesRoute';
import { getViewableUrl } from '../../shared/motive/networking/MediaService';
import { useStyle } from '../../shared/hooks/useStyle';
import { LoadingSkeleton } from '../../core-ui/loadingSkeleton/LoadingSkeleton';
import {
    mediaItemVideoStyle,
    mediaItemThumbnailWrapperStyle,
    mediaItemIconStyle,
    mediaItemImageStyle,
    mediaItemCardStyle,
    mediaItemCardHeaderStyle,
    mediaItemCardFooterStyle,
    mediaItemCardLabelStyle
} from './MediaItemThumbnail.style';
import { IMediaSourceEntity } from '../../redux/spaceKeyed/media/MediaReducer';

interface IMediaItemThumbnail {
    mediaEntity?: IMediaSourceEntity;
    onClick?: (item: IHostedMediaSource) => void;
}

export const MediaItemThumbnail: React.FC<IMediaItemThumbnail> = ({ mediaEntity, onClick }) => {
    const {
        params: { spaceName }
    } = useSpacesRoute();
    const token = useStyle();

    const mediaSource = mediaEntity?.mediaSource;

    if (mediaSource && onClick) {
        const type = mediaSource.contentType.split('/')[0];

        const url = spaceName ? getViewableUrl(spaceName, mediaSource.id, true) : mediaSource.url;

        let thumbnail = (
            <div css={mediaItemThumbnailWrapperStyle}>
                <Icon css={mediaItemIconStyle(token)} size={Size.LARGER} icon={IconTypes.MAGIC}></Icon>
            </div>
        );

        if (type === 'image') {
            thumbnail = <img css={mediaItemImageStyle} src={url} alt={mediaSource.title ?? mediaSource.url} />;
        }

        if (type === 'audio') {
            thumbnail = (
                <div css={mediaItemThumbnailWrapperStyle}>
                    <Icon css={mediaItemIconStyle(token)} size={Size.LARGER} icon={IconTypes.HEADPHONES}></Icon>
                </div>
            );
        }

        if (type === 'video') {
            thumbnail = (
                <video controls css={mediaItemVideoStyle}>
                    <source src={url} type={mediaSource.contentType} />
                </video>
            );
        }
        return (
            <Card onClick={() => onClick(mediaSource)} css={mediaItemCardStyle(token, !!onClick)}>
                <div css={mediaItemCardHeaderStyle}>{thumbnail}</div>

                <div css={mediaItemCardFooterStyle(token)}>
                    <Text size={Size.SMALL} css={mediaItemCardLabelStyle}>
                        {mediaSource.title}
                    </Text>
                </div>
            </Card>
        );
    } else {
        return (
            <Card css={mediaItemCardStyle(token)}>
                <div css={mediaItemCardHeaderStyle}>
                    <LoadingSkeleton />
                </div>

                <div css={mediaItemCardFooterStyle(token)}>
                    <Text css={mediaItemCardLabelStyle}>
                        <LoadingSkeleton />
                    </Text>
                </div>
            </Card>
        );
    }
};
