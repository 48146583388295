import React from 'react';
import { RowItem } from '../../core-ui/rowItem';
import { Size } from '../../core-ui/constants/Size';
import { useStyle } from '../../shared/hooks/useStyle';
import { subTitleStyle, rowStyle } from './SpaceItem.style';
import { useProjectsRoute } from '../../routes/projectsRoute';
import { LoadingSkeleton } from '../../core-ui/loadingSkeleton/LoadingSkeleton';
import { ISpace } from '../../shared/motive/models/Space';

interface ISpaceItemProps {
    space?: ISpace;
}

export const SpaceItem: React.FC<ISpaceItemProps> = ({ space }): React.ReactElement => {
    const tokens = useStyle();
    const { goTo: goToProjects } = useProjectsRoute();

    const handlePressSpace = () => {
        if (space) {
            goToProjects({
                pathParams: {
                    spaceName: space.fullName
                }
            });
        }
    };

    return (
        <RowItem css={rowStyle(tokens)} size={Size.MEDIUM} onClick={handlePressSpace}>
            {() => ({
                content: space ? space.title || space.name : <LoadingSkeleton width={250} />,
                subContent: space ? (
                    <span css={subTitleStyle(tokens)}>{`${space.fullName}`}</span>
                ) : (
                    <LoadingSkeleton width={150} />
                )
            })}
        </RowItem>
    );
};
