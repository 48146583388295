import { INavBarItem } from './NavbarReducer';
import { setScriptInfo } from '../spaceKeyed/scriptEditor/ScriptEditorActions';
import { setCurrentProjectAction } from '../spaceKeyed/spaceKeyedStateActions';

export const deleteNavbarTabAction = (itemId: string) => {
    return {
        type: 'navbar/tabs/delete',
        id: itemId
    } as const;
};

export const appendNavbarTabAction = (item: INavBarItem, isSelected?: boolean) => {
    return {
        type: 'navbar/tabs/append',
        isSelected: isSelected,
        item: item
    } as const;
};

export const clearRouteToTab = () => {
    return {
        type: 'navbar/tabs/routeToTab/clear'
    } as const;
};

export const setNavbarCurrentTabAction = (itemId: string) => {
    return {
        type: 'navbar/tabs/current/set',
        id: itemId
    } as const;
};

export const updateNavbarTabAction = (item: INavBarItem) => {
    return {
        type: 'navbar/tabs/update',
        item: item
    } as const;
};

export type NavbarActionTypes = ReturnType<
    | typeof setNavbarCurrentTabAction
    | typeof deleteNavbarTabAction
    | typeof updateNavbarTabAction
    | typeof appendNavbarTabAction
    | typeof clearRouteToTab
    | typeof setScriptInfo
    | typeof setCurrentProjectAction
>;
