import React from 'react';
import { useGetAllCatalogsByType } from '../../shared/motive/hooks/useCatalogs';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { ICatalog, ICatalogInfo } from '../../shared/motive/models/Catalog';
import { checkFilterObject } from '../../util/FilterUtils';
import { RowItem } from '../../core-ui/rowItem';
import { useProjectConfig } from '../../shared/motive/hooks/useProjectConfigs/useProjectConfigs';
import { useObjectTypeAndImplementors } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { CatalogItemList } from '../../components/catalogItemList';
import { Loading } from '../../core-ui/loading';
import { CatalogsContainer } from '../catalogsContainer';
import { HeightUsedScrollContainer } from '../heightUsedScrollContainer';

export interface ISelectCatalogItemListContainerProps {
    type: string;
    onSelectCatalog: (catalogInfo: ICatalogInfo) => void;
    onSelectItem: (catalogId: string, item: IScriptObjectModel) => void;
    onCreateCatalog: (title: string, catalogType: string) => void;
    onImportCatalog: (projectConfigId: string, catalogIds: string[]) => void;
    projectConfigId: string | undefined;
}

export const CatalogListItem: React.FC<{
    catalog: ICatalog<IScriptObjectModel>;
    filter: string;
    onSelectCatalog: () => void;
    onSelectItem: (item: IScriptObjectModel) => void;
}> = ({ catalog, filter, onSelectCatalog, onSelectItem }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const check = (item: any): boolean => {
        return checkFilterObject(item, filter);
    };

    const handleSelectItem = (item: IScriptObjectModel) => onSelectItem(item);

    return (
        <>
            <RowItem onClick={onSelectCatalog}>{catalog.title}</RowItem>
            {filter && catalog.items && (
                <CatalogItemList
                    catalogId={catalog.id}
                    items={catalog.items.filter(i => check(i))}
                    onSelectItem={handleSelectItem}
                />
            )}
        </>
    );
};

export const SelectCatalogItemListContainer: React.FC<ISelectCatalogItemListContainerProps> = ({
    type,
    onSelectCatalog,
    onSelectItem,
    projectConfigId
}) => {
    const { isLoading } = useGetAllCatalogsByType(type);
    const { isLoading: configIsLoading } = useProjectConfig(projectConfigId);
    const types = useObjectTypeAndImplementors(type);

    if (isLoading || configIsLoading) {
        return <Loading />;
    }

    //const projectCatalogs = projectConfig?.catalogs ?? [];

    //const catalogs = allCatalogs.filter(c => projectCatalogs.some(pCat => pCat.id === c.id));

    return (
        <>
            {projectConfigId && (
                <HeightUsedScrollContainer>
                    <CatalogsContainer
                        whiteListTypes={types}
                        projectConfigId={projectConfigId}
                        showItemSearch={true}
                        onSelectCatalog={onSelectCatalog}
                        onSelectItem={onSelectItem}
                    />
                </HeightUsedScrollContainer>
            )}
        </>
    );
};
