import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';
import { BORDER_WIDTH, BORDER_RADIUS } from '../objectWrapperBox/ObjectWrapperBox.style';

export const labelTextStyle = (token: IToken) => css`
    color: ${token.conditionColors.background.neutral};
    font-size: ${token.fontSizes.small};
`;

export const iconStyle = (token: IToken) => css`
    color: ${token.conditionColors.background.neutral};
    margin-right: 5px;
`;
export const conditionBorderStyle = (color: string) => css`
    border-left: ${BORDER_WIDTH} solid ${color};
    border-radius: ${BORDER_RADIUS} ${BORDER_RADIUS} 0 ${BORDER_RADIUS};
`;

export const conditionCardStyle = (token: IToken) => css`
    position: relative;
    //box-shadow: -3px 3px 38px -10px rgba(0, 0, 0, 0.75);
    ${conditionBorderStyle(token.colors.bar.conditionBarHeader)}
`;
