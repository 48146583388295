import { ITypeBundle } from '../../../shared/motive/models/TypeBundle';

export const catalogTypeSet = (catalogTypes: ITypeBundle[]) => {
    return {
        type: 'catalogTypes/set',
        catalogTypes
    } as const;
};

export const catalogTypeUnset = () => {
    return {
        type: 'catalogTypes/unset'
    } as const;
};

export type CatalogTypeActions = ReturnType<typeof catalogTypeSet | typeof catalogTypeUnset>;
