import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const sectionStyle = ({ spacings }: IToken) => css`
    padding: ${spacings.small};
`;

export const subSectionStyle = ({ spacings }: IToken) => css`
    padding: ${spacings.small};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin: ${spacings.small} 0;
`;

export const publishingInputStyle = (token: IToken) => css`
    border: none;
    background-color: ${token.colors.background.lighter};
    margin: ${token.spacings.smallest} 0;
    resize: none;
    &:hover,
    &:focus {
        border: none;
        background-color: ${token.colors.background.light};
    }
`;

export const publishingSection = (token: IToken) => css`
    padding: ${token.spacings.smaller} 0;
`;

export const publishButton = css`
    width: 100px;
    float: right;
`;

export const requiredField = css`
    color: #f5789b;
`;
