import React from 'react';
import { useStyle } from '../../shared/hooks/useStyle';
import { ISelectorBranch, SelectorBranchProcessingOrder } from '../../shared/motive/models/Script';
import { Size } from '../../core-ui/constants/Size';
import { EditableText } from '../../core-ui/editableText';
import { DynamicRowBase } from '../dynamicRowBase';
import {
    branchMiniItemStyle,
    branchRowStyle,
    dropDownStyle,
    branchLineStyle,
    middleContentStyle,
    rowMiddleItemStyle1,
    rowMiddleItemStyle2,
    rowMiddleItemStyle3,
    deleteButtonIconStyle
} from './DynamicRowBranch.style';
import { IRecursiveDataProps } from '../../containers/frameInspectorDynamicsContainer';
import { nodeButtonStyle, nodeLineStyle } from '../dynamicRowNode/DynamicRowNode.style';
import { Button } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { BRANCH_ROW_HEIGHT, LEFT_IDENTIFIER_SIZE, LINE_WIDTH } from '../dynamicRowBase/DynamicRowBase';
import { useTranslation } from 'react-i18next';

interface IDynamicBranchRowProps extends IRecursiveDataProps {
    scenarioNodeIndex?: number;
    isSelectorTreeRoot?: boolean;
    branch: ISelectorBranch;
    onDeleteBranch: () => void;
    onRowClick: () => void;
}

export const DynamicRowBranch: React.FC<IDynamicBranchRowProps> = ({
    indexDepth,
    branch,
    subFramePath,
    onChange,
    onDeleteBranch,
    scenarioNodeIndex,
    onClickCreateScenario,
    onRowClick
}) => {
    const token = useStyle();
    const { t } = useTranslation();

    const handleClickCreateScenario = () => {
        const insertIndex = scenarioNodeIndex !== undefined ? scenarioNodeIndex + 1 : undefined;
        onClickCreateScenario && onClickCreateScenario(branch, subFramePath, insertIndex);
    };

    const handleDropdownChange = (dropdownValue: string) => {
        if (dropdownValue === SelectorBranchProcessingOrder.Sequence) {
            const newBranch: ISelectorBranch = { ...branch };
            delete newBranch.processingOrder;
            onChange(subFramePath, newBranch);
        } else {
            onChange(`${subFramePath}.processingOrder`, {
                ...{
                    name: dropdownValue
                }
            });
        }
    };

    const leftBranch = <div css={branchMiniItemStyle(token, LEFT_IDENTIFIER_SIZE)}></div>;

    return (
        <DynamicRowBase
            buttonStyle={nodeButtonStyle}
            rowHeight={BRANCH_ROW_HEIGHT}
            offset={2 * indexDepth - 1}
            leftColProps={{
                children: leftBranch,
                connectorNode: (
                    <svg height={BRANCH_ROW_HEIGHT} width={LINE_WIDTH}>
                        <line
                            x1={0}
                            y1={BRANCH_ROW_HEIGHT / 2 + 1.5}
                            x2={LINE_WIDTH}
                            y2={BRANCH_ROW_HEIGHT / 2 + 1.5}
                            css={branchLineStyle}
                        ></line>
                    </svg>
                )
            }}
            css={branchRowStyle(token)}
            onClickPlus={handleClickCreateScenario}
            lineStyle={nodeLineStyle}
            onClick={onRowClick}
        >
            <div css={middleContentStyle}>
                <div css={rowMiddleItemStyle1}>
                    <EditableText
                        defaultValue={branch.name}
                        onEditComplete={value => onChange(`${subFramePath}.name`, value)}
                    />
                </div>
                <div css={rowMiddleItemStyle2}></div>
                <div css={rowMiddleItemStyle3}>
                    <select
                        onClick={e => e.stopPropagation()}
                        value={branch.processingOrder?.name}
                        onChange={e => handleDropdownChange(e.target.value)}
                        css={dropDownStyle}
                    >
                        <option value={SelectorBranchProcessingOrder.Sequence}>{t('inOrder')}</option>
                        <option value={SelectorBranchProcessingOrder.Priority}>{t('priority')}</option>
                        <option value={SelectorBranchProcessingOrder.Random}>{t('weight')}</option>
                    </select>
                    <Button
                        icon={IconTypes.DELETE}
                        size={Size.MEDIUM}
                        css={deleteButtonIconStyle}
                        onClick={onDeleteBranch}
                    />
                </div>
            </div>
        </DynamicRowBase>
    );
};
