import React from 'react';
import ReactModal from 'react-modal';
import { Text } from './../../core-ui/text';
import { Heading } from '../../core-ui/heading';
import {
    errorMessageStyle,
    modalFlexStyle,
    modalStyle,
    modalActionWrapper,
    modalContentWrapper,
    modalHeaderStyle,
    modalBodyStyle,
    modalFooterStyle
} from './Modal.style';
import { ButtonVariant, Button } from '../../core-ui/button/Button';
import { IToken } from '../../shared/contexts/styleContext';

interface IModalProps {
    isOpen: boolean;
    onRequestClose?: () => void;
}

export interface IBaseModalParams {
    header: React.ReactElement;
    body: React.ReactElement;
    footer: React.ReactElement;
    token: IToken;
}

// Template modals are used for commonly used modals (i.e. CRUD / error messaging) throughout the app without having to repeat markup + maintain consistent styling
interface ITemplateModalParams {
    title: string;
    actionLabel?: string;
    onAction?: () => void;
    onClose?: () => void;
    isLoading?: boolean;
    actionLoadingLabel?: string;
    token: IToken;
}

export interface IMessageModalParams extends ITemplateModalParams {
    message: string;
}

export interface IErrorModalParams extends ITemplateModalParams {
    errorMessage?: string;
}

export const Modal: React.FC<IModalProps> = ({ isOpen, children, onRequestClose }) => {
    return (
        <ReactModal
            onRequestClose={onRequestClose}
            isOpen={isOpen}
            ariaHideApp={false}
            style={modalStyle}
            shouldCloseOnOverlayClick
        >
            {children}
        </ReactModal>
    );
};

// Defines how the base modal is laid out and styled
const generateBaseModalContent = (params: IBaseModalParams) => {
    return (
        <div css={modalFlexStyle}>
            <div css={modalHeaderStyle(params.token)}>{params.header}</div>
            <div css={modalBodyStyle(params.token)}>{params.body}</div>
            <div css={modalFooterStyle(params.token)}>{params.footer}</div>
        </div>
    );
};

// A simple modal that contains only a string message and an action if provided
export const generateMessageModalContent = (params: IMessageModalParams) => {
    return generateBaseModalContent({
        header: <Heading>{params.title}</Heading>,
        body: (
            <div css={modalContentWrapper(params.token)}>
                <Text>{params.message}</Text>
            </div>
        ),
        footer: (
            <div css={modalActionWrapper}>
                {params.onAction && (
                    <Button variant={ButtonVariant.SOLID} onClick={params.onAction}>
                        {(params.isLoading ? params.actionLoadingLabel : params.actionLabel) ?? 'Action'}
                    </Button>
                )}
                <Button variant={ButtonVariant.GHOST} onClick={params.onClose}>
                    Close
                </Button>
            </div>
        ),
        token: params.token
    });
};

// A simple modal that prints an error message and an action if provided
export const generateErrorModalContent = (params: IErrorModalParams): React.ReactElement => {
    return generateBaseModalContent({
        header: <Heading>{params.title}</Heading>,
        body: (
            <>
                <div css={errorMessageStyle(params.token)}>
                    <>{params.errorMessage ?? 'Unknown error has occurred.'}</>
                </div>
            </>
        ),
        footer: (
            <div css={modalActionWrapper}>
                {params.onAction && (
                    <Button variant={ButtonVariant.SOLID} onClick={params.onAction}>
                        {(params.isLoading ? params.actionLoadingLabel : params.actionLabel) ?? 'Action'}
                    </Button>
                )}
                <Button variant={ButtonVariant.GHOST} onClick={params.onClose}>
                    Close
                </Button>
            </div>
        ),
        token: params.token
    });
};
