import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const flexStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 200px;
`;

export const buttonStyle = (token: IToken) => css`
    margin: 0px ${token.spacings.smallest};
    padding: 0;
`;

export const fieldElementStyle = css`
    flex-basis: 100%;
`;
