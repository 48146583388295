export const DRAG_PREFIX = `dragItemType`;
export const DROP_PREFIX = `droppable`;

export const DRAG_ITEM_TYPE_RESOURCE = `${DRAG_PREFIX}.resource`;
export const DRAG_ITEM_TYPE_CREATE_RESOURCE = `${DRAG_PREFIX}.createResource`;
export const DRAG_ITEM_TYPE_CREATE_CONDITION = `${DRAG_PREFIX}.createCondition`;
export const DRAG_ITEM_TYPE_CREATE_OBJECT_CONDITION = `${DRAG_PREFIX}.createObjectCondition`;
export const DRAG_ITEM_TYPE_CATALOG_ITEM = `${DRAG_PREFIX}.catalogItem`;
export const DRAG_ITEM_TYPE_SCRIPT_VARIABLE = `${DRAG_PREFIX}.scriptVariable`;
export const DRAG_ITEM_TYPE_SCRIPT_EVENT = `${DRAG_PREFIX}.scriptEvent`;
export const DRAG_ITEM_SCENARIO_DRAFT = `${DRAG_PREFIX}.scenario.draft`;
export const DRAG_ITEM_SCENARIO_PUBLISHED = `${DRAG_PREFIX}.scenario.published`;
export const DRAG_ITEM_SCENARIO_RETIRED = `${DRAG_PREFIX}.scenario.retired`;
export const DRAG_ITEM_DYNAMIC_NODE = `${DRAG_PREFIX}.dynamic.node`;
export const DRAG_ITEM_DYNAMIC_BRANCH = `${DRAG_PREFIX}.dynamic.branch`;

export const DROPPABLE_ID_FRAME_INSPECTOR = `${DROP_PREFIX}.frameInspectorContainer`;
export const DROPPABLE_ID_FRAME_INSPECTOR_CATALOG_ITEMS = `${DROP_PREFIX}.frameInspectorContainer.catalogs`;
export const DROPPABLE_ID_FRAME_INSPECTOR_RESOURCES = `${DROP_PREFIX}.frameInspectorContainer.resources`;
export const DROPPABLE_ID_FRAME_INSPECTOR_CONDITIONS = `${DROP_PREFIX}.frameInspectorContainer.conditions`;
export const DROPPABLE_ID_FRAME_INSPECTOR_SCRIPT_VARIABLES = `${DROP_PREFIX}.frameInspectorContainer.scriptVariables`;
export const DROPPABLE_ID_FRAME_INSPECTOR_SCRIPT_VARIABLES_SECTION = `${DROP_PREFIX}.frameInspectorContainer.scriptVariables.variableSection`;
export const DROPPABLE_ID_FRAME_INSPECTOR_SCRIPT_EVENTS = `${DROP_PREFIX}.frameInspectorContainer.scriptEvents`;
export const DROPPABLE_ID_FRAME_INSPECTOR_DYNAMICS_CONDITIONS = `${DROP_PREFIX}.frameInspectorContainer.dynamics.conditions`;
export const DROPPABLE_ID_FRAME_INSPECTOR_DYNAMICS_VARIABLES = `${DROP_PREFIX}.frameInspectorContainer.dynamics.variables`;
export const DROPPABLE_ID_SCRIPT_EVENT_CONDITION = `${DROP_PREFIX}.frameInspectorContainer.scriptEventCondition`;
export const DROPPABLE_ID_FRAME_THUMB = `${DROP_PREFIX}.frameThumb`;
export const DROPPABLE_ID_PRECONDITION = `${DROP_PREFIX}.preCondition`;
export const DROPPABLE_ID_PRECONDITION_VARIABLE = `${DROP_PREFIX}.preCondition.variable`;
export const DROPPABLE_ID_RESOURCE_PANEL = `${DROP_PREFIX}.resourcePanel`;
export const DROPPABLE_ID_CONDITIONS_PANEL = `${DROP_PREFIX}.conditionsPanel`;
export const DROPPABLE_ID_VARIABLES_PANEL = `${DROP_PREFIX}.variablesPanel`;
export const DROPPABLE_ID_EVENTS_PANEL = `${DROP_PREFIX}.eventsPanel`;
export const DROPPABLE_ID_GLOBAL_EVENTS_PANEL = `${DROP_PREFIX}.globalEventsPanel`;
export const DROPPABLE_ID_SCENARIO_DRAFT = `${DROP_PREFIX}.scenario.draft`;
export const DROPPABLE_ID_SCENARIO_PUBLISHED = `${DROP_PREFIX}.scenario.published`;
export const DROPPABLE_ID_SCENARIO_RETIRED = `${DROP_PREFIX}.scenario.retired`;
export const DROPPABLE_ID_DYNAMIC_NODE = `${DROP_PREFIX}.dynamic.node`;
export const DROPPABLE_ID_DYNAMIC_BRANCH = `${DROP_PREFIX}.dynamic.branch`;

/**
 * Generates a unique drop id zone in a formalized way. This way we can match the constant id to check for matches on any drop of a particular category.
 * @param dropId The drop id from the predetermined list of constants
 * @param id The unique id for the particular droppable
 */
export const generateDropId = (dropId: string, id: string) => `${dropId}:${id}`;
