import { IToken } from '../../shared/contexts/styleContext';
import { css } from '@emotion/core';

export const baseWrapperStyle = css`
    pointer-events: none;
    display: inline-block;
    min-width: fit-content;
`;

export const baseIconStyle = () => css`
    pointer-events: none;
    display: block;
`;

export const circleStyle = () => css`
    border-radius: 50%;
`;

export const smallerWrapperStyle = () => css`
    border-radius: 3px;
`;

export const smallWrapperStyle = () => css`
    border-radius: 4px;
`;

export const mediumWrapperStyle = () => css`
    border-radius: 6px;
`;

export const largeWrapperStyle = () => css`
    border-radius: 8px;
`;

export const largerWrapperStyle = () => css`
    border-radius: 12px;
`;

export const smallerIconStyle = (token: IToken) => css`
    // padding: ${token.spacings.smaller};
    // transform: scale(0.9);
`;

export const smallIconStyle = (token: IToken) => css`
    // padding: ${token.spacings.small};
    // transform: scale(1.25);
`;

export const mediumIconStyle = (token: IToken) => css`
    // padding: ${token.spacings.medium};
    // transform: scale(1.6);
`;

export const largeIconStyle = (token: IToken) => css`
    // padding: ${token.spacings.large};
    // transform: scale(2.25);
`;

export const largerIconStyle = (token: IToken) => css`
    // padding: ${token.spacings.larger};
    // transform: scale(3);
`;

export const hollowVariantStyle = ({ colors: { font } }: IToken) => css`
    // color: ${font.primary};
`;

export const ghostVariantStyle = ({ colors: { font } }: IToken) => css`
    // color: ${font.primary};
    background-color: transparent;
`;

export const clickIconWrapperStyle = css`
    pointer-events: auto;
    cursor: pointer;

    &:hover {
        filter: brightness(80%);
    }
`;
