import { getMediaItemsNetworkCall } from '../../networking/MediaService';
import { useCurrentSpace } from '../useCurrentSpace';
import { IHookProvidedDataSource } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useSelector } from 'react-redux';
import { isLoading } from '../../../../redux/Status';
import { IMediaSourceEntity, useMediaDispatch } from '../../../../redux/spaceKeyed/media/MediaReducer';
import { SelectMediaStatus, SelectAllMediaItems } from '../../../../redux/spaceKeyed/media/MediaSelectors';

export const useMediaItems = (): IHookProvidedDataSource<IMediaSourceEntity[]> => {
    const [currentSpace] = useCurrentSpace();

    const mediaDispatch = useMediaDispatch();
    const mediaStatus = useSelector(SelectMediaStatus);
    const mediaItems = useSelector(SelectAllMediaItems);

    const call = async () => {
        if (isLoading(mediaStatus.loadingState)) return;

        await getMediaItemsNetworkCall(currentSpace, mediaDispatch);
    };

    return {
        data: Object.values(mediaItems),
        load: call,
        cancel: () => undefined,
        isLoading: isLoading(mediaStatus.loadingState),
        error: mediaStatus.error
    };
};
