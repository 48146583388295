import React from 'react';
import { ICatalogInfo } from '../../shared/motive/models/Catalog';
import { Icon } from '../../core-ui/icon';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import {
    detailfolderIcon,
    listInformation,
    listKey,
    detailList,
    folderSection,
    externalCatalogItem,
    externalCatalogName
} from './DashboardCatalogDetails.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { IToken } from '../../shared/contexts/styleContext';
import { useTypeDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { getSmartTypeName } from '../../util/ObjectDefinitionsUtils';
import { dateYMD } from '../../util/DateUtils';
import { useTranslation } from 'react-i18next';

interface IDashboardCatalogDetailsProps {
    catalog: ICatalogInfo;
    allCatalogs: ICatalogInfo[];
    onSelectCatalog?: (id: string) => void;
}

const generateListInfo = (key: string, value: string | React.ReactNode, token: IToken) => {
    return (
        <div css={listInformation(token)}>
            <div css={listKey(token)}>{key}</div>
            <div>{value}</div>
        </div>
    );
};
export const DashboardCatalogDetails: React.FC<IDashboardCatalogDetailsProps> = ({
    catalog,
    allCatalogs,
    onSelectCatalog
}) => {
    const token = useStyle();
    const typeDefs = useTypeDefinitions();
    const { t } = useTranslation();
    const generateInfo = (key: string, value: string | React.ReactNode) => generateListInfo(key, value, token);

    return (
        <>
            <div css={folderSection(token)}>
                <Icon css={detailfolderIcon} icon={IconTypes.FOLDER}></Icon>
                <div>{catalog.name}</div>
            </div>

            <div css={detailList(token)}>
                {generateInfo(t('catalogType'), getSmartTypeName(catalog.objectType, typeDefs))}
                {generateInfo(t('creationDate'), dateYMD(catalog.creationTime))}
                {generateInfo(t('lastModifiedDate'), dateYMD(catalog.lastModifiedTime))}
                {catalog.referencedByCatalogs &&
                    catalog.referencedByCatalogs.length > 0 &&
                    generateInfo(
                        t('usedInCatalogs'),
                        <>
                            {catalog.referencedByCatalogs.map(id => (
                                <div css={externalCatalogItem(token)} key={id}>
                                    <span
                                        onClick={() => onSelectCatalog && onSelectCatalog(id)}
                                        css={externalCatalogName(token)}
                                    >
                                        {allCatalogs.find(c => c.id === id)?.title}
                                    </span>
                                </div>
                            ))}
                        </>
                    )}
            </div>
        </>
    );
};
