import { useGetAllCatalogs } from '../useGetAllCatalogs/useGetAllCatalogs';
import { IScriptObjectModel } from '../../../models/ScriptObjectModel';
import { ICatalog } from '../../../models/Catalog';
import { useCallback } from 'react';

function findItem<T extends IScriptObjectModel = IScriptObjectModel>(
    catalogs: ICatalog<IScriptObjectModel>[],
    itemId?: string
) {
    if (itemId === undefined) return;

    for (let i = 0; i < catalogs.length; i++) {
        const cat = catalogs[i];

        if (cat.items) {
            for (let j = 0; j < cat.items.length; j++) {
                const item = cat.items[j];

                if (item.id === itemId) {
                    return item as T;
                }
            }
        }
    }
}

export const useCatalogItem = <T extends IScriptObjectModel = IScriptObjectModel>(itemId?: string): T | undefined => {
    const { data: catalogs } = useGetAllCatalogs();

    return findItem<T>(catalogs, itemId);
};

export type CatalogItemResolver = <T extends IScriptObjectModel = IScriptObjectModel>(itemId: string) => T | undefined;

export const useCatalogItemResolver: () => CatalogItemResolver = () => {
    const { data: catalogs } = useGetAllCatalogs();

    return useCallback(
        <T extends IScriptObjectModel = IScriptObjectModel>(itemId: string) => {
            return findItem<T>(catalogs, itemId);
        },
        [catalogs]
    );
};
