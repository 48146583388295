import * as React from 'react';
import { useProjectConfigs } from '../../shared/motive/hooks/useProjectConfigs';
import { useLoadDataOnMount } from '../../shared/hooks/useLoadDataOnMount';
import { useMediaItems } from '../../shared/motive/hooks/useMediaItems';
import { useGetAllCatalogs } from '../../shared/motive/hooks/useCatalogs';
import { useGetCatalogTypes } from '../../shared/motive/hooks/useCatalogs/useCatalogTypes/useGetCatalogTypes';
import { useScriptEditorInfo } from '../../shared/motive/hooks/useScriptEditorInfo';
import { FullLoader } from '../../core-ui/fullLoader/FullLoader';
import { useEffect } from 'react';
import { IHookProvidedDataSource } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';

export const SpaceContainer: React.FC<{ space?: string }> = ({ children, space }) => {
    const projectConfigsDataSource = useProjectConfigs();
    const catalogsDataSource = useGetAllCatalogs();
    const catalogTypesDataSource = useGetCatalogTypes();
    const mediaItemsDataSource = useMediaItems();
    const scriptEditorInfoDataSource = useScriptEditorInfo();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const allSources: IHookProvidedDataSource<any>[] = [
        projectConfigsDataSource,
        catalogsDataSource,
        catalogTypesDataSource,
        mediaItemsDataSource,
        scriptEditorInfoDataSource
    ];

    useEffect(() => {
        if (space) {
            const loadData = async () => {
                await Promise.all(allSources.map(d => d.load()));
            };

            loadData();
        }
    }, [space]);

    const isLoading = allSources.some(ds => ds.isLoading);

    return (
        <FullLoader isLoading={isLoading} minimumTime={1000}>
            {children}
        </FullLoader>
    );
};
