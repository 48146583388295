/* eslint-disable @typescript-eslint/no-explicit-any */
import { SpaceKeyedReducer } from './spaceKeyed/spaceStateReducer';
import { SpacesReducer } from './spaces/SpaceReducer';
import { UserInfoReducer } from './userInfo/UserInfoReducer';
import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { NavBarReducer } from './navbar/NavbarReducer';

const AppReducer = combineReducers({
    userInfo: UserInfoReducer,
    spaces: SpacesReducer,
    workspace: SpaceKeyedReducer,
    navbar: NavBarReducer
});

export const ApplicationStore = createStore(AppReducer, composeWithDevTools());

export type ApplicationState = ReturnType<typeof AppReducer>;
