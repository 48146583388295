import React from 'react';
import { SerializedStyles } from '@emotion/core';
import { Transition } from 'react-transition-group';
import { fadeInStyle, fadeOutStyle, fadeInOutStyle } from './FadeTransition.style';
import { TransitionStatus } from 'react-transition-group/Transition';

type TransitionVariant = 'fade-in' | 'fade-out' | 'fade-in-out';

interface ITransitionProps {
    variant?: TransitionVariant | TransitionVariant[];
    active?: boolean;
    time?: number;
    className?: string;
}

export interface ITransitionDetails {
    status: TransitionStatus;
    time: number;
}

const transitionMap: { [key in TransitionVariant]: (details: ITransitionDetails) => SerializedStyles } = {
    'fade-in': fadeInStyle,
    'fade-out': fadeOutStyle,
    'fade-in-out': fadeInOutStyle
};

/**
 * A component to easily display children with animated entrance and exit.
 */
export const SimpleTransition: React.FC<ITransitionProps> = ({
    active: isShowing = true,
    variant = 'fade-in-out',
    children,
    time = 500,
    className
}): React.ReactElement => {
    const getTransitions = (status: TransitionStatus) => {
        const details: ITransitionDetails = { status, time };

        if (Array.isArray(variant)) {
            const transitionStyles = variant.map(v => transitionMap[v]);
            return transitionStyles.map(style => style(details));
        }

        return transitionMap[variant](details);
    };

    return (
        <Transition appear={isShowing} in={isShowing} unmountOnExit timeout={time}>
            {status => (
                <div css={getTransitions(status)} className={className}>
                    {children}
                </div>
            )}
        </Transition>
    );
};
