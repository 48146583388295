// istanbul ignore file MOTIVE_EXCEPTION
import React, { useState } from 'react';
import { Button, ButtonVariant } from '../../core-ui/button';
import {
    resourceLabelTextStyle,
    resourceIconStyle,
    resourceEventLink,
    settingsTabContainerStyle,
    settingsTabStyle,
    settingsTabTextStyle,
    cardAndEventLinkLayoutStyle,
    resourceEventLinkWrapper,
    resourceEventLinkFlex,
    resourceEventLinkSelectAnchor,
    resourceEventLinkSelectWrapper,
    resourceEventLinkIcon,
    resourceObjectEditorSettingsWrapper,
    resourceCardStyle,
    resourceOuterPaddingStyle,
    resourceObjectEditorContentWrapper,
    outputTabContainerStyle,
    outputTabStyle,
    resourceEventLinkerItem,
    resourceEventLinkItemLayout
} from './ResourceWrapper.style';
import { EditableText } from '../../core-ui/editableText/EditableText';
import { Size } from '../../core-ui/constants/Size';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { useStyle } from '../../shared/hooks/useStyle';
import {
    useTypeDefinition,
    useObjectDefinition,
    useObjectDefinitions
} from '../../shared/motive/hooks/useScriptEditorInfo';
import { generateEnumOptions } from '../../core-ui/utils/ComponentUtils';
import { Icon } from '../../core-ui/icon';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { ObjectEditor, IObjectEditorProps } from '../../containers/objectEditor';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { FieldTypes, IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { IScript, IFrame, IResourceWrapper } from '../../shared/motive/models/Script';
import { ObjectWrapperBox } from '../objectWrapperBox';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { getObjectIcon, RESOURCE_ICON_DEFAULT } from '../../util/ObjectDefinitionsUtils';
import { IEnumDefinition } from '../../shared/motive/models/EnumDefinition';
import { BarVariant } from '../../core-ui/bar/Bar';
import { useDragContext } from '../../hooks/useDragContext';
import { CloneDrag } from '../_common/DragAndDrop/CloneDrag/CloneDrag';
import { IObjectInstanceDrag } from '../_common/DragAndDrop/DragModels/DragModels';
import { Drop } from '../_common/DragAndDrop/Drop/Drop';
import { DRAG_ITEM_TYPE_CREATE_OBJECT_CONDITION, DRAG_ITEM_TYPE_RESOURCE } from '../../constants/DragAndDrop';
import { extractDraggableInfo } from '../_common/DragAndDrop/DragAndDropProvider/DragAndDropProvider';
import { IResourceDrag } from '../frameInspectorResources';
import { IEnumItemReference } from '../../shared/motive/models/TypeDefinition';

interface IResourceWrapperViewProps {
    children?: React.ReactNode;
    dragHandleProps?: DraggableProvidedDragHandleProps | null;
    script: IScript;
    frame: IFrame;
    resourceWrapper: IResourceWrapper;

    label?: string | null;
    placeholder?: string;
    note?: string | null;
    isAdvanced: boolean;
    isReadOnly: boolean;
    isEnabled?: boolean;
    hasNote?: boolean;
    onToggleEnabled: () => void;
    onToggleReadOnly: () => void;
    onToggleAdvanced: () => void;
    onCreateNote: () => void;
    onUpdateNote: (value?: string) => void;
    onDeleteNote: () => void;
    onUpdateLabel: (value?: string) => void;
    onDelete: () => void;
    onCopy: () => void;
    onEventLink: (event: IEnumItemReference) => void;
    onClick?: () => void;
    onUpdateResource: (fieldPath: string, updatedValue: FieldTypes) => void;
    onClickSetEventId: (eventId: string) => void;
}

function isContentFieldType(fieldType: string): boolean {
    switch (fieldType) {
        case MotiveTypes.LOCALIZED_MEDIA:
        case MotiveTypes.LOCALIZED_TEXT:
        case MotiveTypes.COLOR:
        case MotiveTypes.CONTENT:
            return true;
        default:
            return false;
    }
}

const ResourceWrapperPrivate: React.FC<IResourceWrapperViewProps> = ({
    resourceWrapper,
    isEnabled,
    note,
    onToggleEnabled,
    label,
    onUpdateLabel,
    frame,
    onEventLink,
    script,
    onUpdateResource,
    placeholder,
    onDelete,
    onCopy,
    dragHandleProps,
    onUpdateNote,
    onClickSetEventId
}) => {
    const tokens = useStyle();
    const objectDefinition = useObjectDefinition(resourceWrapper.resource.type);
    const objDefs = useObjectDefinitions();
    const events = useTypeDefinition<IEnumDefinition>('motive.core.scriptObjectEvent');
    const opts = generateEnumOptions(events.items);
    const [showEventLinks, setShowEventLinks] = useState(false);

    const contentFields = Object.values(objectDefinition.fieldDefinitions).filter(
        f => !f.isOutput && isContentFieldType(f.typeName)
    );
    const settingsFields = Object.values(objectDefinition.fieldDefinitions).filter(
        f => !f.isOutput && !isContentFieldType(f.typeName)
    );
    const outputFields = Object.values(objectDefinition.fieldDefinitions).filter(f => f.isOutput);

    const isSettingsOnly = contentFields.length === 0;

    if (resourceWrapper.events && resourceWrapper.events.length > 0) {
        resourceWrapper.events.forEach(e =>
            opts.push({
                value: e.id,
                label: e.name
            })
        );
    }

    const handleEventLink = (eventId: string) => {
        const item = events.items?.find(i => i.id === eventId) ?? resourceWrapper.events?.find(e => e.id === eventId);

        if (item) {
            const enumRef: IEnumItemReference = {
                itemId: eventId,
                name: item.name
            };

            onEventLink(enumRef);
        }
    };

    const objectEditorProps: IObjectEditorProps<IScriptObjectModel> = {
        script: script,
        frame: frame,
        value: resourceWrapper.resource,
        type: resourceWrapper.resource.type,
        onChange: onUpdateResource,
        resourceWrapper: resourceWrapper,
        onClickSetEventId: onClickSetEventId
    };

    return (
        <div css={cardAndEventLinkLayoutStyle}>
            <ObjectWrapperBox
                isEnabled={isEnabled}
                noteValue={note as string | undefined}
                dragHandleProps={dragHandleProps}
                onNoteChange={onUpdateNote}
                onClickDelete={onDelete}
                onClickCopy={onCopy}
                onClickIsEnableToggle={onToggleEnabled}
                cardStyle={resourceCardStyle(tokens)}
                bodyPaddingStyle={resourceOuterPaddingStyle}
                barVariant={BarVariant.TRANSPARENT}
                object={resourceWrapper.resource}
                headerRender={
                    <>
                        <Icon
                            size={Size.SMALL}
                            faIcon={getObjectIcon(objDefs, resourceWrapper.resource, RESOURCE_ICON_DEFAULT) as IconProp}
                            iconStyle={resourceIconStyle(tokens)}
                        />
                        <EditableText
                            size={Size.MEDIUM}
                            textStyle={resourceLabelTextStyle(tokens)}
                            defaultValue={label || ''}
                            placeholder={placeholder}
                            onEditComplete={onUpdateLabel}
                        />
                    </>
                }
            >
                <ResourceHideOnDragStart resourceId={resourceWrapper.resource.id}>
                    <div css={resourceObjectEditorContentWrapper(tokens)}>
                        <ObjectEditor {...objectEditorProps} showFields={contentFields.map(f => f.name)} />
                    </div>
                    {!isSettingsOnly && (
                        <div css={settingsTabContainerStyle(tokens)}>
                            <div css={settingsTabStyle(tokens)}>
                                <Icon icon={IconTypes.SLIDERS_VERTICAL} />
                                <span css={settingsTabTextStyle}>OPTIONS</span>
                            </div>
                        </div>
                    )}

                    <div css={resourceObjectEditorSettingsWrapper(tokens)}>
                        <ObjectEditor {...objectEditorProps} showFields={settingsFields.map(f => f.name)} />
                    </div>

                    {outputFields.length > 0 && (
                        <>
                            <div css={outputTabContainerStyle(tokens)}>
                                <div css={outputTabStyle(tokens)}>
                                    <Icon icon={IconTypes.POLL} />
                                    <span css={settingsTabTextStyle}>RESULTS</span>
                                </div>
                            </div>

                            <div css={resourceObjectEditorSettingsWrapper(tokens)}>
                                <ObjectEditor {...objectEditorProps} showFields={outputFields.map(f => f.name)} />
                            </div>
                        </>
                    )}
                </ResourceHideOnDragStart>
            </ObjectWrapperBox>
            <ResourceHideOnDragStart resourceId={resourceWrapper.resource.id}>
                <div css={resourceEventLinkWrapper}>
                    <div css={resourceEventLinkFlex}>
                        <div css={resourceEventLinkSelectAnchor}>
                            {showEventLinks && (
                                <Drop
                                    dropId={resourceWrapper.resource.id + '.eventlink'}
                                    dragType={DRAG_ITEM_TYPE_CREATE_OBJECT_CONDITION}
                                >
                                    <div css={resourceEventLinkSelectWrapper(tokens)}>
                                        {opts.map((o, index) => {
                                            return (
                                                <div css={resourceEventLinkItemLayout} key={o.label}>
                                                    <CloneDrag<IObjectInstanceDrag & { zone: string }>
                                                        drag={{
                                                            dragType: DRAG_ITEM_TYPE_CREATE_OBJECT_CONDITION,
                                                            objectId: resourceWrapper.resource.id,
                                                            objectType: resourceWrapper.resource.type,
                                                            zone: `eventList`
                                                        }}
                                                        index={index}
                                                    >
                                                        <Icon
                                                            size={Size.SMALLER}
                                                            //css={[iconStyle(token)]}
                                                            icon={IconTypes.BULLSEYE_POINTER}
                                                        />
                                                    </CloneDrag>
                                                    <Button
                                                        key={o.label}
                                                        css={resourceEventLinkerItem(tokens)}
                                                        onClick={() => {
                                                            handleEventLink(o.value as string);
                                                            setShowEventLinks(false);
                                                        }}
                                                        variant={ButtonVariant.HOLLOW}
                                                    >
                                                        {o.label}
                                                    </Button>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Drop>
                            )}
                        </div>
                        <div css={resourceEventLink(tokens)}>
                            <Button size={Size.INHERIT} onClick={() => setShowEventLinks(!showEventLinks)}>
                                <Icon css={resourceEventLinkIcon(tokens)} icon={IconTypes.PLUS} />
                            </Button>
                        </div>
                    </div>
                </div>
            </ResourceHideOnDragStart>
        </div>
    );
};

export const ResourceWrapper = React.memo(
    function ResourceWrapper(props: IResourceWrapperViewProps) {
        return <ResourceWrapperPrivate {...props} />;
    },
    (oldProps, newProps) => {
        return (
            oldProps.resourceWrapper === newProps.resourceWrapper &&
            oldProps.frame.objectFieldVariableBindings === newProps.frame.objectFieldVariableBindings
        );
    }
);

const ResourceHideOnDragStart: React.FC<{ resourceId: string }> = ({ children, resourceId }) => {
    const { beforeCapture } = useDragContext();

    const dragInfo = beforeCapture && extractDraggableInfo<IResourceDrag>(beforeCapture.draggableId);
    const isDragged = dragInfo && dragInfo.objectId === resourceId && dragInfo.dragType === DRAG_ITEM_TYPE_RESOURCE;

    return <>{!isDragged && children}</>;
};
