// istanbul ignore file MOTIVE_EXCEPTION
import React, { useEffect } from 'react';
import { NavBar } from '../../components/navBar';
import { IContextualMenuItem } from '../../core-ui/contextualMenu';
import {
    gridChildStyle,
    navBarParentStyle,
    maxHeightStyle,
    userInfoMenuItemStyle,
    userInfoNameStyle,
    userInfoEmailStyle,
    userInfoManageAccountStyle,
    userLogoutButtonStyle
} from './NavBarContainer.style';
import { useUserInfo } from '../../shared/motive/hooks/useUserInfo';
import { useHistory } from 'react-router';
import { useLogout } from '../../shared/motive/hooks/useLogout/useLogout';
import { UserIcon } from '../../core-ui/userIcon';
import { ButtonVariant, Button } from '../../core-ui/button';
import { useStyle } from '../../shared/hooks/useStyle';
import { useNavbarCurrentTab } from '../../redux/navbar/NavbarHooks';
import { get } from 'lodash-es';
import { useTranslation } from 'react-i18next';

const NavBarContainerPrivate: React.FC = (): React.ReactElement => {
    const {
        data: { userInfo: info }
    } = useUserInfo();
    const { push } = useHistory();
    const navBarHeight = 60;

    const { execute: logout } = useLogout();
    const style = useStyle();
    const { t } = useTranslation();

    const currentTab = useNavbarCurrentTab();

    useEffect(() => {
        const title = get(currentTab, ['title'], null);

        document.title = !!title ? 'StoryFlow - ' + title : 'StoryFlow';
    }, [currentTab?.title]);

    if (!info) {
        return <></>;
    }

    const menuItems: IContextualMenuItem[] = [
        {
            children: (
                <div css={userInfoMenuItemStyle}>
                    <UserIcon email={info.user.email} name={info.user.name} size={`70`} />
                    <div css={userInfoNameStyle(style)}>{info.user.name}</div>
                    <div css={userInfoEmailStyle(style)}>{info.user.email}</div>
                    <Button
                        css={userInfoManageAccountStyle(style)}
                        variant={ButtonVariant.HOLLOW}
                        onClick={() => push('/spaces')}
                    >
                        {t('workspaces')}
                    </Button>
                    <Button
                        css={userInfoManageAccountStyle(style)}
                        variant={ButtonVariant.HOLLOW}
                        onClick={() => window.open('https://id.storyflow.dev/')}
                    >
                        {t('manageAccount')}
                    </Button>
                </div>
            )
        },
        {
            children: (
                <div css={userInfoMenuItemStyle}>
                    <Button
                        css={userLogoutButtonStyle(style)}
                        variant={ButtonVariant.HOLLOW}
                        onClick={async () => await logout({})}
                    >
                        {t('logout')}
                    </Button>
                </div>
            )
        }
    ];

    return (
        <div css={navBarParentStyle}>
            <NavBar
                heightCss={maxHeightStyle(navBarHeight)}
                css={gridChildStyle}
                name={info?.user?.name ?? 'Motive User'}
                menuItems={menuItems}
                navBarHeight={navBarHeight}
            />
        </div>
    );
};

export const NavBarContainer: React.FC = NavBarContainerPrivate;
