import React from 'react';
import { TextSize, Size } from '../constants/Size';
import { SerializedStyles } from '@emotion/css';
import {
    smallestTextStyle,
    smallerTextStyle,
    smallTextStyle,
    mediumTextStyle,
    largeTextStyle,
    largerTextStyle,
    largestTextStyle,
    baseTextStyle,
    textBoldStyle,
    textItalicStyle,
    textUnderlineStyle,
    textInlineStyle
} from './Text.style';

interface ITextProps {
    className?: string;
    onClick?: () => void;
    size?: TextSize;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    inline?: boolean;
    isPropagationDisabled?: boolean;
}

const getTextStyle = (size: TextSize): SerializedStyles => {
    switch (size) {
        case Size.SMALLEST:
            return smallestTextStyle;
        case Size.SMALLER:
            return smallerTextStyle;
        case Size.SMALL:
            return smallTextStyle;
        case Size.MEDIUM:
            return mediumTextStyle;
        case Size.LARGE:
            return largeTextStyle;
        case Size.LARGER:
            return largerTextStyle;
        case Size.LARGEST:
            return largestTextStyle;
        default:
            throw new Error('An invalid size enumerator was passed into getTextSizeStyle().');
    }
};

export const Text: React.FC<ITextProps> = ({
    size = Size.MEDIUM,
    children,
    bold,
    italic,
    underline,
    inline,
    onClick,
    className,
    isPropagationDisabled = false,
    ...rest
}): React.ReactElement => {
    return (
        <span
            {...rest}
            onClick={e => {
                isPropagationDisabled && e.stopPropagation();
                onClick && onClick();
            }}
            css={[
                baseTextStyle,
                getTextStyle(size),
                bold && textBoldStyle,
                italic && textItalicStyle,
                underline && textUnderlineStyle,
                inline && textInlineStyle
            ]}
            className={className}
        >
            {children}
        </span>
    );
};
