import { css } from '@emotion/core';
import { DrawerSize } from '.';
import { IToken } from '../../shared/contexts/styleContext';
import { DrawerPlacement } from './Drawer';
import { ZIndex } from '../../constants/ZIndex';

const DRAWER_HANDLE_WIDTH = 0;
export const DRAWER_WIDTH_XS = 75;
export const DRAWER_WIDTH_SM = 400;

const handleWidth = `${DRAWER_HANDLE_WIDTH}px`;
const transitionSpeed = '500ms';

const rightPlacementWidths: {
    [key in DrawerSize]: string; //px
} = {
    off: '0px',
    xs: `${DRAWER_WIDTH_XS + DRAWER_HANDLE_WIDTH}px`,
    sm: `${DRAWER_WIDTH_SM + DRAWER_HANDLE_WIDTH}px`,
    lg: `calc(80vw + ${DRAWER_HANDLE_WIDTH}px)`
};
const bottomPlacementHeights: {
    [key in DrawerSize]: string; //px
} = {
    off: '0px',
    xs: '40vh',
    sm: '40vh',
    lg: '66vh'
};

export const gridParentStyle = css`
    display: grid;
    grid-template-columns: ${handleWidth} calc(100% - ${handleWidth});
`;

export const drawerHandleStyle = ({ colors }: IToken) => css`
    cursor: pointer;
    background: ${colors.bar.primary};
    &:hover {
        background: ${colors.bar.secondary};
    }
`;

export const showDrawerBasisRelative: DrawerSize[] = ['off', 'xs', 'sm'];

const printAnchorFlexBasis = (size: DrawerSize, placement: DrawerPlacement, minSize: DrawerSize) => {
    if (placement === 'right') {
        return `flex-basis: ${rightPlacementWidths[minSize]}`;
    } else {
        return `flex-basis: ${bottomPlacementHeights[minSize]}`;
    }
};

const printBoxShadow = (size: DrawerSize) => {
    if (showDrawerBasisRelative.includes(size)) {
        return 'box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);';
    }

    return `box-shadow: -7px 10px 21px 0px rgba(0, 0, 0, 0.75);`;
};

export const anchorStyle = (size: DrawerSize, placement: DrawerPlacement, minSize: DrawerSize) => css`
    position: relative;

    ${placement === 'right' && `right: 0;`}
    ${placement === 'bottom' && `bottom: 0;`};
    ${printAnchorFlexBasis(size, placement, minSize)}
`;

const onHoverXsToSmStyle = (size: DrawerSize) => css`
    ${size === 'xs' &&
        `
        &:hover {
            width: calc(${rightPlacementWidths['xs']} * 2 );
        }
        `}
`;

export const visibleContainerStyle = (
    { colors }: IToken,
    size: DrawerSize,
    placement: DrawerPlacement,
    useOnHoverXsToSm: boolean,
    defaultHeightFromTop: string,
    defaultWidthFromRight: string
) => {
    const isInFixedPosition = !showDrawerBasisRelative.some(s => s === size);
    let transform = `transform: translate(calc(-1 * (${rightPlacementWidths[size]} + ${
        size !== 'off' ? defaultWidthFromRight : '0'
    })));`;
    let transition = `transition: `;

    if (isInFixedPosition) {
        const drawerWidth = rightPlacementWidths[size];
        const margin = `calc((100vw - ${drawerWidth}) / 2)`;

        transform = `transform: translate(calc(-1 * (100vw) + ${margin}), ${`calc(${defaultHeightFromTop} / -2)`});`;
    }
    transition += `transform ${transitionSpeed} ease, width 0s ${transitionSpeed};`;

    return css`
    background-color: ${colors.background.light};
    position: fixed;
    ${printBoxShadow(size)}
    ${useOnHoverXsToSm && onHoverXsToSmStyle(size)}

    ${placement === 'right' &&
        `
        min-width: ${rightPlacementWidths['sm']};
        width: ${rightPlacementWidths[size]};
        transform-origin: center right;
        ${transform}
        ${transition}
        
        height: calc(100vh - ${defaultHeightFromTop});
        bottom: 0px;
        left: 100vw;
        z-index: ${ZIndex.drawer};

    `}

    ${placement === 'bottom' &&
        `height: ${bottomPlacementHeights[size]}; 
        bottom: 0;
    `};
`;
};

export const overlayStyle = (showOverlay: boolean) => css`
    transition: opacity ${transitionSpeed} ease-out;
    background: black;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    z-index: ${ZIndex.drawer};

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    ${showOverlay &&
        `
        visibility: visible;
        opacity: 0.6;
    `}
`;
