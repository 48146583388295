import { css } from '@emotion/core';
import { MAX_WIDTH } from './FacialExpressionsPlayer.style';

export const expressionNameWrapperStyle = css`
    position: absolute;
    width: 100%;
    margin-top: 16px;
    z-index: 1;
`;

export const expressionNameStyle = css`
    width: 60%;
    margin: 0 auto;
    background: #0e0e0e;
    color: white;
    text-align: center;
    font-size: 14px;
    border: none;
    &:hover {
        border: none;
        background-color: #454545;
    }
`;

export const expressionCanvasWrapper = (width?: number) => css`
    ${width && width > MAX_WIDTH
        ? `
        position: absolute;
        width: calc(100% - 250px);
        height: 100%;
        right: 0;`
        : `
        position: relative;
        width: 100%;
        height:250px;
        `}
`;
