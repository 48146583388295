import { useCurrentSpace } from '../useCurrentSpace';
import { deleteScriptNetworkCall } from '../../networking/ScriptService';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useDispatch } from 'react-redux';

export interface IDeleteScriptParams {
    catalogId: string;
    scriptId: string;
}
export const useDeleteScript = (): IHookProvidedCall<IDeleteScriptParams> => {
    const [currentSpace] = useCurrentSpace();
    const catalogDispatch = useDispatch();

    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const deleteScript = async (params: IDeleteScriptParams) => {
        await triggerCall(
            deleteScriptNetworkCall(
                currentSpace,
                params.scriptId,
                params.catalogId,
                getCancelableDispatch(catalogDispatch)
            )
        );
    };

    return {
        execute: deleteScript,
        ...rest
    };
};
