import * as React from 'react';
import { useEffect } from 'react';
import { useStyle } from '../../shared/hooks/useStyle';
import { useSaveScript } from '../../shared/motive/hooks/useSaveScript/useSaveScript';
import { IScript } from '../../shared/motive/models/Script';
import { generateErrorModalContent, Modal } from '../modal';

export const SaveErrorModal: React.FC<Pick<ReturnType<typeof useSaveScript>, 'error'> & {
    script: IScript;
    forceSave: (script: IScript) => Promise<void>;
    isSaving: boolean;
}> = ({ error, script, forceSave, isSaving }) => {
    const isSaveConflict = error?.message.includes('409');
    const message = isSaveConflict ? 'Save Conflict' : 'Save Error';
    const [isErrorDismissed, setIsErrorDismissed] = React.useState(false);
    const styleTokens = useStyle();

    const errorMessage = isSaveConflict
        ? `A change was made to this script while you were authoring. Would you like to overwrite the script with your changes?`
        : error?.message;

    useEffect(() => {
        if (isErrorDismissed) {
            setIsErrorDismissed(false);
        }
    }, [error]);

    return (
        <Modal isOpen={!!error && !isErrorDismissed}>
            {generateErrorModalContent({
                title: message,
                errorMessage: errorMessage,
                token: styleTokens,
                onAction: isSaveConflict
                    ? async () => {
                          await forceSave(script);
                          setIsErrorDismissed(true);
                      }
                    : undefined,
                actionLabel: 'Force Save',
                isLoading: isSaving,
                actionLoadingLabel: 'Saving',
                onClose: () => setIsErrorDismissed(true)
            })}
        </Modal>
    );
};
