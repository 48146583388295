import React from 'react';
import { HeightUsedScrollContainer } from '../heightUsedScrollContainer';
import { sidebarLayoutStyle, spaceSectionStyle, dashboardSection } from './DashboardSidebarContainer.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { useProjectConfig } from '../../shared/motive/hooks/useProjectConfigs/useProjectConfigs';
import { useRenameProjectConfig } from '../../shared/motive/hooks/useRenameProjectConfig/useRenameProjectConfig';
import { CollaboratorContainer } from '../collaboratorContainer';
import { SidebarCollaborators } from '../../components/sidebarCollaborators/SidebarCollaborators';
import { useSpaces } from '../../shared/motive/hooks/useSpaces';
import { useCurrentSpace } from '../../shared/motive/hooks/useCurrentSpace';
import { SidebarSection } from '../../components/sidebarSection/SidebarSection';
import { CopyTextField } from '../../core-ui/copyTextField/CopyTextField';
import { LoadingSkeleton } from '../../core-ui/loadingSkeleton/LoadingSkeleton';
import { RenameProjectConfig } from '../../components/renameProjectConfig';
import { times } from 'lodash-es';
import { Loading } from '../../core-ui/loading';
import { useCurrentProject } from '../../shared/motive/hooks/useCurrentProject';
import { useCurrentProjectConfig } from '../../redux/spaceKeyed/projectConfig/ProjectConfigSelectors';
import { getSystemName } from '../../util/MotiveUtils';
import { useTranslation } from 'react-i18next';

interface IDashboardContainerProps {
    projectId: string;
    dashboardNavHeight: number;
}

const getAppInfoLoading = () => (
    <>
        {times(2, i => (
            <div key={i}>
                <LoadingSkeleton width={150} wrapper={CopyTextField} />
            </div>
        ))}
    </>
);

export const DashboardSidebarContainer: React.FC<IDashboardContainerProps> = ({ projectId, dashboardNavHeight }) => {
    const token = useStyle();
    const config = useCurrentProjectConfig();
    const { data: spaceData } = useSpaces();
    const { t } = useTranslation();

    const [currentSpace] = useCurrentSpace();
    const renameAction = useRenameProjectConfig(projectId);

    const space = spaceData.find(s => s.fullName === currentSpace);

    return (
        <div css={sidebarLayoutStyle(token, dashboardNavHeight)}>
            <div>
                <RenameProjectConfig rename={renameAction} configName={config?.title} />
            </div>
            <HeightUsedScrollContainer css={dashboardSection(token)}>
                <SidebarSection label={t('project')}>
                    {space && (
                        <div css={spaceSectionStyle(token)}>
                            <CopyTextField label={t('configName')} value={config?.name}></CopyTextField>
                            <CopyTextField label={t('appName')} value={space.appId} obscure></CopyTextField>
                            <CopyTextField label={t('apiKey')} value={space.apiKey} obscure></CopyTextField>
                        </div>
                    )}
                </SidebarSection>

                <CollaboratorContainer>
                    {props => <SidebarCollaborators {...props}></SidebarCollaborators>}
                </CollaboratorContainer>
            </HeightUsedScrollContainer>
        </div>
    );
};
