import { useContext } from 'react';
import React from 'react';
import { uiStackContainerStyleSingleItem, stackPanelItem } from './UseUIStack.style';
import { Logger } from '../../../util/logger';
import { UIContext } from '../../../stores/uiStore/UiStore';

export interface IStackItem {
    element: JSX.Element;
    elementTitle: JSX.Element;
    key: string;
}
export type PushMethod = 'clearExisting' | 'clearAfterCurrentIndex' | 'atBack';

export const DEFAULT_UI_STACK_CONTEXT_VALUE = {
    uiStack: [],
    selectedIndex: 0,
    setUIStack: () => undefined,
    setSelectedIndex: () => undefined
};
export interface IUIStackContextValue {
    uiStack: IStackItem[];
    setUIStack: (stack: IStackItem[]) => void;
    selectedIndex: number;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}
const UIStackContext = React.createContext<IUIStackContextValue>(DEFAULT_UI_STACK_CONTEXT_VALUE);

export const UIStackContextProvider = UIStackContext.Provider;

export const useUIStack = () => {
    const { uiStack, setUIStack, selectedIndex, setSelectedIndex } = useContext(UIStackContext);
    const uiContext = useContext(UIContext);

    const callSetUIStack = (stack: IStackItem[]) => {
        uiContext.setDrawerIsOpen(stack && stack.length > 0);
        setUIStack(stack);
    };

    const pushItems = (pushMethod: PushMethod = 'atBack', stackItem: (IStackItem | undefined)[]) => {
        const itemsNoUndefs = stackItem.filter(restItem => restItem !== undefined) as IStackItem[];

        if (pushMethod === 'clearExisting') {
            callSetUIStack([...itemsNoUndefs]);
            setSelectedIndex(itemsNoUndefs.length);
        } else if (pushMethod === 'clearAfterCurrentIndex') {
            callSetUIStack([...uiStack.slice(0, selectedIndex), ...itemsNoUndefs]);
            setSelectedIndex(selectedIndex + itemsNoUndefs.length);
        } else {
            callSetUIStack([...uiStack, ...itemsNoUndefs]);
            setSelectedIndex(uiStack.length + itemsNoUndefs.length);
        }
    };

    const pushItem = (
        pushMethod: PushMethod = 'atBack',
        stackItem: IStackItem | undefined,
        ...restItems: (IStackItem | undefined)[]
    ) => {
        const itemsWithUndef = [stackItem, ...restItems];
        const items = itemsWithUndef.filter(restItem => restItem !== undefined) as IStackItem[];

        pushItems(pushMethod, items);
    };

    const popItem = (item?: IStackItem) => {
        let idx = 0;

        if (item) {
            idx = uiStack.indexOf(item);

            if (idx < 0) return;
        }

        callSetUIStack(uiStack.slice(idx, -1));
        setSelectedIndex(Math.max(uiStack.length - 1, 0));
    };

    const clear = () => {
        callSetUIStack([]);
        setSelectedIndex(0);
    };

    const setElementTitle = (key: string, elementTitle: JSX.Element) => {
        const itemIndex = uiStack.findIndex(item => item.key === key);
        if (itemIndex === -1) {
            Logger.error(`no stack item found for ${key}.`);
            return;
        }
        const item = uiStack[itemIndex];
        uiStack.splice(itemIndex, 1, { ...item, elementTitle });
        callSetUIStack(uiStack);
    };

    const canNavigateBack = () => {
        if (uiStack.length === 0 || selectedIndex <= 1) {
            return false;
        }

        return true;
    };
    const canNavigateForwards = () => {
        return uiStack.length !== 0 && uiStack.length > selectedIndex;
    };

    const navigateBack = () => {
        if (!canNavigateBack()) {
            return;
        }

        setSelectedIndex(selectedIndex - 1);
    };
    const navigateForwards = () => {
        if (!canNavigateForwards()) {
            return;
        }

        setSelectedIndex(selectedIndex + 1);
    };

    const render = () => {
        return (
            <div css={uiStackContainerStyleSingleItem}>
                {uiStack.map(({ element, key }: IStackItem, index) => {
                    return (
                        <div key={key} css={stackPanelItem(index !== selectedIndex - 1)}>
                            {element}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderTitle = () => {
        return <span>{uiStack && selectedIndex > 0 && uiStack[selectedIndex - 1]?.elementTitle}</span>;
    };

    return {
        uiStack,
        pushItem,
        pushItems,
        popItem,
        clear,
        render,
        renderTitle,
        canNavigateBack,
        canNavigateForwards,
        navigateBack,
        navigateForwards,
        setElementTitle
    };
};
