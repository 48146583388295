import React, { useEffect, useState } from 'react';
import { IWatsonIntent } from '../../shared/motive/models/WatsonIntent';
import { Field, Form, Formik, FormikProps } from 'formik';
import { errorHelperTextStyle, FormControl } from '../../core-ui/formControl';
import { Button, ButtonVariant } from '../../core-ui/button';
import { InputField } from '../../core-ui/inputField';
import { Heading } from '../../core-ui/heading';
import { Size } from '../../core-ui/constants/Size';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { uniqueMotiveId } from '../../util/MotiveUtils';
import { buttonWrapperStyle } from './CreateIntentItem.style';
import { Divider } from '../../core-ui/divider';
import { Dropdown, IDropdownOption } from '../../core-ui/dropdown';
import { ConversationalAgent } from '../../shared/motive/models/ConversationalAgent';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { rotating } from '../../constants/AnimationStyles';
import { ConversationalIntent } from '../../shared/motive/models/ConversationalIntent';
import { IWatsonAssistant } from '../../shared/motive/models/WatsonAssistant';
import { useStyle } from '../../shared/hooks/useStyle';

interface ICreateIntentItemProps {
    onIntentItemCreation: (intent: ConversationalIntent) => void;
    isCreateIntentItemLoading?: boolean;
    agents: ConversationalAgent[];
    currentAgent?: ConversationalAgent;
    disableAgentSelection?: boolean; // Only if the intent catalog size is non-zero
}

export const CreateIntentItem: React.FC<ICreateIntentItemProps> = ({
    onIntentItemCreation,
    agents,
    currentAgent,
    isCreateIntentItemLoading,
    disableAgentSelection
}): React.ReactElement => {
    const token = useStyle();

    const [agent, setAgent] = useState<ConversationalAgent | undefined>(currentAgent);

    // Keep in sync as the pivot field changes
    useEffect(() => {
        setAgent(currentAgent);
    }, [currentAgent, setAgent]);

    const handleItemCreation = async (intent: ConversationalIntent) => {
        switch (intent.type) {
            case MotiveTypes.WATSON_INTENT:
                const watsonAssistant = agent as IWatsonAssistant;
                onIntentItemCreation &&
                    onIntentItemCreation({
                        ...intent,
                        assistantReference: { objectId: watsonAssistant?.id, objectType: MotiveTypes.WATSON_ASSISTANT }
                    });
                break;
            default:
                break;
        }
    };

    const handleUpdateAgent = (agentId: string) => {
        setAgent(agents.find(agent => agent.id === agentId));
    };

    const initialValues: IWatsonIntent = {
        id: uniqueMotiveId(),
        intentName: '',
        description: '',
        type: MotiveTypes.WATSON_INTENT,
        assistantReference: undefined
    };

    const agentDropdownOptions = [
        { value: 'None', label: 'None' },
        ...(agents.map(
            (agent: ConversationalAgent): IDropdownOption => {
                return {
                    value: agent.id,
                    label: agent.name
                };
            }
        ) ?? [])
    ];

    const createWatsonIntentItemRender: React.FC<FormikProps<IWatsonIntent>> = props => {
        const intentNameFieldId = 'intentName';
        const intentDescriptionFieldId = 'description';
        const intentAgentFieldId = 'assistantReference';

        return (
            <Form>
                <Heading size={Size.MEDIUM}>Create an Intent</Heading>
                <Divider />
                <FormControl
                    label="Intent Name"
                    fieldId={intentNameFieldId}
                    required={true}
                    helperText={props.errors.intentName}
                    helperTextStyle={errorHelperTextStyle(token)}
                >
                    <Field
                        required={true}
                        id={intentNameFieldId}
                        name={intentNameFieldId}
                        type="text"
                        as={InputField}
                        useNativeOnChange={true}
                        placeholder="Name"
                        isInvalid={props.errors.intentName}
                    />
                </FormControl>
                <FormControl label="Intent Description" fieldId={intentDescriptionFieldId}>
                    <Field
                        required={true}
                        id={intentDescriptionFieldId}
                        name={intentDescriptionFieldId}
                        type="text"
                        as={InputField}
                        useNativeOnChange={true}
                        placeholder="Description"
                    />
                </FormControl>
                <FormControl label="Assistant" fieldId={intentAgentFieldId} required={true}>
                    <Dropdown
                        isDisabled={!!disableAgentSelection}
                        onChange={handleUpdateAgent}
                        value={agent?.id ?? 'None'}
                        options={agentDropdownOptions}
                    />
                </FormControl>
                <div css={buttonWrapperStyle}>
                    {!isCreateIntentItemLoading ? (
                        <Button
                            type="submit"
                            variant={ButtonVariant.SOLID}
                            disabled={!props.values.intentName}
                            onClick={() => props.submitForm().then(() => props.resetForm())}
                        >
                            Create Intent
                        </Button>
                    ) : (
                        <Button
                            disabled
                            icon={IconTypes.LOAD_SPINNER}
                            iconStyle={rotating}
                            variant={ButtonVariant.SOLID}
                        >
                            Creating
                        </Button>
                    )}
                </div>
            </Form>
        );
    };

    const validate = (values: IWatsonIntent) => {
        const errors: any = {};

        if (!values.intentName) {
            errors.intentName = 'Required';
        }

        return errors;
    };

    return (
        <Formik validate={validate} initialValues={initialValues} onSubmit={handleItemCreation} validateOnBlur={false}>
            {createWatsonIntentItemRender}
        </Formik>
    );
};
