/* eslint-disable @typescript-eslint/no-explicit-any */
import { uniqueId } from 'lodash-es';
import {
    IScriptObjectModel,
    FieldTypes,
    getObjectDict,
    IScriptObjectDict
} from '../shared/motive/models/ScriptObjectModel';
import { IObjectReference } from '../shared/motive/models/ObjectReference';
import { IEnumItemReference, IObjectDefinition, ITypeDefinitionMap } from '../shared/motive/models/TypeDefinition';
import { MotiveTypes } from '../constants/MotiveTypes';
import { getSmartName } from './ObjectDefinitionsUtils';
import { ISelectorTree, ISelectorBranch } from '../shared/motive/models/Script';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ILanguageSettings } from '../shared/motive/stores/editorLocale/EditorLocale';
import { ICatalog } from '../shared/motive/models/Catalog';

export const NAMES_NON_EDITABLE_FIELDS = ['id', 'type', 'variableReference'];

export const getSpaceFromTypeName = (typeName: string) => {
    const idx = typeName.lastIndexOf('.');

    if (idx > 0) {
        return typeName.slice(0, idx);
    }

    return typeName;
}

export const getShortTypeName = (typeName: string) => {
    const idx = typeName.lastIndexOf('.');

    if (idx > 0) {
        return typeName.slice(idx + 1, typeName.length);
    }

    return typeName;
}

export const getEnumItemName = (enumVal: IEnumItemReference | string) => {
    if (typeof enumVal === 'string') {
        return enumVal as string;
    }

    return enumVal.name;
};

export const getSystemName = (title: string = ''): string => {
    return title
        .replace(/\s/g, '_')
        .replace(/[^a-zA-Z0-9_]/g, '')
        .toLowerCase();
};

export const uniqueMotiveId = (): string => {
    return uniqueId('tmp:');
};

export const removeFromArray = (array: any[], item: any) => {
    const n = array.indexOf(item);

    if (n >= 0) {
        array.splice(n, 1);
    }
};

export const createScriptObject = <T extends IScriptObjectModel = IScriptObjectModel>(
    type: string,
    objectDefinitions: ITypeDefinitionMap
): T => {
    const definition: IObjectDefinition | undefined = (objectDefinitions[type] as IObjectDefinition) ?? undefined;

    if (!definition) {
        throw new Error(`unable to find type ${type} in typeDefinitions.`);
    }

    const emptyFieldTypesMap: {
        [key: string]: FieldTypes;
    } = {};

    const recursiveScriptObjectFields = Object.keys(definition.fieldDefinitions).reduce(
        (requiredFieldsOfScriptObject, key) => {
            if (requiredFieldsOfScriptObject[key] || NAMES_NON_EDITABLE_FIELDS.some(nonEditKey => nonEditKey === key)) {
                return requiredFieldsOfScriptObject;
            }

            const fieldDef = definition.fieldDefinitions[key];
            const fieldTypeObjectDef: IObjectDefinition | undefined = objectDefinitions[
                fieldDef.typeName
            ] as IObjectDefinition;

            if (fieldDef.editorInfo?.defaultValue) {
                requiredFieldsOfScriptObject[key] = fieldDef.editorInfo.defaultValue;
            } else if (!fieldDef.isNullable) {
                if (
                    fieldTypeObjectDef?.dataType === 'object' &&
                    !(fieldTypeObjectDef?.isAbstract || fieldTypeObjectDef?.implementors?.length > 0) &&
                    !fieldDef.isExternalReference &&
                    !fieldDef.isArray
                ) {
                    requiredFieldsOfScriptObject[key] = createScriptObject<T>(fieldDef.typeName, objectDefinitions);
                }
            }

            return requiredFieldsOfScriptObject;
        },
        emptyFieldTypesMap
    );

    const tmpId = uniqueMotiveId();
    const obj: IScriptObjectModel = {
        id: tmpId,
        type: type,
        ...recursiveScriptObjectFields
    };

    return obj as T;
};

export const createScriptObjectDict = (type: string, objectDefinitions: ITypeDefinitionMap): IScriptObjectDict => {
    return getObjectDict(createScriptObject(type, objectDefinitions));
};

export const isTypeOrInterface = (type: string, objectDefinition: IObjectDefinition): boolean => {
    return (
        type === MotiveTypes.OBJECT ||
        type === objectDefinition.name ||
        (objectDefinition.interfaces && objectDefinition.interfaces.indexOf(type) >= 0)
    );
};

export const isTypeOrImplementor = (type: string, objectDefinition: IObjectDefinition): boolean => {
    return (
        objectDefinition.name === MotiveTypes.OBJECT ||
        type === objectDefinition.name ||
        (objectDefinition.implementors && objectDefinition.implementors.indexOf(type) >= 0)
    );
};

export const isNumberType = (type: string) => {
    return type === MotiveTypes.INT || type === MotiveTypes.FLOAT;
};

export const createObjectReference = (type: string, id: string, label?: string): IObjectReference => {
    // TODO: add editor label?
    return {
        objectId: id,
        objectType: type,
        editorLabel: label || undefined
    };
};

export const createObjectReferenceFromObject = (
    scriptObj: IScriptObjectModel,
    typeDefs?: ITypeDefinitionMap,
    languageSettings?: ILanguageSettings
): IObjectReference => {
    // TODO: add editor label?
    const objRef = createObjectReference(scriptObj.type, scriptObj.id);

    if (typeDefs && languageSettings) {
        objRef.editorLabel = getSmartName(scriptObj, typeDefs, languageSettings);
    }

    return objRef;
};

const recursiveGetMaxDepthSelectorBranches: (branches?: ISelectorBranch[]) => number = branches => {
    if (!branches) {
        return 0;
    }
    return branches.reduce((maxDepth, branch) => {
        if (!branch.nodes) {
            return maxDepth;
        }

        const branchDepth =
            1 +
            branch.nodes.reduce((maxScenarioDepth, subScenario) => {
                const thisMaxDepth = recursiveGetMaxDepthSelectorBranches(subScenario.branches);
                return thisMaxDepth > maxScenarioDepth ? thisMaxDepth : maxScenarioDepth;
            }, 0);

        return branchDepth > maxDepth ? branchDepth : maxDepth;
    }, 1);
};

export const getSelectorTreeMaxDepth: (tree?: ISelectorTree) => number | undefined = tree => {
    if (!tree) {
        return undefined;
    }

    let depth = 1;
    if (tree.branches && tree.branches.length > 0) {
        depth = recursiveGetMaxDepthSelectorBranches(tree.branches);
    }

    return depth;
};

export const editorIconStrClean: (editorIcon?: string) => IconProp | undefined = editorIcon => {
    return editorIcon?.split('fa-')[1] as IconProp;
};

export const filterCatalogItem = (
    catalog: ICatalog<IScriptObjectModel>,
    itemId: string
): ICatalog<IScriptObjectModel> => {
    return {
        ...catalog,
        items: catalog.items?.filter((item: IScriptObjectModel) => {
            return item.id !== itemId;
        })
    };
};
