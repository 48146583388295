import { useCurrentSpace } from '../useCurrentSpace';
import { AddCollaborator } from '../../networking/CollaboratorService';
import { useUserInfo } from '../useUserInfo';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { useDispatch } from 'react-redux';

export interface IAddCollaboratorParams {
    email: string;
}

export const useAddCollaborators = (): IHookProvidedCall<IAddCollaboratorParams> => {
    const [currentSpace] = useCurrentSpace();
    const {
        data: { userInfo }
    } = useUserInfo();
    const dispatch = useDispatch();

    const { triggerCall, ...rest } = useNetworkCallWithoutDataSource();

    async function call(params: IAddCollaboratorParams) {
        if (userInfo) {
            return await triggerCall(AddCollaborator(currentSpace, params.email, userInfo.user.email, dispatch));
        }
    }

    return { execute: call, ...rest };
};
