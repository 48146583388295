import { Reducer } from 'redux';
import produce from 'immer';
import { TextToSpeechAction } from './TextToSpeechActions';

export interface IPollyVoice {
    additionalLanguageCodes: string[];
    gender: string;
    id: string;
    languageCode: string;
    languageName: string;
    name: string;
    supportedEngines: string[];
}

export interface ITextToSpeechState {
    voices: IPollyVoice[];
}

export const defaultTextToSpeechState: ITextToSpeechState = {
    voices: []
};

export const TextToSpeechReducer: Reducer<ITextToSpeechState, TextToSpeechAction> = produce(
    (state: ITextToSpeechState, action: TextToSpeechAction) => {
        switch (action.type) {
            case 'textToSpeech/set': {
                state.voices = action.voices;
                break;
            }
        }
    },
    defaultTextToSpeechState
);
