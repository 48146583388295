import { useCurrentSpace } from '../useCurrentSpace';
import { updateEnumDefinition } from '../../networking/EnumDefinitionService';
import { IEnumDefinition } from '../../models/EnumDefinition';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { useDispatch } from 'react-redux';

export interface IUpdateEnumDefinitionParams {
    enumId: string;
    updatedItem: IEnumDefinition;
}

export const useUpdateEnumDefinition = (): IHookProvidedCall<IUpdateEnumDefinitionParams> => {
    const [currentSpace] = useCurrentSpace();

    const dispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const updateEnumDefinitions = async (params: IUpdateEnumDefinitionParams) => {
        await triggerCall(
            updateEnumDefinition(currentSpace, params.enumId, params.updatedItem, getCancelableDispatch(dispatch))
        );
    };

    return { execute: updateEnumDefinitions, ...rest };
};
