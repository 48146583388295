import { IObjectDrag, IObjectInstanceDrag } from '../DragModels/DragModels';
import { IScriptObjectModel } from '../../../../shared/motive/models/ScriptObjectModel';
import { createScriptObject, createObjectReference } from '../../../../util/MotiveUtils';
import { ScriptActionType } from '../../../../shared/motive/reducers/ScriptEditorReducers';
import { IScriptObjectEventCondition } from '../../../../shared/motive/models/IScriptObjectEventCondition';
import { MotiveTypes } from '../../../../constants/MotiveTypes';
import { Dispatch } from 'redux';
import { ITypeDefinitionMap } from '../../../../shared/motive/models/TypeDefinition';
import { generateEnumOptions } from '../../../../core-ui/utils/ComponentUtils';
import { IEnumDefinition } from '../../../../shared/motive/models/EnumDefinition';

export const onDragCreateCondition = (dispatch: Dispatch, frameId: string, objectDefinitions: ITypeDefinitionMap) => {
    return (dragObjInfo: IObjectDrag) => {
        const condition: IScriptObjectModel = createScriptObject(dragObjInfo.objectType, objectDefinitions);

        const action = {
            type: ScriptActionType.PRE_CONDITION_ADD,
            objectDefinitions: objectDefinitions,
            object: condition,
            destinationFrameId: frameId
        };

        dispatch(action);
    };
};

export const onDragCreateConditionOfInstance = (
    dispatch: Dispatch,
    frameId: string,
    objectDefinitions: ITypeDefinitionMap,
    isEnumRef: boolean,
    event: string = 'close'
) => {
    return (dragObjInfo: IObjectInstanceDrag) => {
        const condition = createScriptObject<IScriptObjectEventCondition>(
            MotiveTypes.SCRIPT_OBJECT_CONDITION,
            objectDefinitions
        );

        if (dragObjInfo.objectType && dragObjInfo.objectId) {
            condition.objectReference = createObjectReference(dragObjInfo.objectType, dragObjInfo.objectId);
            // Vast majority of the time we're linking a sequence of interactions, so "close"
            // should be the most common event used.
            if (isEnumRef) {
                const objEventDefinition = objectDefinitions[MotiveTypes.SCRIPT_OBJECT_EVENT] as IEnumDefinition;

                const eventRef = objEventDefinition?.items?.find(c => c.name === event);

                if (eventRef) {
                    condition.event = {
                        itemId: eventRef.id as string,
                        name: eventRef.name
                    };
                }
            } else {
                condition.event = event;
            }
        }

        const action = {
            type: ScriptActionType.PRE_CONDITION_ADD,
            objectDefinitions: objectDefinitions,
            object: condition,
            destinationFrameId: frameId
        };

        dispatch(action);
    };
};
