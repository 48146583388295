import React, { useContext } from 'react';

interface ICustomEventContextValues {
    customEvents: string[];
    setCustomEvents: (list: string[]) => void;
}

const customEventContext = React.createContext<ICustomEventContextValues>({
    customEvents: [],
    setCustomEvents: () => {}
});

export const CustomEventContextProvider = customEventContext.Provider;
export const useCustomEventContext = () => useContext(customEventContext);
