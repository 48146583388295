import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const containerStyle = (token: IToken) => css`
    display: flex;
    margin-right: ${token.spacings.smallest};
    & > span {
    }
    & > button {
        min-height: 18px;
        max-height: 18px;
        display: flex;
        align-items: center;
        padding: 0 ${token.spacings.smallest};
    }
`;

export const adaptiveButtonStyle = (token: IToken, isFieldAdaptive?: boolean) => css`
    color: ${isFieldAdaptive ? token.colors.bar.variableBarHeader : token.colors.font.secondary};
    font-size: 1rem;
`;
