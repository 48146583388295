import React, { useState, useEffect, useMemo } from 'react';
import { IScriptEditorSessionDependentProps } from '../../components/scriptEditor/ScriptLoader';
import { IResourceReference, ObjectReferenceContextProvider } from '../../contexts/objectReferenceContext';
import { FRAME_PRE_CONDITION } from '../../shared/motive/models/Script';
import { handleScrollInto } from '../../util/RefUtils';

export const ObjectReferenceContextContainer: React.FC<Pick<IScriptEditorSessionDependentProps, 'selectedFrame'>> = ({
    children,
    selectedFrame: frame
}) => {
    const [queueResourceScroll, setQueueResourceScroll] = useState<string | undefined>(undefined);
    const [queueConditionScroll, setQueueConditionScroll] = useState<boolean>(false);

    const conditionReference = React.createRef<HTMLDivElement>();

    const resourceReferences = useMemo(() => {
        const resources: IResourceReference = {};
        frame?.resources?.forEach(value => {
            const ref = React.createRef<HTMLDivElement>();
            resources[value.resource.id] = ref;
        });
        return resources;
    }, [frame?.id, frame?.resources?.length]);

    const scrollToResource = (id: string) => {
        if (resourceReferences[id] && resourceReferences[id].current !== null) {
            handleScrollInto(resourceReferences[id]);
        } else {
            setQueueResourceScroll(id);
        }
    };

    const scrollToCondition = () => {
        handleScrollInto(conditionReference);
    };

    useEffect(() => {
        queueResourceScroll && scrollToResource(queueResourceScroll);
        setQueueResourceScroll(undefined);
    }, [frame?.id, frame?.resources, queueResourceScroll, setQueueResourceScroll]);

    useEffect(() => {
        queueConditionScroll && scrollToCondition();
        setQueueConditionScroll(false);
    }, [frame?.id, frame?.[FRAME_PRE_CONDITION], queueConditionScroll, setQueueConditionScroll]);

    const referenceValues = useMemo(() => {
        return {
            conditionReference,
            scrollToCondition,
            resourceReferences,
            scrollToResource,
            queueResourceScroll
        };
    }, [conditionReference, scrollToCondition, resourceReferences, scrollToResource, queueResourceScroll]);

    return <ObjectReferenceContextProvider value={referenceValues}>{children}</ObjectReferenceContextProvider>;
};
