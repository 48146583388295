import { IActivity } from '../../models/Activity';
import { useCurrentSpace } from '../useCurrentSpace';
import { getActivities } from '../../networking/ActivityService';
import { useNetworkCallForDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedDataSource } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useDispatch, useSelector } from 'react-redux';
import { selectActivities } from '../../../../redux/spaceKeyed/activity/ActivitySelector';

export const useActivities = (): IHookProvidedDataSource<IActivity[]> => {
    const [currentSpace] = useCurrentSpace();

    const dispatch = useDispatch();
    const state = useSelector(selectActivities);

    const { triggerCall, ...rest } = useNetworkCallForDataSource(state);

    const call = async () => {
        await triggerCall(getActivities(currentSpace, dispatch));
    };

    return {
        data: state,
        load: call,
        ...rest
    };
};
