import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const resourcesWrapperStyle = css`
    display: grid;
    grid-gap: 1em;
`;

export const resourceContainerStyle = ({ spacings }: IToken) => css`
    padding-bottom: ${spacings.smaller};
`;

export const resetPointerEventsStyle = css`
    pointer-events: all;
`;

export const absoluteStyleMatchRelativeSize = css`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
`;

export const sharedStyleRightPanel = (maxHeight: number, barHeight: number) => css`
    flex: 1;
    position: sticky;
    top: ${barHeight}px;
    max-height: ${maxHeight}px;
    overflow-y: auto;
`;

export const sharedStyleBodyPanel = css`
    flex: 3;
`;

export const sharedStyleFlexParent = css`
    display: flex;
`;
export const emptyResourceText = (token: IToken) => css`
    padding: 30px;
    background-color: ${token.colors.background.lighter};
    border: 1px dashed ${token.colors.bar.resourceBarHeader};
    font-size: 16px;
`;
