import { useEffect, useCallback, useState } from 'react';

export const useRightClickMenu = (outerRef: React.RefObject<HTMLElement>) => {
    const [xPos, setXPos] = useState('0px');
    const [yPos, setYPos] = useState('0px');
    const [isMenuActive, showMenu] = useState(false);

    const handleContextMenu = useCallback(
        event => {
            if (outerRef && outerRef.current && outerRef.current.contains(event.target)) {
                event.preventDefault();
                setXPos(`${event.pageX}px`);
                setYPos(`${event.pageY}px`);
                showMenu(true);
            } else {
                showMenu(false);
            }
        },
        [showMenu, outerRef, setXPos, setYPos]
    );

    const handleClick = useCallback(() => {
        showMenu(false);
    }, [showMenu]);

    useEffect(() => {
        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            document.removeEventListener('click', handleClick);
        };
    });

    return { xPos, yPos, isMenuActive, showMenu };
};
