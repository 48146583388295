import React, { useEffect, useState } from 'react';
import { DashboardIntentManagement } from '../../components/dashboardIntentManagement';
import { useProjectDashboard } from '../../contexts/projectDashboardContext';
import { useConvoAIIntentsRoute } from '../../routes/convoAIIntentsRoute';
import {
    useCreateCatalog,
    useDeleteCatalog,
    useGetAllCatalogsByType,
    useRenameCatalog
} from '../../shared/motive/hooks/useCatalogs';
import { useToast } from '../../core-ui/hooks/useToast';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useCheckSystemNameConflict } from '../../shared/motive/hooks/useCheckSystemNameConflict/useCheckSystemNameConflict';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { useCurrentProject } from '../../shared/motive/hooks/useCurrentProject';
import { useProjectConfig } from '../../shared/motive/hooks/useProjectConfigs/useProjectConfigs';
import { filterCatalogsByProject } from '../../util/CatalogUtils';
import { IIntentManagementProps } from '../../components/dashboardIntentManagement/DashboardIntentManagement';

interface IIntentCatalogsContainer {
    children?: (props: IIntentManagementProps) => React.ReactNode;
    onSelectCatalog: (catalogId: string) => void;
    onSelectIntent: (catalogId: string, intentId: string) => void;
}

export const IntentCatalogsContainer: React.FC<IIntentCatalogsContainer> = (props): React.ReactElement => {
    // Routing
    const { setSelectedTab } = useProjectDashboard();
    const {
        params: { catalogId: selectedCatalog, intentId: selectedIntent }
    } = useConvoAIIntentsRoute();

    const [currentProjectId] = useCurrentProject();

    // UI Hooks
    const toast = useToast();

    // Network Calls
    const {
        execute: createIntentCatalogCall,
        error: createIntentCatalogError,
        isLoading: isCreateIntentCatalogLoading
    } = useCreateCatalog();

    const {
        execute: renameIntentCatalogCall,
        error: renameIntentCatalogError,
        isLoading: isRenameIntentCatalogLoading
    } = useRenameCatalog();

    const {
        execute: deleteIntentCatalogCall,
        error: deleteIntentCatalogError,
        isLoading: isDeleteIntentCatalogLoading
    } = useDeleteCatalog();

    const { data: currentProject } = useProjectConfig(currentProjectId);
    const { data: watsonIntentCatalogs } = useGetAllCatalogsByType(MotiveTypes.WATSON_INTENT);
    const { data: watsonAgentCatalogs } = useGetAllCatalogsByType(MotiveTypes.WATSON_ASSISTANT);
    // const { data: dialogflowIntentCatalogs } = useGetAllCatalogsByType(MotiveTypes.DIALOGFLOW_INTENT);
    // const { data: dialogflowAgentCatalogs } = useGetAllCatalogsByType(MotiveTypes.DIALOGFLOW_AGENT);

    // Filtered Data
    const intentCatalogsForProject = filterCatalogsByProject([...watsonIntentCatalogs], currentProject);
    const watsonAgentCatalogsForProject = filterCatalogsByProject(watsonAgentCatalogs, currentProject);
    // const dialogflowAgentCatalogsForProject = filterCatalogsByProject(dialogflowAgentCatalogs, currentProject);
    const agentCatalogs: ICatalog[] = [...watsonAgentCatalogsForProject];

    // Other
    const checkNameConflict = useCheckSystemNameConflict();
    const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (renameIntentCatalogError) {
            toast({
                description: 'There was an error in updating an intent catalog.',
                isClosable: true,
                position: 'bottom-right',
                status: 'error',
                title: 'Error: Updating Catalog'
            });
        }
    }, [toast, renameIntentCatalogError]);

    useEffect(() => {
        if (deleteIntentCatalogError) {
            toast({
                description: 'There was an error in deleting an intent catalog.',
                isClosable: true,
                position: 'bottom-right',
                status: 'error',
                title: 'Error: Deleting Catalog'
            });
        }
    }, [toast, deleteIntentCatalogError]);

    const handleCreateIntentCatalog = (catalogTitle: string) => {
        if (checkNameConflict(catalogTitle)) {
            toast({
                description: `A catalog already exists with the name ${catalogTitle}.`,
                isClosable: true,
                position: 'bottom-right',
                status: 'error',
                title: 'Error: Creating a Catalog'
            });
        } else {
            createIntentCatalogCall({
                catalogTitle: catalogTitle,
                objectType: MotiveTypes.WATSON_INTENT,
                importToCurrentProject: true
            }).then(obj => {
                if (obj) {
                    toast({
                        description: `Successfully created ${catalogTitle}.`,
                        isClosable: true,
                        position: 'bottom-right',
                        status: 'success',
                        title: 'Success: Created a Catalog'
                    });
                }
            });
        }
    };

    //type: ICatalog<IIntent>
    const handleRenameIntentCatalog = async (id: string, title: string) => {
        if (!checkNameConflict(title)) {
            await renameIntentCatalogCall({ catalogId: id, catalogTitle: title }).then(res => {
                toast({
                    description: `Successfully renamed catalog to ${title}.`,
                    isClosable: true,
                    position: 'bottom-right',
                    status: 'success',
                    title: 'Success: Renamed a Catalog'
                });
            });
        } else {
            toast({
                description: 'A catalog already exists with that name.',
                isClosable: true,
                position: 'bottom-right',
                status: 'error',
                title: 'Error: Renaming Catalog'
            });
        }
    };

    //type: ICatalog<IIntent>
    const handleDeleteIntentCatalog = async (intentCatalog: ICatalog) => {
        await deleteIntentCatalogCall({ catalogId: intentCatalog.id }).then(() => {
            toast({
                description: `Successfully deleted catalog ${intentCatalog.title}.`,
                isClosable: true,
                position: 'bottom-right',
                status: 'success',
                title: 'Success: Deleted a Catalog'
            });
        });
    };

    const handleSearchFilter = (filter: string | undefined) => {
        setSearchFilter(filter);
    };

    if (props.children) {
        return (
            <>
                {props.children({
                    onSelectCatalog: props.onSelectCatalog,
                    intentCatalogs: intentCatalogsForProject,
                    agentCatalogs: agentCatalogs,
                    onSelectIntent: props.onSelectIntent,
                    onRenameIntentCatalog: handleRenameIntentCatalog,
                    onCreateCatalog: handleCreateIntentCatalog,
                    onDeleteIntentCatalog: handleDeleteIntentCatalog,
                    isCreateCatalogLoading: isCreateIntentCatalogLoading,
                    searchFilter: searchFilter,
                    onIntentSearch: handleSearchFilter
                })}
            </>
        );
    }

    return (
        <DashboardIntentManagement
            onSelectCatalog={props.onSelectCatalog}
            onCreateCatalog={handleCreateIntentCatalog}
            agentCatalogs={agentCatalogs}
            onSelectIntent={props.onSelectIntent}
            selectedCatalog={selectedCatalog}
            selectedIntent={selectedIntent}
            intentCatalogs={intentCatalogsForProject}
            onRenameIntentCatalog={handleRenameIntentCatalog}
            onDeleteIntentCatalog={handleDeleteIntentCatalog}
            onGoBackToCatalogs={() => setSelectedTab('ConvoAI')}
            isCreateCatalogLoading={isCreateIntentCatalogLoading}
            searchFilter={searchFilter}
            onIntentSearch={handleSearchFilter}
        />
    );
};
