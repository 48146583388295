import { Dispatch } from 'redux';
import { IObjectInstanceDrag } from '../DragModels/DragModels';
import {
    IScriptEditorAddDefinedVariablesAction,
    ScriptActionType
} from '../../../../shared/motive/reducers/ScriptEditorReducers';

export const onDragVariableCreate = (dispatch: Dispatch, frameId: string) => {
    return (dragObjInfo: IObjectInstanceDrag) => {
        const scriptDispatchAction: IScriptEditorAddDefinedVariablesAction = {
            type: ScriptActionType.ADD_DEFINED_VARIABLES_TO_FRAME,
            targetFrameId: frameId,
            variableIds: [dragObjInfo.objectId]
        };
        dispatch(scriptDispatchAction);
    };
};
