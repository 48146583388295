import React from 'react';
import { ISelectorNode } from '../../shared/motive/models/Script';
import { createScriptObject } from '../../util/MotiveUtils';
import { useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo';
import { addCondition } from '../../util/ScriptUtils';
import { IScriptObjectUpdateAction, ScriptActionType } from '../../shared/motive/reducers/ScriptEditorReducers';
import { useDispatch } from 'react-redux';
import { DRAG_ITEM_TYPE_CREATE_CONDITION } from '../../constants/DragAndDrop';
import { IObjectDrag } from '../../components/_common/DragAndDrop/DragModels/DragModels';
import { Drop } from '../../components/_common/DragAndDrop/Drop/Drop';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDroppableScenarioConditionContainerProps {
    scenarioNode?: ISelectorNode;
    frameId: string;
    subFramePath: string;
}
export const DroppableScenarioConditionContainer: React.FC<IDroppableScenarioConditionContainerProps> = ({
    children,
    scenarioNode,
    frameId,
    subFramePath,
    ...restDroppableProps
}) => {
    const droppableId = `droppable:scenarioCondition.${scenarioNode?.id ?? 'selectorTree'}`;
    const objectDefinitions = useObjectDefinitions();
    const scriptDispatch = useDispatch();

    const dropList = [
        {
            dragType: DRAG_ITEM_TYPE_CREATE_CONDITION,
            onDragEnd: (dropObjInfo: IObjectDrag) => {
                if (scenarioNode && dropObjInfo?.objectType) {
                    const newCondition = createScriptObject(dropObjInfo.objectType, objectDefinitions);
                    const updatedWrapper = addCondition(scenarioNode.condition, newCondition, objectDefinitions);

                    const action: IScriptObjectUpdateAction = {
                        type: ScriptActionType.OBJECT_UPDATE,
                        updatedObject: updatedWrapper,
                        updatedObjectPath: subFramePath,
                        targetFrameId: frameId
                    };

                    scriptDispatch(action);
                }
            }
        }
    ];

    return (
        <Drop dropId={droppableId} isDisabled={!scenarioNode} allowedDragList={dropList} {...restDroppableProps}>
            {children}
        </Drop>
    );
};
