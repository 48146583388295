import { SPACE_ENDPOINT } from '../constants/ApiEndpoint';
import { httpClient } from './HttpClient';
import { IEnumDefinition, IEnumDefinitionItem } from '../models/EnumDefinition';
import {
    enumAppendAction,
    enumItemAppendAction,
    enumDeleteAction,
    enumItemDeleteAction,
    EditorInfoActions,
    enumUpdateAction,
    enumItemUpdateAction
} from '../../../redux/spaceKeyed/editorInfo/EditorInfoActions';

export const createEnumPath = (spaceName: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/enumDefinitions`;
};

export const updateOrDeleteEnumPath = (spaceName: string, enumId: string) => {
    return `${createEnumPath(spaceName)}/${enumId}/`;
};

export const createEnumItemPath = (spaceName: string, enumId: string) => {
    return `${updateOrDeleteEnumPath(spaceName, enumId)}items`;
};

export const updateOrDeleteEnumItemPath = (spaceName: string, enumId: string, enumItemId: string) => {
    return `${createEnumItemPath(spaceName, enumId)}/${enumItemId}/`;
};

export const createEnumNetworkCall = async (
    spaceName: string,
    eDef: IEnumDefinition,
    dispatch: (action: EditorInfoActions) => void
): Promise<IEnumDefinition> => {
    const response = await httpClient.post(createEnumPath(spaceName), eDef);
    dispatch(enumAppendAction(response.data));
    return response.data as IEnumDefinition;
};

export const updateEnumDefinition = async (
    spaceName: string,
    enumId: string,
    data: IEnumDefinition,
    dispatch: (action: EditorInfoActions) => void
): Promise<IEnumDefinition> => {
    dispatch(enumUpdateAction(enumId, data));
    const response = await httpClient.put(updateOrDeleteEnumPath(spaceName, enumId), data);
    dispatch(enumUpdateAction(enumId, response.data));
    return response.data as IEnumDefinition;
};

export const deleteEnumNetworkCall = async (
    spaceName: string,
    enumId: string,
    dispatch: (action: EditorInfoActions) => void
): Promise<void> => {
    await httpClient.delete(updateOrDeleteEnumPath(spaceName, enumId));
    dispatch(enumDeleteAction(enumId));
};

export const createEnumItemNetworkCall = async (
    spaceName: string,
    data: IEnumDefinitionItem,
    enumId: string,
    dispatch: (action: EditorInfoActions) => void
): Promise<IEnumDefinitionItem> => {
    const response = await httpClient.post(createEnumItemPath(spaceName, enumId), data);
    dispatch(enumItemAppendAction(enumId, response.data));
    return response.data as IEnumDefinitionItem;
};

export const updateEnumItemNetworkCall = async (
    spaceName: string,
    enumId: string,
    enumItemId: string,
    data: IEnumDefinitionItem,
    dispatch: (action: EditorInfoActions) => void
): Promise<IEnumDefinitionItem> => {
    const response = await httpClient.put(updateOrDeleteEnumItemPath(spaceName, enumId, enumItemId), data);
    dispatch(enumItemUpdateAction(enumId, enumItemId, response.data));
    return response.data as IEnumDefinitionItem;
};

export const deleteEnumItemNetworkCall = async (
    spaceName: string,
    enumId: string,
    enumItemId: string,
    dispatch: (action: EditorInfoActions) => void
): Promise<void> => {
    await httpClient.delete(updateOrDeleteEnumItemPath(spaceName, enumId, enumItemId));
    dispatch(enumItemDeleteAction(enumId, enumItemId));
};
