import React, { ReactNode, Ref, forwardRef } from 'react';
import { FrameInspectorResources } from '../frameInspectorResources';
import {
    conditionHeaderStyle,
    resourceHeaderStyle,
    sectionHeaderIcons,
    defaultVariableHeaderStyle,
    headerIcon,
    workAreaNarrow,
    bodySectionHeaderStyle,
    bodySectionStyle,
    mfIconStyle,
    message,
    frameBackgroundStyle,
    expandSection
} from './FrameInspectorBody.style';
import { Heading } from '../../core-ui/heading';
import { FRAME_PRE_CONDITION, IConditionWrapper, IScript, IFrame } from '../../shared/motive/models/Script';
import { Text } from '../../core-ui/text';
import { ConditionContainer } from '../../containers/conditionContainer/ConditionContainer';
import { useStyle } from '../../shared/hooks/useStyle';
import { DocumentBox } from '../documentBox';
import { DraggableHeightUsedScrollContainer } from '../../containers/heightUsedScrollContainer';
import { Icon } from '../../core-ui/icon';
import { Size } from '../../core-ui/constants/Size';
import { useToggle } from '../../shared/hooks/useToggle';
import { Collapsible } from '../../core-ui/collapse';
import { getResourceIcons, getConditionIcons } from '../../util/IconUtils';
import {
    useTypeDefinitions,
    useObjectDefinitions
} from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { useCurrentLanguageSettings } from '../../shared/motive/stores/editorLocale/EditorLocale';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { TooltipBox } from '../../core-ui/tooltip';
import { FrameInspectorVariables } from '../frameInspectorVariables';
import { useObjectReferenceContext } from '../../contexts/objectReferenceContext';
import { RotateChevron } from '../rotateChevron';
import { SerializedStyles } from '@emotion/core';
import { Drop } from '../_common/DragAndDrop/Drop/Drop';
import {
    DRAG_ITEM_TYPE_SCRIPT_VARIABLE,
    DRAG_ITEM_TYPE_CREATE_CONDITION,
    DRAG_ITEM_TYPE_CREATE_OBJECT_CONDITION,
    DRAG_ITEM_TYPE_CREATE_RESOURCE,
    DRAG_ITEM_TYPE_CATALOG_ITEM,
    DROPPABLE_ID_FRAME_INSPECTOR,
    DROPPABLE_ID_CONDITIONS_PANEL,
    DROPPABLE_ID_RESOURCE_PANEL,
    DROPPABLE_ID_FRAME_INSPECTOR_SCRIPT_VARIABLES,
    DROPPABLE_ID_FRAME_INSPECTOR_SCRIPT_VARIABLES_SECTION,
    DRAG_ITEM_TYPE_RESOURCE
} from '../../constants/DragAndDrop';
import { onDragVariableCreate } from '../_common/DragAndDrop/OnDragHandlers/VariableActions';
import { useDispatch } from 'react-redux';
import { useGetAllCatalogs } from '../../shared/motive/hooks/useCatalogs';
import {
    onDragCreateCondition,
    onDragCreateConditionOfInstance
} from '../_common/DragAndDrop/OnDragHandlers/ConditionActions';
import { onDragAddResource } from '../_common/DragAndDrop/OnDragHandlers/ResourceActions';
import { onDragCatalogItem } from '../_common/DragAndDrop/OnDragHandlers/CatalogActions';
import { useCheckDragHover } from '../_common/DragAndDrop/DragAndDropProvider/DragAndDropContext';
import { useCurrentProjectConfig } from '../../redux/spaceKeyed/projectConfig/ProjectConfigSelectors';
import { VersionDates } from '../../util/ScriptUtils';
import { useTranslation, Trans } from 'react-i18next';

export enum FrameBodySectionEnum {
    PreConditions,
    Resources,
    Variables,
    PostConditions
}

export interface IFrameInspectorBodyProps {
    frame: IFrame;
    script: IScript;
    onClickSetVariableId: (variableId: string) => void;
    onClickSetEventId: (eventId: string) => void;
}

export const FrameInspectorBody: React.FC<IFrameInspectorBodyProps> = ({
    frame,
    script,
    onClickSetVariableId,
    onClickSetEventId
}) => {
    const isEmptyMainFrame =
        script &&
        frame.id === script.rootFrame.id &&
        !script.rootFrame.resources?.length &&
        !script.rootFrame[FRAME_PRE_CONDITION] &&
        !script.rootFrame.postCondition;
    const token = useStyle();
    const dispatch = useDispatch();
    const [showConditions, toggleConditions] = useToggle(true);
    const [showResource, toggleResources] = useToggle(true);
    const [showVariables, toggleVariables] = useToggle(true);

    const typeDefinitions = useTypeDefinitions();
    const language = useCurrentLanguageSettings();

    const conditionIcon = getConditionIcons(typeDefinitions, frame[FRAME_PRE_CONDITION]);
    const resourceIcons = getResourceIcons(typeDefinitions, language, frame.resources);

    const frameHasDefinedVariables = frame?.definedVariables && frame.definedVariables?.length > 0;

    const {
        resourceReferences,
        scrollToResource,
        conditionReference,
        queueResourceScroll
    } = useObjectReferenceContext();

    const checkDrag = useCheckDragHover();

    const { t } = useTranslation();

    const showVariableSection =
        frameHasDefinedVariables ||
        checkDrag(DROPPABLE_ID_FRAME_INSPECTOR_SCRIPT_VARIABLES, DRAG_ITEM_TYPE_SCRIPT_VARIABLE);

    const isVariableDragOver = checkDrag(
        DROPPABLE_ID_FRAME_INSPECTOR_SCRIPT_VARIABLES_SECTION,
        DRAG_ITEM_TYPE_SCRIPT_VARIABLE
    );

    const isResourceDragOver = checkDrag(DROPPABLE_ID_FRAME_INSPECTOR, DRAG_ITEM_TYPE_CREATE_RESOURCE);

    const isConditionDragOver = checkDrag(DROPPABLE_ID_FRAME_INSPECTOR, DRAG_ITEM_TYPE_CREATE_CONDITION);

    return (
        <DraggableHeightUsedScrollContainer
            scrollRef={queueResourceScroll ? resourceReferences[queueResourceScroll] : undefined}
        >
            <FrameInspectorBodyDroppables frameId={frame.id}>
                <div css={frameBackgroundStyle(frame.isEnabled)}>
                    <DocumentBox
                        rightColRender={
                            !showVariableSection ? (
                                undefined
                            ) : (
                                <BodySection
                                    headerRender={<Heading size={Size.SMALL}>{t('variablesInFrame')}</Heading>}
                                    headerStyle={defaultVariableHeaderStyle(token)}
                                    showCollapse={showVariables}
                                    onClickToggleCollapse={toggleVariables}
                                >
                                    {frameHasDefinedVariables && (
                                        <Drop
                                            dropId={DROPPABLE_ID_FRAME_INSPECTOR_SCRIPT_VARIABLES_SECTION}
                                            allowedDragList={[
                                                {
                                                    dragType: DRAG_ITEM_TYPE_SCRIPT_VARIABLE,
                                                    onDragEnd: onDragVariableCreate(dispatch, frame.id)
                                                }
                                            ]}
                                        >
                                            <FrameInspectorVariables
                                                frame={frame}
                                                script={script}
                                                onClickSetVariableId={onClickSetVariableId}
                                            />
                                            <div css={expandSection(isVariableDragOver)}></div>
                                        </Drop>
                                    )}
                                </BodySection>
                            )
                        }
                    >
                        <ConditionDroppables frameId={frame.id}>
                            <BodySection
                                headerRender={
                                    <>
                                        <Heading size={Size.SMALL}>{t('conditions')}</Heading>
                                        <div ref={conditionReference} css={sectionHeaderIcons}>
                                            {(frame[FRAME_PRE_CONDITION] as IConditionWrapper)?.condition && (
                                                <Icon
                                                    size={Size.SMALLER}
                                                    faIcon={conditionIcon.icon as IconName}
                                                ></Icon>
                                            )}
                                        </div>
                                    </>
                                }
                                headerStyle={conditionHeaderStyle(token)}
                                showCollapse={showConditions}
                                onClickToggleCollapse={toggleConditions}
                            >
                                <ConditionContainer
                                    condition={frame[FRAME_PRE_CONDITION]}
                                    subFrameConditionPath={FRAME_PRE_CONDITION}
                                    frame={frame}
                                    dropId={frame.id}
                                    script={script}
                                    onClickSetEventId={onClickSetEventId}
                                />
                                <div css={expandSection(isConditionDragOver)}></div>
                            </BodySection>
                        </ConditionDroppables>
                        <Drop
                            dropId={DROPPABLE_ID_FRAME_INSPECTOR_SCRIPT_VARIABLES}
                            allowedDragList={[
                                {
                                    dragType: DRAG_ITEM_TYPE_SCRIPT_VARIABLE,
                                    onDragEnd: onDragVariableCreate(dispatch, frame.id)
                                }
                            ]}
                        >
                            <BodySection
                                showCollapse={showResource}
                                onClickToggleCollapse={toggleResources}
                                headerStyle={resourceHeaderStyle(token)}
                                headerRender={
                                    <>
                                        <Heading size={Size.SMALL}>{t('resources')}</Heading>
                                        <div css={sectionHeaderIcons}>
                                            {resourceIcons.map((iconDef, idx) => {
                                                return (
                                                    <TooltipBox
                                                        color={'black'}
                                                        label={iconDef.name}
                                                        key={idx}
                                                        hasArrow={true}
                                                    >
                                                        <Icon
                                                            onClick={() =>
                                                                scrollToResource(iconDef.resourceWrapper.resource.id)
                                                            }
                                                            css={headerIcon}
                                                            size={Size.SMALLER}
                                                            faIcon={iconDef.icon as IconName}
                                                        ></Icon>
                                                    </TooltipBox>
                                                );
                                            })}
                                        </div>
                                    </>
                                }
                            >
                                {!isEmptyMainFrame && (
                                    <>
                                        <FrameInspectorResources
                                            frame={frame}
                                            script={script}
                                            resourceReferences={resourceReferences}
                                            onClickSetEventId={onClickSetEventId}
                                        />
                                        <div css={expandSection(isResourceDragOver)}></div>
                                    </>
                                )}
                                {isEmptyMainFrame && <MainFrameHelpText />}
                            </BodySection>
                        </Drop>
                    </DocumentBox>
                </div>
            </FrameInspectorBodyDroppables>
        </DraggableHeightUsedScrollContainer>
    );
};

const MainFrameHelpText: React.FC = (): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <>
            <Text css={message}>
                <Icon faIcon={'ban'} iconStyle={mfIconStyle}></Icon>
                <Trans i18nKey="mainFrameText">
                    This is your main frame. <b>You can not add resources to the main frame.</b> To begin editing the
                    script, select a subframe to the left and drag resources from the right side into this middle
                    column.
                </Trans>
            </Text>
        </>
    );
};

interface IFrameInspectorBodyDroppablesProps {
    children: ReactNode;
    frameId: string;
}
const FrameInspectorBodyDroppables: React.FC<IFrameInspectorBodyDroppablesProps> = ({ children, frameId }) => {
    const { data: catalogs } = useGetAllCatalogs();
    const language = useCurrentLanguageSettings();
    const objectDefinitions = useObjectDefinitions();
    const scriptDispatch = useDispatch();
    const { scrollToResource } = useObjectReferenceContext();
    const config = useCurrentProjectConfig();
    const isEnumRef = VersionDates.checkUseEnumRef(config?.typeVersionDate);

    const dropList = [
        {
            dragType: DRAG_ITEM_TYPE_CREATE_CONDITION,
            onDragEnd: onDragCreateCondition(scriptDispatch, frameId, objectDefinitions)
        },
        {
            dragType: DRAG_ITEM_TYPE_CREATE_OBJECT_CONDITION,
            onDragEnd: onDragCreateConditionOfInstance(scriptDispatch, frameId, objectDefinitions, isEnumRef)
        },
        {
            dragType: DRAG_ITEM_TYPE_CREATE_RESOURCE,
            onDragEnd: onDragAddResource(scriptDispatch, frameId, objectDefinitions, id => {
                scrollToResource(id);
            })
        },
        {
            dragType: DRAG_ITEM_TYPE_CATALOG_ITEM,
            onDragEnd: onDragCatalogItem(scriptDispatch, catalogs, objectDefinitions, language, frameId)
        }
    ];

    return (
        <Drop dropId={DROPPABLE_ID_FRAME_INSPECTOR} allowedDragList={dropList}>
            {children}
        </Drop>
    );
};

interface IConditionDroppablesProps {
    children: ReactNode;
    frameId: string;
}

const ConditionDroppables: React.FC<IConditionDroppablesProps> = props => {
    const { data: catalogs } = useGetAllCatalogs();
    const language = useCurrentLanguageSettings();
    const objectDefinitions = useObjectDefinitions();
    const scriptDispatch = useDispatch();

    const dropList = [
        {
            dragType: DRAG_ITEM_TYPE_CATALOG_ITEM,
            onDragEnd: onDragCatalogItem(
                scriptDispatch,
                catalogs,
                objectDefinitions,
                language,
                props.frameId,
                'condition'
            )
        }
    ];

    return (
        <Drop dropId={DROPPABLE_ID_CONDITIONS_PANEL} allowedDragList={dropList}>
            {props.children}
        </Drop>
    );
};

interface IBodySectionProps {
    onClickToggleCollapse: () => void;
    showCollapse: boolean;
    headerRender: ReactNode;
    headerStyle?: SerializedStyles;
    ref?: Ref<HTMLDivElement>;
    children?: ReactNode;
    className?: string;
}
const BodySection = forwardRef<HTMLDivElement, IBodySectionProps>(function BodySection(
    { children, onClickToggleCollapse, showCollapse, headerStyle, headerRender, className },
    ref
) {
    const token = useStyle();
    return (
        <>
            <div ref={ref} css={bodySectionStyle(token)} className={className}>
                <Collapsible isShowing={showCollapse}>
                    <div css={workAreaNarrow}>{children}</div>
                </Collapsible>
                <div css={[bodySectionHeaderStyle(token), headerStyle]} onClick={onClickToggleCollapse}>
                    <RotateChevron isPointingDown={showCollapse} />
                    {headerRender}
                </div>
            </div>
        </>
    );
});
