import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const scenarioButtonStyle = ({ spacings, colors }: IToken) => css`
    all: unset;
    background-color: ${colors.background.lighter};
    width: 400px;
    height: 50px;
    margin: 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: ${spacings.smaller};
    &:hover {
        background-color: ${colors.background.light};
    }
    border-bottom: 1px solid ${colors.border.primary};
`;

export const scenarioButtonContainerStyle = css`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: right;
    height: 50px;
`;

export const scenarioInnerElementStyle = css`
    margin: 10px;
    width: 390px;
    height: 30px;
`;

export const scenarioSaveCancelStyle = css`
    margin-left: 10px;
    margin-right: 10px;
    width: 80px;
`;

export const catalogTitleStyle = css`
    font-weight: 600;
    font-size: 1.1em;
`;

export const createScenarioCardStyle = css`
    padding: 20px;
`;
