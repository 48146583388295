import { keyframes, css } from '@emotion/core';

const rotatingAnimation = keyframes` {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
}`;

export const rotating = css`
    -webkit-animation: ${rotatingAnimation} 2s linear infinite;
`;

const pulseAnimation = keyframes`
 {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(70%);
  }
  100% {
      filter: brightness(100%);
  }
}`;

export const pulse = css`
    -webkit-animation: ${pulseAnimation} 1s infinite;

}`;
const transformStyle = (open: boolean) => `rotate(${open ? '0deg' : '-90deg'})`;
const transitionStyle = `0.15s ease-in-out`;

export const rotateChevron = (open: boolean) => css`
    -webkit-transition: ${transitionStyle};
    -moz-transition: ${transitionStyle};
    -o-transition: ${transitionStyle};
    transition: ${transitionStyle};
    transform: ${transformStyle(open)};
    -webkit-transform: ${transformStyle(open)};
    -moz-transform: ${transformStyle(open)};
    -ms-transform: ${transformStyle(open)};
    align-items: center;
    margin-right: 6px;
`;

const textPulseAnimation = keyframes`
    0% { 
        opacity: 1.0;
    }
    50% { 
        opacity: 0.5;
    }
    100% { 
        opacity: 1.0;
    }
`;

export const textPulse = css`
    -webkit-animation: ${textPulseAnimation} 0.7s infinite;
`;
