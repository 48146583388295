import { css } from '@emotion/core';

export const modalScriptStyle = css`
    position: fixed;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 70%;
    background-color: white;
    border-radius: 4px;
    padding: 24px;
    transform: translate(-50%, -50%);
    border: 1px solid #d7d7d7;
    z-index: 100;
`;

export const pageBackground = css`
    overflow: hidden;
    pointer-events: none;
    user-select: none;
`;

export const storyFlowSelectStyle = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 50;
    opacity: 1;
`;
