import { ICatalog } from '../../../models/Catalog';
import { IScriptObjectModel } from '../../../models/ScriptObjectModel';
import { IHookProvidedDataSource } from '../../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useCurrentSpace } from '../../useCurrentSpace';
import { useNetworkCallForDataSource } from '../../../../hooks/useNetworkCallForDataSource';
import { getCatalogsNetworkCall } from '../../../networking/CatalogService';
import { useSelector, useDispatch } from 'react-redux';
import { SelectAllCatalogs } from '../../../../../redux/spaceKeyed/catalog/CatalogSelectors';

export const useGetAllCatalogs = (): IHookProvidedDataSource<ICatalog<IScriptObjectModel>[]> => {
    const [space] = useCurrentSpace();
    const catalogs = useSelector(SelectAllCatalogs);
    const dispatch = useDispatch();

    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallForDataSource(catalogs);

    const load = async () => {
        await triggerCall(getCatalogsNetworkCall(space, getCancelableDispatch(dispatch)));
    };

    return {
        data: catalogs,
        load: load,
        ...rest
    };
};
