import { css } from '@emotion/core';

export const scriptEditorHistoryWrapper = (height: number) => css`
    height: ${height}px;
    padding: 0px 32px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
`;

export const historyIcon = css`
    color: white;
`;

export const historyWrapper = (isActive: boolean) => css`
    margin: 0px 8px;
    color: white;

    ${!isActive &&
        `cursor: auto;
        filter: brightness(40%);

        &:hover {
            filter: brightness(40%);
        }
    `}
`;
