import { useCurrentSpace } from '../useCurrentSpace';
import { deleteMediaItemNetworkCall } from '../../networking/MediaService';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useDispatch } from 'react-redux';

export interface IDeleteMediaItemParams {
    id: string;
    force?: boolean;
}

export const useDeleteMediaItem = (throwErrors: boolean = false): IHookProvidedCall<IDeleteMediaItemParams> => {
    const [currentSpace] = useCurrentSpace();
    const mediaDispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource(throwErrors);

    const deleteMediaItem = async (params: IDeleteMediaItemParams): Promise<void> => {
        return await triggerCall(
            deleteMediaItemNetworkCall(currentSpace, params.id, getCancelableDispatch(mediaDispatch), params.force)
        );
    };

    return {
        execute: deleteMediaItem,
        ...rest
    };
};
