//istanbul ignore file MOTIVE_EXCEPTION
import React from 'react';
import { IProjectsRouteParams } from '../projectsRoute';
import { ScenariosContainer } from '../../containers/scenariosContainer';
import { useRoute } from '../useRoute';

export interface IScenariosRouteParams extends IProjectsRouteParams {
    scenarioName?: string;
}

export const useScenariosRoute = () => useRoute<IScenariosRouteParams>('Scenarios');

export const ScenariosRoute: React.FC = () => {
    return <ScenariosContainer />;
};
