import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';
export {};

export const catalogImportListStyle = (token: IToken) => css`
    font-size: ${token.fontSizes.small};
    margin-bottom: 5px;
`;

export const importButtonStyle = css`
    margin-top: 5px;
`;
