import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const drawerWrapperStyle = (token: IToken) => css`
    padding-left: ${token.spacings.smaller};
`;

export const actionBarStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const buttonWrapperStyle = css`
    display: flex;
`;

export const buttonSpacingStyle = (token: IToken) => css`
    margin-left: ${token.spacings.smallest};
`;

export const deleteButtonStyle = (token: IToken) => css`
    background-color: ${token.colors.interactible.warning};
`;

export const catalogSettingButtonStyle = (token: IToken) => css`
    background-color: ${token.colors.background.neutral};
`;

export const createIntentWrapper = (token: IToken) => css`
    padding: ${token.spacings.small};
    border: 1px solid ${token.colors.border.secondary};
    margin-top: ${token.spacings.smaller};
`;
