import React from 'react';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useGetAllCatalogsByType, useGetCatalogById } from '../../shared/motive/hooks/useCatalogs';
import { useCurrentProject } from '../../shared/motive/hooks/useCurrentProject';
import { filterCatalogsByProject } from '../../util/CatalogUtils';
import { useProjectConfig } from '../../shared/motive/hooks/useProjectConfigs/useProjectConfigs';
import { DrawerIntentManagement } from '../../components/drawerIntentManagement';

export interface IDrawerIntentsContainerProps {
    intentCatalogId: string;
}

export const DrawerIntentsContainer: React.FC<IDrawerIntentsContainerProps> = props => {
    const [currentProjectId] = useCurrentProject();
    const { data: currentProject } = useProjectConfig(currentProjectId);

    const { data: intentCat } = useGetCatalogById(props.intentCatalogId);
    const { data: watsonAssistantCatalogs } = useGetAllCatalogsByType(MotiveTypes.WATSON_ASSISTANT);
    const watsonAssistantCatalogsForSpace = filterCatalogsByProject(watsonAssistantCatalogs, currentProject);

    return <DrawerIntentManagement agentCatalogs={watsonAssistantCatalogsForSpace} intentCat={intentCat} />;
};
