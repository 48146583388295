import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext/';
import chroma from 'chroma-js';

export const baseTabStyle = (token: IToken) => css`
    color: ${token.colors.font.tertiary};
    width: 25%;
    margin: 48px 0 0 0;
    padding: ${token.spacings.smaller} ${token.spacings.small};
    // text-transform: capitalize;
    // font-size: ${token.fontSizes.small};
`;

export const tabStyle = (token: IToken) => css`
    ${baseTabStyle(token)}
    background-color: ${token.colors.bar.dashboardNavbar};
    transition-duration: 100ms;
    border-radius: 0px;
    &:hover {
        color: ${token.colors.font.tertiary};
        background-color: ${token.colors.bar.tertiary};
    }
    &:active {
        box-shadow: none;
        background: linear-gradient(90deg, rgba(63,189,203,1) 0%, rgba(99,195,183,1) 47%, rgba(142,202,158,1) 100%);
    }
`;

export const selectedTabStyle = (token: IToken) => css`
    ${baseTabStyle(token)}
    background: linear-gradient(90deg, rgba(63,189,203,1) 0%, rgba(99,195,183,1) 47%, rgba(142,202,158,1) 100%);
    transition-duration: 100ms;
    border-radius: 0px;
    &:hover {
        filter: brightness(110%);
    }
    &:active {
        box-shadow: none;
    }
`;

export const containerStyle = (token: IToken, dashboardHeight: number) => css`
    display: flex;
    position: relative;
    //height: ${dashboardHeight}px;
    align-items: center;
    background-color: ${chroma(token.colors.bar.dashboardNavbar)
        .darken()
        .css()};
`;
