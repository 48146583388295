import { IToken } from './../../shared/contexts/styleContext/interfaces/Token';
import { css } from '@emotion/core';
import { stretchWidth } from '../../constants/HelperStyles';

export const scenarioHeaderWrapperStyle = () => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const emptyCollectionStyle = (token: IToken) => css`
    ${stretchWidth}
    margin: ${token.spacings.smallest} 0;
`;

export const scenarioSectionStyle = (token: IToken) => css`
    margin: 0 ${token.spacings.smallest};
`;

export const creationFlowModalStyle = (token: IToken) => css`
    padding: 10px;
    margin: 0 ${token.spacings.medium};
`;

export const cancelModalButtonStyle = css`
    float: right;
`;

export const scenarioDragStyle = css`
    background-color: #eeffed;
    outline: 2px dashed #00ce5c;
`;
export const scenarioDropStyle = css`
    min-height: 100px;
`;
