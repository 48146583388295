import { useContext } from 'react';
import React from 'react';
import { DropResult, DragStart, BeforeCapture } from 'react-beautiful-dnd';
import { IBaseDrag } from '../../components/_common/DragAndDrop/Drop/Drop';
import { extractDraggableInfo } from '../../components/_common/DragAndDrop/DragAndDropProvider/DragAndDropProvider';

export interface IEditorDragContextValue {
    beforeCapture?: BeforeCapture;
    dropResult?: DropResult;
    dragStart?: DragStart;
}
const editorDragContext = React.createContext<IEditorDragContextValue>({});
export const EditorDragContextProvider = editorDragContext.Provider;
export const useDragContext = () => useContext(editorDragContext);

export const useDragBeforeCapture = () => {
    const { beforeCapture } = useContext(editorDragContext);
    return beforeCapture;
};

export function useCurrentDrag<T extends IBaseDrag>() {
    const beforeCapture = useDragBeforeCapture();

    const dragInfo = beforeCapture && extractDraggableInfo<T>(beforeCapture.draggableId);

    return dragInfo;
}
