import { useCurrentSpace } from '../useCurrentSpace';
import { uniqueMotiveId } from '../../../../util/MotiveUtils';
import { createScriptNetworkCall } from '../../networking/ScriptService';
import { MotiveTypes } from '../../../../constants/MotiveTypes';
import { IFrame, IScript } from '../../models/Script';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { useDispatch } from 'react-redux';

export const mainFrame: IFrame = {
    name: 'Main Frame',
    notes: null,
    resources: [],
    isExclusive: false,
    isEnabled: true,
    isLive: false,
    resetOnClose: false,
    subFrames: [],
    objectFieldVariableBindings: {},
    definedVariables: [],
    id: uniqueMotiveId(),
    type: MotiveTypes.FRAME
};

export interface ICreateScriptParams {
    scriptName: string;
    catalogId: string;
}
export const useCreateScript = (): IHookProvidedCall<ICreateScriptParams> => {
    const [currentSpace] = useCurrentSpace();
    //const [, catalogDispatch] = useSubReducer(CatalogReducer, 'catalogState');
    const catalogDispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const postScript = async (params: ICreateScriptParams) => {
        if (!params.scriptName) {
            throw new Error('Script title cannot be empty');
        }

        const script: IScript = {
            id: uniqueMotiveId(),
            name: params.scriptName,
            type: MotiveTypes.MOTIVE_SCRIPT,
            rootFrame: mainFrame,
            path: ''
        };

        await triggerCall(
            createScriptNetworkCall(currentSpace, script, params.catalogId, getCancelableDispatch(catalogDispatch))
        );
    };

    return { execute: postScript, ...rest };
};
