/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Card } from '../../core-ui/card';
import { Bar } from '../../core-ui/bar';
import { Size, ButtonSize } from '../../core-ui/constants/Size';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Toggle } from '../../core-ui/toggle';
import { TextArea } from '../../core-ui/textArea';
import { BarVariant, IBarProps } from '../../core-ui/bar/Bar';
import { useStyle } from '../../shared/hooks/useStyle';
import { Button, ButtonVariant } from '../../core-ui/button';
import { IWithDragHandle } from '../../util/DragUtils';
import {
    isDisabledOverlayStyle,
    headingBarStyle,
    toggleStyle,
    noteWrapperStyle,
    noteTextAreaStyle,
    flex1Style,
    defaultCardStyle,
    defaultBodyBackgroundStyle,
    defaultBodyPaddingStyle,
    subHeadingToolsLayout,
    subHeadingToolsLayoutRight,
    iconStyle,
    objectTypeIdStyle
} from './ObjectWrapperBox.style';
import { Collapsible } from '../../core-ui/collapse';
import { InterpolationWithTheme } from '@emotion/core';
import { Icon } from '../../core-ui/icon/Icon';
import { getSmartObjectTypeName } from '../../util/ObjectDefinitionsUtils';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { useObjectDefinition } from '../../shared/motive/hooks/useScriptEditorInfo';

export interface IObjectWrapperBoxProps extends IWithDragHandle {
    headerRender?: React.ReactNode;
    isEnabled?: boolean;
    onClickIsEnableToggle?: (value: boolean) => void;
    onClickDelete?: () => void;
    onClickCopy?: () => void;
    noteValue?: string | undefined;
    hideBorder?: boolean;
    onNoteChange?: (value: IObjectWrapperBoxProps['noteValue']) => void;
    barVariant?: BarVariant;
    cardStyle?: InterpolationWithTheme<any>;
    bodyBackgroundStyle?: InterpolationWithTheme<any>;
    bodyPaddingStyle?: InterpolationWithTheme<any>;
    deleteButtonIcon?: IconTypes;
    deleteButtonSize?: ButtonSize;
    onBarMouseDownCapture?: IBarProps['onMouseDownCapture'];
    onBarMouseUpCapture?: IBarProps['onMouseUpCapture'];
    object?: IScriptObjectModel;
}
export const ObjectWrapperBox: React.FC<IObjectWrapperBoxProps> = ({
    children,
    headerRender,
    dragHandleProps,
    isEnabled = true,
    onClickIsEnableToggle,
    onClickDelete,
    onClickCopy,
    noteValue,
    onNoteChange,
    cardStyle,
    bodyBackgroundStyle,
    bodyPaddingStyle,
    barVariant = BarVariant.RESOURCEHEADER,
    deleteButtonSize,
    onBarMouseDownCapture,
    onBarMouseUpCapture,
    deleteButtonIcon,
    object
}) => {
    const tokens = useStyle();
    const [showNote, setShowNote] = useState(false);
    const objectDef = useObjectDefinition(object?.type ?? '');

    return (
        <Card css={cardStyle ?? defaultCardStyle(tokens)}>
            <>
                <Bar
                    css={headingBarStyle(tokens)}
                    variant={barVariant}
                    dragHandleProps={dragHandleProps}
                    onMouseDownCapture={onBarMouseDownCapture}
                    onMouseUpCapture={onBarMouseUpCapture}
                >
                    {headerRender}
                    <div css={flex1Style} />

                    {onClickIsEnableToggle && (
                        <Toggle
                            css={toggleStyle(tokens)}
                            size={Size.SMALL}
                            value={isEnabled}
                            onClick={() => onClickIsEnableToggle(!isEnabled)}
                        />
                    )}
                    {!!onClickDelete && (
                        <Button
                            size={deleteButtonSize ?? Size.SMALL}
                            onClick={onClickDelete}
                            icon={deleteButtonIcon ?? IconTypes.DELETE}
                        />
                    )}
                </Bar>

                <div css={bodyBackgroundStyle ?? defaultBodyBackgroundStyle(tokens)}>
                    {object && (
                        <div css={bodyPaddingStyle ?? defaultBodyPaddingStyle(tokens)}>
                            <div css={objectTypeIdStyle(tokens)}>
                                {getSmartObjectTypeName(objectDef)} ({object.id})
                            </div>
                        </div>
                    )}
                    <div css={bodyPaddingStyle ?? defaultBodyPaddingStyle(tokens)}>
                        <div css={subHeadingToolsLayout}>
                            {!!onNoteChange && (
                                <Button onClick={() => setShowNote(!showNote)}>
                                    {/* <LocalizedText textKey={LocalizationKeys.Note} /> */}
                                    <Icon
                                        variant={ButtonVariant.GHOST}
                                        icon={IconTypes.STICKY_NOTE}
                                        iconStyle={iconStyle(tokens)}
                                    />
                                </Button>
                            )}
                            <div css={subHeadingToolsLayoutRight}>
                                {!!onClickCopy && (
                                    <Button onClick={onClickCopy}>
                                        {/* <LocalizedText textKey={LocalizationKeys.Note} /> */}
                                        <Icon
                                            variant={ButtonVariant.GHOST}
                                            icon={IconTypes.COPY}
                                            iconStyle={iconStyle(tokens)}
                                        />
                                    </Button>
                                )}
                            </div>
                        </div>
                        {!!onNoteChange && (
                            <>
                                <Collapsible isShowing={showNote} css={noteWrapperStyle(tokens)}>
                                    <TextArea
                                        css={noteTextAreaStyle(tokens)}
                                        defaultValue={noteValue}
                                        onChange={onNoteChange}
                                    />
                                </Collapsible>
                            </>
                        )}
                        {children}
                    </div>
                    <div css={isDisabledOverlayStyle(isEnabled)} />
                </div>
            </>
        </Card>
    );
};
