import React from 'react';
import { useProjectConfigs } from '../../shared/motive/hooks/useProjectConfigs';
import { IProjectConfig, IProjectConfigurations } from '../../shared/motive/models/ProjectConfig';
import { useScenariosRoute } from '../../routes/scenariosRoute';
import { useCreateProject, ICreateProjectParams } from '../../shared/motive/hooks/useCreateProject/useCreateProject';
import { ICatalog } from '../../shared/motive/models/Catalog';
import {
    IHookProvidedCall,
    IHookProvidedDataSource
} from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import {
    ICreateCatalogParams,
    useCreateCatalog
} from '../../shared/motive/hooks/useCatalogs/useCreateCatalog/useCreateCatalog';

export interface IProjectConfigsProps {
    projectConfigs: IHookProvidedDataSource<IProjectConfigurations>;
    onProjectSelect: (config: IProjectConfig) => void;
    addProjectAction: IHookProvidedCall<ICreateProjectParams, IProjectConfig | undefined>;
    addCatalogAction: IHookProvidedCall<ICreateCatalogParams, ICatalog | undefined>;
}

interface IProjectConfigsContainerProps {
    children: (props: IProjectConfigsProps) => React.ReactNode;
}

export const ProjectConfigsContainer: React.FC<IProjectConfigsContainerProps> = ({ children }): React.ReactElement => {
    const projectConfigs = useProjectConfigs();
    const addProject = useCreateProject();
    const addCatalog = useCreateCatalog();

    const { goTo } = useScenariosRoute();

    const handleConfigClick = (config: IProjectConfig) => {
        goTo({
            pathParams: {
                projectName: config.id
            }
        });
    };

    return (
        <>
            {children({
                projectConfigs: projectConfigs,
                onProjectSelect: handleConfigClick,
                addProjectAction: addProject,
                addCatalogAction: addCatalog
            })}
        </>
    );
};
