import React, { ChangeEvent } from 'react';
import {
    disabledDropdownItemStyle,
    baseDropdownStyle,
    selectOverrideProps,
    instructionsDropdownItemStyle
} from './Dropdown.style';
import { Select, FormLabel, FormHelperText } from '@chakra-ui/core';
import { useStyle } from '../../shared/hooks/useStyle';
import { Size } from '../constants/Size';

export type DropdownSize = Size.MEDIUM | Size.SMALL;
export type DropdownVariant = 'solid' | 'hollow';

interface IDropdownProps {
    className?: string;
    label?: string;
    placeholder?: string;
    value?: string;
    options: IDropdownOption[];
    helper?: string;
    grouped?: boolean;
    onChange?: (changedValue: string) => void;
    instructions?: string;
    size?: DropdownSize;
    variant?: DropdownVariant;
    isDisabled?: boolean;
}

export const Dropdown: React.FC<IDropdownProps> = ({
    label,
    placeholder = '',
    helper,
    onChange,
    options,
    grouped,
    value,
    instructions,
    isDisabled,
    size = Size.SMALL,
    variant = 'solid',
    className,
    ...rest
}): React.ReactElement => {
    const token = useStyle();

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        onChange && onChange(event.target.value);
    };

    if (grouped) {
        const groups: { [key: string]: IDropdownOptionProps[] } = {};

        options.forEach(o => {
            const group = o.group !== undefined ? o.group : '';

            if (!groups[group]) {
                groups[group] = [];
            }

            const props: IDropdownOptionProps = {
                ...o
            };

            groups[group].push(props);
        });

        const groupKeys = Object.keys(groups);

        return (
            <>
                {label && <FormLabel>{label}</FormLabel>}
                <Select
                    {...rest}
                    css={baseDropdownStyle}
                    aria-labelledby={label}
                    placeholder={placeholder}
                    onChange={handleChange}
                    value={value}
                    isDisabled={isDisabled}
                    {...selectOverrideProps(token, size as DropdownSize, variant)}
                    size={'sm'}
                    className={className}
                    rootProps={{ zIndex: 0 }}
                >
                    {groupKeys.map(
                        (group: string, index: number): React.ReactNode => {
                            return (
                                <DropdownOptionGroup
                                    key={index}
                                    options={groups[group]}
                                    label={group}
                                ></DropdownOptionGroup>
                            );
                        }
                    )}
                </Select>
                {helper && <FormHelperText>{helper}</FormHelperText>}
            </>
        );
    } else {
        return (
            <>
                {label && <FormLabel>{label}</FormLabel>}
                <Select
                    {...rest}
                    css={baseDropdownStyle}
                    aria-labelledby={label}
                    placeholder={placeholder}
                    onChange={handleChange}
                    value={value}
                    {...selectOverrideProps(token, size as DropdownSize, variant)}
                    size="sm"
                    className={className}
                    rootProps={{ zIndex: 0 }}
                    isDisabled={isDisabled}
                >
                    {instructions && (
                        <DropdownOption
                            key={'.instructions'}
                            instruction={true}
                            value={undefined}
                            label={instructions}
                        />
                    )}
                    {options?.map(
                        (option: IDropdownOption, index: number): React.ReactNode => {
                            return (
                                <DropdownOption
                                    key={index}
                                    value={option.value}
                                    label={option.label}
                                    disabled={option.disabled ? option.disabled : false}
                                ></DropdownOption>
                            );
                        }
                    )}
                </Select>
                {helper && <FormHelperText>{helper}</FormHelperText>}
            </>
        );
    }
};

export interface IDropdownOption {
    value: string | undefined;
    label: string;
    group?: string;
    disabled?: boolean;
}

export const EMPTY_DROPDOWN_OPTION = { value: undefined, label: '' };

interface IDropdownOptionProps {
    className?: string;
    label?: string;
    value: string | undefined;
    disabled?: boolean;
    instruction?: boolean;
}

interface IDropdownOptionGroupProps {
    label?: string;
    options?: IDropdownOptionProps[];
}

const DropdownOptionGroup: React.FC<IDropdownOptionGroupProps> = ({ label, options }): React.ReactElement => {
    return (
        <optgroup label={label}>
            {options &&
                options.map((o, idx) => {
                    return <DropdownOption key={idx} {...o} />;
                })}
        </optgroup>
    );
};

const DropdownOption: React.FC<IDropdownOptionProps> = ({
    value,
    label,
    className,
    disabled,
    instruction
}): React.ReactElement => {
    return (
        <option
            disabled={disabled}
            className={className}
            value={value}
            label={label}
            css={disabled ? disabledDropdownItemStyle : instruction ? instructionsDropdownItemStyle : undefined}
        >
            {value}
        </option>
    );
};
