import { css } from '@emotion/core';

export const fullLoaderStyle = css`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const loaderWrapperStyle = css`
    margin-top: 32px;
`;

export const fullLoaderChildrenStyle = css`
    height: 100%;
`;
