import { useState, useEffect } from 'react';
import { debounce } from 'lodash-es';
const DEBOUNCE_WAIT = 500;

export const useDebounce = <T>(value: T, wait: number = DEBOUNCE_WAIT) => {
    const [debounceValue, setDebounceValue] = useState(value);

    useEffect(() => {
        const handler = debounce(() => {
            setDebounceValue(value);
        }, wait);
        handler();

        return handler.cancel;
    }, [value, wait]);

    return debounceValue;
};
