import { useCurrentSpace } from '../useCurrentSpace';
import { useScriptEditorInfo } from '../useScriptEditorInfo';
import { IEnumDefinition } from '../../models/EnumDefinition';

interface IEnumDefinitionValues {
    importedEnums: IEnumDefinition[];
    spaceEnums: IEnumDefinition[];
}

export const useEnumDefinitions = (): IEnumDefinitionValues => {
    const [currentSpace] = useCurrentSpace();

    const data = useScriptEditorInfo();

    const importedEnums: IEnumDefinition[] = [];
    const spaceEnums: IEnumDefinition[] = [];

    const typeDefs = Object.entries(data.data.typeDefinitions);
    typeDefs.forEach(([type, def]) => {
        if (def.dataType === 'enum') {
            const enumDef = def as IEnumDefinition;
            const space = type.substring(0, type.lastIndexOf('.'));
            if (space === currentSpace) {
                spaceEnums.push(enumDef);
            } else {
                importedEnums.push(enumDef);
            }
        }
    });

    return { importedEnums, spaceEnums };
};
