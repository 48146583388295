import { IColors } from '../../../shared/contexts/styleContext';
import chroma from 'chroma-js';
import { reduceArrayToObject } from '../../../util/Utils';

export const PALETTES = 'palettes';

const createSpreadObject = (stringArray: string[], defaultIndex: number) => {
    return stringArray.reduce(reduceArrayToObject, { default: stringArray[defaultIndex] });
};

const interpolate = (...rest: string[]) =>
    chroma
        // .scale([...rest])
        .bezier([...rest])
        .scale()
        .mode('lab');

export const getColors = (): IColors => {
    const bgLightStr = '#eff2f5';
    const bgDarkStr = '#171720';

    const bgColorStr = '#2a4858';
    const interactiveColorStr = '#4ebb70';
    const resourceColorStr = '#708090';
    const dynamicsColorStr = '#0ab1b1';
    const conditionColorStr = '#057099';
    const highlightColorStr = '#5C6BC0';

    const spreadLength = 11;
    const spreadMiddleIndex = Math.floor(spreadLength / 2);

    const neutralSpread = interpolate(bgLightStr, bgDarkStr).colors(spreadLength);
    const bgColorSpread = interpolate(bgLightStr, bgColorStr, bgDarkStr).colors(spreadLength);
    const interactiveColorSpread = interpolate(bgLightStr, interactiveColorStr, bgDarkStr).colors(spreadLength);
    const resourceSpread = interpolate(bgLightStr, resourceColorStr, bgDarkStr).colors(spreadLength);
    const dynamicsSpread = interpolate(bgLightStr, dynamicsColorStr, bgDarkStr).colors(spreadLength);
    const conditionSpread = interpolate(bgLightStr, conditionColorStr, bgDarkStr).colors(spreadLength);
    const highlightSpread = interpolate(bgLightStr, highlightColorStr, bgDarkStr).colors(spreadLength);

    const staticColors = {
        none: 'transparent',
        gray: 'gray',
        blue: 'blue',
        green: 'green',
        red: 'red',
        white: 'white',
        pink: 'pink',
        black: 'black'
    };

    return {
        palettes: {
            neutralSpread: createSpreadObject(neutralSpread, spreadMiddleIndex),
            bgColorSpread: createSpreadObject(bgColorSpread, spreadMiddleIndex),
            interactiveColorSpread: createSpreadObject(interactiveColorSpread, spreadMiddleIndex),
            resourceSpread: createSpreadObject(resourceSpread, spreadMiddleIndex),
            dynamicsSpread: createSpreadObject(dynamicsSpread, spreadMiddleIndex),
            conditionSpread: createSpreadObject(conditionSpread, spreadMiddleIndex),
            highlightSpread: createSpreadObject(highlightSpread, spreadMiddleIndex)
        },
        staticColors: staticColors,

        background: {
            default: staticColors.white,
            darker: '#171720',
            dark: '#2f2e41',
            neutral: '#6e6d7a',
            light: neutralSpread[1],
            lighter: neutralSpread[0],
            colorLight: bgColorSpread[2],
            colorLighter: bgColorSpread[1],
            highlight: highlightColorStr
        },
        bar: {
            primary: neutralSpread[10],
            secondary: '#9797a0',
            tertiary: neutralSpread[7],
            resourceBarHeader: '#718096',
            dashboardNavbar: '#6e6d7a',
            conditionBarHeader: '#0293c7ff',
            variableBarHeader: '#0ab1b1',
            catalogBarHeader: '#363f70'
        },
        border: {
            primary: neutralSpread[1],
            secondary: neutralSpread[2],
            tertiary: neutralSpread[3],
            warning: '#e73438',
            highlight: highlightColorStr
        },
        font: {
            primary: neutralSpread[10],
            secondary: neutralSpread[4],
            tertiary: neutralSpread[0],
            warning: '#e73438',
            highlight: highlightColorStr
        },
        interactible: {
            warning: '#e73438',
            primary: '#4ebb70',
            secondary: bgColorSpread[5],
            tertiary: bgColorSpread[2],
            highlight: highlightSpread[7],
            disabled: bgColorSpread[3]
        },
        interactibleBorder: {
            primary: bgColorSpread[5],
            secondary: neutralSpread[3],
            tertiary: interactiveColorSpread[5],
            warning: '#e73438',
            highlight: highlightColorStr
        }
    };
};

export const getResourceColors = (): IColors => {
    const colors = {
        ...getColors()
    };

    return colors;
};

export const getConditionColors = (): IColors => {
    const mainColors = getColors();
    const colors = {
        ...mainColors,

        font: {
            ...mainColors.font,
            primary: 'white',
            secondary: 'white',
            tertiary: 'white'
        },

        background: {
            ...mainColors.background,
            default: 'white',
            darker: '#3b3f48',
            dark: '#6c7078',
            neutral: '#0293c7ff',
            light: '#c4dce7',
            lighter: '#eff2f5',
            conditionItem: '#0293c7ff'
        }
    };

    return colors;
};

export const getDynamicsColors = (): IColors => {
    const mainColors = getColors();
    const colors = {
        ...mainColors,

        font: {
            ...mainColors.font,
            primary: 'white',
            secondary: 'white',
            tertiary: 'white'
        },

        background: {
            ...mainColors.background,
            default: '#4cadac',
            darker: '#6c7078',
            dark: '#266161',
            neutral: '#0ab1b1',
            light: '#bee1e1',
            lighter: '#0293c7ff',
            conditionItem: '#0293c7ff'
        }
    };

    return colors;
};

export const Colors = getColors();
export const ConditionColors = getConditionColors();
export const DynamicsColors = getDynamicsColors();
