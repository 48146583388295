import { IToken } from '../../shared/contexts/styleContext';
import { css } from '@emotion/core';

export const selectOptionStyle = (tokens: IToken, isActive: boolean) => css`
    font-size: ${tokens.fontSizes.large};
    color: ${tokens.colors.font.tertiary};
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 16px;
    margin-bottom: 8px;
    border-radius: 4px;
    ${isActive && `background-color: ${tokens.colors.background.darker};`}
    &:hover {
        background-color: ${tokens.colors.background.darker};
    }
`;

export const optionIconBox = css`
    background-color: green;
    border-radius: 50%;
    padding: 8px;
    height: 18px;
    width: 18px;
    margin-right: 12px;
    display: flex;
    align-items: center;
`;

export const optionIcon = css`
    margin: auto;
`;

export const projectManageWrapperStyle = (tokens: IToken) => css`
    margin: ${tokens.spacings.medium} 0;
    padding: 0 ${tokens.spacings.small};
`;
