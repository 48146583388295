import { IScriptObjectModel } from './ScriptObjectModel';

export enum CompoundConditionOperator {
    AND = 'and',
    OR = 'or'
}

export interface ICompoundCondition extends IScriptObjectModel {
    operator: CompoundConditionOperator;
    conditions: IScriptObjectModel[];
}
