import { ReactElement } from 'react';
import { useStyle } from '../../shared/hooks/useStyle';
import {
    catalogItemLayoutStyle,
    catalogItemHeaderStyle,
    catalogItemThumbStyle,
    catalogItemMainContentStyle,
    iconLayoutStyle
} from './CatalogItemList.style';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import React from 'react';
import { Button } from '../../core-ui/button';

interface ICatalogItemProps {
    onDeleteClicked?: () => void;
    onEditClicked?: (() => void) | undefined;
    thumbnail: (() => React.ReactElement) | undefined;
    allowQuickObjectEdit?: boolean;
    header: () => React.ReactElement;
}

export const CatalogItem: React.FC<ICatalogItemProps> = ({
    onEditClicked,
    onDeleteClicked,
    thumbnail,
    header,
    allowQuickObjectEdit,
    children
}) => {
    const token = useStyle();
    return (
        <div css={catalogItemLayoutStyle(token)}>
            <div css={catalogItemHeaderStyle}>
                {thumbnail && <div css={catalogItemThumbStyle(token)}>{thumbnail()}</div>}
                <div css={catalogItemMainContentStyle}>{header()}</div>
                {onEditClicked && allowQuickObjectEdit && (
                    <div css={iconLayoutStyle}>
                        <Button icon={IconTypes.EDIT} onClick={onEditClicked}></Button>
                        <Button icon={IconTypes.DELETE} onClick={onDeleteClicked}></Button>
                    </div>
                )}
            </div>
            {children}
        </div>
    );
};
