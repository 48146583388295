import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext/interfaces/Token';

export const thumbContainerStyle = css`
    opacity: 0.5;
`;

export const sectionCollectionStyle = (token: IToken) => css`
    grid-gap: ${token.spacings.smaller};
`;

export const sectionGroupStyle = (token: IToken) => css`
    background: ${token.colors.background.lighter};
    padding: 5px;
`;
export const sectionGroupTitleStyle = (token: IToken) => css`
    color: ${token.colors.font.secondary};
    padding-bottom: 5px;
`;

export const isDraggingStyle = (isDragging: boolean) => css`
    ${isDragging && `transform: translate3d(0, -50%, 0);`}
`;

export const containerStyle = ({ spacings }: IToken) => css`
    padding: 0 ${spacings.smallest};
`;

export const displayNoneStyle = css`
    display: none;
`;
export const inputStickyPositionStyle = ({ colors, spacings }: IToken) => css`
    background-color: ${colors.background.dark};
    padding-left: ${spacings.smallest};
    padding-right: 10px;
    padding-bottom: ${spacings.smallest};
`;

export const inputStyle = css`
    margin: unset;
`;

export const frameToolPanelLayoutStyle = css`
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
`;
