import React, { useState } from 'react';
import { IFieldEditorProps } from '../../containers/objectEditor';
import { Text } from '../../core-ui/text';
import { IValueDefinition } from '../../shared/motive/models/TypeDefinition';
import { Dropdown } from '../../core-ui/dropdown';
import { IDropdownOption } from '../../core-ui/dropdown/Dropdown';
import { useEnumTypes, useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { EditModeAware } from '../editableOnFocus/EditModeAware';
import { PRIMITIVE_TYPES_NAME_MAP } from '../../shared/motive/models/ScriptObjectModel';
import { createScriptObject, getSpaceFromTypeName } from '../../util/MotiveUtils';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { ITypeBundle } from '../../shared/motive/models/TypeBundle';
import { getSmartEnumTypeName } from '../../util/ObjectDefinitionsUtils';

export const ValueDefinitionEditor: React.FC<IFieldEditorProps<IValueDefinition>> = ({ value, onChange }) => {
    const objecDefinitions = useObjectDefinitions();

    const valueDef: IValueDefinition = value
        ? { ...value }
        : {
              ...createScriptObject<IValueDefinition>(MotiveTypes.VALUE_DEFINITION, objecDefinitions),
              typeName: '',
              isArray: false,
              isExternalReference: false,
              useIdOnly: false,
              isNullable: false
          };

    const [valueDefType, setValueDefType] = useState<string | undefined>(valueDef.typeName);

    const notSetOpt = {
        label: 'NOT SET',
        value: undefined
    };

    const primitiveOpts: IDropdownOption[] = [notSetOpt];
    PRIMITIVE_TYPES_NAME_MAP.forEach((niceName, key) => {
        primitiveOpts.push({
            label: niceName,
            value: key
        });
    });

    const enumDefs = useEnumTypes();

    const enumBundlesInit: {
        [index: string]: ITypeBundle;
    } = {};

    const enumBundles = enumDefs.reduce((enumBundle, currValue, currIdx, enumDefs) => {
        const _space = getSpaceFromTypeName(currValue.name);

        const spaceEnums: ITypeBundle = enumBundle[_space] ?? {
            bundleName: _space,
            typeReferences: []
        };

        spaceEnums.typeReferences.push({
            name: currValue.name,
            displayName: getSmartEnumTypeName(currValue),
            fullName: currValue.name
        });

        enumBundle[_space] = spaceEnums;

        return enumBundle;
    }, enumBundlesInit);

    const enumOpts: IDropdownOption[] = [notSetOpt];

    Object.values(enumBundles).forEach(bundle => {
        bundle.typeReferences.forEach(r => {
            enumOpts.push({
                label: r.displayName,
                value: r.fullName,
                group: bundle.bundleName
            });
        });
    });

    const [dataTypesToShow, setDataTypeToShow] = useState<string>(
        enumDefs.some(d => d.name === valueDefType) ? 'enum' : 'primitive'
    );

    const dataTypeOpts: IDropdownOption[] = [
        {
            label: 'SELECT TYPE',
            value: undefined
        },
        {
            label: 'Primitive',
            value: 'primitive'
        },
        {
            label: 'Enum',
            value: 'enum'
        }
    ];

    const handleTypeChange = (typeName: string | undefined) => {
        setValueDefType(typeName);

        if (typeName) {
            valueDef.typeName = typeName;

            onChange(valueDef);
        } else {
            onChange(undefined);
        }
    };

    const renderEditor = () => {
        return (
            <div>
                <Dropdown
                    options={dataTypeOpts}
                    value={dataTypesToShow}
                    onChange={(_t: string) => {
                        setDataTypeToShow(_t);
                    }}
                ></Dropdown>
                <Dropdown
                    grouped={dataTypesToShow === 'enum'}
                    options={dataTypesToShow === 'enum' ? enumOpts : primitiveOpts}
                    value={valueDefType}
                    onChange={handleTypeChange}
                ></Dropdown>
            </div>
        );
    };

    const renderReadOnly = () => {
        return <Text>{value}</Text>;
    };

    return <EditModeAware readonlyModeRender={renderReadOnly} editModeRender={renderEditor} />;
};
