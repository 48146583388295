import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const detailedViewGridStyle = css`
    display: grid;
    grid-template-columns: 1000px;
    grid-template-rows: 300px 300px;
    padding: 12px;
`;

export const mediaItemPreviewStyle = css`
    object-fit: contain;
    max-height: 90%;
    margin: auto;
`;

export const detailedViewMetadataWrapper = css`
    display: flex;
    flex-direction: column;
`;

export const infoWrapperStyle = (token: IToken) => css`
    display: flex;
    flex-direction: column;
    margin: 0 0 ${token.spacings.smaller} 0;
`;

export const infoLabelStyle = (token: IToken) => css`
    color: ${token.colors.font.secondary};
    font-size: ${token.fontSizes.medium};
`;

export const infoTextStyle = (token: IToken) => css`
    font-size: ${token.fontSizes.medium};
`;
