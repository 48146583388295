import { ISpacings } from '../../../shared/contexts/styleContext';

export const Spacings: ISpacings = {
    smallest: '4px',
    smaller: '8px',
    small: '16px',
    medium: '24px',
    large: '32px',
    larger: '40px',
    largest: '48px'
};
