import { createCatalogNetworkCall } from '../../../networking/CatalogService';
import { useCurrentSpace } from '../../useCurrentSpace';
import { ICatalog, ICatalogInfo } from '../../../models/Catalog';
import { IScriptObjectModel } from '../../../models/ScriptObjectModel';
import { getSystemName, uniqueMotiveId } from '../../../../../util/MotiveUtils';
import { useCurrentProject } from '../../useCurrentProject';
import { useNetworkCallWithoutDataSource } from '../../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useDispatch } from 'react-redux';

export interface ICreateCatalogParams {
    catalogTitle: string;
    objectType: string;
    importToCurrentProject: boolean;
}
export const useCreateCatalog = (): IHookProvidedCall<ICreateCatalogParams, ICatalogInfo | undefined> => {
    const [currentSpace] = useCurrentSpace();
    const [currentProject] = useCurrentProject();

    const dispatch = useDispatch();

    const { triggerCall, ...rest } = useNetworkCallWithoutDataSource();

    const postCatalog = async (params: ICreateCatalogParams): Promise<ICatalogInfo | undefined> => {
        if (!params.catalogTitle && !params.objectType) {
            throw new Error('Catalog title & object type cannot be empty');
        }

        const catalog: ICatalog<IScriptObjectModel> = {
            id: uniqueMotiveId(),
            title: params.catalogTitle,
            name: getSystemName(params.catalogTitle),
            objectType: params.objectType
        };

        var newCat = await triggerCall(
            createCatalogNetworkCall(
                currentSpace,
                catalog,
                dispatch,
                params.importToCurrentProject ? currentProject : undefined
            )
        );

        return newCat;
    };

    return {
        execute: postCatalog,
        ...rest
    };
};
