import React from 'react';
import { ScriptActionType } from '../../shared/motive/reducers/ScriptEditorReducers';
import { createScriptObject, createObjectReference } from '../../util/MotiveUtils';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { IScriptEvent } from '../../shared/motive/models/IScriptEvent';
import { useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo';
import { useDispatch } from 'react-redux';
import { IObjectInstanceDrag } from '../../components/_common/DragAndDrop/DragModels/DragModels';
import { Drop } from '../../components/_common/DragAndDrop/Drop/Drop';
import { DROPPABLE_ID_FRAME_INSPECTOR_SCRIPT_EVENTS, DRAG_ITEM_TYPE_SCRIPT_EVENT } from '../../constants/DragAndDrop';

const DROPPABLE_ID = DROPPABLE_ID_FRAME_INSPECTOR_SCRIPT_EVENTS;
const DROPPABLE_TYPE = DRAG_ITEM_TYPE_SCRIPT_EVENT;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDroppableScriptEventContainerProps {
    frameId: string;
}

export const DroppableScriptEventContainer: React.FC<IDroppableScriptEventContainerProps> = ({ children, frameId }) => {
    const scriptDispatch = useDispatch();
    const objectDefinitions = useObjectDefinitions();

    const onScriptEventDrop = (obj: IObjectInstanceDrag) => {
        const resource = createScriptObject<IScriptEvent>(MotiveTypes.SCRIPT_EVENT, objectDefinitions);

        resource.eventReference = createObjectReference(MotiveTypes.SCRIPT_EVENT_DEFINITION, obj.objectId);

        scriptDispatch({
            type: ScriptActionType.RESOURCE_ADD,
            resource,
            destinationIndex: -1,
            destinationFrameId: frameId
        });
    };

    return (
        <Drop dropId={DROPPABLE_ID} allowedDragList={[{ dragType: DROPPABLE_TYPE, onDragEnd: onScriptEventDrop }]}>
            {children}
        </Drop>
    );
};
