import React from 'react';
import { useEnumDefinitions } from '../../shared/motive/hooks/useEnumDefinitions/useEnumDefinitions';
import { EnumDefinitions } from '../../components/enumDefinitions';
import {
    useDeleteEnumDefinition,
    IDeleteEnumDefinitionParams
} from '../../shared/motive/hooks/useDeleteEnumDefinition/useDeleteEnumDefinition';
import { useCreateEnumDefinition } from '../../shared/motive/hooks/useCreateEnumDefinition/useCreateEnumDefinition';
import {
    IUpdateEnumDefinitionParams,
    useUpdateEnumDefinition
} from '../../shared/motive/hooks/useUpdateEnumDefinition/useUpdateEnumDefinition';
import {
    useCreateEnumDefinitionItem,
    IUseCreateEnumDefinitionItemParams
} from '../../shared/motive/hooks/useCreateEnumDefinitionItem/useCreateEnumDefinitionItem';
import { IEnumDefinitionItem } from '../../shared/motive/models/EnumDefinition';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';

export const EnumDefinitionsContainer: React.FC = () => {
    const { spaceEnums, importedEnums } = useEnumDefinitions();
    const handleCreateEnum = useCreateEnumDefinition();

    return (
        <>
            <EnumDefinitions
                spaceEnums={spaceEnums}
                importedEnums={importedEnums}
                onCreateEnum={handleCreateEnum}
            ></EnumDefinitions>
        </>
    );
};

export interface IEnumDefinitionItemProps {
    createEnumDefinitionItem: IHookProvidedCall<IUseCreateEnumDefinitionItemParams, IEnumDefinitionItem | undefined>;
    updateEnumDefinition: IHookProvidedCall<IUpdateEnumDefinitionParams>;
    deleteEnumDefinition: IHookProvidedCall<IDeleteEnumDefinitionParams>;
}

interface IEnumDefinitionItemContainerProps {
    children: (props: IEnumDefinitionItemProps) => React.ReactNode;
}

export const EnumDefinitionItemContainer: React.FC<IEnumDefinitionItemContainerProps> = ({ children }) => {
    const handleDeleteEnum = useDeleteEnumDefinition();
    const handleUpdateEnum = useUpdateEnumDefinition();
    const handleCreateEnumItem = useCreateEnumDefinitionItem();
    return (
        <>
            {children({
                deleteEnumDefinition: handleDeleteEnum,
                updateEnumDefinition: handleUpdateEnum,
                createEnumDefinitionItem: handleCreateEnumItem
            })}
        </>
    );
};
