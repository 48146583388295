/* eslint-disable @typescript-eslint/no-empty-interface */
import { IScriptObjectModel } from './ScriptObjectModel';
import { IObjectReference } from './ObjectReference';
import { IValueDefinition } from './TypeDefinition';

export const FRAME_PRE_CONDITION = 'preCondition';
export const FRAME_SELECTOR_TREE = 'selectorTree';

export enum SelectorBranchProcessingOrder {
    Sequence = 'sequence',
    Priority = 'priority',
    Random = 'random'
}

export interface IVariableSelector extends IScriptObjectModel {
    variableReference?: IObjectReference;
}

export interface ISelectorNode extends IScriptObjectModel {
    name: string;
    priority?: number;
    weight?: number;
    condition?: ICondition;
    selectors: IVariableSelector[] | undefined;
    branches: ISelectorBranch[] | undefined;
}

export interface ISelectorBranch {
    name: string;
    processingOrder?: {
        name: SelectorBranchProcessingOrder;
    };
    nodes?: ISelectorNode[] | undefined;
}

export interface ISelectorTree {
    defaultSelectors?: IVariableSelector[];
    branches: ISelectorBranch[];
}

export interface IFrameDefinedVariable {
    variableId: string;
    isRequired: boolean;
}

export interface IFrame extends IScriptObjectModel {
    name: string;
    notes?: string | null;
    resources?: IResourceWrapper[] | null;
    [FRAME_PRE_CONDITION]?: ICondition | null;
    postCondition?: ICondition | null;
    isExclusive: boolean;
    isEnabled: boolean;
    isLive: boolean;
    resetOnClose: boolean;
    subFrames?: IFrame[] | null;
    selectorTree?: ISelectorTree | null;
    objectFieldVariableBindings?: {
        [key: string]: { [key: string]: string };
    } | null;
    definedVariables?: IFrameDefinedVariable[] | null;
}

export interface IResource extends IScriptObjectModel {}

export interface ICondition extends IScriptObjectModel {}

export interface ISelector extends IScriptObjectModel {}

export interface IResourceEvent extends IScriptObjectModel {
    name: string;
}

export interface IResourceWrapper {
    label?: string | null;
    notes?: string | null;
    isEnabled: boolean;
    resource: IScriptObjectModel;
    events?: IResourceEvent[];
}

export const CONDITION_WRAPPER_LABEL = 'label';
export const CONDITION_WRAPPER_NOTES = 'notes';
export const CONDITION_WRAPPER_IS_ENABLED = 'isEnabled';
export const CONDITION_WRAPPER_CONDITION = 'condition';
export interface IConditionWrapper extends IScriptObjectModel {
    label?: string | undefined;
    notes?: string | undefined;
    isEnabled?: boolean;
    condition: IScriptObjectModel | undefined | null;
}

export interface IScriptVariable extends IScriptObjectModel {
    name: string;
    valueDefinition: IValueDefinition;
    isGlobal: boolean;
    isNetwork: boolean;
    isInput: boolean;
    isOutput: boolean;
}

export interface IScriptEventDefinition extends IScriptObjectModel {
    name: string;
    isGlobal: boolean;
    isNetwork: boolean;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IScript extends IScriptObjectModel {
    variables?: IScriptVariable[];
    events?: IScriptEventDefinition[];
    rootFrame: IFrame;
    name: string;
    path?: string;
    creationTime?: string;
    lastModifiedTime?: string;
}

export interface IScriptInfo extends Omit<IScript, 'rootFrame'> {}
