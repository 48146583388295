import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const defaultVariablesStyle = (token: IToken) => css`
    border: 1px solid ${token.colors.border.tertiary};
    border-left: 5px solid ${token.dynamicsColors.background.neutral};
    border-radius: ${token.borderRadii.small};
    background-color: ${token.colors.background.lighter};
    padding: 6px 12px;
    margin-bottom: ${token.spacings.smaller};
    margin-right: ${token.spacings.smallest};
    position: relative;
`;

export const flexStyleRowRightAligned = css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const variableHeadingStyle = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
`;

export const animateVariableBgStyle = (token: IToken) => css`
    cursor: pointer;
    background: linear-gradient(to left, white 50%, ${token.dynamicsColors.background.neutral} 50%) right;
    background-size: 200%;
    transition: 0.3s ease-out;
    &:hover {
        background-position: left;
    }
`;
