import React, { useEffect } from 'react';
import { shouldShowField, IObjectEditorRendererProps, IObjectEditorProps } from '../../containers/objectEditor';
import { useObjectDefinition, useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo';
import { IScript, IScriptVariable } from '../../shared/motive/models/Script';
import { createObjectReferenceFromObject, createScriptObject } from '../../util/MotiveUtils';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useCatalogItem } from '../../shared/motive/hooks/useCatalogs/useCatalogItem/useCatalogItem';
import { IScriptLauncher } from '../../shared/motive/models/ScriptLauncher';
import { IVariableSetting } from '../../shared/motive/models/IVariableSetting';
import { IFieldDefinition } from '../../shared/motive/models/TypeDefinition';
import { IVariableBinding } from '../../shared/motive/models/IVariableBinding';
import { IObjectReference } from '../../shared/motive/models/ObjectReference';
import { fieldHasVariable } from '../../util/ScriptDynamicsUtil';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IScriptLauncherEditorProps extends IObjectEditorRendererProps<IScriptLauncher> {}

export const ScriptLauncherEditor: React.FC<IScriptLauncherEditorProps> = (props): React.ReactElement => {
    const objectDefinition = useObjectDefinition(props.type);
    const objectDefinitions = useObjectDefinitions();
    const value = props.value;
    const launchScript = useCatalogItem<IScript>(value?.scriptReference?.objectId);

    const inputVars = launchScript?.variables?.filter(v => v.isInput);
    const outputVars = launchScript?.variables?.filter(v => v.isOutput);

    const scriptRefDef = objectDefinition.fieldDefinitions['scriptReference'];
    const inputsDef = objectDefinition.fieldDefinitions['inputVariableSettings'];
    const outputsDef = objectDefinition.fieldDefinitions['outputVariableBindings'];

    // Filter out any settings that do not have a variable or point to a variable that is not
    // marked as an input. These will be committed on save.
    const filteredSettings = value?.inputVariableSettings?.filter(
        s => s.variableReference && inputVars?.some(v => v.id === s.variableReference.objectId)
    );

    const createSettingObject = (variable: IScriptVariable) => {
        const setting = createScriptObject<IVariableSetting>(MotiveTypes.VARIABLE_SETTING, objectDefinitions);

        setting.setting = {
            valueDefinition: variable.valueDefinition,
            value: undefined
        };

        setting.variableReference = createObjectReferenceFromObject(variable);

        return setting;
    };

    useEffect(() => {
        const launcherValue = props.value as IScriptLauncher;
        if (!launchScript && launcherValue && launcherValue.inputVariableSettings) {
            props.onChange(inputsDef.name, undefined);
        } else {
            const settings: IVariableSetting[] = filteredSettings ?? [];

            let flag = false;

            inputVars?.forEach(v => {
                const currSetting = filteredSettings?.find(s => s.variableReference?.objectId === v.id);

                if (!currSetting) {
                    const varSetting = createSettingObject(v);

                    settings.push({
                        ...varSetting
                    });

                    flag = true;
                }
            });

            if (flag) {
                props.onChange(inputsDef.name, settings);
            }
        }
    }, [launchScript, filteredSettings]);

    const createBindingObject = (variable: IScriptVariable) => {
        const setting = createScriptObject<IVariableBinding>(MotiveTypes.VARIABLE_BINDING, objectDefinitions);

        setting.callerVariableReference = createObjectReferenceFromObject(variable);

        return setting;
    };

    const renderSettings = (variables: IScriptVariable[], settingsDef: IFieldDefinition, isOutput: boolean) => {
        //const settings: IVariableSetting[] = [];

        return variables?.map(v => {
            // Look for var in launcher
            const currSetting = filteredSettings?.find(s => s.variableReference?.objectId === v.id);

            const varSetting: IVariableSetting = currSetting ?? createSettingObject(v);

            //settings.push(setting);

            const fieldDef: IFieldDefinition = {
                ...v.valueDefinition,
                isOutput: isOutput,
                typeName: 'dynamic',
                isArray: false,
                name: 'setting',
                editorInfo: {
                    label: v.name,
                    helpText: '',
                    index: 0,
                    isAdvanced: false,
                    useTextArea: false
                }
            };

            var objProps: IObjectEditorProps = {
                ...props,
                value: varSetting,
                showFields: undefined,
                onChange: (_, newValue) => {
                    const settings =
                        filteredSettings?.map(s => {
                            if (s.variableReference.objectId === v.id) {
                                const newSetting: IVariableSetting = {
                                    ...s,
                                    setting: {
                                        valueDefinition: v.valueDefinition,
                                        value: newValue
                                    }
                                };

                                return newSetting;
                            }

                            return s;
                        }) ?? [];

                    if (!settings.some(s => s.variableReference.objectId === v.id)) {
                        settings.push({
                            ...varSetting,
                            setting: {
                                valueDefinition: varSetting.setting.valueDefinition,
                                value: newValue
                            }
                        });
                    }

                    props.onChange(settingsDef.name, settings);
                }
            };

            return props.renderField(objProps, fieldDef, varSetting.setting);
        });
    };

    const renderOutputs = (variables: IScriptVariable[]) => {
        const bindings: IVariableBinding[] = [];

        return variables?.map(v => {
            // Look for var in launcher
            const currBinding = value?.outputVariableBindings?.find(s => s.callerVariableReference?.objectId === v.id);

            const binding: IVariableBinding = currBinding ? { ...currBinding } : createBindingObject(v);

            bindings.push(binding);

            const fieldDef: IFieldDefinition = {
                ...v.valueDefinition,
                isOutput: true,
                isArray: false,
                name: 'calleeVariableReference',
                editorInfo: {
                    label: v.name,
                    helpText: '',
                    index: 0,
                    isAdvanced: false,
                    useTextArea: false
                }
            };

            var objProps: IObjectEditorProps = {
                ...props,
                showFields: undefined,
                onChange: (_, newSetting) => {
                    binding.calleeVariableReference = newSetting as IObjectReference;

                    props.onChange(outputsDef.name, bindings);
                }
            };

            return props.renderField(objProps, fieldDef, binding.calleeVariableReference);
        });
    };

    const objProps = props as IObjectEditorRendererProps;

    return (
        <>
            {props.renderField(objProps, scriptRefDef, value?.scriptReference)}
            {inputVars &&
                shouldShowField(inputsDef, true, props.showFields) &&
                renderSettings(inputVars, inputsDef, false)}
            {outputVars && shouldShowField(outputsDef, true, props.showFields) && renderOutputs(outputVars)}
        </>
    );
};
