import React, { useState } from 'react';
import { Size } from '../constants/Size';
import { InputField } from '../inputField/InputField';
import { datePickerWrapperStyle } from './DatePicker.style';
import Datetime from 'react-datetime';
import * as moment from 'moment';
import './react-datetime.css';
import { EditorCultureCode } from '../../constants/EditorCultureCode';

import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/en-ca';
import { Button } from '../button';

export interface IDatePickerProps {
    className?: string;
    value: string;
    onDateChange: (date: string) => void;
    locale?: EditorCultureCode;
}

export const DatePicker: React.FC<IDatePickerProps> = ({
    className,
    value,
    onDateChange,
    locale = 'en-ca'
}): React.ReactElement => {
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

    return (
        <div className={className}>
            <InputField value={value} size={Size.MEDIUM} onFocus={() => setShowDatePicker(true)} />
            {showDatePicker && (
                <div css={datePickerWrapperStyle}>
                    <Button onClick={() => setShowDatePicker(false)}>Hide</Button>
                    <Datetime
                        css={datePickerWrapperStyle}
                        viewDate={value}
                        dateFormat={'YYYY-MM-DD'}
                        timeFormat={'HH:mm:ss'}
                        onChange={date => {
                            onDateChange((date as moment.Moment)?.format('YYYY-MM-DDTHH:mm:ss').toString() as string);
                        }}
                        input={false}
                        locale={locale}
                    />
                </div>
            )}
        </div>
    );
};
