// istanbul ignore file MOTIVE_EXCEPTION
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, memo, useMemo } from 'react';
import {
    ScriptActionType,
    IScriptFrameAction,
    IResourceWrapperAction
} from '../../shared/motive/reducers/ScriptEditorReducers';
import { ResourceWrapper } from '../../components/resourceWrapper/ResourceWrapper';
import { useScriptEditorInfo, useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo';
import { IResourceWrapper, IScript, IFrame } from '../../shared/motive/models/Script';
import { copyResource } from '../../util/ScriptUtils';
import { useChooseScriptScopeState, useChooseScriptScopeDispatch } from '../../hooks/useChooseScriptScope';
import { useHandleUpdateResource } from '../../hooks/useHandleUpdateResource';
import { getResourceDisplayName } from '../../util/ObjectDefinitionsUtils';
import { useTypeDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { EditableOnFocus } from '../../components/editableOnFocus/EditableOnFocus';
import { useCatalogItemResolver } from '../../shared/motive/hooks/useCatalogs/useCatalogItem/useCatalogItem';
import { useCurrentLanguageSettings } from '../../shared/motive/stores/editorLocale/EditorLocale';
import { useDispatch, useSelector } from 'react-redux';
import { SelectScriptEditorSelectedFrameId } from '../../redux/spaceKeyed/scriptEditor/ScriptEditorSelectors';
import { useCreateObjectEventLinkFrame } from '../../shared/motive/hooks/useCreateObjectEventLinkFrame/useCreateObjectEventLinkFrame';
import { IEnumItemReference } from '../../shared/motive/models/TypeDefinition';

export interface IResourceWrapperProps {
    resourceWrapper: IResourceWrapper;
    script: IScript;
    frame: IFrame;
    index: number;
    dragHandleProps: DraggableProvidedDragHandleProps | null;
    onClickSetEventId: (eventId: string) => void;
}

export const ResourceWrapperContainer: React.FC<IResourceWrapperProps> = memo(function ResourceWrapperContainer(
    props: IResourceWrapperProps
): React.ReactElement {
    const scriptDispatch = useDispatch();
    const selectedFrameId = useSelector(SelectScriptEditorSelectedFrameId);
    const [isAdvancedView, setAdvancedView] = useState<boolean>(false);
    const [isReadOnlyView, setReadOnlyView] = useState<boolean>(true);
    const [noteState, setNoteState] = useState<boolean>(!!props.resourceWrapper.notes);
    const {
        data: { typeDefinitions }
    } = useScriptEditorInfo();
    const typeDefs = useTypeDefinitions();
    const language = useCurrentLanguageSettings();
    const { state, chooseTypePrettified } = useChooseScriptScopeState();
    const choseScriptScopeDispatch = useChooseScriptScopeDispatch();
    const handleUpdateResource = useHandleUpdateResource(props);
    const catalogItemResolver = useCatalogItemResolver();
    const createEventLink = useCreateObjectEventLinkFrame();

    const handleToggleAdvanced = (): void => setAdvancedView(!isAdvancedView);
    const handleToggleReadOnly = (): void => setReadOnlyView(!isReadOnlyView);

    const displayName = useMemo(() => {
        return getResourceDisplayName(props.resourceWrapper, typeDefs, language, catalogItemResolver, props.script);
    }, [props.resourceWrapper, catalogItemResolver, language, props.script, typeDefs]);

    const handleClickResource =
        state !== 'choosing' || chooseTypePrettified !== 'Resource'
            ? undefined
            : () => {
                  choseScriptScopeDispatch({
                      actionType: 'choose',
                      chosenValue: props.resourceWrapper.resource
                  });
              };

    const handleCreateNote = (): void => {
        setNoteState(true);
    };

    const handleUpdateNote = (value?: string): void => {
        const updatedResource = { ...props.resourceWrapper, notes: value };

        scriptDispatch({
            type: ScriptActionType.RESOURCE_UPDATE,
            resourceWrapper: updatedResource,
            targetFrameId: props.frame.id
        });
    };

    const handleDeleteNote = (): void => {
        handleUpdateNote();
        setNoteState(false);
    };

    const handleUpdateLabel = (value?: string): void => {
        const updatedResource = { ...props.resourceWrapper, label: value };

        scriptDispatch({
            type: ScriptActionType.RESOURCE_UPDATE,
            resourceWrapper: updatedResource,
            targetFrameId: props.frame.id
        });
    };

    const handleEnabledToggle = (): void => {
        const updatedResource = { ...props.resourceWrapper, isEnabled: !props.resourceWrapper.isEnabled };

        scriptDispatch({
            type: ScriptActionType.RESOURCE_UPDATE,
            resourceWrapper: updatedResource,
            targetFrameId: props.frame.id
        });
    };

    const handleRemove = (): void => {
        scriptDispatch({
            type: ScriptActionType.RESOURCE_REMOVE,
            resourceId: props.resourceWrapper.resource.id
        });
    };

    const handleCopy = (): void => {
        const newWrapper = copyResource(props.resourceWrapper, typeDefinitions);

        const action: IResourceWrapperAction = {
            type: ScriptActionType.RESOURCE_WRAPPER_ADD,
            targetFrameId: selectedFrameId,
            resourceWrapper: newWrapper
        };

        scriptDispatch(action);
    };

    const handleEventLink = (event: IEnumItemReference): void => {
        if (event) {
            const action: IScriptFrameAction = {
                type: ScriptActionType.FRAME_ADD,
                targetFrameId: selectedFrameId,
                frame: createEventLink(`On ${displayName} ${event?.name}`, props.resourceWrapper.resource, event)
            };

            scriptDispatch(action);
        }
    };

    const resourceDefinition = typeDefinitions[props.resourceWrapper.resource.type];

    return (
        <>
            <EditableOnFocus>
                <ResourceWrapper
                    script={props.script}
                    frame={props.frame}
                    resourceWrapper={props.resourceWrapper}
                    onUpdateResource={handleUpdateResource}
                    label={displayName}
                    placeholder={resourceDefinition && resourceDefinition.title ? resourceDefinition.title : undefined}
                    note={props.resourceWrapper.notes !== null ? props.resourceWrapper.notes : ''}
                    isAdvanced={isAdvancedView}
                    isReadOnly={isReadOnlyView}
                    isEnabled={props.resourceWrapper.isEnabled}
                    hasNote={noteState}
                    onToggleEnabled={handleEnabledToggle}
                    onToggleReadOnly={handleToggleReadOnly}
                    onToggleAdvanced={handleToggleAdvanced}
                    onCreateNote={handleCreateNote}
                    onUpdateNote={handleUpdateNote}
                    onDeleteNote={handleDeleteNote}
                    onUpdateLabel={handleUpdateLabel}
                    onDelete={handleRemove}
                    onCopy={handleCopy}
                    onEventLink={handleEventLink}
                    onClick={handleClickResource}
                    dragHandleProps={props.dragHandleProps}
                    onClickSetEventId={props.onClickSetEventId}
                ></ResourceWrapper>
            </EditableOnFocus>
        </>
    );
});
