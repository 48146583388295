/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray } from 'lodash-es';
import { useEffect } from 'react';
import { IHookProvidedDataSource } from '../useNetworkCallForDataSource/IHookProvidedDataSource';

export const useLoadDataOnMount = (
    ...dataSources: (IHookProvidedDataSource<any> | IHookProvidedDataSource<any>[])[]
) => {
    const loadAllSetsOfData = () => {
        dataSources.forEach(async ds => {
            if (isArray(ds)) {
                await Promise.all(ds.map(source => source.load()));
                return;
            }

            await ds.load();
        });
    };

    useEffect(() => {
        const tokens: (() => void)[] = [];

        dataSources.forEach((dataSource: IHookProvidedDataSource | IHookProvidedDataSource[]) => {
            if (isArray(dataSource)) {
                tokens.concat(dataSource.map(ds => ds.cancel));
                return;
            }

            tokens.push(dataSource.cancel);
        });
        loadAllSetsOfData();
        return () => tokens.forEach(t => t());
    }, []);
};
