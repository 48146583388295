import React, { useEffect } from 'react';
import { Button } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Size } from '../../core-ui/constants/Size';
import { useResizeObserver } from '../../shared/hooks/useResizeObserver';
import { SerializedStyles } from '@emotion/core';
import {
    dynamicBaseButtonStyle,
    dynamicIconStyle,
    flexGrowStyle,
    leftColChildrenParentStyle,
    leftColRowFlexParent,
    leftColWrapperStyle,
    plusButtonContainer,
    rowStyle
} from './DynamicRowBase.style';

export const LINE_WIDTH = 50;
export const LEFT_IDENTIFIER_SIZE = 30;
export const BRANCH_ROW_HEIGHT = 70;
export const NODE_ROW_HEIGHT = BRANCH_ROW_HEIGHT;
export const MARGIN_OFFSET = 24;

export interface ILeftColProps {
    children: React.ReactNode;
    connectorNode?: React.ReactNode;
    onWidthChange?: (width: number) => void;
    onSetNode?: (setNode: HTMLDivElement | null) => void;
}

const LeftColumn: React.FC<ILeftColProps> = ({ children, connectorNode, onSetNode }) => {
    return (
        <div ref={onSetNode} css={leftColRowFlexParent}>
            <div css={leftColChildrenParentStyle}>{children}</div>
            <div>{connectorNode}</div>
        </div>
    );
};

const LeftColumnReportWidth: React.FC<ILeftColProps> = ({ onWidthChange, ...restProps }) => {
    const [node, setNode] = useResizeObserver(150);
    const width = node?.contentRect.width ?? 0;

    useEffect(() => {
        if (onWidthChange) {
            onWidthChange(width);
        }
    }, [width, onWidthChange]);

    return <LeftColumn {...restProps} onSetNode={setNode} />;
};

interface IDynamicRowBaseProps {
    leftColProps: ILeftColProps;
    className?: string;
    onClick?: () => void;
    rowHeight: number;
    onClickPlus?: () => void;
    buttonStyle?: SerializedStyles;
    lineStyle?: SerializedStyles;
    offset: number;
}

export const DynamicRowBase: React.FC<IDynamicRowBaseProps> = ({
    leftColProps,
    children,
    className,
    onClick,
    rowHeight,
    onClickPlus,
    buttonStyle,
    lineStyle,
    offset
}) => {
    return (
        <div css={rowStyle(rowHeight)} className={className}>
            <div onClick={onClick}>
                <div css={leftColWrapperStyle(offset * MARGIN_OFFSET)}>
                    {!leftColProps.onWidthChange ? (
                        <LeftColumn {...leftColProps} />
                    ) : (
                        <LeftColumnReportWidth {...leftColProps} />
                    )}
                    <div css={flexGrowStyle}>{children}</div>
                </div>
            </div>
            <div css={plusButtonContainer}>
                <svg height={rowHeight} width={LINE_WIDTH}>
                    <line
                        x1={0}
                        y1={rowHeight / 2}
                        x2="100%"
                        y2={rowHeight / 2}
                        strokeDasharray="4 1"
                        css={lineStyle}
                    ></line>
                </svg>
                <Button
                    css={[dynamicBaseButtonStyle, buttonStyle]}
                    icon={IconTypes.PLUS}
                    size={Size.SMALL}
                    iconStyle={dynamicIconStyle}
                    onClick={onClickPlus}
                />
            </div>
        </div>
    );
};
