import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import {
    buttonWrapperStyle,
    iconStyle,
    previousButtonStyle,
    formSpacing,
    loadingWrapperStyle
} from './CreateAgent.style';
import { Button, ButtonVariant } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Size } from '../../core-ui/constants/Size';
import { errorHelperTextStyle, FormControl } from '../../core-ui/formControl';
import { Heading } from '../../core-ui/heading';
import { Icon } from '../../core-ui/icon';
import { InputField } from '../../core-ui/inputField';
import { Text } from '../../core-ui/text';
import { useStyle } from '../../shared/hooks/useStyle';
import { providerIconStyle, providerIconWrapperStyle } from './CreateAgent.style';
import { IWatsonAssistant } from '../../shared/motive/models/WatsonAssistant';
import { IDialogflowAgent } from '../../shared/motive/models/DialogflowAgent';
import { uniqueMotiveId } from '../../util/MotiveUtils';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { ConversationalAgent } from '../../shared/motive/models/ConversationalAgent';
import { IWatsonServiceConfiguration } from '../../shared/motive/models/WatsonServiceConfiguration';
import { useConvoAIAgentManagementRoute } from '../../routes/convoAIAgentRoute/ConvoAIAgentRoute';
import { Divider } from '../../core-ui/divider';
import { WatsonConfigurationEditor } from '../watsonConfigurationEditor';
import { Loading } from '../../core-ui/loading';

interface ICreateAgentProps {
    onCreateAgent?: (props: ConversationalAgent) => Promise<void>;
    onCreateWatsonConfiguration?: (config: IWatsonServiceConfiguration) => Promise<void>;
    onUpdateWatsonConfiguration?: (config: IWatsonServiceConfiguration) => Promise<void>;
    watsonConfigurations: IWatsonServiceConfiguration[];
}

interface IAgentCreationWizardWrapperProps {
    onCreateAgent?: (props: ConversationalAgent) => Promise<void>;
    providerType: string;
    onGoToProviderSelection: () => void;
    watsonConfigurations: IWatsonServiceConfiguration[];
    onCreateWatsonConfiguration?: (config: IWatsonServiceConfiguration) => Promise<void>;
    onUpdateWatsonConfiguration?: (config: IWatsonServiceConfiguration) => Promise<void>;
}

interface IWatsonAssistantWizardProps {
    watsonConfigurations: IWatsonServiceConfiguration[];
    onCreateWatsonConfiguration?: (config: IWatsonServiceConfiguration) => Promise<void>;
    onUpdateWatsonConfiguration?: (config: IWatsonServiceConfiguration) => Promise<void>;
    onCreateAgent: (agent: ConversationalAgent) => Promise<void>;
    onReset: () => void;
}

const CREATE_WATSON_SERVICE_CONFIGURATION = 'newWatsonConfig';

const addDialogflowAgentInfoRender: React.FC<FormikProps<IDialogflowAgent>> = props => {
    const agentNameId = 'agentName';
    const agentDescriptionId = 'description';

    return (
        <Form>
            <Heading size={Size.MEDIUM}>Agent Info</Heading>
            <Divider />
            <FormControl label="Agent Name" fieldId={agentNameId} required={true}>
                <Field
                    required={true}
                    id={agentNameId}
                    name={agentNameId}
                    type="text"
                    as={InputField}
                    useNativeOnChange={true}
                    placeholder="Name"
                />
            </FormControl>
            <FormControl label="Agent Description" fieldId={agentDescriptionId} required={true}>
                <Field
                    required={true}
                    id={agentDescriptionId}
                    name={agentDescriptionId}
                    type="text"
                    as={InputField}
                    useNativeOnChange={true}
                    placeholder="Description"
                />
            </FormControl>
            <div css={buttonWrapperStyle}>
                <Button type="reset" variant={ButtonVariant.GHOST} onClick={() => props.handleReset()}>
                    Previous
                </Button>
                <Button type="submit" variant={ButtonVariant.SOLID} onClick={() => props.handleSubmit()}>
                    Create Agent
                </Button>
            </div>
        </Form>
    );
};

const addWatsonAgentInfoRender: React.FC<FormikProps<IWatsonAssistant>> = props => {
    const agentNameId = 'name';
    const agentAssistantId = 'assistantId';

    return (
        <Form>
            <Heading size={Size.MEDIUM}>{`(2/2) Link Watson Assistant`}</Heading>
            <Divider />
            <Text size={Size.LARGER}>
                To link a Watson Assistant, you must give your agent a name and enter a Watson Assistant Id.
            </Text>
            <div css={formSpacing}>
                <FormControl
                    label="Agent Name"
                    fieldId={agentNameId}
                    required={true}
                    helperText={props.errors.name}
                    helperTextStyle={errorHelperTextStyle()}
                >
                    <Field
                        required={true}
                        id={agentNameId}
                        name={agentNameId}
                        type="text"
                        as={InputField}
                        useNativeOnChange={true}
                        placeholder="Name"
                        isInvalid={props.errors.name}
                    />
                </FormControl>
                <FormControl
                    label="Assistant ID"
                    fieldId={agentAssistantId}
                    required={true}
                    helperText={props.errors.assistantId}
                    helperTextStyle={errorHelperTextStyle()}
                >
                    <Field
                        required={true}
                        id={agentAssistantId}
                        name={agentAssistantId}
                        type="text"
                        as={InputField}
                        useNativeOnChange={true}
                        placeholder="Assistant ID"
                        isInvalid={props.errors.assistantId}
                    />
                </FormControl>
            </div>
            <div css={buttonWrapperStyle}>
                <Button type="reset" variant={ButtonVariant.GHOST} onClick={() => props.handleReset()}>
                    Previous
                </Button>
                <Button
                    disabled={!!props.errors.assistantId || !!props.errors.name}
                    type="submit"
                    variant={ButtonVariant.SOLID}
                    onClick={() => props.handleSubmit()}
                >
                    Link Watson Assistant
                </Button>
            </div>
        </Form>
    );
};

export const WatsonAssistantWizard: React.FC<IWatsonAssistantWizardProps> = props => {
    const [hoveredService, setHoveredService] = useState<string | undefined>(
        props.watsonConfigurations[0]?.id ?? undefined
    );

    const [selectedService, setSelectedService] = useState<string | undefined>(undefined);

    const handleResetServiceSelection = () => {
        setSelectedService(undefined);
    };

    const handleHoverService = (str?: string) => {
        setHoveredService(str);
    };

    if (selectedService) {
        const initValues = {
            id: uniqueMotiveId(),
            type: MotiveTypes.WATSON_ASSISTANT,
            name: '',
            serviceReference: { objectId: selectedService, objectType: MotiveTypes.WATSON_SERVICE_CONFIGURATION },
            assistantId: ''
        };

        const validate = (values: IWatsonAssistant) => {
            const errors: any = {};

            if (!values.name) {
                errors.name = 'Required';
            }

            if (!values.assistantId) {
                errors.assistantId = 'Required';
            }

            return errors;
        };

        return (
            <Formik
                validate={validate}
                initialValues={initValues}
                onSubmit={props.onCreateAgent}
                onReset={handleResetServiceSelection}
                validateOnBlur={false}
            >
                {addWatsonAgentInfoRender}
            </Formik>
        );
    }

    return (
        <>
            <Heading size={Size.MEDIUM}>{`(1/2) Link Watson Service Configuration`}</Heading>
            <Divider />
            <Text size={Size.LARGER}>
                Before linking a Watson Assistant, you must create or select a Watson Service Configuration for this
                project.
            </Text>
            <WatsonConfigurationEditor
                watsonConfigurations={props.watsonConfigurations}
                selectedWatsonConfigurationId={hoveredService}
                onSelectWatsonConfiguration={handleHoverService}
                onCreateWatsonConfiguration={props.onCreateWatsonConfiguration}
                onUpdateWatsonConfiguration={props.onUpdateWatsonConfiguration}
            />
            <div css={buttonWrapperStyle}>
                <Button variant={ButtonVariant.GHOST} onClick={props.onReset}>
                    Previous
                </Button>
                <Button
                    disabled={!hoveredService || hoveredService === CREATE_WATSON_SERVICE_CONFIGURATION}
                    variant={ButtonVariant.SOLID}
                    onClick={() => setSelectedService(hoveredService)}
                >
                    Next
                </Button>
            </div>
        </>
    );
};

// TODO: CAI-45
export const AgentCreationWizardWrapper: React.FC<IAgentCreationWizardWrapperProps> = props => {
    const { goTo: goToAgentManagementRoute } = useConvoAIAgentManagementRoute();
    const [isCreating, setIsCreating] = useState<boolean>(false);

    const handleCreateAgent = async (val: ConversationalAgent) => {
        setIsCreating(true);
        props.onCreateAgent &&
            props.onCreateAgent(val).then(() => {
                setIsCreating(false);
                goToAgentManagementRoute();
            });
    };

    if (isCreating) {
        return (
            <div css={loadingWrapperStyle}>
                <Loading />
            </div>
        );
    }

    switch (props.providerType) {
        case MotiveTypes.WATSON_ASSISTANT:
            return (
                <WatsonAssistantWizard
                    watsonConfigurations={props.watsonConfigurations}
                    onCreateWatsonConfiguration={props.onCreateWatsonConfiguration}
                    onUpdateWatsonConfiguration={props.onUpdateWatsonConfiguration}
                    onCreateAgent={handleCreateAgent}
                    onReset={props.onGoToProviderSelection}
                />
            );
        default:
            return <>An invalid provider type was provided.</>;
    }
};

export const ChooseProviderType: React.FC<{ onChooseProviderType: (providerType?: string) => void }> = props => {
    const [selectedType, setSelectedType] = useState<string | undefined>();
    const token = useStyle();

    const handlePrevious = () => {
        props.onChooseProviderType(undefined);
    };

    const handleSelectProvider = () => {
        props.onChooseProviderType(selectedType);
    };

    return (
        <>
            <Heading size={Size.MEDIUM}>{`Agent Management`}</Heading>
            <Divider />
            <Text size={Size.LARGER}>Select an option for agent management.</Text>
            <div css={providerIconWrapperStyle(token)}>
                {/* 
                    // To be added when dialogflow flows work
                    <div
                    css={providerIconStyle(
                        token,
                        selectedType !== undefined,
                        selectedType === MotiveTypes.DIALOGFLOW_AGENT
                    )}
                    onClick={() => {
                        setSelectedType(MotiveTypes.DIALOGFLOW_AGENT);
                    }}
                >
                    <Icon css={iconStyle(token)} size={Size.LARGER} icon={IconTypes.SPEECH} />
                    <Text size={Size.LARGE} bold>
                        Create a Dialogflow Agent
                    </Text>
                    <Text>Google</Text>
                </div> */}
                <div
                    css={providerIconStyle(
                        token,
                        selectedType !== undefined,
                        selectedType === MotiveTypes.WATSON_ASSISTANT
                    )}
                    onClick={() => {
                        setSelectedType(MotiveTypes.WATSON_ASSISTANT);
                    }}
                >
                    <Icon css={iconStyle(token)} size={Size.LARGER} icon={IconTypes.SPEECH} />
                    <Text size={Size.LARGE} bold>
                        Link a Watson Assistant
                    </Text>
                    <Text>IBM</Text>
                </div>
            </div>
            <div css={buttonWrapperStyle}>
                <Button
                    css={previousButtonStyle(token)}
                    onClick={handlePrevious}
                    variant={ButtonVariant.HOLLOW}
                    disabled
                >
                    Previous
                </Button>
                <Button onClick={handleSelectProvider} variant={ButtonVariant.SOLID} disabled={!selectedType}>
                    Next
                </Button>
            </div>
        </>
    );
};

export const CreateAgent: React.FC<ICreateAgentProps> = props => {
    // Skip the very first step by setting default provider type to MotiveTypes.WatsonAssistant
    const [providerType, setProviderType] = useState<string | undefined>(MotiveTypes.WATSON_ASSISTANT);

    const handleChooseProviderType = (provider?: string) => {
        setProviderType(provider);
    };

    const handleGoToProviderSelection = () => {
        // Disable previous for now
        // setProviderType(undefined);
    };

    return (
        <>
            {providerType ? (
                <AgentCreationWizardWrapper
                    onCreateAgent={props.onCreateAgent}
                    onCreateWatsonConfiguration={props.onCreateWatsonConfiguration}
                    onUpdateWatsonConfiguration={props.onUpdateWatsonConfiguration}
                    providerType={providerType}
                    watsonConfigurations={props.watsonConfigurations}
                    onGoToProviderSelection={handleGoToProviderSelection}
                />
            ) : (
                <ChooseProviderType onChooseProviderType={handleChooseProviderType} />
            )}
        </>
    );
};
