import { IAccountManagerViewModel } from '../../shared/motive/models/AccountManagerViewModel';
import { UserLoginStatus } from './UserInfoReducer';

export const userInfoSetAction = (userInfo: IAccountManagerViewModel) => {
    return {
        type: `userInfo/set`,
        userInfo
    } as const;
};

export const userInfoUnsetAction = () => {
    return {
        type: `userInfo/unset`
    } as const;
};

export const userLoginStatusSet = (status: UserLoginStatus, error?: Error) => {
    return {
        type: `userInfo/status/set`,
        status,
        error
    } as const;
};

export const userLoginStatusUnset = () => {
    return {
        type: `userInfo/status/unset`
    } as const;
};

export type UserInfoActionType = ReturnType<
    typeof userInfoSetAction | typeof userInfoUnsetAction | typeof userLoginStatusSet | typeof userLoginStatusUnset
>;
