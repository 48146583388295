import React from 'react';
import { containerStyle } from './DrawerSideMenu.style';
import { Button, ButtonVariant } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Size } from '../../core-ui/constants/Size';
import { useStyle } from '../../shared/hooks/useStyle';

export interface IMenuItem {
    key: string;
    icon: IconTypes;
    onClick: () => void;
}
export interface IDrawerSideMenuProps {
    width: number; // px
    menuItems: IMenuItem[];
    selectedItem?: string;
}
export const DrawerSideMenu: React.FC<IDrawerSideMenuProps> = ({ selectedItem, width, menuItems }) => {
    const tokens = useStyle();

    return (
        <div css={containerStyle(tokens, width)}>
            {menuItems.map((item, index) => (
                <Button
                    variant={ButtonVariant.TAB}
                    key={index}
                    selected={selectedItem === item.key}
                    onClick={item.onClick}
                    icon={item.icon}
                    size={Size.INHERIT}
                />
            ))}
        </div>
    );
};
