import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const containerStyle = ({ spacings }: IToken) => css`
    padding: ${spacings.medium};
    height: 100%;
`;

export const SpacesGrid = css`
    margin: 50px;
    display: grid;
    grid-template-rows: 0.5fr 11.5fr;
    grid-template-columns: 1fr 8fr 1fr;
    grid-gap: 10px;
`;

export const SpacesListGridArea = css`
    display: grid;
    grid-gap: 15px;
    grid-column: 2;
    grid-row: 2;
    grid-template-columns: 3fr 3fr 3fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 10px;
`;

export const InputText = css`
    width: 80%;
    margin-left: 10%;
    grid-column: 2;
    grid-row: 1;
`;
