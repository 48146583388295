import React, { useState } from 'react';
import { ICatalogsProps } from '../catalogs';
import { groupBy, sortBy } from 'lodash-es';
import { useTypeDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { getSmartTypeName } from '../../util/ObjectDefinitionsUtils';
import {
    collectionStyle,
    catalogSectionStyle,
    catalogTitleStyle,
    dashboardDetailsWrapper,
    dashboardCatalogFoldersWrapper,
    dashboardCatalogWrapper,
    dashboardCatalogsLayout,
    catalogDetailsIcon,
    catalogDetailsText,
    selectCatalogSection,
    dashboardCatalogCard,
    closeDetailsIcon,
    dashboardSearchControls
} from './DashboardCatalogs.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { DashboardPanel } from '../dashboardPanel';
import { Icon } from '../../core-ui/icon';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Size } from '../../core-ui/constants/Size';
import { Card } from '../../core-ui/card';
import { InputField } from '../../core-ui/inputField';
import { ICatalog, ICatalogInfo } from '../../shared/motive/models/Catalog';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { DashboardCatalogDetails } from '../dashboardCatalogDetails';
import { ScrollZone } from '../../core-ui/scrollZone/ScrollZone';
import { CatalogFolder } from '../catalogFolder';
import { QuickCreateImportCatalogsContainer } from '../../containers/quickCreateImportCatalogsContainer';
import { Collapsible } from '../../core-ui/collapse';
import { useToggle } from '../../shared/hooks/useToggle';
import { CatalogItemContainer } from '../../containers/catalogItemContainer/CatalogItemContainer';
import { Breadcrumbs } from '../../core-ui/breadcrumbs/Breadcrumbs';
import { useGetAllCatalogs } from '../../shared/motive/hooks/useCatalogs';
import { useTranslation } from 'react-i18next';

const filterCatalogPredicate = (filter: string, catalog: ICatalog<IScriptObjectModel>) => {
    if (!filter) {
        return true;
    }

    return catalog.title && catalog.title.toUpperCase().includes(filter.toUpperCase());
};

export const DashboardCatalogs: React.FC<ICatalogsProps> = (props: ICatalogsProps) => {
    const { catalogs } = props;
    const [filter, setFilter] = useState('');
    const [selectedCatalog, setSelectedCatalog] = useState<ICatalogInfo | undefined>(undefined);
    const [isCatalogCreation, toggleCatalogCreation] = useToggle(false);

    const clickOutAreaRef = React.createRef<HTMLDivElement>();

    const token = useStyle();
    const allCatalogs = sortBy(catalogs, 'id');
    const filteredCatalogs = allCatalogs.filter(c => filterCatalogPredicate(filter, c));

    const groupedCatalogs = groupBy(filteredCatalogs, 'objectType');
    const sortedCatalogs = Object.keys(groupedCatalogs).sort();
    const typeDefs = useTypeDefinitions();

    const allCatalogsForSpace = useGetAllCatalogs();
    const { t } = useTranslation();
    const handleFolderClickout = (catalog: ICatalogInfo, e?: MouseEvent) => {
        if (selectedCatalog && selectedCatalog.id !== catalog.id) {
            return;
        }

        //Only unselects the folder if the click out happens in the click-out area
        e &&
            clickOutAreaRef.current?.contains(e.target as Element) &&
            setSelectedCatalog(prevState => {
                if (!prevState) {
                    return catalog;
                } else if (catalog.id === prevState.id) {
                    return undefined;
                } else {
                    return prevState;
                }
            });
    };

    const handleSelectCatalog = (catalogId: string) => {
        const findCatalog = allCatalogs.find(c => c.id === catalogId);

        if (findCatalog) {
            setSelectedCatalog(findCatalog);
        }

        //Reset the search if we are applying a filter but the external catalog is not within the search terms
        if (filter && !filteredCatalogs.some(c => c.id === catalogId)) {
            setFilter('');
        }
    };

    const buttonText = isCatalogCreation ? t('cancel') : t('create');

    return (
        <DashboardPanel
            headingTitle={t('catalogs')}
            onClickCreateButton={toggleCatalogCreation}
            buttonText={buttonText}
        >
            <Collapsible isShowing={isCatalogCreation}>
                <QuickCreateImportCatalogsContainer
                    projectConfig={props.projectConfig}
                    allSpaceCatalogs={props.allSpaceCatalogs}
                    blackListTypes={props.blackListTypes}
                    whiteListTypes={props.whiteListTypes}
                    onImportCatalogs={props.onImportCatalogs}
                    onCreateCatalog={props.onCreateCatalog}
                />
            </Collapsible>
            <div css={dashboardCatalogsLayout}>
                <Breadcrumbs crumbs={[{ name: '' }]}></Breadcrumbs>

                <div css={dashboardCatalogWrapper}>
                    <ScrollZone css={dashboardCatalogCard(token)}>
                        <div ref={clickOutAreaRef} css={dashboardCatalogFoldersWrapper(token)}>
                            <div css={dashboardSearchControls}>
                                <InputField placeholder={t('search')} value={filter} onChange={setFilter}></InputField>
                            </div>
                            {sortedCatalogs.map(type => (
                                <div key={type} css={catalogSectionStyle(token)}>
                                    <div css={catalogTitleStyle}>{getSmartTypeName(type, typeDefs)}</div>
                                    <Card css={collectionStyle(token)}>
                                        {groupedCatalogs[type].map(catalog => (
                                            <CatalogItemContainer key={catalog.id}>
                                                {({ renameCatalog, deleteCatalog }) => (
                                                    <CatalogFolder
                                                        onDelete={deleteCatalog}
                                                        onRename={renameCatalog}
                                                        onClick={() => setSelectedCatalog(catalog)}
                                                        onClickOut={evt => handleFolderClickout(catalog, evt)}
                                                        onDoubleClick={() => props.onCatalogRowPressed(catalog)}
                                                        isSelected={selectedCatalog?.id === catalog.id}
                                                        catalog={catalog}
                                                    ></CatalogFolder>
                                                )}
                                            </CatalogItemContainer>
                                        ))}
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </ScrollZone>
                    <ScrollZone css={dashboardDetailsWrapper(token)}>
                        {selectedCatalog ? (
                            <>
                                <Icon
                                    onClick={() => setSelectedCatalog(undefined)}
                                    css={closeDetailsIcon}
                                    icon={IconTypes.TIMES}
                                ></Icon>
                                <DashboardCatalogDetails
                                    allCatalogs={allCatalogsForSpace.data}
                                    catalog={selectedCatalog}
                                    onSelectCatalog={handleSelectCatalog}
                                ></DashboardCatalogDetails>
                            </>
                        ) : (
                            <div css={selectCatalogSection}>
                                <div css={catalogDetailsIcon}>
                                    <Icon icon={IconTypes.EDIT} size={Size.LARGER}></Icon>
                                </div>
                                <div css={catalogDetailsText}>{t('viewCatalogDetailsText')}</div>
                            </div>
                        )}
                    </ScrollZone>
                </div>
            </div>
        </DashboardPanel>
    );
};
