import React from 'react';
import { createContext, useState } from 'react';
import { noop } from 'lodash-es';
import { EditableOnlyProvider } from '../../components/editableOnFocus/EditableOnFocus';

export interface IUIContext {
    drawerIsOpen: boolean;
    setDrawerIsOpen: (state: boolean) => void;
}

const UIContextDefaultValues: IUIContext = {
    drawerIsOpen: false,
    setDrawerIsOpen: noop
};

export const UIContext = createContext<IUIContext>(UIContextDefaultValues);

export const UiStore: React.FC = ({ children }) => {
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const ctxt: IUIContext = {
        drawerIsOpen: drawerIsOpen,
        setDrawerIsOpen: setDrawerIsOpen
    };

    return (
        <EditableOnlyProvider>
            <UIContext.Provider value={ctxt}>{children}</UIContext.Provider>
        </EditableOnlyProvider>
    );
};
