import { FieldTypes } from '../../shared/motive/models/ScriptObjectModel';
import { setWith, clone } from 'lodash-es';
import { IResourceWrapperProps } from '../../containers/ResourceWrapperContainer/ResourceWrapperContainer';
import { ScriptActionType } from '../../shared/motive/reducers/ScriptEditorReducers';
import { useDispatch } from 'react-redux';

export const useHandleUpdateResource = (props: IResourceWrapperProps) => {
    const scriptDispatch = useDispatch();

    return (path: string, fieldValue: FieldTypes): void => {
        const updatedResource = setWith(clone(props.resourceWrapper.resource), path, fieldValue, clone);

        const updatedWrapper = {
            ...props.resourceWrapper,
            resource: updatedResource
        };

        scriptDispatch({
            type: ScriptActionType.RESOURCE_UPDATE,
            resourceWrapper: updatedWrapper,
            targetFrameId: props.frame.id
        });
    };
};
