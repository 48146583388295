import React, { useContext } from 'react';
import { useUIStack } from '../../shared/hooks/useUIStack';
import { DashboardDrawer } from '../../components/dashboardDrawer';

import { overFlowHiddenStyle } from './DashboardDrawerContainer.style';
import { drawerContainerStyle } from '../drawerCatalogsTabContainer/DrawerCatalogsTabContainer.style';
import { UIContext } from '../../stores/uiStore/UiStore';

interface IDashboardDrawerContainerProps {
    isInMediaQueryMobileRange: boolean;
}

export const DashboardDrawerContainer: React.FC<IDashboardDrawerContainerProps> = () => {
    const { render, renderTitle, uiStack, pushItem } = useUIStack();

    const uiContext = useContext(UIContext);

    return (
        <DashboardDrawer
            css={drawerContainerStyle}
            isShowing={uiStack.length > 0}
            onClickClose={() => {
                pushItem('clearExisting', undefined);
                uiContext.setDrawerIsOpen(false);
            }}
            selectedTitleElement={renderTitle()}
        >
            <div css={overFlowHiddenStyle}>{render()}</div>
        </DashboardDrawer>
    );
};
