import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext/interfaces/Token';

export const baseResourceItemStyle = ({ colors }: IToken) => `
    background-color: ${colors.background.light};
    transition-duration: 250ms;
    border-radius: 6px;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 4px;
    &:hover {
        filter: brightness(90%);
    }
    height: 88px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
`;

export const thumbContainerStyle = css`
    opacity: 0.5;
`;

export const resourceListItemStyle = css`
    display: flex;
    align-content: center;
    align-items: center;
`;

export const resourceGridItemStyle = css`
    height: 100%;
    width: 100%;
`;

export const gridItemTypeHeadingStyle = (span: number) => css`
    grid-column-start: span ${span};
`;

export const resourceItemTextStyle = (token: IToken, display?: 'grid' | 'list') => css`
    text-align: ${display !== 'list' ? 'center' : 'left'};
    white-space: normal;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: ${token.fontSizes.small};
`;

export const resourcesCollectionStyle = (token: IToken) => css`
    grid-gap: ${token.spacings.smaller};
`;
export const resourcesGroupStyle = (token: IToken) => css`
    background: ${token.colors.background.lighter};
    padding: 5px;
    margin-bottom: 1px;
`;
export const resourcesGroupTitleStyle = (token: IToken) => css`
    text-transform: uppercase;
    color: ${token.colors.font.secondary};
    padding-bottom: 5px;
`;
export const resourcesListCollectionStyle = (token: IToken) => css`
    grid-gap: ${token.spacings.smaller};
    grid-template-columns: repeat(auto-fit);
`;

export const resourceGroupContainerStyle = ({ colors, spacings }: IToken) => css`
    background-color: ${colors.background.light};
    color: white;
    padding: ${spacings.smaller};
    border-bottom: solid;
    border-width: 1px;
    cursor: pointer;
`;

export const isDraggingStyle = (isDragging: boolean) => css`
    ${isDragging && `transform: translate3d(0, -50%, 0);`}
`;

export const containerStyle = ({ spacings }: IToken) => css`
    padding: 0 ${spacings.smallest};
`;

export const resourceIconStyle = (token: IToken) => css`
    padding: ${token.spacings.smaller};
    color: ${token.colors.font.primary};
    transform: scale(0.8);
`;
