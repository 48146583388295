import { TabsProps } from '@chakra-ui/core';

export const defaultTabProps = {
    _focus: undefined
};

export const defaultMainTabsProps: Partial<TabsProps> = {
    variant: 'enclosed-colored',
    align: 'end'
};
