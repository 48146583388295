import { useEffect } from 'react';
import { useCallbackRef } from '../useCallbackRef/UseCallbackRef';
import { noop } from 'lodash-es';
const DOCUMENT_EVENT_CLICK = 'click';

function useDocumentClickHandler<T extends HTMLElement>(
    onClickOut: (event?: MouseEvent) => void = noop,
    onClickIn: (event?: MouseEvent) => void = noop,
    calculateOffDivContainment: boolean = false
): (node: T) => void {
    const [anchor, setAnchorRefCallback] = useCallbackRef<T>();

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent): void => {
            // If we didn't click anything or don't know what we clicked do nothing.
            if (!event || !event.target || !(event.target instanceof Element) || !anchor) {
                return;
            }

            const rect = anchor.getBoundingClientRect();

            // We will consider this to be a click inside of the div if the mouse was inside the bounding rect when it occured.
            const isMouseInBoundingRect =
                event.clientX >= rect.left &&
                event.clientX <= rect.right &&
                event.clientY >= rect.top &&
                event.clientY <= rect.bottom;

            // Otherwise a click is when our anchor contains the target OR is the target itself.
            const isElementClick = calculateOffDivContainment
                ? (calculateOffDivContainment && anchor.contains(event.target)) || anchor === event.target
                : isMouseInBoundingRect;

            if (!isElementClick && onClickOut) {
                onClickOut(event);
            }

            if (isElementClick && onClickIn) {
                onClickIn(event);
            }
        };

        document.addEventListener(DOCUMENT_EVENT_CLICK, handleDocumentClick);

        return () => {
            document.removeEventListener(DOCUMENT_EVENT_CLICK, handleDocumentClick);
        };
    }, [anchor, onClickIn, onClickOut]);

    return setAnchorRefCallback;
}

export function useClickOutElement<T extends HTMLElement>(
    onClick: (event?: MouseEvent) => void,
    useContains = false
): (node: T) => void {
    return useClickInAndOutElement(onClick, () => null, useContains);
}

export function useClickInElement<T extends HTMLElement>(
    onClick: (event?: MouseEvent) => void,
    useContains = false
): (node: T) => void {
    return useClickInAndOutElement(() => null, onClick, useContains);
}

export function useClickInAndOutElement<T extends HTMLElement>(
    onClickOut: (event?: MouseEvent) => void,
    onClickIn: (event?: MouseEvent) => void,
    useContains: boolean = false
): (node: T) => void {
    return useDocumentClickHandler(onClickOut, onClickIn, useContains);
}
