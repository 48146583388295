import { Tooltip, ITooltip } from '@chakra-ui/core';
import React from 'react';

export interface ITooltipProps {
    label?: string;
    placement?: ITooltip['placement'];
    color?: string;
    hasArrow?: boolean;
    className?: string;
    isOpen?: boolean;
    showDelay?: number;
    hideDelay?: number;
    onOpen?: () => void;
    onClose?: () => void;
}

export const TooltipBox: React.FC<ITooltipProps> = ({
    children,
    label,
    placement = 'top',
    hasArrow = false,
    color = 'black.600',
    ...rest
}): React.ReactElement => {
    return (
        <Tooltip
            bg={color}
            hasArrow={hasArrow}
            label={label}
            placement={placement}
            aria-label={label ? label : ''}
            {...rest}
        >
            <span>{children}</span>
        </Tooltip>
    );
};
