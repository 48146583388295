/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useDropzone } from 'react-dropzone';
import {
    collapsibleContentWrapperStyle,
    headingStyle,
    uploadItemStyle,
    uploadLoadingItemStyle,
    uploadLoadingBarStyle,
    uploadPercentStyle,
    uploadErrorStyle,
    uploadErrorButtonStyle,
    uploadErrorMessageStyle
} from './MediaUploadZone.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { IUploadState } from '../../containers/mediaManagementContainer/MediaManagementContainer';
import { getErrorStatusCode } from '../../shared/motive/networking/HttpClient';
import { Button, ButtonVariant } from '../../core-ui/button';
import { mediaUploadRemoveAction } from '../../redux/spaceKeyed/media/MediaActions';
import { isResolved, isLoading } from '../../redux/Status';
import { EmptyState } from '../../core-ui/emptyStates/EmptyState';
import { useDispatch } from 'react-redux';
import { IUploadingMediaEntity } from '../../redux/spaceKeyed/media/MediaReducer';

interface IUploadMediaItemProps {
    item: IUploadingMediaEntity;
    onFileUpload: (acceptedFiles: any[], overwrite: boolean, oldId?: string) => void;
}

const UploadingMediaItem = ({ item, onFileUpload }: IUploadMediaItemProps) => {
    const token = useStyle();
    const dispatch = useDispatch();

    const error = item.status.error;
    const isConflict = error && getErrorStatusCode(error) === 409;

    const handleOverwrite = () => {
        onFileUpload([item.file], true, item.id);
    };

    const remove = () => dispatch(mediaUploadRemoveAction(item.id));

    return (
        <div css={uploadItemStyle(token)}>
            <div>{item.file?.name}</div>

            {isLoading(item.status.loadingState) && (
                <div css={uploadLoadingBarStyle}>
                    <div css={uploadLoadingItemStyle(item.status.uploadProgress ?? 0)}>
                        <div css={uploadPercentStyle}>{`${item.status.uploadProgress}%`}</div>
                    </div>
                </div>
            )}

            {error && (
                <div css={uploadErrorStyle(token)}>
                    <div css={uploadErrorMessageStyle(token)}>
                        {isConflict ? (
                            <span>A file with the same name already exists.</span>
                        ) : (
                            <span>An error occured trying to upload your file: {error.message}</span>
                        )}
                    </div>
                    {isResolved(item.status.loadingState) && (
                        <>
                            <Button css={uploadErrorButtonStyle(token)} variant={ButtonVariant.SOLID} onClick={remove}>
                                Cancel
                            </Button>
                            {isConflict && (
                                <Button
                                    css={uploadErrorButtonStyle(token)}
                                    variant={ButtonVariant.SOLID}
                                    onClick={handleOverwrite}
                                >
                                    Overwrite
                                </Button>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

interface IMediaUploadZoneProps {
    uploadStatus?: IUploadState;
    onFileUpload: (acceptedFiles: any[], overwrite: boolean, oldId?: string) => void;
    uploadingItems?: IUploadingMediaEntity[];
    multipleFiles?: boolean;
    uploadMessage?: string | React.ReactNode;
}

const dropMessage = (fileLength: number) => `Drop ${fileLength} file${fileLength > 1 ? `s` : ``}`;

export const MediaUploadZone: React.FC<IMediaUploadZoneProps> = ({
    uploadStatus,
    uploadingItems,
    onFileUpload,
    uploadMessage = `Click here or drag and drop file(s) to upload`,
    multipleFiles = true
}) => {
    const handleDrop = (acceptedFiles: any[]) => {
        onFileUpload(acceptedFiles, false);
    };

    const { getRootProps, getInputProps, isDragActive, draggedFiles } = useDropzone({
        onDrop: handleDrop,
        multiple: multipleFiles
    });
    const token = useStyle();

    return (
        <div {...getRootProps()} css={collapsibleContentWrapperStyle(token, isDragActive)}>
            <div css={headingStyle}>
                {uploadingItems && uploadingItems.length > 0 ? (
                    <>
                        {uploadingItems.map(item => (
                            <UploadingMediaItem item={item} key={item.id} onFileUpload={onFileUpload} />
                        ))}
                    </>
                ) : (
                    <>
                        {isDragActive ? (
                            <EmptyState icon="file">{dropMessage(draggedFiles.length)}</EmptyState>
                        ) : (
                            <EmptyState icon="upload">{uploadMessage}</EmptyState>
                        )}
                    </>
                )}
            </div>
            <>{uploadStatus?.message}</>
            <input {...getInputProps()} />
        </div>
    );
};
