import { labelTextStyle, conditionCardStyle, iconStyle } from './Condition.style';
import React from 'react';
import { ObjectEditor, IObjectEditorProps } from '../../containers/objectEditor';
import { FieldTypes } from '../../shared/motive/models/ScriptObjectModel';
import { useStyle } from '../../shared/hooks/useStyle';
import { EditableText } from '../../core-ui/editableText';
import { Size } from '../../core-ui/constants/Size';
import {
    CONDITION_WRAPPER_LABEL,
    CONDITION_WRAPPER_IS_ENABLED,
    IConditionWrapper,
    CONDITION_WRAPPER_NOTES
} from '../../shared/motive/models/Script';
import { ObjectWrapperBox } from '../objectWrapperBox';
import { Icon } from '../../core-ui/icon';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BarVariant } from '../../core-ui/bar/Bar';

interface IConditionProps extends IObjectEditorProps {
    conditionWrapper: IConditionWrapper;
    conditionIcon?: string;
    children?: React.ReactNode;
    label?: string | null;
    placeholder?: string;
    note?: string | null;
    isReadOnly: boolean;
    isEnabled?: boolean;
    hasNote?: boolean;
    onClick?: () => void;
    onDelete?: () => void;
    onClickSetEventId?: ((eventId: string) => void) | undefined;
}

export const Condition: React.FC<IConditionProps> = ({
    onChange,
    path,
    conditionIcon,
    onDelete,
    onClickSetEventId,
    ...restProps
}) => {
    const tokens = useStyle();
    const getSubPath = (subPath: string) => `${path ? `${path}.` : ''}${subPath}`;
    const handleWrapperChangeFactory = (subWrapperPath: string) => (value: FieldTypes) => {
        onChange(`${getSubPath(subWrapperPath)}`, value);
    };
    const handleIsEnableChange = handleWrapperChangeFactory(CONDITION_WRAPPER_IS_ENABLED);
    const handleNotesChanged = handleWrapperChangeFactory(CONDITION_WRAPPER_NOTES);

    const condition = restProps.conditionWrapper?.condition;

    const conditionPath = 'condition';

    if (!condition) {
        return <></>;
    }

    return (
        <>
            <ObjectWrapperBox
                isEnabled={restProps.isEnabled}
                onClickIsEnableToggle={handleIsEnableChange}
                onClickDelete={onDelete}
                noteValue={restProps.conditionWrapper.notes}
                onNoteChange={handleNotesChanged}
                barVariant={BarVariant.TRANSPARENT}
                cardStyle={conditionCardStyle(tokens)}
                headerRender={
                    <>
                        {conditionIcon && (
                            <Icon css={iconStyle(tokens)} size={Size.SMALL} faIcon={conditionIcon as IconProp} />
                        )}
                        <EditableText
                            size={Size.MEDIUM}
                            textStyle={labelTextStyle(tokens)}
                            defaultValue={restProps.label ?? undefined}
                            placeholder={restProps.placeholder}
                            onEditComplete={handleWrapperChangeFactory(CONDITION_WRAPPER_LABEL)}
                        />
                    </>
                }
            >
                <ObjectEditor
                    script={restProps.script}
                    frame={restProps.frame}
                    value={condition}
                    type={condition.type}
                    onChange={onChange}
                    path={conditionPath}
                    hideFields={['isEnabled', 'label']}
                    onClickSetEventId={onClickSetEventId}
                />
            </ObjectWrapperBox>
        </>
    );
};
