import { IToken } from '../../shared/contexts/styleContext';
import { css } from '@emotion/core';

export const panelLayout = (token: IToken) => css`
    overflow: auto;
    box-sizing: border-box;
    height: 97%;
    display: grid;
    grid-template-rows: auto 1fr;
`;

export const panelPadding = (token: IToken) => css`
    padding: ${token.spacings.smaller};
    height: 100%;
    box-sizing: border-box;
`;

export const headingWrapperStyle = (token: IToken) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${token.spacings.small} ${token.spacings.medium} ${token.spacings.smallest} ${token.spacings.medium};
    min-height: ${token.spacings.large};
`;

export const headingTextStyle = css`
    font-weight: 400;
`;

export const dividerStyle = (token: IToken) => css`
    margin: 0 ${token.spacings.medium} ${token.spacings.smaller} ${token.spacings.medium};
`;

export const panelBodyStyle = (token: IToken) => css`
    padding: 0 ${token.spacings.medium};
    height: inherit;
`;
