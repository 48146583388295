/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { CatalogQuickEditor } from '../../components/catalogQuickEditor/CatalogQuickEditor';
import { useAddCatalogItem } from '../../shared/motive/hooks/useCatalogs/useAddCatalogItem/useAddCatalogItem';
import { useGetCatalogById } from '../../shared/motive/hooks/useCatalogs';
import { useSaveCatalogItem } from '../../shared/motive/hooks/useCatalogs/useSaveCatalogItem/useSaveCatalogItem';
import { createScriptObject, filterCatalogItem } from '../../util/MotiveUtils';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo';
import { IMediaItem, IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { HeightUsedScrollContainer } from '../heightUsedScrollContainer';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { useSaveCatalog } from '../../shared/motive/hooks/useCatalogs/useSaveCatalog/useSaveCatalog';
import { Loading } from '../../core-ui/loading';
import { searchStyle } from './CatalogQuickEditorContainer.style';
import { InputField } from '../../core-ui/inputField';
import { Size } from '../../core-ui/constants/Size';

export interface ICatalogQuickEditorContainerProps {
    catalogId: string;
    allowQuickObjectEdit?: boolean;
}

export const CatalogQuickEditorContainer: React.FC<ICatalogQuickEditorContainerProps> = ({
    catalogId,
    allowQuickObjectEdit = true
}): React.ReactElement => {
    const { execute: addNewItem } = useAddCatalogItem();
    const { execute: updateItem } = useSaveCatalogItem();
    const { execute: saveCatalog } = useSaveCatalog();
    const { data: catalog, isLoading } = useGetCatalogById(catalogId);
    const [showUploadZone, setShowUploadZone] = useState(false);
    const typeDefs = useObjectDefinitions();
    //const { handleSetDrawerTitle } = useDashboardDrawer();

    const [filterValue, setFilterValue] = useState('');

    const handleAddNewItem = async (itemToAdd: IScriptObjectModel) => {
        await addNewItem({ catalogId, item: itemToAdd });
    };

    const handleSaveObject = async (itemToAdd: IScriptObjectModel) => {
        await updateItem({ catalogId, item: itemToAdd });
    };

    const handleShowUploadZone = () => {
        setShowUploadZone(!showUploadZone);
    };

    const handleDeleteItem = (itemId: string, catalog?: ICatalog<IScriptObjectModel>) => {
        catalog && saveCatalog({ catalog: filterCatalogItem(catalog, itemId) });
    };

    const handleFileUpload = (res: IHostedMediaSource) => {
        const newMediaItem: IMediaItem = {
            ...createScriptObject(MotiveTypes.MEDIA_ITEM, typeDefs),
            title: (res as IHostedMediaSource).title as string,
            source: res as IHostedMediaSource
        };
        addNewItem({ catalogId, item: newMediaItem });
    };

    useEffect(() => {
        //handleSetDrawerTitle(<>{catalog?.title}</>);
    });

    const handleChange = (changedValue: string) => setFilterValue(changedValue);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            {catalog && (
                <HeightUsedScrollContainer>
                    <InputField css={searchStyle} onChange={handleChange} size={Size.MEDIUM} />
                    <CatalogQuickEditor
                        catalog={catalog}
                        onAddNewItem={handleAddNewItem}
                        onSaveItem={handleSaveObject}
                        allowQuickObjectEdit={allowQuickObjectEdit}
                        onFileUpload={handleFileUpload}
                        showUploadZone={showUploadZone}
                        onShowUploadZone={handleShowUploadZone}
                        onDeleteItem={handleDeleteItem}
                        itemFilter={filterValue}
                    />
                </HeightUsedScrollContainer>
            )}
        </>
    );
};
