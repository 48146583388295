import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const listHeadingStyle = (token: IToken) => css`
    margin-top: 15px;
    background-color: ${token.colors.interactible.tertiary};
    padding: ${token.spacings.smaller};
`;

export const listItemStyle = (token: IToken) => css`
    padding: ${token.spacings.small} ${token.spacings.smaller};
    border-bottom: 1px solid ${token.colors.border.primary};
`;

export const checkboxStyle = (token: IToken) => css`
    border-color: ${token.colors.border.secondary};
`;

export const checkboxWrapperStyle = (token: IToken) => css`
    padding-left: ${token.spacings.smaller};
`;

export const spinnerOffsetStyle = css`
    padding-top: 3px;
`;

export const spinnerIconStyle = (token: IToken) => css`
    color: ${token.colors.interactibleBorder.primary};
`;

export const spinnerWrapperStyle = css`
    display: flex;
    justify-content: center;
`;

export const textTitleWrapperStyle = (token: IToken, isLoading?: boolean) => css`
    font-size: ${token.fontSizes.medium};
    align-self: center;
    ${isLoading && `color: ${token.colors.font.secondary};`}
    border: 1px solid transparent;
    &:hover {
        border: 1px solid ${token.colors.interactibleBorder.secondary};
        background-color: rgba(0, 0, 0, 0.05);
    }
`;

export const gridRowStyle = css`
    display: grid;
    grid-template-columns: 5% 2.5% 40% 37.5% 15%;
`;

export const textMetadataWrapperStyle = (token: IToken) => css`
    font-size: ${token.fontSizes.medium};
`;

export const editFieldWrapperStyle = (token: IToken) => css`
    padding-right: ${token.spacings.medium};
`;

export const nameOnlyGridRowStyle = css`
    display: grid;
    grid-template-columns: 5% 17.5% 77.5%;
`;

export const draggableRowItemStyle = (token: IToken) => css`
    background-color: ${token.colors.background.lighter};
`;
