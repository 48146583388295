import React, { useState, useEffect } from 'react';
import { useStyle } from '../../shared/hooks/useStyle';
import { EditableText } from '../../core-ui/editableText';
import { IRenameProjectConfigParams } from '../../shared/motive/hooks/useRenameProjectConfig/useRenameProjectConfig';
import { Size } from '../../core-ui/constants/Size';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { projectNameWrapperStyle, projectNameStyle, projectNameInputStyle } from './RenameProjectConfig.style';

interface IRenameProjectConfigProps {
    rename: IHookProvidedCall<IRenameProjectConfigParams>;
    configName?: string;
}
export const RenameProjectConfig: React.FC<IRenameProjectConfigProps> = ({ rename, configName }) => {
    const token = useStyle();
    const [name, setName] = useState(configName);

    useEffect(() => {
        setName(configName);
    }, [configName]);

    return (
        <EditableText
            wrapperStyle={projectNameWrapperStyle(token)}
            textStyle={projectNameStyle(token)}
            inputStyle={projectNameInputStyle(token)}
            value={name}
            onChange={setName}
            onEditComplete={completedValue => rename.execute({ newName: completedValue })}
            size={Size.MEDIUM}
        ></EditableText>
    );
};
