import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const baseBarStyle = () => css`
    min-height: fit-content;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    box-sizing: border-box;
`;

export const primaryBarStyle = ({ colors }: IToken) => css`
    background-color: ${colors.bar.primary};
`;

export const secondaryBarStyle = ({ colors }: IToken) => css`
    background-color: ${colors.bar.secondary};
`;

export const tertiaryBarStyle = ({ colors }: IToken) => css`
    background-color: ${colors.bar.tertiary};
`;

export const resourceBarHeaderStyle = ({ colors }: IToken) => css`
    background-color: ${colors.bar.resourceBarHeader};
`;

export const conditionHeaderStyle = ({ colors }: IToken) => css`
    background-color: ${colors.bar.conditionBarHeader};
`;
export const variableHeaderStyle = (token: IToken) => css`
    background-color: ${token.colors.background.light};
    padding: 0;
`;
export const transparentBarStyle = (token: IToken) => css`
    background-color: transparent;
    color: ${token.colors.font.primary};
`;
