import { css } from '@emotion/core';
import { breakPointLargeNoFloor } from '../../core-ui/constants/Breakpoints';

export const parentLayoutStyle = css`
    display: grid;
    grid-template-rows: minmax(100px, auto) auto;
    grid-auto-flow: column;
    grid-template-columns: minmax(300px, 100%);
    grid-auto-columns: minmax(30%, 250px);
    ${breakPointLargeNoFloor} {
        grid-auto-flow: row;
    }
`;

export const columnItemStyle = css`
    grid-row: 1 / -1;

    ${breakPointLargeNoFloor} {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
    }
`;
