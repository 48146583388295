import React from 'react';
import { ErrorStyle, TextStyle } from './VisualError.style';

export const VisualErrorTestId = 'VisualErrorTestId';

interface IVisualErrorProps {
    message: string;
}

export const VisualError: React.FC<IVisualErrorProps> = ({ message, children }): React.ReactElement => {
    return (
        <div data-testid={VisualErrorTestId} css={ErrorStyle}>
            <p css={TextStyle}>{message}</p>
            {children}
        </div>
    );
};
