import { useCurrentSpace } from '../useCurrentSpace';
import { IEnumDefinition } from '../../models/EnumDefinition';
import { createEnumNetworkCall } from '../../networking/EnumDefinitionService';
import { getSystemName, uniqueMotiveId } from '../../../../util/MotiveUtils';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { useDispatch } from 'react-redux';

export interface IUseCreateEnumDefinitionParams {
    enumTitle: string;
}

export const useCreateEnumDefinition = (): IHookProvidedCall<
    IUseCreateEnumDefinitionParams,
    IEnumDefinition | undefined
> => {
    const [currentSpace] = useCurrentSpace();
    const dispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const createEnumDefinition = async (
        params: IUseCreateEnumDefinitionParams
    ): Promise<IEnumDefinition | undefined> => {
        if (!params.enumTitle) {
            throw new Error('Enum Title cannot be empty');
        }

        const eDef: IEnumDefinition = {
            id: uniqueMotiveId(),
            dataType: 'enum',
            name: getSystemName(params.enumTitle),
            editorInfo: {
                title: params.enumTitle
            }
        };

        const newEnum = await triggerCall(createEnumNetworkCall(currentSpace, eDef, getCancelableDispatch(dispatch)));

        return newEnum;
    };

    return { execute: createEnumDefinition, ...rest };
};
