import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const projectNameStyle = (token: IToken) => css`
    color: ${token.colors.font.tertiary};
    font-size: ${token.fontSizes.large};
`;

export const projectNameInputStyle = (token: IToken) => css`
    border: none;
    margin: 0 ${token.spacings.large};
    font-size: ${token.fontSizes.large};
`;

export const projectNameWrapperStyle = (token: IToken) => css`
    margin: 0 ${token.spacings.medium};
    width: 100%;
    overflow: hidden;
`;
