import { useCurrentSpace } from '../useCurrentSpace';
import { deleteEnumNetworkCall } from '../../networking/EnumDefinitionService';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useDispatch } from 'react-redux';

export interface IDeleteEnumDefinitionParams {
    enumId: string;
}

export const useDeleteEnumDefinition = (): IHookProvidedCall<IDeleteEnumDefinitionParams> => {
    const [currentSpace] = useCurrentSpace();
    const dispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const deleteEnumDefinition = async (params: IDeleteEnumDefinitionParams) => {
        await triggerCall(deleteEnumNetworkCall(currentSpace, params.enumId, getCancelableDispatch(dispatch)));
    };

    return { execute: deleteEnumDefinition, ...rest };
};
