import React, { useState } from 'react';
import { IPublishingProps } from '../../components/publishing/Publishing';
import { useCreateBundle } from '../../shared/motive/hooks/useCreateBundle/useCreateBundle';

interface IPublishingContainerProps {
    children: (props: IPublishingProps) => React.ReactNode;
}

export enum IPackageTypes {
    XAPI = 'xAPI'
}

export interface ILMSConfigurationOptions {
    title: string;
    description: string;
    packageMode: IPackageTypes;
    isAuto: boolean;
    isHosted: boolean;
    isQuickCode: boolean;
    isHeadset: boolean;
    launchServer: string;
    launchServerAuth: string;
}

const defaultConfiguration: ILMSConfigurationOptions = {
    title: '',
    description: '',
    packageMode: IPackageTypes.XAPI,
    isAuto: true,
    isHosted: true,
    isQuickCode: true,
    isHeadset: true,
    launchServer: '',
    launchServerAuth: ''
};

/* istanbul ignore next */
export const PublishingContainer: React.FC<IPublishingContainerProps> = ({ children }) => {
    const [configuration, setConfiguration] = useState(defaultConfiguration);

    const createBundleAction = useCreateBundle();

    const handleTitle = (title: string) => {
        setConfiguration({ ...configuration, title: title });
    };
    const handleDesc = (description: string) => {
        setConfiguration({ ...configuration, description: description });
    };
    const handleAutoToggle = () => {
        setConfiguration({ ...configuration, isAuto: !configuration.isAuto });
    };
    const handleHostingToggle = () => {
        setConfiguration({ ...configuration, isHosted: !configuration.isHosted });
    };
    const handleLaunchServer = (server: string) => {
        setConfiguration({ ...configuration, launchServer: server });
    };
    const handleLaunchAuth = (auth: string) => {
        setConfiguration({ ...configuration, launchServerAuth: auth });
    };

    return (
        <>
            {children({
                configuration: configuration,
                onHostedToggle: handleHostingToggle,
                onAutoToggle: handleAutoToggle,
                onDescriptionChange: handleDesc,
                onLaunchServerAuthChange: handleLaunchAuth,
                onLaunchServerChange: handleLaunchServer,
                onTitleChange: handleTitle,
                createBundleAction: createBundleAction
            })}
        </>
    );
};
