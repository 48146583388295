import { IBaseDrag } from '../Drop/Drop';
import produce from 'immer';
import { IDragEndHandlerAction } from './DragAndDropActions';

export type DragEndHandler = (data: IBaseDrag | undefined, index: number) => void;

/**
 * Extendable state to manage drag & drop
 * @param onDragEndHandlers Dictionary keyed off dropId for functions to fire when a drag end event occurs on the particular dropId
 * @param dragDropHoverState Dictionary keyed off dropId to keep a track of what drag types are hovering over it.
 */
interface IDragHandleState {
    onDragEndHandlers: Record<string, DragEndHandler[]>;
    dragDropHoverState: Record<string, IBaseDrag | undefined>;
}

export const DragHandleReducer = produce((state: IDragHandleState, action: IDragEndHandlerAction) => {
    switch (action.type) {
        case 'dragDrop/delete':
            delete state.onDragEndHandlers[action.dropId];
            break;
        case 'dragDrop/addHandler':
            state.onDragEndHandlers[action.dropId] = action.handler;
            break;
        case 'dragDrop/hover':
            if (action.drag) {
                state.dragDropHoverState[action.dropId] = action.drag;
            } else if (state.dragDropHoverState[action.dropId]) {
                delete state.dragDropHoverState[action.dropId];
            }
            break;
    }
});
