import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { emptyStateIconStyle, emptyStateMessageStyle } from './EmptyState.style';
import { useStyle } from '../../shared/hooks/useStyle';

interface IEmptyStateProps {
    icon: IconProp;
    className?: string;
}

/**
 * Displays a large icon with any additional child content to represent when a collection or state is empty.
 */
export const EmptyState: React.FC<IEmptyStateProps> = ({ icon, children, className }) => {
    const token = useStyle();

    return (
        <div className={className}>
            <div>
                <FontAwesomeIcon css={emptyStateIconStyle(token)} icon={icon} size="7x" />
            </div>
            <div css={emptyStateMessageStyle(token)}>{children}</div>
        </div>
    );
};
