export enum IconTypes {
    ANALYTICS = 'Analytics',
    ANCHOR = 'Anchor',
    ATOM = 'Atom',
    BULLSEYE_POINTER = 'Bullseye Pointer',
    CHECKMARK = 'Checkmark',
    CHEVRON_DOWN = 'Chevron Down',
    CHEVRON_LEFT = 'Chevron Left',
    CHEVRON_RIGHT = 'Chevron Right',
    COG = 'Cog',
    COGS = 'Cogs',
    COMMENT = 'Comment',
    COPY = 'Copy',
    COPY_H = 'Copy-H',
    CUBE = 'Cube',
    CUBES = 'Cubes',
    DELETE = 'Delete',
    DELETE_H = 'Delete-H',
    DOWN = 'Down',
    EDIT = 'Edit',
    ELLIPSES = 'Ellipses',
    ELLIPSES_H = 'Ellipses-H',
    EYE = 'Eye',
    EYESLASH = 'EyeSlash',
    VERTICAL_ELLIPSES = 'Ellipses-v',
    EXCLAMATION = 'Exclamation',
    EXTERNAL_LINK = 'External Link',
    FOLDER = 'Folder',
    FOLDER_OPEN = 'Folder Open',
    GRID = 'Grid',
    HEADPHONES = 'Headphones',
    HISTORY = 'History',
    LIST = 'List',
    LOAD_SPINNER = 'Loading Spinner',
    MAGIC = 'Magic',
    MEDIA = 'Media',
    MOVE = 'Move',
    PHOTO_VIDEO = 'Photo Video',
    PLAY = 'Play',
    PLUS = 'Plus',
    POLL = 'Poll',
    SCRIPT = 'Script',
    FILM = 'Film',
    SAVE = 'Save',
    SLIDERS_VERTICAL = 'Vertical Sliders',
    SORT = 'Sort',
    SPEECH = 'Speech',
    STICKY_NOTE = 'Sticky Note',
    SYNC = 'Sync',
    TIMES = 'Times',
    UP = 'Up',
    USER = 'User',
    USERS = 'Users',
    WALK = 'Walk',
    CLOSE = 'Close',
    MINUS = 'Minus',
    FILE = 'File',
    UNDO = 'Undo',
    REDO = 'Redo',
    UPLOAD = 'Upload',
    HOME = 'Home'
}
