enum StaticColorsEnum {
    none,
    red,
    blue,
    green,
    pink,
    white,
    black,
    gray
}

type StaticColorsStrings = keyof typeof StaticColorsEnum;
type StaticColors = Readonly<{ [key in StaticColorsStrings]: string }>;

export interface IBaseColors {
    primary: string;
    secondary: string;
    tertiary: string;
    warning: string;
    highlight: string;
}

export interface IPalette {
    [keys: string]: {
        [keys: string]: string;
    };
}

export interface IBackgroundColors {
    default: string;
    dark: string;
    neutral: string;
    darker: string;
    light: string;
    lighter: string;
    colorLight: string;
    colorLighter: string;
    highlight: string;
}

export interface IBarColors {
    primary: string;
    secondary: string;
    tertiary: string;
    resourceBarHeader: string;
    conditionBarHeader: string;
    dashboardNavbar: string;
    variableBarHeader: string;
    catalogBarHeader: string;
}

export interface IInteractibleColors extends IBaseColors {
    disabled: string;
}

interface IColorsPrivate<T extends StaticColors> {
    staticColors: T;
    palettes?: IPalette;
    background: IBackgroundColors;
    bar: IBarColors;
    border: IBaseColors;
    font: IBaseColors;
    interactible: IInteractibleColors;
    interactibleBorder: IBaseColors;
}

export type IColors = Readonly<IColorsPrivate<StaticColors>>;
