import { css } from '@emotion/core';

export const generateGridLayout = (min: string, max: string, repeatNumberOfCols: string, gridAutoRows?: string) => css`
    display: grid;
    grid-template-columns: repeat(${repeatNumberOfCols}, minmax(${min}, ${max}));
    justify-content: center;

    ${gridAutoRows ? `grid-auto-rows: ${gridAutoRows};` : ''}
`;

export const generateListLayout = (min: string, max: string) => css`
    display: grid;
    grid-template-columns: repeat(1, minmax(${min}, ${max}));
`;
