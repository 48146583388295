import { LocalizationKeys } from '../LocalizationKeys';

export const enStrings: Map<LocalizationKeys, string> = new Map<LocalizationKeys, string>()
    .set(LocalizationKeys.Account, 'Account')
    .set(LocalizationKeys.View, 'View')
    .set(LocalizationKeys.Login, 'Login')
    .set(LocalizationKeys.LogOut, 'Log out')
    .set(LocalizationKeys.CreateProject, 'Create a new project')
    .set(LocalizationKeys.ExistingProject, 'Open existing project')
    .set(LocalizationKeys.Recent, 'Recent')
    .set(LocalizationKeys.Save, 'Save')
    .set(LocalizationKeys.Cancel, 'Cancel')
    .set(LocalizationKeys.SignIntoMotive, 'Sign in to Motive')
    .set(LocalizationKeys.LastSavedOn, 'Last saved on')
    .set(LocalizationKeys.Scripting, 'Scripting')
    .set(LocalizationKeys.Catalog, 'Catalog')
    .set(LocalizationKeys.Catalogs, 'Catalogs')
    .set(LocalizationKeys.Resource, 'Resource')
    .set(LocalizationKeys.Resources, 'Resources')
    .set(LocalizationKeys.Script, 'Script')
    .set(LocalizationKeys.Scripts, 'Scripts')
    .set(LocalizationKeys.CourseDescription, 'Course Description')
    .set(LocalizationKeys.CourseTitle, 'Course Title')
    .set(LocalizationKeys.EnterADescription, 'Enter a Description')
    .set(LocalizationKeys.OutputOptions, 'Output Options')
    .set(LocalizationKeys.LaunchOptions, 'Launch Options')
    .set(LocalizationKeys.Properties, 'Properties')
    .set(LocalizationKeys.LaunchWithAQuickCode, 'Launch with Quick Code')
    .set(LocalizationKeys.LaunchInHeadset, 'Launch in Headset')
    .set(LocalizationKeys.MotiveHosted, 'Motive Hosted')
    .set(LocalizationKeys.LaunchServerOptions, 'Launch Server Options')
    .set(LocalizationKeys.LaunchServerAuthentication, 'Launch Server Authentication')
    .set(LocalizationKeys.LaunchServerURL, 'Launch Server URL')
    .set(LocalizationKeys.Download, 'Download')
    .set(LocalizationKeys.AutomaticUpload, 'Automatic Upload')
    .set(LocalizationKeys.AddNote, 'Add Note')
    .set(LocalizationKeys.Note, 'Note')
    .set(LocalizationKeys.RemoveNote, 'Remove Note')
    .set(LocalizationKeys.ManageAccount, 'Manage Account')
    .set(LocalizationKeys.MySpaces, 'Workspaces')
    .set(LocalizationKeys.AddCustomEvent, 'Add Custom Event')
    .set(LocalizationKeys.Delete, 'Delete')
    .set(LocalizationKeys.ProjectName, 'Project Name')
    .set(LocalizationKeys.ProjectNameIsTooLong, 'The project name is too long')
    .set(LocalizationKeys.ProjectNameIsTooShort, 'The project name is too short, it must be greater than 5 characters')
    .set(LocalizationKeys.ProjectNameIsTaken, 'The project name is already taken')
    .set(LocalizationKeys.AdvancedSettings, 'Advanced Settings')
    .set(LocalizationKeys.CatalogOptions, 'Catalog Options')
    .set(LocalizationKeys.AutoGenerateCatalogs, 'Auto Generate Default Catalogs')
    .set(LocalizationKeys.ImportCatalogs, 'Import Existing Catalogs')
    .set(LocalizationKeys.Create, 'Create')
    .set(LocalizationKeys.Scenarios, 'Scenarios')
    .set(LocalizationKeys.Media, 'Media')
    .set(LocalizationKeys.Show, 'Show')
    .set(LocalizationKeys.Hide, 'Hide')
    .set(LocalizationKeys.Copy, 'Copy')
    .set(LocalizationKeys.Team, 'Team')
    .set(LocalizationKeys.AddTeamMember, 'Add Team Member')
    .set(LocalizationKeys.PendingInvites, 'Pending Invites')
    .set(LocalizationKeys.ConfigName, 'Config Name')
    .set(LocalizationKeys.AppName, 'App Name')
    .set(LocalizationKeys.ApiKey, 'Api Key')
    .set(LocalizationKeys.Project, 'Project')
    .set(LocalizationKeys.Edited, 'edited')
    .set(LocalizationKeys.Activity, 'Activity');
