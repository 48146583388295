import React, { useState } from 'react';
import { Text } from '../../core-ui/text';
import { IScriptDirectoryItem } from '../../shared/motive/models/ScriptDirectoryItem';
import { getLocalizedText } from '../../util/ObjectDefinitionsUtils';
import { RowItem } from '../../core-ui/rowItem';
import { Size } from '../../core-ui/constants/Size';
import { useStyle } from '../../shared/hooks/useStyle';
import { Collapsible } from '../../core-ui/collapse';
import { ScenarioEditor } from '../scenarioEditor';
import { ScenarioItemContainer } from '../../containers/scenarioItemContainer';
import { selectedScenarioStyle, scenarioRowStyle, scenarioStyle, scenarioEditorLayout } from './ScenarioItem.style';
import { scenarioTestId } from '../scenarios/Scenarios';
import { LoadingSkeleton } from '../../core-ui/loadingSkeleton/LoadingSkeleton';
import { Card } from '../../core-ui/card';
import { Button, ButtonVariant } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { DeleteModal } from '../../core-ui/deleteModal/DeleteModal';
import { IDeleteCatalogItemParams } from '../../shared/motive/hooks/useCatalogs/useDeleteCatalogItem/useDeleteCatalogItem';

interface IScenarioItemProps {
    scenario?: IScriptDirectoryItem;
    catalog?: ICatalog<IScriptObjectModel>;
    onClick?: (item: IScriptDirectoryItem) => void;
    onCancel?: () => void;
    onScenarioCreation?: () => void;
    cultureCode?: string;
    selectedScenarioId?: string;
}

export const ScenarioItem: React.FC<IScenarioItemProps> = ({
    scenario,
    catalog,
    onClick,
    onCancel,
    onScenarioCreation,
    cultureCode,
    selectedScenarioId
}): React.ReactElement => {
    const token = useStyle();
    const [isDeleteModalShowing, setDeleteModalShowing] = useState(false);
    const localizedTitle = scenario && cultureCode && getLocalizedText(scenario.localizedTitle, cultureCode);

    const TitleNode: React.ReactNode = scenario ? (
        <Text size={Size.LARGE}>{localizedTitle ? localizedTitle : 'Unnamed Scenario'}</Text>
    ) : (
        <LoadingSkeleton />
    );
    const selectedScenarioStyling =
        selectedScenarioId === scenario?.id && token ? selectedScenarioStyle(token) : undefined;

    const handleDelete = (deleteCatalogItem: IHookProvidedCall<IDeleteCatalogItemParams>) => {
        if (catalog && scenario) {
            deleteCatalogItem.execute({ catalogId: catalog.id, catalogItemId: scenario.id });

            setDeleteModalShowing(false);
        }
    };
    return (
        <>
            <ScenarioItemContainer>
                {props => (
                    <div css={scenarioRowStyle} data-testid={scenarioTestId}>
                        <RowItem
                            onClick={(): void => {
                                onClick && scenario && onClick(scenario);
                            }}
                            css={[scenarioStyle(token), selectedScenarioStyling]}
                        >
                            {() => ({
                                content: TitleNode,
                                rightContent: (
                                    <Button
                                        onClick={() => {
                                            setDeleteModalShowing(true);
                                        }}
                                        icon={IconTypes.DELETE}
                                        variant={ButtonVariant.HOLLOW}
                                    ></Button>
                                )
                            })}
                        </RowItem>

                        <DeleteModal
                            isOpen={isDeleteModalShowing}
                            onConfirm={() => handleDelete(props.deleteCatalogItem)}
                            onCloseModal={() => setDeleteModalShowing(false)}
                        >
                            {`Are you sure you want to delete the scenario "${localizedTitle}"?`}
                        </DeleteModal>

                        {scenario && catalog ? (
                            <Collapsible isShowing={selectedScenarioId === scenario.id}>
                                <Card css={scenarioEditorLayout(token)}>
                                    <ScenarioEditor
                                        catalog={catalog}
                                        scenario={scenario}
                                        onSave={props.saveScenario}
                                        onCancel={onCancel}
                                        onScenarioCreation={onScenarioCreation}
                                    ></ScenarioEditor>
                                </Card>
                            </Collapsible>
                        ) : (
                            <></>
                        )}
                    </div>
                )}
            </ScenarioItemContainer>
        </>
    );
};
