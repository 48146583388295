import React, { createContext, useState, Dispatch, SetStateAction, useContext } from 'react';
import { EditorCultureCode } from '../../../../constants/EditorCultureCode';

export interface ILanguageSettings {
    editorLanguage: EditorCultureCode;
    contentLanguage: string;
}

interface IEditorLocaleContextValue {
    languageSettings: ILanguageSettings;
    setLanguage: Dispatch<SetStateAction<ILanguageSettings>>;
}

export const EditorLocaleContext = createContext<IEditorLocaleContextValue>({
    languageSettings: {
        editorLanguage: EditorCultureCode.ENGLISH,
        contentLanguage: EditorCultureCode.ENGLISH
    },
    setLanguage: () => null
});

export const useCurrentLanguageSettings = () => {
    const { languageSettings } = useContext(EditorLocaleContext);

    return languageSettings;
};

export const useCurrentContentLanguage = () => {
    return useCurrentLanguageSettings().contentLanguage;
};

export const useCurrentEditorLanguage = () => {
    return useCurrentLanguageSettings().editorLanguage;
};

export const useSetContentLanguage = () => {
    const context = useContext(EditorLocaleContext);

    return (contentLanguage: string) => {
        context.setLanguage({
            contentLanguage: contentLanguage,
            editorLanguage: context.languageSettings.editorLanguage
        });
    };
};

export const EditorLocaleStore: React.FC = ({ children }): React.ReactElement => {
    const [language, setLanguage] = useState<ILanguageSettings>({
        editorLanguage: EditorCultureCode.ENGLISH,
        contentLanguage: EditorCultureCode.ENGLISH
    });

    return (
        <EditorLocaleContext.Provider value={{ languageSettings: language, setLanguage: setLanguage }}>
            {children}
        </EditorLocaleContext.Provider>
    );
};
