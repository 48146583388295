import { Text } from '../../core-ui/text';
import React from 'react';
import { Size } from '../../core-ui/constants/Size';
import { containerStyle, adaptiveButtonStyle } from './FieldEditorLabel.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { Button } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Icon } from '../../core-ui/icon';

interface IFieldEditorLabel {
    label: string | undefined | null;
    showAdaptiveToggle?: boolean;
    isAdaptiveShowing?: boolean;
    setIsVariable?: (isVariable: boolean) => void;
}
export const FieldEditorLabel: React.FC<IFieldEditorLabel> = ({
    label,
    showAdaptiveToggle,
    isAdaptiveShowing,
    setIsVariable
}) => {
    const token = useStyle();
    return (
        <div css={containerStyle(token)}>
            {showAdaptiveToggle && (
                <Button
                    //size={Size.SMALL}
                    onClick={() => {
                        setIsVariable?.(!isAdaptiveShowing);
                    }}
                >
                    <span css={adaptiveButtonStyle(token, isAdaptiveShowing)}>
                        <Icon icon={IconTypes.ATOM} />
                    </span>
                </Button>
            )}
            <Text size={Size.MEDIUM}>{label}</Text>
        </div>
    );
};
