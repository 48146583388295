import { css } from '@emotion/core';

export const homeIconStyle = css`
    margin-right: 12px;
`;

export const breadcrumbNameStyle = css`
    margin-left: -5px;
`;

export const breadcrumbItemStyle = (isFirst: boolean, isLast: boolean) => css`
    padding: 9px 12px;
    display: block;
    text-decoration: none;
    position: relative;
    height: 40px;
    text-align: center;
    margin-right: 24px;
    outline: none;
    box-sizing: border-box;
    cursor: pointer;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        border: 0 solid white;
        border-width: 20px 10px;
        width: 0;
        height: 0;
    }

    &:before {
        left: -20px;
        border-left-color: transparent;
    }

    &:after {
        left: 100%;
        border-color: lightgray;
        border-left-color: white;
    }

    &:hover {
        background-color: #f5f5f5;

        &:before {
            border-color: #f5f5f5;
            border-left-color: transparent;
        }

        &:after {
            border-left-color: #f5f5f5;
        }
    }

    ${isFirst &&
        `   
            &:before {
                border: none;
            }
        `}

    ${isLast &&
        `
            padding-right: 24px;
            border-right: none;

            &:after {
                border: none;
            }
    `}
`;

export const breadCrumbPanelStyle = css`
    background-color: white;
    border: 1px solid lightGray;
`;

export const breadCrumbWrapperStyle = css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
`;
