import { css } from '@emotion/core';
import { stretchWidth } from '../../constants/HelperStyles';

export const textAreaStyle = css`
    ${stretchWidth}
    height: 80px;
    font-family: inherit;
    resize: vertical;
    padding: 8px;
    border: 0.5px solid #ccc;
    background-color: #f0f0f0;
`;
