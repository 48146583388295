import React from 'react';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useScriptsRoute } from '../../routes/scriptsRoute/ScriptsRoute';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { IScriptsProps } from '../../components/scriptCatalogs/ScriptCatalogs';
import { CatalogsContainer } from '../catalogsContainer';
import { useCurrentProjectId } from '../../shared/motive/hooks/useProjectConfigs/useProjectConfigs';
import { useTranslation } from 'react-i18next';

interface IScriptCatalogsContainerProps {
    children?: (props: IScriptsProps) => React.ReactNode;
}

export const ScriptCatalogsContainer: React.FC<IScriptCatalogsContainerProps> = (): React.ReactElement => {
    const { goTo: goToScriptsRoute } = useScriptsRoute();
    const projectConfigId = useCurrentProjectId();
    const { t } = useTranslation();

    const handleScriptClicked = (catalogId: string, item: IScriptObjectModel) => {
        goToScriptsRoute({
            pathParams: {
                scriptId: item.id
            }
        });
    };

    return (
        <>
            {/* {children ? (
                children({
                    scriptCatalogs: scriptCats,
                    onScriptClicked: handleScriptClicked,
                    addScriptAction: addScript,
                    isLoading
                })
            ) : (
                <ScriptCatalogs
                    scriptCatalogs={scriptCats}
                    onScriptClicked={handleScriptClicked}
                    addScriptAction={addScript}
                    isLoading={isLoading}
                ></ScriptCatalogs>
            )} */}

            <CatalogsContainer
                whiteListTypes={[MotiveTypes.MOTIVE_SCRIPT]}
                projectConfigId={projectConfigId as string}
                allowQuickObjectEdit={false}
                allowQuickAddItem={true}
                collapsible={true}
                initializeOpen={true}
                itemsAreDraggable={true}
                showItemSearch={true}
                quickEditTitle={t('editScriptName')}
                quickAddTitle={t('addNewScript')}
                onSelectItem={handleScriptClicked}
            />
        </>
    );
};
