import { IToken } from '../../../shared/contexts/styleContext';
import { css } from '@emotion/core';

export const catalogTitleStyle = ({ colors, spacings }: IToken) => css`
    color: ${colors.font.primary};
    padding: ${spacings.smaller};
`;

export const catalogItemTextStyle = ({ spacings }: IToken) => css`
    padding: 0 calc(2 * ${spacings.smaller});
`;

export const flexFillSpaceStyle = css`
    flex: 1;
`;

export const flexButtonStyle = ({ spacings }: IToken) => css`
    margin-left: ${spacings.smallest};
    border-radius: 20px;
`;

export const iconStyle = (token: IToken) => css`
    color: ${token.colors.staticColors.black};
`;

export const pillIconStyle = (token: IToken) => css`
    color: ${token.colors.staticColors.white};
`;

export const textStyle = (token: IToken) => css`
    color: ${token.colors.staticColors.white};
    cursor: pointer;
`;

export const pillFlexChildrenStyle = css`
    display: flex;
    align-items: center;
`;

const dragOverStyle = (token: IToken) => `
    background-color:${token.colors.background.light};
    padding:-3px;
`;

export const itemDropStyle = (token: IToken, isDraggingOver: boolean) => css`
    width: 100%;
    ${isDraggingOver && dragOverStyle(token)}
`;

export const selectingTextStyle = (token: IToken) => css`
    font-size: ${token.fontSizes.small};
    background: ${token.colors.background.light};
    padding: ${token.spacings.smallest};
    border-radius: 20px;
`;

export const flexInlineWrapper = css`
    flex: 1;
`;

export const pillContainerStyle = css`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 20px;
`;
