/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { Button, ButtonVariant } from '../../core-ui/button';
import { Size } from '../../core-ui/constants/Size';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { QuickObjectEditor } from '../quickObjectEditor/QuickObjectEditor';
import { CatalogItemList } from '../catalogItemList';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { Collapsible } from '../../core-ui/collapse';
import { catalogQuickEditorButtonStyle } from './CatalogQuickEditor.style';
import { useStyle } from '../../shared/hooks/useStyle';
import { filterObjects } from '../../util/FilterUtils';
import { MediaUploadContainer } from '../../containers/mediaUploadContainer/MediaUploadContainer';
import { IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { useTranslation } from 'react-i18next';

export interface ICatalogQuickEditorProps {
    catalog: ICatalog<IScriptObjectModel>;
    onAddNewItem: (item: IScriptObjectModel) => void;
    onSaveItem: (item: IScriptObjectModel) => void;
    onDeleteItem?: (itemId: string, catalog?: ICatalog<IScriptObjectModel>) => void;
    onSelectItem?: (item: IScriptObjectModel) => void;
    onFileUpload?: (media: IHostedMediaSource) => void;
    showUploadZone?: boolean;
    onShowUploadZone?: () => void;
    allowQuickObjectEdit?: boolean;
    itemFilter?: string;
}

export const CatalogQuickEditor: React.FC<ICatalogQuickEditorProps> = ({
    catalog,
    onAddNewItem,
    onSaveItem,
    onDeleteItem,
    onSelectItem,
    onFileUpload = () => undefined,
    showUploadZone = false,
    onShowUploadZone,
    allowQuickObjectEdit,
    itemFilter
}) => {
    const [isAddingItem, setIsAddingItem] = useState(false);

    const handleSaveItem = (object: IScriptObjectModel) => {
        onSaveItem(object);
    };

    const handleAddNewItem = (object: IScriptObjectModel) => {
        setIsAddingItem(false);

        onAddNewItem(object);
    };

    const handleDeleteItem = (itemId: string) => {
        onDeleteItem && onDeleteItem(itemId, catalog);
    };

    const items = filterObjects(catalog?.items, itemFilter) ?? [];

    const token = useStyle();
    const { t } = useTranslation();
    return (
        <>
            <div>
                {catalog.objectType === MotiveTypes.MEDIA_ITEM && (
                    <>
                        <Button
                            variant={ButtonVariant.HOLLOW}
                            onClick={onShowUploadZone}
                            css={catalogQuickEditorButtonStyle(token)}
                            icon={IconTypes.UPLOAD}
                        >
                            {t('upload')}
                        </Button>
                        <Collapsible isShowing={showUploadZone}>
                            <MediaUploadContainer onUpload={onFileUpload} />
                        </Collapsible>
                    </>
                )}
                {catalog.items && (
                    <CatalogItemList
                        catalogId={catalog.id}
                        items={items}
                        onSelectItem={onSelectItem}
                        allowQuickEdit={allowQuickObjectEdit}
                        onSaveItem={handleSaveItem}
                        onDeleteItem={handleDeleteItem}
                        isDraggable={true}
                    />
                )}
            </div>
            {isAddingItem ? (
                <QuickObjectEditor
                    onSave={handleAddNewItem}
                    onCancel={() => setIsAddingItem(false)}
                    type={catalog.objectType}
                    title={t('newItem')}
                />
            ) : (
                <Button
                    css={catalogQuickEditorButtonStyle(token)}
                    variant={ButtonVariant.HOLLOW}
                    size={Size.MEDIUM}
                    icon={IconTypes.PLUS}
                    onClick={() => setIsAddingItem(true)}
                >
                    {t('newItem')}
                </Button>
            )}
        </>
    );
};
