/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector as standardCreateSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { IScriptEditorStore } from './ScriptEditorReducer';
import { ApplicationState } from '../../AppReducer';
import { IFrame, IScript } from '../../../shared/motive/models/Script';
import { isEqual } from 'lodash-es';
import { createSubSelector } from '../spaceKeyedSelectors';

const useCustomStateDiffFunctions = true;

const recursiveFastCheckFrames = (a: IFrame | null | undefined, b: IFrame | null | undefined) => {
    if (!isEqual(!!a, !!b)) {
        return false;
    }

    a = a as IFrame;
    b = b as IFrame;

    if (a.id !== b.id) {
        return false;
    }

    if (a.subFrames?.length !== b.subFrames?.length) {
        return false;
    }

    if (
        a.name !== b.name ||
        a.isExclusive !== b.isExclusive ||
        a.isLive !== b.isLive ||
        a.notes !== b.notes ||
        a.resetOnClose !== b.resetOnClose ||
        a.type !== b.type
    ) {
        return false;
    }

    // Compare on subframes.
    if (a.subFrames?.length !== b.subFrames?.length) {
        return false;
    }

    // Compare on resources
    if (a.resources?.length !== b.resources?.length) {
        return false;
    }

    // Variable field bindings
    if (!isEqual(a.objectFieldVariableBindings, b.objectFieldVariableBindings)) {
        return false;
    }

    if (!isEqual(a.selectorTree, b.selectorTree)) {
        return false;
    }

    if (a.subFrames && b.subFrames) {
        for (let i = 0; i < a.subFrames?.length; i++) {
            if (!recursiveFastCheckFrames(a.subFrames[i], b.subFrames[i])) {
                return false;
            }
        }
    }

    return true;
};

const createPerformantSelector = createSelectorCreator(defaultMemoize, (a: any, b: any) => {
    if (!a || !b) {
        return isEqual(a, b);
    }

    const checkProp = (prop: string) => {
        if (a.hasOwnProperty(prop) && b.hasOwnProperty(b)) {
            if (Array.isArray(a[prop]) && Array.isArray(b[prop]) && a[prop].length !== b[prop].length) {
                return false;
            }
            if (!isEqual(a[prop], b[prop])) {
                return false;
            }
        }

        return true;
    };

    if (
        !checkProp('selectedFrameId') ||
        !checkProp('id') ||
        !checkProp('type') ||
        !checkProp('lastModified') ||
        !checkProp('lastModifiedTime') ||
        !checkProp('variables') ||
        !checkProp('events') ||
        !checkProp('resources')
    ) {
        return false;
    }

    if (a['type'] === 'motive.core.frame' && b['type'] === 'motive.core.frame' && !!recursiveFastCheckFrames(a, b)) {
        return false;
    }

    if (
        a.hasOwnProperty('rootFrame') &&
        b.hasOwnProperty('rootFrame') &&
        !recursiveFastCheckFrames(a['rootFrame'], b['rootFrame'])
    ) {
        return false;
    }

    // Finally, just compare with lodash.
    return isEqual(a, b);
});

const createSelector = useCustomStateDiffFunctions ? createPerformantSelector : standardCreateSelector;

const EditorState = (state: ApplicationState) =>
    state.workspace.spaceKeyState[state.workspace.currentSpace as string]?.scriptEditor as IScriptEditorStore;

const ScriptState = (state: ApplicationState) =>
    state.workspace.spaceKeyState[state.workspace.currentSpace as string]?.scriptEditor?.script as IScript;

const SelectScriptEditorState = createSelector(EditorState, state => state);

export const SelectScriptEditorIsDirty = createSelector(SelectScriptEditorState, state => state.isDirty);
export const SelectScriptEditorCurrentScript = createSelector(ScriptState, script => script);
export const SelectScriptEditorSelectedFrameId = createSelector(
    SelectScriptEditorState,
    state => state?.selectedFrameId
);

export const SelectScriptEditorStateHistory = createSelector(SelectScriptEditorState, state => state?.stateHistory);
export const SelectScriptEditorFrameMap = createSelector(SelectScriptEditorState, state => state?.frameMap);
export const SelectScriptEditorFrame = (id: string) =>
    createPerformantSelector(SelectScriptEditorFrameMap, map => map[id]);

export const selectSignalledResource = createSubSelector(editorState => editorState.scriptEditor.externalHighlight);

export const SelectCurrentFrame = createSelector(
    SelectScriptEditorFrameMap,
    SelectScriptEditorSelectedFrameId,
    (map, curr) => map[curr]
);
