import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const catalogItemLayoutStyle = (token: IToken) => css`
    display: flex;
    flex-direction: column;
    min-height: 30px;
    padding: ${token.spacings.smaller};
    margin-bottom: 1px;
    background: ${token.colors.background.lighter};
    transition: 0.1s;
    font-size: ${token.fontSizes.medium};
    &:hover {
        background: ${token.colors.background.light};
    }
`;

export const catalogItemHeaderStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const mediaPlayerStyle = css`
    flex-basis: 100%;
    height: 100%;
    flex-wrap: wrap;

    & > video {
        max-width: 150px;
    }
`;

export const catalogItemThumbStyle = (token: IToken) => css`
    width: 80px;
    height: 40px;
    background: ${token.colors.background.neutral};
    border-radius: ${token.borderRadii.small};
    color: ${token.colors.font.tertiary};
    font-size: 1.1em;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${token.spacings.smaller};
    & > img {
        max-width: 100%;
        max-height: 100%;
    }
`;
export const scriptItemContentStyle = (token: IToken) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${token.colors.background.lighter};
    font-size: ${token.fontSizes.medium};
    position: relative;
    padding: ${token.spacings.smaller};
    margin-bottom: 1px;

    &:hover {
        background: ${token.colors.background.light};
    }
`;
export const catalogItemsOptionsWrapper = (token: IToken) => css`
    position: absolute;
    top: 30px;
    right: ${token.spacings.smaller};
    background: white;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: ${token.spacings.smaller};
    z-index: 100;
    box-shadow: 0px 0px 2px rgba(1, 1, 1, 0.2);
    overflow: visible;
`;
export const actionButtonsStyle = (token: IToken) => css`
display:flex;   
& > button{
        //background-color: ${token.colors.background.light};
       
        min-width: 20px;
        min-height:20px;
        max-width:20px;
        max-height:20px;
        height:20px;
        width:20px;
        font-size: ${token.fontSizes.small};
    }
`;

export const catalogItemMainContentStyle = css`
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const draggableContainerStyle = css`
    position: relative;
`;

export const draggableElementStyle = css`
    //position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

export const iconLayoutStyle = css`
    display: flex;
    flex-direction: row;
`;
