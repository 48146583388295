import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const loadingSpinner = (token: IToken) => css`
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid ${token.colors.interactible.primary};
    width: 120px;
    height: 120px;
    margin-left: 45%;
    margin-top: 15%;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
