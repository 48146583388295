import { css } from '@emotion/core';
import { ITransitionDetails } from './SimpleTransition';

export const fadeInStyle = ({ status, time }: ITransitionDetails) => css`
    transition: ${time}ms;
    opacity: 0;
    ${status === 'entered' && `opacity: 1;`}
`;

export const fadeOutStyle = ({ status, time }: ITransitionDetails) => css`
    transition: ${time}ms;

    ${status === 'exited' && `opacity: 0;`}
`;

export const fadeInOutStyle = (details: ITransitionDetails) => css`
    ${fadeInStyle(details)}
    ${fadeOutStyle(details)}
`;
