import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const conditionSectionStyle = (token: IToken, showConditions: boolean) => css`
    grid-area: conditions;
    padding: ${token.spacings.small};
    padding-bottom: 0;
    margin: ${token.spacings.smallest} 0 0 ${token.spacings.smallest};
    ${showConditions && `box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);`}
`;

export const conditionHeaderStyle = (token: IToken) => css`
    background: ${token.colors.bar.conditionBarHeader};
`;

export const resourceSectionStyle = (token: IToken, isResourceActive: boolean) => css`
    grid-area: resources;
    padding: ${token.spacings.small};
    margin: 0 0 0 ${token.spacings.smallest};
    ${isResourceActive && `box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);`}
`;

export const defaultVariableSectionStyle = (token: IToken, showVariables: boolean) => css`
    grid-area: defaults;
    padding: ${token.spacings.small};
    margin: ${token.spacings.smallest} ${token.spacings.smallest} 0 0;
    ${showVariables && `box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);`}
    @media only screen and (max-width: 1575px) {
        margin: 0 0 0 ${token.spacings.smallest};
    }
`;

export const bodySectionStyle = ({ spacings }: IToken) => css`
    margin-top: ${spacings.smallest};
    margin-left: ${spacings.smallest};
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    background: white;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
`;

export const bodySectionHeaderStyle = ({ spacings, colors }: IToken) => css`
    display: flex;
    text-transform: uppercase;
    justify-content: flex-start;
    cursor: pointer;
    align-items: center;
    color: ${colors.font.tertiary};
    position: sticky;
    top: 0;

    padding: ${spacings.smallest} ${spacings.smaller};
    margin-bottom: ${spacings.smaller};

    &:hover {
        filter: brightness(105%);
    }
`;

export const resourceHeaderStyle = (token: IToken) => css`
    background: ${token.colors.bar.resourceBarHeader};
`;
export const defaultVariableHeaderStyle = (token: IToken) => css`
    background: ${token.colors.bar.variableBarHeader};
`;
export const sectionHeaderIcons = css`
    margin-left: 10px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    height: 16px;
    overflow: hidden;
    margin-top: -3px;
`;

export const headerIcon = css`
    margin-left: 6px;
`;

export const chevronPosition = (token: IToken) => css`
    margin-right: ${token.spacings.smaller};
`;

export const flexStyleRow = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const workArea = css`
    padding: 16px;
    max-width: 500px;
    min-width: 280px;
    margin: 0 auto;
`;

export const workAreaLayout = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const workAreaNarrow = css`
    padding: 5px;
    max-width: 500px;
    margin: 0 auto;
`;

export const displayNoneStyle = css`
    display: none;
`;

export const mfIconStyle = css`
    display: block;
    text-align: center;
    margin: 0 10px;
    font-size: 5rem;
    color: lightgrey;
`;
export const message = css`
    display: flex;
    align-items: center;
    widht: 100%;
`;

export const containerHeaderStyle = css`
    font-size: 18px;
`;

export const frameBackgroundStyle = (isEnabled: boolean) => css`
    ${!isEnabled ? 'opacity:0.5;' : ''}
`;

export const expandSection = (expand: boolean) => css`
    transition: height 0.1s linear;
    ${expand && `height: 60px;`}
    ${!expand && `transition: height 0s; height: 0px;`}
`;
