import React from 'react';
import { baseCheckboxStyle, baseLabelStyle } from './Checkbox.style';
import { Checkbox as ChakraCheckbox } from '@chakra-ui/core';
import { CheckboxSize, Size } from '../constants/Size';
import { Text } from '../text';
import { getChakraSize } from '../utils/SizeMapping';
import { useStyle } from '../../shared/hooks/useStyle';

interface ICheckboxProps {
    className?: string;
    onClick?: () => void;
    isChecked: boolean;
    isDisabled?: boolean;
    color?: string;
    size?: CheckboxSize;
    labelText?: string;
    dataTestId?: string;
}

export const DATA_TEST_ID = 'checkbox';

export const Checkbox: React.FC<ICheckboxProps> = ({
    onClick,
    size = Size.MEDIUM,
    color,
    isDisabled,
    labelText,
    isChecked,
    className,
    dataTestId = DATA_TEST_ID
}): React.ReactElement => {
    const handleCheckboxClick = () => {
        !isDisabled && onClick && onClick();
    };

    const token = useStyle();

    return (
        <>
            <ChakraCheckbox
                data-testid={dataTestId}
                onChange={handleCheckboxClick}
                css={baseCheckboxStyle(token)}
                size={getChakraSize(size) as 'sm' | 'md' | 'lg'}
                variantColor={color}
                isChecked={isChecked}
                className={className}
            />
            {labelText && (
                <Text css={baseLabelStyle} size={size}>
                    {labelText}
                </Text>
            )}
        </>
    );
};
