import React, { useContext } from 'react';
import { DashboardTab } from '../../components/dashboardNavBar';

interface IProjectDashboardContextValue {
    selectedTab: DashboardTab;
    setSelectedTab: (tab: DashboardTab) => void;
    onDashItemSelected: (tab: DashboardTab, itemName: string) => void;
}

const projectDashboardContext = React.createContext<IProjectDashboardContextValue>({
    selectedTab: 'Scenarios',
    setSelectedTab: () => undefined,
    onDashItemSelected: () => undefined
});

export const ProjectDashboardContextProvider = projectDashboardContext.Provider;
export const useProjectDashboard = () => useContext(projectDashboardContext);
