import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const createProjectInputField = css`
    position: relative;
    top: 30%;
    transform: translateY(-30%);
    margin: auto;
    width: 60%;
    height: 50%;
`;

export const createProjectStyle = (token: IToken) => css`
    margin-left: ${token.spacings.smallest};
`;

export const inputFieldStyle = (token: IToken) => css`
    border: 2px solid ${token.colors.interactibleBorder.tertiary};
    padding: ${token.spacings.small} ${token.spacings.medium};
    font-size: 24px;
    margin: ${token.spacings.small} 0;
    border-radius: 5px 0px 0px 5px;

    &:hover {
        border: 2px solid ${token.colors.interactibleBorder.secondary};
    }

    &:focus {
        border: 2px solid ${token.colors.interactibleBorder.secondary};
    }
`;

export const formStyle = css`
    margin-bottom: 16px;
    width: 50%;
`;

export const importSectionToggle = (token: IToken) => css`
    margin-bottom: ${token.spacings.small};
    user-select: none;

    display: flex;
    padding: ${token.spacings.small} ${token.spacings.smaller};
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    &::selection {
        color: none;
        background: none;
    }
`;

export const importSection = (token: IToken) => css`
    margin-bottom: ${token.spacings.medium};
    user-select: none;

    padding: ${token.spacings.medium};
    border: 1px solid lightgray;
`;
const transformStyle = (open: boolean) => `rotate(${open ? '0deg' : '-90deg'})`;
const transitionStyle = `0.15s ease-in-out`;

export const rotateChevron = (open: boolean) => css`
    transition: ${transitionStyle};
    transform: ${transformStyle(open)};
    align-items: center;
    margin-right: 6px;
`;

export const fieldGrid = css`
    display: grid;
    grid-template-columns: 80% 20%;
    align-items: center;
`;
export const createButton = css`
    border-radius: 0px 5px 5px 0px;
    height: 64px;
    min-height: 64px;
    max-height: 64px;
    padding: 20px;
    font-size: 1.2em;
    &:hover {
        filter: brightness(110%);
    }
`;

export const importerText = (tokens: IToken) => css`
    margin-left: ${tokens.spacings.smaller};
`;

export const importerItem = (tokens: IToken) => css`
    margin: ${tokens.spacings.small} 0;
`;
