import { IDrawerProps } from '../../core-ui/drawer';
import { useStyle } from '../../shared/hooks/useStyle';
import { menuStyle, drawerLayoutStyle, drawerPanelStyle } from './DashboardDrawer.style';
import React, { useContext } from 'react';
import { DashboardDrawerHeader } from '../dashboardDrawerHeader';
import { DrawerHistoryButtonsContainer } from '../../containers/drawerHistoryButtonsContainer/DrawerHistoryButtonsContainer';
import { DrawerSideMenuContainer } from '../../containers/drawerSideMenuContainer';
import { IStackItem, useUIStack } from '../../shared/hooks/useUIStack';
import { UIContext } from '../../stores/uiStore/UiStore';

export interface IDashboardDrawerProps extends IDrawerProps {
    selectedTitleElement: JSX.Element;
    isXSorOff: boolean;
}

export interface IDashboardDrawerPropsSimple {
    selectedTitleElement: JSX.Element;
    onClickClose: () => void;
    isShowing: boolean;
}

const BOTTOM_MARGIN = 15;
export const DashboardDrawer: React.FC<IDashboardDrawerPropsSimple> = ({
    children,
    selectedTitleElement,
    onClickClose,
    isShowing
}) => {
    const tokens = useStyle();
    const { pushItem } = useUIStack();
    const uiContext = useContext(UIContext);

    const handlePushItem = (stackItem: IStackItem) => {
        pushItem('clearExisting', stackItem);
    };

    const handleRepeatClick = () => {
        pushItem('clearExisting', undefined);
        uiContext.setDrawerIsOpen(false);
    };

    return (
        <div css={drawerLayoutStyle}>
            {isShowing && (
                <div css={drawerPanelStyle(tokens, BOTTOM_MARGIN)}>
                    <div>
                        <DashboardDrawerHeader
                            //drawerSize={size}
                            selectedTitleElement={selectedTitleElement}
                            onClickClose={onClickClose}
                        >
                            <DrawerHistoryButtonsContainer></DrawerHistoryButtonsContainer>
                        </DashboardDrawerHeader>
                    </div>
                    <div>{children}</div>
                </div>
            )}
            <DrawerSideMenuContainer pushItem={handlePushItem} onRepeatClick={handleRepeatClick} css={menuStyle} />
        </div>
    );

    /*
    return (
        <Drawer
            size={size}
            minSize={minSize}
            placement={placement}
            setVisibleDrawerRef={setVisibleDrawerRef}
            onOverlayClick={onOverlayClick}
            onDrawerHandleClick={onDrawerHandleClick}
            defaultHeightFromTop={defaultHeightFromTop}
            defaultWidthFromRight={defaultWidthFromRight}
            anchorRender={<DrawerSideMenuContainer />}
        >
            <div css={allDrawerContentContainerStyle(tokens, isXSorOff)}>
                <DashboardDrawerHeader
                    drawerSize={size}
                    selectedTitleElement={selectedTitleElement}
                    onClickClose={onOverlayClick}
                />
                <DrawerHistoryButtonsContainer />
                {children}
            </div>
        </Drawer>
    );
        */
};
