import { Action } from 'redux';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IStateHistoryNode<T> {
    previous: IStateHistoryNode<T> | undefined;
    state: T;
    next: IStateHistoryNode<T> | undefined;
    isRoot: boolean;
    isDefault: boolean;
    hasBack: () => boolean;
    hasForward: () => boolean;
    undo: (dispatchStateUpdate: (state: T) => void) => void;
    redo: (dispatchStateUpdate: (state: T) => void) => void;
}

export const getDefaultStateHistory = <T extends IStateHistoryTrackable<T>>(): IStateHistoryNode<T> => {
    return {
        hasBack: () => false,
        hasForward: () => false,
        isRoot: true,
        next: undefined,
        previous: undefined,
        isDefault: true,
        redo: () => {},
        state: ({} as unknown) as T,
        undo: () => {}
    };
};

const hasBack = <T extends IStateHistoryTrackable<T>>(state: IStateHistoryNode<T>): boolean => {
    return !state.isRoot && !state.isDefault && !!state.previous;
};

const hasForward = <T extends IStateHistoryTrackable<T>>(state: IStateHistoryNode<T>): boolean => {
    return !!state.next;
};

const trackStateUpdate = <T extends IStateHistoryTrackable<T>>(state: IStateHistoryNode<T>, trackerState: T): T => {
    const next: IStateHistoryNode<T> = {
        ...state,
        isDefault: false,
        previous: !state.isDefault ? state : undefined,
        next: undefined,
        isRoot: state.isDefault,
        state: trackerState,
        // Self ref workaround
        undo: () => {},
        redo: () => {},
        hasBack: () => false,
        hasForward: () => false
    };

    next.undo = (dispatchStateUpdate: (state: T) => void) => goBack(next, dispatchStateUpdate);
    next.redo = (dispatchStateUpdate: (state: T) => void) => goForward(next, dispatchStateUpdate);
    next.hasBack = () => hasBack(next);
    next.hasForward = () => hasForward(next);

    return {
        ...trackerState,
        stateHistory: next
    };
};

const goBack = <T extends IStateHistoryTrackable<T>>(
    current: IStateHistoryNode<T>,
    dispatchStateUpdate: (state: T) => void
): void => {
    if (!current.hasBack()) {
        return;
    }

    if (!current.previous) {
        throw new Error('Can not go back if no previous state exists.');
    }

    const next = {
        ...current.previous,
        next: {
            ...current
        }
    };

    // Merge our methods back in for correct scope
    next.undo = (dispatchStateUpdate: (state: T) => void) => goBack(next, dispatchStateUpdate);
    next.redo = (dispatchStateUpdate: (state: T) => void) => goForward(next, dispatchStateUpdate);
    next.hasBack = () => hasBack(next);
    next.hasForward = () => hasForward(next);

    const nextState = {
        ...current.previous.state,
        stateHistory: next
    };

    dispatchStateUpdate(nextState);
};

const goForward = <T extends IStateHistoryTrackable<T>>(
    state: IStateHistoryNode<T>,
    dispatchStateUpdate: (state: T) => void
): void => {
    if (!state.hasForward()) {
        return;
    }

    if (!state.next) {
        throw new Error('Can not go back if no previous state exists.');
    }

    const next: IStateHistoryNode<T> = {
        ...state.next,
        previous: {
            ...state
        }
    };

    // Merge our methods back in for correct scope
    next.undo = (dispatchStateUpdate: (state: T) => void) => goBack(next, dispatchStateUpdate);
    next.redo = (dispatchStateUpdate: (state: T) => void) => goForward(next, dispatchStateUpdate);
    next.hasBack = () => hasBack(next);
    next.hasForward = () => hasForward(next);

    dispatchStateUpdate({
        ...next.state,
        stateHistory: next
    });
};

export enum StateHistoryAction {
    UPDATE = 'STATE_HISTORY_UPDATE'
}

export interface IStateHistoryUpdate<T> extends Action<StateHistoryAction> {
    state: T;
    // TODO get rid of this
    operation: StateHistoryAction;
}

export interface IStateHistoryTrackable<T extends IStateHistoryTrackable<T>> {
    stateHistory: IStateHistoryNode<T>;
}

export const trackStateHistoryReducer = <T extends IStateHistoryTrackable<T>, K extends IStateHistoryUpdate<T>>(
    reducer: (...args: any[]) => T,
    action: K,
    blacklistActions: any[] = [],
    ...args: any[]
): T => {
    const nextState = reducer(...args);

    if ((action && action.type === StateHistoryAction.UPDATE) || blacklistActions.some(c => c === action.type)) {
        return nextState;
    }

    return { ...trackStateUpdate(nextState.stateHistory, nextState) };
};
