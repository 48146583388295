import React, { memo, useState } from 'react';
import { IFieldDefinition, IObjectDefinition, IFields } from '../../shared/motive/models/TypeDefinition';
import { FieldEditor } from './fieldEditor';
import { FieldTypes, IScriptObjectModel, getObjectDict } from '../../shared/motive/models/ScriptObjectModel';
import { useObjectDefinition } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { orderBy } from 'lodash-es';
import { IFrame, IResourceWrapper, IScript } from '../../shared/motive/models/Script';
import { IDynamicValue } from '../../shared/motive/models/IDynamicValue';
import { AdaptiveFieldEditor } from './fieldEditor/AdaptiveFieldEditor';
import { NAMES_NON_EDITABLE_FIELDS } from '../../util/MotiveUtils';
import { CustomObjectEditorFactory } from './customEditor';
import { EditModeAware } from '../../components/editableOnFocus/EditModeAware';
import { FieldEditorLabel } from '../../components/fieldEditorLabel';
import { fieldLayoutStyle, fieldRowStyle } from './ObjectEditor.style';
import { getFieldVariableId, fieldHasVariable, removeFieldVariable } from '../../util/ScriptDynamicsUtil';
import { useStyle } from '../../shared/hooks/useStyle';
import { dispatchUpdateFrame } from '../../shared/motive/reducers/ScriptEditorReducers';
import { useDispatch } from 'react-redux';

export const ExternalFrameEditorTestId = 'ExternalFrameEditor';
export const ExternalScriptEditorTestId = 'ExternalScriptEditor';
export const ExternalGlobalEditorTestId = 'ExternalGlobalEditor';
export const EnumEditorTestId = 'EnumEditor';
export const FieldEditorTestId = 'FieldEditor';
export const AbstractEditorTestId = 'AbstractEditor';
export const CustomEditorTestId = 'CustomEditor';
export const NoEditorTestId = 'NoEditor';

export type RenderFieldEditorCallback = (
    label: string,
    renderEditor: () => React.ReactElement | undefined,
    value?: FieldTypes,
    layoutHint?: FieldLayoutHint
) => React.ReactElement;

export type RenderFieldCallback = (
    objectProperties: IObjectEditorProps,
    fieldDef: IFieldDefinition,
    fieldValue: FieldTypes,
    customizeProps?: (props: IFieldEditorProps<FieldTypes>) => IFieldEditorProps<FieldTypes>
) => React.ReactElement;

export interface IEditorPropsBase {
    type: string;
    path?: string;
    isReadOnly?: boolean;
    frame?: IFrame;
    script?: IScript;
}

export interface IEditorProps<T extends FieldTypes> extends IEditorPropsBase {
    value?: T;
}

export interface IFieldEditorProps<T extends FieldTypes> extends IEditorProps<T> {
    fieldDefinition: IFieldDefinition;
    onChange: (updatedValue: FieldTypes, index?: number | undefined) => void;
    parentObjectEditorProps: IObjectEditorProps<IScriptObjectModel>;
    className?: string;
    overrideImplementors?: string[];
}

export interface IObjectEditorProps<T extends IScriptObjectModel = IScriptObjectModel> extends IEditorProps<T> {
    objectDefinition?: IObjectDefinition;
    onChange: (fieldPath: string, updatedValue: FieldTypes) => void;
    hideAdvanced?: boolean;
    showFields?: string[];
    hideFields?: string[];
    customOverrideMap?: Map<string, React.FC<IFieldEditorProps<FieldTypes>>>;
    customFieldMap?: Map<string, React.FC<IFieldEditorProps<FieldTypes>>>;
    depth?: number;
    resourceWrapper?: IResourceWrapper;
    editingVariableId?: string;
    onClickSetEventId?: ((eventId: string) => void) | undefined;
}

export interface IObjectEditorRendererProps<T extends IScriptObjectModel = IScriptObjectModel>
    extends IObjectEditorProps<T> {
    renderField: RenderFieldCallback;
    renderFieldEditor: RenderFieldEditorCallback;
}

export type FieldLayoutHint = 'inline' | 'newline';

const sortFieldDefinition = (fields: IFields): IFieldDefinition[] => {
    return orderBy(
        Object.values(fields),
        [
            (Field: IFieldDefinition): number => {
                return Field?.editorInfo?.index ?? 999;
            }
        ],
        ['asc']
    );
};

export function shouldShowField(
    fieldDef: IFieldDefinition,
    isScript: boolean,
    showFields?: string[],
    hideFields?: string[]
) {
    if (!isScript && fieldDef.referenceScope === 'script') {
        return false;
    }

    return (!showFields || showFields.includes(fieldDef.name)) && !hideFields?.includes(fieldDef.name);
}

const removeNonEditableFields = (
    fieldDefs: IFieldDefinition[],
    isScript: boolean,
    showFields?: string[],
    omittedFields?: string[]
): IFieldDefinition[] => {
    var hiddenFields = omittedFields ? NAMES_NON_EDITABLE_FIELDS.concat(omittedFields) : NAMES_NON_EDITABLE_FIELDS;

    return fieldDefs.filter(field => shouldShowField(field, isScript, showFields, hiddenFields));
};

const renderFieldEditor = (
    label: string,
    renderEditor: () => React.ReactElement | undefined,
    value?: FieldTypes,
    layoutHint?: FieldLayoutHint | undefined,
    showAdaptiveToggle?: boolean,
    isAdaptiveShowing?: boolean,
    setIsVariable?: (isVariable: boolean) => void
): React.ReactElement => {
    const renderEditMode = () => {
        return (
            <div css={fieldLayoutStyle(layoutHint)}>
                <FieldEditorLabel
                    label={label}
                    showAdaptiveToggle={showAdaptiveToggle}
                    isAdaptiveShowing={isAdaptiveShowing}
                    setIsVariable={setIsVariable}
                />
                {renderEditor()}
            </div>
        );
    };

    const renderReadonlyMode = () => {
        if (value) {
            return <></>;
        }

        return (
            <div>
                <b>
                    <FieldEditorLabel
                        label={label}
                        showAdaptiveToggle={showAdaptiveToggle}
                        isAdaptiveShowing={isAdaptiveShowing}
                    />
                </b>
                <span>: </span>
                {renderEditor()}
            </div>
        );
    };

    return <EditModeAware key={label} readonlyModeRender={renderReadonlyMode} editModeRender={renderEditMode} />;
};

const RenderField: React.FC<{
    objectProperties: IObjectEditorProps;
    fieldDef: IFieldDefinition;
    fieldValue: FieldTypes;
    customizeProps?: (props: IFieldEditorProps<FieldTypes>) => IFieldEditorProps<FieldTypes>;
}> = ({ objectProperties, fieldDef, fieldValue, customizeProps }) => {
    const token = useStyle();

    //Appends the path so we can keep a track of where we are in the definition
    let path = objectProperties.path ? `${objectProperties.path}.${fieldDef.name}` : fieldDef.name;
    const dynamicFieldName = fieldDef.name;

    if (fieldDef.typeName === 'dynamic') {
        const dynVal = fieldValue as IDynamicValue;

        if (!dynVal?.valueDefinition) {
            throw new Error("Can't render dynamic value without a value definition");
        }

        // Override fieldDef to take on the values of valueDef
        fieldDef = {
            ...fieldDef,
            ...dynVal.valueDefinition,
            name: 'value',
            editorInfo: fieldDef.editorInfo
        };

        path = `${path}.value`;

        fieldValue = dynVal.value;
    }

    const isVariable =
        !!objectProperties.frame &&
        !!objectProperties.value?.id &&
        fieldHasVariable(objectProperties.frame, objectProperties.value.id, dynamicFieldName);

    const [adaptiveViewIsToggled, setAdaptiveViewIsToggled] = useState(isVariable);

    const scriptDispatch = useDispatch();

    if (!fieldDef) {
        return <>Undefined field definition</>;
    }

    // Callers may use this without filtering the show/hide fields: repeat that here
    if (
        !shouldShowField(fieldDef, !!objectProperties.frame, objectProperties.showFields, objectProperties.hideFields)
    ) {
        return <></>;
    }

    let fieldProps: IFieldEditorProps<FieldTypes> = {
        ...objectProperties,
        fieldDefinition: fieldDef,
        type: fieldDef.typeName,
        path: path,
        value: fieldValue,
        onChange: _t => {
            if (objectProperties.onChange) {
                objectProperties.onChange(path, _t);
            }
        },
        parentObjectEditorProps: objectProperties
    };

    fieldProps = customizeProps ? customizeProps(fieldProps) : fieldProps;

    const obj = objectProperties.value as IScriptObjectModel;
    const showAdaptiveToggle = !!(objectProperties.frame && !fieldDef.isOutput);
    let showField: boolean;
    // Need a toggle on global advanced fields
    const globalShowAdvanced = false;
    const frame = objectProperties.frame as IFrame;
    const elemKey = fieldDef.name;

    const scriptVariableId = getFieldVariableId(frame, obj.id, dynamicFieldName);
    //setIsVariable(!!scriptVariableId);

    // Always show fields if there is a value present.
    showField = isVariable || fieldProps.value !== undefined;

    if (!objectProperties.isReadOnly) {
        showField =
            showField || !fieldDef.editorInfo?.isAdvanced || globalShowAdvanced || !objectProperties.hideAdvanced;
    }

    const handleToggleAdaptiveView = (showAdaptive: boolean) => {
        if (showAdaptive) {
            setAdaptiveViewIsToggled(true);
        } else {
            setAdaptiveViewIsToggled(false);

            const newFrame = removeFieldVariable(frame, obj.id, dynamicFieldName);

            dispatchUpdateFrame(scriptDispatch, newFrame);
        }
    };

    const renderAdaptiveEditor = () => {
        return (
            <AdaptiveFieldEditor
                key={`${obj.id}.${fieldDef.name}`}
                dynamicFieldName={dynamicFieldName}
                variableId={scriptVariableId}
                {...fieldProps}
                showSelector={scriptVariableId !== objectProperties.editingVariableId}
            />
        );
    };

    const showAdaptiveView = adaptiveViewIsToggled;

    const renderEditorInternal = (
        label: string,
        renderEditor: () => React.ReactElement | undefined,
        value?: FieldTypes,
        layoutHint?: FieldLayoutHint | undefined
    ): React.ReactElement => {
        return renderFieldEditor(
            label,
            showAdaptiveView ? renderAdaptiveEditor : renderEditor,
            value,
            isVariable ? 'newline' : layoutHint,
            showAdaptiveToggle,
            showAdaptiveView,
            handleToggleAdaptiveView
        );
    };

    return (
        <React.Fragment key={elemKey}>
            {showField && (
                <>
                    <div css={fieldRowStyle(token, showAdaptiveView)}>
                        <FieldEditor
                            key={`${obj.id}.${fieldDef.name}`}
                            {...fieldProps}
                            renderEditor={renderEditorInternal}
                        />
                    </div>
                </>
            )}
        </React.Fragment>
    );
};

// Callback to map function below that takes a field def and the index
// in the field array.
const renderFieldFactory = (objectProperties: IObjectEditorProps<IScriptObjectModel>) => (
    fieldDefProp: IFieldDefinition
): React.ReactElement => {
    const fieldDef: IFieldDefinition = fieldDefProp;

    const dict = getObjectDict(objectProperties.value);
    const fieldValue = dict ? dict[fieldDef.name] : undefined;

    return (
        <RenderField
            key={`${objectProperties.value?.id}.${fieldDef.name}`}
            objectProperties={objectProperties}
            fieldDef={fieldDef}
            fieldValue={fieldValue}
        />
    );
};

const DefaultObjectEditorRenderer: React.FC<IObjectEditorRendererProps> = (
    props: IObjectEditorRendererProps
): React.ReactElement => {
    const objectDefinition = useObjectDefinition(props.type);

    if (!objectDefinition) {
        return <></>;
    }

    const sortedFields = removeNonEditableFields(
        sortFieldDefinition(objectDefinition.fieldDefinitions),
        !!props.frame,
        props.showFields,
        props.hideFields
    );

    const renderMap = renderFieldFactory(props);

    return <>{sortedFields.map(renderMap)}</>;
};

/**
 * @typeId The type id of the schema you want to build, e.g. 'motive.gaming.playableContent'
 * @typeValues The values that corresponds to the schema for the typeId
 * @fieldDefinition The field definitions that are exposed from a parent ObjectEditor
 * @isReadOnly If the read only mode is turned on (resources only)
 * @isAdvanced If the advanced toggle is turned on (resources only)
 * @path The path denotes how to get from the root of the object to its current position
 * @onChange When an editor makes a change it should call onChange with the state representing the new values, it should call undefined if it wants to unset the value
 * @customOverrideMap Optional overrides for custom components
 */
const ObjectEditorPrivate: React.FC<IObjectEditorProps<IScriptObjectModel>> = (
    props: IObjectEditorProps<IScriptObjectModel>
): React.ReactElement => {
    const objectDefinition = useObjectDefinition(props.type);

    if (!objectDefinition) {
        return <></>;
    }

    const customInfo = CustomObjectEditorFactory(props.type);
    const CustomEditor = customInfo?.editor;

    const getShowFields = () => {
        if (customInfo?.showFields && props.showFields) {
            return customInfo.showFields.filter(f => props.showFields?.includes(f));
        }

        return customInfo?.showFields ?? props.showFields;
    };

    const renderFieldInternal = (
        objectProperties: IObjectEditorProps,
        fieldDef: IFieldDefinition,
        fieldValue: FieldTypes,
        customizeProps?: (props: IFieldEditorProps<FieldTypes>) => IFieldEditorProps<FieldTypes>
    ): React.ReactElement => {
        return (
            <RenderField
                key={`${objectProperties.value?.id}.${fieldDef.name}`}
                objectProperties={objectProperties}
                fieldDef={fieldDef}
                fieldValue={fieldValue}
                customizeProps={customizeProps}
            />
        );
    };

    const renderEditorInternal: RenderFieldEditorCallback = (
        label: string,
        renderEditor: () => React.ReactElement | undefined,
        value?: FieldTypes,
        layoutHint?: FieldLayoutHint
    ): React.ReactElement => {
        return renderFieldEditor(label, renderEditor, value, layoutHint);
    };

    const childProps: IObjectEditorRendererProps = {
        renderField: renderFieldInternal,
        renderFieldEditor: renderEditorInternal,
        ...props
    };

    return (
        <>
            {CustomEditor ? (
                <CustomEditor key={props.value?.id} {...childProps} showFields={getShowFields()} />
            ) : (
                <DefaultObjectEditorRenderer {...childProps} showFields={getShowFields()} />
            )}
        </>
    );
};

// eslint-disable-next-line react/display-name
export const ObjectEditor: React.FC<IObjectEditorProps<IScriptObjectModel>> = memo(props => (
    <ObjectEditorPrivate {...props} />
));
