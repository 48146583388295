/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import Axios, { AxiosInstance, AxiosResponse } from 'axios';
export const BASE_URL = process.env.REACT_APP_MOTIVE_BASE_URL as string;
const TIMEOUT = process.env.REACT_APP_TIMEOUT ? parseInt(process.env.REACT_APP_TIMEOUT, 10) : 20000;

interface IHTTPClient extends AxiosInstance {}

export interface IHTTPResponse extends AxiosResponse {}

export const httpClient: IHTTPClient = Axios.create({
    baseURL: BASE_URL,
    timeout: TIMEOUT,
    validateStatus: status => {
        if (status === 401 || status === 403) {
        }

        return status === 200;
    },
    withCredentials: true
});

export const longRunningHttpClient: IHTTPClient = Axios.create({
    baseURL: BASE_URL,
    validateStatus: status => {
        if (status === 401 || status === 403) {
        }

        return status === 200;
    },
    withCredentials: true
});

export const getErrorResponse = (error: Error) => {
    return (error as any)?.response as AxiosResponse;
};

export const getErrorStatusCode = (error: Error) => {
    return getErrorResponse(error)?.status;
};
