import { get } from 'lodash-es';
import { useEffect } from 'react';
import {
    useClearRouteToTab,
    useNavbarCurrentTabId,
    useNavbarRouteToTab,
    useNavbarSetCurrentTab,
    useNavbarTabs
} from '../../redux/navbar/NavbarHooks';
import { useScriptsRouteMatch } from '../scriptsRoute/ScriptsRoute';

export const useRouteTabSynchronization = () => {
    const selectTab = useNavbarSetCurrentTab();
    const currentTabId = useNavbarCurrentTabId();
    const scriptsMatch = useScriptsRouteMatch();
    const routeToTabId = useNavbarRouteToTab();
    const clearRouteToTab = useClearRouteToTab();
    const tabs = useNavbarTabs();

    // Keep tabs in sync with routing
    useEffect(() => {
        if (!scriptsMatch) {
            selectTab('project');
        }
    }, [scriptsMatch]);
};
