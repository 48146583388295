import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const rowItemStyle = (token: IToken, hasOnClick?: boolean) => css`
    border-bottom: 1px solid ${token.colors.border.secondary};
    ${hasOnClick &&
        `cursor: pointer; &:hover 
    { 
        ${token.colors.background.lighter}; 
    }`}
`;

export const gridRowStyle = css`
    padding-left: 15px;
    display: grid;
    grid-template-columns: 0.5fr 50% 3fr 3fr 3fr;
`;

export const thumbnailWrapperStyle = (token: IToken) => css`
    height: 60px;
    width: 60px;
    background-color: ${token.colors.background.lighter};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
`;

export const mediaItemPreviewStyle = (token: IToken) => css`
    width: 100%;
    color: ${token.colors.background.dark};
`;

export const textTitleWrapperStyle = (token: IToken) => css`
    padding: 0px ${token.spacings.small};
    align-self: center;
`;

export const textMetadataWrapperStyle = (token: IToken) => css`
    padding: 0px ${token.spacings.small};
    align-self: center;
    justify-self: center;
`;
