import { AccountRoute } from './accountRoute/AccountRoute';
import { ScenariosRoute } from './scenariosRoute';
import { ProjectsRoute } from './projectsRoute';
import { SpacesRoute } from './spacesRoute';
import { routeFactory, IRoute } from '../shared/routing';
import { PublishingRoute } from './publishingRoute';
import { CatalogsRoute } from './catalogsRoute';
import { ScriptsRoute } from './scriptsRoute';
import { MediaRoute } from './mediaRoute/MediaRoute';
import { BackupRoute } from './backupRoute';
import { DashboardRoute } from './dashboardRoute';
import { ConvoAIRoute } from './convoAIRoute';
import { ConvoAIIntentsRoute } from './convoAIIntentsRoute';
import { ConvoAIAgentManagementRoute } from './convoAIAgentRoute';
import { ConvoAIAgentCreationRoute } from './convoAIAgentCreationRoute';

enum KeyEnum {
    CatchAll,
    Login,
    Spaces,
    Projects,
    Scenarios,
    Catalogs,
    Publishing,
    Scripts,
    Media,
    Backup,
    Dashboard,
    Account,
    ConvoAI,
    ConvoAIIntentManagement,
    ConvoAIAgentManagement,
    ConvoAIAgentCreation
}
export type EditorRouteKey = keyof typeof KeyEnum;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEditorRoute extends IRoute<EditorRouteKey> {}

export const [routes, routeMap] = routeFactory<EditorRouteKey>([
    {
        key: 'Spaces',
        path: '/spaces/:spaceName?',
        component: SpacesRoute,
        subRoutes: [
            {
                key: 'Projects',
                path: '/projects/:projectName?',
                component: ProjectsRoute,
                subRoutes: [
                    {
                        key: 'Dashboard',
                        path: '/dashboard',
                        component: DashboardRoute,
                        subRoutes: [
                            {
                                key: 'Scenarios',
                                path: '/scenarios/:scenarioName?',
                                component: ScenariosRoute
                            },
                            {
                                key: 'Catalogs',
                                path: '/catalogs/:catalogName?',
                                component: CatalogsRoute
                            },
                            {
                                key: 'Publishing',
                                path: '/publishing',
                                component: PublishingRoute
                            },

                            {
                                key: 'Media',
                                path: '/media',
                                component: MediaRoute
                            },
                            {
                                key: 'Backup',
                                path: '/backup',
                                component: BackupRoute
                            },
                            {
                                key: 'ConvoAI',
                                path: '/convoAI',
                                component: ConvoAIRoute,
                                subRoutes: [
                                    {
                                        key: 'ConvoAIIntentManagement',
                                        path: '/intents/:catalogId?/:intentId?',
                                        component: ConvoAIIntentsRoute
                                    },
                                    {
                                        key: 'ConvoAIAgentManagement',
                                        path: '/agents/:agentId?',
                                        component: ConvoAIAgentManagementRoute
                                    },
                                    {
                                        key: 'ConvoAIAgentCreation',
                                        path: '/createAgent',
                                        component: ConvoAIAgentCreationRoute
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        key: 'Scripts',
                        path: '/scripts/:scriptId?',
                        component: ScriptsRoute
                    }
                ]
            }
        ]
    },
    {
        key: 'Account',
        path: '/account',
        component: AccountRoute
    },
    {
        key: 'CatchAll',
        path: '*',
        component: SpacesRoute
    }
]);
