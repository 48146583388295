import { css } from '@emotion/core';
import { IEditorColor } from '../../shared/motive/models/EditorColor';

export const generateRGBaString = (color: IEditorColor | undefined) => {
    if (!color) {
        return '';
    }
    return `rgb(${color.r.toString()}, ${color.g.toString()}, ${color.b.toString()}, ${color.a?.toString()})`;
};

export const colorEditorStyle = (color: IEditorColor | undefined) => css`
    background-color: ${generateRGBaString(color)};
`;
