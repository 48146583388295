import React, { useState } from 'react';
import { Text } from '../../core-ui/text';
import { IScriptDirectoryItem } from '../../shared/motive/models/ScriptDirectoryItem';
import { emptyCollectionStyle, scenarioDragStyle, scenarioDropStyle, scenarioSectionStyle } from './Scenarios.style';
import { Size } from '../../core-ui/constants/Size';
import { useStyle } from '../../shared/hooks/useStyle';
import { ICatalog, ICatalogInfo } from '../../shared/motive/models/Catalog';
import { EmptyCollection } from '../../core-ui/emptyCollection';
import { ICreateCatalogParams } from '../../shared/motive/hooks/useCatalogs/useCreateCatalog/useCreateCatalog';
import { ICreateScriptLauncherParams } from '../../shared/motive/hooks/useCatalogs/useCreateScriptLauncher/useCreateScriptLauncher';
import { DashboardPanel } from '../dashboardPanel';
import { CreateScenario } from '../createScenario';
import { times, sortBy } from 'lodash-es';
import { ScenarioItem } from '../scenarioItem';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { Collapsible } from '../../core-ui/collapse';
import { Drop, IBaseDrag } from '../_common/DragAndDrop/Drop/Drop';
import {
    DRAG_ITEM_SCENARIO_DRAFT,
    DRAG_ITEM_SCENARIO_PUBLISHED,
    DRAG_ITEM_SCENARIO_RETIRED,
    DROPPABLE_ID_SCENARIO_DRAFT,
    DROPPABLE_ID_SCENARIO_PUBLISHED,
    DROPPABLE_ID_SCENARIO_RETIRED
} from '../../constants/DragAndDrop';
import { EasyDrag } from '../_common/DragAndDrop/EasyDrag/EasyDrag';
import { useSaveScenarioItem } from '../../shared/motive/hooks/useSaveScenarioItem/useSaveScenarioItem';
import { DRAFT, PUBLISHED, RETIRED } from '../../containers/scenariosContainer/ScenariosContainer';
import { useCurrentDrag } from '../../hooks/useDragContext';
import { useCurrentProjectConfig } from '../../redux/spaceKeyed/SpaceKeyedHooks';
import { VersionDates } from '../../util/ScriptUtils';
import { useEnumTypes } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useTranslation } from 'react-i18next';

export const scenarioTestId = 'test_launcher';

export interface IGroupdScenarioItem {
    item: IScriptDirectoryItem;
    catalog: ICatalog;
}

export interface IScenarioProps {
    published: IGroupdScenarioItem[];
    draft: IGroupdScenarioItem[];
    retired: IGroupdScenarioItem[];
    isLoading?: boolean;
    catalogs: ICatalog[];
    selectedScenarioId?: string;
    createScenario: IHookProvidedCall<ICreateScriptLauncherParams, IScriptDirectoryItem | void>;
    createCatalog: IHookProvidedCall<ICreateCatalogParams, ICatalogInfo | undefined>;
}

interface IScenarioDrag extends IBaseDrag {
    catalogId: string;
    item: IScriptDirectoryItem;
}

interface IScenarioSection {
    title: string;
    dropId: string;
    dragIds: string[];
    publishingStatus: string;
}

const ScenarioSection: React.FC<IScenarioSection> = ({
    title,
    dropId,
    dragIds,
    publishingStatus,
    children
}): React.ReactElement => {
    const { execute } = useSaveScenarioItem();
    const token = useStyle();
    const config = useCurrentProjectConfig();
    const isEnumRefSpace = VersionDates.checkUseEnumRef(config?.typeVersionDate);
    const enumTypes = useEnumTypes();

    const handleDrag = (dragType: string, publishingStatus: string) => {
        return {
            dragType: dragType,
            onDragEnd: (data: IScenarioDrag) => {
                const newItem: IScriptDirectoryItem = data.item;
                if (isEnumRefSpace) {
                    const statuses = enumTypes.find(e => e.name === MotiveTypes.PUBLISHING_STATUS);
                    const statusDefinition = statuses?.items?.find(c => c.name === publishingStatus);

                    if (statusDefinition) {
                        newItem.publishingStatus = {
                            itemId: statusDefinition.id,
                            name: statusDefinition.name
                        };
                    }
                } else {
                    newItem.publishingStatus = publishingStatus;
                }
                execute({ catalogId: data.catalogId, item: newItem });
            },
            onDragOverStyle: scenarioDragStyle
        };
    };

    const dragListArr = dragIds.map(id => {
        return handleDrag(id, publishingStatus);
    });

    return (
        <div css={scenarioSectionStyle(token)}>
            <div>
                <Text size={Size.MEDIUM}>{title}</Text>
            </div>
            <div>
                <Drop
                    dropId={dropId}
                    allowedDragList={dragListArr}
                    css={scenarioDropStyle}
                    dragType={`DEFAULT`}
                    showPlaceHolder
                >
                    {children}
                </Drop>
            </div>
        </div>
    );
};

export const Scenarios: React.FC<IScenarioProps> = ({
    published,
    draft,
    retired,
    isLoading,
    catalogs
}): React.ReactElement => {
    const token = useStyle();
    const { t, i18n } = useTranslation();
    const cultureCode = i18n.language;
    const [editingItem, setEditingItem] = useState<IScriptDirectoryItem | undefined>();
    const [isCreationFlowShowing, setCreationFlowShowing] = useState<boolean>(false);

    const selectItem = (item: IScriptDirectoryItem) => {
        if (isCreationFlowShowing) {
            onCreateScenarioHandler();
        }

        if (editingItem?.id !== item.id) {
            setEditingItem(item);
        } else {
            setEditingItem(undefined);
        }
    };

    const cancelSelect = () => {
        setEditingItem(undefined);
    };

    const onCreateScenarioHandler = () => {
        setCreationFlowShowing(false);
    };

    const onEditScenarioHandler = () => {
        setEditingItem(undefined);
    };

    const buttonText: string = isCreationFlowShowing ? t('cancel') : t('create');

    const sortedDraft = sortBy(draft, i => i.item.order ?? Number.MAX_VALUE);
    const sortedPublished = sortBy(published, i => i.item.order ?? Number.MAX_VALUE);
    const sortedRetired = sortBy(retired, i => i.item.order ?? Number.MAX_VALUE);

    const currDragInfo = useCurrentDrag();

    const checkRetiredDrag =
        currDragInfo?.dragType === DRAG_ITEM_SCENARIO_PUBLISHED || currDragInfo?.dragType === DRAG_ITEM_SCENARIO_DRAFT;

    return (
        <DashboardPanel
            headingTitle={t('scenarios')}
            buttonText={buttonText}
            onClickCreateButton={() => {
                setCreationFlowShowing(!isCreationFlowShowing);
                if (editingItem) {
                    setEditingItem(undefined);
                }
            }}
        >
            <Collapsible isShowing={isCreationFlowShowing}>
                <CreateScenario catalogs={catalogs} onScenarioCreation={onCreateScenarioHandler}></CreateScenario>
            </Collapsible>

            <ScenarioSection
                title={t('published')}
                dropId={DROPPABLE_ID_SCENARIO_PUBLISHED}
                dragIds={[DRAG_ITEM_SCENARIO_DRAFT, DRAG_ITEM_SCENARIO_RETIRED]}
                publishingStatus={PUBLISHED}
            >
                {!isLoading ? (
                    published.length === 0 ? (
                        <EmptyCollection
                            css={emptyCollectionStyle(token)}
                            message={t('noPublishedScenariosText')}
                        ></EmptyCollection>
                    ) : (
                        sortedPublished.map((launcher, index) => {
                            if (launcher) {
                                return (
                                    <EasyDrag<IScenarioDrag>
                                        index={index}
                                        drag={{
                                            dragType: DRAG_ITEM_SCENARIO_PUBLISHED,
                                            catalogId: launcher.catalog.id,
                                            item: launcher.item
                                        }}
                                        key={launcher.item.id}
                                        isDragDisabled={editingItem?.id === launcher.item.id}
                                    >
                                        <ScenarioItem
                                            scenario={launcher.item}
                                            catalog={launcher.catalog}
                                            cultureCode={cultureCode}
                                            selectedScenarioId={editingItem?.id}
                                            onClick={selectItem}
                                            onCancel={cancelSelect}
                                            onScenarioCreation={onEditScenarioHandler}
                                        />
                                    </EasyDrag>
                                );
                            } else {
                                return <></>;
                            }
                        })
                    )
                ) : (
                    <>
                        {times(3, idx => (
                            <ScenarioItem key={idx}></ScenarioItem>
                        ))}
                    </>
                )}
            </ScenarioSection>

            <ScenarioSection
                title={t('draft')}
                dropId={DROPPABLE_ID_SCENARIO_DRAFT}
                dragIds={[DRAG_ITEM_SCENARIO_PUBLISHED, DRAG_ITEM_SCENARIO_RETIRED]}
                publishingStatus={DRAFT}
            >
                {!isLoading ? (
                    draft.length === 0 ? (
                        <EmptyCollection
                            css={emptyCollectionStyle(token)}
                            message={t('noDraftScenariosText')}
                        ></EmptyCollection>
                    ) : (
                        sortedDraft.map((launcher, index) => {
                            if (launcher) {
                                return (
                                    <EasyDrag<IScenarioDrag>
                                        index={index}
                                        drag={{
                                            dragType: DRAG_ITEM_SCENARIO_DRAFT,
                                            catalogId: launcher.catalog.id,
                                            item: launcher.item
                                        }}
                                        key={launcher.item.id}
                                        isDragDisabled={editingItem?.id === launcher.item.id}
                                    >
                                        <ScenarioItem
                                            scenario={launcher.item}
                                            catalog={launcher.catalog}
                                            cultureCode={cultureCode}
                                            selectedScenarioId={editingItem?.id}
                                            onClick={selectItem}
                                            onCancel={cancelSelect}
                                            onScenarioCreation={onEditScenarioHandler}
                                        />
                                    </EasyDrag>
                                );
                            } else {
                                return <></>;
                            }
                        })
                    )
                ) : (
                    <>
                        {times(3, idx => (
                            <ScenarioItem key={idx}></ScenarioItem>
                        ))}
                    </>
                )}
            </ScenarioSection>

            {(checkRetiredDrag || sortedRetired.length > 0) && (
                <ScenarioSection
                    title={t('retired')}
                    dropId={DROPPABLE_ID_SCENARIO_RETIRED}
                    dragIds={[DRAG_ITEM_SCENARIO_PUBLISHED, DRAG_ITEM_SCENARIO_DRAFT]}
                    publishingStatus={RETIRED}
                >
                    {!isLoading ? (
                        retired.length === 0 ? (
                            <EmptyCollection
                                css={emptyCollectionStyle(token)}
                                message={t('noRetiredScenariosText')}
                            ></EmptyCollection>
                        ) : (
                            retired?.map((launcher, index) => {
                                if (launcher) {
                                    return (
                                        <EasyDrag<IScenarioDrag>
                                            index={index}
                                            drag={{
                                                dragType: DRAG_ITEM_SCENARIO_RETIRED,
                                                catalogId: launcher.catalog.id,
                                                item: launcher.item
                                            }}
                                            key={launcher.item.id}
                                            isDragDisabled={editingItem?.id === launcher.item.id}
                                        >
                                            <ScenarioItem
                                                key={launcher.item.id}
                                                scenario={launcher.item}
                                                catalog={launcher.catalog}
                                                cultureCode={cultureCode}
                                                selectedScenarioId={editingItem?.id}
                                                onClick={selectItem}
                                                onCancel={cancelSelect}
                                                onScenarioCreation={onEditScenarioHandler}
                                            />
                                        </EasyDrag>
                                    );
                                } else {
                                    return <></>;
                                }
                            })
                        )
                    ) : (
                        <>
                            {times(3, idx => (
                                <ScenarioItem key={idx}></ScenarioItem>
                            ))}
                        </>
                    )}
                </ScenarioSection>
            )}
        </DashboardPanel>
    );
};
