import { IActivity } from '../../../shared/motive/models/Activity';
import produce from 'immer';
import { ActivityActions } from './ActivityActions';

export interface IActivityState {
    activities: IActivity[];
}

export const defaultActivityState: IActivityState = {
    activities: []
};

export const ActivityReducer = produce((state: IActivityState, action: ActivityActions) => {
    switch (action.type) {
        case 'activity/set': {
            state.activities = action.activities;
            break;
        }
        case 'activity/unset': {
            state.activities = [];
            break;
        }
    }
}, defaultActivityState);
