import { HashRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import React from 'react';
import { IEditorRoute, routes } from '..';
import { NavBarContainer } from '../../containers/navBar';

/**
 * Handles awkwardness of using `render` prop of `<Route />`
 * @param subRoute
 */
export const renderSubRoute = (subRoute: IEditorRoute) => {
    return (
        <Route
            key={subRoute.key}
            path={subRoute.fullPath}
            exact={subRoute.exact}
            strict={subRoute.strict}
            sensitive={subRoute.sensitive}
        >
            <>{subRoute.component && <subRoute.component />}</>
        </Route>
    );
};

export const EditorRoutes: React.FC = () => {
    return (
        <HashRouter>
            <Switch>{routes.map(renderSubRoute)}</Switch>
        </HashRouter>
    );
};
