import React from 'react';
import { IObjectEditorRendererProps, shouldShowField } from '../../containers/objectEditor';
import { IBehaviourStateInput } from '../../shared/motive/models/IBehaviourModel';
import { useObjectDefinition } from '../../shared/motive/hooks/useScriptEditorInfo';
import { IDropdownOption, Dropdown } from '../../core-ui/dropdown';
import { createObjectReferenceFromObject } from '../../util/MotiveUtils';
import { DynamicValueComparerEditor } from '../dynamicValueComparerEditor';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IBehaviourStateInputEditorProps extends IObjectEditorRendererProps<IBehaviourStateInput> {}

const BEHAVIOUR_MODEL_FIELD = 'behaviourModel';
const BEHAVIOUR_STATE_REFERENCE_FIELD = 'behaviourStateReference';
const COMPARER_FIELD = 'comparer';

export const BehaviourStateInputEditor: React.FC<IBehaviourStateInputEditorProps> = (props): React.ReactElement => {
    const objectDefinition = useObjectDefinition(props.type);
    const value = props.value;

    const behaviourModelDef = objectDefinition.fieldDefinitions[BEHAVIOUR_MODEL_FIELD];
    const stateRefDef = objectDefinition.fieldDefinitions[BEHAVIOUR_STATE_REFERENCE_FIELD];
    const comparerDef = objectDefinition.fieldDefinitions[COMPARER_FIELD];
    const stateRefValue = props.value?.behaviourStateReference;

    const behaviourModel = value?.behaviourModel;

    const getBehaviourState = (id: string | undefined) => {
        return behaviourModel?.behaviourStates?.find(s => s.id === id);
    };

    const behaviourState = getBehaviourState(stateRefValue?.objectId);

    const renderChooseState = () => {
        if (behaviourModel?.behaviourStates) {
            const dropOpts: IDropdownOption[] = [
                ...behaviourModel.behaviourStates.map(s => ({
                    label: s.name,
                    value: s.id
                }))
            ];

            const handleChangeStateVal = (val: string) => {
                if (value) {
                    const newState = getBehaviourState(val);

                    if (newState) {
                        const obj: IBehaviourStateInput = {
                            ...value,
                            behaviourStateReference: newState ? createObjectReferenceFromObject(newState) : undefined
                            //comparer: defaultComparer
                        };

                        props.onChange(props.path ?? '', obj);
                    } else {
                        //props.onChange(fieldPath, undefined);
                    }
                }
            };

            const renderOpts = () => {
                return (
                    <Dropdown
                        instructions={'CHOOSE STATE'}
                        value={stateRefValue?.objectId}
                        options={dropOpts}
                        onChange={handleChangeStateVal}
                        //mulitiple={props.fieldDefinition.isArray}
                    ></Dropdown>
                );
            };

            return props.renderFieldEditor(stateRefDef.editorInfo?.label ?? stateRefDef.name, renderOpts);
        }
    };

    const objProps = props as IObjectEditorRendererProps;

    return (
        <>
            {props.renderField(objProps, behaviourModelDef, value?.behaviourModel)}
            {behaviourModel &&
                shouldShowField(stateRefDef, !!props.frame, props.showFields, props.hideFields) &&
                renderChooseState()}
            {behaviourState && (
                <DynamicValueComparerEditor
                    {...props}
                    valueDefinition={behaviourState.valueDefinition}
                    comparerFieldDefinition={comparerDef}
                />
            )}
        </>
    );
};
