import React from 'react';
import { FieldTypes, IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { ScriptActionType, IScriptObjectUpdateAction } from '../../shared/motive/reducers/ScriptEditorReducers';
import { useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { Condition } from '../../components/condition/Condition';
import { IConditionWrapper, IFrame, IScript } from '../../shared/motive/models/Script';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { createScriptObject, createObjectReference } from '../../util/MotiveUtils';
import { setWith, clone } from 'lodash-es';
import { getSmartName, getObjectIcon, CONDITION_ICON_DEFAULT } from '../../util/ObjectDefinitionsUtils';
import { useCurrentLanguageSettings } from '../../shared/motive/stores/editorLocale/EditorLocale';
import { defaultConditionMessage } from './ConditionContainer.style';

import { IScriptEventCondition } from '../../shared/motive/models/IScriptEventCondition';
import { useStyle } from '../../shared/hooks/useStyle';
import { IVariableValueCondition } from '../../shared/motive/models/IVariableValueCondition';
import { combineCondition } from '../../util/ScriptUtils';
import { useDispatch } from 'react-redux';
import { IObjectInstanceDrag } from '../../components/_common/DragAndDrop/DragModels/DragModels';
import { Drop } from '../../components/_common/DragAndDrop/Drop/Drop';
import { DRAG_ITEM_TYPE_SCRIPT_VARIABLE, DRAG_ITEM_TYPE_SCRIPT_EVENT } from '../../constants/DragAndDrop';
import { Trans, useTranslation } from 'react-i18next';

interface IConditionContainerProps {
    condition: IScriptObjectModel | undefined | null;
    subFrameConditionPath: string;
    frame: IFrame;
    dropId: string;
    script: IScript;
    onClickSetEventId?: ((eventId: string) => void) | undefined;
}

export const ConditionContainer: React.FC<IConditionContainerProps> = React.memo(function ConditionContainer(
    props: IConditionContainerProps
) {
    const { subFrameConditionPath, ...restProps } = props;
    const scriptDispatch = useDispatch();
    const objectDefinitions = useObjectDefinitions();
    const language = useCurrentLanguageSettings();

    const currentNullableWrapper =
        restProps.condition && restProps.condition.type === MotiveTypes.CONDITION_WRAPPER
            ? (restProps.condition as IConditionWrapper)
            : undefined;

    const getOrCreateWrapper = (): IConditionWrapper => {
        let wrapper: IConditionWrapper | undefined = currentNullableWrapper;
        if (!wrapper) {
            wrapper = {
                ...createScriptObject<IConditionWrapper>(MotiveTypes.CONDITION_WRAPPER, objectDefinitions),
                condition: restProps.condition,
                isEnabled: true
            };
        }

        return wrapper;
    };

    const handleDelete = () => {
        const action: IScriptObjectUpdateAction = {
            type: ScriptActionType.OBJECT_UPDATE,
            updatedObject: undefined,
            updatedObjectPath: subFrameConditionPath,
            targetFrameId: restProps.frame.id
        };

        scriptDispatch(action);
    };

    const handleAddCondition = (condition: IScriptObjectModel) => {
        const newCondition = combineCondition(currentNullableWrapper?.condition, condition, objectDefinitions);

        // scriptDispatch(action);
        handleWrapperChange('condition', newCondition);
    };

    const handleWrapperChange = (subWrapperPath: string, subWrapperValue: FieldTypes): void => {
        const toUpdateWrapper = getOrCreateWrapper();
        const updatedCondition = setWith(clone(toUpdateWrapper), subWrapperPath, subWrapperValue, clone);
        const action: IScriptObjectUpdateAction = {
            type: ScriptActionType.OBJECT_UPDATE,
            updatedObject: updatedCondition,
            updatedObjectPath: subFrameConditionPath,
            targetFrameId: restProps.frame.id
        };

        scriptDispatch(action);
    };

    const conditionDropList = [
        {
            dragType: DRAG_ITEM_TYPE_SCRIPT_VARIABLE,
            onDragEnd: (dragObjInfo: IObjectInstanceDrag) => {
                const condition = createScriptObject<IVariableValueCondition>(
                    MotiveTypes.VARIABLE_VALUE_CONDITION,
                    objectDefinitions
                );

                condition.variableReference = createObjectReference(MotiveTypes.SCRIPT_VARIABLE, dragObjInfo.objectId);

                handleAddCondition(condition);
            }
        },
        {
            dragType: DRAG_ITEM_TYPE_SCRIPT_EVENT,
            onDragEnd: (dragObjInfo: IObjectInstanceDrag) => {
                const condition = createScriptObject<IScriptEventCondition>(
                    MotiveTypes.SCRIPT_EVENT_CONDITION,
                    objectDefinitions
                );

                condition.eventReference = createObjectReference(
                    MotiveTypes.SCRIPT_EVENT_DEFINITION,
                    dragObjInfo.objectId
                );

                handleAddCondition(condition);
            }
        }
    ];

    const isEnabled = !!currentNullableWrapper?.isEnabled;
    const token = useStyle();
    const { t } = useTranslation();

    return (
        <Drop dropId={props.dropId} allowedDragList={conditionDropList}>
            <>
                {currentNullableWrapper?.condition && (
                    <Condition
                        conditionIcon={getObjectIcon(
                            objectDefinitions,
                            currentNullableWrapper.condition,
                            CONDITION_ICON_DEFAULT
                        )}
                        isReadOnly={false}
                        isEnabled={isEnabled}
                        label={
                            (currentNullableWrapper && currentNullableWrapper.label) ??
                            getSmartName(currentNullableWrapper.condition, objectDefinitions, language)
                        }
                        frame={props.frame}
                        type={currentNullableWrapper.type}
                        conditionWrapper={currentNullableWrapper}
                        onChange={handleWrapperChange}
                        onDelete={handleDelete}
                        script={props.script}
                        onClickSetEventId={props.onClickSetEventId}
                    />
                )}
                {!currentNullableWrapper?.condition && (
                    <div css={defaultConditionMessage(token)}>
                        <Trans i18nKey="frameHasNoConditionsText">
                            This frame has no conditions. It will open in all cases.
                        </Trans>
                    </div>
                )}
            </>
        </Drop>
    );
});
