import React, { ReactNode } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

interface ILoadingSkeletonProps {
    count?: number;
    duration?: number;
    width?: string | number;
    wrapper?: ReactNode;
    height?: string | number;
    circle?: boolean;
}

/**
 * Wrapper around the Skeleton class from react-loading-skeleton.
 * Note: The props are not exported from the library so this components props are a copy of it. If we update the package, this might break or leave out values.
 */
export const LoadingSkeleton: React.FC<ILoadingSkeletonProps> = (props: ILoadingSkeletonProps): React.ReactElement => {
    return (
        <SkeletonTheme color="#ccc" highlightColor="#b7b7b7">
            <Skeleton {...props} />
        </SkeletonTheme>
    );
};
