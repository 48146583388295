import { FormControl } from '../formControl';
import { InputField } from '../inputField';
import { Divider } from '../divider';
import React from 'react';
import { Heading } from '../heading';
import { Size } from '../constants/Size';
import { skeletonLoader } from './SkeletonForm.style';

const SkeletonEntry: React.ReactElement = (
    <FormControl fieldId="" label={''} required={true} helperText={''}>
        <InputField type={'phone'} value={''} size={Size.MEDIUM} />
    </FormControl>
);

// Helper method for generating skeleton forms.
export const SkeletonForm = (
    // Required, the number of rows to generate for the form.
    rowCount: number,
    // Optional pass this in to override what elements to render.
    skeletonEntry: React.ReactElement = SkeletonEntry,
    // Optional pass this in to override the logic of when to render a divider as a fxn of the count.
    shouldRenderDivider: (idx: number) => boolean = idx => idx % 3 === 0
) => {
    const generateSkeletonRows = (num: number) => {
        const rows = [];
        for (let i = 0; i < num; i++) {
            if (shouldRenderDivider(i)) {
                rows.push(<Divider key={i}></Divider>);
            } else {
                rows.push(React.cloneElement(skeletonEntry, { key: i }));
            }
        }
        return rows;
    };

    return (
        <form css={skeletonLoader}>
            <Heading size={Size.MEDIUM}>&nbsp; &nbsp;</Heading>
            <br></br>
            {generateSkeletonRows(rowCount)}
        </form>
    );
};
