//istanbul ignore file MOTIVE_EXCEPTION
import React, { useState, useEffect } from 'react';
import { renderSubRoute } from '../editorRoutes';
import { ISpacesRouteParams } from '../spacesRoute';
import { ProjectConfigsContainer } from '../../containers/projectConfigs';
import { useRoute, useEditorRouteMatch } from '../useRoute';
import { ProjectDashboardContainer } from '../../containers/projectDashboardContainer';
import { DashboardTab } from '../../components/dashboardNavBar';
import { useCatalogsRoute, useCatalogsRouteMatch } from '../catalogsRoute';
import { useScenariosRoute } from '../scenariosRoute';
import { ProjectDashboardContextProvider } from '../../contexts/projectDashboardContext';
import { useMediaRoute, useMediaRouteMatch } from '../mediaRoute/MediaRoute';
import { useBackupRoute, useBackupRouteMatch } from '../backupRoute';
import { ProjectManagement } from '../../components/projectManagement';
import { useCurrentProject } from '../../shared/motive/hooks/useCurrentProject';
import { useConvoAIRouteMatch } from '../convoAIRoute/ConvoAIRoute';
import { useConvoAIIntentsRoute } from '../convoAIIntentsRoute';
import { useRouteTabSynchronization } from './UseRouteTabSynchronization';

export interface IProjectsRouteParams extends ISpacesRouteParams {
    projectName?: string;
}

export const useProjectsRoute = () => useRoute<IProjectsRouteParams>('Projects');
export const useProjectsRouteMatch = () => useEditorRouteMatch<IProjectsRouteParams>('Projects');

export const ProjectsRoute: React.FC = () => {
    const {
        subRoutes,
        params: { projectName }
    } = useProjectsRoute();
    const { goTo: goToScenariosRoute } = useScenariosRoute();
    const { goTo: goToCatalogsRoute } = useCatalogsRoute();
    const { goTo: goToMediaRoute } = useMediaRoute();
    const { goTo: goToBackupRoute } = useBackupRoute();
    const { goTo: goToAIIntentsRoute } = useConvoAIIntentsRoute();

    const catalogsMatch = useCatalogsRouteMatch();
    const mediaMatch = useMediaRouteMatch();
    const backupMatch = useBackupRouteMatch();
    const AIMatch = useConvoAIRouteMatch();

    const [currentProject, setCurrentProject] = useCurrentProject();

    useEffect(() => {
        setCurrentProject(projectName);
    }, [projectName, setCurrentProject]);

    const [selectedItems, setSelectedItems] = useState<Record<DashboardTab, string | undefined>>({
        Scenarios: '',
        Catalogs: '',
        Media: '',
        Backup: '',
        ConvoAI: ''
    });

    let selectedTab: DashboardTab = 'Scenarios';

    if (catalogsMatch) {
        selectedTab = 'Catalogs';
    } else if (mediaMatch) {
        selectedTab = 'Media';
    } else if (backupMatch) {
        selectedTab = 'Backup';
    } else if (AIMatch) {
        selectedTab = 'ConvoAI';
    }

    const goToTab = (tab: DashboardTab, newItemVal?: string) => {
        const val = newItemVal || selectedItems[tab];
        const pushProps = {
            replace: tab === selectedTab
        };

        switch (tab) {
            case 'Scenarios':
                goToScenariosRoute({
                    pathParams: {
                        scenarioName: val
                    },
                    pushProps
                });
                break;
            case 'Catalogs':
                goToCatalogsRoute({
                    pathParams: {
                        catalogName: val
                    },
                    pushProps
                });
                break;
            case 'Media':
                goToMediaRoute({
                    pathParams: {},
                    pushProps
                });
                break;
            case 'Backup':
                goToBackupRoute({
                    pathParams: {},
                    pushProps
                });
                break;
            case 'ConvoAI':
                goToAIIntentsRoute({
                    pathParams: {},
                    pushProps
                });
                break;
            default:
                break;
        }
    };

    const handleTabSelected = (tab: DashboardTab) => {
        goToTab(tab);
    };
    const handleDashItemSelected = (tab: DashboardTab, itemName: string) => {
        setSelectedItems({ ...selectedItems, [tab]: itemName });
        goToTab(tab, itemName);
    };

    useRouteTabSynchronization();

    return (
        <>
            {!currentProject && (
                <ProjectConfigsContainer>{props => <ProjectManagement {...props} />}</ProjectConfigsContainer>
            )}
            {currentProject && (
                <ProjectDashboardContextProvider
                    value={{
                        selectedTab,
                        setSelectedTab: handleTabSelected,
                        onDashItemSelected: handleDashItemSelected
                    }}
                >
                    <ProjectDashboardContainer selectedTab={selectedTab} onTabSelected={handleTabSelected}>
                        {subRoutes.map(renderSubRoute)}
                    </ProjectDashboardContainer>
                </ProjectDashboardContextProvider>
            )}
        </>
    );
};
