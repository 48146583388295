import React from 'react';
import { IntentsContainer } from '../../containers/intentsContainer';
import { Button, ButtonVariant } from '../../core-ui/button';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { useStyle } from '../../shared/hooks/useStyle';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { ConversationalIntent } from '../../shared/motive/models/ConversationalIntent';
import { Intents } from '../intents/Intents';
import {
    actionBarStyle,
    buttonSpacingStyle,
    catalogSettingButtonStyle,
    createIntentWrapper,
    deleteButtonStyle,
    drawerWrapperStyle
} from './DrawerIntentManagement.style';
import { Heading } from '../../core-ui/heading';
import { buttonWrapperStyle } from './DrawerIntentManagement.style';
import { Collapsible } from '../../core-ui/collapse';
import { useState } from 'react';
import { CreateIntentItem } from '../createIntentItem';
import { IntentCatalogSetting } from '../intentCatalogSetting/IntentCatalogSetting';
import { InputField } from '../../core-ui/inputField';

interface IDrawerIntentManagementProps {
    agentCatalogs: ICatalog[];
    intentCat?: ICatalog;
}

export const DrawerIntentManagement: React.FC<IDrawerIntentManagementProps> = props => {
    const token = useStyle();
    const [isCreateIntentShowing, setIsCreateIntentShowing] = useState<boolean>(false);
    const [isCatalogSettingsShowing, setIsCatalogsSettingsShowing] = useState<boolean>(false);

    return (
        <div css={drawerWrapperStyle(token)}>
            <IntentsContainer
                agentCatalogs={props.agentCatalogs}
                intentCatalog={props.intentCat}
                onSelectIntent={() => undefined}
            >
                {props => (
                    <>
                        <div css={actionBarStyle}>
                            <Heading>{props.intentCatalog?.title}</Heading>
                            <div css={buttonWrapperStyle}>
                                <Button
                                    disabled={props.multiSelectedIntents?.length === 0}
                                    onClick={() =>
                                        props.onDeleteIntents && props.onDeleteIntents(props.multiSelectedIntents ?? [])
                                    }
                                    css={[buttonSpacingStyle(token), deleteButtonStyle(token)]}
                                    icon={IconTypes.DELETE}
                                    variant={ButtonVariant.SOLID}
                                />
                                <Button
                                    onClick={() => {
                                        setIsCatalogsSettingsShowing(!isCatalogSettingsShowing);
                                        setIsCreateIntentShowing(false);
                                    }}
                                    css={[buttonSpacingStyle(token), catalogSettingButtonStyle(token)]}
                                    icon={IconTypes.COG}
                                    variant={ButtonVariant.SOLID}
                                />
                                <Button
                                    onClick={() => {
                                        setIsCreateIntentShowing(!isCreateIntentShowing);
                                        setIsCatalogsSettingsShowing(false);
                                    }}
                                    css={buttonSpacingStyle(token)}
                                    icon={IconTypes.PLUS}
                                    variant={ButtonVariant.SOLID}
                                />
                            </div>
                        </div>
                        <Collapsible isShowing={isCreateIntentShowing}>
                            <div css={createIntentWrapper(token)}>
                                <CreateIntentItem
                                    onIntentItemCreation={intent =>
                                        props.onCreateIntent && props.onCreateIntent(intent)
                                    }
                                    agents={props.agents ?? []}
                                    currentAgent={props.agent}
                                    isCreateIntentItemLoading={props.isCreateIntentLoading}
                                    disableAgentSelection={
                                        props.intentCatalog?.items && props.intentCatalog?.items.length > 0
                                    }
                                />
                            </div>
                        </Collapsible>
                        <Collapsible isShowing={isCatalogSettingsShowing}>
                            <div css={createIntentWrapper(token)}>
                                <IntentCatalogSetting
                                    currentAgent={props.agent}
                                    agents={props.agents}
                                    onUpdateAgent={agentId => props.onUpdateAgent && props.onUpdateAgent(agentId)}
                                    isEmpty={props.intentCatalog?.items && props.intentCatalog?.items?.length > 0}
                                />
                            </div>
                        </Collapsible>
                        <InputField
                            placeholder={`Search Intents`}
                            onChange={props.onSearchIntent}
                            value={props.searchFilter}
                        />
                        {props.intentCatalog && (
                            <Intents
                                agents={props.agents ?? []}
                                intents={(props.intentCatalog?.items as ConversationalIntent[]) ?? []}
                                catalogId={props.intentCatalog?.id}
                                onUpdateIntent={props.onUpdateIntent}
                                onSelectIntent={props.onSelectIntent}
                                searchFilter={props.searchFilter}
                                isCreateIntentLoading={props.isCreateIntentLoading}
                                isUpdatingPivotField={props.isUpdatePivotFieldLoading}
                                isDeleteIntentsLoading={props.isDeleteIntentLoading}
                                selectedIntents={props.multiSelectedIntents}
                                onMultiSelectIntent={props.onMultiSelectIntents}
                                isDraggable
                                nameOnly
                            />
                        )}
                    </>
                )}
            </IntentsContainer>
        </div>
    );
};
