import moment from 'moment';

export function prettierDate(dateString: string | Date | undefined): string {
    if (dateString) {
        var m = moment(dateString).local();

        if (m.isValid()) {
            return m.format('MMMM Do YYYY, h:mm:ss a');
        }
    }

    return '';
}

export function prettierSimpleDate(dateString: string | Date | undefined): string {
    if (dateString) {
        var m = moment(dateString).local();

        if (m.isValid()) {
            return m.format('MMMM Do YYYY');
        }
    }

    return '';
}

export function dateYMD(dateString: string | Date | undefined): string {
    if (dateString) {
        var m = moment(dateString).local();

        if (m.isValid()) {
            return m.format('YYYY-MM-DD, h:mm:ss a');
        }
    }

    return '';
}
