import { IEnumDefinition, IEnumDefinitionItem } from '../../../shared/motive/models/EnumDefinition';
import { IScriptEditorInfo } from '../../../shared/motive/models/ScriptEditorInfo';

export type EditorInfoActions = ReturnType<
    | typeof scriptEditorInfoSetAction
    | typeof enumAppendAction
    | typeof enumUpdateAction
    | typeof enumDeleteAction
    | typeof enumItemAppendAction
    | typeof enumItemUpdateAction
    | typeof enumItemDeleteAction
>;

export function scriptEditorInfoSetAction(scriptEditorInfo: IScriptEditorInfo) {
    return {
        type: 'projectConfig/scriptEditorInfo/set',
        scriptEditorInfo: scriptEditorInfo
    } as const;
}

export function enumAppendAction(enumDef: IEnumDefinition) {
    return {
        type: 'enum/append',
        enumDefinition: enumDef
    } as const;
}

export function enumUpdateAction(enumId: string, enumDef: IEnumDefinition) {
    return {
        type: 'enum/update',
        enumId: enumId,
        enumDefinition: enumDef
    } as const;
}

export function enumDeleteAction(enumId: string) {
    return {
        type: 'enum/delete',
        enumId: enumId
    } as const;
}

export function enumItemAppendAction(enumId: string, enumItem: IEnumDefinitionItem) {
    return {
        type: 'enum/enumItem/append',
        enumId: enumId,
        enumItem: enumItem
    } as const;
}

export function enumItemUpdateAction(enumId: string, enumItemId: string, enumItem: IEnumDefinitionItem) {
    return {
        type: 'enum/enumItem/update',
        enumId: enumId,
        enumItemId: enumItemId,
        enumItem: enumItem
    } as const;
}

export function enumItemDeleteAction(enumId: string, enumItemId: string) {
    return {
        type: 'enum/enumItem/delete',
        enumId: enumId,
        enumItemId: enumItemId
    } as const;
}
