import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const headingWrapperStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const buttonStyle = (token: IToken) => css`
    margin-left: ${token.spacings.smaller};
`;

export const disabledButtonStyle = (token: IToken) => css`
    background-color: ${token.colors.background.neutral};
`;

export const buttonWrapperStyle = (token?: IToken) => css`
    display: flex;
    justify-content: flex-end;
    margin-top: ${token?.spacings?.smaller ?? '8px'};
`;

export const agentDetailsWrapperStyle = (token: IToken) => css`
    padding: ${token.spacings.small};
    border: 1px solid ${token.colors.border.primary};
`;

export const resetButtonStyle = (token: IToken) => css`
    background-color: ${token.colors.background.neutral};
`;
