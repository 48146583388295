//istanbul ignore file MOTIVE_EXCEPTION
import React from 'react';
import { renderSubRoute } from '../editorRoutes';
import { IProjectsRouteParams } from '../projectsRoute';
import { useRoute, useEditorRouteMatch } from '../useRoute';
import { DashboardNavBar } from '../../components/dashboardNavBar';
import { HeightUsedScrollContainer } from '../../containers/heightUsedScrollContainer';
import { useProjectDashboard } from '../../contexts/projectDashboardContext';
import { DashboardSidebarContainer } from '../../containers/dashboardSidebarContainer';
import { SidebarContainer } from '../../containers/sidebarContainer';
import { dashboardLayoutStyle } from './DashboardRoute.style';

export const useDashboardRoute = () => useRoute<IProjectsRouteParams>('Dashboard');
export const useDashboardRouteMatch = () => useEditorRouteMatch<IProjectsRouteParams>('Dashboard');

const dashboardNavHeight = 48;

export const DashboardRoute: React.FC = () => {
    const {
        subRoutes,
        params: { projectName }
    } = useDashboardRoute();

    const { selectedTab, setSelectedTab } = useProjectDashboard();

    return (
        <>
            {projectName && (
                <SidebarContainer
                    sidebarContent={
                        <DashboardSidebarContainer dashboardNavHeight={dashboardNavHeight} projectId={projectName} />
                    }
                >
                    <div css={dashboardLayoutStyle}>
                        <DashboardNavBar
                            height={dashboardNavHeight}
                            selectedTab={selectedTab}
                            onTabPress={setSelectedTab}
                        ></DashboardNavBar>
                        <HeightUsedScrollContainer>{subRoutes.map(renderSubRoute)}</HeightUsedScrollContainer>
                    </div>
                </SidebarContainer>
            )}
        </>
    );
};
