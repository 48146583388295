import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const providerIconWrapperStyle = (token: IToken) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const providerIconStyle = (token: IToken, isItemSelected: boolean, isSelected?: boolean) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 200px;
    border: 2px solid ${token.colors.border.primary};
    border-radius: 6px;
    background-color: ${token.colors.background.lighter};
    cursor: pointer;
    ${isSelected && `box-shadow: 0px 0px 5px 0px rgba(61,132,255,0.75);`}
    ${isItemSelected && !isSelected && `opacity: 0.5;`}
    margin: 24px;
    transition-duration: 250ms;
`;

export const serviceSelectionWrapperStyle = (token: IToken) => css`
    display: grid;
    grid-template-columns: 30% 70%;
    margin: auto;
    border: 1px solid ${token.colors.border.secondary};
    height: 300px;
    margin-top: ${token.spacings.small};
`;

export const listWrapperStyle = (token: IToken) => css`
    background-color: ${token.colors.background.lighter};
`;

export const infoWrapperStyle = (token: IToken) => css`
    border-left: 1px solid ${token.colors.border.secondary};
`;

export const emptyCollectionWrapper = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const fieldRowStyle = (token: IToken) => css`
    display: grid;
    grid-template-columns: 10% 90%;
    margin-top: ${token.spacings.smallest};
    align-items: center;
`;

export const serviceConfigRowSpacingStyle = (token: IToken) => css`
    margin-left: ${token.spacings.smaller};
`;
export const serviceConfigurationRowItemStyle = (token: IToken) => css`
    padding: ${token.spacings.smaller};
`;

export const configInformationStyle = (token: IToken) => css`
    padding: ${token.spacings.small};
`;

export const createServiceConfigButtonWrapperStyle = (token: IToken) => css`
    display: flex;
    justify-content: flex-end;
    margin-top: ${token.spacings.smaller};
`;

export const selectedRowStyle = (isSelected: boolean, token: IToken) => css`
    border-bottom: 1px solid ${token.colors.background.light};
    ${isSelected &&
        `background-color: ${token.colors.interactible.tertiary};
         &:hover {
            background-color: ${token.colors.interactible.tertiary};
                }
    `}
`;

export const loadingSkeletonWrapper = (token: IToken) => css`
    padding: 0 ${token.spacings.small};
`;

export const plusIconStyle = (token: IToken) => css`
    margin-left: ${token.spacings.smallest};
`;

export const newServiceConfigRowStyle = (token: IToken) => css`
    margin-left: ${token.spacings.smaller};
    display: flex;
    align-items: center;
`;

export const credentialsSectionStyle = (token: IToken) => css`
    margin-top: ${token.spacings.smaller};
`;

export const enableCreateWatsonServiceConfig = (isEnabled: boolean) => css`
    ${!isEnabled && `opacity: 50%;`}
`;

export const resetButtonStyle = (token: IToken) => css`
    background-color: ${token.colors.background.neutral};
`;

export const disabledButtonStyle = (token: IToken) => css`
    background-color: ${token.colors.background.neutral};
`;
