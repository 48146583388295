export enum BreakPoint {
    Smaller = 'Smaller',
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
    Larger = 'Larger'
}

const { Smaller, Small, Medium, Large, Larger } = BreakPoint;

export interface IBreakpoints {
    [Smaller]: number;
    [Small]: number;
    [Medium]: number;
    [Large]: number;
    [Larger]: number;
}

// defaults
const DEFAULT_BREAK_POINTS: IBreakpoints = {
    [Smaller]: 320,
    [Small]: 600,
    [Medium]: 900,
    [Large]: 1200,
    [Larger]: 1800
};

const MAP_CEILING_TO_FLOOR: { [key in BreakPoint]: BreakPoint | undefined } = {
    [Smaller]: undefined,
    [Small]: Smaller,
    [Medium]: Small,
    [Large]: Medium,
    [Larger]: Large
};

const breakPointRange = (ceilingBreakPoint: BreakPoint, useFloor: boolean = true) => {
    const floorBreakPoint = MAP_CEILING_TO_FLOOR[ceilingBreakPoint];
    const floorString =
        floorBreakPoint && useFloor ? `(min-width: ${DEFAULT_BREAK_POINTS[floorBreakPoint] + 1}px) and` : '';
    const ceilingString = `(max-width: ${DEFAULT_BREAK_POINTS[ceilingBreakPoint]}px)`;

    return `@media ${floorString} ${ceilingString}`;
};

const withinRange = (ceilingBreakPoint: BreakPoint, width?: number, useFloor: boolean = true): boolean => {
    if (!width && width !== 0) {
        return false;
    }
    const floorBreakPoint = MAP_CEILING_TO_FLOOR[ceilingBreakPoint];

    const isLargerThanFloor =
        !useFloor || floorBreakPoint === Smaller || (floorBreakPoint && width > DEFAULT_BREAK_POINTS[floorBreakPoint]);

    return !!(isLargerThanFloor && width <= DEFAULT_BREAK_POINTS[ceilingBreakPoint]);
};

const isWithinRangeFactory: (bp: BreakPoint) => (width?: number) => boolean = bp => width => withinRange(bp, width);
const isWithinRangeNoFloorFactory: (bp: BreakPoint) => (width?: number) => boolean = bp => width =>
    withinRange(bp, width, false);

export const breakPoints = { ...DEFAULT_BREAK_POINTS };

// e.g. useBreakPointSmaller: string ==== '@media (min-width) and (max-width)'
export const breakPointSmaller = breakPointRange(BreakPoint.Smaller);
export const breakPointSmall = breakPointRange(BreakPoint.Small);
export const breakPointMedium = breakPointRange(BreakPoint.Medium);
export const breakPointLarge = breakPointRange(BreakPoint.Large);
export const breakPointLarger = breakPointRange(BreakPoint.Larger);

// e.g. useBreakPointSmallerNoFloor: string ==== '@media (max-width)'
export const breakPointSmallerNoFloor = breakPointRange(BreakPoint.Smaller, false);
export const breakPointSmallNoFloor = breakPointRange(BreakPoint.Small, false);
export const breakPointMediumNoFloor = breakPointRange(BreakPoint.Medium, false);
export const breakPointLargeNoFloor = breakPointRange(BreakPoint.Large, false);
export const breakPointLargerNoFloor = breakPointRange(BreakPoint.Larger, false);
const breakPointNoFloorMap = {
    [BreakPoint.Smaller]: breakPointSmallerNoFloor,
    [BreakPoint.Small]: breakPointSmallNoFloor,
    [BreakPoint.Medium]: breakPointMediumNoFloor,
    [BreakPoint.Large]: breakPointLargeNoFloor,
    [BreakPoint.Larger]: breakPointLargerNoFloor
};
export const breakPointNoFloor = (ceiling: BreakPoint) => breakPointNoFloorMap[ceiling];

export const isWithinRangeSmaller = isWithinRangeFactory(BreakPoint.Smaller);
export const isWithinRangeSmall = isWithinRangeFactory(BreakPoint.Small);
export const isWithinRangeMedium = isWithinRangeFactory(BreakPoint.Medium);
export const isWithinRangeLarge = isWithinRangeFactory(BreakPoint.Large);
export const isWithinRangeLarger = isWithinRangeFactory(BreakPoint.Larger);

export const isWithinRangeSmallerNoFloor = isWithinRangeNoFloorFactory(BreakPoint.Smaller);
export const isWithinRangeSmallNoFloor = isWithinRangeNoFloorFactory(BreakPoint.Small);
export const isWithinRangeMediumNoFloor = isWithinRangeNoFloorFactory(BreakPoint.Medium);
export const isWithinRangeLargeNoFloor = isWithinRangeNoFloorFactory(BreakPoint.Large);
export const isWithinRangeLargerNoFloor = isWithinRangeNoFloorFactory(BreakPoint.Larger);
const isWithinRangeNoFloorMap = {
    [BreakPoint.Smaller]: isWithinRangeSmallerNoFloor,
    [BreakPoint.Small]: isWithinRangeSmallNoFloor,
    [BreakPoint.Medium]: isWithinRangeMediumNoFloor,
    [BreakPoint.Large]: isWithinRangeLargeNoFloor,
    [BreakPoint.Larger]: isWithinRangeLargerNoFloor
};

export const isWithinRangeNoFloor = (ceiling: BreakPoint, width: number) => isWithinRangeNoFloorMap[ceiling](width);
