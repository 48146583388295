import React from 'react';
import { useStyle } from '../../shared/hooks/useStyle';
import { dividerStyle } from './Divider.style';

interface IDividerProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHRElement>, HTMLHRElement> {
    className?: string;
}

export const Divider: React.FC<IDividerProps> = ({ ...rest }) => {
    const tokens = useStyle();

    return <hr css={dividerStyle(tokens)} {...rest} />;
};
