import React from 'react';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { IScript } from '../../shared/motive/models/Script';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { ScriptItemContainer } from '../../containers/scriptItemContainer';
import { ScriptItem } from '../scriptItem';

interface IScriptItemProps {
    catalogItem?: ICatalog<IScript>;
    onScriptClicked?: (script: IScriptObjectModel) => void;
}

export const Scripts: React.FC<IScriptItemProps> = ({ catalogItem, onScriptClicked }): React.ReactElement => {
    return (
        <>
            {catalogItem &&
                catalogItem.items &&
                catalogItem.items.map(script => {
                    return (
                        <ScriptItemContainer key={script.id}>
                            {props => (
                                <ScriptItem
                                    catalogItem={catalogItem}
                                    deleteScriptAction={props.deleteScriptAction}
                                    renameScriptAction={props.renameScriptAction}
                                    onScriptClicked={onScriptClicked}
                                    script={script}
                                ></ScriptItem>
                            )}
                        </ScriptItemContainer>
                    );
                })}
        </>
    );
};
