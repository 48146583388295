import React, { Ref, forwardRef } from 'react';
import { Input, IInput } from '@chakra-ui/core';
import { Size, InputSize } from '../constants/Size';
import { ChangeEvent } from 'react';
import {
    invalidInputFieldStyle,
    disabledInputFieldStyle,
    readOnlyInputFieldStyle,
    smallInputFieldStyle,
    mediumInputFieldStyle,
    largeInputFieldStyle,
    baseInputFieldStyle
} from './InputField.style';
import { IToken } from '../../shared/contexts/styleContext';
import { useStyle } from '../../shared/hooks/useStyle';
import { getChakraSize } from '../utils/SizeMapping';

type NativeCallback = (evt: ChangeEvent<HTMLInputElement>) => void;
/**
 * Additional useful props from IInput<HTMLInputElement>
 * @props {boolean} isInvalid
 * @props {boolean} isDisabled
 * @props {boolean} isReadOnly
 */

export interface IInputFieldProps extends Omit<IInput<HTMLInputElement>, 'size' | 'variant'> {
    key?: string;
    autoFocus?: boolean;
    className?: string;
    type?: string;
    title?: string;
    name?: string;
    testId?: string;
    value?: string | number;
    defaultValue?: string | number;
    size?: InputSize;
    autoComplete?: string;
    placeholder?: string;
    suggestionList?: string | undefined;
    useNativeOnChange?: boolean; // Workaround because we have swallowed the native event here.
    onChange?: (value: string) => void | NativeCallback;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    ref?: Ref<HTMLInputElement>;
    id?: string;
}

const getInputFieldSizeStyle = (size: Size, token: IToken) => {
    switch (size) {
        case Size.SMALL:
            return smallInputFieldStyle(token);
        case Size.MEDIUM:
            return mediumInputFieldStyle(token);
        case Size.LARGE:
            return largeInputFieldStyle(token);
        default:
            return mediumInputFieldStyle(token);
    }
};

const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

export const InputField = forwardRef<HTMLInputElement, IInputFieldProps>(function InputField(
    { size, onChange, onBlur, onKeyDown, value, onFocus, isInvalid, suggestionList, useNativeOnChange, ...props },
    ref
) {
    const token = useStyle();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        onChange && onChange(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        event.stopPropagation();
        onKeyDown && onKeyDown(event);
    };

    return (
        <Input
            {...props}
            size={getChakraSize(size) as 'sm' | 'md' | 'lg'}
            variant="unstyled"
            onChange={useNativeOnChange ? ((onChange as unknown) as NativeCallback) : handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={handleKeyDown}
            value={value}
            onClick={stopPropagation}
            list={suggestionList}
            css={[
                baseInputFieldStyle(token),
                size && getInputFieldSizeStyle(size, token),
                isInvalid && invalidInputFieldStyle(token),
                props.isDisabled && disabledInputFieldStyle(token),
                props.isReadOnly && readOnlyInputFieldStyle()
            ]}
            ref={ref}
        />
    );
});
