import React, { useEffect, useState } from 'react';
import { IObjectEditorRendererProps } from '../../containers/objectEditor';
import { useObjectDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo';
import { createScriptObject } from '../../util/MotiveUtils';
import { IFieldDefinition, IObjectDefinitionMap, IValueDefinition } from '../../shared/motive/models/TypeDefinition';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { IScriptObjectModel, FieldTypes, getObjectDict } from '../../shared/motive/models/ScriptObjectModel';
import { IDynamicValueComparer } from '../../shared/motive/models/IDynamicValueComparer';

interface IDynamicValueComparerEditorProps extends IObjectEditorRendererProps<IScriptObjectModel> {
    valueDefinition: IValueDefinition;
    comparerFieldDefinition: IFieldDefinition;
}

function getComparersForType(objDefs: IObjectDefinitionMap, typeName: string) {
    const comparerDef = objDefs[MotiveTypes.DYNAMIC_VALUE_COMPARER];

    const comparers = [comparerDef.name];

    return comparers.concat(
        comparerDef?.implementors.filter(i => {
            const impDef = objDefs[i];

            return impDef?.editorInfo?.associatedTypes?.includes(typeName);
        })
    );
}

export const DynamicValueComparerEditor: React.FC<IDynamicValueComparerEditorProps> = (props): React.ReactElement => {
    const objDefs = useObjectDefinitions();
    const value = props.value;
    const valueDict = getObjectDict(value);
    const fieldName = props.comparerFieldDefinition.name;
    const comparers = getComparersForType(objDefs, props.valueDefinition.typeName);
    const comparer = valueDict?.[fieldName] as IDynamicValueComparer;
    const path = `${props.path}.${fieldName}`;
    const [valueDefinitionId, setValueDefinitionId] = useState(props.valueDefinition.id);

    const handleOnComparerChange = (updatedValue: FieldTypes) => {
        // If the comparer value def hasn't been set, intercept the onChange call and
        // set it before passing it up.
        if (updatedValue) {
            let newComparer = updatedValue as IDynamicValueComparer;
            if (!newComparer.compareValue?.valueDefinition) {
                newComparer = {
                    ...newComparer,
                    compareValue: {
                        value: undefined,
                        valueDefinition: props.valueDefinition
                    }
                };

                props.onChange(path, newComparer);

                return;
            }
        }

        props.onChange(path, updatedValue);
    };

    useEffect(() => {
        // Initialize the comparer if:
        // - It's not currently set and there is only one valid comparer (the default)
        // - The current comparer type is not a valid type
        if (valueDefinitionId !== props.valueDefinition.id) {
            if (comparers.length <= 1) {
                const defaultComparer = createScriptObject<IDynamicValueComparer>(
                    MotiveTypes.DYNAMIC_VALUE_COMPARER,
                    objDefs
                );
                defaultComparer.compareValue = {
                    value: undefined,
                    valueDefinition: props.valueDefinition
                };

                props.onChange(path, defaultComparer);
            } else {
                props.onChange(path, undefined);
            }

            setValueDefinitionId(props.valueDefinition.id);
        }
    }, [props.valueDefinition]);

    const objProps = props as IObjectEditorRendererProps;

    const renderComparer = () => {
        return props.renderField(objProps, props.comparerFieldDefinition, comparer, props => ({
            ...props,
            overrideImplementors: comparers,
            onChange: handleOnComparerChange
        }));
    };

    return <>{renderComparer()}</>;
};
