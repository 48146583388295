import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: 0,
        transform: 'translate(-50%, -50%)'
    }
};

export const errorMessageStyle = (token: IToken) => css`
    background-color: ${token.colors.background.lighter};
    min-height: 10vh;
    margin: ${token.spacings.smaller} 0;
    padding: ${token.spacings.smaller} 0;
`;

export const modalFlexStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-direction: column;
`;

export const modalContentWrapper = (token: IToken) => css`
    margin: 0 0 ${token.spacings.smaller} 0;
`;

export const modalActionWrapper = css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const modalHeaderStyle = (token: IToken) => css`
    padding: ${token.spacings.smaller} ${token.spacings.smaller};
    border-bottom: 1px solid ${token.colors.border.primary};
`;

export const modalBodyStyle = (token: IToken) => css`
    padding: ${token.spacings.smaller};
`;

export const modalFooterStyle = (token: IToken) => css`
    padding: ${token.spacings.smaller};
    border-top: 1px solid ${token.colors.border.primary};
`;
