import React, { useState, useEffect } from 'react';
import { fullLoaderStyle, loaderWrapperStyle, fullLoaderChildrenStyle } from './FullLoader.style';
import { SimpleTransition } from '../simpleTransition/SimpleTransition';

interface IFullLoaderProps {
    isLoading: boolean;
    minimumTime?: number;
}

const logo = '/img/storyflow-loading-logo.svg';
const fullLoader = '/img/fullLoader.gif';

export const FullLoader: React.FC<IFullLoaderProps> = ({
    isLoading,
    children,
    minimumTime = 0
}): React.ReactElement => {
    const [delay, setDelay] = useState(minimumTime > 0 ? true : false);

    useEffect(() => {
        setTimeout(() => setDelay(false), minimumTime);
    }, [minimumTime]);

    const showLoader = isLoading || delay;
    return (
        <>
            <SimpleTransition active={showLoader} variant={'fade-in-out'}>
                <div css={fullLoaderStyle}>
                    <img width={400} height={120} src={logo} alt={`Storyflow`} />
                    <img css={loaderWrapperStyle} width={80} height={80} alt="" src={fullLoader} />
                </div>
            </SimpleTransition>
            <SimpleTransition active={!showLoader} variant={'fade-in'} css={fullLoaderChildrenStyle}>
                {children}
            </SimpleTransition>
        </>
    );
};
