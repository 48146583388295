import React, { useMemo } from 'react';
import { IFieldEditorProps } from '../ObjectEditor';
import { Dropdown, IDropdownOption } from '../../../core-ui/dropdown';
import { IFieldDefinition } from '../../../shared/motive/models/TypeDefinition';
import { useTypeDefinition } from '../../../shared/motive/hooks/useScriptEditorInfo';
import { generateEnumOptions } from '../../../core-ui/utils/ComponentUtils';
import { IEnumDefinition } from '../../../shared/motive/models/EnumDefinition';

interface IEnumEditorDropdownProps {
    onChange: (val: string) => void;
    options: IDropdownOption[];
    fieldDefinition: IFieldDefinition;
    value?: string;
}

const EnumEditorDropdown = React.memo(function EnumEditorDropdown(props: IEnumEditorDropdownProps) {
    return <Dropdown value={props.value} options={props.options} onChange={props.onChange}></Dropdown>;
});

export const EnumItemStringEditor: React.FC<IFieldEditorProps<string>> = ({
    fieldDefinition,
    value,
    type,
    onChange
}) => {
    const enumDefinition = useTypeDefinition<IEnumDefinition>(type);
    const enumOpts = useMemo(
        () => generateEnumOptions(enumDefinition.items, fieldDefinition.isNullable || value === undefined),
        [enumDefinition]
    );

    const handleChange = (itemId?: string) => {
        const item = enumDefinition.items?.find(i => i.id == itemId);

        if (item) {
            onChange(item.name);
        } else {
            onChange(undefined);
        }
    };

    const currEnumOption = enumDefinition.items?.find(c => c.name === value);

    return (
        <EnumEditorDropdown
            value={currEnumOption?.id}
            fieldDefinition={fieldDefinition}
            options={enumOpts}
            onChange={handleChange}
        />
    );
};
