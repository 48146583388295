import React from 'react';
import {
    labelTextStyle,
    localizedVideoStyle,
    localizedAudioStyle,
    localizedImageStyle,
    simpleIconStyle,
    pillStyle,
    mediaFlexStyle
} from './MediaItemPreview.style';
import { Text } from '../../core-ui/text';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Icon } from '../../core-ui/icon';
import { Size } from '../../core-ui/constants/Size';
import { Pill } from '../../core-ui/pill';
import { IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { getViewableUrl } from '../../shared/motive/networking/MediaService';
import { useSpacesRoute } from '../../routes/spacesRoute';
import { getSmartMediaSourceName, getFilename } from '../../util/ObjectDefinitionsUtils';

interface IMediaPreviewProps {
    className?: string;
    mediaItem: IHostedMediaSource;
    withLabel?: boolean;
    loadImagesOnly?: boolean;
    onLabelClick?: () => void;
    title?: string;
}

interface IMediaSourcePlayerProps {
    mediaSource: IHostedMediaSource;
    label?: string;
    loadImagesOnly?: boolean;
    className?: string;
}

// eslint-disable-next-line react/display-name
export const MediaSourcePlayer: React.FC<IMediaSourcePlayerProps> = React.memo(
    ({ className, mediaSource, label, loadImagesOnly = false }) => {
        const {
            params: { spaceName }
        } = useSpacesRoute();

        const [type] = mediaSource?.contentType ? mediaSource?.contentType.split('/') : [undefined, undefined];

        const url = spaceName && mediaSource?.id ? getViewableUrl(spaceName, mediaSource.id, true) : mediaSource?.url;

        const fileName = getFilename(mediaSource?.url);

        if (type === 'video') {
            return (
                <div css={mediaFlexStyle}>
                    {label && label}

                    {!loadImagesOnly ? (
                        <video className={className} css={localizedVideoStyle} controls>
                            <source src={url} type={mediaSource?.contentType} />
                        </video>
                    ) : (
                        <Icon css={simpleIconStyle} icon={IconTypes.MEDIA} />
                    )}
                </div>
            );
        }

        if (type === 'audio' || fileName?.endsWith('ogg')) {
            const contentType = fileName?.endsWith('ogg') ? 'audio/ogg' : mediaSource.contentType;

            return (
                <>
                    {label && label}
                    {!loadImagesOnly ? (
                        <audio className={className} css={localizedAudioStyle} controls>
                            <source src={url} type={contentType} />
                        </audio>
                    ) : (
                        <Icon css={simpleIconStyle} icon={IconTypes.HEADPHONES} />
                    )}
                </>
            );
        }

        if (type === 'image') {
            return (
                <div css={mediaFlexStyle}>
                    {label && label}
                    <img className={className} css={localizedImageStyle} src={url} alt={mediaSource.title as string} />
                </div>
            );
        }

        return <></>;
    }
);

export const MediaItemPreview: React.FC<IMediaPreviewProps> = ({
    className,
    mediaItem,
    withLabel = false,
    loadImagesOnly = false,
    onLabelClick,
    title
}) => {
    const {
        params: { spaceName }
    } = useSpacesRoute();

    const [type] = mediaItem?.contentType ? mediaItem?.contentType.split('/') : [undefined, undefined];

    const label = (
        <div>
            <Pill iconStyle={pillStyle} size={Size.MEDIUM} onClickClose={onLabelClick}>
                <Text size={Size.MEDIUM} css={labelTextStyle}>{`${title ?? getSmartMediaSourceName(mediaItem)}`}</Text>
            </Pill>
        </div>
    );

    const url = spaceName && mediaItem?.id ? getViewableUrl(spaceName, mediaItem.id, true) : mediaItem?.url;

    if (withLabel && type === undefined) {
        return <>{label}</>;
    }

    const fileName = getFilename(mediaItem?.url);

    // TODO: combine with above where possible: need to check conditional rendering?
    if (type === 'video') {
        return (
            <div css={mediaFlexStyle}>
                {withLabel && label}

                {!loadImagesOnly ? (
                    <video className={className} css={localizedVideoStyle} controls>
                        <source src={url} type={mediaItem?.contentType} />
                    </video>
                ) : (
                    <Icon css={simpleIconStyle} icon={IconTypes.MEDIA} />
                )}
            </div>
        );
    }

    if (type === 'audio' || fileName?.endsWith('ogg')) {
        const contentType = fileName?.endsWith('ogg') ? 'audio/ogg' : mediaItem.contentType;

        return (
            <div css={mediaFlexStyle}>
                {withLabel && label}
                {!loadImagesOnly ? (
                    <audio className={className} css={localizedAudioStyle} controls>
                        <source src={url} type={contentType} />
                    </audio>
                ) : (
                    <Icon css={simpleIconStyle} icon={IconTypes.HEADPHONES} />
                )}
            </div>
        );
    }

    if (type === 'image') {
        return (
            <div css={mediaFlexStyle}>
                {withLabel && label}
                <img className={className} css={localizedImageStyle} src={url} alt={mediaItem.title as string} />
            </div>
        );
    }

    return <>{withLabel && label}</>;
};
