import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const containerStyle = ({ spacings, colors }: IToken, width: number) => css`
    width: ${width}px;
    height: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: ${width - 10}px;
    grid-auto-columns: ${width - 10}px;
    grid-row-gap: ${spacings.smallest};
    background-color: ${colors.background.neutral};
    box-sizing: border-box;

    padding: ${spacings.smallest};
    & > button {
        font-size: 0.8em;
    }
`;
