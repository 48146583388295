import { GetProjectConfigs } from '../../networking/ProjectConfigService';
import { IProjectConfigurations, IProjectConfig } from '../../models/ProjectConfig';
import { useCurrentSpace } from '../useCurrentSpace';
import { IHookProvidedDataSource } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallForDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { useSelector, useDispatch } from 'react-redux';
import { SelectProjectConfigsState } from '../../../../redux/spaceKeyed/projectConfig/ProjectConfigSelectors';
import { useCurrentProject } from '../useCurrentProject';

export const useProjectConfigs = (): IHookProvidedDataSource<IProjectConfigurations> => {
    const [currentSpace] = useCurrentSpace();

    const projectConfigState = useSelector(SelectProjectConfigsState);
    const dispatch = useDispatch();

    const { triggerCall, ...rest } = useNetworkCallForDataSource(projectConfigState);

    const call = async () => {
        await triggerCall(GetProjectConfigs(currentSpace, dispatch));
    };

    return { data: projectConfigState.configData, load: call, ...rest };
};

export const useProjectConfig = (
    projectId: string | undefined
): IHookProvidedDataSource<IProjectConfig | undefined> => {
    const allConfigs = useProjectConfigs();
    const config = allConfigs.data?.projectConfigs?.find(c => c.id === projectId);

    const { triggerCall, ...rest } = useNetworkCallForDataSource(config);

    const load = async () => {
        await triggerCall(allConfigs.load());
    };

    return { ...rest, data: config ? config : undefined, load: load };
};

export const useCurrentProjectId = (): string | undefined => {
    const [projectId] = useCurrentProject();

    return projectId;
};
