import { httpClient } from './HttpClient';
import { ICollaborator, ISpaceCollaboratorRequest, IPendingCollaborator } from '../models/Collaborator';
import { tryUseCachedForGetReq, UseStaleWhileFetchingStrategy } from './StaleWhileFetching';
import { Dispatch } from 'redux';
import {
    collaboratorSetAction,
    collaboratorClearAction,
    pendingCollaboratorSetAction,
    collaboratorDeleteAction,
    pendingCollaboratorDeleteAction
} from '../../../redux/spaceKeyed/collaborator/CollaboratorActions';

export const collaboratorPath = (spaceName: string) => `api/space/${spaceName}/collaborators/`;
const GetCollaboratorsLegacy = async (spaceName: string, dispatch: Dispatch): Promise<void> => {
    try {
        const collaboratorResponse = await httpClient.get(collaboratorPath(spaceName));

        dispatch(collaboratorSetAction(collaboratorResponse.data));
    } catch (error) {
        dispatch(collaboratorClearAction());
    }
};

const GetCollaboratorsSWF = async (spaceName: string, dispatch: Dispatch): Promise<void> => {
    const url = collaboratorPath(spaceName);
    await tryUseCachedForGetReq(
        url,
        httpClient.get(url),
        data => dispatch(collaboratorSetAction(data as ICollaborator[])),
        () => dispatch(collaboratorClearAction())
    );
};

export const GetCollaborators = UseStaleWhileFetchingStrategy ? GetCollaboratorsSWF : GetCollaboratorsLegacy;

export const pendingPath = (spaceName: string) => `api/space/${spaceName}/collaborators/invitations`;
const GetPendingCollaboratorsLegacy = async (spaceName: string, dispatch: Dispatch): Promise<void> => {
    try {
        const collaboratorResponse = await httpClient.get(pendingPath(spaceName));

        dispatch(pendingCollaboratorSetAction(collaboratorResponse.data));
    } catch (error) {}
};

const GetPendingCollaboratorsSWF = async (spaceName: string, dispatch: Dispatch): Promise<void> => {
    const url = pendingPath(spaceName);
    await tryUseCachedForGetReq(
        url,
        httpClient.get(url),
        data => dispatch(pendingCollaboratorSetAction(data as IPendingCollaborator[])),
        () => {}
    );
};

export const GetPendingCollaborators = UseStaleWhileFetchingStrategy
    ? GetPendingCollaboratorsSWF
    : GetPendingCollaboratorsLegacy;

export const addCollabPath = (spaceName: string) => `api/space/${spaceName}/collaborators`;
export const AddCollaborator = async (
    spaceName: string,
    email: string,
    inviterEmail: string,
    dispatch: Dispatch
): Promise<void> => {
    try {
        const collabPayload: ISpaceCollaboratorRequest = {
            spaceName: spaceName,
            user: { email: email }
        };

        const collaboratorResponse = await httpClient.post<ICollaborator[]>(addCollabPath(spaceName), collabPayload);

        if (collaboratorResponse.data.find(c => c.email.toLowerCase() === email.toLowerCase())) {
            dispatch(collaboratorSetAction(collaboratorResponse.data));
        } else {
            //If the collaborator was not added in our response, he must be pending
            await GetPendingCollaborators(spaceName, dispatch);
        }
    } catch (error) {}
};

export const DeleteCollaborator = async (
    spaceName: string,
    collaborator: ICollaborator | IPendingCollaborator,
    dispatch: Dispatch
): Promise<void> => {
    if ((collaborator as ICollaborator).email) {
        await DeleteCurrentCollaborator(spaceName, collaborator as ICollaborator, dispatch);
    } else if ((collaborator as IPendingCollaborator).InviteeEmail) {
        await DeletePendingCollaborator(spaceName, collaborator as IPendingCollaborator, dispatch);
    }
};

export const deleteCollabPath = (spaceName: string, collaborator: ICollaborator) =>
    `api/space/${spaceName}/collaborators/?userId=${collaborator.id}`;

export const DeleteCurrentCollaborator = async (
    spaceName: string,
    collaborator: ICollaborator,
    dispatch: Dispatch
): Promise<void> => {
    try {
        await httpClient.delete(deleteCollabPath(spaceName, collaborator));

        dispatch(collaboratorDeleteAction(`${collaborator.id}`));
    } catch (error) {}
};

export const deletePendingCollabPath = (spaceName: string, collaborator: IPendingCollaborator) =>
    `editor/pendingInvites?spaceId=${collaborator.SpaceId}&inviterId=${collaborator.InviterId}&inviteeEmail=${collaborator.InviteeEmail}`;

export const DeletePendingCollaborator = async (
    spaceName: string,
    collaborator: IPendingCollaborator,
    dispatch: Dispatch
): Promise<void> => {
    try {
        await httpClient.delete(deletePendingCollabPath(spaceName, collaborator));

        dispatch(pendingCollaboratorDeleteAction(collaborator.InviteeEmail));
    } catch (error) {}
};
