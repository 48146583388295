import { IToken } from './../../shared/contexts/styleContext/interfaces/Token';
import { css } from '@emotion/core';

export const selectedScenarioStyle = (token: IToken) => css`
    background-color: ${token.colors.background.light};
`;

export const scenarioStyle = (token: IToken) => css`
    padding: ${token.spacings.medium};
    background-color: ${token.colors.background.lighter};
    &:hover {
        background-color: ${token.colors.background.light};
    }
`;
export const scenarioRowStyle = css`
    border-bottom: 3px solid transparent;
`;

export const scenarioEditorLayout = (token: IToken) => css`
    padding: ${token.spacings.medium};
    border-right: 1px solid ${token.colors.border.primary};
    border-bottom: 1px solid ${token.colors.border.primary};
    overflow: auto;
    border-top: none;
    border-radius: 0;
`;
