import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const dashboardConvoAILayout = css`
    display: grid;
    grid-template-rows: auto 1fr;
    height: 97%;
`;

export const dashboardConvoAIBodyStyle = (token: IToken) => css`
    border-radius: 0;
    border: 1px solid ${token.colors.border.primary};
    border-top: transparent;
`;

export const dashboardConvoAIBodyContentStyle = (token: IToken) => css`
    padding: ${token.spacings.small} ${token.spacings.medium} ${token.spacings.medium} ${token.spacings.medium};
    overflow-y: overlay;
`;

export const dashboardConvoAIWrapperStyle = (token: IToken) => css`
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: ${token.spacings.small};
`;

export const createButtonStyle = (token: IToken) => css`
    background-color: ${token.colors.interactible.secondary};
    margin-left: 12px;
`;
