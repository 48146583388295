import React from 'react';
import { CatalogsContainer } from '../catalogsContainer';
import { useDashboardDrawer } from '../../hooks/useDashboardDrawer';
import { useCurrentProjectId } from '../../shared/motive/hooks/useProjectConfigs/useProjectConfigs';
import { HeightUsedScrollContainer } from '../heightUsedScrollContainer';
import { ICatalogInfo } from '../../shared/motive/models/Catalog';

export interface IDrawerCatalogsTabContainerProps {
    type?: string;
    blackListTypes?: string[];
    allowQuickObjectEdit?: boolean;
    allowQuickObjectAdd?: boolean;
    showItemFilter?: boolean;
    collapsible?: boolean;
}

export const DrawerCatalogsTabContainer: React.FC<IDrawerCatalogsTabContainerProps> = ({
    type,
    blackListTypes,
    allowQuickObjectEdit,
    showItemFilter,
    collapsible,
    allowQuickObjectAdd
}) => {
    const whiteListTypes = type ? [type] : undefined;
    const projectConfigId = useCurrentProjectId();
    const { handleClickCatalog } = useDashboardDrawer();

    if (!projectConfigId) {
        throw new Error('no project name found');
    }

    const handleSelectCatalog = (catalogInfo: ICatalogInfo) => {
        handleClickCatalog(catalogInfo);
    };

    return (
        <HeightUsedScrollContainer>
            <CatalogsContainer
                whiteListTypes={whiteListTypes}
                blackListTypes={blackListTypes}
                projectConfigId={projectConfigId}
                onSelectCatalog={handleSelectCatalog}
                allowQuickObjectEdit={allowQuickObjectEdit}
                collapsible={collapsible}
                allowQuickAddItem={allowQuickObjectAdd}
                showItemSearch={showItemFilter}
                itemsAreDraggable={true}
            />
        </HeightUsedScrollContainer>
    );
};
