import { SPACE_ENDPOINT } from '../constants/ApiEndpoint';
import { httpClient } from './HttpClient';
import { IScript } from '../models/Script';
import {
    catalogItemAppendAction,
    catalogItemUpdateAction,
    catalogItemDeleteAction
} from '../../../redux/spaceKeyed/catalog/CatalogItemActions';
import { AllCatalogActions } from '../../../redux/spaceKeyed/catalog/CatalogsReducer';

export const createScriptPath = (spaceName: string, catalogId: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/catalogs/${catalogId}/items`;
};

export const saveScriptPath = (spaceName: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/StoryFlow/Save`;
};

export const patchScriptPath = (spaceName: string, catalogId: string, scriptId: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/scriptCatalogs/${catalogId}/script/${scriptId}/metadata`;
};

export const deleteScriptPath = (spaceName: string, catalogId: string, scriptId: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/scriptCatalogs/${catalogId}/scripts/${scriptId}/`;
};

export const duplicateScriptPath = (spaceName: string, catalogId: string, scriptId: string) => {
    return `${SPACE_ENDPOINT}/${spaceName}/scriptCatalogs/${catalogId}/scripts/${scriptId}/copy`;
};

export const createScriptNetworkCall = async (
    spaceName: string,
    script: IScript,
    catalogId: string,
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    try {
        const response = await httpClient.post(createScriptPath(spaceName, catalogId), script);
        dispatch(catalogItemAppendAction(catalogId, response.data));
    } catch (error) {
        throw new Error(error);
    }
};

export const saveScriptNetworkCall = async (
    spaceName: string,
    script: IScript,
    dispatch: (action: AllCatalogActions) => void,
    overwrite: boolean = false
) => {
    try {
        const response = await httpClient.post<IScript>(
            `${saveScriptPath(spaceName)}${overwrite ? `?overwrite=true` : ``}`,
            script
        );
        dispatch(catalogItemUpdateAction(response.data));
        return response;
    } catch (e) {
        throw e;
    }
};

export const renameScriptNetworkCall = async (
    spaceName: string,
    catalogId: string,
    scriptId: string,
    scriptName: string,
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    try {
        const response = await httpClient.patch(patchScriptPath(spaceName, catalogId, scriptId), { name: scriptName });

        dispatch(catalogItemUpdateAction(response.data));
    } catch (error) {
        throw new Error(error);
    }
};

export const deleteScriptNetworkCall = async (
    spaceName: string,
    scriptId: string,
    catalogId: string,
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    try {
        await httpClient.delete(deleteScriptPath(spaceName, catalogId, scriptId));
        dispatch(catalogItemDeleteAction(catalogId, scriptId));
    } catch (error) {
        throw error;
    }
};

export const duplicateScriptNetworkCall = async (
    spaceName: string,
    scriptId: string,
    catalogId: string,
    dispatch: (action: AllCatalogActions) => void
): Promise<void> => {
    try {
        const response = await httpClient.post(duplicateScriptPath(spaceName, catalogId, scriptId));
        dispatch(catalogItemAppendAction(catalogId, response.data));
    } catch (error) {
        throw error;
    }
};
