import { ICatalog } from '../../../shared/motive/models/Catalog';
import { IScriptObjectModel } from '../../../shared/motive/models/ScriptObjectModel';
import { produce } from 'immer';
import { Reducer } from 'redux';
import { CatalogActions } from './CatalogActions';
import { CatalogItemActions } from './CatalogItemActions';

export interface ICatalogsState {
    catalogData: ICatalog<IScriptObjectModel>[];
    allCatalogsLoaded: boolean;
}

const DEFAULT_CATALOGS_STORE_STORE_VALUE: ICatalogsState = {
    catalogData: [],
    allCatalogsLoaded: false
};

export const replaceCatalogsByType = (
    currentState: ICatalog<IScriptObjectModel>[],
    catalogsOfType: ICatalog<IScriptObjectModel>[],
    catalogTypes: string[]
): ICatalog<IScriptObjectModel>[] => {
    return [
        ...currentState.filter(catalog => !catalogTypes.some(type => type === catalog.objectType)),
        ...catalogsOfType
    ];
};

export type AllCatalogActions = CatalogActions | CatalogItemActions;

export const CatalogsReducer: Reducer<ICatalogsState, AllCatalogActions> = produce(
    (state: ICatalogsState, action: AllCatalogActions) => {
        switch (action.type) {
            case 'catalog/set': {
                state.catalogData = action.catalogs;
                state.allCatalogsLoaded = true;
                break;
            }
            case 'catalog/append': {
                if (state.catalogData.some(cat => cat.id === action.catalog.id)) {
                    throw new Error('A catalog with the same id already exists, did you mean to update the catalog?');
                }

                state.catalogData.push(action.catalog);
                break;
            }
            case 'catalog/update': {
                const idx = state.catalogData.findIndex(cat => cat.id === action.catalog.id);

                if (idx >= 0) {
                    state.catalogData[idx] = action.catalog;
                }

                break;
            }
            case 'catalog/delete': {
                state.catalogData = state.catalogData.filter(cat => cat.id !== action.id);
                break;
            }
            case 'catalog/set/types': {
                state.catalogData = replaceCatalogsByType(state.catalogData, action.catalogs, action.catalogType);
                break;
            }
            case 'catalog/set/id': {
                const idx = state.catalogData.findIndex(c => c.id === action.catalog.id);

                if (idx >= 0) {
                    state.catalogData[idx] = action.catalog;
                } else {
                    state.catalogData.push(action.catalog);
                }

                break;
            }
            case 'catalog/rename': {
                const idx = state.catalogData.findIndex(cat => cat.id === action.id);

                if (idx >= 0) {
                    state.catalogData[idx].title = action.title;
                    state.catalogData[idx].name = action.name;
                }

                break;
            }

            case 'catalog/item/append': {
                const idx = state.catalogData.findIndex(cat => cat.id === action.catalogId);

                if (idx >= 0) {
                    if (!state.catalogData[idx].items) {
                        state.catalogData[idx].items = [];
                    }

                    state.catalogData[idx].items?.push(action.item);
                }

                break;
            }
            case 'catalog/item/update': {
                const idx = state.catalogData.findIndex(
                    cat => cat.items && cat.items.some(item => item.id === action.item.id)
                );

                if (idx >= 0) {
                    const catalogItems = state.catalogData[idx].items;
                    if (catalogItems) {
                        const itemIdx = catalogItems.findIndex(item => item.id === action.item.id);

                        if (itemIdx >= 0) {
                            catalogItems[itemIdx] = action.item;
                        }
                    }
                }
                break;
            }
            case 'catalog/item/delete': {
                const idx = state.catalogData.findIndex(cat => cat.id === action.catalogId);

                if (idx >= 0) {
                    state.catalogData[idx].items = state.catalogData[idx].items?.filter(item => item.id !== action.id);
                }

                break;
            }
        }
    },
    DEFAULT_CATALOGS_STORE_STORE_VALUE
);
