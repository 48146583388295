import { IPollyVoice } from '../../../../redux/spaceKeyed/textToSpeech/TextToSpeechReducer';
import { getTextToSpeechVoicesCall } from '../../networking/TextToSpeechService';
import { IHookProvidedDataSource } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useCurrentSpace } from '../useCurrentSpace';
import { useNetworkCallForDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { useDispatch, useSelector } from 'react-redux';
import { SelectTextToSpeechState } from '../../../../redux/spaceKeyed/textToSpeech/TextToSpeechSelectors';

export const useTextToSpeechVoices = (): IHookProvidedDataSource<IPollyVoice[]> => {
    const [space] = useCurrentSpace();
    const { voices } = useSelector(SelectTextToSpeechState);
    const dispatch = useDispatch();

    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallForDataSource(voices);

    const load = async () => {
        await triggerCall(getTextToSpeechVoicesCall(space, dispatch));
    };

    return {
        data: voices,
        load: load,
        ...rest
    };
};
