import React from 'react';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { Button, ButtonVariant } from '../../core-ui/button';
import { ObjectEditor, IObjectEditorProps } from '../../containers/objectEditor/ObjectEditor';
import {
    catalogItemInspectorStyle,
    catalogEditorWrapperStyle,
    catalogItemsWrapperStyle,
    catalogInspectorStyle,
    topBarStyle,
    saveButtonStyle,
    catalogInspectorBarStyle,
    objectEditorStyle,
    catalogEditorEmptyCollectionStyle,
    catalogEditorHeaderWrapper,
    catalogEditorLayout
} from './CatalogEditor.style';
import { ICatalogContainerRenderProps } from '../../containers/catalogContainer';
import { CatalogItemList } from '../catalogItemList';
import { Card } from '../../core-ui/card';
import { useStyle } from '../../shared/hooks/useStyle';
import { ScrollZone } from '../../core-ui/scrollZone/ScrollZone';
import { Prompt } from 'react-router';
import { Icon } from '../../core-ui/icon';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { getSmartName } from '../../util/ObjectDefinitionsUtils';
import { useTypeDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import { useCurrentLanguageSettings } from '../../shared/motive/stores/editorLocale/EditorLocale';
import { buttonLayoutStyle, buttonSpanStyle } from './CatalogEditor.style';
import { rotating } from '../../constants/AnimationStyles';
import { Breadcrumbs } from '../../core-ui/breadcrumbs/Breadcrumbs';
import { EmptyState } from '../../core-ui/emptyStates/EmptyState';
import { useTranslation } from 'react-i18next';

interface ICatalogEditorProps extends ICatalogContainerRenderProps {
    selectedItem?: IScriptObjectModel;
    isChoosingExternal?: boolean;
    onSelectItem: (item: IScriptObjectModel) => void;
    onConfirmChoose: (item: IScriptObjectModel) => void;
    onGoBackToCatalogs: () => void;
}

interface ICatalogItemInspectorProps {
    catalogItem?: IScriptObjectModel;
    onChange: IObjectEditorProps['onChange'];
    onDelete: (index: number) => void;
    path: string;
    itemIndex?: number;
}

export const CatalogEditor: React.FC<ICatalogEditorProps> = (props): React.ReactElement => {
    const token = useStyle();
    const { t } = useTranslation();
    const selectedItemIndex = props.catalog?.items?.findIndex(item => item.id === props.selectedItem?.id);
    let showButton: 'save' | 'saving' | 'noChanges' | 'confirmItemForChoose' | 'selectItemFromList' = 'noChanges';

    const handleConfirm = () => {
        props.selectedItem && props.onConfirmChoose(props.selectedItem);
    };
    const buttonMap = {
        save: (
            <Button onClick={props.onCatalogSave} variant={ButtonVariant.SOLID} css={saveButtonStyle}>
                {t('save')}
            </Button>
        ),
        saving: (
            <Button
                variant={ButtonVariant.SOLID}
                css={saveButtonStyle}
                icon={IconTypes.LOAD_SPINNER}
                iconStyle={rotating}
            ></Button>
        ),
        noChanges: (
            <Button disabled css={saveButtonStyle}>
                {t('save')}
            </Button>
        ),
        confirmItemForChoose: (
            <Button onClick={handleConfirm} variant={ButtonVariant.SOLID}>
                {t('confirmItemForUse')}
            </Button>
        ),
        selectItemFromList: (
            <Button disabled variant={ButtonVariant.HOLLOW}>
                {t('selectItemFromList')}
            </Button>
        ),
        returnToCatalogs: (
            <Button onClick={props.onGoBackToCatalogs} variant={ButtonVariant.GHOST}>
                {t('returnToCatalogs')}
            </Button>
        )
    };

    if (props.isCatalogSaving) {
        showButton = 'saving';
    } else if (props.isCatalogDirty) {
        showButton = 'save';
    } else if (props.isChoosingExternal) {
        showButton = 'confirmItemForChoose';
    }

    return (
        <>
            <Prompt message={t('confirmALeave')} when={props.isCatalogDirty} />
            {props.catalog && (
                <div css={catalogEditorLayout}>
                    <Breadcrumbs
                        crumbs={[{ name: '', onClick: props.onGoBackToCatalogs }, { name: props.catalog.title }]}
                    ></Breadcrumbs>
                    <Card css={catalogEditorWrapperStyle}>
                        <ScrollZone css={catalogItemsWrapperStyle(token)}>
                            <div css={catalogEditorHeaderWrapper}>
                                <span css={topBarStyle(token)}>
                                    <Button onClick={props.onCreateEmptyCatalogItem} icon={IconTypes.PLUS}></Button>
                                    <div css={buttonLayoutStyle}>
                                        <span css={buttonSpanStyle(token)}>{buttonMap[showButton]}</span>
                                    </div>
                                </span>
                            </div>

                            <CatalogItemList
                                catalogId={props.catalog.id}
                                items={props.catalog?.items ? props.catalog?.items : []}
                                onSelectItem={props.onSelectItem}
                                selectedItem={props.selectedItem}
                                allowQuickEdit={false}
                            />
                        </ScrollZone>
                        <ScrollZone>
                            <CatalogItemInspector
                                onDelete={props.onDeleteCatalogItem}
                                itemIndex={selectedItemIndex as number}
                                catalogItem={props.selectedItem}
                                onChange={props.onChange}
                                path={`${props.path}[${selectedItemIndex}]`}
                            />
                        </ScrollZone>
                    </Card>
                </div>
            )}
        </>
    );
};

export const CatalogItemInspector: React.FC<ICatalogItemInspectorProps> = ({
    catalogItem,
    onChange,
    onDelete,
    itemIndex,
    path
}) => {
    const handleDeleteItem = () => onDelete(itemIndex as number);
    const token = useStyle();
    const { t } = useTranslation();
    const typeDefs = useTypeDefinitions();
    const language = useCurrentLanguageSettings();
    return (
        <>
            {catalogItem ? (
                <div css={catalogItemInspectorStyle}>
                    <div css={catalogInspectorStyle}>
                        <div css={catalogInspectorBarStyle(token)}>
                            <span>{getSmartName(catalogItem, typeDefs, language)}</span>
                            <Icon onClick={handleDeleteItem} icon={IconTypes.DELETE_H}></Icon>
                        </div>
                        <div css={objectEditorStyle(token)}>
                            <ObjectEditor
                                key={catalogItem.id}
                                type={catalogItem.type}
                                value={catalogItem}
                                onChange={onChange}
                                path={path}
                            ></ObjectEditor>
                        </div>
                    </div>
                </div>
            ) : (
                <div css={catalogItemInspectorStyle}>
                    <div css={catalogInspectorStyle}>
                        <EmptyState css={catalogEditorEmptyCollectionStyle} icon={'folder-open'}>
                            {t('createOrSelectItem')}
                        </EmptyState>
                    </div>
                </div>
            )}
        </>
    );
};
