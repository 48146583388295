import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const barLayoutStyle = (height: number) => css`
    height: ${height}px;
`;

export const editFrameOptsStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
`;

export const frameNameContainerStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const barTabsStyle = css`
    width: 300px;
    align-items: right;
`;

export const frameEnableToggleStyle = css`
    padding: 10px;
`;

export const frameNameTextStyle = css`
    color: white;
`;

export const frameNameWrapperStyle = css`
    background-color: transparent;
    border-radius: 5px;
    display: flex;
    align-items: center;
`;

export const frameNameInputStyle = (token: IToken) => css`
    color: ${token.colors.font.primary};
    border-radius: 5px;
`;

export const optionMenuRow = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    width: 180px;
    height: 40px;
`;

export const frameSettingsButtonStyle = css`
    padding: 0;
    &:hover {
        color: black;
    }
`;
