import { ApplicationState } from '../AppReducer';
import { createSelector, Selector } from 'reselect';
import { subReducerTypes } from './spaceStateReducer';

export const SelectCurrentSpace = (state: ApplicationState) => state.workspace.currentSpace;
export const SelectWorkSpace = (state: ApplicationState) => state.workspace;

export const SelectCurrentProjectId = (state: ApplicationState) => state.workspace.currentProject;
export const SelectLastScript = (state: ApplicationState) => state.workspace.lastScript;

export const SelectCurrentSpaceState = createSelector(
    [SelectCurrentSpace, SelectWorkSpace],
    (currentSpace, workSpace) => {
        if (!currentSpace) {
            return {
                activity: {},
                bundle: {},
                catalog: {},
                catalogTypes: {},
                collaborator: {},
                media: {},
                project: {},
                projectConfig: {},
                scriptEditor: {},
                textToSpeech: {}
            };
        }
        return workSpace.spaceKeyState[currentSpace] as subReducerTypes;
    }
);

type KeyTypes =
    | 'activity'
    | 'bundle'
    | 'catalog'
    | 'catalogTypes'
    | 'collaborator'
    | 'media'
    | 'project'
    | 'projectConfig'
    | 'scriptEditor'
    | 'textToSpeech';

export function GenerateSubSelector<T>(key: KeyTypes): Selector<ApplicationState, T> {
    return createSelector(SelectCurrentSpaceState, workspace => {
        return workspace[key] as T;
    });
}

// Could also do something like so we can access the nested props easier e.g. selectPendingCollabs = createSubSelector((state: subReducerTypes) => state.collaborators.pendingCollaborators);

export function createSubSelector<T>(selector: (state: subReducerTypes) => T) {
    return createSelector(SelectCurrentSpaceState, workspace => selector(workspace as subReducerTypes));
}

// or a combination of the two
// export function GenerateSubSelector2<T>(
//     key: KeyTypes | ((state: subReducerTypes) => T)
// ): Selector<ApplicationState, T> {
//     return createSelector(SelectCurrentSpaceState, workspace => {
//         return typeof key === 'function' ? key(workspace as subReducerTypes) : (workspace[key] as T);
//     });
// }
