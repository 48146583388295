import { css } from '@emotion/core';

export const leftColRowFlexParent = css`
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 12px;
`;

export const middleContentBaseStyle = css`
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 50px;
    border-radius: 8px;
    color: white;
    justify-content: space-between;
`;

export const rowStyle = (rowHeight: number) => css`
    cursor: unset;
    height: ${rowHeight}px;
    padding: 0;
    display: grid;
    grid-template-columns: 9fr 1fr;
    font-size: 1rem;
    align-items: center;
`;

export const rowItemMiddleLayoutStyle = css`
    display: flex;
    align-items: center;
    margin: 0 10px;
`;

export const plusButtonContainer = css`
    display: flex;
    align-items: center;
`;

export const leftColChildrenParentStyle = css`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const dynamicBaseButtonStyle = css`
    height: 24px;
    width: 24px;
    font-size: 0.8rem;
    color: white;
`;

export const leftColWrapperStyle = (offset: number) => css`
    display: flex;
    align-items: center;
    padding-left: ${offset}px;
`;

export const flexGrowStyle = css`
    flex-grow: 1;
`;

export const dynamicIconStyle = css`
    color: white;
`;
