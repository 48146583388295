import { ICollaborator, IPendingCollaborator } from '../../../shared/motive/models/Collaborator';

export type CollaboratorActions = ReturnType<
    | typeof collaboratorSetAction
    | typeof collaboratorAppendAction
    | typeof collaboratorDeleteAction
    | typeof collaboratorClearAction
    | typeof pendingCollaboratorAppendAction
    | typeof pendingCollaboratorSetAction
    | typeof pendingCollaboratorDeleteAction
>;

export const collaboratorSetAction = (collaborators: ICollaborator[]) => {
    return {
        type: 'collaborator/set',
        collaborators
    } as const;
};

export const collaboratorClearAction = () => {
    return {
        type: 'collaborator/clear'
    } as const;
};

export const collaboratorAppendAction = (collaborator: ICollaborator) => {
    return {
        type: 'collaborator/add',
        collaborator
    } as const;
};

export const collaboratorDeleteAction = (id: string) => {
    return {
        type: 'collaborator/delete',
        id
    } as const;
};

export const pendingCollaboratorSetAction = (pendingCollabs: IPendingCollaborator[]) => {
    return {
        type: 'pendingCollaborator/set',
        pendingCollabs
    } as const;
};

export const pendingCollaboratorAppendAction = (pendingCollab: IPendingCollaborator) => {
    return {
        type: 'pendingCollaborator/append',
        pendingCollab
    } as const;
};

export const pendingCollaboratorDeleteAction = (email: string) => {
    return {
        type: 'pendingCollaborator/delete',
        email
    } as const;
};
