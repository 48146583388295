import React, { useEffect } from 'react';
import { Catalogs, ICatalogsProps } from '../../components/catalogs';
import { useDeleteCatalog, useRenameCatalog, useCreateCatalog } from '../../shared/motive/hooks/useCatalogs';
import { useAddProjectCatalogs } from '../../shared/motive/hooks/useCatalogs/useAddProjectCatalogs/useAddProjectCatalogs';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { useAddCatalogItem } from '../../shared/motive/hooks/useCatalogs/useAddCatalogItem/useAddCatalogItem';
import { ICatalogInfo } from '../../shared/motive/models/Catalog';
import { useToast } from '../../core-ui/hooks/useToast';
import { useCheckSystemNameConflict } from '../../shared/motive/hooks/useCheckSystemNameConflict/useCheckSystemNameConflict';
import { useSelector } from 'react-redux';
import { SelectAllCatalogs } from '../../redux/spaceKeyed/catalog/CatalogSelectors';
import { useCurrentProjectConfig } from '../../redux/spaceKeyed/projectConfig/ProjectConfigSelectors';
import { useTranslation } from 'react-i18next';

export interface ICatalogsContainerProps {
    children?: (props: ICatalogsProps) => React.ReactNode;
    whiteListTypes?: string[];
    blackListTypes?: string[];
    projectConfigId: string;
    allowQuickObjectEdit?: boolean;
    quickEditTitle?: string;
    collapsible?: boolean;
    initializeOpen?: boolean;
    onSelectCatalog?: (catalogInfo: ICatalogInfo) => void;
    onSelectItem?: (catalogId: string, item: IScriptObjectModel) => void;
    itemFilter?: string;
    itemsAreDraggable?: boolean;
    allowQuickAddItem?: boolean;
    quickAddTitle?: string;
    showItemSearch?: boolean;
}

export const CatalogsContainer: React.FC<ICatalogsContainerProps> = ({
    children,
    onSelectCatalog,
    collapsible,
    initializeOpen,
    whiteListTypes,
    projectConfigId,
    allowQuickObjectEdit,
    blackListTypes,
    itemFilter,
    itemsAreDraggable,
    allowQuickAddItem,
    showItemSearch,
    onSelectItem,
    quickAddTitle,
    quickEditTitle
}): React.ReactElement => {
    const { t } = useTranslation();
    if (!projectConfigId) {
        throw new Error('No project name was found');
    }
    const projectConfig = useCurrentProjectConfig();

    const toast = useToast();

    const allSpaceCatalogs = useSelector(SelectAllCatalogs);

    const { execute: handleDeleteCatalog, isLoading: deleteCatalogLoading } = useDeleteCatalog();
    const { execute: handleRenameCatalog, error: renameError } = useRenameCatalog();
    const { execute: handleCreateCatalog, error: createCatalogError } = useCreateCatalog();
    const { execute: addProjectCatalogs } = useAddProjectCatalogs();
    const { execute: addNewItem } = useAddCatalogItem();
    const checkNameConflict = useCheckSystemNameConflict();

    useEffect(() => {
        if (renameError) {
            toast({
                description: t('errorRenamingCatalog'),
                isClosable: true,
                position: 'bottom-right',
                status: 'error',
                title: t('errorMessage', { message: t('renamingCatalog') })
            });
        }
    }, [toast, renameError]);

    useEffect(() => {
        if (createCatalogError) {
            toast({
                description: t('errorCreatingCatalog'),
                isClosable: true,
                position: 'bottom-right',
                status: 'error',
                title: t('errorMessage', { message: t('creatingCatalog') })
            });
        }
    }, [toast, createCatalogError]);

    if (!projectConfig) {
        throw new Error('Unable to find project config');
    }

    const projectCatalogs = projectConfig.catalogs ?? [];

    const handleCatalogRowPressed = (catalogInfo: ICatalogInfo) => {
        onSelectCatalog && onSelectCatalog(catalogInfo);
    };

    const handleImportCatalogs: ICatalogsProps['onImportCatalogs'] = async (projectId, catalogIds) => {
        await addProjectCatalogs({ projectId: projectId, catalogIds: catalogIds });
    };

    const handleCreateAndImport = (title: string, type: string) => {
        if (checkNameConflict(title)) {
            toast({
                description: t('catalogNameAlreadyExists'),
                isClosable: true,
                position: 'bottom-right',
                status: 'error',
                title: t('errorMessage', { message: t('creatingCatalog') })
            });
        } else {
            handleCreateCatalog({ catalogTitle: title, objectType: type, importToCurrentProject: true }).then(() => {
                toast({
                    description: t('successfullyCreatedCatalog', { name: title }),
                    isClosable: true,
                    position: 'bottom-right',
                    status: 'success',
                    title: t('successMessage', { message: t('createdCatalog') })
                });
            });
        }
    };

    const handleDelete = async (id: string, onComplete?: () => void) => {
        await handleDeleteCatalog({ catalogId: id });

        if (!!onComplete) {
            onComplete();
        }
    };

    const handleRename = (id: string, name: string) => {
        if (checkNameConflict(name)) {
            toast({
                description: t('catalogNameAlreadyExists'),
                isClosable: true,
                position: 'bottom-right',
                status: 'error',
                title: t('errorMessage', { message: t('renamingCatalog') })
            });
        } else {
            handleRenameCatalog({ catalogId: id, catalogTitle: name }).then(() => {
                toast({
                    description: t('successfullyRenamedCatalog', { name }),
                    isClosable: true,
                    position: 'bottom-right',
                    status: 'success',
                    title: t('successMessage', { message: t('renamedCatalog') })
                });
            });
        }
    };

    const handleAddCatalogItem = async (catalogId: string, itemToAdd: IScriptObjectModel) => {
        await addNewItem({ catalogId, item: itemToAdd });
    };

    const catalogs = allSpaceCatalogs.filter(
        c =>
            projectCatalogs.some(pCat => pCat.id === c.id) &&
            (!blackListTypes || (blackListTypes && !blackListTypes.some(blackType => c.objectType === blackType))) &&
            (!whiteListTypes || whiteListTypes.some(whiteType => c.objectType === whiteType))
    );

    return (
        <>
            {children ? (
                children({
                    catalogs,
                    onDeleteCatalog: handleDelete,
                    onRenameCatalog: handleRename,
                    onCreateCatalog: handleCreateAndImport,
                    onCatalogRowPressed: handleCatalogRowPressed,
                    onImportCatalogs: handleImportCatalogs,
                    projectConfig,
                    allSpaceCatalogs,
                    whiteListTypes,
                    blackListTypes
                })
            ) : (
                <Catalogs
                    catalogs={catalogs}
                    onDeleteCatalog={handleDelete}
                    onDeleteCatalogLoading={deleteCatalogLoading}
                    onRenameCatalog={handleRename}
                    onCreateCatalog={handleCreateAndImport}
                    onCatalogRowPressed={handleCatalogRowPressed}
                    onImportCatalogs={handleImportCatalogs}
                    onAddCatalogItem={allowQuickAddItem ? handleAddCatalogItem : undefined}
                    onSelectItem={onSelectItem}
                    projectConfig={projectConfig}
                    allSpaceCatalogs={allSpaceCatalogs}
                    whiteListTypes={whiteListTypes}
                    blackListTypes={blackListTypes}
                    allowQuickObjectEdit={allowQuickObjectEdit}
                    quickEditTitle={quickEditTitle}
                    quickAddTitle={quickAddTitle}
                    collapsible={collapsible}
                    initializeOpen={initializeOpen}
                    showItemSearch={showItemSearch}
                    itemFilter={itemFilter}
                    itemsAreDraggable={itemsAreDraggable}
                ></Catalogs>
            )}
        </>
    );
};
