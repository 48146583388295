import React from 'react';
import { Scenarios } from '../../components/scenarios';
import { IScriptDirectoryItem } from '../../shared/motive/models/ScriptDirectoryItem';
import { ICatalog } from '../../shared/motive/models/Catalog';
import { scenarioLayout, containerStyle, activityLayout } from './ScenariosContainer.style';
import { useCreateCatalog } from '../../shared/motive/hooks/useCatalogs';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useCreateScriptLauncher } from '../../shared/motive/hooks/useCatalogs/useCreateScriptLauncher/useCreateScriptLauncher';
import { DashboardPanel } from '../../components/dashboardPanel';
import { ActivitiesContainer } from '../activitiesContainer';
import { Activities } from '../../components/activities';
import { Loading } from '../../core-ui/loading';
import { ScriptCatalogsContainer } from '../scriptCatalogsContainer';
import { useGetAllProjectCatalogsByType } from '../../shared/motive/hooks/useCatalogs/useGetAllProjectCatalogsByType/useGetAllProjectCatalogsByType';
import { IGroupdScenarioItem } from '../../components/scenarios/Scenarios';
import { useCurrentProjectConfig } from '../../redux/spaceKeyed/SpaceKeyedHooks';
import { VersionDates } from '../../util/ScriptUtils';
import { IEnumItemReference } from '../../shared/motive/models/TypeDefinition';
import { useTranslation } from 'react-i18next';

export const PUBLISHED = 'published';
export const DRAFT = 'draft';
export const RETIRED = 'retired';

type SortScenarioReturnType = [IGroupdScenarioItem[], IGroupdScenarioItem[], IGroupdScenarioItem[]];

export const sortScenarios = (
    data: ICatalog<IScriptDirectoryItem>[],
    isEnumRefSpace?: boolean
): SortScenarioReturnType => {
    const predicate = (item: IScriptDirectoryItem, state: string) => {
        if (!item.publishingStatus) {
            return false;
        }
        if (isEnumRefSpace) {
            return (item.publishingStatus as IEnumItemReference)?.name === state;
        } else {
            return (item.publishingStatus as string) === state;
        }
    };
    const draftList: IGroupdScenarioItem[] = [];
    const publishedList: IGroupdScenarioItem[] = [];
    const retiredList: IGroupdScenarioItem[] = [];

    data.forEach(catalog => {
        catalog.items &&
            catalog.items.forEach(catalogItem => {
                if (predicate(catalogItem, PUBLISHED)) {
                    publishedList.push({ item: catalogItem, catalog: catalog });
                } else if (predicate(catalogItem, RETIRED)) {
                    retiredList.push({ item: catalogItem, catalog: catalog });
                } else {
                    draftList.push({ item: catalogItem, catalog: catalog });
                }
            });
    });

    return [draftList, publishedList, retiredList];
};

/* istanbul ignore next */
export const ScenariosContainer: React.FC = (): React.ReactElement => {
    const { data, isLoading } = useGetAllProjectCatalogsByType(MotiveTypes.SCRIPT_DIRECTORY_ITEM);

    const createScenario = useCreateScriptLauncher();
    const createCatalog = useCreateCatalog();

    const config = useCurrentProjectConfig();

    const isEnumRefSpace = VersionDates.checkUseEnumRef(config?.typeVersionDate);

    const [draft, published, retired] = sortScenarios(data, isEnumRefSpace);

    const { t } = useTranslation();

    return (
        <Loading isLoaded={!isLoading}>
            <div css={containerStyle}>
                <div css={scenarioLayout}>
                    <Scenarios
                        published={published}
                        draft={draft}
                        retired={retired}
                        createScenario={createScenario}
                        createCatalog={createCatalog}
                        catalogs={data}
                        isLoading={isLoading}
                    ></Scenarios>
                </div>
                <div css={scenarioLayout}>
                    <DashboardPanel headingTitle={t('scripts')}>
                        <ScriptCatalogsContainer>
                            {/* {props => <ScriptCatalogs showAll={true} {...props} />} */}
                        </ScriptCatalogsContainer>
                    </DashboardPanel>
                </div>
                <div css={activityLayout}>
                    <DashboardPanel headingTitle={t('activity')}>
                        <ActivitiesContainer>{props => <Activities {...props} />}</ActivitiesContainer>
                    </DashboardPanel>
                </div>
            </div>
        </Loading>
    );
};
