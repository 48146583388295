import React from 'react';
import { baseCardStyle, baseCardImgStyle, cardShadowStyle } from './Card.style';
import { useStyle } from '../../shared/hooks/useStyle';

interface ICardProps {
    className?: string;
    img?: string;
    shadow?: boolean;
    onClick?: () => void;
}

export const Card: React.FC<ICardProps> = ({ children, img, shadow = false, onClick, ...rest }): React.ReactElement => {
    const token = useStyle();

    return (
        <div onClick={onClick} css={[baseCardStyle(token), shadow && cardShadowStyle]} {...rest}>
            {img && <img css={baseCardImgStyle} src={img} alt="Card" />}
            {children}
        </div>
    );
};
