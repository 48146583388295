import { css } from '@emotion/core';

export const buttonWrapperStyle = css`
    display: flex;
    justify-content: flex-end;
    margin: 12px 0;
`;

export const previousButtonStyle = css`
    background-color: #a3aeb7;
`;
