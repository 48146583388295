//istanbul ignore file MOTIVE_EXCEPTION
import React from 'react';
import { renderSubRoute } from '../editorRoutes';
import { IProjectsRouteParams } from '../projectsRoute';
import { useEditorRouteMatch, useRoute } from '../useRoute';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useGetCatalogTypes } from '../../shared/motive/hooks/useCatalogs/useCatalogTypes/useGetCatalogTypes';
import { useScenariosRoute } from '../scenariosRoute';
import { AgentsContainer } from '../../containers/agentsContainer';
import { CreateAgent } from '../../components/createAgent';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConvoAIAgentCreationRouteParams extends IProjectsRouteParams {
    agentId?: string;
}

export const useConvoAIAgentCreationRoute = () => useRoute<IConvoAIAgentCreationRouteParams>('ConvoAIAgentCreation');
export const useConvoAIAgentCreationRouteMatch = () =>
    useEditorRouteMatch<IConvoAIAgentCreationRouteParams>('ConvoAIAgentCreation');

export const ConvoAIAgentCreationRoute: React.FC = () => {
    const { subRoutes } = useConvoAIAgentCreationRoute();

    const { goTo } = useConvoAIAgentCreationRoute();

    const { goTo: redirectToScenarios } = useScenariosRoute();

    const { data: typeData } = useGetCatalogTypes();
    const isAIFeatureSetAvailable = typeData.some(type => type.bundleName === MotiveTypes.AI);

    if (isAIFeatureSetAvailable) {
        return (
            <>
                <AgentsContainer>
                    {props => (
                        <CreateAgent
                            watsonConfigurations={props.watsonConfigurations ?? []}
                            onCreateWatsonConfiguration={props.onCreateWatsonConfiguration}
                            onCreateAgent={props.onCreateAgent}
                            onUpdateWatsonConfiguration={props.onUpdateWatsonConfiguration}
                        />
                    )}
                </AgentsContainer>
                {subRoutes.map(renderSubRoute)}
            </>
        );
    }

    return <>{redirectToScenarios()}</>;
};
