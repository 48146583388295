import React from 'react';
import { useRoute, useEditorRouteMatch } from '../useRoute';
import { MediaManagementContainer } from '../../containers/mediaManagementContainer';

export const useMediaRoute = () => useRoute('Media');
export const useMediaRouteMatch = () => useEditorRouteMatch('Media');

export const MediaRoute: React.FC = () => {
    return (
        <>
            <MediaManagementContainer />
        </>
    );
};
