import { css } from '@emotion/core';
import { stretchWidth } from '../../constants/HelperStyles';
import { IToken } from '../../shared/contexts/styleContext';

export const collapsibleContainerStyle = (tokens: IToken, length?: number) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, 80px);
    grid-gap: ${tokens.spacings.small};
    padding: 0 ${tokens.spacings.smaller};
    ${length && length > 2 && `justify-content: space-around`};
`;

export const addCollaboratorWrapper = (tokens: IToken) => css`
    margin: ${tokens.spacings.medium};
`;

export const pendingCollaboratorWrapper = (tokens: IToken) => css`
    margin: 0 ${tokens.spacings.smaller};
    border-radius: ${tokens.borderRadii.small};
    background-color: ${tokens.colors.background.dark};
`;

export const addCollaboratorInput = css`
    outline: none;
    border: none;
    border-radius: 0;
    height: 32px;
    &:hover {
        border: none;
    }
`;

export const addCollaboratorButton = css`
    margin: auto;
    border-radius: 0;
    min-width: 44px;
`;

export const addCollaboratorGroup = css`
    display: flex;
    ${stretchWidth}
`;
