import { saveProjectConfigNetworkCall } from '../../networking/ProjectConfigService';
import { useCurrentSpace } from '../useCurrentSpace';
import { getSystemName } from '../../../../util/MotiveUtils';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useSelector, useDispatch } from 'react-redux';
import { SelectProjectConfigsState } from '../../../../redux/spaceKeyed/projectConfig/ProjectConfigSelectors';

export interface IRenameProjectConfigParams {
    newName: string;
}

export const useRenameProjectConfig = (projectId: string): IHookProvidedCall<IRenameProjectConfigParams> => {
    const [currentSpace] = useCurrentSpace();

    const projectConfigState = useSelector(SelectProjectConfigsState);
    const dispatch = useDispatch();

    const { triggerCall, ...rest } = useNetworkCallWithoutDataSource();

    async function call(params: IRenameProjectConfigParams) {
        const config = projectConfigState.configData.projectConfigs.find(c => c.id === projectId);

        if (!config) {
            throw new Error('The project config you are trying to rename does not exist');
        }

        const systemName = getSystemName(params.newName);

        if (systemName !== config.name) {
            const newConfig = { ...config, title: params.newName, name: getSystemName(params.newName) };

            await triggerCall(saveProjectConfigNetworkCall(currentSpace, newConfig, dispatch));
        }
    }

    return { execute: call, ...rest };
};
