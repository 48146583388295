import { IScript } from '../../models/Script';
import { IHookProvidedDataSource } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallForDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { useCatalogsBaseHook } from '../useCatalogs/useCatalogBaseHook';
import { MotiveTypes } from '../../../../constants/MotiveTypes';

export const useScript = (scriptId?: string): IHookProvidedDataSource<IScript | undefined> => {
    const scripts = useCatalogsBaseHook({ type: [MotiveTypes.MOTIVE_SCRIPT] });

    var originalScript = scripts.data.flatMap(c => c.items as IScript[]).find(s => s?.id === scriptId);

    const { triggerCall, ...rest } = useNetworkCallForDataSource(originalScript);

    const call = async () => {
        await triggerCall(scripts.load());
    };

    return {
        data: originalScript,
        load: call,
        ...rest
    };
};
