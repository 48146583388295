import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const containerStyle = ({ colors, spacings }: IToken, height: number) => css`
    position: sticky;
    top: 0;
    min-width: 0;
    height: ${height}px;
    background-color: ${colors.background.darker};
    box-sizing: border-box;
    padding: ${spacings.smallest};
    border-radius: 5px 0 0 0;
`;

export const mainButtonStyle = css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const mainButtonIconWrapperStyle = css`
    flex: 1 0 auto;
`;

export const mainButtonHeadingStyle = ({ colors }: IToken) => css`
    color: ${colors.font.tertiary};
    text-transform: uppercase;
    padding: 5px;
`;

export const navForwardStyle = css`
    display: flex;
    justify-content: flex-end;
`;

export const navCloseStyle = css`
    display: flex;
    justify-content: flex-end;
`;

export const navButtonStyle = ({ colors, spacings }: IToken) => css`
    padding: ${spacings.smallest} ${spacings.smaller};
    background-color: ${colors.interactible.tertiary};
    color: ${colors.font.primary};
`;
