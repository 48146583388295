import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';
import chroma from 'chroma-js';

export const flexWrapLayoutStyle = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
`;
export const eventObjectWrapperStyle = (token: IToken) => css`
    background: white;
    padding: 0 ${token.spacings.smallest} ${token.spacings.smaller};
    margin: 1px -${token.spacings.smaller};
    &:hover {
        background: ${chroma(token.colors.background.darker)
            .alpha(0.1)
            .css()};
    }
`;

export const eventObjectWrapperBgStyle = css`
    background: white;
`;

export const animateEventBgStyle = (token: IToken) => css`
    background: white;
    animation-name: changeBgColor;
    animation-duration: 4s;
    animation-delay: 0s;
    @keyframes changeBgColor {
        from,
        to {
            background-color: white;
        }
        40% {
            background-color: ${token.dynamicsColors.background.neutral};
        }
    }
`;

export const addScriptButtonStyle = (token: IToken) => css`
    width:100%;
    border-radius:0;
    margin: 0 -${token.spacings.smaller};
    text-align:center;
    color:${token.colors.font.primary};
    box-sizing:content-box;
    background-color: ${chroma(token.dynamicsColors.background.neutral)
        .alpha(0.3)
        .css()};
}
`;
export const optionsEditorStyle = (token: IToken) => css`
    background: ${token.colors.background.light};
    margin: 0 -${token.spacings.smaller} -${token.spacings.smaller};
    padding: 0 ${token.spacings.smaller} ${token.spacings.smaller};
    & .css-tya3pf-FieldEditorLabel {
        width: 80px;
    }
`;
export const inlineStyle = css``;
export const editButtonStyle = css`
    margin-top: 5px;
`;

export const textNoOverflowStyle = css`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const headingRenderContainerStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
export const variableObjectEditorStyle = (Token: IToken) => css`
    margin: 0 -${Token.spacings.small};
`;
export const outerHeadingsAndVarsLayoutStyle = (Token: IToken) => css`
    background: ${Token.colors.background.lighter};
    padding: ${Token.spacings.smaller};
`;

export const displayNoneStyle = css`
    display: none;
`;

export const inputStickyPositionStyle = ({ colors, spacings }: IToken) => css`
    background-color: ${colors.background.dark};
    padding-left: ${spacings.smallest};
    padding-right: 10px;
    padding-bottom: ${spacings.smallest};
`;

export const inputStyle = css`
    margin: unset;
`;
export const eventHeadingStyle = css`
    font-weight: 600;
`;
