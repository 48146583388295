import { deleteCatalogNetworkCall } from '../../../networking/CatalogService';
import { useCurrentSpace } from '../../useCurrentSpace';
import { IHookProvidedCall } from '../../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../../hooks/useNetworkCallForDataSource';
import { useDispatch } from 'react-redux';

export interface IDeleteCatalogParams {
    catalogId: string;
}
export const useDeleteCatalog = (): IHookProvidedCall<IDeleteCatalogParams> => {
    const [currentSpace] = useCurrentSpace();
    const catalogDispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const deleteCatalog = async (params: IDeleteCatalogParams) => {
        await triggerCall(
            deleteCatalogNetworkCall(currentSpace, params.catalogId, getCancelableDispatch(catalogDispatch))
        );
    };

    return {
        execute: deleteCatalog,
        ...rest
    };
};
