import { Size } from '../constants/Size';

export type ChakraSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl';
export type IMotiveSizeMapping = { [key in Size]?: ChakraSize };

export const getChakraSize = (key: Size | undefined, overrides?: IMotiveSizeMapping): ChakraSize | undefined => {
    if (!key) return;

    const defaultSizeMappings: IMotiveSizeMapping = {
        [Size.SMALLEST]: 'xs',
        [Size.SMALLER]: 'xs',
        [Size.SMALL]: 'sm',
        [Size.MEDIUM]: 'md',
        [Size.LARGE]: 'lg',
        [Size.LARGER]: 'xl',
        [Size.LARGEST]: '2xl'
    };

    if (overrides && overrides[key]) {
        return overrides[key];
    }

    return defaultSizeMappings[key] ? defaultSizeMappings[key] : undefined;
};
