import { useCurrentSpace } from '../useCurrentSpace';
import { renameScriptNetworkCall } from '../../networking/ScriptService';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useDispatch } from 'react-redux';

export interface IRenameScriptParams {
    catalogId: string;
    scriptId: string;
    scriptName: string;
}
export const useRenameScriptCatalogItem = (): IHookProvidedCall<IRenameScriptParams> => {
    const [currentSpace] = useCurrentSpace();
    //const [, catalogDispatch] = useSubReducer(CatalogReducer, 'catalogState');
    const catalogDispatch = useDispatch();
    const { triggerCall, getCancelableDispatch, ...rest } = useNetworkCallWithoutDataSource();

    const patchScript = async (params: IRenameScriptParams) => {
        await triggerCall(
            renameScriptNetworkCall(
                currentSpace,
                params.catalogId,
                params.scriptId,
                params.scriptName,
                getCancelableDispatch(catalogDispatch)
            )
        );
    };

    return {
        execute: patchScript,
        ...rest
    };
};
