import React from 'react';
import {
    useSaveCatalogItem,
    ISaveCatalogItemParams
} from '../../shared/motive/hooks/useCatalogs/useSaveCatalogItem/useSaveCatalogItem';
import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { IHookProvidedCall } from '../../shared/hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import {
    IDeleteCatalogItemParams,
    useDeleteCatalogItem
} from '../../shared/motive/hooks/useCatalogs/useDeleteCatalogItem/useDeleteCatalogItem';

export interface IScenarioItemProps {
    saveScenario: IHookProvidedCall<ISaveCatalogItemParams, IScriptObjectModel | undefined>;
    deleteCatalogItem: IHookProvidedCall<IDeleteCatalogItemParams>;
}

interface IScenarioItemContainerProps {
    children: (props: IScenarioItemProps) => React.ReactNode;
}
export const ScenarioItemContainer: React.FC<IScenarioItemContainerProps> = ({ children }) => {
    const saveScenario = useSaveCatalogItem();
    const deleteCatalogItem = useDeleteCatalogItem();

    return <>{children({ saveScenario, deleteCatalogItem })}</>;
};
