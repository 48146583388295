import { IProjectConfigurations, IProjectConfig } from '../../../shared/motive/models/ProjectConfig';
import { ICatalogInfo } from '../../../shared/motive/models/Catalog';
import { catalogDeleteAction } from '../catalog/CatalogActions';

export type ProjectConfigAction = ReturnType<
    | typeof projectConfigSetAction
    | typeof projectConfigUpdateAction
    | typeof projectConfigAddCatalogAction
    | typeof projectConfigAppendAction
    | typeof catalogDeleteAction
>;

export const projectConfigSetAction = (config: IProjectConfigurations) => {
    return {
        type: 'projectConfig/set',
        config
    } as const;
};

export const projectConfigAppendAction = (projectConfig: IProjectConfig) => {
    return {
        type: 'projectConfig/append',
        projectConfig
    } as const;
};
export const projectConfigUpdateAction = (projectConfig: IProjectConfig) => {
    return {
        type: 'projectConfig/update',
        projectConfig
    } as const;
};

export const projectConfigAddCatalogAction = (projectId: string, catalog: ICatalogInfo) => {
    return {
        type: 'projectConfig/append/catalog',
        projectId,
        catalog
    } as const;
};
