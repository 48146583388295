import { IToken } from '../../shared/contexts/styleContext';
import { css } from '@emotion/core';

export const collapsibleContentWrapperStyle = (token: IToken) => css`
    display: flex;
    flex-direction: row;
    background-color: ${token.colors.background.lighter};
    border: 3px dashed ${token.colors.background.light};
    padding: ${token.spacings.larger};
    justify-content: center;
    align-items: center;
    height: 10vh;
    cursor: pointer;
    margin-bottom: ${token.spacings.small};
`;

export const headingStyle = css`
    text-align: center;
`;

export const previewAudioPlayerDiv = css`
    height: 36px;
    width: 300px;
    background-color: red;
`;

export const previewAudioPlayerStyle = (token: IToken) => css`
    // margin-top: ${token.spacings.small};
    max-height: 36px;
    width: 100%;
    outline: none;
`;

export const previewLayout = (token: IToken) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${token.spacings.small};
`;

export const ttsWrapperStyle = (token: IToken) => css`
    padding: ${token.spacings.small};
    margin: ${token.spacings.small} 0;
    border: 1px solid ${token.colors.border.primary};
`;

export const ttsButtonControlStyle = (token: IToken) => css`
    margin-top: ${token.spacings.small};
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ttsButtonStyle = (token: IToken) => css`
    margin-left: ${token.spacings.smaller};
`;

export const ttsSpacingStyle = (token: IToken) => css`
    margin-top: ${token.spacings.small};
`;

export const ttsVoiceOptions = css`
    max-width: 300px;
`;
