import { IScriptObjectModel } from '../../shared/motive/models/ScriptObjectModel';
import { useTypeDefinitions } from '../../shared/motive/hooks/useScriptEditorInfo/useScriptEditorInfo';
import React, { useState } from 'react';
import { Collection } from '../../core-ui/collection';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { getSmartName, getObjectIcon } from '../../util/ObjectDefinitionsUtils';
import { selectedCatalogItemRowStyle, catalogItemRowStyle } from '../catalogEditor/CatalogEditor.style';
import { QuickObjectEditor } from '../quickObjectEditor';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { mediaPlayerStyle } from './CatalogItemList.style';
import { Icon } from '../../core-ui/icon';
import { IMediaItem, IHostedMediaSource } from '../../shared/motive/models/MediaItem';
import { MediaSourcePlayer } from '../mediaItemPreview/MediaItemPreview';
import { useSpacesRoute } from '../../routes/spacesRoute';
import { getViewableUrl } from '../../shared/motive/networking/MediaService';
import { useCurrentLanguageSettings } from '../../shared/motive/stores/editorLocale/EditorLocale';
import { CatalogItem } from './CatalogItem';
import { CatalogScriptItem } from './CatalogScriptItem';
import { CloneDrag } from '../_common/DragAndDrop/CloneDrag/CloneDrag';
import { ICatalogObjectInstanceDrag } from '../_common/DragAndDrop/DragModels/DragModels';
import { Drop } from '../_common/DragAndDrop/Drop/Drop';
import { DRAG_ITEM_TYPE_CATALOG_ITEM } from '../../constants/DragAndDrop';
import { useTranslation } from 'react-i18next';

interface ICatalogItemListProps {
    catalogId: string;
    items: IScriptObjectModel[];
    selectedItem?: IScriptObjectModel;
    onSelectItem?: (item: IScriptObjectModel) => void;
    allowQuickEdit?: boolean;
    onSaveItem?: (item: IScriptObjectModel) => void;
    onDeleteItem?: (itemId: string) => void;
    isDraggable?: boolean;
}

export const CatalogItemList: React.FC<ICatalogItemListProps> = ({
    catalogId,
    items,
    onSelectItem,
    selectedItem,
    allowQuickEdit,
    onSaveItem,
    onDeleteItem,
    isDraggable
}) => {
    const [quickEditItem, setQuickEditItem] = useState<IScriptObjectModel | undefined>(undefined);

    const typeDefs = useTypeDefinitions();
    const language = useCurrentLanguageSettings();
    const { t } = useTranslation();

    const {
        params: { spaceName }
    } = useSpacesRoute();

    return (
        <>
            <div>
                <Collection display={'list'} minColumnWidth={`75px`} maxColumnWidth={`1fr`}>
                    <Drop dropId={catalogId} dragType={DRAG_ITEM_TYPE_CATALOG_ITEM} isDisabled>
                        <div>
                            {items
                                ? items.map((item, idx) => {
                                      const handleSelectItem = () => {
                                          onSelectItem && onSelectItem(item);
                                      };

                                      let rowItemContent: () => JSX.Element;
                                      let dragThumbContent = <></>;
                                      let body: (() => JSX.Element) | undefined;

                                      if (item.type === MotiveTypes.MOTIVE_SCRIPT) {
                                          const header = () => <>{getSmartName(item, typeDefs, language)}</>;
                                          const thumbnail = () => <Icon icon={IconTypes.SCRIPT} />;

                                          // eslint-disable-next-line react/display-name
                                          rowItemContent = () => (
                                              <CatalogScriptItem catalogId={catalogId} script={item} />
                                          );

                                          dragThumbContent = (
                                              <CatalogItem
                                                  allowQuickObjectEdit={allowQuickEdit}
                                                  header={header}
                                                  thumbnail={thumbnail}
                                              />
                                          );
                                      } else {
                                          const mediaItem =
                                              item.type === MotiveTypes.MEDIA_ITEM ? (item as IMediaItem) : undefined;
                                          const isImage = mediaItem?.source?.contentType.startsWith('image');
                                          const url = mediaItem?.source?.url;

                                          const header = () => <>{getSmartName(item, typeDefs, language)}</>;
                                          const thumbnail = () => {
                                              return spaceName && isImage && url && mediaItem?.source?.id ? (
                                                  <img
                                                      src={getViewableUrl(spaceName, mediaItem?.source?.id, true)}
                                                      alt={mediaItem?.source?.title ?? t('media')}
                                                  ></img>
                                              ) : (
                                                  <Icon faIcon={getObjectIcon(typeDefs, item)} />
                                              );
                                          };

                                          body =
                                              mediaItem?.source !== undefined && !isImage
                                                  ? () => (
                                                        <div css={mediaPlayerStyle}>
                                                            <MediaSourcePlayer
                                                                mediaSource={mediaItem.source as IHostedMediaSource}
                                                            />
                                                        </div>
                                                    )
                                                  : undefined;

                                          const defaultItemContent = () => {
                                              return (
                                                  <>
                                                      <CatalogItem
                                                          header={header}
                                                          thumbnail={thumbnail}
                                                          allowQuickObjectEdit={allowQuickEdit}
                                                          onEditClicked={() => setQuickEditItem(item)}
                                                          onDeleteClicked={() => {
                                                              onDeleteItem && onDeleteItem(item.id);
                                                          }}
                                                      ></CatalogItem>
                                                  </>
                                              );
                                          };

                                          rowItemContent = defaultItemContent;

                                          dragThumbContent = (
                                              <CatalogItem
                                                  allowQuickObjectEdit={allowQuickEdit}
                                                  header={header}
                                                  thumbnail={thumbnail}
                                              />
                                          );
                                      }

                                      const rowItemRender = () => {
                                          return (
                                              <div
                                                  css={
                                                      selectedItem?.id === item.id
                                                          ? selectedCatalogItemRowStyle
                                                          : catalogItemRowStyle
                                                  }
                                              >
                                                  {rowItemContent()}
                                              </div>
                                          );
                                      };

                                      return (
                                          <div key={item.id}>
                                              {quickEditItem === item ? (
                                                  <QuickObjectEditor
                                                      type={item.type}
                                                      object={item}
                                                      onSave={newItem => onSaveItem && onSaveItem(newItem)}
                                                      onCancel={() => setQuickEditItem(undefined)}
                                                  />
                                              ) : (
                                                  <>
                                                      {isDraggable ? (
                                                          <>
                                                              <CloneDrag<ICatalogObjectInstanceDrag>
                                                                  drag={{
                                                                      dragType: DRAG_ITEM_TYPE_CATALOG_ITEM,
                                                                      catalogId: catalogId,
                                                                      objectId: item.id,
                                                                      objectType: item.type
                                                                  }}
                                                                  index={idx}
                                                                  thumb={dragThumbContent}
                                                              >
                                                                  <div onClick={handleSelectItem}>
                                                                      {rowItemRender()}
                                                                  </div>
                                                              </CloneDrag>
                                                              <div>{body && body()}</div>
                                                          </>
                                                      ) : (
                                                          <div onClick={handleSelectItem}>{rowItemRender()}</div>
                                                      )}
                                                  </>
                                              )}
                                          </div>
                                      );
                                  })
                                : t('emptyList')}
                        </div>
                    </Drop>
                </Collection>
            </div>
        </>
    );
};
