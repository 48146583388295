import React from 'react';
import { FC } from 'react';
import { BoxProps } from '@chakra-ui/core';
import { loadingSpinner } from './Loading.style';
import { useStyle } from '../../shared/hooks/useStyle';

// WORKAROUND FOR CHAKRA 0.7
// Chakra changes the meaning of sizes in 0.7 so this is a duplication of the 0.7 api for these fields so that our usages are
// compatible once we make the upgrade...
interface ISkeleton {
    /**
     * The color at the animation start
     */
    colorStart?: string;
    /**
     * The color at the animation end
     */
    colorEnd?: string;
    /**
     * Render only the children
     */
    isLoaded?: boolean;
    /**
     * The animation speed in seconds
     */
    speed?: number;
    /**
     * The fadeIn duration in seconds
     */
    fadeInDuration?: number;
}

type SkeletonProps = ISkeleton & BoxProps;

export interface ILoadingProps extends SkeletonProps {
    loadingRender?: () => JSX.Element;
}

export const Loading: FC<ILoadingProps> = ({ children, isLoaded, loadingRender }) => {
    const token = useStyle();

    if (!isLoaded) {
        if (!!loadingRender) {
            return loadingRender();
        }
        return <div css={loadingSpinner(token)} />;
    }

    return <>{children}</>;
};
