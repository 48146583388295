/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMediaAction } from './MediaActions';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { Reducer } from 'redux';
import { IStatus } from '../../Status';
import { IHostedMediaSource } from '../../../shared/motive/models/MediaItem';

export interface IMediaUploadStatus extends IStatus {
    uploadProgress?: number;
}

export interface IMediaSourceEntity {
    mediaSource: IHostedMediaSource;
    status: IMediaUploadStatus;
}

export interface IUploadingMediaEntity {
    id: string;
    file: any;
    status: IMediaUploadStatus;
}

/**
 * @param mediaItems The media items that are currently stored in the server
 * @param uploadingMediaItems The media items that are currently being uploaded to the server
 * @param status The status of the media items collection
 */
export interface IMediaState {
    mediaItems: Record<string, IMediaSourceEntity>;
    uploadingMediaItems: Record<string, IUploadingMediaEntity>;
    status: IStatus;
}

const defaultMediaState: IMediaState = {
    mediaItems: {},
    uploadingMediaItems: {},
    status: {
        loadingState: 'initial'
    }
};

const populateMediaItems = (items: IHostedMediaSource[]) => {
    const mediaMap: Record<string, IMediaSourceEntity> = {};

    items.forEach(i => {
        mediaMap[i.id] = {
            mediaSource: i,
            status: {
                loadingState: 'resolved'
            }
        };
    });

    return mediaMap;
};

export const MediaReducer: Reducer<IMediaState, IMediaAction> = produce((state: IMediaState, action: IMediaAction) => {
    switch (action.type) {
        case 'media/loading':
            state.status = {
                loadingState: 'loading',
                error: undefined
            };
            break;

        case 'media/set':
            state.mediaItems = populateMediaItems(action.mediaItems);
            state.status = {
                loadingState: 'resolved',
                error: undefined
            };
            break;

        case 'media/error':
            state.status = {
                loadingState: 'resolved',
                error: action.error
            };
            break;

        case 'media/itemLoading':
            state.mediaItems[action.id].status = {
                loadingState: 'loading',
                error: undefined
            };
            break;

        case 'media/itemDelete':
            delete state.mediaItems[action.id];
            break;

        case 'media/itemError':
            state.mediaItems[action.id].status = {
                loadingState: 'resolved',
                error: action.error
            };
            break;

        case 'media/uploadBegin':
            state.uploadingMediaItems[action.id] = {
                id: action.id,
                file: action.file,
                status: {
                    loadingState: 'loading',
                    error: undefined,
                    uploadProgress: 0
                }
            };
            break;

        case 'media/uploadComplete':
            delete state.uploadingMediaItems[action.oldId];
            state.mediaItems[action.source.id] = {
                mediaSource: action.source,
                status: {
                    loadingState: 'resolved'
                }
            };
            break;

        case 'media/uploadRemove':
            delete state.uploadingMediaItems[action.id];
            break;

        case 'media/uploadProgress':
            state.uploadingMediaItems[action.id].status.uploadProgress = action.progress;
            break;

        case 'media/uploadError':
            state.uploadingMediaItems[action.id].status = {
                loadingState: 'resolved',
                error: action.error,
                uploadProgress: undefined
            };
            break;
    }
}, defaultMediaState);

export const useMediaDispatch: () => React.Dispatch<IMediaAction> = () => {
    return useDispatch() as React.Dispatch<IMediaAction>;
};
