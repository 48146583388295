export enum MotiveTypes {
    OBJECT = 'motive.core.object',
    STRING = 'motive.core.string',
    BOOLEAN = 'motive.core.bool',
    DYNAMIC_VALUE_SELECTOR = 'motive.core.dynamicValueSelector',
    SET_OPERATION_SELECTOR = 'motive.core.setOperationSelector',
    DYNAMIC_VALUE_COMPARER = 'motive.core.dynamicValueComparer',
    VARIABLE_VALUE_CONDITION = 'motive.core.variableValueCondition',
    INT = 'motive.core.int',
    LOCALIZED_TEXT = 'motive.core.localizedText',
    LOCALIZED_MEDIA = 'motive.core.localizedMedia',
    MOTIVE_SCRIPT = 'motive.core.script',
    SCRIPT_DIRECTORY_ITEM = 'motive.core.scriptDirectoryItem',
    PUBLISHING_STATUS = 'motive.core.publishingStatus',
    SELECTOR_NODE = 'motive.core.selectorNode',
    FRAME = 'motive.core.frame',
    FLOAT = 'motive.core.float',
    SCRIPT_OBJECT_CONDITION = 'motive.core.scriptObjectEventCondition',
    COMPOUND_CONDITION = 'motive.core.compoundCondition',
    CONDITION_WRAPPER = 'motive.core.conditionWrapper',
    DATE_TIME = 'motive.core.dateTime',
    TIME_SPAN = 'motive.core.timeSpan',
    CUSTOM_EVENT = 'motive.core.scriptEvent',
    CUSTOM_EVENT_CONDITION = 'motive.core.scriptEventCondition',
    COLOR = 'motive.core.color',
    CONTENT = 'motive.core.content',
    VALUE_DEFINITION = 'motive.core.valueDefinition',
    SCRIPT_VARIABLE = 'motive.core.scriptVariable',
    SCRIPT_EVENT_DEFINITION = 'motive.core.scriptEventDefinition',
    SCRIPT_EVENT = 'motive.core.scriptEvent',
    SCRIPT_EVENT_CONDITION = 'motive.core.scriptEventCondition',
    MEDIA_ITEM = 'motive.core.mediaItem',
    BEHAVIOUR_MODEL_BINDINGS = 'motive.core.behaviourModelBindings',
    BEHAVIOUR_MODEL = 'motive.core.behaviourModel',
    BEHAVIOUR_STATE_VARIABLE = 'motive.core.behaviourStateVariable',
    BEHAVIOUR_STATE_INPUT = 'motive.3d.behaviourStateInput',
    WORLD_OBJECT_INTERACTION = 'motive.3d.worldObjectInteraction',
    SCRIPT_LAUNCHER = 'motive.core.scriptLauncher',
    SCENE_WORLD_OBJECT = 'motive.3d.sceneWorldObject',
    SCENE_WORLD_ANCHOR = 'motive.3d.sceneWorldAnchor',
    PLAYABLE_CONTENT = 'motive.ui.playableContent',
    PLAYABLE_AUDIO_CONTENT = 'motive.ui.playableAudioContent',
    WORLD_OBJECT = 'motive.3d.worldObject',
    WORLD_ANCHOR = 'motive.3d.worldAnchor',
    ASSET_SPAWNER = 'motive.3d.assetSpawner',
    ASSET_INSTANCE = 'motive.3d.assetInstance',
    NAMED_WORLD_OBJECT = 'motive.3d.namedWorldObject',
    NAMED_WORLD_ANCHOR = 'motive.3d.namedWorldAnchor',
    NAMED_POSITIONAL_ANCHOR = 'motive.3d.namedPositionalAnchor',
    NAMED_SCENE_OBJECT = 'motive.3d.namedSceneObject',
    NAMED_EQUIP_TARGET = 'motive.3d.namedEquipTarget',
    EQUIP_TARGET = 'motive.3d.equipTarget',
    UNITY_ASSET = 'motive.unity.asset',
    BINARY_ASSET = 'motive.3d.binaryAsset',
    VARIABLE_SETTING = 'motive.core.variableSetting',
    VARIABLE_BINDING = 'motive.core.variableBinding',
    SCRIPT_OBJECT_EVENT = 'motive.core.scriptObjectEvent',
    RESOURCE_EVENT = 'motive.core.resourceEvent',
    BLEND_SHAPE_FACIAL_EXPRESSION = 'motive.3d.blendShapeFacialExpression',
    BLEND_SHAPE_FACIAL_SETTING = 'motive.3d.facialBlendShapeSetting',
    FACIAL_BLEND_SHAPE = 'motive.3d.facialBlendShape',
    AI = 'AI',
    SPEECH_RECOGNITION_CONDITION = 'motive.ai.speechRecognitionCondition',
    SPEECH_RECOGNIZER = 'motive.ai.speechRecognizer',
    INTENT_RECOGNIZER = 'motive.ai.intentRecognizer',
    WATSON_INTENT = 'motive.ai.watsonIntent',
    WATSON_ASSISTANT = 'motive.ai.watsonAssistant',
    WATSON_SERVICE_CREDENTIALS = 'motive.ai.watsonServiceCredentials',
    WATSON_SERVICE_CONFIGURATION = 'motive.ai.watsonServiceConfiguration',
    DIALOGFLOW_AGENT = 'motive.ai.dialogflowAgent',
    DIALOGFLOW_INTENT = 'motive.ai.dialogflowIntent',
    VOICE_INPUT = 'motive.ai.voiceInput',
    VOICE_INPUT_OPTION = 'motive.ai.voiceInputOption',
    CHARACTER_ACTION_PLAYER = 'motive.3d.characterActionPlayer',
    FACIAL_EXPRESSION_ACTION = 'motive.3d.facialExpressionAction'
}
