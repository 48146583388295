import { css } from '@emotion/core';
import { IToken } from '../../shared/contexts/styleContext';

export const baseCardStyle = (token: IToken) => css`
    border-radius: ${token.borderRadii.small};
    border: 1px solid ${token.colors.border.primary};
    background-color: white;
`;

export const cardShadowStyle = css`
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
`;

export const baseCardImgStyle = css`
    width: 100%;
    padding: 0;
    border-radius: 6px 6px 0px 0px;
`;
