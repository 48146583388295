import React from 'react';
import { DrawerSideMenu, IMenuItem } from '../../components/drawerSideMenu';
import { DRAWER_WIDTH_XS } from '../../core-ui/drawer/Drawer.style';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import {
    useGetDrawerStackItem,
    DASHBOARD_DRAWER_CATALOGS_KEY,
    DASHBOARD_DRAWER_SCRIPTS_KEY,
    DASHBOARD_DRAWER_MEDIA_KEY,
    DASHBOARD_DRAWER_ENUMS_KEY,
    DASHBOARD_DRAWER_CAI_KEY
} from '../../hooks/useDashboardDrawer';
import { useUIStack, IStackItem } from '../../shared/hooks/useUIStack';
import { Logger } from '../../util/logger';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useGetCatalogTypes } from '../../shared/motive/hooks/useCatalogs/useCatalogTypes/useGetCatalogTypes';

export interface IDrawerSideMenuProps {
    pushItem: (stackItem: IStackItem) => void;
    onRepeatClick: () => void;
}

export const DrawerSideMenuContainer: React.FC<IDrawerSideMenuProps> = ({ pushItem, onRepeatClick }) => {
    const getDrawerStackItem = useGetDrawerStackItem();
    const { uiStack } = useUIStack();

    const { data: typeData } = useGetCatalogTypes();
    const isAIFeatureSetAvailable = typeData.some(type => type.bundleName === MotiveTypes.AI);

    let selectedItem: string | undefined = undefined;
    if (uiStack && uiStack[0]) {
        selectedItem = uiStack[0].key;
    }

    const handleMenuItemPress = (itemKey: string) => {
        switch (itemKey) {
            case 'scripts':
                if (selectedItem === 'scriptsTab') {
                    onRepeatClick();
                    break;
                }

                pushItem(getDrawerStackItem('scripts'));
                break;
            case 'catalogs':
                if (selectedItem === 'catalogsTab') {
                    onRepeatClick();
                    break;
                }

                pushItem(getDrawerStackItem('catalogs'));
                break;
            case 'media':
                if (selectedItem === 'mediaItemCatalogs') {
                    onRepeatClick();
                    break;
                }
                pushItem(getDrawerStackItem('mediaItemCatalogs'));
                break;
            case 'enums':
                if (selectedItem === 'enums') {
                    onRepeatClick();
                    break;
                }

                pushItem(getDrawerStackItem('enums'));
                break;
            case 'cai':
                if (selectedItem === 'intentCatalogs') {
                    onRepeatClick();
                    break;
                }

                pushItem(getDrawerStackItem('intentCatalogs'));
                break;
            default:
                Logger.error(`Selected tab ${itemKey} does not have a handler, aborting.`);
                return;
        }
    };

    const sideMenuItems: IMenuItem[] = [
        {
            key: DASHBOARD_DRAWER_SCRIPTS_KEY,
            icon: IconTypes.SCRIPT,
            onClick: () => handleMenuItemPress('scripts')
        },
        {
            key: DASHBOARD_DRAWER_CATALOGS_KEY,
            icon: IconTypes.FOLDER,
            onClick: () => handleMenuItemPress('catalogs')
        },
        {
            key: DASHBOARD_DRAWER_MEDIA_KEY,
            icon: IconTypes.PHOTO_VIDEO,
            onClick: () => handleMenuItemPress('media')
        },
        {
            key: DASHBOARD_DRAWER_ENUMS_KEY,
            icon: IconTypes.LIST,
            onClick: () => handleMenuItemPress('enums')
        }
    ];

    isAIFeatureSetAvailable &&
        sideMenuItems.push({
            key: DASHBOARD_DRAWER_CAI_KEY,
            icon: IconTypes.COMMENT,
            onClick: () => handleMenuItemPress('cai')
        });

    return <DrawerSideMenu menuItems={sideMenuItems} selectedItem={selectedItem} width={DRAWER_WIDTH_XS} />;
};
