import { IResourceDrag } from '../../../frameInspectorResources';
import { ScriptActionType } from '../../../../shared/motive/reducers/ScriptEditorReducers';
import { Dispatch } from 'redux';
import { IFrame } from '../../../../shared/motive/models/Script';
import { ISubObjectDrag } from '../DragModels/DragModels';
import { IScriptObjectModel } from '../../../../shared/motive/models/ScriptObjectModel';
import { createScriptObject } from '../../../../util/MotiveUtils';
import { IObjectDefinition, IObjectDefinitionMap } from '../../../../shared/motive/models/TypeDefinition';
import { set } from 'lodash-es';

export const onDragMoveResource = (dispatch: Dispatch, frame: IFrame) => {
    return (data: IResourceDrag, index: number) => {
        if (!frame.resources?.some(c => c.resource.id === data.objectId)) {
            dispatch({
                type: ScriptActionType.RESOURCE_MOVE,
                destinationFrameId: frame.id,
                destinationIndex: frame.resources?.length ?? 0,
                sourceIndex: index,
                resourceId: data.objectId
            });
        }
    };
};

export const onDragAddResource = (
    dispatch: Dispatch,
    frameId: string,
    objectDefinitions: IObjectDefinitionMap,
    callback?: (id: string) => void
) => {
    return (dragObjInfo: ISubObjectDrag) => {
        const resource: IScriptObjectModel = createScriptObject(dragObjInfo.objectType, objectDefinitions);

        if (dragObjInfo.subObjectInfo) {
            const createSubObj = (obj: IScriptObjectModel, objDef: IObjectDefinition, idx: number) => {
                const subObjInfo = dragObjInfo?.subObjectInfo?.[idx];

                if (dragObjInfo && subObjInfo) {
                    const subObj = createScriptObject(subObjInfo.fieldType, objectDefinitions);

                    const val = subObjInfo.isArray ? [subObj] : subObj;

                    set(obj, subObjInfo.fieldName, val);

                    const nextIdx = idx + 1;

                    if (dragObjInfo?.subObjectInfo && nextIdx < dragObjInfo.subObjectInfo.length) {
                        const subObjInfo = objectDefinitions[subObj.type];

                        createSubObj(subObj, subObjInfo, nextIdx);
                    }

                    return subObj;
                }
            };

            const _objDef = objectDefinitions[resource.type];

            createSubObj(resource, _objDef, 0);
        }

        callback && callback(resource.id);

        dispatch({
            type: ScriptActionType.RESOURCE_ADD,
            resource: resource,
            destinationIndex: -1,
            destinationFrameId: frameId
        });
    };
};
