import { IToken } from '../../shared/contexts/styleContext';
import { css } from '@emotion/core';

export const defaultTabListStyle = css`
    min-width: 0;
`;

export const defaultTabPanelsStyle = css`
    width: 100%;
`;

export const defaultTabStyle = ({ colors }: IToken) => css`
    min-width: 0;
    color: ${colors.background.default};
    background-color: unset;
    border: 1px black;

    border: 1px solid transparent;

    &:hover {
    }
`;

export const defaultTabPanelStyle = css`
    height: 100%;
`;
