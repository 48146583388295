import { IScriptObjectModel } from '../shared/motive/models/ScriptObjectModel';

export function checkFilter(phrase: string, filter: string): boolean {
    const filterLower = filter && filter.toLowerCase();

    const contains = !filterLower || phrase.toLowerCase().includes(filterLower);

    return contains;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function checkFilterObject(object: any, filter: string): boolean {
    return checkFilter(JSON.stringify(object), filter);
}

export function filterObjects(
    objects: IScriptObjectModel[] | undefined,
    filter: string | undefined
): IScriptObjectModel[] | undefined {
    if (!filter) return objects;

    return objects?.filter(o => checkFilterObject(o, filter));
}
