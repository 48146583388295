import React, { useState } from 'react';
import { styleTokenDefaults } from './styleTokenDefaults';
import { StyleProvider } from '../../shared/contexts/styleContext';

interface IDefaultStyleStoreProps {
    color?: string;
}

export const DefaultStyleStore: React.FC<IDefaultStyleStoreProps> = ({ children, color }) => {
    const [, setCurrentColor] = useState<string | undefined>(color);

    return (
        <StyleProvider value={{ tokens: styleTokenDefaults, changePaletteColor: color => setCurrentColor(color) }}>
            {children}
        </StyleProvider>
    );
};
