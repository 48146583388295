import { SPACE_ENDPOINT } from '../constants/ApiEndpoint';
import { httpClient } from './HttpClient';
import { Logger } from '../../../util/logger';
import { IProjectConfig, IProjectConfigurations } from '../models/ProjectConfig';
import { tryUseCachedForGetReq, UseStaleWhileFetchingStrategy } from './StaleWhileFetching';
import { Dispatch } from 'redux';
import {
    projectConfigUpdateAction,
    projectConfigSetAction,
    projectConfigAppendAction
} from '../../../redux/spaceKeyed/projectConfig/ProjectConfigActions';

export const projectConfigPath = (spaceName: string) => `${SPACE_ENDPOINT}/${spaceName}/projectConfigs`;

const GetProjectConfigsLegacy = async (spaceName: string, dispatch: Dispatch): Promise<void> => {
    try {
        const response = await httpClient.get(projectConfigPath(spaceName));

        dispatch(projectConfigSetAction(response.data));
    } catch (error) {
        Logger.error('caught get projects error');
    }
};

const GetProjectConfigsSWF = async (spaceName: string, dispatch: Dispatch): Promise<void> => {
    const url = projectConfigPath(spaceName);
    await tryUseCachedForGetReq(
        url,
        httpClient.get(url),
        data => dispatch(projectConfigSetAction(data as IProjectConfigurations)),
        () => {}
    );
};

export const GetProjectConfigs = UseStaleWhileFetchingStrategy ? GetProjectConfigsSWF : GetProjectConfigsLegacy;

export const saveProjectConfigNetworkCall = async (
    spaceName: string,
    projectConfig: IProjectConfig,
    dispatch: Dispatch
): Promise<void> => {
    try {
        await httpClient.put(projectConfigPath(spaceName), projectConfig);

        dispatch(projectConfigUpdateAction(projectConfig));
    } catch (error) {
        Logger.error('caught update projects error');
    }
};

export const createProjectConfigNetworkCall = async (
    spaceName: string,
    projectConfig: IProjectConfig,
    dispatch: Dispatch
): Promise<IProjectConfig | undefined> => {
    try {
        var config = await httpClient.post(projectConfigPath(spaceName), projectConfig);

        //Project config post call response does not return a full config, perhaps we should change this on the backend soon
        //dispatch(projectConfigAppendAction(config.data));

        //For now we can query the config again
        await GetProjectConfigsLegacy(spaceName, dispatch);

        return config.data;
    } catch (error) {
        Logger.error('caught create projects error');
    }
};
