import { IContextualMenuItem } from '../contextualMenu';
import { useRightClickMenu } from '../../shared/hooks/useRightClickMenu/useRightClickMenu';
import React, { Fragment, useEffect } from 'react';
import { useStyle } from '../../shared/hooks/useStyle';
import { rightClickMenuStyle, rightClickMenuItemStyle } from './RightClickContextualMenu.style';

interface IRightClickContextualMenuProps {
    menuItems: IContextualMenuItem[];
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
}

export const RightClickContextualMenu: React.FC<IRightClickContextualMenuProps> = ({
    children,
    menuItems,
    onMenuClose,
    onMenuOpen
}) => {
    const ref = React.createRef<HTMLDivElement>();
    const token = useStyle();
    const { isMenuActive, showMenu, xPos, yPos } = useRightClickMenu(ref);

    useEffect(() => {
        if (isMenuActive) {
            onMenuOpen && onMenuOpen();
        } else {
            onMenuClose && onMenuClose();
        }
    }, [isMenuActive]);

    return (
        <>
            <div ref={ref}>{children}</div>
            {menuItems && (
                <div css={rightClickMenuStyle(xPos, yPos, isMenuActive)}>
                    {menuItems.map((mItem, index) => (
                        <Fragment key={index}>
                            <div
                                css={rightClickMenuItemStyle(token)}
                                onClick={e => {
                                    e.stopPropagation();
                                    mItem.onPress && mItem.onPress();
                                    showMenu(false);
                                }}
                            >
                                {mItem.children}
                            </div>
                        </Fragment>
                    ))}
                </div>
            )}
        </>
    );
};
