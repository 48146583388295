import React from 'react';
import { useRoute, useEditorRouteMatch } from '../useRoute';
import { IProjectsRouteParams, useProjectsRouteMatch } from '../projectsRoute';
import { CatalogsContainer } from '../../containers/catalogsContainer';
import { DashboardCatalogs } from '../../components/dashboardCatalog';
import { CatalogEditorContainer } from '../../containers/CatalogEditorContainer';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { ICatalogInfo } from '../../shared/motive/models/Catalog';

interface ICatalogsRouteParams extends IProjectsRouteParams {
    catalogName?: string;
}

export const useCatalogsRoute = () => useRoute<ICatalogsRouteParams>('Catalogs');
export const useCatalogsRouteMatch = () => useEditorRouteMatch<ICatalogsRouteParams>('Catalogs');

export const CatalogsRoute: React.FC = () => {
    const match = useProjectsRouteMatch();
    const projectConfigId = match?.params.projectName;

    const {
        goTo,
        params: { catalogName }
    } = useCatalogsRoute();

    const handleSelectCatalog = (catalog: ICatalogInfo) => {
        goTo({ pathParams: { catalogName: catalog.id } });
    };

    if (!projectConfigId) {
        throw new Error('no project name found');
    }

    return (
        <>
            {catalogName ? (
                <CatalogEditorContainer catalogId={catalogName}></CatalogEditorContainer>
            ) : (
                <CatalogsContainer
                    blackListTypes={[
                        MotiveTypes.MOTIVE_SCRIPT
                        // MotiveTypes.DIALOGFLOW_AGENT,
                        // MotiveTypes.WATSON_AGENT,
                        // MotiveTypes.INTENT
                    ]}
                    projectConfigId={projectConfigId}
                    onSelectCatalog={handleSelectCatalog}
                >
                    {props => <DashboardCatalogs {...props}></DashboardCatalogs>}
                </CatalogsContainer>
            )}
        </>
    );
};
