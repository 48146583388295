import React, { useState } from 'react';
import { Collapsible } from '../../core-ui/collapse';
import { useStyle } from '../../shared/hooks/useStyle';
import {
    outerMostContainerStyle,
    inlineBlockStyle,
    variablesHeaderContainer,
    iconStyle
} from './FrameInspectorDynamicsContainer.style';
import { ScriptActionType } from '../../shared/motive/reducers/ScriptEditorReducers';
import {
    ISelectorBranch,
    ISelectorNode,
    ISelectorTree,
    IFrame,
    FRAME_SELECTOR_TREE,
    IScript
} from '../../shared/motive/models/Script';
import { Size } from '../../core-ui/constants/Size';
import { HeightUsedScrollContainer } from '../heightUsedScrollContainer';
import { createSelectorBranch, createSelectorTree, createSelectorScenario } from '../../util/ScriptDynamicsUtil';
import { arrayInsert } from '../../util/Utils';
import { FrameVariablesOptionsContainer } from '../frameVariablesOptionsContainer';
import { useSetFrameToolsTab } from '../../hooks/useFrameTools';
import { FRAME_TOOLS_TAB_INDICES } from '../frameToolsContainer';
import { frameVariableListStyle } from '../frameVariablesOptionsContainer/FrameVariablesOptionsContainer.style';
import { useDispatch } from 'react-redux';
import {
    DROPPABLE_ID_FRAME_INSPECTOR_DYNAMICS_VARIABLES,
    DRAG_ITEM_TYPE_SCRIPT_VARIABLE
} from '../../constants/DragAndDrop';
import { onDragVariableCreate } from '../../components/_common/DragAndDrop/OnDragHandlers/VariableActions';
import { Drop } from '../../components/_common/DragAndDrop/Drop/Drop';
import { ILeftColProps } from '../../components/dynamicRowBase/DynamicRowBase';
import { IDynamicRowNodeProps } from '../../components/dynamicRowNode/DynamicRowNode';
import { IRecursiveSelectiveTreeRootProps, RecursiveSelectiveTreeRoot } from '../../components/dynamicTree/DynamicTree';
import { Text } from '../../core-ui/text';
import { IObjectEditorProps } from '../objectEditor';
import { FieldTypes } from '../../shared/motive/models/ScriptObjectModel';
import { IconTypes } from '../../core-ui/constants/IconTypes';
import { Icon } from '../../core-ui/icon';
import { useTranslation } from 'react-i18next';

export const HOVER_CONTENT_CLASS_NAME = 'HOVER_CONTENT_CLASS';
export const CREATE_PATH = 'CREATE_PATH';
export const KEY_CREATE_SCENARIO = 'KEY_CREATE_SCENARIO';
export const KEY_CREATE_BRANCH = 'KEY_CREATE_BRANCH';
export const KEY_SHOW_FRAME_VARIABLES = 'KEY_SHOW_FRAME_VARIABLES';

interface IFrameInspectorDynamicsProps {
    frame: IFrame;
    script: IScript;
    onClickSetVariableId: (eventId: string) => void;
}

export interface IOnClickCreateSelectorTree {
    onClickCreateVariable?: () => void;
    onClickCreateBranch: (
        nullableScenario: ISelectorNode | undefined,
        subFrameScenarioPath: string | undefined,
        insertIndex?: number
    ) => void;
    onClickCreateScenario?: (branch: ISelectorBranch, subFrameBranchPath: string, insertIndex?: number) => void;
}

export interface IFrameInspectorDynamicsOnChange {
    onChange: (
        subFramePath: string,
        subFrameValue: ISelectorTree | ISelectorNode | ISelectorBranch | FieldTypes | undefined
    ) => void;
    subFramePath: NonNullable<IObjectEditorProps['path']>;
}

export interface ISelectedIndex {
    selected?: string | undefined;
    setSelected: (index: ISelectedIndex['selected']) => void;
}

export interface IWithDepthProps {
    indexDepth: number;
    maxDepth: number;
}

export interface ILeftColWidth {
    leftColWidth: number;
    onLeftColWidthChange?: ILeftColProps['onWidthChange'];
}

export interface IRecursiveDataProps
    extends IWithDepthProps,
        ISelectedIndex,
        IFrameInspectorDynamicsOnChange,
        ILeftColWidth,
        IOnClickCreateSelectorTree {
    frame: IFrame;
    script: IScript;
}

export interface ISelectedIndex {
    selected?: string | undefined;
    setSelected: (index: ISelectedIndex['selected']) => void;
}

export const FrameInspectorDynamicsContainer: React.FC<IFrameInspectorDynamicsProps> = ({
    frame,
    script,
    onClickSetVariableId
}) => {
    const tokens = useStyle();
    const { t } = useTranslation();
    const scriptDispatch = useDispatch();

    const dropList = [
        {
            dragType: DRAG_ITEM_TYPE_SCRIPT_VARIABLE,
            onDragEnd: onDragVariableCreate(scriptDispatch, frame.id)
        }
    ];

    const [selected, setSelected] = useState<ISelectedIndex['selected']>(undefined);
    const [defaultLeftColWidth, setDefaultLeftColWidth] = useState(100);
    const setFrameToolsTab = useSetFrameToolsTab();

    const handleFrameChange: IRecursiveSelectiveTreeRootProps['onChange'] = (subFramePath, value) => {
        const action = {
            type: ScriptActionType.OBJECT_UPDATE,
            targetFrameId: frame.id,
            updatedObject: value,
            updatedObjectPath: subFramePath
        };
        scriptDispatch(action);
    };

    const handleDefaultLeftColWidthChange: ILeftColProps['onWidthChange'] = width => {
        if (width === 0 || width === defaultLeftColWidth) {
            return;
        }

        setDefaultLeftColWidth(width);
    };

    const handleClickCreateScenario: IDynamicRowNodeProps['onClickCreateScenario'] = (
        selectorBranch,
        subFrameBranchPath,
        insertIndex
    ) => {
        let { nodes = [] } = selectorBranch;
        if (!insertIndex || insertIndex < 0) {
            insertIndex = nodes.length;
        }
        nodes = arrayInsert(nodes, createSelectorScenario(), insertIndex);
        const updatedBranch: ISelectorBranch = {
            ...selectorBranch,
            nodes
        };

        setSelected(undefined);
        handleFrameChange(subFrameBranchPath, updatedBranch);
    };

    const handleClickCreateBranch: IDynamicRowNodeProps['onClickCreateBranch'] = (
        nullableScenario,
        subFrameScenarioPath,
        insertIndex
    ) => {
        const addBranchToRoot = !nullableScenario;
        let branches = addBranchToRoot ? frame.selectorTree?.branches ?? [] : nullableScenario?.branches ?? [];

        insertIndex = !!insertIndex && insertIndex >= 0 ? insertIndex : branches.length;
        branches = arrayInsert(branches, createSelectorBranch(), insertIndex);

        let toChangeTarget: ISelectorTree | ISelectorNode | undefined = nullableScenario;
        let toChangePath = subFrameScenarioPath;
        if (addBranchToRoot) {
            toChangeTarget = frame.selectorTree ?? createSelectorTree();
            toChangePath = 'selectorTree';
        }

        if (!toChangeTarget) {
            throw new Error('no target found');
        }
        if (!toChangePath) {
            throw new Error('no valid path');
        }

        toChangeTarget = {
            ...toChangeTarget,
            branches
        };

        setSelected(undefined);
        handleFrameChange(toChangePath, toChangeTarget);
    };

    const handleClickCreateVariable: IRecursiveSelectiveTreeRootProps['onClickCreateVariable'] = () => {
        setFrameToolsTab(FRAME_TOOLS_TAB_INDICES.variables, true);
    };

    return (
        <HeightUsedScrollContainer css={outerMostContainerStyle} style={{ padding: 0 }}>
            <Drop dropId={DROPPABLE_ID_FRAME_INSPECTOR_DYNAMICS_VARIABLES} allowedDragList={dropList}>
                <div
                    css={variablesHeaderContainer}
                    onClick={() =>
                        setSelected(selected === KEY_SHOW_FRAME_VARIABLES ? undefined : KEY_SHOW_FRAME_VARIABLES)
                    }
                >
                    <Text css={inlineBlockStyle} size={Size.LARGER}>
                        {t('variableInFrameWithCount', { count: frame.definedVariables?.length ?? 0 })}
                    </Text>
                    <Icon
                        icon={selected === KEY_SHOW_FRAME_VARIABLES ? IconTypes.CHEVRON_DOWN : IconTypes.CHEVRON_RIGHT}
                        iconStyle={iconStyle}
                    ></Icon>
                </div>
                <Collapsible isShowing={selected === KEY_SHOW_FRAME_VARIABLES}>
                    <div css={frameVariableListStyle(tokens)}>
                        <FrameVariablesOptionsContainer frame={frame} onClickSetVariableId={onClickSetVariableId} />
                    </div>
                </Collapsible>
            </Drop>

            <RecursiveSelectiveTreeRoot
                frame={frame}
                script={script}
                selectorTree={frame.selectorTree}
                selected={selected}
                setSelected={setSelected}
                onChange={handleFrameChange}
                subFramePath={FRAME_SELECTOR_TREE}
                onLeftColWidthChange={handleDefaultLeftColWidthChange}
                leftColWidth={defaultLeftColWidth}
                onClickCreateScenario={handleClickCreateScenario}
                onClickCreateBranch={handleClickCreateBranch}
                onClickCreateVariable={handleClickCreateVariable}
            />
        </HeightUsedScrollContainer>
    );
};
