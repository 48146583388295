import { createBundle } from '../../networking/BundleService';
import { useCurrentSpace } from '../useCurrentSpace';
import { useCurrentProject } from '../useCurrentProject';
import { IHookProvidedCall } from '../../../hooks/useNetworkCallForDataSource/IHookProvidedDataSource';
import { useNetworkCallWithoutDataSource } from '../../../hooks/useNetworkCallForDataSource';
import { useDispatch } from 'react-redux';

export interface ICreateBundleParams {
    name: string;
    description?: string;
}

export const useCreateBundle = (): IHookProvidedCall<ICreateBundleParams> => {
    const [currentSpace] = useCurrentSpace();
    const [currentProject] = useCurrentProject();

    const dispatch = useDispatch();
    const { triggerCall, ...rest } = useNetworkCallWithoutDataSource();

    const call = async (params: ICreateBundleParams) => {
        await triggerCall(createBundle(currentSpace, currentProject, params.name, dispatch));
    };

    return {
        execute: call,
        ...rest
    };
};
