import React from 'react';
import { MotiveTypes } from '../../constants/MotiveTypes';
import { useDashboardDrawer } from '../../hooks/useDashboardDrawer';
import { DrawerIntentCatalogs } from '../../components/drawerIntentCatalogs';

export const DrawerIntentCatalogsContainer: React.FC = () => {
    const { handleClickIntentCatalog } = useDashboardDrawer();

    const handleSelectCatalog = (catalogId: string) => {
        handleClickIntentCatalog({ id: catalogId, name: catalogId, objectType: MotiveTypes.WATSON_INTENT });
    };

    return <DrawerIntentCatalogs onSelectCatalog={handleSelectCatalog} />;
};
